import { venueText } from "@transficc/components";
import * as IRSDomain from "../irs-domain";
import {
    addSpacesToCamelCase,
    calculatePackageLevelValueByInquiryType,
    IRSInquiry,
    IRSLeg,
    PackageType,
    QuoteCompetitiveStatus,
    TradedAway,
} from "../irs-domain";
import { AutoXMode, VenueType } from "@transficc/trader-desktop-shared-domain";
import { NO_VALUE } from "@transficc/trader-desktop-ag-grid-common";

export interface BlotterRow {
    ticketId: number;
    venueName: string;
    venueType: string | null;
    path: string[];
    aggregateId: string;
    lastInquiryStateUpdateTimestampNanos: number;
    inquiryState: string;
    counterpartyFirm: string;
    counterpartyTrader: string;
    salespersonName: string | null;
    salespersonId: string | null;
    lastAckedActionTraderName: string;
    autoMode: string;
    legPosition: string;
    legEffectiveDate: number | null;
    legTerminationDate: number | null;
    legSide: string;
    legInstrumentName: string;
    legQuantity: string;
    legTradedPrice: string;
    legLatestQuotedBidPrice: string;
    legLatestQuotedMidPrice: string;
    legLatestQuotedAskPrice: string;
    legCoverPrice: string;
    legTradedAway: string;
    legQuoteCompetitiveness: string;
    legPriceType: string;
    legFixedRate: string | null;
    legMinPriceIncrement: string;
    isRfm: string;
    tradeHandle: string | null;
    inquiryCreationTimestampNanos: number;
}

export const mapInquiryToBlotterRows = (inquiry: IRSInquiry<PackageType>): BlotterRow[] => {
    switch (inquiry.packageType) {
        case "compression":
            return mapCompressionToBlotterRows(inquiry as IRSDomain.IRSInquiry<"compression">);
        case "curve":
            return mapCurveToBlotterRows(inquiry as IRSDomain.IRSInquiry<"curve">);
        case "outright":
            return mapOutrightToBlotterRows(inquiry as IRSDomain.IRSInquiry<"outright">);
    }
};

const getTraderName = (inquiry: IRSInquiry<PackageType>): string => {
    return inquiry.lastAckedActionTraderName ?? NO_VALUE;
};

const getLegSide = (leg: IRSLeg<PackageType>): string => leg.side.toString().toUpperCase();

const getLegTradedAway = (leg: IRSLeg<PackageType>): string => {
    if (leg.tradedAway === null || leg.tradedAway === TradedAway.NotSet) {
        return NO_VALUE;
    }
    return leg.tradedAway.toString().toUpperCase();
};

const getQuoteCompetitiveness = (quoteCompetitiveStatus: QuoteCompetitiveStatus | null): string => {
    if (quoteCompetitiveStatus === null || quoteCompetitiveStatus === QuoteCompetitiveStatus.NotSet) {
        return NO_VALUE;
    }
    return quoteCompetitiveStatus.toString();
};

const getAutoMode = (autoXMode: AutoXMode | null): string => {
    if (autoXMode === null) {
        return NO_VALUE;
    }
    return autoXMode.toString().toUpperCase().replace(/_/g, " ");
};

const formatIsRfm = (inquiry: IRSInquiry<PackageType>): string => {
    return inquiry.isRfm ? "YES" : "NO";
};

const mapVenueType = (venueType: VenueType | null): string => {
    switch (venueType) {
        case null:
            return NO_VALUE;
        case VenueType.Mtf:
            return "MTF";
        case VenueType.Sef:
            return "SEF";
        case VenueType.OffVenue:
            return "OFF VENUE";
    }
};

const mapCompressionToBlotterRows = (inquiry: IRSInquiry<"compression">): BlotterRow[] => {
    const inquiryQuantity = aggregateLegValue(inquiry, (leg) => Number(leg.quantity));

    let inquiryTradedPriceStr: string;
    const anyLegsWithATradedPrice = inquiry.legs.find((leg) => leg.negotiatedPrice != null) !== undefined;
    if (anyLegsWithATradedPrice) {
        const aggregatedPrice = aggregateLegValue(inquiry, (leg) => Number(leg.negotiatedPrice));
        inquiryTradedPriceStr = `${aggregatedPrice}` ?? NO_VALUE;
    } else {
        inquiryTradedPriceStr = NO_VALUE;
    }

    const inquiryRow: BlotterRow = {
        ticketId: inquiry.ticketId,
        venueName: venueText(inquiry.venueName),
        venueType: mapVenueType(inquiry.venueType),
        path: [`${inquiry.ticketId}`],
        aggregateId: inquiry.aggregateId,
        lastInquiryStateUpdateTimestampNanos: Number(inquiry.lastInquiryStateUpdateTimestampNanos),
        inquiryState: getInquiryState(inquiry),
        counterpartyFirm: inquiry.counterpartyFirm,
        counterpartyTrader: inquiry.counterpartyTrader,
        salespersonName: inquiry.salesPersonName,
        salespersonId: inquiry.salesPersonId,
        autoMode: getAutoMode(inquiry.autoXMode),
        lastAckedActionTraderName: getTraderName(inquiry),
        legPosition: NO_VALUE,
        legEffectiveDate: null,
        legTerminationDate: null,
        legSide: "As Defined",
        legInstrumentName: `LIST - ${inquiry.legs.length} items`,
        legQuantity: `${inquiryQuantity}`,
        legTradedPrice: inquiryTradedPriceStr,
        legLatestQuotedBidPrice: inquiry.latestQuotedPackageFee ?? NO_VALUE,
        legLatestQuotedMidPrice: inquiry.latestQuotedMidPackageFee ?? NO_VALUE,
        legLatestQuotedAskPrice: inquiry.latestQuotedOppositePackageFee ?? NO_VALUE,
        legCoverPrice: inquiry.packageCoverPrice ?? NO_VALUE,
        legTradedAway: getPackageTradedAway(inquiry),
        legQuoteCompetitiveness: getQuoteCompetitiveness(inquiry.packageQuoteCompetitiveStatus),
        legPriceType: addSpacesToCamelCase(inquiry.legs[0].priceType),
        legFixedRate: NO_VALUE,
        legMinPriceIncrement: inquiry.legs[0].minPriceIncrement,
        isRfm: formatIsRfm(inquiry),
        tradeHandle: inquiry.copyHandle ?? NO_VALUE,
        inquiryCreationTimestampNanos: Number(inquiry.inquiryCreationTimestampNanos),
    };

    const legRows: BlotterRow[] = inquiry.legs.map(
        (leg) =>
            ({
                ticketId: inquiry.ticketId,
                venueName: venueText(inquiry.venueName),
                venueType: mapVenueType(inquiry.venueType),
                path: [`${inquiry.ticketId}`, `${leg.position}`],
                aggregateId: inquiry.aggregateId,
                lastInquiryStateUpdateTimestampNanos: Number(inquiry.lastInquiryStateUpdateTimestampNanos),
                inquiryState: getInquiryState(inquiry),
                counterpartyFirm: inquiry.counterpartyFirm,
                counterpartyTrader: inquiry.counterpartyTrader,
                salespersonName: inquiry.salesPersonName,
                salespersonId: inquiry.salesPersonId,
                lastAckedActionTraderName: getTraderName(inquiry),
                legPosition: `${leg.position}`,
                legEffectiveDate: leg.effectiveDate,
                legTerminationDate: leg.terminationDate,
                autoMode: getAutoMode(inquiry.autoXMode),
                legSide: getLegSide(leg),
                legInstrumentName: leg.instrumentName,
                legQuantity: leg.quantity,
                legTradedPrice: leg.negotiatedPrice ?? NO_VALUE,
                legLatestQuotedBidPrice: NO_VALUE,
                legLatestQuotedMidPrice: NO_VALUE,
                legLatestQuotedAskPrice: NO_VALUE,
                legCoverPrice: leg.coverPrice ?? NO_VALUE,
                legTradedAway: NO_VALUE,
                legQuoteCompetitiveness: NO_VALUE,
                legPriceType: addSpacesToCamelCase(leg.priceType),
                legFixedRate: leg.fixedRate,
                legMinPriceIncrement: leg.minPriceIncrement,
                isRfm: NO_VALUE,
                tradeHandle: NO_VALUE,
                inquiryCreationTimestampNanos: Number(inquiry.inquiryCreationTimestampNanos),
            } satisfies BlotterRow),
    );

    return [inquiryRow, ...legRows];
};

const mapCurveToBlotterRows = (inquiry: IRSInquiry<"curve">): BlotterRow[] => {
    const inquiryRow: BlotterRow = {
        ticketId: inquiry.ticketId,
        venueName: venueText(inquiry.venueName),
        venueType: mapVenueType(inquiry.venueType),
        path: [`${inquiry.ticketId}`],
        aggregateId: inquiry.aggregateId,
        lastInquiryStateUpdateTimestampNanos: Number(inquiry.lastInquiryStateUpdateTimestampNanos),
        inquiryState: getInquiryState(inquiry),
        counterpartyFirm: inquiry.counterpartyFirm,
        counterpartyTrader: inquiry.counterpartyTrader,
        salespersonName: inquiry.salesPersonName,
        salespersonId: inquiry.salesPersonId,
        lastAckedActionTraderName: getTraderName(inquiry),
        legPosition: NO_VALUE,
        legEffectiveDate: null,
        legTerminationDate: null,
        autoMode: getAutoMode(inquiry.autoXMode),
        legSide: getLegSide(inquiry.legs[1]),
        legInstrumentName: `${inquiry.legs[0].instrumentName} / ${inquiry.legs[1].instrumentName}`,
        legQuantity: inquiry.legs[1].quantity,
        legTradedPrice: getCurvePackageTradedPrice(inquiry),
        legLatestQuotedBidPrice: inquiry.latestQuotedPackageFeePay ?? NO_VALUE,
        legLatestQuotedMidPrice: inquiry.latestQuotedMidPackageFee ?? NO_VALUE,
        legLatestQuotedAskPrice: inquiry.latestQuotedPackageFeeRcv ?? NO_VALUE,
        legCoverPrice: inquiry.packageCoverPrice ?? NO_VALUE,
        legTradedAway: getPackageTradedAway(inquiry),
        legQuoteCompetitiveness: getQuoteCompetitiveness(inquiry.packageQuoteCompetitiveStatus),
        legPriceType: addSpacesToCamelCase(inquiry.legs[1].priceType),
        legFixedRate: "-",
        legMinPriceIncrement: inquiry.packageMinPriceIncrement ?? "0",
        isRfm: formatIsRfm(inquiry),
        tradeHandle: inquiry.copyHandle ?? NO_VALUE,
        inquiryCreationTimestampNanos: Number(inquiry.inquiryCreationTimestampNanos),
    };

    const legRows: BlotterRow[] = inquiry.legs.map((leg) => {
        return {
            ticketId: inquiry.ticketId,
            venueName: venueText(inquiry.venueName),
            venueType: mapVenueType(inquiry.venueType),
            path: [`${inquiry.ticketId}`, `${leg.position}`],
            aggregateId: inquiry.aggregateId,
            lastInquiryStateUpdateTimestampNanos: Number(inquiry.lastInquiryStateUpdateTimestampNanos),
            inquiryState: getInquiryState(inquiry),
            counterpartyFirm: inquiry.counterpartyFirm,
            counterpartyTrader: inquiry.counterpartyTrader,
            salespersonName: inquiry.salesPersonName,
            salespersonId: inquiry.salesPersonId,
            lastAckedActionTraderName: getTraderName(inquiry),
            legPosition: `${leg.position}`,
            legEffectiveDate: leg.effectiveDate,
            legTerminationDate: leg.terminationDate,
            autoMode: getAutoMode(inquiry.autoXMode),
            legSide: getLegSide(leg),
            legInstrumentName: leg.instrumentName,
            legQuantity: leg.quantity,
            legTradedPrice: leg.negotiatedPrice ?? NO_VALUE,
            legLatestQuotedBidPrice: leg.lastQuotedPrices[0] ?? NO_VALUE,
            legLatestQuotedMidPrice: leg.lastQuotedPrices[1] ?? NO_VALUE,
            legLatestQuotedAskPrice: leg.lastQuotedPrices[2] ?? NO_VALUE,
            legCoverPrice: leg.coverPrice ?? NO_VALUE,
            legTradedAway: NO_VALUE,
            legQuoteCompetitiveness: NO_VALUE,
            legPriceType: addSpacesToCamelCase(leg.priceType),
            legFixedRate: leg.fixedRate,
            legMinPriceIncrement: leg.minPriceIncrement,
            isRfm: NO_VALUE,
            tradeHandle: NO_VALUE,
            inquiryCreationTimestampNanos: Number(inquiry.inquiryCreationTimestampNanos),
        } satisfies BlotterRow;
    });
    return [inquiryRow, ...legRows];
};

const getInquiryState = (inquiry: IRSInquiry<PackageType>): string => {
    return inquiry.state.toString().toUpperCase().replace(/_/g, " ");
};

const getPackageTradedAway = (inquiry: IRSInquiry<PackageType>): string => {
    if (inquiry.packageTradedAway === null || inquiry.packageTradedAway.toUpperCase() === TradedAway.NotSet.toUpperCase()) {
        return NO_VALUE;
    }
    return inquiry.packageTradedAway.toString().toUpperCase();
};

const mapOutrightToBlotterRows = (inquiry: IRSInquiry<"outright">): BlotterRow[] => {
    return inquiry.legs.map((leg) => {
        return {
            ticketId: inquiry.ticketId,
            venueName: venueText(inquiry.venueName),
            venueType: mapVenueType(inquiry.venueType),
            path: [`${inquiry.ticketId}_${leg.position}`],
            aggregateId: inquiry.aggregateId,
            lastInquiryStateUpdateTimestampNanos: Number(inquiry.lastInquiryStateUpdateTimestampNanos),
            inquiryState: getInquiryState(inquiry),
            counterpartyFirm: inquiry.counterpartyFirm,
            counterpartyTrader: inquiry.counterpartyTrader,
            salespersonName: inquiry.salesPersonName,
            salespersonId: inquiry.salesPersonId,
            lastAckedActionTraderName: getTraderName(inquiry),
            autoMode: getAutoMode(inquiry.autoXMode),
            legPosition: `${leg.position}`,
            legEffectiveDate: leg.effectiveDate,
            legTerminationDate: leg.terminationDate,
            legSide: getLegSide(leg),
            legInstrumentName: leg.instrumentName,
            legQuantity: leg.quantity,
            legTradedPrice: leg.negotiatedPrice ?? NO_VALUE,
            legLatestQuotedBidPrice: leg.lastQuotedPrices[0] ?? NO_VALUE,
            legLatestQuotedMidPrice: leg.lastQuotedPrices[1] ?? NO_VALUE,
            legLatestQuotedAskPrice: leg.lastQuotedPrices[2] ?? NO_VALUE,
            legCoverPrice: leg.coverPrice ?? NO_VALUE,
            legTradedAway: getLegTradedAway(leg),
            legQuoteCompetitiveness: getQuoteCompetitiveness(leg.quoteCompetitiveStatus),
            legPriceType: addSpacesToCamelCase(leg.priceType),
            legFixedRate: leg.fixedRate,
            legMinPriceIncrement: leg.minPriceIncrement,
            isRfm: formatIsRfm(inquiry),
            tradeHandle: inquiry.copyHandle ?? NO_VALUE,
            inquiryCreationTimestampNanos: Number(inquiry.inquiryCreationTimestampNanos),
        } satisfies BlotterRow;
    });
};

const aggregateLegValue = (inquiry: IRSInquiry<"compression">, aggregator: (leg: IRSLeg<"compression">) => number): number => {
    let aggregatedValue = 0;
    inquiry.legs.forEach((leg) => {
        aggregatedValue += aggregator(leg);
    });
    return aggregatedValue;
};

const getCurvePackageTradedPrice = (inquiry: IRSInquiry<"curve">): string => {
    if (inquiry.legs[0].negotiatedPrice === null || inquiry.legs[1].negotiatedPrice === null) {
        return NO_VALUE;
    }
    return (
        calculatePackageLevelValueByInquiryType(
            inquiry.isMac,
            inquiry.legs[1].negotiatedPrice,
            inquiry.legs[0].negotiatedPrice,
        )?.toString() ?? NO_VALUE
    );
};
