import { useMemo } from "react";
import { credit } from "./CreditSlice";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { DataMatrixCellPosition } from "@transficc/components";

export interface TicketStateDispatch {
    setDriver: (newDriverPosition: DataMatrixCellPosition | null) => void;
}

export const useTicketStateDispatch = (ticketId: number): TicketStateDispatch => {
    const dispatch = useDispatch<Dispatch<AnyAction>>();
    return useMemo(
        () => ({
            setDriver: (selectedDriverCellPosition) =>
                dispatch(
                    credit.actions.setDriver({
                        ticketId,
                        selectedDriverCellPosition,
                    }),
                ),
        }),
        [dispatch, ticketId],
    );
};
