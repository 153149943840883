import { useCallback } from "react";
import { accept, autoStreamRequest, manualRequest, priceBasisUpdate, quote, reject } from "../thunks/InquiryActions";
import { EpochClock } from "@transficc/infrastructure";
import { ActionIdGenerator } from "@transficc/trader-desktop-application-context";
import { useThunkDispatch } from "./useThunkDispatch";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";

export const useInquiryActionPublisher = (actionIdGenerator: ActionIdGenerator, clock: EpochClock): ActionPublisher.ActionPublisher => {
    const dispatch = useThunkDispatch();

    return useCallback(
        (action: ActionPublisher.Action) => {
            const payloadActionType = action.msgType;
            switch (payloadActionType) {
                case ActionPublisher.InquiryAction.Quote:
                    void dispatch(
                        quote({
                            ...action,
                            actionId: actionIdGenerator.next(),
                            transactTimeNs: clock.getCurrentTimeNs(),
                        }),
                    );
                    break;
                case ActionPublisher.InquiryAction.DealerAccept:
                    void dispatch(
                        accept({
                            ...action,
                            actionId: actionIdGenerator.next(),
                            transactTimeNs: clock.getCurrentTimeNs(),
                        }),
                    );
                    break;
                case ActionPublisher.InquiryAction.DealerReject:
                    void dispatch(
                        reject({
                            ...action,
                            actionId: actionIdGenerator.next(),
                            transactTimeNs: clock.getCurrentTimeNs(),
                        }),
                    );
                    break;
                case ActionPublisher.InquiryAction.PriceBasisUpdate:
                    void dispatch(
                        priceBasisUpdate({
                            ...action,
                        }),
                    );
                    break;
                case ActionPublisher.InquiryAction.AutoStreamRequest:
                    void dispatch(
                        autoStreamRequest({
                            ...action,
                        }),
                    );
                    break;
                case ActionPublisher.InquiryAction.ManualRequest:
                    void dispatch(
                        manualRequest({
                            ...action,
                        }),
                    );
                    break;
                default:
                    payloadActionType satisfies never;
            }
        },
        [actionIdGenerator, clock, dispatch],
    );
};
