/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Draft, produce } from "immer";
import { DateTime } from "luxon";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { AssetClass, AutoXMode } from "@transficc/trader-desktop-shared-domain";
// import {PricerState} from "packages/protocols/trader-desktop-public-protocol-types/src/lib/generated";
// import {
//     AssetClass,
//     PackageType,
//     PricerState,
//     PriceType,
//     QuoteCompetitiveStatus,
//     RateType,
//     Side,
//     TradedAway,
// } from "packages/protocols/trader-desktop-public-protocol-types/src/lib/generated";

export const makeActionFailure = (
    producer: (draft: TraderDesktopProtocol.ActionFailure) => void = () => undefined,
): TraderDesktopProtocol.ActionFailure => {
    const actionFailure: TraderDesktopProtocol.ActionFailure = {
        actionId: "df46b930-9735-496c-a94e-3fdc9556000e",
        actionType: TraderDesktopProtocol.ActionType.Quote,
        reason: "invalid value",
        source: TraderDesktopProtocol.ActionFailureSource.Venue,
        userId: 123,
    };

    return produce(actionFailure, producer);
};

export const mapDateInt = (date: string): number => {
    const dateTime = DateTime.fromFormat(date, "dd MMM yy");
    return parseInt(dateTime.toFormat("yyyyMMdd"));
};

function turnInToRfm(draft: Draft<TraderDesktopProtocol.Inquiry>): void {
    draft.isRfm = true;
    for (const leg of draft.legs) {
        if (!leg) {
            return;
        }
        leg.side = TraderDesktopProtocol.Side.Undisclosed;
    }
}

export const makeRfmOutrightInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const inquiry = makeRfqOutrightInquiry(ticketId, turnInToRfm);
    return produce(inquiry, producer);
};

export const makeRfqOutrightInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const date = new Date(Date.now());
    const inquiry: TraderDesktopProtocol.Inquiry = {
        autoXMode: AutoXMode.Manual,
        autoXModeChangeDescription: "",
        autoXModeChangeReason: null,
        ticketId: ticketId * 1000000,
        aggregateId: `agg-${ticketId}`,
        venueName: "TRADEWEB_IRS_TRADING",
        venueType: TraderDesktopProtocol.VenueType.OffVenue,
        assetClass: AssetClass.Irs,

        eventSequence: -1,

        numberOfDealers: 2,
        counterpartyTrader: "John Doe",
        counterpartyFirm: "Customer Firm Name",
        salesPersonId: "miss-sales-person",
        salesPersonName: "Miss Sales Person",

        timerStartTimestampMillis: String(Date.now()),
        timerEndTimestampMillis: String(Date.now() + 30000),

        allowedActions: [TraderDesktopProtocol.ActionType.Quote, TraderDesktopProtocol.ActionType.DealerReject],
        state: TraderDesktopProtocol.InquiryState.NewRequest,
        actionFailures: [],
        lastAckedActionTraderName: "bob bob",
        lastInquiryStateUpdateTimestampNanos: String(Date.now() * 1000000),
        isPtmmRequired: false,

        legs: [
            makeLeg((leg) => {
                leg.position = 0;
                leg.side = TraderDesktopProtocol.Side.Buy;
                leg.quantity = "25000000";
                leg.negotiatedPrice = "1.123";
                leg.clearingFirm = "LCM";
            }),
        ],
        packageType: TraderDesktopProtocol.PackageType.Outright,
        packageCoverPrice: "1.1122",
        packageTradedAway: TraderDesktopProtocol.TradedAway.No,
        packageQuoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus.Cover,
        isMac: false,
        isRfm: false,
        inquiryCreationTimestampNanos: String(date.setHours(date.getHours() - 1) * 1000000),
        autoSpotFailed: null,
        benchmarkHedge: null,
        counterpartyRank: null,
        counterpartyType: null,
        customerOppositePackagePrice: null,
        customerPackagePrice: null,
        hasMixedIndices: null,
        hedgingType: null,
        isAllToAll: null,
        isCounterpartyAnonymous: null,
        isPackageMisweighted: null,
        latestQuotedMidPackagePrice: null,
        latestQuotedOppositePackagePrice: null,
        latestQuotedPackagePrice: null,
        listId: null,
        msgType: "Inquiry",
        numberOfListItems: null,
        packageDelta: null,
        packageDirection: null,
        packageMidCompositePrice: null,
        priceBasisEventSequence: null,
        priceBasisMarketPackage: null,
        priceBasisOppositePackage: null,
        priceBasisPreTradeMidMarketPackagePrice: null,
        priceGenerationTimestampNanos: null,
        pricerCustomComponents: null,
        pricerCustomInfo: null,
        pricerError: null,
        pricerOppositePackagePrice: null,
        pricerPackageMidPrice: null,
        pricerPackagePrice: null,
        pricerState: TraderDesktopProtocol.PricerState.Pending,
        priceTier: null,
        priceValidForNanos: null,
        quoteIsAlwaysFirm: null,
        sendingTimestampNanos: "0",
        spotNegotiationModel: null,
        spotRequestTimeNanos: null,
        tradedPackagePrice: null,
        triggerEvent: TraderDesktopProtocol.TriggerEvent.QuoteRequest,
        updateType: TraderDesktopProtocol.UpdateType.Snapshot,
        venueSpecifiedOnTheWireTimeMillis: null,
    };

    return produce(inquiry, producer);
};

export const makeRfmOutrightMacInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const inquiry = makeRfqOutrightMacInquiry(ticketId, turnInToRfm);
    return produce(inquiry, producer);
};

export const makeRfqOutrightMacInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry =>
    makeRfqOutrightInquiry(ticketId, (draft) => {
        draft.isMac = true;
        draft.legs[0]!.fixedRate = "2.50";
        draft.legs[0]!.priceType = TraderDesktopProtocol.PriceType.NetPresentValue;
        draft.legs[0]!.instrumentName = "CAD 2.50 Jun 22 10YR LCH";
        draft.legs[0]!.latestQuotedBidPrice = "139";
        draft.legs[0]!.latestQuotedMidPrice = "138";
        draft.legs[0]!.latestQuotedAskPrice = "136";
        draft.legs[0]!.bidCompositePrice = "140";
        draft.legs[0]!.midCompositePrice = "137";
        draft.legs[0]!.askCompositePrice = "132";
        draft.legs[0]!.minPriceIncrement = "0.01";
        draft.legs[0]!.negotiatedPrice = "200000";
        draft.legs[0]!.coverPrice = "190000";
        producer(draft);
    });

export const makeRfmCurveInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const inquiry = makeRfqCurveInquiry(ticketId, turnInToRfm);
    return produce(inquiry, producer);
};

export const makeRfqCurveInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const date = new Date(Date.now());
    const inquiry: TraderDesktopProtocol.Inquiry = {
        autoXMode: AutoXMode.Manual,
        autoXModeChangeDescription: "",
        autoXModeChangeReason: null,
        ticketId: ticketId,
        aggregateId: `agg-${ticketId}`,
        venueName: "TRADEWEB_IRS_TRADING",
        venueType: TraderDesktopProtocol.VenueType.OffVenue,
        assetClass: AssetClass.Irs,

        eventSequence: -1,

        // tier: 1,
        numberOfDealers: 2,
        counterpartyTrader: "John Doe",
        counterpartyFirm: "Customer Firm Name",
        salesPersonId: "miss-sales-person",
        salesPersonName: "Miss Sales Person",

        timerStartTimestampMillis: String(Date.now()),
        timerEndTimestampMillis: String(Date.now() + 30000),

        allowedActions: [TraderDesktopProtocol.ActionType.Quote, TraderDesktopProtocol.ActionType.DealerReject],
        state: TraderDesktopProtocol.InquiryState.NewRequest,
        actionFailures: [],
        isPackageMisweighted: false,
        packageMidCompositePrice: null,

        // isFromSnapshot: false,
        // copyHandle: null,
        // riskViewUrl: null,
        lastAckedActionTraderName: "bob bob",
        lastInquiryStateUpdateTimestampNanos: String(Date.now() * 1000000),

        packageCoverPrice: "1.6",
        packageTradedAway: TraderDesktopProtocol.TradedAway.No,
        packageQuoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus.Cover,
        isMac: false,
        isRfm: false,
        isPtmmRequired: false,

        // packageMinPriceIncrement: "0.001",
        // latestQuotedPackageFeePay: null,
        // latestQuotedPackageFeeRcv: null,
        // latestQuotedMidPackageFee: null,
        // autoQuotePriceState: PricerState.Priced,
        // autoQuotePriceError: null,
        // autoQuotePriceValidity: null,
        legs: [
            makeLeg((leg) => {
                leg.position = 0;
                leg.side = TraderDesktopProtocol.Side.Sell;
                leg.instrumentName = "5Y (SB) vs 3M CDOR";
                leg.quantity = "25000000";
                leg.negotiatedPrice = "2.123";
                leg.clearingFirm = "LCM";
                leg.priceType = TraderDesktopProtocol.PriceType.InterestRate;
            }),
            makeLeg((leg) => {
                leg.position = 1;
                leg.side = TraderDesktopProtocol.Side.Buy;
                leg.instrumentName = "10Y (SB) vs 3M CDOR";
                leg.quantity = "75000000";
                leg.negotiatedPrice = "3.321";
                leg.clearingFirm = "LCM";
                leg.priceType = TraderDesktopProtocol.PriceType.InterestRate;
            }),
        ],
        packageType: TraderDesktopProtocol.PackageType.Curve,
        // priceBasis: {
        //     eventSequence: 0,
        // },
        inquiryCreationTimestampNanos: String(date.setHours(date.getHours() - 1) * 1000000),
        autoSpotFailed: null,
        benchmarkHedge: null,
        counterpartyRank: null,
        counterpartyType: null,
        customerOppositePackagePrice: null,
        customerPackagePrice: null,
        hasMixedIndices: null,
        hedgingType: null,
        isAllToAll: null,
        isCounterpartyAnonymous: null,
        latestQuotedMidPackagePrice: null,
        latestQuotedOppositePackagePrice: null,
        latestQuotedPackagePrice: null,
        listId: null,
        msgType: "Inquiry",
        numberOfListItems: null,
        packageDelta: null,
        packageDirection: null,
        priceBasisEventSequence: null,
        priceBasisMarketPackage: null,
        priceBasisOppositePackage: null,
        priceBasisPreTradeMidMarketPackagePrice: null,
        priceGenerationTimestampNanos: null,
        pricerCustomComponents: null,
        pricerCustomInfo: null,
        pricerError: null,
        pricerOppositePackagePrice: null,
        pricerPackageMidPrice: null,
        pricerPackagePrice: null,
        pricerState: TraderDesktopProtocol.PricerState.Pending,
        priceTier: null,
        priceValidForNanos: null,
        quoteIsAlwaysFirm: null,
        sendingTimestampNanos: "0",
        spotNegotiationModel: null,
        spotRequestTimeNanos: null,
        tradedPackagePrice: null,
        triggerEvent: TraderDesktopProtocol.TriggerEvent.QuoteRequest,
        updateType: TraderDesktopProtocol.UpdateType.Snapshot,
        venueSpecifiedOnTheWireTimeMillis: null,
    };

    return produce(inquiry, producer);
};

export const makeRfqCurveMacInquiry = (
    ticketId = 1,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry =>
    makeRfqCurveInquiry(ticketId, (draft) => {
        draft.packageCoverPrice = "600000";
        draft.isMac = true;
        draft.legs[0]!.fixedRate = "2.50";
        draft.legs[0]!.priceType = TraderDesktopProtocol.PriceType.NetPresentValue;
        draft.legs[0]!.instrumentName = "CAD 2.50 Jun 22 10YR LCH";
        draft.legs[0]!.latestQuotedBidPrice = "380000";
        draft.legs[0]!.latestQuotedMidPrice = "380000";
        draft.legs[0]!.latestQuotedAskPrice = "380000";
        draft.legs[0]!.bidCompositePrice = "140";
        draft.legs[0]!.midCompositePrice = "137";
        draft.legs[0]!.askCompositePrice = null;
        draft.legs[0]!.pricerBid = "139";
        draft.legs[0]!.pricerMid = "138";
        draft.legs[0]!.pricerAsk = null;
        draft.legs[0]!.negotiatedPrice = "400000";
        draft.legs[1]!.fixedRate = "2.20";
        draft.legs[1]!.priceType = TraderDesktopProtocol.PriceType.NetPresentValue;
        draft.legs[1]!.instrumentName = "CAD 2.20 Jun 23 10YR LCH";
        draft.legs[1]!.negotiatedPrice = "100000";
        draft.legs[1]!.coverPrice = null;
        draft.legs[1]!.latestQuotedBidPrice = "280000";
        draft.legs[1]!.latestQuotedMidPrice = "280000";
        draft.legs[1]!.latestQuotedAskPrice = "280000";
        draft.legs[1]!.bidCompositePrice = "150";
        draft.legs[1]!.midCompositePrice = "147";
        draft.legs[1]!.askCompositePrice = null;
        draft.legs[1]!.pricerBid = "149";
        draft.legs[1]!.pricerMid = "148";
        draft.legs[1]!.pricerAsk = null;
        producer(draft);
    });

export interface CompressionFactoryParams {
    numberOfLegs: number;
    ticketId?: number;
    minPriceIncrement?: string;
}

export type CompressionFactory = (params: CompressionFactoryParams) => TraderDesktopProtocol.Inquiry;

export const makeRfmCompressionInquiry = (
    { numberOfLegs, minPriceIncrement = "1000", ticketId = 1 }: CompressionFactoryParams,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const inquiry = makeRfqCompressionInquiry({ numberOfLegs, minPriceIncrement, ticketId }, (draft) => {
        draft.isRfm = true;
        draft.latestQuotedOppositePackagePrice = "2600";
    });
    return produce(inquiry, producer);
};

export const makeRfqCompressionInquiry = (
    { numberOfLegs, minPriceIncrement = "1000", ticketId = 1 }: CompressionFactoryParams,
    producer: (draft: TraderDesktopProtocol.Inquiry) => void = () => undefined,
): TraderDesktopProtocol.Inquiry => {
    const legs = [];
    for (let position = 0; position < numberOfLegs; position++) {
        legs.push(
            makeCompressionLeg((draft) => {
                draft.position = position;
                draft.side = position % 2 === 0 ? TraderDesktopProtocol.Side.Buy : TraderDesktopProtocol.Side.Sell;
                draft.clearingFirm = position % 3 === 0 ? "LCM" : "ICE";
                draft.quantity = `${(position + 1) * 1_000_000}`;
                draft.minPriceIncrement = minPriceIncrement;
                draft.fixedRate = `${-10.12 + position * 1.98}`;
            }),
        );
    }

    const firstLeg = legs[0] as TraderDesktopProtocol.InquiryLeg;
    const remainingLegs = legs.slice(1);

    const date = new Date(Date.now());
    const inquiry: TraderDesktopProtocol.Inquiry = {
        autoXMode: AutoXMode.Manual,
        autoXModeChangeDescription: "",
        autoXModeChangeReason: null,
        ticketId: ticketId,
        aggregateId: `agg-${ticketId}`,
        venueName: "TRADEWEB_IRS_TRADING",
        listId: `LIST-ID-${ticketId}`,
        customerOppositePackagePrice: null,
        packageDirection: TraderDesktopProtocol.PackageDirection.Market,
        venueType: TraderDesktopProtocol.VenueType.OffVenue,
        assetClass: AssetClass.Irs,

        eventSequence: -1,

        priceTier: 1,
        numberOfDealers: 2,
        counterpartyTrader: "John Doe",
        counterpartyFirm: "Customer Firm Name",
        salesPersonId: "miss-sales-person",
        salesPersonName: "Miss Sales Person",

        timerStartTimestampMillis: String(Date.now()),
        timerEndTimestampMillis: String(Date.now() + 2700000),

        allowedActions: [TraderDesktopProtocol.ActionType.Quote, TraderDesktopProtocol.ActionType.DealerReject],
        state: TraderDesktopProtocol.InquiryState.NewRequest,
        actionFailures: [],

        updateType: TraderDesktopProtocol.UpdateType.Update,

        isMac: false,

        legs: [firstLeg, ...remainingLegs],
        packageType: TraderDesktopProtocol.PackageType.Compression,
        customerPackagePrice: "3000",
        tradedPackagePrice: "4000",
        latestQuotedPackagePrice: "2000",
        latestQuotedMidPackagePrice: "2200",
        latestQuotedOppositePackagePrice: null,
        pricerError: null,
        pricerPackagePrice: "1222",
        pricerPackageMidPrice: "1234",
        pricerState: TraderDesktopProtocol.PricerState.Priced,
        lastAckedActionTraderName: "bob bob",
        lastInquiryStateUpdateTimestampNanos: String(Date.now() * 1000000),
        packageCoverPrice: "1.7",
        packageTradedAway: TraderDesktopProtocol.TradedAway.No,
        packageQuoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus.Cover,
        packageMidCompositePrice: String(1000 * legs.length),
        isRfm: false,
        isPtmmRequired: false,
        packageDelta: "-0.123",
        hasMixedIndices: false,
        inquiryCreationTimestampNanos: String(date.setHours(date.getHours() - 1) * 1000000),
        autoSpotFailed: null,
        benchmarkHedge: null,
        counterpartyRank: null,
        counterpartyType: null,
        hedgingType: null,
        isAllToAll: null,
        isCounterpartyAnonymous: null,
        isPackageMisweighted: null,
        msgType: "Inquiry",
        numberOfListItems: null,
        priceBasisEventSequence: null,
        priceBasisMarketPackage: null,
        priceBasisOppositePackage: null,
        priceBasisPreTradeMidMarketPackagePrice: null,
        priceGenerationTimestampNanos: null,
        pricerCustomComponents: null,
        pricerCustomInfo: null,
        pricerOppositePackagePrice: null,
        priceValidForNanos: null,
        quoteIsAlwaysFirm: null,
        sendingTimestampNanos: "0",
        spotNegotiationModel: null,
        spotRequestTimeNanos: null,
        triggerEvent: TraderDesktopProtocol.TriggerEvent.QuoteRequest,
        venueSpecifiedOnTheWireTimeMillis: null,
    };

    return produce(inquiry, producer);
};

const makeLeg = (producer: (draft: TraderDesktopProtocol.InquiryLeg) => void): TraderDesktopProtocol.InquiryLeg => {
    const leg: TraderDesktopProtocol.InquiryLeg = {
        askCompositePrice: null,
        benchmarkName: null,
        bidCompositePrice: "1.386",
        clearingFirm: "LCM",
        coverPrice: "1.5",
        currency: "CAD",
        effectiveDate: mapDateInt("28 Apr 22"),
        fixedRate: null,
        floatingRateSpread: null,
        instrumentName: "10Y (SB) vs 3M CDOR",
        isInstrumentCustom: false,
        latestQuotedAskPrice: "1.382",
        latestQuotedBidPrice: "1.38",
        latestQuotedMidPrice: "1.381",
        leg1DayCountConvention: "365",
        leg1PaymentFrequency: "6M",
        leg1RateType: TraderDesktopProtocol.RateType.Fixed,
        leg2DayCountConvention: "ACT/365.FIXED",
        leg2PaymentFrequency: "ACT/365.FIXED",
        leg2RateType: TraderDesktopProtocol.RateType.Floating,
        midCompositePrice: "1.383",
        minPriceIncrement: "0.001",
        negotiatedPrice: null,
        position: 0,
        priceBasisAskPriceSource: null,
        priceBasisAskPriceSpread: null,
        priceBasisBidPriceSource: null,
        priceBasisBidPriceSpread: null,
        priceBasisManualAskRate: null,
        priceBasisManualBidRate: null,
        priceBasisPreTradeMidMarketPrice: null,
        priceType: TraderDesktopProtocol.PriceType.InterestRate,
        pricerAsk: "1.382",
        pricerBid: "1.38",
        pricerMid: "1.381",
        quantity: "25.000",
        quoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus.Cover,
        referenceId: "",
        relativeSettlementDate: null,
        settlementDate: null,
        side: TraderDesktopProtocol.Side.Buy,
        terminationDate: mapDateInt("28 Apr 27"),
        tieBreakPrice: null,
        tradedAway: TraderDesktopProtocol.TradedAway.No,
        customerAskPrice: null,
        customerBidPrice: null,
    };

    return produce(leg, producer);
};

const makeCompressionLeg = (producer: (draft: TraderDesktopProtocol.InquiryLeg) => void): TraderDesktopProtocol.InquiryLeg => {
    const leg: TraderDesktopProtocol.InquiryLeg = {
        side: TraderDesktopProtocol.Side.Buy,
        instrumentName: "CUSTOM 3Y Inflation vs May23 USA CPI-U(3M)",
        quantity: "25.000",
        isInstrumentCustom: false,
        currency: "CAD",
        effectiveDate: mapDateInt("28 Apr 22"),
        terminationDate: mapDateInt("28 Apr 27"),
        minPriceIncrement: "0.001",
        position: 0,
        referenceId: "",
        clearingFirm: "LCM",
        fixedRate: "-10.123456789",
        floatingRateSpread: null,
        negotiatedPrice: null,
        coverPrice: "1.9",
        tradedAway: TraderDesktopProtocol.TradedAway.Yes,
        quoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus.Cover,
        priceType: TraderDesktopProtocol.PriceType.NetPresentValue,
        askCompositePrice: null,
        benchmarkName: null,
        bidCompositePrice: null,
        customerAskPrice: null,
        customerBidPrice: null,
        latestQuotedAskPrice: null,
        latestQuotedBidPrice: null,
        latestQuotedMidPrice: null,
        leg1DayCountConvention: null,
        leg1PaymentFrequency: null,
        leg1RateType: null,
        leg2DayCountConvention: null,
        leg2PaymentFrequency: null,
        leg2RateType: null,
        midCompositePrice: null,
        priceBasisAskPriceSource: null,
        priceBasisAskPriceSpread: null,
        priceBasisBidPriceSource: null,
        priceBasisBidPriceSpread: null,
        priceBasisManualAskRate: null,
        priceBasisManualBidRate: null,
        priceBasisPreTradeMidMarketPrice: null,
        pricerAsk: null,
        pricerBid: null,
        pricerMid: null,
        relativeSettlementDate: null,
        settlementDate: null,
        tieBreakPrice: null,
    };

    return produce(leg, producer);
};
