import React from "react";
import { Box, Text } from "../../atoms";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";

export interface DataTableCellData {
    value: ((isSelectedRow: boolean) => React.ReactNode) | string | number | null;
    cellBackgroundColor?: Theme.Color;
    cellForegroundColor?: Theme.Color;
    cellFontSize?: Theme.FontSizes;
    cellFontWeight?: Theme.FontWeights;
    cellTextTruncate?: boolean;
    cellPadding?: boolean;
    testId?: string;
}

export interface DataTableRowData<RowId extends string | number = string> {
    rowId: RowId;
    testId?: string;
    values: DataTableCellData[];
}

export interface DataTableProps<RowId extends string | number = string> {
    config: {
        columnConfig: DataTableColumnConfig[];
        columnLabelPosition?: "top" | "bottom";
        cellHeight: Theme.SpacingLevels;
        cellBackgroundColor: Theme.Color;
        cellForegroundColor: Theme.Color;
        cellSelectionColor?: Theme.Color;
        cellDisabledSelectionColor?: Theme.Color;
        cellSelectionBackgroundColor?: Theme.Color;
        cellFontSize: Theme.FontSizes;
        cellBorderWidth: Theme.SpacingLevels;
        cellSelectionBorderWidth?: Theme.SpacingLevels;
        animateNewRows?: boolean;
    };
    rows: DataTableRowData<RowId>[];
    selectedRowId?: RowId | null;
    onClickRow?: (rowId: RowId) => void;
    disabledSelection?: boolean;
    emptyTableMessage?: React.ReactNode;
}

export interface DataTableColumnConfig {
    label?: string;
    labelColor?: Theme.Color;
    labelBackgroundColor?: Theme.Color;
    labelSize?: Theme.FontSizes;
    width: Theme.SpacingLevels;
    growable?: true;
}

const Table = styled(Box)``;

const Row = styled(Box)<{ columnConfig: DataTableColumnConfig[]; animateNewRows?: boolean }>`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: ${(props) =>
        props.columnConfig
            .map((config) => (config.growable ? `minmax(${props.theme.spacing[config.width]}, 100%)` : props.theme.spacing[config.width]))
            .join(" ")};
    animation: ${(props) => (props.animateNewRows ? "flashing 100ms 1" : "")};

    @keyframes flashing {
        50% {
            transform: rotateZ(0.5deg);
        }
    }
`;

const SelectedCellBorder = styled(Box).attrs({ rounded: false })<{ border: ThemeUtils.BorderType }>`
    z-index: 10;
    position: absolute;
    top: -${(props) => (props.border === "none" ? "0" : props.theme.spacing[props.border.width])};
    bottom: -${(props) => (props.border === "none" ? "0" : props.theme.spacing[props.border.width])};
    left: -${(props) => (props.border === "none" ? "0" : props.theme.spacing[props.border.width])};
    right: -${(props) => (props.border === "none" ? "0" : props.theme.spacing[props.border.width])};
`;

const Cell = styled(Box).attrs({
    width: "full",
    contentDirection: "row",
    contentAlignment: "center",
    contentJustification: "center",
    textAlign: "center",
})<{ isClickable: boolean; selected: boolean }>`
    position: relative;
    ${(props) => (props.isClickable ? "cursor: pointer;" : "")}
    ${(props) => (props.isClickable ? "user-select: none;" : "")}
`;

const ColumnLabelContainer = styled(Box).attrs<{ columnLabelPosition: "top" | "bottom"; cellHeight: Theme.SpacingLevels }>((props) => ({
    width: "full",
    height: props.cellHeight,
    contentDirection: "row",
    contentAlignment: "center",
    contentJustification: "center",
    padding: "1",
}))<{ columnLabelPosition: "top" | "bottom"; cellHeight: Theme.SpacingLevels }>``;

export const DataTable = <RowId extends string | number = string>({
    config: {
        columnConfig,
        cellBorderWidth,
        cellSelectionBorderWidth,
        columnLabelPosition = "top",
        cellForegroundColor,
        cellBackgroundColor,
        cellSelectionColor,
        cellDisabledSelectionColor,
        cellSelectionBackgroundColor,
        cellHeight,
        cellFontSize,
        animateNewRows,
    },
    rows,
    onClickRow,
    selectedRowId,
    disabledSelection,
    emptyTableMessage,
}: DataTableProps<RowId>): React.ReactNode => {
    const areCellsClickable = !!onClickRow && !disabledSelection;
    const doesHaveColumnLabel = columnConfig.some((col) => !!col.label);
    const noCellBorder: ThemeUtils.BorderType = { width: cellBorderWidth, color: { color: "transparent" } };
    const cellBorder: ThemeUtils.BorderType = { width: cellBorderWidth, color: cellForegroundColor };
    const selectedCellBorder: ThemeUtils.BorderType = {
        width: cellSelectionBorderWidth ?? "0.5",
        color: (disabledSelection ? cellDisabledSelectionColor : cellSelectionColor) ?? { color: "white" },
    };

    const renderColumnLabels = (): React.ReactNode => {
        if (!doesHaveColumnLabel) {
            return null;
        }

        return (
            <Row columnConfig={columnConfig}>
                {columnConfig.map((col, idx) => (
                    <ColumnLabelContainer
                        key={idx}
                        columnLabelPosition={columnLabelPosition}
                        borderL={idx === 0 ? cellBorder : noCellBorder}
                        borderT={cellBorder}
                        borderR={cellBorder}
                        borderB={rows.length === 0 ? cellBorder : noCellBorder}
                        cellHeight={cellHeight}
                        backgroundColor={col.labelBackgroundColor ?? cellBackgroundColor}
                    >
                        <Text textColor={col.labelColor ?? cellForegroundColor} size={col.labelSize} weight="bold">
                            {col.label ? col.label : ""}
                        </Text>
                    </ColumnLabelContainer>
                ))}
            </Row>
        );
    };

    return (
        <Table>
            {columnLabelPosition === "top" && renderColumnLabels()}
            {rows.length === 0 && (emptyTableMessage ?? null)}
            {rows.map((row, rowIndex) => {
                const isSelected = selectedRowId !== null && selectedRowId === row.rowId;
                const isClickable = areCellsClickable;

                return (
                    <Row
                        key={row.rowId}
                        animateNewRows={animateNewRows}
                        columnConfig={columnConfig}
                        data-testid={row.testId ?? `row-${String(row.rowId)}`}
                        data-is-selected={String(isSelected)}
                        data-is-clickable={String(isClickable)}
                    >
                        {row.values.map((cell, columnIndex) => {
                            const foregroundColor = cell.cellForegroundColor ?? cellForegroundColor;
                            const backgroundColor =
                                cell.cellBackgroundColor ?? (isSelected ? cellSelectionBackgroundColor : null) ?? cellBackgroundColor;
                            const fontSize = cell.cellFontSize ?? cellFontSize;
                            const fontWeight = cell.cellFontWeight ?? (isSelected ? "black" : "normal");
                            const onClick = isClickable
                                ? () => {
                                      onClickRow(row.rowId);
                                  }
                                : undefined;

                            const cellTitle = cell.cellTextTruncate && typeof cell.value === "string" ? cell.value : undefined;
                            const cellPadding = cell.cellPadding ?? true;

                            return (
                                <Cell
                                    data-is-selected={String(isSelected)}
                                    data-is-clickable={String(isClickable)}
                                    key={`cell-${rowIndex}-${columnIndex}`}
                                    borderL={columnIndex === 0 ? cellBorder : noCellBorder}
                                    borderT={rowIndex === 0 ? cellBorder : noCellBorder}
                                    borderR={cellBorder}
                                    borderB={cellBorder}
                                    paddingL={cellPadding ? "2" : undefined}
                                    paddingR={cellPadding ? "2" : undefined}
                                    backgroundColor={backgroundColor}
                                    isClickable={isClickable}
                                    selected={isSelected}
                                    onClick={onClick}
                                    height={cellHeight}
                                    data-testid={cell.testId ?? `cell-${rowIndex}-${columnIndex}`}
                                    title={cellTitle}
                                >
                                    {isSelected && <SelectedCellBorder border={selectedCellBorder} />}
                                    {typeof cell.value === "string" || typeof cell.value === "number" || cell.value === null ? (
                                        <Text
                                            textColor={foregroundColor}
                                            size={fontSize}
                                            weight={fontWeight}
                                            truncateWithEllipsis={cell.cellTextTruncate}
                                        >
                                            {cell.value ?? "-"}
                                        </Text>
                                    ) : (
                                        cell.value(isSelected)
                                    )}
                                </Cell>
                            );
                        })}
                    </Row>
                );
            })}
            {columnLabelPosition === "bottom" && renderColumnLabels()}
        </Table>
    );
};
