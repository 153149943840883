import { useCallback, useEffect, useRef } from "react";
import Sound from "./audio/poker-chips-daniel_simon.mp3";
import type { TraderDesktopElectronApi } from "@transficc/trader-desktop-electron-api";
import { EpochClock } from "@transficc/infrastructure";
import log from "../logger/globalLogger";

const usePlaySound = (clock: EpochClock): ((volumeIsOn: boolean, shouldLog: boolean) => void) => {
    const timeOfLastPlayedSoundMs = useRef(0);

    return useCallback(
        (volumeIsOn: boolean, shouldLog: boolean): void => {
            if (volumeIsOn && timeOfLastPlayedSoundMs.current < clock.getCurrentTimeMs() + 2000) {
                timeOfLastPlayedSoundMs.current = clock.getCurrentTimeMs();
                new Audio(Sound).play().catch(() => {});
                if (shouldLog) {
                    log.info("useNewRequestEffect - New ticket, playing sound");
                }
            }
        },
        [clock],
    );
};

interface FilterableInquiry<InquiryState> {
    isFromSnapshot: boolean;
    ticketId: number;
    state: InquiryState;
}

export function useNewRequestEffect<InquiryState>(
    mappedInquiries: FilterableInquiry<InquiryState>[],
    electronApi: TraderDesktopElectronApi,
    clock: EpochClock,
    volumeIsOn: boolean,
    newStates: InquiryState[],
    popup: boolean,
): void {
    const previouslySeenNewRequestTicketIds = useRef([] as number[]);

    const maybePlaySound = usePlaySound(clock);

    useEffect(() => {
        // This is very inefficient as its iterating through every inquiry every time an inquiry comes in. O(n) and it makes the desktop app much slower - we have seen this issue before.
        const newRequestTicketIds = mappedInquiries
            .filter((i) => newStates.some((state) => state === i.state))
            .filter((i) => !i.isFromSnapshot) // if the request has come from a snapshot then these inquiries arrived before the user opened the window, so we don't want to play a sound
            .map((i) => i.ticketId)
            .sort();

        const unseenNewRequests = newRequestTicketIds.filter((ticketId) => !previouslySeenNewRequestTicketIds.current.includes(ticketId));

        const hasNewRequest = unseenNewRequests.length > 0;

        if (hasNewRequest) {
            if (popup) {
                log.info("useNewRequestEffect - New ticket, moving to top " + unseenNewRequests.join(","));
                electronApi.moveTop();
            }
            maybePlaySound(volumeIsOn, popup);
            previouslySeenNewRequestTicketIds.current = newRequestTicketIds;
        }
        // NOTE: Changing volumeIsOn should not cause this to run, purposefully
        // We capture the value of the boolean at the point where we have a new inquiry
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electronApi, mappedInquiries]);
}
