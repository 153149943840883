import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkConfig } from "../thunks/ThunkConfig";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyThunkDispatch = ThunkDispatch<never, ThunkConfig, any>;

// don't use useThunkDispatch directly in application code, instead hide it in a hook
// and expose a dispatch function which takes a type of actions for your specific purpose
export const useThunkDispatch = (): AnyThunkDispatch => useDispatch<AnyThunkDispatch>();
