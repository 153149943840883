import { Box, Icon, IconNames, Text } from "@transficc/components";
import styled from "styled-components";

const IconBox = styled(Box)`
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 1.5rem;
`;

const ErrorMessageText = styled(Text)`
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
`;

export interface ErrorMessageBoxProps {
    title: string;
    text: string;
    severity: "danger" | "warning";
    dismissable: boolean;
    onClose: () => void;
}

const resolveFontSize = (length: number): Theme.FontSizes => {
    if (length > 53) {
        return "sm";
    } else {
        return "base";
    }
};

export const ErrorMessageBox: React.FC<ErrorMessageBoxProps> = ({ title, text, severity, dismissable, onClose }) => {
    const textToRender = `${title}: ${text}`;
    const fontSize = resolveFontSize(textToRender.length);
    return (
        <Box
            contentDirection="row"
            contentJustification="space-between"
            contentAlignment="center"
            backgroundColor={{ color: "gray", level: "300" }}
            paddingT="1"
            paddingL="2"
            data-testid="error-message"
        >
            <IconBox marginR="2">
                <Icon
                    name={IconNames.Warning}
                    size="5"
                    iconColor={severity === "warning" ? { color: "gold", level: "600" } : { color: severity }}
                />
            </IconBox>
            <ErrorMessageText
                size={fontSize}
                data-testid="error-message-text"
                textColor={{ color: "black" }}
                truncateWithEllipsis
                title={text}
            >
                {textToRender}
            </ErrorMessageText>
            {dismissable && (
                <IconBox marginL="2">
                    <Icon
                        name={IconNames.Cross}
                        size="6"
                        iconColor={{ color: "black" }}
                        onClick={onClose}
                        data-testid="error-message-close"
                    />
                </IconBox>
            )}
        </Box>
    );
};
