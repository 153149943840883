import styled from "styled-components";
import { Box, BoxProps, ConnectionStatus, ConnectionStatusProps, Icon, IconNames } from "@transficc/components";
import { TradingStatusToggle } from "../trading-status-toggle/trading-status-toggle";

const BottomBarContainer = styled(Box).attrs(
    (): BoxProps => ({
        width: "full",
        height: "12",
        paddingL: "4",
        paddingR: "4",
        backgroundColor: { color: "cyan", level: "700" },
        contentDirection: "row",
        contentAlignment: "center",
    }),
)`
    & > *:not(:last-child) {
        margin-right: ${(props) => props.theme.spacing["4"]};
    }

    & > *:last-child {
        margin-left: auto;
    }
`;

export interface ActiveInquiriesBottomBarProps {
    onVolumeToggle: () => unknown;
    volumeIsOn: boolean;
    connectionStatus: ConnectionStatusProps["connectionStatus"];
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
}

export const ActiveInquiriesBottomBar: React.FC<ActiveInquiriesBottomBarProps> = ({
    onVolumeToggle,
    volumeIsOn,
    connectionStatus,
    tradingStatus,
    serverTradingStatus,
    onTradingStatusChanged,
}) => {
    return (
        <BottomBarContainer>
            <Box marginR="auto">
                <Icon
                    name={volumeIsOn ? IconNames.VolumeOn : IconNames.VolumeOff}
                    iconColor={{ color: "white" }}
                    onClick={onVolumeToggle}
                    size={"6"}
                />
            </Box>
            <Box marginR="auto" marginL="auto">
                <TradingStatusToggle
                    onTradingStatusChanged={onTradingStatusChanged}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                    connectionStatus={connectionStatus}
                />
            </Box>
            <Box marginL="auto">
                <ConnectionStatus connectionStatus={connectionStatus} size={"6"} />
            </Box>
        </BottomBarContainer>
    );
};
