// https://github.com/microsoft/TypeScript/issues/42873
import "reselect";
import "redux";
import "redux-thunk";

import { configureStore } from "@reduxjs/toolkit";
import { SessionTransport } from "@transficc/trader-desktop-application-context";
import { EpochClock } from "@transficc/infrastructure";

import { sessionReducer } from "./slices/session/SessionSlice";
import { makeIrsListenerMiddleware, irsReducer } from "@transficc/trader-desktop-irs";
import { creditReducer } from "@transficc/trader-desktop-credit";
import { loadingStatusReducer } from "./slices/LoadingStatusSlice"; // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createNewStore(sessionTransport: SessionTransport, clock: EpochClock) {
    // this function is secretly returning a store with a RootState of type any

    const irsListenerMiddleware = makeIrsListenerMiddleware(sessionTransport, clock);

    return configureStore({
        reducer: {
            irs: irsReducer,
            credit: creditReducer,
            session: sessionReducer,
            loadingStatus: loadingStatusReducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: { warnAfter: 200 },
                // We have stuff in our store that are not serializable (e.g. BigDecimals). Serializing / deserializing them adds an extra overhead that we don't want to pay.
                // Disabling this check is a trade-off between performance and debugability that we are willing to make.
                // At the time of writing this, our main concern is performance. For debugging, we have enough signals, and we are in close contact with the users of trader desktop.
                // We will probably need to revisit our decision in the future and see if that tradeoff decision still makes sense.
                serializableCheck: false,
                thunk: {
                    extraArgument: { sessionTransport, clock },
                },
            })
                // Add the listener middleware to the store.
                // NOTE: Since this can receive actions with functions inside,
                // it should go before the serializability check middleware (although we have that turned off!)
                .prepend(irsListenerMiddleware.middleware),
    });
}
