import React from "react";
import { backgroundColorFor, DisplayState, displayTextFor, foregroundColorFor } from "../irs-domain";
import { DecimalNumber } from "@transficc/infrastructure";
import { Box, NumberInput, NumberInputTitleLabelConfig } from "@transficc/components";

export interface PackageControlAndPricesProps {
    config: {
        packageTitleLabel?: NumberInputTitleLabelConfig;
    };
    model: {
        quoted: DecimalNumber | null;
        aqLevel: DecimalNumber | null;
        levelValue: DecimalNumber | null;
        levelDisabled: boolean;
        levelSelected: boolean;
        levelDisplayState: DisplayState;
        levelInvalid: boolean;
        spreadValue: DecimalNumber | null;
        spreadDisabled: boolean;
        increment: DecimalNumber;
        selectedTickSize: DecimalNumber;
        inputForegroundColor?: Theme.Color;
        inputBackgroundColor?: Theme.Color;
        isMac: boolean;
    };
    callbacks: {
        onLevelValueChange: (value: DecimalNumber) => void;
        onLevelDirtyChange: (isDirty: boolean) => void;
        onSpreadValueChange: (value: DecimalNumber) => void;
        onSpreadDirtyChange: (isDirty: boolean) => void;
    };
}

export const PackageControlAndPrices: React.FC<PackageControlAndPricesProps> = ({ config: { packageTitleLabel }, model, callbacks }) => {
    const packageTitle = model.isMac ? "Package Fee" : "Package Level";
    const toolTip = model.isMac ? "Package fee (net cost per notional)" : "Package spread (bps)";
    return (
        <>
            <NumberInput
                config={{
                    titleText: packageTitle,
                    titleTextSelector: packageTitle,
                    showTitleText: false,
                    size: "medium",
                    initialDisplayText: "",
                    snapToMultiplesOf: model.increment,
                    titleLabel: packageTitleLabel,
                    invalidText: "Stale Price",
                    toolTip,
                }}
                model={{
                    value: model.levelValue,
                    disabled: model.levelDisabled,
                    selected: model.levelSelected,
                    displayType: model.levelDisplayState === DisplayState.Valid ? "input" : "box",
                    boxDisplayText: displayTextFor(model.levelDisplayState),
                    boxForegroundColor: foregroundColorFor(model.levelDisplayState),
                    boxBackgroundColor: backgroundColorFor(model.levelDisplayState),
                    increment: model.selectedTickSize,
                    inputBackgroundColor: model.inputBackgroundColor,
                    inputForegroundColor: model.inputForegroundColor,
                    invalid: model.levelInvalid,
                }}
                callbacks={{
                    onValueChange: callbacks.onLevelValueChange,
                    onDirtyChange: callbacks.onLevelDirtyChange,
                }}
            />
            <Box marginT="2" marginB="4">
                <NumberInput
                    config={{
                        titleText: "Spread",
                        titleTextSelector: "Package Spread",
                        showTitleText: true,
                        size: "small",
                        initialDisplayText: "-",
                        snapToMultiplesOf: model.increment,
                    }}
                    model={{
                        value: model.spreadValue,
                        disabled: model.spreadDisabled,
                        selected: false,
                        displayType: "input",
                        increment: model.selectedTickSize,
                        invalid: false,
                    }}
                    callbacks={{
                        onValueChange: callbacks.onSpreadValueChange,
                        onDirtyChange: callbacks.onSpreadDirtyChange,
                    }}
                />
            </Box>
        </>
    );
};
