import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { Draft, Immutable, produce } from "immer";
import * as IRSDomain from "../irs-domain";
import {
    DisplayState,
    InquirySide,
    invalidPricerStates,
    IRSInquiry,
    IRSLeg,
    mapPricerStateToDisplayState,
    PriceType,
    SelectedDriver,
} from "../irs-domain";
import { basisPointsToDecimal, decimalToBasisPoints, nullableRoundToIncrement, roundToIncrement } from "../irs-domain/scaling";
import { computeManualPtmmValue, computePtmmValue } from "../irs-domain/ptmm";
import { getSelectedDriver } from "../irs-domain/price-source";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import {
    CloseActionFailureAction,
    CurveLegPosition,
    IRSCurveReducerAction,
    IRSCurveReducerState,
    LegSideState,
    LegState,
    QuotingSide,
} from "./irs-curve-reducer-types";

const RIGHT_LEG_POSITION = 1;
const LEFT_LEG_POSITION = 0;

const initialLegSideState: LegSideState = {
    customerRateValue: null,
    manualRateValue: null,
    spreadValue: null,
    selectedDriver: null,
};

export const curveReducerInitialState: IRSCurveReducerState = {
    inquiry: null,
    autoXModeChangeReason: null,
    legs: [
        {
            position: 0,
            ptmmValue: null,
            manualPtmmValue: null,
            pay: initialLegSideState,
            rcv: initialLegSideState,
        },
        {
            position: 1,
            ptmmValue: null,
            manualPtmmValue: null,
            pay: initialLegSideState,
            rcv: initialLegSideState,
        },
    ],
    priceBasisSequenceNumber: 0,
    isDirty: false,
    shouldPublishPriceBasisUpdate: false,
    latestActionFailure: null,
    closedActionFailures: [],
};

function getSelectedDriverRowCol(legState: Immutable<LegSideState>): SelectedDriver | undefined {
    return legState.selectedDriver
        ? {
              rowNo: legState.selectedDriver.rowNo,
              columnNo: legState.selectedDriver.columnNo,
          }
        : undefined;
}

function getSelectedDriverValue(legSideState: Immutable<LegSideState>, irsLeg: IRSDomain.IRSLeg<"curve">): string | null {
    const driver = getSelectedDriverRowCol(legSideState);
    return getDriverValue(irsLeg, driver);
}

function getDriverValue(inquiryLeg: Draft<IRSDomain.IRSLeg<"curve">>, driver?: SelectedDriver): string | null {
    if (inquiryLeg && driver) {
        const columnNo = driver.columnNo;
        const rowNo = driver.rowNo;

        if (rowNo === 0) {
            return inquiryLeg.lastQuotedPrices[columnNo] ?? null;
        } else if (rowNo === 1) {
            return inquiryLeg.autoQuotePrices[columnNo] ?? null;
        } else if (rowNo === 2) {
            return inquiryLeg.venuePrices[columnNo] ?? null;
        }
    }
    return null;
}

function setCustomerRateFromDriverAndSpreadForLegSide(
    draft: Draft<IRSCurveReducerState>,
    legPosition: CurveLegPosition,
    legSideState: Draft<LegSideState>,
): void {
    if (!draft.inquiry) {
        return;
    }

    const driverValue = getSelectedDriverValue(legSideState, draft.inquiry.legs[legPosition]);

    if (draft.inquiry && draft.inquiry.state === IRSDomain.InquiryState.Done) {
        const tradedPrice = draft.inquiry.legs[legPosition].negotiatedPrice;
        if (tradedPrice !== null) {
            legSideState.customerRateValue = new ImmutableDecimalNumber(tradedPrice);
            if (driverValue) {
                if (draft.inquiry.legs[legPosition].priceType === PriceType.NetPresentValue) {
                    legSideState.spreadValue = new ImmutableDecimalNumber(tradedPrice).subtract(new ImmutableDecimalNumber(driverValue));
                } else {
                    legSideState.spreadValue = decimalToBasisPoints(
                        new ImmutableDecimalNumber(tradedPrice).subtract(new ImmutableDecimalNumber(driverValue)),
                    );
                }
            } else {
                legSideState.spreadValue = null;
            }
            return;
        }
    }

    if (legSideState.selectedDriver !== null && driverValue === null) {
        legSideState.customerRateValue = null;
        legSideState.spreadValue = null;
        legSideState.manualRateValue = null;
    }

    if (driverValue !== null) {
        if (legSideState.spreadValue === null) {
            legSideState.spreadValue = new ImmutableDecimalNumber(0);
        }

        const newCustomerRate =
            draft.inquiry.legs[legPosition].priceType === PriceType.NetPresentValue
                ? new ImmutableDecimalNumber(driverValue).add(legSideState.spreadValue)
                : new ImmutableDecimalNumber(driverValue).add(legSideState.spreadValue.divideBy(100));
        const increment = new ImmutableDecimalNumber(draft.inquiry.legs[legPosition].minPriceIncrement);
        legSideState.customerRateValue = roundToIncrement(newCustomerRate, increment);
        legSideState.manualRateValue = null;
    } else {
        legSideState.manualRateValue = legSideState.customerRateValue;
    }
}

function setCustomerRateFromDriverAndSpreadForLeg(draft: Draft<IRSCurveReducerState>, leg: Draft<LegState>): void {
    if (!draft.inquiry) {
        return;
    }

    setCustomerRateFromDriverAndSpreadForLegSide(draft, leg.position, draft.legs[leg.position].pay);
    setCustomerRateFromDriverAndSpreadForLegSide(draft, leg.position, draft.legs[leg.position].rcv);
}

function updatePtmmForLeg(draft: Draft<IRSCurveReducerState>, leg: LegState, legIndex: CurveLegPosition): void {
    if (!draft.inquiry) {
        return;
    }

    const minPriceIncrement = new ImmutableDecimalNumber(draft.inquiry.legs[legIndex].minPriceIncrement);
    const aqMidPrice = draft.inquiry.legs[legIndex].autoQuotePrices[1];

    leg.ptmmValue = nullableRoundToIncrement(
        computePtmmValue(leg.ptmmValue, draft.inquiry.autoQuotePriceState, aqMidPrice),
        minPriceIncrement,
    );
    leg.manualPtmmValue = nullableRoundToIncrement(
        computeManualPtmmValue(leg.ptmmValue, draft.inquiry.autoQuotePriceState, aqMidPrice),
        minPriceIncrement,
    );
}

function resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferent(
    draft: Draft<IRSCurveReducerState>,
    position: CurveLegPosition,
    inquiry: IRSDomain.IRSInquiry<"curve">,
): void {
    if (!draft.inquiry) {
        return;
    }

    resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferentForSide(
        draft.inquiry.legs[position],
        inquiry.legs[position],
        draft.legs[position].pay,
    );
    resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferentForSide(
        draft.inquiry.legs[position],
        inquiry.legs[position],
        draft.legs[position].rcv,
    );
}

function resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferentForSide(
    legState: Draft<IRSLeg<"curve">>,
    legUpdate: IRSLeg<"curve">,
    legSideState: Draft<LegSideState>,
): void {
    const isALastQuoteDriverSelected = legSideState.selectedDriver?.rowNo === 0;
    const lastQuoteDriverSelectedColumn = legSideState.selectedDriver?.columnNo ?? -1;
    if (isALastQuoteDriverSelected && lastQuoteDriverSelectedColumn >= 0) {
        const previousLastSelectedQuotePrice = legState.lastQuotedPrices[lastQuoteDriverSelectedColumn];
        const newLastSelectedQuotePrice = legUpdate.lastQuotedPrices[lastQuoteDriverSelectedColumn];
        if (previousLastSelectedQuotePrice !== newLastSelectedQuotePrice) {
            legSideState.spreadValue = null;
        }
    }
}

function deselectDriverForQuotingSide(draft: Draft<IRSCurveReducerState>, quotingSide: QuotingSide, legPosition: CurveLegPosition): void {
    if (!draft.inquiry) {
        return;
    }

    const oppositeLegSide = legPosition === 0 ? 1 : 0;
    if (quotingSide === "PAY") {
        draft.legs[legPosition].pay.manualRateValue = draft.legs[legPosition].pay.customerRateValue;
        draft.legs[legPosition].pay.selectedDriver = null;
        draft.legs[legPosition].pay.spreadValue = null;
        draft.legs[oppositeLegSide].rcv.manualRateValue = draft.legs[oppositeLegSide].rcv.customerRateValue;
        draft.legs[oppositeLegSide].rcv.selectedDriver = null;
        draft.legs[oppositeLegSide].rcv.spreadValue = null;
    } else if (quotingSide === "RCV") {
        draft.legs[legPosition].rcv.manualRateValue = draft.legs[legPosition].rcv.customerRateValue;
        draft.legs[legPosition].rcv.selectedDriver = null;
        draft.legs[legPosition].rcv.spreadValue = null;
        draft.legs[oppositeLegSide].pay.manualRateValue = draft.legs[oppositeLegSide].pay.customerRateValue;
        draft.legs[oppositeLegSide].pay.selectedDriver = null;
        draft.legs[oppositeLegSide].pay.spreadValue = null;
    }
}

function selectInitialDrivers(draft: Draft<IRSCurveReducerState>, inquiry: IRSDomain.IRSInquiry<"curve">): void {
    const leg0DisplayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);
    const leg1DisplayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);
    const shouldSelectLeg0PayLeg1Rcv =
        (leg0DisplayState !== DisplayState.Valid || inquiry.legs[0].autoQuotePrices[0] !== null) &&
        (leg1DisplayState !== DisplayState.Valid || inquiry.legs[1].autoQuotePrices[2] !== null);
    draft.legs[0].pay.selectedDriver =
        shouldSelectLeg0PayLeg1Rcv && [InquirySide.Undisclosed, InquirySide.Buy].includes(inquiry.legs[0].side)
            ? { rowNo: 1, columnNo: 0 }
            : null;
    draft.legs[1].rcv.selectedDriver =
        shouldSelectLeg0PayLeg1Rcv && [InquirySide.Undisclosed, InquirySide.Sell].includes(inquiry.legs[1].side)
            ? { rowNo: 1, columnNo: 2 }
            : null;

    const shouldSelectLeg0RcvLeg1Pay =
        (leg0DisplayState !== DisplayState.Valid || inquiry.legs[0].autoQuotePrices[2] !== null) &&
        (leg1DisplayState !== DisplayState.Valid || inquiry.legs[1].autoQuotePrices[0] !== null);
    draft.legs[0].rcv.selectedDriver =
        shouldSelectLeg0RcvLeg1Pay && [InquirySide.Undisclosed, InquirySide.Sell].includes(inquiry.legs[0].side)
            ? { rowNo: 1, columnNo: 2 }
            : null;
    draft.legs[1].pay.selectedDriver =
        shouldSelectLeg0RcvLeg1Pay && [InquirySide.Undisclosed, InquirySide.Buy].includes(inquiry.legs[1].side)
            ? { rowNo: 1, columnNo: 0 }
            : null;
}

function onUpdatePackageSpreadValue(draft: Draft<IRSCurveReducerState>, spreadValue: DecimalNumber, quotingSide: QuotingSide): void {
    if (!draft.inquiry) {
        return;
    }
    const leftLegSideState = quotingSide === "PAY" ? draft.legs[LEFT_LEG_POSITION].rcv : draft.legs[LEFT_LEG_POSITION].pay;
    const rightLegSideState = quotingSide === "PAY" ? draft.legs[RIGHT_LEG_POSITION].pay : draft.legs[RIGHT_LEG_POSITION].rcv;
    if (
        draft.inquiry?.legs[LEFT_LEG_POSITION].priceType === PriceType.NetPresentValue &&
        draft.inquiry?.legs[RIGHT_LEG_POSITION].priceType === PriceType.NetPresentValue
    ) {
        rightLegSideState.spreadValue = spreadValue.subtract(leftLegSideState.spreadValue ?? new ImmutableDecimalNumber(0));
    } else {
        rightLegSideState.spreadValue = spreadValue.add(leftLegSideState.spreadValue ?? new ImmutableDecimalNumber(0));
    }

    setCustomerRateFromDriverAndSpreadForLeg(draft, draft.legs[RIGHT_LEG_POSITION]);
}

function onUpdatePackageLevelValue(draft: Draft<IRSCurveReducerState>, packageLevel: DecimalNumber, quotingSide: QuotingSide): void {
    if (!draft.inquiry) {
        return;
    }

    const isMac =
        draft.inquiry.legs[0].priceType === PriceType.NetPresentValue && draft.inquiry.legs[1].priceType === PriceType.NetPresentValue;

    deselectDriverForQuotingSide(draft, quotingSide, 1);

    const leftLegCustomerRate =
        quotingSide === "PAY" ? draft.legs[LEFT_LEG_POSITION].rcv.customerRateValue : draft.legs[LEFT_LEG_POSITION].pay.customerRateValue;
    const rightLegSide = quotingSide === "PAY" ? draft.legs[RIGHT_LEG_POSITION].pay : draft.legs[RIGHT_LEG_POSITION].rcv;
    if (leftLegCustomerRate) {
        if (isMac) {
            rightLegSide.customerRateValue = packageLevel.subtract(leftLegCustomerRate);
            rightLegSide.manualRateValue = packageLevel.subtract(leftLegCustomerRate);
        } else {
            rightLegSide.customerRateValue = leftLegCustomerRate.add(basisPointsToDecimal(packageLevel));
            rightLegSide.manualRateValue = leftLegCustomerRate.add(basisPointsToDecimal(packageLevel));
        }
    }
}

function onSelectDriver(
    draft: Draft<IRSCurveReducerState>,
    driver: SelectedDriver,
    legPosition: CurveLegPosition,
    quotingSide: QuotingSide,
): void {
    if (!draft.inquiry) {
        return;
    }

    const legSideState = quotingSide === "PAY" ? draft.legs[legPosition].pay : draft.legs[legPosition].rcv;

    const otherLegPosition = legPosition === 0 ? 1 : 0;
    const otherDriver: SelectedDriver = {
        rowNo: driver.rowNo,
        columnNo: 2 - driver.columnNo,
    };

    const otherQuotingSide = quotingSide === "PAY" ? "RCV" : "PAY";
    const otherLegStateSide = otherQuotingSide === "PAY" ? draft.legs[otherLegPosition].pay : draft.legs[otherLegPosition].rcv;

    const displayState = driver.rowNo !== 1 ? DisplayState.Valid : mapPricerStateToDisplayState(draft.inquiry.autoQuotePriceState);
    const otherDisplayState =
        otherDriver.rowNo !== 1 ? DisplayState.Valid : mapPricerStateToDisplayState(draft.inquiry.autoQuotePriceState);

    const driverValue = getDriverValue(draft.inquiry.legs[legPosition], driver);
    const otherDriverValue = getDriverValue(draft.inquiry.legs[otherLegPosition], otherDriver);

    const shouldSelectDriver = displayState !== DisplayState.Valid || driverValue !== null;
    const shouldSelectOtherDriver = otherDisplayState !== DisplayState.Valid || otherDriverValue !== null;

    const otherLegDriverIsAlreadySelected = getSelectedDriverRowCol(otherLegStateSide) !== undefined;
    if (shouldSelectDriver && (shouldSelectOtherDriver || otherLegDriverIsAlreadySelected)) {
        legSideState.selectedDriver = driver;
        legSideState.spreadValue = new ImmutableDecimalNumber(0);
        setCustomerRateFromDriverAndSpreadForLeg(draft, draft.legs[legPosition]);
    }

    if (shouldSelectDriver && (shouldSelectOtherDriver || otherLegDriverIsAlreadySelected) && !otherLegDriverIsAlreadySelected) {
        otherLegStateSide.selectedDriver = otherDriver;
        otherLegStateSide.spreadValue = new ImmutableDecimalNumber(0);
        setCustomerRateFromDriverAndSpreadForLeg(draft, draft.legs[otherLegPosition]);
    }
}

function onUpdatePtmmValue(draft: Draft<IRSCurveReducerState>, ptmmValue: DecimalNumber, legPosition: CurveLegPosition): void {
    draft.legs[legPosition].ptmmValue = ptmmValue;
    draft.legs[legPosition].manualPtmmValue = ptmmValue;
}

function onUpdateSpreadValue(
    draft: Draft<IRSCurveReducerState>,
    spreadValue: DecimalNumber | null,
    legPosition: CurveLegPosition,
    quotingSide: QuotingSide,
): void {
    if (!draft.inquiry) {
        return;
    }

    const legState = draft.legs[legPosition];
    const legSideState = quotingSide === "PAY" ? legState.pay : legState.rcv;
    legSideState.spreadValue = spreadValue;
    const driverValue = getSelectedDriverValue(legSideState, draft.inquiry.legs[legPosition]);

    if (!driverValue) {
        legSideState.customerRateValue = null;
        legSideState.manualRateValue = null;
        legSideState.spreadValue = null;
    } else {
        setCustomerRateFromDriverAndSpreadForLeg(draft, draft.legs[legPosition]);
    }
}

function onUpdateCustomerRateValue(
    draft: Draft<IRSCurveReducerState>,
    newValue: DecimalNumber,
    legPosition: CurveLegPosition,
    quotingSide: QuotingSide,
): void {
    if (!draft.inquiry) {
        return;
    }

    const legState = draft.legs[legPosition];
    const legStateSide = quotingSide === "PAY" ? legState.pay : legState.rcv;

    legStateSide.customerRateValue = newValue;
    legStateSide.manualRateValue = newValue;
    deselectDriverForQuotingSide(draft, quotingSide, legPosition);
}

const updatePriceBasisSequenceNumber = (draft: Draft<IRSCurveReducerState>, inquiry: IRSInquiry<"curve">): void => {
    const actionPriceBasisEventSequence = inquiry.priceBasis.eventSequence;
    if (actionPriceBasisEventSequence && actionPriceBasisEventSequence > draft.priceBasisSequenceNumber) {
        draft.priceBasisSequenceNumber = actionPriceBasisEventSequence;
    }
};

function updateInquiryOnPriceBasis(draft: Draft<IRSCurveReducerState>, inquiry: IRSInquiry<"curve">): void {
    const serverSequenceNumber = inquiry.priceBasis.eventSequence ?? 0;
    const screenSequenceNumber = draft.priceBasisSequenceNumber;
    if (serverSequenceNumber <= screenSequenceNumber) {
        return;
    }

    function updatePriceBasisForLeg(legPosition: CurveLegPosition): void {
        const priceBasisLeg = inquiry.legs[legPosition].priceBasisLeg;

        if (priceBasisLeg.ptmm !== null) {
            onUpdatePtmmValue(draft, new ImmutableDecimalNumber(priceBasisLeg.ptmm), legPosition);
        }

        function updatePriceBasisForSide(
            quotingSide: QuotingSide,
            priceSource: ActionPublisher.PriceSource | null,
            priceSpread: string | null,
            manualPrice: string | null,
        ): void {
            const selectedDriver = getSelectedDriver(priceSource);

            if (selectedDriver) {
                onSelectDriver(draft, selectedDriver, legPosition, quotingSide);
            } else {
                deselectDriverForQuotingSide(draft, quotingSide, legPosition);
            }

            onUpdateSpreadValue(draft, priceSpread ? new ImmutableDecimalNumber(priceSpread) : null, legPosition, quotingSide);

            if (manualPrice) {
                onUpdateCustomerRateValue(draft, new ImmutableDecimalNumber(manualPrice), legPosition, quotingSide);
            }
        }

        updatePriceBasisForSide("PAY", priceBasisLeg.bidPriceSource, priceBasisLeg.bidPriceSpread, priceBasisLeg.manualBidPrice);
        updatePriceBasisForSide("RCV", priceBasisLeg.askPriceSource, priceBasisLeg.askPriceSpread, priceBasisLeg.manualAskPrice);
    }

    if (!draft.inquiry) {
        return;
    }

    updatePriceBasisForLeg(0);
    updatePriceBasisForLeg(1);
    updatePriceBasisSequenceNumber(draft, inquiry);
}

function onUpdateInquiry(draft: Draft<IRSCurveReducerState>, inquiry: IRSInquiry<"curve">, userId: number): void {
    if (draft.inquiry === null) {
        selectInitialDrivers(draft, inquiry);
    } else {
        resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferent(draft, 0, inquiry);
        resetSpreadIfDrivenByLastQuotedAndNewQuotedIsDifferent(draft, 1, inquiry);
    }
    draft.inquiry = inquiry;
    draft.autoXModeChangeReason = inquiry.autoXModeChangeReason;

    const firstLeg = draft.legs[0];
    const secondLeg = draft.legs[1];

    updatePtmmForLeg(draft, firstLeg, 0);
    updatePtmmForLeg(draft, secondLeg, 1);

    setCustomerRateFromDriverAndSpreadForLeg(draft, firstLeg);
    setCustomerRateFromDriverAndSpreadForLeg(draft, secondLeg);

    const resetRateIfDrivenByPricerAndPricerStateIsBad = (legPosition: CurveLegPosition): void => {
        const isPriceDrivenByAutoQuoter = (legSideState: Immutable<LegSideState>): boolean =>
            getSelectedDriverRowCol(legSideState)?.rowNo === 1;

        const autoQuotePriceState = inquiry.autoQuotePriceState;
        if (invalidPricerStates.some((state) => state === autoQuotePriceState) && isPriceDrivenByAutoQuoter(draft.legs[legPosition].pay)) {
            draft.legs[legPosition].pay.customerRateValue = null;
            draft.legs[legPosition].pay.manualRateValue = null;
        }
        if (invalidPricerStates.some((state) => state === autoQuotePriceState) && isPriceDrivenByAutoQuoter(draft.legs[legPosition].rcv)) {
            draft.legs[legPosition].rcv.customerRateValue = null;
            draft.legs[legPosition].rcv.manualRateValue = null;
        }
    };

    resetRateIfDrivenByPricerAndPricerStateIsBad(0);
    resetRateIfDrivenByPricerAndPricerStateIsBad(1);

    const lastFailure = draft.inquiry.actionFailures[draft.inquiry.actionFailures.length - 1];
    if (lastFailure && lastFailure.userId === userId) {
        draft.latestActionFailure = {
            actionId: lastFailure.actionId,
            actionType: lastFailure.actionType,
            reason: lastFailure.reason,
            source: lastFailure.source,
            userId: lastFailure.userId,
            closed: draft.closedActionFailures.includes(lastFailure.actionId),
        };
    }

    updateInquiryOnPriceBasis(draft, inquiry);
}

function onCloseActionFailure(draft: Draft<IRSCurveReducerState>, action: CloseActionFailureAction): void {
    if (draft.latestActionFailure) {
        if (draft.latestActionFailure.actionId === action.actionId) {
            draft.latestActionFailure.closed = true;
            draft.closedActionFailures.push(action.actionId);
        }
    }
}

export function irsCurveReducer(state: IRSCurveReducerState, action: IRSCurveReducerAction): IRSCurveReducerState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "UpdateInquiry":
                onUpdateInquiry(draft, action.inquiry, action.userId);
                break;
            case "UpdateCustomerRateValue":
                onUpdateCustomerRateValue(draft, action.newValue, action.legPosition, action.quotingSide);
                shouldSendPriceBasisMessage(draft);
                break;
            case "UpdateSpreadValue":
                onUpdateSpreadValue(draft, action.spreadValue, action.legPosition, action.quotingSide);
                shouldSendPriceBasisMessage(draft);
                break;
            case "SelectDriver":
                onSelectDriver(draft, action.driver, action.legPosition, action.quotingSide);
                shouldSendPriceBasisMessage(draft);
                break;
            case "DeselectDriver":
                deselectDriverForQuotingSide(draft, action.quotingSide, action.legPosition);
                shouldSendPriceBasisMessage(draft);
                break;
            case "UpdatePtmmValue":
                onUpdatePtmmValue(draft, action.args.ptmmValue, action.args.legPosition);
                shouldSendPriceBasisMessage(draft);
                break;
            case "UpdatePackageLevelValue":
                onUpdatePackageLevelValue(draft, action.packageLevel, action.quotingSide);
                shouldSendPriceBasisMessage(draft);
                break;
            case "UpdatePackageSpreadValue":
                onUpdatePackageSpreadValue(draft, action.spreadValue, action.quotingSide);
                shouldSendPriceBasisMessage(draft);
                break;
            case "PriceBasisUpdateSent":
                draft.shouldPublishPriceBasisUpdate = false;
                break;
            case "SetIsDirty":
                draft.isDirty = action.isDirty;
                break;
            case "ClearCircuitBreaker":
                draft.autoXModeChangeReason = null;
                break;
            case "CloseActionFailure": {
                onCloseActionFailure(draft, action);
                break;
            }
        }
    });
}

function shouldSendPriceBasisMessage(draft: Draft<IRSCurveReducerState>): void {
    draft.priceBasisSequenceNumber++;
    draft.shouldPublishPriceBasisUpdate = true;
}
