import { Box, Text, Toggle, ToggleProps } from "../../atoms";
import styled from "styled-components";

export type Position = "left" | "right" | "above" | "below";

export interface TextToggleProps extends ToggleProps {
    text: string;
    position: Position;
}

const RightMarginedText = styled(Text)`
    margin-right: 10px;
`;

const LeftMarginedText = styled(Text)`
    margin-left: 10px;
`;

const BottomMarginedText = styled(Text)`
    margin-bottom: 10px;
`;

const TopMarginedText = styled(Text)`
    margin-top: 10px;
`;

export const TextToggle: React.FC<TextToggleProps> = ({ text, position, ...toggleProps }) => {
    switch (position) {
        case "left":
            return (
                <Box contentDirection="row" contentAlignment="center">
                    <RightMarginedText>{text}</RightMarginedText>
                    <Toggle {...toggleProps} />
                </Box>
            );
        case "right":
            return (
                <Box contentDirection="row" contentAlignment="center">
                    <Toggle {...toggleProps} />
                    <LeftMarginedText>{text}</LeftMarginedText>
                </Box>
            );
        case "above":
            return (
                <Box contentDirection="column" contentAlignment="center">
                    <BottomMarginedText>{text}</BottomMarginedText>
                    <Toggle {...toggleProps} />
                </Box>
            );
        case "below":
            return (
                <Box contentDirection="column" contentAlignment="center">
                    <Toggle {...toggleProps} />
                    <TopMarginedText>{text}</TopMarginedText>
                </Box>
            );
        default:
            return null;
    }
};
