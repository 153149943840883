import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, BoxProps, Icon, IconNames, Text } from "../../atoms";
import { ThemeUtils } from "../../theme";

type TextInputSizes = "xs" | "sm" | "md" | "lg";

export interface TextInputProps {
    id: string;
    value: string | null;
    title: string;
    showTitle: boolean;
    placeholder?: string;
    size: TextInputSizes;
    width?: Theme.SpacingLevels;
    height?: Theme.SpacingLevels;
    disabled?: boolean;
    multiLine?: boolean;
    resize?: "vertical" | "horizontal" | "both" | "none";

    onInputEntered?: (value: string) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onEnterKeyPress?: () => unknown;
    hasIcon?: boolean;
    onIconClick?: () => unknown;
    iconVisible?: boolean;
}

interface InputProps {
    disabled?: boolean;
    value: string;
    fontSize: Theme.FontSizes;
    resize: "vertical" | "horizontal" | "both" | "none";
}

const Input = styled(Box)<InputProps>`
    color: white;
    font-size: ${(props) => (typeof props.fontSize === "object" ? props.fontSize.custom : props.theme.font.sizes[props.fontSize])};
    resize: ${(props) => props.resize};

    &:disabled {
        color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "gray", level: "600" })};
        cursor: not-allowed;
    }
`;

const IconContainer = styled(Box)<BoxProps>`
    padding-right: 2px;
`;

interface SizeValueObject {
    inputBorderWidth: Theme.SpacingLevels;
    height: Theme.SpacingLevels;
    width: Theme.SpacingLevels;
    inputFontSize: Theme.FontSizes;
    titleFontSize: Theme.FontSizes;
}

const sizeValues: Record<TextInputSizes, SizeValueObject> = {
    xs: {
        inputBorderWidth: "px",
        width: "52",
        height: "8",
        inputFontSize: "sm",
        titleFontSize: "base",
    },
    sm: {
        inputBorderWidth: "px",
        width: "52",
        height: "8",
        inputFontSize: "lg",
        titleFontSize: "base",
    },
    md: {
        inputBorderWidth: "0.5",
        width: "64",
        height: "14",
        inputFontSize: "2xl",
        titleFontSize: "xl",
    },
    lg: {
        inputBorderWidth: "0.5",
        width: "80",
        height: "16",
        inputFontSize: "3xl",
        titleFontSize: "2xl",
    },
};

export const TextInput: React.FC<TextInputProps> = ({
    id,
    size,
    disabled = false,
    value,
    title,
    showTitle,
    placeholder,
    onInputEntered,
    onFocus,
    onBlur,
    width,
    height,
    multiLine,
    resize,
    onEnterKeyPress,
    hasIcon = false,
    onIconClick,
    iconVisible = false,
}) => {
    const { height: sizeHeight, inputBorderWidth, width: sizeWidth, inputFontSize, titleFontSize } = sizeValues[size];

    const [focused, setFocused] = useState(false);
    const [triggerInputFocus, setTriggerInputFocus] = useState(false);

    const color: Theme.Color = disabled ? { color: "gray", level: "600" } : { color: "white" };
    const borderColor: Theme.Color = !disabled && focused ? { color: "warning", level: "400" } : color;

    const inputDisplayText = value ?? (disabled ? "-" : "");

    const inputRef: React.Ref<HTMLInputElement> = React.createRef();

    const onChangeHandler = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            e.preventDefault();

            onInputEntered?.(e.currentTarget.value);
        },
        [onInputEntered],
    );
    const onFocusHandler = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(true);
            onFocus?.(e);
        },
        [onFocus],
    );
    const onBlurHandler = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);
            onBlur?.(e);
        },
        [onBlur],
    );
    const onKeyDownHandler = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                e.currentTarget.blur();
                onEnterKeyPress?.();
            }
        },
        [onEnterKeyPress],
    );

    const inputBox = (
        <Input
            data-testid={id + "-text-input"}
            as={multiLine ? "textarea" : "input"}
            resize={resize ?? "none"}
            ref={inputRef}
            disabled={disabled}
            height={height ?? sizeHeight}
            width="full"
            placeholder={placeholder}
            fontSize={inputFontSize}
            backgroundColor={{ color: "transparent" }}
            padding="2"
            type="text"
            value={inputDisplayText}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onKeyDown={onKeyDownHandler}
        />
    );

    useEffect(() => {
        if (triggerInputFocus && inputRef.current) {
            inputRef.current.focus();
            setTriggerInputFocus(false);
        }
    }, [inputRef, triggerInputFocus]);

    return (
        <Box contentDirection={"column"} width={width ?? sizeWidth}>
            {showTitle ? (
                <Box width="full" contentDirection={"column"} contentAlignment={"center"} marginB="0.5">
                    <Text textColor={color} size={titleFontSize}>
                        {title}
                    </Text>
                </Box>
            ) : null}
            <Box
                width="full"
                contentDirection={"row"}
                contentAlignment={"center"}
                border={{ width: inputBorderWidth, color: borderColor, style: "solid" }}
            >
                <Text srOnly>{title}</Text>
                {inputBox}
                {hasIcon ? (
                    <IconContainer>
                        <Icon
                            name={IconNames.Cross}
                            iconColor={iconVisible ? { color: "gray", level: "500" } : { color: "transparent" }}
                            size={"4"}
                            onClick={iconVisible ? onIconClick : undefined}
                        />
                    </IconContainer>
                ) : null}
            </Box>
        </Box>
    );
};
