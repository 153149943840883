import React, { useCallback } from "react";
import { Box, Button, semanticButtonStyling, Text } from "@transficc/components";
import { usePricerCustomComponents } from "../irs-slice/irs-selectors";

interface InquiryButtonProps {
    visible: boolean;
    title: string;
    buttonName: string;
    onClick: () => void;
}

const InquiryButton: React.FC<InquiryButtonProps> = ({ visible, title, buttonName, onClick }) => {
    return visible ? (
        <Box width={"full"} contentAlignment={"end"} marginL={"1"}>
            <Text srOnly>{buttonName}</Text>
            <Button
                styling={{ ...semanticButtonStyling("info"), unselectedBackgroundColor: { color: "transparent" } }}
                title={title}
                width={"32"}
                size={"sm"}
                onClick={onClick}
            />
        </Box>
    ) : (
        <Text srOnly>{"No " + title + " provided"}</Text>
    );
};

export interface InquiryButtonRowProps {
    openRiskViewWindow: (url: string) => void;
}

export const InquiryButtonRow: React.FC<InquiryButtonRowProps> = ({ openRiskViewWindow }) => {
    const { riskViewUrl, copyHandle } = usePricerCustomComponents();

    const showRiskView = riskViewUrl != null && riskViewUrl.length > 0;
    const onClickRiskView = useCallback(() => {
        if (riskViewUrl != null && riskViewUrl.length > 0) {
            openRiskViewWindow(riskViewUrl);
        }
    }, [openRiskViewWindow, riskViewUrl]);

    const showCopyHandle = copyHandle != null && copyHandle.length > 0;
    const onClickCopyHandle = useCallback(() => {
        if (copyHandle != null && copyHandle.length > 0) {
            void navigator.clipboard.writeText(copyHandle);
        }
    }, [copyHandle]);

    return (
        <Box contentDirection={"row"} selfAlignment={"end"} marginB="1">
            <InquiryButton visible={showRiskView} title="Risk View" buttonName={"Risk View: " + riskViewUrl} onClick={onClickRiskView} />
            <InquiryButton
                visible={showCopyHandle}
                title="Copy Handle"
                buttonName={"Copy Handle: " + copyHandle}
                onClick={onClickCopyHandle}
            />
        </Box>
    );
};
