import { ConnectionStatus } from "./ConnectionStatus";
import { RootState, useStateSelector } from "../../hooks/useStateSelector";
import { TradingStatus } from "./TradingStatus";
import { SessionUser } from "./SessionSlice";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { createSelector } from "@reduxjs/toolkit";

export const useAccessToken = (): string | null => useStateSelector((state: RootState) => state.session.accessToken);
export const useIdToken = (): string | null => useStateSelector((state: RootState) => state.session.idToken);
export const useDefinitelyAccessToken = (): string => {
    const accessToken = useStateSelector((state: RootState) => state.session.accessToken);
    if (!accessToken) throw new Error("Attempted to retrieve access token when not authenticated");
    return accessToken;
};
const selectUser = createSelector(
    (state: RootState) => state.session.user,
    (user) => user ?? { userId: Number.MIN_VALUE, username: "" },
);
export const useUser = (): SessionUser => useStateSelector(selectUser);
export const useSoundEnabled = (): boolean | null => useStateSelector((state: RootState) => state.session.soundEnabled);
export const useConnectionStatus = (): ConnectionStatus => useStateSelector((state: RootState) => state.session.connectionStatus);
export const useTradingStatus = (): TradingStatus => useStateSelector((state: RootState) => state.session.tradingStatus);
export const useServerTradingStatus = (): TradingStatus => useStateSelector((state: RootState) => state.session.serverTradingStatus);
export const useIsLoggedIn = (): boolean => useStateSelector((state: RootState) => state.session.accessToken !== null);
export const useUserGroups = (): TraderDesktopProtocol.UserGroup[] => useStateSelector((state: RootState) => state.session.userGroups);
