import React from "react";
import { Box, Icon, IconNames, Text } from "../../atoms";

export interface AnnotatedComponentProps {
    children?: React.ReactNode;
    visible: boolean;
    message: string;
    label: string;
    hiddenLabel?: string;
}

const VisibleAnnotation: React.FC<{ message: string }> = ({ message }) => (
    <Box
        width="full"
        height={message ? "6" : "0"}
        contentDirection="row"
        contentAlignment="center"
        contentJustification="center"
        backgroundColor={{ color: "warning", level: "500" }}
    >
        <Icon size={"4"} iconColor={{ color: "black" }} name={IconNames.Warning} />
        <Box marginR={"1"} />
        <Text size={"xs"} weight={"medium"} textAlignment={"center"} textColor={{ color: "black" }}>
            {message}
        </Text>
    </Box>
);

const HiddenAnnotation: React.FC<{ hiddenLabel?: string }> = ({ hiddenLabel }) => (
    <Box marginT={"6"}>{hiddenLabel ? <Text srOnly>{hiddenLabel}</Text> : null}</Box>
);

export const AnnotatedComponent: React.FC<AnnotatedComponentProps> = ({ visible, message, label, hiddenLabel, children }) => {
    const annotation = visible ? <VisibleAnnotation message={message} /> : <HiddenAnnotation hiddenLabel={hiddenLabel} />;
    const additionalElement = message ? annotation : null;
    return (
        <Box contentDirection={"column"} contentAlignment={"center"} contentJustification={"center"} padding={"0.5"} width={"full"}>
            <Text srOnly>{label}</Text>
            {children}
            {additionalElement}
        </Box>
    );
};
