import { WebSocketSessionProtocol } from "@transficc/websocket-session-public-protocol-types";

export type ServerMessage =
    | WebSocketSessionProtocol.SessionOpenRequest
    | WebSocketSessionProtocol.SessionOpenResponse
    | WebSocketSessionProtocol.SessionPingRequest
    | WebSocketSessionProtocol.SessionPongResponse
    | WebSocketSessionProtocol.SessionClose;

const constructServerMessage = <T>(
    params: T,
): T & {
    sendingTimestampNanos: string;
} => ({
    ...params,
    sendingTimestampNanos: String(Date.now()) + "000000",
});

export const sessionOpenRequest = (accessToken: string): WebSocketSessionProtocol.SessionOpenRequest =>
    constructServerMessage({
        msgType: "SessionOpenRequest",
        token: accessToken,
    });

export const sessionPingRequest = (): WebSocketSessionProtocol.SessionPingRequest =>
    constructServerMessage({
        msgType: "SessionPingRequest",
    });
