import * as React from "react";
import log from "./logger/globalLogger";
import { Box, Button, semanticButtonStyling, Text } from "@transficc/components";

interface HomePanelProps {
    errorMessage: string;
    initiateOAuthFlow: (
        identityProviderGatewayUrl: string,
        customerId: string | null | (string | null)[],
        shouldTryExternalSSO: boolean,
    ) => Promise<void>;
    identityProviderGatewayUrl: string | null;
}

const OAuthErrorPanel: React.FunctionComponent<HomePanelProps> = ({ errorMessage, initiateOAuthFlow, identityProviderGatewayUrl }) => {
    const onClick = (): void => {
        if (identityProviderGatewayUrl !== null) {
            initiateOAuthFlow(identityProviderGatewayUrl, null, false).catch((error) => {
                log.error("Failed to redirect you to the login service.", String(error));
                throw new Error("Failed to redirect you to the login service.");
            });
        }
    };
    return (
        <Box padding={"3"} contentDirection={"column"}>
            <Text data-testid="oauth-error-message">{errorMessage}</Text>
            <Button
                data-testid="authorise-button"
                title="Try Sign in Again"
                styling={semanticButtonStyling("info")}
                onClick={(): void => onClick()}
            />
        </Box>
    );
};

export default OAuthErrorPanel;
