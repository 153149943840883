// https://github.com/microsoft/TypeScript/issues/42873
import "reselect";
import "redux";

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { mapProtocolToIRSInquiry } from "./irs-domain-mapping";
import { IRSInquiry, PackageType, SelectedDriver } from "../irs-domain";
import { irsOutrightReducer, outrightReducerInitialState } from "./irs-outright-reducer";
import { CurveLegPosition } from "./irs-curve-reducer-types";
import { QuotingSide } from "./irs-outright-reducer-types";
import { curveReducerInitialState, irsCurveReducer } from "./irs-curve-reducer";
import { compressionReducerInitialState, irsCompressionReducer } from "./irs-compression-reducer";
import { DecimalNumber, ImmutableDecimalNumber, max } from "@transficc/infrastructure";
import { TicketState } from "./irs-ticket-state";

export type AllSides = Side | PackageDirection;
export type Side = "PAY" | "RCV";
export type PackageDirection = "Market" | "Opposite";

export interface InquiryData {
    latestInquiry: IRSInquiry<PackageType>;
    latestInquiryInProtocolForm: TraderDesktopProtocol.Inquiry;
    latestOnDemandPrices: TraderDesktopProtocol.OnDemandPrices | null;
    latestPriceBasis: TraderDesktopProtocol.PriceBasis | null;
    ticketState: TicketState;
    dismissed: boolean;
    expired: boolean;
}

export interface IrsState {
    selectedInquiry: number | null;
    inquiries: EntityState<InquiryData, number>;
}

const inquiryDataAdapter = createEntityAdapter<InquiryData, number>({
    selectId: (inquiryData) => inquiryData.latestInquiry.ticketId,
    sortComparer: (e1, e2) => e2.latestInquiry.ticketId - e1.latestInquiry.ticketId,
});

const isComplete = (inquiry: TraderDesktopProtocol.Inquiry): boolean => {
    return (
        inquiry.state === TraderDesktopProtocol.InquiryState.NotDoneDealerTimeout ||
        inquiry.state === TraderDesktopProtocol.InquiryState.NotDoneDealerReject ||
        inquiry.state === TraderDesktopProtocol.InquiryState.NotDoneCustomerTimeout ||
        inquiry.state === TraderDesktopProtocol.InquiryState.NotDoneCustomerReject ||
        inquiry.state === TraderDesktopProtocol.InquiryState.Done
    );
};

function updateInquiry(currentInquiryData: InquiryData, mappedInquiry: IRSInquiry<PackageType>, userId: number): void {
    if (currentInquiryData.ticketState.type === "outright") {
        currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
            type: "UpdateInquiry",
            inquiry: mappedInquiry as IRSInquiry<"outright">,
            increment: new ImmutableDecimalNumber(mappedInquiry.legs[0].minPriceIncrement),
            userId,
        });
    } else if (currentInquiryData.ticketState.type === "curve") {
        currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
            type: "UpdateInquiry",
            inquiry: mappedInquiry as IRSInquiry<"curve">,
            userId,
        });
    } else if (currentInquiryData.ticketState.type === "compression") {
        const priceIncrement = max(...mappedInquiry.legs.map((leg) => new ImmutableDecimalNumber(leg.minPriceIncrement)));
        currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
            type: "UpdateInquiry",
            args: {
                inquiry: mappedInquiry as IRSInquiry<"compression">,
                increment: priceIncrement,
                userId,
            },
        });
    }
}

const upsertInquiry = (state: IrsState, { inquiry, userId }: InquiryAction): void => {
    const currentInquiryData = state.inquiries.entities[inquiry.ticketId];

    const mappedInquiry = mapProtocolToIRSInquiry(inquiry, null, currentInquiryData?.latestPriceBasis ?? null);

    if (currentInquiryData) {
        currentInquiryData.latestInquiry = mappedInquiry;
        currentInquiryData.latestInquiryInProtocolForm = inquiry;
        currentInquiryData.latestOnDemandPrices = null;

        if (isComplete(inquiry)) {
            currentInquiryData.expired = true;
        }

        updateInquiry(currentInquiryData, mappedInquiry, userId);
        return;
    }

    const packageType = mappedInquiry.packageType;
    let ticketState: TicketState;

    switch (packageType) {
        case "outright": {
            ticketState = {
                type: "outright",
                state: outrightReducerInitialState,
            };
            break;
        }
        case "curve": {
            ticketState = {
                type: "curve",
                state: curveReducerInitialState,
            };
            break;
        }
        case "compression": {
            ticketState = {
                type: "compression",
                state: compressionReducerInitialState,
            };
            break;
        }
        default:
            packageType satisfies never;
            throw new Error();
    }

    const newInquiryData: InquiryData = {
        latestInquiry: mappedInquiry,
        latestInquiryInProtocolForm: inquiry,
        latestOnDemandPrices: null,
        latestPriceBasis: null,
        dismissed: isComplete(inquiry),
        expired: isComplete(inquiry),
        ticketState,
    };

    inquiryDataAdapter.addOne(state.inquiries, newInquiryData);

    updateInquiry(newInquiryData, mappedInquiry, userId);
};

export interface InquiryAction {
    inquiry: TraderDesktopProtocol.Inquiry;
    userId: number;
}

function selectTheMostRecentTicket(state: IrsState): void {
    for (let i = state.inquiries.ids.length - 1; i >= 0; i--) {
        const newTicketId = state.inquiries.ids[i];
        if (!newTicketId) {
            continue;
        }
        const newInquiryData = state.inquiries.entities[newTicketId];
        if (newInquiryData && !newInquiryData.dismissed) {
            state.selectedInquiry = newTicketId;
            return;
        }
    }
}

function selectTheNextOldestTicket(selectedInquiryTicketId: number, state: IrsState): void {
    for (let i = 0; i < state.inquiries.ids.length; i++) {
        const newTicketId = state.inquiries.ids[i];
        if (!newTicketId || newTicketId > selectedInquiryTicketId) {
            continue;
        }
        const newInquiryData = state.inquiries.entities[newTicketId];
        if (!newInquiryData || newInquiryData.dismissed) {
            continue;
        }
        state.selectedInquiry = newTicketId;
        return;
    }
    selectTheMostRecentTicket(state);
}

function recalculateSelectedTicket(state: IrsState): void {
    const selectedInquiryTicketId = state.selectedInquiry;
    if (selectedInquiryTicketId === null) {
        selectTheMostRecentTicket(state);
        return;
    }
    if (!selectedInquiryIsActive(selectedInquiryTicketId, state)) {
        selectTheNextOldestTicket(selectedInquiryTicketId, state);
    }
}

function selectedInquiryIsActive(selectedInquiry: number, state: IrsState): boolean {
    const selectedInquiryData = state.inquiries.entities[selectedInquiry];
    return selectedInquiryData !== undefined && !selectedInquiryData.dismissed;
}

export const irs = createSlice({
    name: "irs",
    initialState: (): IrsState => ({
        selectedInquiry: null,
        inquiries: inquiryDataAdapter.getInitialState(),
    }),
    reducers: {
        upsert: (state, action: PayloadAction<InquiryAction>) => {
            upsertInquiry(state, action.payload);
            recalculateSelectedTicket(state);
        },
        updateSelectedInquiry: (state, action: PayloadAction<{ ticketId: number }>) => {
            state.selectedInquiry = action.payload.ticketId;
        },
        updateOnDemandPrices: (state, action: PayloadAction<TraderDesktopProtocol.OnDemandPrices>) => {
            const currentInquiryData = state.inquiries.entities[action.payload.ticketId];

            if (!currentInquiryData) {
                return;
            }

            currentInquiryData.latestOnDemandPrices = action.payload;
            currentInquiryData.latestInquiry = mapProtocolToIRSInquiry(
                currentInquiryData.latestInquiryInProtocolForm,
                action.payload,
                // NOTE: because the price basis info being set back to null
                // on an on-demand pricer incremental update doesn't do anything
                // (the event sequence check in the reducers catches it)
                // it's not possible to have a failing test just for this line, which isn't great
                // However - there is a test that could possibly catch it
                currentInquiryData?.latestPriceBasis ?? null,
            );

            updateInquiry(currentInquiryData, currentInquiryData.latestInquiry, Number.MIN_SAFE_INTEGER); // TODO: the way we've structured userId is absolute shit, rewrite this
        },
        updatePriceBasis: (state, action: PayloadAction<TraderDesktopProtocol.PriceBasis>) => {
            const currentInquiryData = state.inquiries.entities[action.payload.ticketId];

            if (!currentInquiryData) {
                return;
            }

            currentInquiryData.latestPriceBasis = action.payload;
            currentInquiryData.latestInquiry = mapProtocolToIRSInquiry(
                currentInquiryData.latestInquiryInProtocolForm,
                currentInquiryData.latestOnDemandPrices,
                action.payload,
            );

            updateInquiry(currentInquiryData, currentInquiryData.latestInquiry, Number.MIN_SAFE_INTEGER); // TODO: the way we've structured userId is absolute shit, rewrite this
        },
        dismiss: (state, action: PayloadAction<number>) => {
            const ticketId = action.payload;
            const currentInquiryData = state.inquiries.entities[ticketId] ?? null;
            if (!currentInquiryData) {
                return;
            }
            currentInquiryData.dismissed = true;
            if (ticketId === state.selectedInquiry) {
                recalculateSelectedTicket(state);
            }
        },
        updateLegCustomerRate: (
            state,
            action: PayloadAction<{
                legPosition: number;
                quotingSide: Side;
                newValue: DecimalNumber;
            }>,
        ) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;

            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;
            switch (packageType) {
                case "outright": {
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "UpdateCustomerRateValue",
                        quotingSide: action.payload.quotingSide,
                        newValue: action.payload.newValue,
                    });
                    break;
                }
                case "curve": {
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "UpdateCustomerRateValue",
                        legPosition: action.payload.legPosition as 0 | 1,
                        quotingSide: action.payload.quotingSide,
                        newValue: action.payload.newValue,
                    });
                    break;
                }
                case "compression": {
                    throw new Error("You can't update leg customer rate value for a compression");
                }
            }
        },
        updateSpreadValue: (
            state,
            action: PayloadAction<{
                legPosition: number;
                quotingSide: QuotingSide;
                spreadValue: DecimalNumber;
            }>,
        ) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;
            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;
            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "UpdateSpreadValue",
                        quotingSide: action.payload.quotingSide,
                        spreadValue: action.payload.spreadValue,
                        increment: new ImmutableDecimalNumber(currentInquiryData.latestInquiry.legs[0].minPriceIncrement),
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "UpdateSpreadValue",
                        quotingSide: action.payload.quotingSide,
                        spreadValue: action.payload.spreadValue,
                        legPosition: action.payload.legPosition as CurveLegPosition,
                    });
                    break;
                case "compression":
                    throw new Error("You can't update leg customer spread value for a compression");
            }
        },
        setDirty: (state, action: PayloadAction<{ isDirty: boolean }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;
            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;
            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "SetIsDirty",
                        isDirty: action.payload.isDirty,
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "SetIsDirty",
                        isDirty: action.payload.isDirty,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "SetIsDirty",
                        isDirty: action.payload.isDirty,
                    });
                    break;
            }
        },
        updatePtmmValue: (state, action: PayloadAction<{ ptmmValue: DecimalNumber; legPosition?: number }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;

            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;
            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePtmmValue",
                        newValue: action.payload.ptmmValue,
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePtmmValue",
                        args: {
                            ptmmValue: action.payload.ptmmValue,
                            legPosition: action.payload.legPosition as CurveLegPosition,
                        },
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePtmmValue",
                        newValue: action.payload.ptmmValue,
                    });
                    break;
            }
        },
        priceBasisUpdateSent: (state, action: PayloadAction<{ ticketId: number }>) => {
            const currentInquiryData = state.inquiries.entities[action.payload.ticketId] ?? null;

            if (!currentInquiryData) {
                return;
            }

            switch (currentInquiryData.ticketState.type) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "PriceBasisUpdateSent",
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "PriceBasisUpdateSent",
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "PriceBasisUpdateSent",
                    });
                    break;
            }
        },

        selectDriver: (
            state,
            action: PayloadAction<{
                side: AllSides;
                driver: SelectedDriver;
                legPosition?: number;
            }>,
        ) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;

            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "SelectDriver",
                        quotingSide: action.payload.side as Side,
                        driver: action.payload.driver,
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "SelectDriver",
                        quotingSide: action.payload.side as Side,
                        driver: action.payload.driver,
                        legPosition: action.payload.legPosition as CurveLegPosition,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "SelectDriver",
                        driver: action.payload.driver,
                        side: action.payload.side as PackageDirection,
                    });
                    break;
            }
        },
        deselectDriver: (state, action: PayloadAction<{ side: AllSides; legPosition?: number }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;
            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "DeselectDriver",
                        quotingSide: action.payload.side as Side,
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "DeselectDriver",
                        quotingSide: action.payload.side as Side,
                        legPosition: action.payload.legPosition as CurveLegPosition,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "DeselectDriver",
                        side: action.payload.side as PackageDirection,
                    });
                    break;
            }
        },
        clearCircuitBreaker: (state) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;

            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "ClearCircuitBreaker",
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "ClearCircuitBreaker",
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "ClearCircuitBreaker",
                    });
                    break;
            }
        },
        closedActionFailure: (state, action: PayloadAction<{ actionId: string }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;
            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    currentInquiryData.ticketState.state = irsOutrightReducer(currentInquiryData.ticketState.state, {
                        type: "CloseActionFailure",
                        actionId: action.payload.actionId,
                        ticketId: state.selectedInquiry,
                    });
                    break;
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "CloseActionFailure",
                        actionId: action.payload.actionId,
                        ticketId: state.selectedInquiry,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "CloseActionFailure",
                        actionId: action.payload.actionId,
                        ticketId: state.selectedInquiry,
                    });
                    break;
            }
        },
        updatePackageValue: (state, action: PayloadAction<{ packageValue: DecimalNumber; quotingSide: AllSides }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;

            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    throw new Error("Should not dispatch updatePackageValue for outright");
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePackageLevelValue",
                        packageLevel: action.payload.packageValue,
                        quotingSide: action.payload.quotingSide as Side,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePackageFeeValue",
                        newValue: action.payload.packageValue,
                        side: action.payload.quotingSide as PackageDirection,
                    });
                    break;
            }
        },
        updatePackageSpreadValue: (state, action: PayloadAction<{ spreadValue: DecimalNumber; quotingSide: AllSides }>) => {
            if (!state.selectedInquiry) {
                return;
            }
            const currentInquiryData = state.inquiries.entities[state.selectedInquiry] ?? null;
            if (!currentInquiryData) {
                return;
            }

            const packageType = currentInquiryData.ticketState.type;

            switch (packageType) {
                case "outright":
                    throw new Error("Should not dispatch updatePackageLevelValue for outright");
                case "curve":
                    currentInquiryData.ticketState.state = irsCurveReducer(currentInquiryData.ticketState.state, {
                        type: "UpdatePackageSpreadValue",
                        spreadValue: action.payload.spreadValue,
                        quotingSide: action.payload.quotingSide as Side,
                    });
                    break;
                case "compression":
                    currentInquiryData.ticketState.state = irsCompressionReducer(currentInquiryData.ticketState.state, {
                        type: "UpdateSpreadValue",
                        args: {
                            spreadValue: action.payload.spreadValue,
                            increment: max(
                                ...currentInquiryData.latestInquiry.legs.map((leg) => new ImmutableDecimalNumber(leg.minPriceIncrement)),
                            ),
                            side: action.payload.quotingSide as PackageDirection,
                        },
                    });
                    break;
            }
        },
    },
});

export const irsSelectors = inquiryDataAdapter.getSelectors<{ irs: IrsState }>((state) => state.irs.inquiries);
export const irsReducer = irs.reducer;

export const {
    updateSpreadValue,
    updateLegCustomerRate,
    selectDriver,
    deselectDriver,
    updatePtmmValue,
    updatePackageValue,
    updatePackageSpreadValue,
} = irs.actions;
