import { VenueType } from "@transficc/trader-desktop-shared-domain";
import { Box, Text } from "@transficc/components";

interface VenueTypeDisplayProps {
    venueType: VenueType | null;
}

export const VenueTypeDisplay: React.FC<VenueTypeDisplayProps> = ({ venueType }) => {
    if (venueType === null) {
        return null;
    }

    let text = venueType.toString();
    let textColor: Theme.NonShadedColors = "black";
    let backgroundColor: Theme.ShadedColors | Theme.NonShadedColors = "white";
    if (venueType === VenueType.OffVenue) {
        text = "OFF VENUE";
        textColor = "white";
        backgroundColor = "gray";
    }
    return (
        <Box
            height={"5"}
            width={"28"}
            backgroundColor={{ color: backgroundColor }}
            contentAlignment={"center"}
            contentJustification={"center"}
        >
            <Text size={"xs"} weight={"bold"} textColor={{ color: textColor }} data-testid={"venueType"}>
                {text}
            </Text>
        </Box>
    );
};
