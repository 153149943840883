import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, BoxProps, Icon, IconNames, Text } from "@transficc/components";
import { getStateBarBackgroundColor, getStateBarForegroundColor, getStateDisplayText } from "../credit-domain/constants";
import { useCreditStateCallbacks } from "../credit-slice/useCreditStateCallbacks";
import { useDisplayedInquiry } from "../credit-slice/useCreditSliceSelectors";
import { InquiryState, SpotNegotiationModel } from "../credit-domain";
import { formatSpotRequestTime } from "../formatters/formatSpotRequestTime";
import { TimezoneProvider } from "@transficc/infrastructure";
import { ErrorMessageBox } from "@transficc/trader-desktop-shared-components";

interface CreditInquiryFooterProps {
    timezoneProvider: TimezoneProvider;
}

const GrowingBox = styled(Box)`
    flex-grow: 1;
`;

const StateBarBox = styled(Box).attrs(
    (): BoxProps => ({
        contentDirection: "row",
        contentAlignment: "center",
        contentJustification: "center",
        width: "full",
        height: "12",
    }),
)``;

const OwnerBox = styled(Box).attrs(
    (): BoxProps => ({
        backgroundColor: { color: "info", level: "600" },
        contentDirection: "row",
        marginL: "auto",
        contentJustification: "center",
        contentAlignment: "center",
        height: "12",
        width: "64",
        padding: "4",
    }),
)``;

const getFooterStateDisplayText = (
    inquiryState: InquiryState,
    spotRequestTimeNanos: string | null,
    spotNegotiationModel: SpotNegotiationModel | null,
    timezoneProvider: TimezoneProvider,
): string => {
    const stateDisplayText = getStateDisplayText(inquiryState);
    if (
        inquiryState === InquiryState.PendingSpot &&
        spotRequestTimeNanos !== null &&
        spotNegotiationModel === SpotNegotiationModel.VenuePricedDelayed
    ) {
        const spotRequestTimeFormatted = formatSpotRequestTime(spotRequestTimeNanos, timezoneProvider);
        return `${stateDisplayText} (${spotRequestTimeFormatted})`;
    }

    return stateDisplayText;
};

export const CreditInquiryFooter: React.FC<CreditInquiryFooterProps> = ({ timezoneProvider }) => {
    const inquiry = useDisplayedInquiry();
    const creditStateCallbacks = useCreditStateCallbacks();
    const latestActionFailure = inquiry.latestActionFailure;

    useEffect(() => {
        if (latestActionFailure && !latestActionFailure.closed) {
            setTimeout(() => {
                creditStateCallbacks.closeActionFailure(inquiry.ticketId, latestActionFailure.actionId);
            }, 30_000);
        }
    }, [creditStateCallbacks, inquiry.ticketId, latestActionFailure]);

    const actionFailure =
        !latestActionFailure?.closed && latestActionFailure?.reason ? (
            <ErrorMessageBox
                title="Action Failure"
                text={latestActionFailure.reason}
                severity="danger"
                dismissable={true}
                onClose={() => {
                    creditStateCallbacks.closeActionFailure(inquiry.ticketId, latestActionFailure.actionId);
                }}
            />
        ) : null;

    const autoIcon =
        inquiry.owner === "Auto" ? (
            <Icon iconColor={{ color: "lime", level: "600" }} name={IconNames.Auto} size={"6"} data-testid="inquiry-owner-auto-icon" />
        ) : null;

    return (
        <>
            <GrowingBox />
            {actionFailure}
            <Box contentDirection={"row"} contentAlignment={"center"} width={"full"}>
                <StateBarBox backgroundColor={getStateBarBackgroundColor(inquiry.state)}>
                    <Text srOnly>Inquiry State</Text>
                    <Text textAlignment={"center"} size={"xl"} textColor={getStateBarForegroundColor(inquiry.state)}>
                        {getFooterStateDisplayText(
                            inquiry.state,
                            inquiry.spotRequestTimeNanos,
                            inquiry.spotNegotiationModel,
                            timezoneProvider,
                        )}
                    </Text>
                </StateBarBox>
                <OwnerBox data-testid="inquiry-owner">
                    {autoIcon}
                    <Box width={"2"} />
                    <Text size={"xl"}>{inquiry.owner}</Text>
                </OwnerBox>
            </Box>
        </>
    );
};
