import * as IRSDomain from "../irs-domain";
import { InquiryState, InquiryStatus } from "../irs-domain";
import styled from "styled-components";
import React, { useCallback, useEffect } from "react";
import { colorByState, rfmColor } from "../irs-domain/colors";
import { IRSActiveInquiryBody } from "./irs-active-inquiry-body";
import { Box, Icon, IconNames, Text, ThemeUtils } from "@transficc/components";
import { AutoXMode } from "@transficc/trader-desktop-shared-domain";
import { useIRSInquiry, useDefinitelySelectedInquiryTicketId } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";

export interface IRSActiveInquirySummaryProps {
    ticketId: number;
}

const IRSActiveInquirySummaryContainer = styled(Box)<{ state: IRSDomain.InquiryState }>`
    width: 100%;
    cursor: pointer;

    animation: ${(props) =>
        props.state === IRSDomain.InquiryState.LastLook ? "flashingSizeSmall 750ms infinite" : "flashingSizeLarge 300ms 1"};

    @keyframes flashingSizeSmall {
        50% {
            transform: scale(1.05);
        }
    }

    @keyframes flashingSizeLarge {
        50% {
            transform: scale(1.1);
        }
    }
`;
const IRSActiveInquirySummaryStateContainer = styled(Box)`
    & > ${Text} {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const IRSActiveInquirySummaryCounterpartyFirmContainer = styled(Box)`
    & > ${Text} {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const IRSActiveInquirySummary: React.FC<IRSActiveInquirySummaryProps> = ({ ticketId }) => {
    const inquiry = useIRSInquiry(ticketId);
    const selectedInquiryTicketId = useDefinitelySelectedInquiryTicketId();
    const { updateSelectedInquiry, dismiss } = useIRSStateDispatch();

    const onClick = useCallback(() => {
        updateSelectedInquiry(ticketId);
    }, [updateSelectedInquiry, ticketId]);

    // TODO: do this in redux thunks like we do for credit
    useEffect(() => {
        if (inquiry.status === InquiryStatus.Done || inquiry.status === InquiryStatus.NotDone) {
            setTimeout(() => {
                dismiss(ticketId);
            }, 10000);
        }
    }, [dismiss, inquiry.status, ticketId]);

    const selected = inquiry.ticketId === selectedInquiryTicketId;
    const selectedBorder: ThemeUtils.BorderType = { width: "2", color: { color: "warning", level: "400" } };
    const unselectedBorder: ThemeUtils.BorderType = { width: "2", color: { color: "transparent" } };

    const { stateBarBackgroundColor, stateBarForegroundColor } = colorByState(inquiry.state);

    const isInquiryFinished = inquiry.status !== InquiryStatus.Active || inquiry.state === InquiryState.DealerAccepted;

    return (
        <IRSActiveInquirySummaryContainer
            data-testid={`active-inquiry-summary-${inquiry.aggregateId}`}
            key={inquiry.aggregateId}
            state={inquiry.state}
            onClick={onClick}
            border={selected ? selectedBorder : unselectedBorder}
            data-is-selected={selected}
        >
            {inquiry.isRfm ? (
                <Box
                    contentDirection={"row"}
                    contentJustification={"center"}
                    width={"full"}
                    padding="0.5"
                    // border={{ width: "0.5", color: { color: "black" } }}
                    // rounded
                    backgroundColor={rfmColor}
                >
                    <Text size={"xs"} textColor={{ color: "black" }} weight="bold" data-testid={"active-inquiry-summary-label"}>
                        RFM
                    </Text>
                </Box>
            ) : (
                <Text srOnly>Is not an RFM</Text>
            )}
            <IRSActiveInquirySummaryStateContainer
                width={"full"}
                backgroundColor={stateBarBackgroundColor}
                padding={"1"}
                contentDirection={"row"}
                contentJustification={"center"}
                contentAlignment={"center"}
                style={{ position: "relative" }}
            >
                <Text srOnly>Inquiry State</Text>
                {!isInquiryFinished && inquiry.autoXMode === AutoXMode.AutoStream ? (
                    <Box contentDirection="row" contentJustification="space-around">
                        <Icon name={IconNames.PlayCircle} size="4" iconColor={{ color: "success", level: "300" }} />
                        <Box marginL="1.5">
                            <Text textColor={stateBarForegroundColor} size={"sm"}>
                                Streaming
                            </Text>
                        </Box>
                    </Box>
                ) : (
                    <Text textColor={stateBarForegroundColor} size={"sm"}>
                        {IRSDomain.mapInquiryStateToDisplayName(inquiry.state)}
                    </Text>
                )}
            </IRSActiveInquirySummaryStateContainer>
            <IRSActiveInquirySummaryCounterpartyFirmContainer
                width={"full"}
                backgroundColor={{ color: "cyan", level: "700" }}
                padding={"1"}
                contentDirection={"row"}
                contentJustification={"center"}
            >
                <Text srOnly>Counterparty Firm</Text>
                <Text textColor={{ color: "white" }} size={"sm"}>
                    {inquiry.counterpartyFirm}
                </Text>
            </IRSActiveInquirySummaryCounterpartyFirmContainer>
            <IRSActiveInquiryBody inquiry={inquiry} />
        </IRSActiveInquirySummaryContainer>
    );
};
