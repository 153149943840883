import React from "react";
import { IRSInquiry } from "../irs-domain";
import { IRSInquiryHeader, OpenCustomerAnalytics } from "../irs-inquiry-header/irs-inquiry-header";
import { IRSInquiryBody, SelectedInquiryContainer } from "../irs-inquiry/irs-inquiry";
import { IRSInquiryFooter } from "../irs-inquiry-footer/irs-inquiry-footer";
import { IRSActionButtons } from "../irs-action-buttons/irs-action-buttons";
import { IrsRfqCompressionControls } from "./irs-rfq-compression-controls";
import { IrsCompressionTable } from "./irs-compression-table";
import { IrsCompressionHeader } from "./irs-compression-header";
import { Box, useRerenderOnInterval } from "@transficc/components";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { IrsRfmCompressionControls } from "./irs-rfm-compression-controls";
import { useIRSCompression } from "./irs-compression-view";
import { useDefinitelySelectedInquiry, useSelectedInquiryIsFinished } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";
import { useClock } from "@transficc/trader-desktop-application-context";

export interface IRSCompressionProps {
    publishAction: ActionPublisher.ActionPublisher;
    openRiskViewWindow: (url: string) => void;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

export const IrsCompression: React.FC<IRSCompressionProps> = ({ publishAction, openRiskViewWindow, openCustomerAnalyticsWindow }) => {
    const isInquiryFinished = useSelectedInquiryIsFinished();
    const clock = useClock();
    const inquiry = useDefinitelySelectedInquiry() as IRSInquiry<"compression">;

    const {
        market,
        opposite,

        shouldShowPackageFeeAsStale,
        packageFeeDisplayState,
        autoQuoteDisplayState,
        orderedLegs,

        notional,
        delta,

        latestQuotedPricesRow,

        isMixedCcp,
        isMixedIndices,
    } = useIRSCompression(inquiry, clock, isInquiryFinished);

    const { setDirty, clearCircuitBreaker, closeActionFailure, updatePtmmValue } = useIRSStateDispatch();

    useRerenderOnInterval(500); // for timer-based rendering like stale prices

    const compressionControls = !inquiry.isRfm ? (
        <IrsRfqCompressionControls
            model={{
                packageFeeValue: market.packageFeeValue,
                isPackageFeeInputDisabled: isInquiryFinished,
                packageFeeDisplayState: packageFeeDisplayState,
                isPackageFeeInvalid: shouldShowPackageFeeAsStale,

                spreadValue: market.spreadValue,
                isSpreadInputDisabled: market.spreadInputDisabled,

                autoQuoteDisplayState: autoQuoteDisplayState,
                autoQuoteErrorMessage: inquiry.autoQuotePriceError,
                selectedDriver: market.selectedDriver,

                isInquiryFinished: isInquiryFinished,

                autoQuotePricerState: inquiry.autoQuotePriceState,
                lastQuotedPrices: latestQuotedPricesRow,
                autoQuotePrices: inquiry.packageDirection === "Market" ? market.autoQuotedPricesRow : opposite.autoQuotedPricesRow,
                venuePrices: inquiry.packageDirection === "Market" ? market.venuePricesRow : opposite.venuePricesRow,

                tradedPackageFee: inquiry.tradedPackageFee,
                autoQuotePriceValidity: inquiry.autoQuotePriceValidity,
            }}
            callbacks={{
                updatePackageFee: market.updatePackageFee,
                onPackageFeeDirtyChange: setDirty,

                updateSpreadValue: market.updateSpreadValue,
                onSpreadDirtyChange: setDirty,

                updatePtmmValue,

                selectDriver: market.selectDriver,
                clearDriverValue: market.clearDriverValue,
            }}
        />
    ) : (
        <IrsRfmCompressionControls
            model={{
                market: {
                    packageFeeValue: market.packageFeeValue,
                    selectedDriver: market.selectedDriver,
                    spreadValue: market.spreadValue,
                    isSpreadInputDisabled: market.spreadInputDisabled,
                    autoQuotePrices: market.autoQuotedPricesRow,
                    venuePrices: market.venuePricesRow,
                },
                opposite: {
                    packageFeeValue: opposite.packageFeeValue,
                    selectedDriver: opposite.selectedDriver,
                    spreadValue: opposite.spreadValue,
                    isSpreadInputDisabled: opposite.spreadInputDisabled,
                    autoQuotePrices: opposite.autoQuotedPricesRow,
                    venuePrices: opposite.venuePricesRow,
                },
                isPackageFeeInputDisabled: isInquiryFinished,
                packageFeeDisplayState: packageFeeDisplayState,
                isPackageFeeInvalid: shouldShowPackageFeeAsStale,

                autoQuoteDisplayState: autoQuoteDisplayState,
                autoQuoteErrorMessage: inquiry.autoQuotePriceError,

                isInquiryFinished: isInquiryFinished,

                autoQuotePricerState: inquiry.autoQuotePriceState,
                lastQuotedPrices: latestQuotedPricesRow,

                tradedPackageFee: inquiry.tradedPackageFee,
                autoQuotePriceValidity: inquiry.autoQuotePriceValidity,

                packageDirection: inquiry.packageDirection,
            }}
            callbacks={{
                market: {
                    updatePackageFee: market.updatePackageFee,
                    selectDriver: market.selectDriver,
                    clearDriverValue: market.clearDriverValue,
                    updateSpreadValue: market.updateSpreadValue,
                },
                opposite: {
                    updatePackageFee: opposite.updatePackageFee,
                    selectDriver: opposite.selectDriver,
                    clearDriverValue: opposite.clearDriverValue,
                    updateSpreadValue: opposite.updateSpreadValue,
                },
                onPackageFeeDirtyChange: setDirty,
                onSpreadDirtyChange: setDirty,
                updatePtmmValue,
            }}
        />
    );
    return (
        <SelectedInquiryContainer>
            <IRSInquiryHeader openCustomerAnalyticsWindow={openCustomerAnalyticsWindow} />
            <IRSInquiryBody>
                <Box marginR={"5"}>
                    <IrsCompressionHeader
                        model={{
                            legCount: inquiry.legs.length,
                            notional,
                            delta,
                            isMixedCcp,
                            isMixedIndices,
                        }}
                        callbacks={{ openRiskViewWindow: openRiskViewWindow }}
                    />
                    <IrsCompressionTable
                        model={{
                            legs: orderedLegs,
                        }}
                    />
                </Box>
                <Box contentDirection={"column"} contentJustification={"space-between"} contentAlignment={"center"} marginT={"9"}>
                    <Box contentDirection={"column"}>
                        <IRSActionButtons publishAction={publishAction} />
                    </Box>
                    {compressionControls}
                </Box>
            </IRSInquiryBody>
            <IRSInquiryFooter clearCircuitBreaker={clearCircuitBreaker} closeActionFailure={closeActionFailure} />
        </SelectedInquiryContainer>
    );
};
