import * as React from "react";
import { Provider } from "react-redux";
import { createNewStore } from "./redux/store";
import { SessionTransportDispatcher } from "./messaging/SessionTransportDispatcher";
import ApplicationRouter from "./ApplicationRouter";
import { useClock, useSessionTransport } from "@transficc/trader-desktop-application-context";
import { GlobalStyles, TransficcThemeProvider } from "@transficc/components";
import { traderDesktopTheme } from "@transficc/trader-desktop-shared-components";

interface ApplicationProps {
    messageBatchingMaxBatchSize: number;
    messageBatchingMaxBatchIntervalMs: number;
    routerCreator: (element: React.ReactElement) => React.ReactElement;
}

export const Application: React.FC<ApplicationProps> = ({
    routerCreator,
    messageBatchingMaxBatchSize,
    messageBatchingMaxBatchIntervalMs,
}) => {
    const clock = useClock();
    const sessionTransport = useSessionTransport();

    return (
        <TransficcThemeProvider theme={traderDesktopTheme}>
            <GlobalStyles />
            <Provider store={createNewStore(sessionTransport, clock)}>
                {routerCreator(
                    <>
                        <SessionTransportDispatcher
                            messageBatchingMaxBatchSize={messageBatchingMaxBatchSize}
                            messageBatchingMaxBatchIntervalMs={messageBatchingMaxBatchIntervalMs}
                        />
                        <ApplicationRouter />
                    </>,
                )}
            </Provider>
        </TransficcThemeProvider>
    );
};
