import { electronHide, FrameName } from "@transficc/trader-desktop-electron-api";
import type { TraderDesktopElectronApi } from "@transficc/trader-desktop-electron-api";

interface WindowDimensions {
    width: number | undefined;
    height: number | undefined;
    screenX: number;
    screenY: number;
}

const lastSavedWindowSizesForFrame = new Map<FrameName, WindowDimensions>();

export const openWindow = ({
    electronApi,
    url,
    target,
    electronOpenHidden,
    popup,
    width,
    height,
    saveWindowSize,
}: {
    electronApi: TraderDesktopElectronApi;
    url: string;
    target: FrameName;
    electronOpenHidden: boolean;
    popup: boolean;
    width?: number;
    height?: number;
    saveWindowSize?: boolean;
}): WindowProxy | null => {
    const features: string[] = [];

    if (saveWindowSize) {
        const lastWindowSize = lastSavedWindowSizesForFrame.get(target);
        if (lastWindowSize) {
            width = lastWindowSize.width;
            height = lastWindowSize.height;
            features.push(`screenX=${lastWindowSize.screenX}`);
            features.push(`screenY=${lastWindowSize.screenY}`);
        }
    }

    if (width) {
        features.push(`width=${width}`);
    }

    if (height) {
        features.push(`height=${height}`);
    }

    if (popup) {
        features.push("popup");
    }
    if (electronOpenHidden) {
        features.push(electronHide);
    }

    const win = window.open(url, target, features.join(","));

    if (!win) {
        return null;
    }

    if (!electronOpenHidden) {
        electronApi.moveTop();
    }

    if (saveWindowSize) {
        win.addEventListener("unload", () => {
            lastSavedWindowSizesForFrame.set(target, {
                width: win.innerWidth,
                height: win.innerHeight,
                screenX: win.screenX,
                screenY: win.screenY,
            });
        });
    }

    return win;
};
