import { SessionTransport, webSocketProtocol } from "@transficc/trader-desktop-application-context";
import { ServerMessage } from "./SessionProtocolMessages";
import { TraderDesktopWebSocketSession } from "./TraderDesktopWebSocketSession";
import { DisconnectionReason, WebSocketSessionCloseCodes } from "@transficc/websocket";
import log from "../logger/globalLogger";

export class WebSocketDirectSessionTransport implements SessionTransport {
    private readonly webSocketSession: TraderDesktopWebSocketSession;

    constructor() {
        this.webSocketSession = new TraderDesktopWebSocketSession(log);
    }

    close(): void {
        this.webSocketSession.disconnectSession(
            WebSocketSessionCloseCodes.NORMAL,
            "Last component unmounted. Occurs during normal log off.",
        );
    }

    onConnected(handler: () => void): () => void {
        this.webSocketSession.onOpen(handler);
        return function () {};
    }

    onDisconnected(handler: (reason: DisconnectionReason, description: string | null) => void): () => void {
        this.webSocketSession.onClose(handler);
        return function () {};
    }

    onMessage(handler: (payload: string) => void): () => void {
        this.webSocketSession.onMessage((msg: ServerMessage) => handler(JSON.stringify(msg)));
        return () => {};
    }

    open(accessToken: string): void {
        this.webSocketSession.connectSession(webSocketProtocol(), accessToken);
    }

    send(payload: string): void {
        this.webSocketSession.send(payload);
    }
}
