import React, { useCallback } from "react";
import styled, { DefaultTheme } from "styled-components";
import { Box, BoxProps } from "../box";
import { Text } from "../text";
import { ThemeUtils } from "../../theme";
import { Icon, IconProps } from "../icon/icon";

export type ButtonSize = "xs" | "sm" | "md" | "lg";

export interface ButtonStyling {
    selectedBackgroundColor: Theme.Color;
    unselectedBackgroundColor: Theme.Color;
    selectedForegroundColor: Theme.Color;
    unselectedForegroundColor: Theme.Color;
}

export const semanticButtonStyling = (semanticColor: Theme.SemanticColors): ButtonStyling => {
    return {
        selectedBackgroundColor: {
            color: semanticColor,
            level: "400",
        },
        unselectedBackgroundColor: {
            color: semanticColor,
            level: "900",
        },
        selectedForegroundColor: {
            color: "white",
        },
        unselectedForegroundColor: {
            color: "white",
        },
    };
};

export interface ButtonProps {
    title?: React.ReactNode;
    hoverTitle?: string;
    leftIcon?: IconProps;
    rightIcon?: IconProps;
    size?: ButtonSize;
    styling: ButtonStyling;
    disabled?: boolean;
    selected?: boolean;
    onClick?: () => unknown;
    width?: Theme.SpacingLevels;
    height?: Theme.SpacingLevels;
    flatOn?: "left" | "right" | "both";
}

const buttonBorderWidthBasedOnSize = (theme: DefaultTheme, size: ButtonProps["size"]): Theme.SpacingLevels => {
    switch (size) {
        case "sm":
        case "md":
            return "0.5";
        case "lg":
            return "1";
        default:
            return "0.5";
    }
};

const buttonPaddingBasedOnSize = (theme: DefaultTheme, size: ButtonProps["size"]): string => {
    switch (size) {
        case "sm":
            return `${theme.spacing["1"]} ${theme.spacing["2"]}`;
        case "md":
            return `${theme.spacing["2"]} ${theme.spacing["4"]}`;
        case "lg":
            return `${theme.spacing["3"]} ${theme.spacing["5"]}`;
        default:
            return "";
    }
};

const StyledButton = styled(Box).attrs(
    (): BoxProps => ({
        contentDirection: "row",
        contentAlignment: "center",
        contentJustification: "center",
    }),
)<Pick<ButtonProps, "styling" | "size" | "selected" | "flatOn">>`
    cursor: pointer;
    width: ${(props) => props.width ?? "initial"};

    text-align: center;

    border-top-left-radius: ${(props) => (props.flatOn === "left" || props.flatOn === "both" ? "0" : props.theme.borderRadius)};
    border-bottom-left-radius: ${(props) => (props.flatOn === "left" || props.flatOn === "both" ? "0" : props.theme.borderRadius)};
    border-top-right-radius: ${(props) => (props.flatOn === "right" || props.flatOn === "both" ? "0" : props.theme.borderRadius)};
    border-bottom-right-radius: ${(props) => (props.flatOn === "right" || props.flatOn === "both" ? "0" : props.theme.borderRadius)};

    border: ${(props) =>
        ThemeUtils.buildBorder(props.theme, {
            width: buttonBorderWidthBasedOnSize(props.theme, props.size),
            color: props.styling.selectedBackgroundColor,
            style: "solid",
        })};
    background-color: ${(props) => {
        const color = props.selected ? props.styling.selectedBackgroundColor : props.styling.unselectedBackgroundColor;
        return ThemeUtils.colorSelector(props.theme, color);
    }};

    padding: ${(props) => buttonPaddingBasedOnSize(props.theme, props.size)};

    &:hover {
        background-color: ${(props) => ThemeUtils.colorSelector(props.theme, props.styling.selectedBackgroundColor)};
    }

    &:hover:not(:disabled) > * {
        color: ${(props) => ThemeUtils.colorSelector(props.theme, props.styling.selectedForegroundColor)};
    }

    &:active {
        border: ${(props) =>
            ThemeUtils.buildBorder(props.theme, {
                width: buttonBorderWidthBasedOnSize(props.theme, props.size),
                color: {
                    color: "white",
                },
                style: "solid",
            })};
    }

    &:disabled {
        border: ${(props) =>
            ThemeUtils.buildBorder(props.theme, {
                width: buttonBorderWidthBasedOnSize(props.theme, props.size),
                color: {
                    color: "gray",
                    level: "600",
                },
                style: "solid",
            })};
        background-color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "gray", level: "900" })};
        cursor: not-allowed;
    }
`;

const mapButtonSizeToTextFontSize = (buttonSize?: ButtonSize): Theme.FontSizes => {
    switch (buttonSize) {
        case "xs":
            return "xs";
        case "sm":
            return "sm";
        case "lg":
            return "xl";
        default:
            return "base";
    }
};

export const Button: React.FC<ButtonProps> = ({ title, onClick, ...props }) => {
    const onClickHandler = useCallback(() => {
        if (!props.disabled) {
            onClick?.();
        }
    }, [onClick, props.disabled]);

    const disabledColor: Theme.Color = {
        color: "gray",
        level: "500",
    };
    const textColor = props.disabled
        ? disabledColor
        : props.selected
        ? props.styling.selectedForegroundColor
        : props.styling.unselectedForegroundColor;
    const innerButtonContent = title ? (
        <Text weight="bold" textColor={textColor} size={mapButtonSizeToTextFontSize(props.size)}>
            {title}
        </Text>
    ) : null;

    return (
        <StyledButton {...props} as="button" type="button" title={props.hoverTitle} onClick={onClickHandler}>
            {props.leftIcon && (
                <Box marginR={title || props.rightIcon ? "2" : "0"}>
                    <Icon {...props.leftIcon} />
                </Box>
            )}
            {innerButtonContent}
            {props.rightIcon && (
                <Box marginL={title || props.leftIcon ? "2" : "0"}>
                    <Icon {...props.rightIcon} />
                </Box>
            )}
        </StyledButton>
    );
};
