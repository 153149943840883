import { createGlobalStyle } from "styled-components";

// this is used to prevent double-scroll-bar issues in the IRS and Credit/Bond docks
// despite best efforts it is not clear how the div that scrolls in the active inquiries list/table
// causes the whole body to scroll, or how to fix it. so this is a bit of a hack to make that work.
// feel free to find a better way. if you remove this CSS, you will likely see the issue.
export const DisableBodyScrolling = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;
