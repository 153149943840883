import React, { useState } from "react";
import { Box, DataTable, DataTableCellData, DataTableProps, ProgressCircle, RadioBar, Text } from "@transficc/components";
// import { DataTable, DataTableCellData, DataTableProps, RadioBar } from "../../molecules";
import styled from "styled-components";

const PageHeader: React.FC<{ customerFirm: string }> = ({ customerFirm }) => {
    return (
        <Box
            contentDirection={"row"}
            height={"10"}
            backgroundColor={{ color: "cyan", level: "700" }}
            contentAlignment={"center"}
            padding={"2"}
            marginB={"2"}
        >
            <Box>
                <Text size={"lg"}>{customerFirm}</Text>
            </Box>
            <Box
                rounded
                marginL={"12"}
                backgroundColor={{ color: "gray", level: "200" }}
                contentJustification="space-around"
                padding={"1"}
                height={"6"}
            >
                <Text textColor={{ color: "black" }} size={"base"}>
                    Tier 1
                </Text>
            </Box>
            <Box rounded marginL={"12"} contentJustification="space-around" padding={"1"}>
                <Text textColor={{ color: "gray", level: "300" }} size={"base"}>
                    Last 6 Months
                </Text>
            </Box>
        </Box>
    );
};

const HitRatioAndOptionalPriceInsight: React.FC<{ isCredit: boolean; customerFirm: string; customerTrader: string }> = ({
    isCredit,
    customerFirm,
    customerTrader,
}) => {
    return (
        <Box contentDirection={"row"} width={"full"}>
            <HitRatio customerFirm={customerFirm} customerTrader={customerTrader} />

            {isCredit ? <Box width={"4"} /> : null}
            {isCredit ? <PriceInsight /> : null}
        </Box>
    );
};

function hash(value: string): number {
    let hashcode = 0;
    for (let i = 0; i < value.length; i++) {
        hashcode += 31 * value.charCodeAt(i);
    }
    return hashcode;
}

const HitRatio: React.FC<{ customerFirm: string; customerTrader: string }> = ({ customerFirm, customerTrader }) => {
    const firmHitPercentage = (hash(customerFirm) % 60) + 30;
    const traderHitPercentage = Math.max(0, firmHitPercentage - (hash(customerTrader) % 30));
    return (
        <Box width={"full"}>
            <Box
                contentDirection={"row"}
                height={"8"}
                backgroundColor={{ color: "gray", level: "300" }}
                contentAlignment={"center"}
                padding={"2"}
                marginB={"4"}
            >
                <Box>
                    <Text textColor={{ color: "black" }} size={"lg"}>
                        Hit Ratio
                    </Text>
                </Box>
            </Box>

            <Box contentAlignment={"center"} selfAlignment={"center"} textAlign={"center"}>
                <Box width={"full"} contentAlignment={"center"} contentDirection={"row"}>
                    <Box width={"full"} contentAlignment={"center"}>
                        <Text textColor={{ color: "gray", level: "300" }} size={"base"}>
                            {customerFirm}
                        </Text>
                    </Box>
                    <Box marginL={"4"} />
                    <Box width={"full"} contentAlignment={"center"}>
                        <Text textColor={{ color: "gray", level: "300" }} size={"base"}>
                            {customerTrader}
                        </Text>
                    </Box>
                </Box>
                <Box width={"full"} contentAlignment={"center"} contentDirection={"row"}>
                    <Box width={"full"} contentAlignment={"center"}>
                        <ProgressCircle percentage={firmHitPercentage} />
                    </Box>
                    <Box marginL={"4"} />
                    <Box width={"full"} contentAlignment={"center"}>
                        <ProgressCircle percentage={traderHitPercentage} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const PriceZoneLeft = styled(Box)`
    background-color: #e25b42; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, #e25a42, #bf7138);
    border-radius: 40% 0 0 40% / 20% 0 0 20%;
    box-shadow: 5px 5px;
`;
const PriceZoneMiddle = styled(Box)`
    background-color: #988a2d; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, #bf7138, #72a222);
    box-shadow: 5px 5px;
`;

const PriceZoneRight = styled(Box)`
    background-color: #988a2d; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, #72a222, #2eca10);
    border-radius: 0 40% 40% 0 / 0 20% 20% 0;
    box-shadow: 5px 5px;
`;

const PriceZones: React.FC<{ activeValue: string }> = ({ activeValue }) => {
    return (
        <Box contentDirection={"row"} width={"full"}>
            <Box>
                <Text size={"sm"}>Worst</Text>
                <PriceZoneLeft
                    height={"6"}
                    width={"28"}
                    backgroundColor={{ color: "danger" }}
                    contentDirection={"row"}
                    contentAlignment={"center"}
                    marginT={"1"}
                >
                    <Box width={"full"}>
                        <Text textColor={{ color: "black" }} size={"base"}>
                            0%
                        </Text>
                    </Box>
                </PriceZoneLeft>
            </Box>
            <Box marginL={"2"} />
            <Box>
                <Text size={"sm"}>10% Worst</Text>
                <PriceZoneMiddle
                    height={"6"}
                    width={"28"}
                    backgroundColor={{ color: "warning" }}
                    contentDirection={"row"}
                    contentAlignment={"center"}
                    marginT={"1"}
                >
                    <Box width={"full"}>
                        <Text textColor={{ color: "black" }} size={"base"}>
                            {activeValue === "Tweb Zone" ? "60%" : "65%"}
                        </Text>
                    </Box>
                </PriceZoneMiddle>
            </Box>
            <Box marginL={"2"} />
            <Box>
                <Text size={"sm"}>Better</Text>
                <PriceZoneRight
                    height={"6"}
                    width={"28"}
                    backgroundColor={{ color: "success" }}
                    contentDirection={"row"}
                    contentAlignment={"center"}
                    marginT={"1"}
                >
                    <Box width={"full"}>
                        <Text textColor={{ color: "black" }} size={"base"}>
                            {activeValue === "Tweb Zone" ? "95%" : "92%"}
                        </Text>
                    </Box>
                </PriceZoneRight>
            </Box>
        </Box>
    );
};

enum DotPosition {
    LEFT,
    MIDDLE,
    RIGHT,
}

interface PriceZonesRowProps {
    position: DotPosition;
}

const PriceZonesRow: React.FC<PriceZonesRowProps> = ({ position }) => {
    const blackDot = <Box backgroundColor={{ color: "black" }} rounded height={"2"} width={"2"} margin={"auto"}></Box>;
    return (
        <Box contentDirection={"row"} width={"full"}>
            <Box>
                <PriceZoneLeft height={"3"} width={"6"} backgroundColor={{ color: "danger" }}>
                    {position === DotPosition.LEFT ? blackDot : null}
                </PriceZoneLeft>
            </Box>
            <Box marginL={"1"} />
            <Box>
                <PriceZoneMiddle height={"3"} width={"6"} backgroundColor={{ color: "warning" }}>
                    {position === DotPosition.MIDDLE ? blackDot : null}
                </PriceZoneMiddle>
            </Box>
            <Box marginL={"1"} />
            <Box>
                <PriceZoneRight height={"3"} width={"6"} backgroundColor={{ color: "success" }}>
                    {position === DotPosition.RIGHT ? blackDot : null}
                </PriceZoneRight>
            </Box>
        </Box>
    );
};

const PriceInsight: React.FC = () => {
    const buttonLabels = ["CP+ Zone", "Tweb Zone"];
    const [activeButton, setActiveButton] = useState("Tweb Zone");
    return (
        <Box width={"full"}>
            <Box
                contentDirection={"row"}
                height={"8"}
                backgroundColor={{ color: "gray", level: "300" }}
                contentAlignment={"center"}
                padding={"2"}
                marginB={"4"}
            >
                <Box contentDirection={"row"}>
                    <Text textColor={{ color: "black" }} size={"lg"}>
                        Price Insight
                    </Text>
                    <Box />
                    <Box marginL={"4"} />
                    <RadioBar values={buttonLabels} selectedValue={activeButton} onClick={setActiveButton} joined buttonHeight={"6"} />
                </Box>
            </Box>

            <Box contentAlignment={"center"} selfAlignment={"center"} textAlign={"center"}>
                <Text textColor={{ color: "gray", level: "300" }} size={"base"}>
                    Trade Probability Zone
                </Text>
                <Box marginB={"4"} />
                <PriceZones activeValue={activeButton} />
            </Box>
        </Box>
    );
};

const CREDIT_BUY: DataTableCellData = {
    value: "BUY",
    cellBackgroundColor: { color: "cyan", level: "200" },
    cellForegroundColor: { color: "black" },
};

const CREDIT_SELL: DataTableCellData = {
    value: "SELL",
    cellBackgroundColor: { color: "rose", level: "200" },
    cellForegroundColor: { color: "black" },
};

const IRS_PAY: DataTableCellData = {
    value: "PAY",
    cellBackgroundColor: { color: "rose", level: "200" },
    cellForegroundColor: { color: "black" },
};

const IRS_RCV: DataTableCellData = {
    value: "RCV",
    cellBackgroundColor: { color: "cyan", level: "200" },
    cellForegroundColor: { color: "black" },
};

const DONE: DataTableCellData = {
    value: "Done",
    cellBackgroundColor: { color: "success", level: "500" },
    cellForegroundColor: { color: "black" },
};

const NOT_DONE: DataTableCellData = {
    value: "Not Done",
    cellBackgroundColor: { color: "gray", level: "200" },
    cellForegroundColor: { color: "black" },
};

const IRSFirmRows: DataTableProps<number>["rows"] = [
    {
        rowId: 1,
        values: [
            { value: "22 Aug 2024 09:05:45" },
            { value: "USD 10Y vs SOFR" },
            IRS_PAY,
            { value: "80MM" },
            { value: "4.5" },
            { value: "4.4" },
            { value: "Rate" },
            DONE,
            { value: "1" },
        ],
    },
    {
        rowId: 2,
        values: [
            { value: "21 Aug 2024 09:08:00" },
            { value: "USD 5Y FWD vs SOFR" },
            IRS_PAY,
            { value: "150MM" },
            { value: "5.123" },
            { value: "5.023" },
            { value: "Rate" },
            DONE,
            { value: "8" },
        ],
    },
    {
        rowId: 3,
        values: [
            { value: "20 Aug 2024 09:06:45" },
            { value: "USD list (30 items)" },
            { value: "-" },
            { value: "386MM" },
            { value: "390k" },
            { value: "-" },
            { value: "PV" },
            NOT_DONE,
            { value: "10" },
        ],
    },
    {
        rowId: 4,
        values: [
            { value: "19 Aug 2024 09:15:45" },
            { value: "USD 10Y SOFR vs USD 15Y SOFR" },
            IRS_RCV,
            { value: "325MM" },
            { value: "120.65" },
            { value: "-" },
            { value: "Spread" },
            DONE,
            { value: "5" },
        ],
    },
];

const CreditFirmRows: DataTableProps<number>["rows"] = [
    {
        rowId: 1,
        values: [
            { value: "22 Aug 2024 09:05:45" },
            { value: "HD 2.500 9/15/35" },
            CREDIT_BUY,
            { value: "500k" },
            { value: "108.6" },
            { value: "107.2" },
            { value: "Price" },
            DONE,
            { value: "3" },
            { value: () => <PriceZonesRow position={DotPosition.LEFT} /> },
        ],
    },
    {
        rowId: 2,
        values: [
            { value: "21 Aug 2024 09:08:00" },
            { value: "AAPL 2.500 9/15/35" },
            CREDIT_BUY,
            { value: "3MM" },
            { value: "49.1" },
            { value: "49.3" },
            { value: "Spread" },
            DONE,
            { value: "15" },
            { value: () => <PriceZonesRow position={DotPosition.MIDDLE} /> },
        ],
    },
    {
        rowId: 3,
        values: [
            { value: "20 Aug 2024 09:06:45" },
            { value: "MCD 4.450 9/1/25 c25" },
            CREDIT_SELL,
            { value: "50k" },
            { value: "5.61" },
            { value: "-" },
            { value: "Yield" },
            NOT_DONE,
            { value: "-" },
            { value: () => <PriceZonesRow position={DotPosition.MIDDLE} /> },
        ],
    },
    {
        rowId: 4,
        values: [
            { value: "02 Jul 2024 09:45:45" },
            { value: "HD 2.500 9/15/25" },
            CREDIT_BUY,
            { value: "80k" },
            { value: "98.6" },
            { value: "99.0" },
            { value: "Spread" },
            DONE,
            { value: "5" },
            { value: () => <PriceZonesRow position={DotPosition.RIGHT} /> },
        ],
    },
];
const CreditTraderRows: DataTableProps<number>["rows"] = CreditFirmRows.slice(0, 3);

const IRSTraderRows: DataTableProps<number>["rows"] = IRSFirmRows.slice(0, 3);

const HeaderBackgroundColor: Theme.Color = { color: "cyan", level: "600" };
const IRSConfig: DataTableProps<number>["config"] = {
    columnConfig: [
        { label: "Timestamp", width: "48", growable: true, labelBackgroundColor: HeaderBackgroundColor },
        { label: "Description", width: "72", growable: true, labelBackgroundColor: HeaderBackgroundColor },
        { label: "Side", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Qty", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Level", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Cover", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Type", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "State", width: "24", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Dlrs", width: "14", labelBackgroundColor: HeaderBackgroundColor },
    ],
    cellHeight: "6",
    cellBackgroundColor: { color: "transparent" },
    cellForegroundColor: { color: "white" },
    cellFontSize: "sm",
    cellBorderWidth: "px",
    cellSelectionBorderWidth: "0",
    cellSelectionBackgroundColor: { color: "cyan", level: "800" },
    animateNewRows: true,
};

const CreditConfig: DataTableProps<number>["config"] = {
    columnConfig: [
        { label: "Timestamp", width: "48", growable: true, labelBackgroundColor: HeaderBackgroundColor },
        { label: "Description", width: "48", growable: true, labelBackgroundColor: HeaderBackgroundColor },
        { label: "Side", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Qty", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Level", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Cover", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Type", width: "16", labelBackgroundColor: HeaderBackgroundColor },
        { label: "State", width: "24", labelBackgroundColor: HeaderBackgroundColor },
        { label: "Dlrs", width: "14", labelBackgroundColor: HeaderBackgroundColor },
        { label: "CP+ Zone", width: "24", labelBackgroundColor: HeaderBackgroundColor },
    ],
    cellHeight: "6",
    cellBackgroundColor: { color: "transparent" },
    cellForegroundColor: { color: "white" },
    cellFontSize: "sm",
    cellBorderWidth: "px",
    cellSelectionBorderWidth: "0",
    cellSelectionBackgroundColor: { color: "cyan", level: "800" },
    animateNewRows: true,
};

const LatestRFQs: React.FC<{ isCredit: boolean }> = ({ isCredit }) => {
    const buttonLabels = ["Firm", "Trader"];
    const [activeButton, setActiveButton] = useState("Firm");

    const config = isCredit ? CreditConfig : IRSConfig;
    const rows = isCredit
        ? activeButton === "Trader"
            ? CreditTraderRows
            : CreditFirmRows
        : activeButton === "Trader"
        ? IRSTraderRows
        : IRSFirmRows;
    return (
        <Box>
            <Box
                contentDirection={"row"}
                height={"8"}
                backgroundColor={{ color: "gray", level: "300" }}
                contentAlignment={"center"}
                padding={"2"}
                marginB={"2"}
                marginT={"4"}
            >
                <Box contentDirection={"row"} contentAlignment={"center"}>
                    <Text textColor={{ color: "black" }} size={"lg"}>
                        Last RFQ(s)
                    </Text>
                    <Box marginL={"4"} />
                    <RadioBar values={buttonLabels} selectedValue={activeButton} onClick={setActiveButton} joined buttonHeight={"6"} />
                </Box>
            </Box>

            <Box contentDirection={"row"} width={"full"}>
                <DataTable config={config} rows={rows} />
            </Box>
        </Box>
    );
};

export interface CustomerAnalyticsStaticExampleProps {
    isCredit: boolean;
    customerFirm: string;
    customerTrader: string;
}

export const CustomerAnalyticsStaticExample: React.FC<CustomerAnalyticsStaticExampleProps> = ({
    isCredit,
    customerFirm,
    customerTrader,
}) => {
    return (
        <Box>
            <PageHeader customerFirm={customerFirm} />
            <HitRatioAndOptionalPriceInsight isCredit={isCredit} customerFirm={customerFirm} customerTrader={customerTrader} />
            <LatestRFQs isCredit={isCredit} />
        </Box>
    );
};
