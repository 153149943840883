import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateTraderDesktopSettings as Settings, updateTraderSettings } from "../../messaging/ApplicationProtocolMessages";
import { ThunkConfig } from "./ThunkConfig";

export const updateTraderDesktopSettings = createAsyncThunk<void, Settings, ThunkConfig>(
    "settings/updateTraderDesktopSettings",
    (arg, { extra }) => {
        const message = updateTraderSettings(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);
