import { TypedUseSelectorHook, useSelector } from "react-redux";
import { InquiryData, irsSelectors, IrsState } from "./irs-slice";
import { createSelector } from "@reduxjs/toolkit";
import * as IRSDomain from "../irs-domain";
import {
    BidMidAsk,
    calculatePackageLevelValueByInquiryType,
    DisplayState,
    InquirySide,
    InquiryState,
    InquiryStatus,
    IRSInquiry,
    IRSLeg,
    LatestActionFailure,
    mapPricerStateToDisplayState,
    PackageDirection,
    PackageType,
    PricerState,
    SelectedDriver,
} from "../irs-domain";
import { DecimalNumber, ImmutableDecimalNumber, max } from "@transficc/infrastructure";
import { ActionPublisher, AssetClass, AutoXMode, VenueType } from "@transficc/trader-desktop-shared-domain";
import { IRSOutrightReducerState, IRSQuotingReducerState } from "./irs-outright-reducer-types";
import { IRSCurveReducerState, LegSideState as CurveLegSideState } from "./irs-curve-reducer-types";
import { IRSCompressionReducerState } from "./irs-compression-reducer-types";
import { TicketState } from "./irs-ticket-state";
import { decimalToBasisPoints } from "../irs-domain/scaling";
import { getSelectedDriverValueForLeg, isSelectedDriverAutoQuotePriceSource } from "../irs-domain/price-source";
import React from "react";

interface PartialRootState {
    irs: IrsState;
}

export type IRSState = {
    isDirty: boolean;

    autoXModeChangeReason: ActionPublisher.AutoXModeChangeReason | null;

    latestActionFailure: LatestActionFailure | null;
    closedActionFailures: string[];

    shouldPublishPriceBasisUpdate: boolean;
    priceBasisSequenceNumber: number;
} & (
    | { type: "leg-based"; legs: LegState[] }
    | {
          type: "package-based";
          ptmmValue: DecimalNumber | null;
          manualPtmmValue: DecimalNumber | null;

          market: PackageSideState;
          opposite: PackageSideState;
      }
);

export interface InquiryHeader {
    ticketId: number;
    venueName: string;

    venueType: VenueType | null;
    assetClass: AssetClass | null;

    tier: number | null;
    numberOfDealers: number | null;
    counterpartyFirm: string;
    counterpartyTrader: string;
    salesPersonId: string | null;
    salesPersonName: string | null;
    clearingHouse?: string | null;

    timerStartTimestampMillis: string | null;
    timerEndTimestampMillis: string | null;

    currency: string;

    isRfm: boolean;
}

export interface LegInquiryHeader {
    isCustom: boolean;
    side: InquirySide;
    instrumentName: string;
    inquiryState: IRSDomain.InquiryState;
    fixedPeriod: string;
    floatPeriod: string;
    fixedRate: string | null;
}

export interface LegDateAndSize {
    quantity: string;
    side: InquirySide;
    effectiveDate: number | null;
    terminationDate: number | null;
}

export interface InquiryFooter {
    latestActionFailure: IRSDomain.LatestActionFailure | null;
    ticketId: number;
    isInquiryFinished: boolean;
    state: IRSDomain.InquiryState;
    autoXMode: AutoXMode | null;
    autoXModeChangeReason: ActionPublisher.AutoXModeChangeReason | null;
    autoXModeChangeDescription: string | null;
}

export interface ActionButtonsModel {
    customerAskPrice: string | null;
    customerBidPrice: string | null;
    minPriceIncrement: string;
}

export interface PricerCustomComponents {
    riskViewUrl: string | null;
    copyHandle: string | null;
}

export interface LegState {
    position: number;

    pay: LegSideState;
    rcv: LegSideState;

    ptmmValue: DecimalNumber | null;
    manualPtmmValue: DecimalNumber | null;
}

export interface LegSideState {
    customerRateValue: DecimalNumber | null;
    manualCustomerRateValue: DecimalNumber | null;
    spreadValue: DecimalNumber | null;

    selectedDriver: SelectedDriver | null;
    hasDriverValue: boolean;

    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

interface PackageSideState {
    customerRateValue: DecimalNumber | null;
    manualCustomerRateValue: DecimalNumber | null;
    spreadValue: DecimalNumber | null;

    selectedDriver: SelectedDriver | null;
    hasDriverValue: boolean;

    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

interface QuoteActionLeg {
    referenceId: string;
    bidPrice: string;
    askPrice: string;
    preTradeMidMarketPrice: string | null;
}

interface QuoteActionValues {
    ticketId: number;
    eventSequence: number;
    packagePrice: string | null;
    oppositePackagePrice: string | null;
    preTradeMidMarketPackagePrice: string | null;
    onTheWireTimeMs: number | null;
    legs: QuoteActionLeg[];
}

interface PtmmState {
    value: DecimalNumber | null;
    autoQuoteMidPrice: string | null;
    displayState: DisplayState;
}

const createIRSSelector = createSelector.withTypes<PartialRootState>();
const useIRSStateSelector: TypedUseSelectorHook<PartialRootState> = useSelector;
const selectAllIRSInquiries: (state: PartialRootState) => InquiryData[] = irsSelectors.selectAll;

const selectActiveIRSInquiries = createIRSSelector(
    [selectAllIRSInquiries],

    (inquiries) =>
        inquiries
            .filter((inquiryData) => !inquiryData.dismissed)
            .map((inquiryData) => {
                return inquiryData.latestInquiry;
            }),
);

const selectActiveIRSInquiryIDs = createIRSSelector([selectActiveIRSInquiries], (inquiries) =>
    inquiries.map((inquiryData) => inquiryData.ticketId),
);

const maybeSelectedInquiryTicketId = (state: PartialRootState): number | null => state.irs.selectedInquiry;
export const useMaybeSelectedInquiryTicketId = (): number | null => useIRSStateSelector(maybeSelectedInquiryTicketId);

const maybeSelectedInquiryData = (state: PartialRootState): InquiryData | null => {
    const ticketId = state.irs.selectedInquiry;
    if (!ticketId) {
        return null;
    }
    const inquiry = state.irs.inquiries.entities[ticketId];
    if (!inquiry) {
        return null;
    }
    return inquiry;
};
const maybeSelectedInquiry = createIRSSelector([maybeSelectedInquiryData], (inquiryData) => inquiryData?.latestInquiry ?? null);
export const useMaybeSelectedInquiry = (): IRSInquiry<PackageType> | null => useIRSStateSelector(maybeSelectedInquiry);

const definitelySelectedInquiryTicketId = (state: PartialRootState): number => {
    const selectedTicketId = maybeSelectedInquiryTicketId(state);
    if (!selectedTicketId) {
        throw new Error("No selected inquiry");
    }
    return selectedTicketId;
};
export const useDefinitelySelectedInquiryTicketId = (): number => useIRSStateSelector(definitelySelectedInquiryTicketId);

const definitelySelectedInquiry = createIRSSelector([maybeSelectedInquiryData], (inquiryData) => {
    if (!inquiryData) {
        throw new Error("Ticket data does not exist for selected ticketId");
    }
    return inquiryData.latestInquiry;
});
export const useDefinitelySelectedInquiry = (): IRSInquiry<PackageType> => useIRSStateSelector(definitelySelectedInquiry);

const definitelySelectedInquiryTicketState = createIRSSelector([maybeSelectedInquiryData], (inquiryData) => {
    if (!inquiryData) {
        throw new Error("Ticket data does not exist for selected ticketId");
    }
    return inquiryData.ticketState;
});
export const useDefinitelySelectedInquiryTicketState = (): TicketState => useIRSStateSelector(definitelySelectedInquiryTicketState);

const inquiryFooterSelector = createIRSSelector(
    [definitelySelectedInquiry, definitelySelectedInquiryTicketState],
    (inquiry, ticketState): InquiryFooter => {
        return {
            autoXMode: inquiry.autoXMode,
            autoXModeChangeDescription: inquiry.autoXModeChangeDescription,
            autoXModeChangeReason: inquiry.autoXModeChangeReason,
            isInquiryFinished: inquiryIsFinished(inquiry),
            latestActionFailure: ticketState.state.latestActionFailure,
            state: inquiry.state,
            ticketId: inquiry.ticketId,
        };
    },
);

export const useActionButtonsModel = (): ActionButtonsModel => useIRSStateSelector(actionButtonsModelSelector);

const actionButtonsModelSelector = createIRSSelector([definitelySelectedInquiry], (inquiry): ActionButtonsModel => {
    let customerAskPrice = null;
    let customerBidPrice = null;

    if (inquiry.packageType === "outright") {
        const castInquiry = inquiry as IRSInquiry<"outright">;
        customerAskPrice = castInquiry.legs[0].customerAskPrice;
        customerBidPrice = castInquiry.legs[0].customerBidPrice;
    } else if (inquiry.packageType === "curve") {
        const castInquiry = inquiry as IRSInquiry<"curve">;
        const customerAskValueAtLeg0 = castInquiry.legs[0].customerAskPrice;
        const customerBidValueAtLeg0 = castInquiry.legs[0].customerBidPrice;
        const customerAskValueAtLeg1 = castInquiry.legs[1].customerAskPrice;
        const customerBidValueAtLeg1 = castInquiry.legs[1].customerBidPrice;

        const customerPriceValue = calculatePackageLevelValueByInquiryType(inquiry.isMac, customerBidValueAtLeg1, customerAskValueAtLeg0);
        customerBidPrice = customerPriceValue !== null ? customerPriceValue.toString() : null;

        const hitLiftPriceValue = calculatePackageLevelValueByInquiryType(inquiry.isMac, customerAskValueAtLeg1, customerBidValueAtLeg0);
        customerAskPrice = hitLiftPriceValue !== null ? hitLiftPriceValue.toString() : null;
    } else if (inquiry.packageType === "compression") {
        const castInquiry = inquiry as IRSInquiry<"compression">;
        customerBidPrice = castInquiry.customerPackageFee;
        customerAskPrice = castInquiry.customerOppositePackageFee;
    }

    return {
        customerAskPrice,
        customerBidPrice,
        minPriceIncrement: inquiry.legs[0].minPriceIncrement,
    };
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectPtmmState = (legPosition?: number) =>
    createIRSSelector([definitelySelectedInquiry, selectIRSReduxState], (inquiry, ticketState) => {
        if (ticketState.type === "package-based" && inquiry.packageType === "compression") {
            const castInquiry = inquiry as IRSInquiry<"compression">;
            return {
                value: ticketState.ptmmValue,
                autoQuoteMidPrice: castInquiry.autoQuotePackageMidPrice,
                displayState: mapPricerStateToDisplayState(inquiry.autoQuotePriceState),
            };
        } else {
            if (legPosition === undefined) {
                throw new Error("Expected a legpoistion for getting ptmm for a non-compression package type");
            }
            const stateLeg = getStateLeg(ticketState, legPosition);
            const inquiryLeg = getInquiryLeg(inquiry, legPosition);

            return {
                value: stateLeg.ptmmValue,
                autoQuoteMidPrice: inquiryLeg.autoQuotePrices[1],
                displayState: mapPricerStateToDisplayState(inquiry.autoQuotePriceState),
            };
        }
    });

export const usePtmmState = (legPosition?: number): PtmmState => useIRSStateSelector(selectPtmmState(legPosition));

export const usePricerCustomComponents = (): PricerCustomComponents => useIRSStateSelector(pricerCustomComponentsSelector);

const pricerCustomComponentsSelector = createIRSSelector([definitelySelectedInquiry], (inquiry): PricerCustomComponents => {
    return {
        riskViewUrl: inquiry.riskViewUrl,
        copyHandle: inquiry.copyHandle,
    };
});
export const useInquiryFooter = (): InquiryFooter => useIRSStateSelector(inquiryFooterSelector);

const inquiryHeaderSelector = createIRSSelector([definitelySelectedInquiry], (inquiry): InquiryHeader => {
    return {
        assetClass: inquiry.assetClass,
        counterpartyFirm: inquiry.counterpartyFirm,
        counterpartyTrader: inquiry.counterpartyTrader,
        currency: inquiry.legs[0].currency,
        isRfm: inquiry.isRfm,
        numberOfDealers: inquiry.numberOfDealers,
        salesPersonId: inquiry.salesPersonId,
        salesPersonName: inquiry.salesPersonName,
        ticketId: inquiry.ticketId,
        tier: inquiry.tier,
        timerEndTimestampMillis: inquiry.timerEndTimestampMillis,
        timerStartTimestampMillis: inquiry.timerStartTimestampMillis,
        venueName: inquiry.venueName,
        venueType: inquiry.venueType,
        clearingHouse: inquiry.packageType === "compression" ? null : inquiry.legs[0].clearingHouse,
    };
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const minPriceIncrementLegSelector = (legPosition: number) =>
    createIRSSelector([definitelySelectedInquiry], (inquiry): DecimalNumber => {
        const leg = getInquiryLeg(inquiry, legPosition);
        return new ImmutableDecimalNumber(leg.minPriceIncrement);
    });

export const useMinPriceIncrementLegSelector = (legPosition: number): DecimalNumber =>
    useIRSStateSelector(minPriceIncrementLegSelector(legPosition));

const minPriceIncrementAllLegsSelector = createIRSSelector([definitelySelectedInquiry], (inquiry): DecimalNumber => {
    return max(...inquiry.legs.map((leg) => new ImmutableDecimalNumber(leg.minPriceIncrement)));
});

export const useMinPriceIncrementAllLegsSelector = (): DecimalNumber => useIRSStateSelector(minPriceIncrementAllLegsSelector);

const minPriceIncrementPackageSelector = createIRSSelector([definitelySelectedInquiry], (inquiry): DecimalNumber => {
    if (inquiry.packageType !== "curve") {
        throw new Error("Min Price Increment for Package only available for curve");
    }
    const castInquiry = inquiry as IRSInquiry<"curve">;
    return new ImmutableDecimalNumber(castInquiry.packageMinPriceIncrement);
});

export const useMinPriceIncrementPackageSelector = (): DecimalNumber => useIRSStateSelector(minPriceIncrementPackageSelector);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const legDateAndSizeSelector = (legPosition: number) =>
    createIRSSelector([definitelySelectedInquiry], (inquiry): LegDateAndSize => {
        const leg = getInquiryLeg(inquiry, legPosition);

        return {
            effectiveDate: leg.effectiveDate,
            quantity: leg.quantity,
            side: leg.side,
            terminationDate: leg.terminationDate,
        };
    });

export const useInquiryLegHeaderSelector = (legPosition: number): LegInquiryHeader =>
    useIRSStateSelector(inquiryLegHeaderSelector(legPosition));

export const useSelectedLegDateAndSize = (legPosition: number): LegDateAndSize => useIRSStateSelector(legDateAndSizeSelector(legPosition));

function getInquiryLeg(inquiry: IRSInquiry<PackageType>, legPosition: number): IRSDomain.IRSLeg<"outright"> | IRSDomain.IRSLeg<"curve"> {
    if (inquiry.packageType === "compression") {
        throw new Error("Compression should not retrieving leg state");
    }
    const leg = inquiry.legs.find((l) => l.position === legPosition) as IRSDomain.IRSLeg<"outright"> | IRSDomain.IRSLeg<"curve">;

    if (!leg) {
        throw new Error(`Leg not present at leg position: ${legPosition}`);
    }
    return leg;
}

function getStateLeg(state: IRSState, legPosition: number): LegState {
    if (state.type === "package-based") {
        throw new Error("Compression should not retrieving leg state");
    }
    const leg = state.legs.find((l) => l.position === legPosition);

    if (!leg) {
        throw new Error(`Leg not present at leg position: ${legPosition}`);
    }
    return leg;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const inquiryLegHeaderSelector = (legPosition: number) => {
    return createIRSSelector([definitelySelectedInquiry], (inquiry): LegInquiryHeader => {
        const leg = getInquiryLeg(inquiry, legPosition);

        return {
            isCustom: leg.isCustom,
            side: leg.side,
            fixedPeriod: leg.fixedPeriod,
            fixedRate: leg.fixedRate,
            floatPeriod: leg.floatPeriod,
            instrumentName: leg.instrumentName,
            inquiryState: inquiry.state,
        };
    });
};

export const useSelectedInquiryHeader = (): InquiryHeader => useIRSStateSelector(inquiryHeaderSelector);

export const useSelectedInquiryEventSequence = (): number => useIRSStateSelector(definitelySelectedInquiry).eventSequence;

export const useSelectedInquiryIsFinished = (): boolean => inquiryIsFinished(useIRSStateSelector(definitelySelectedInquiry));
export const useSelectedInquiryState = (): InquiryState => useIRSStateSelector(definitelySelectedInquiry).state;

export const useSelectedInquiryAutoXModeChangeReason = (): ActionPublisher.AutoXModeChangeReason | null =>
    useIRSStateSelector(definitelySelectedInquiryTicketState).state.autoXModeChangeReason;

export const useSelectedInquiryLatestActionFailure = (): LatestActionFailure | null =>
    useIRSStateSelector(definitelySelectedInquiryTicketState).state.latestActionFailure;

const inquiryIsFinished = (inquiry: IRSInquiry<PackageType>): boolean => {
    const isFinished = inquiry.status !== InquiryStatus.Active || inquiry.state === InquiryState.DealerAccepted;
    return inquiry.packageType === "compression" ? isFinished || inquiry.state === InquiryState.TradedPendingFeeAllocation : isFinished;
};

function isSelectedDriverStreamable(leg: IRSLeg<"curve"> | IRSLeg<"outright">, selectedDriver: SelectedDriver | null): boolean {
    return (
        isSelectedDriverAutoQuotePriceSource(selectedDriver) &&
        getSelectedDriverValueForLeg(selectedDriver, leg) !== "" &&
        getSelectedDriverValueForLeg(selectedDriver, leg) !== null
    );
}

const shouldBeAllowedToStream = createIRSSelector(
    [definitelySelectedInquiry, definitelySelectedInquiryTicketState],
    (inquiry, ticketState): boolean => {
        if (inquiry.autoXMode !== AutoXMode.Manual) {
            return false;
        }

        if (ticketState.type === "outright") {
            const castInquiry = inquiry as IRSInquiry<"outright">;
            const canStreamPay = isSelectedDriverStreamable(castInquiry.legs[0], ticketState.state.pay.selectedDriver);
            const canStreamRcv = isSelectedDriverStreamable(castInquiry.legs[0], ticketState.state.rcv.selectedDriver);

            if (castInquiry.legs[0].side === InquirySide.Buy) {
                return canStreamPay;
            }
            if (castInquiry.legs[0].side === InquirySide.Sell) {
                return canStreamRcv;
            }

            return canStreamPay && canStreamRcv;
        } else if (ticketState.type === "curve") {
            const castInquiry = inquiry as IRSInquiry<"curve">;
            const [leftLegState, rightLegState] = ticketState.state.legs;
            const [leftLeg, rightLeg] = castInquiry.legs;

            const canStreamPayLeft = isSelectedDriverStreamable(leftLeg, leftLegState.pay.selectedDriver);
            const canStreamRcvLeft = isSelectedDriverStreamable(leftLeg, leftLegState.rcv.selectedDriver);
            const canStreamPayRight = isSelectedDriverStreamable(rightLeg, rightLegState.pay.selectedDriver);
            const canStreamRcvRight = isSelectedDriverStreamable(rightLeg, rightLegState.rcv.selectedDriver);

            if (leftLeg.side === InquirySide.Buy) {
                return canStreamPayLeft && canStreamRcvRight;
            }
            if (leftLeg.side === InquirySide.Sell) {
                return canStreamRcvLeft && canStreamPayRight;
            }

            return canStreamPayLeft && canStreamRcvLeft && canStreamPayRight && canStreamRcvRight;
        } else if (ticketState.type === "compression") {
            const castInquiry = inquiry as IRSInquiry<"compression">;
            const hasMarketDriverValue = selectedDriverHasValue(castInquiry, ticketState.state.market.selectedDriver);
            const hasOppositeDriverValue = selectedDriverHasValue(castInquiry, ticketState.state.opposite.selectedDriver);
            const canStreamMarket = isSelectedDriverAutoQuotePriceSource(ticketState.state.market.selectedDriver) && hasMarketDriverValue;
            const canStreamOpposite =
                isSelectedDriverAutoQuotePriceSource(ticketState.state.opposite.selectedDriver) && hasOppositeDriverValue;
            switch (castInquiry.packageDirection) {
                case PackageDirection.Market:
                    return canStreamMarket;
                case PackageDirection.Opposite:
                    return canStreamOpposite;
                case PackageDirection.Undisclosed:
                    return canStreamMarket && canStreamOpposite;
                default:
                    castInquiry.packageDirection satisfies never;
                    throw new Error();
            }
        }
        return false;
    },
);

const shouldBeAllowedToQuote = createIRSSelector(
    [definitelySelectedInquiry, definitelySelectedInquiryTicketState],
    (inquiry, ticketState): boolean => {
        if (inquiry.autoXMode !== AutoXMode.Manual) {
            return false;
        }

        if (ticketState.type === "outright") {
            const state = ticketState.state;
            const ptmmPresentIfRequired = !inquiry.isPtmmRequired || state.ptmmValue !== null;
            const canQuotePay = canQuoteSide(ptmmPresentIfRequired, state.pay, state.isDirty, inquiry.autoQuotePriceState);
            const canQuoteRcv = canQuoteSide(ptmmPresentIfRequired, state.rcv, state.isDirty, inquiry.autoQuotePriceState);

            if (inquiry.legs[0].side === InquirySide.Buy) {
                return canQuotePay;
            }

            if (inquiry.legs[0].side === InquirySide.Sell) {
                return canQuoteRcv;
            }

            return canQuotePay && canQuoteRcv;
        } else if (ticketState.type === "curve") {
            const castInquiry = inquiry as IRSInquiry<"curve">;
            const leftLegState = ticketState.state.legs[0];
            const rightLegState = ticketState.state.legs[1];

            const packageLevelValuePay = packageLevelValueForSide(rightLegState.pay, leftLegState.rcv, inquiry.isMac);
            const packageLevelValueRcv = packageLevelValueForSide(rightLegState.rcv, leftLegState.pay, inquiry.isMac);

            const packageLevelDisplayStatePay = packageLevelDisplayStateForSide(
                leftLegState.rcv,
                rightLegState.pay,
                inquiry.autoQuotePriceState,
            );
            const packageLevelDisplayStateRcv = packageLevelDisplayStateForSide(
                leftLegState.pay,
                rightLegState.rcv,
                inquiry.autoQuotePriceState,
            );

            const hasValidPackageLevel =
                (castInquiry.legs[1].side === InquirySide.Buy && packageLevelValuePay !== null) ||
                (castInquiry.legs[1].side === InquirySide.Sell && packageLevelValueRcv !== null) ||
                (packageLevelValueRcv !== null && packageLevelValuePay !== null);

            const hasValidPackageLevelDisplayState =
                (castInquiry.legs[1].side === InquirySide.Buy && packageLevelDisplayStatePay === DisplayState.Valid) ||
                (castInquiry.legs[1].side === InquirySide.Sell && packageLevelDisplayStateRcv === DisplayState.Valid) ||
                (packageLevelDisplayStatePay !== null && packageLevelDisplayStateRcv !== null);

            const hasValidPtmmValue = !inquiry.isPtmmRequired || (leftLegState.ptmmValue !== null && rightLegState.ptmmValue !== null);
            return !ticketState.state.isDirty && hasValidPackageLevelDisplayState && hasValidPackageLevel && hasValidPtmmValue;
        } else if (ticketState.type === "compression") {
            const castInquiry = inquiry as IRSInquiry<"compression">;
            const state = ticketState.state;
            const hasMarketDriverValue = selectedDriverHasValue(castInquiry, state.market.selectedDriver);
            const hasOppositeDriverValue = selectedDriverHasValue(castInquiry, state.opposite.selectedDriver);
            if (castInquiry.packageDirection === PackageDirection.Undisclosed) {
                return (
                    !state.isDirty &&
                    (state.market.selectedDriver === null || hasMarketDriverValue) &&
                    (state.opposite.selectedDriver === null || hasOppositeDriverValue) &&
                    state.market.packageFeeValue !== null &&
                    state.opposite.packageFeeValue !== null &&
                    (!inquiry.isPtmmRequired || state.roundedPtmm !== null)
                );
            }
            const marketPriceCanQuote =
                castInquiry.packageDirection !== PackageDirection.Market ||
                ((state.market.selectedDriver === null || hasMarketDriverValue) && state.market.packageFeeValue !== null);
            const oppositePriceCanQuote =
                castInquiry.packageDirection !== PackageDirection.Opposite ||
                ((state.opposite.selectedDriver === null || hasOppositeDriverValue) && state.opposite.packageFeeValue !== null);
            return (
                !state.isDirty && marketPriceCanQuote && oppositePriceCanQuote && (!inquiry.isPtmmRequired || state.roundedPtmm !== null)
            );
        }
        return false;
    },
);

const selectedInquiryQuoteDisabled = createIRSSelector(
    [definitelySelectedInquiry, shouldBeAllowedToQuote],
    (inquiry, allowedToQuote): boolean => {
        return (
            inquiryIsFinished(inquiry) ||
            !inquiry.allowedActions.includes(IRSDomain.InquiryAction.Quote) ||
            !allowedToQuote ||
            inquiry.autoXMode === AutoXMode.AutoStream
        );
    },
);

export const useSelectedInquiryQuoteDisabled = (): boolean => useIRSStateSelector(selectedInquiryQuoteDisabled);

function getSelectedDriverDisplayState(selectedDriver: SelectedDriver | null, pricerState: PricerState): DisplayState {
    const isAutoQuoteDriverSelected = selectedDriver?.rowNo === 1;
    const autoQuoteDisplayState: DisplayState = mapPricerStateToDisplayState(pricerState);
    return isAutoQuoteDriverSelected ? autoQuoteDisplayState : DisplayState.Valid;
}

function canQuoteSide(
    ptmmPresentIfRequired: boolean,
    quotingSide: IRSQuotingReducerState,
    dirty: boolean,
    pricerState: PricerState,
): boolean {
    const selectedDriverDisplayState = getSelectedDriverDisplayState(quotingSide.selectedDriver, pricerState);

    return (
        !dirty &&
        (quotingSide.selectedDriver === null || quotingSide.hasDriverValue) &&
        selectedDriverDisplayState === DisplayState.Valid &&
        quotingSide.customerRateValue !== null &&
        ptmmPresentIfRequired
    );
}

function packageLevelValueForSide(legPay: CurveLegSideState, otherLegRcv: CurveLegSideState, mac: boolean): DecimalNumber | null {
    const rightLegPayValue = legPay.customerRateValue;
    const leftLegRcvValue = otherLegRcv.customerRateValue;
    if (rightLegPayValue && leftLegRcvValue) {
        if (mac) {
            return rightLegPayValue.add(leftLegRcvValue);
        } else {
            return decimalToBasisPoints(rightLegPayValue.subtract(leftLegRcvValue));
        }
    } else {
        return null;
    }
}

function packageLevelDisplayStateForSide(
    legSide: CurveLegSideState,
    otherLegOppositeSide: CurveLegSideState,
    autoQuotePriceState: PricerState,
): DisplayState {
    const legSideDriverDisplayState = getSelectedDriverDisplayState(legSide.selectedDriver, autoQuotePriceState);
    const otherLegOppositeSideDriverDisplayState = getSelectedDriverDisplayState(otherLegOppositeSide.selectedDriver, autoQuotePriceState);
    return legSideDriverDisplayState !== DisplayState.Valid ? legSideDriverDisplayState : otherLegOppositeSideDriverDisplayState;
}

function selectedDriverHasValue(inquiry: IRSInquiry<"compression">, selectedDriver: SelectedDriver | null): boolean {
    if (selectedDriver?.rowNo === 0) {
        switch (selectedDriver.columnNo) {
            case 0:
                return inquiry.latestQuotedPackageFee !== null;
            case 1:
                return inquiry.latestQuotedMidPackageFee !== null;
            case 2:
                return inquiry.latestQuotedOppositePackageFee !== null;
            default:
                return false;
        }
    } else if (selectedDriver?.rowNo === 1) {
        switch (selectedDriver.columnNo) {
            case 0:
                return inquiry.autoQuotePackagePrice !== null;
            case 1:
                return inquiry.autoQuotePackageMidPrice !== null;
            case 2:
                return inquiry.autoQuoteOppositePackagePrice !== null;
            default:
                return false;
        }
    } else if (selectedDriver?.rowNo === 2 && selectedDriver.columnNo === 1) {
        return inquiry.packageMidCompositePrice != null;
    }
    return false;
}

const selectIRSReduxState = createIRSSelector(definitelySelectedInquiryTicketState, (ticketState) => {
    if (ticketState.type === "outright") {
        return mapOutrightToIRSState(ticketState.state);
    } else if (ticketState.type === "curve") {
        return mapCurveToIRSState(ticketState.state);
    } else if (ticketState.type === "compression") {
        return mapCompressionToIRSState(ticketState.state);
    }
    ticketState satisfies never;
    throw new Error("Ticket type not implemented");
});

const selectQuoteValues = createIRSSelector([definitelySelectedInquiry, selectIRSReduxState], (inquiry, ticketState): QuoteActionValues => {
    let packagePrice = "";
    let oppositePackagePrice = "";
    let preTradeMidMarketPackagePrice = null;
    const quoteLegs: QuoteActionLeg[] = [];

    if (ticketState.type === "leg-based") {
        for (let i = 0; i < ticketState.legs.length; i++) {
            const leg = inquiry.legs[i];
            const stateLeg = ticketState.legs[i];

            if (leg && stateLeg) {
                const bidPrice = leg.side === InquirySide.Sell ? "" : stateLeg.pay.customerRateValue?.toString() ?? "";
                const askPrice = leg.side === InquirySide.Buy ? "" : stateLeg.rcv.customerRateValue?.toString() ?? "";

                quoteLegs.push({
                    referenceId: leg.referenceId,
                    bidPrice: bidPrice,
                    askPrice: askPrice,
                    preTradeMidMarketPrice: stateLeg.ptmmValue?.toString() ?? null,
                });
            }
        }
        if (ticketState.legs[0] && ticketState.legs[1] && inquiry.legs[1]) {
            const leftLegState = ticketState.legs[0];
            const rightLegState = ticketState.legs[1];
            const leftBidPrice = inquiry.legs[0].side === InquirySide.Sell ? "" : leftLegState.pay.customerRateValue?.toString() ?? "";
            const leftAskPrice = inquiry.legs[0].side === InquirySide.Buy ? "" : leftLegState.rcv.customerRateValue?.toString() ?? "";

            const rightBidPrice = inquiry.legs[1].side === InquirySide.Sell ? "" : rightLegState.pay.customerRateValue?.toString() ?? "";
            const rightAskPrice = inquiry.legs[1].side === InquirySide.Buy ? "" : rightLegState.rcv.customerRateValue?.toString() ?? "";

            const leftPtmmValue = leftLegState.ptmmValue?.toString() ?? null;
            const rightPtmmValue = rightLegState.ptmmValue?.toString() ?? null;

            preTradeMidMarketPackagePrice =
                calculatePackageLevelValueByInquiryType(inquiry.isMac, rightPtmmValue, leftPtmmValue)?.toString() ?? null;
            packagePrice = calculatePackageLevelValueByInquiryType(inquiry.isMac, rightBidPrice, leftAskPrice)?.toString() ?? "";
            oppositePackagePrice = calculatePackageLevelValueByInquiryType(inquiry.isMac, rightAskPrice, leftBidPrice)?.toString() ?? "";
        }
    } else if (ticketState.type === "package-based") {
        const compression = inquiry as IRSInquiry<"compression">;
        packagePrice =
            compression.packageDirection !== PackageDirection.Opposite ? ticketState.market.customerRateValue?.toString() ?? "" : "";
        oppositePackagePrice =
            compression.packageDirection !== PackageDirection.Market ? ticketState.opposite.customerRateValue?.toString() ?? "" : "";
        preTradeMidMarketPackagePrice = ticketState.ptmmValue?.toString() ?? null;
    }

    return {
        ticketId: inquiry.ticketId,
        eventSequence: inquiry.eventSequence,
        packagePrice: packagePrice,
        oppositePackagePrice: oppositePackagePrice,
        preTradeMidMarketPackagePrice: preTradeMidMarketPackagePrice,
        onTheWireTimeMs: null,
        legs: quoteLegs,
    };
});

export const useQuoteActionValues = (): QuoteActionValues => useIRSStateSelector(selectQuoteValues);

export const useSelectedInquiryAutoStreamDisabled = (): boolean => useIRSStateSelector(selectedInquiryAutoStreamDisabled);

const selectedInquiryAutoStreamDisabled = createSelector(
    [selectedInquiryQuoteDisabled, shouldBeAllowedToStream, definitelySelectedInquiry],
    (isQuoteDisabled, isAllowedToStream, selectedInquiry): boolean => {
        return inquiryIsFinished(selectedInquiry) || isQuoteDisabled || !isAllowedToStream;
    },
);

export const useSelectedInquiryAcceptDisabled = (): boolean => {
    const inquiry = useIRSStateSelector(definitelySelectedInquiry);
    return inquiryIsFinished(inquiry) || !inquiry.allowedActions.includes(IRSDomain.InquiryAction.DealerAccept);
};

export const useSelectedInquiryRejectDisabled = (): boolean => {
    const inquiry = useIRSStateSelector(definitelySelectedInquiry);
    return inquiryIsFinished(inquiry) || !inquiry.allowedActions.includes(IRSDomain.InquiryAction.DealerReject);
};

export const useSelectedInquiryAutoStreamStopDisabled = (): boolean => {
    const inquiry = useIRSStateSelector(definitelySelectedInquiry);
    return inquiryIsFinished(inquiry) || inquiry.autoXMode !== AutoXMode.AutoStream;
};

export const useAllIRSInquiries = (): InquiryData[] => useIRSStateSelector(selectAllIRSInquiries);

export const useActiveIRSInquiries = (): IRSInquiry<PackageType>[] => useIRSStateSelector(selectActiveIRSInquiries);
export const useActiveIRSInquiryTicketIDs = (): number[] => useIRSStateSelector(selectActiveIRSInquiryIDs);

export const useIRSInquiry = (ticketId: number): IRSInquiry<PackageType> =>
    useIRSStateSelector((state) => {
        const inquiry = state.irs.inquiries.entities[ticketId];
        if (!inquiry) {
            throw new Error("No inquiry found for ticket ID " + ticketId);
        }
        return inquiry.latestInquiry;
    });

function mapOutrightToIRSState(state: IRSOutrightReducerState): IRSState {
    return {
        type: "leg-based",

        legs: [
            {
                position: 0,
                ptmmValue: state.ptmmValue,
                manualPtmmValue: state.manualPtmmValue,
                pay: {
                    customerRateValue: state.pay.customerRateValue,
                    manualCustomerRateValue: state.pay.manualRateValue,
                    spreadValue: state.pay.spreadValue,
                    selectedDriver: state.pay.selectedDriver,
                    hasDriverValue: state.pay.hasDriverValue,
                    autoQuotePrices: state.pay.autoQuotePrices,
                    venuePrices: state.pay.venuePrices,
                    latestQuotedPrices: state.pay.latestQuotedPrices,
                },
                rcv: {
                    customerRateValue: state.rcv.customerRateValue,
                    manualCustomerRateValue: state.rcv.manualRateValue,
                    spreadValue: state.rcv.spreadValue,
                    selectedDriver: state.rcv.selectedDriver,
                    hasDriverValue: state.rcv.hasDriverValue,
                    autoQuotePrices: state.rcv.autoQuotePrices,
                    venuePrices: state.rcv.venuePrices,
                    latestQuotedPrices: state.rcv.latestQuotedPrices,
                },
            },
        ],

        autoXModeChangeReason: state.autoXModeChangeReason,
        closedActionFailures: state.closedActionFailures,
        isDirty: state.isDirty,
        latestActionFailure: state.latestActionFailure,

        priceBasisSequenceNumber: state.priceBasisSequenceNumber,
        shouldPublishPriceBasisUpdate: state.shouldPublishPriceBasisUpdate,
    };
}

function mapCurveToIRSState(state: IRSCurveReducerState): IRSState {
    return {
        type: "leg-based",

        legs: [
            {
                position: 0,
                ptmmValue: state.legs[0].ptmmValue,
                manualPtmmValue: state.legs[0].manualPtmmValue,
                pay: {
                    customerRateValue: state.legs[0].pay.customerRateValue,
                    manualCustomerRateValue: state.legs[0].pay.manualRateValue,
                    spreadValue: state.legs[0].pay.spreadValue,
                    selectedDriver: state.legs[0].pay.selectedDriver,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    hasDriverValue: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    autoQuotePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    venuePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    latestQuotedPrices: null as any,
                },
                rcv: {
                    customerRateValue: state.legs[0].rcv.customerRateValue,
                    manualCustomerRateValue: state.legs[0].rcv.manualRateValue,
                    spreadValue: state.legs[0].rcv.spreadValue,
                    selectedDriver: state.legs[0].rcv.selectedDriver,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    hasDriverValue: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    autoQuotePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    venuePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    latestQuotedPrices: null as any,
                },
            },
            {
                position: 1,
                ptmmValue: state.legs[1].ptmmValue,
                manualPtmmValue: state.legs[1].manualPtmmValue,
                pay: {
                    customerRateValue: state.legs[1].pay.customerRateValue,
                    manualCustomerRateValue: state.legs[1].pay.manualRateValue,
                    spreadValue: state.legs[1].pay.spreadValue,
                    selectedDriver: state.legs[1].pay.selectedDriver,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    hasDriverValue: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    autoQuotePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    venuePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    latestQuotedPrices: null as any,
                },
                rcv: {
                    customerRateValue: state.legs[1].rcv.customerRateValue,
                    manualCustomerRateValue: state.legs[1].rcv.manualRateValue,
                    spreadValue: state.legs[1].rcv.spreadValue,
                    selectedDriver: state.legs[1].rcv.selectedDriver,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    hasDriverValue: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    autoQuotePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    venuePrices: null as any,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
                    latestQuotedPrices: null as any,
                },
            },
        ],

        autoXModeChangeReason: state.autoXModeChangeReason,
        closedActionFailures: state.closedActionFailures,
        isDirty: state.isDirty,
        latestActionFailure: state.latestActionFailure,

        priceBasisSequenceNumber: state.priceBasisSequenceNumber,
        shouldPublishPriceBasisUpdate: state.shouldPublishPriceBasisUpdate,
    };
}

function mapCompressionToIRSState(state: IRSCompressionReducerState): IRSState {
    return {
        type: "package-based",

        market: {
            customerRateValue: state.market.packageFeeValue,
            manualCustomerRateValue: state.market.manualPackageFeeValue,
            spreadValue: state.market.spreadValue,
            selectedDriver: state.market.selectedDriver,
            hasDriverValue: false,
            autoQuotePrices: [
                state.market.roundedAutoQuotedPackagePrice,
                state.market.roundedAutoQuotedPackageMidPrice,
                state.market.roundedAutoQuotedOppositePackagePrice,
            ],
            venuePrices: [null, state.market.roundedVenueMidPackagePrice, null],
            latestQuotedPrices: [
                state.roundedLatestQuotedPackagePrice,
                state.roundedLatestQuotedMidPackagePrice,
                state.roundedLatestQuotedOppositePackagePrice,
            ],
        },

        opposite: {
            customerRateValue: state.opposite.packageFeeValue,
            manualCustomerRateValue: state.opposite.manualPackageFeeValue,
            spreadValue: state.opposite.spreadValue,
            selectedDriver: state.opposite.selectedDriver,
            hasDriverValue: false,
            autoQuotePrices: [
                state.opposite.roundedAutoQuotedPackagePrice,
                state.opposite.roundedAutoQuotedPackageMidPrice,
                state.opposite.roundedAutoQuotedOppositePackagePrice,
            ],
            venuePrices: [null, state.opposite.roundedVenueMidPackagePrice, null],
            latestQuotedPrices: [
                state.roundedLatestQuotedPackagePrice,
                state.roundedLatestQuotedMidPackagePrice,
                state.roundedLatestQuotedOppositePackagePrice,
            ],
        },

        ptmmValue: state.roundedPtmm,
        manualPtmmValue: state.manualPtmm,

        autoXModeChangeReason: state.autoXModeChangeReason,
        closedActionFailures: state.closedActionFailures,
        isDirty: state.isDirty,
        latestActionFailure: state.latestActionFailure,

        priceBasisSequenceNumber: state.priceBasis.sequenceNumber,
        shouldPublishPriceBasisUpdate: state.shouldPublishPriceBasisUpdate,
    };
}

export const useIRSReduxState = (): IRSState => {
    const selectedInquiry = useIRSStateSelector((state) => state.irs.selectedInquiry);
    if (!selectedInquiry) {
        throw new Error("No inquiry selected");
    }

    const stateSelector = React.useMemo(
        () =>
            createIRSSelector([(state: PartialRootState) => state.irs.inquiries.entities[selectedInquiry]?.ticketState], (ticketState) => {
                if (!ticketState) {
                    throw new Error("Ticket state not found for selected inquiry");
                }
                if (ticketState.type === "outright") {
                    return mapOutrightToIRSState(ticketState.state);
                } else if (ticketState.type === "curve") {
                    return mapCurveToIRSState(ticketState.state);
                } else if (ticketState.type === "compression") {
                    return mapCompressionToIRSState(ticketState.state);
                }
                ticketState satisfies never;
                throw new Error("Ticket type not implemented");
            }),
        [selectedInquiry],
    );

    return useIRSStateSelector(stateSelector);
};
