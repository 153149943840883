import { Metric } from "@transficc/trader-desktop-application-context";

export const createHeartbeatMetricFactory = (): (() => Metric[]) => {
    let heartbeatCount = 0;

    const createHeartbeatMetric = (): Metric[] => {
        const timestamp = Date.now();
        heartbeatCount++;
        return [
            {
                fields: {
                    count: heartbeatCount,
                },
                name: "browser.heartbeat",
                tags: {},
                timestamp,
            },
        ];
    };
    return createHeartbeatMetric;
};

export const createNoSharedWorkerHeartbeatMetric = (duration: number): Metric[] => {
    const timestamp = Date.now();
    return [
        {
            fields: {
                noResponseDurationMs: duration,
            },
            name: "browser.sharedworkerheartbeat",
            tags: {},
            timestamp,
        },
    ];
};

export function createPlatformMetrics(appVersion: string, electronAppVersion: string): Metric[] {
    let deviceMemory = 0;
    // @ts-expect-error not defined in typescript hence for the type check
    if (typeof navigator.deviceMemory != "undefined" && typeof navigator.deviceMemory === "number") {
        // @ts-expect-error not defined in typescript hence for the type check
        deviceMemory = navigator.deviceMemory as number;
    }

    const timestamp = Date.now();
    return [
        {
            fields: {
                cpuCount: navigator.hardwareConcurrency,
                estMinMemory: deviceMemory,
                screenHeight: window.screen.height,
                screenWidth: window.screen.width,
                pixelRatio: window.devicePixelRatio,
                electronAppVersion: electronAppVersion,
            },
            name: "browser.versions",
            tags: {
                platform: navigator.platform,
                appVersion: appVersion,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: Intl.DateTimeFormat().resolvedOptions().locale,
            },
            timestamp,
        },
    ];
}
