import React, { useCallback } from "react";
import { DecimalNumber } from "@transficc/infrastructure";
import { DisplayState } from "../irs-domain";
import styled from "styled-components";
import { Box, BoxProps, NumberInput, NumberInputSize } from "@transficc/components";
import { usePtmmState, useSelectedInquiryIsFinished } from "../irs-slice/irs-selectors";

export interface IRSPriceAndSpreadProps {
    tickButtonSize: DecimalNumber;
    minPriceIncrement: DecimalNumber;
    size: NumberInputSize;
    onValueChange: (value: DecimalNumber) => void;
    legPosition?: number;
}

const Container = styled(Box).attrs<{ marginTop: Theme.SpacingLevels }>(
    ({ marginTop }): BoxProps => ({ width: "full", marginT: marginTop, contentAlignment: "center" }),
)<{ marginTop: Theme.SpacingLevels }>``;

export const IRSPtmm: React.FC<IRSPriceAndSpreadProps> = ({ tickButtonSize, minPriceIncrement, size, onValueChange, legPosition }) => {
    const ptmmNoopCallback = useCallback(() => {}, []);
    const { value, displayState, autoQuoteMidPrice } = usePtmmState(legPosition);
    const inquiryIsFinished = useSelectedInquiryIsFinished();

    return (
        <Container marginTop="2">
            <NumberInput
                config={{
                    titleText: "PTMM",
                    showTitleText: true,
                    titleTextSelector: "PTMM",
                    size: size,
                    initialDisplayText: "-",
                    snapToMultiplesOf: minPriceIncrement,
                }}
                model={{
                    value,
                    disabled: inquiryIsFinished || (autoQuoteMidPrice !== null && displayState === DisplayState.Valid),
                    selected: false,
                    increment: tickButtonSize,
                    displayType: "input",
                    invalid: false,
                }}
                callbacks={{
                    onValueChange: onValueChange,
                    onDirtyChange: ptmmNoopCallback,
                }}
            />
        </Container>
    );
};
