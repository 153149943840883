import { ThemeUtils } from "../../theme";
import styled from "styled-components";
import { Box } from "../../atoms";

interface InputProps {
    disabled?: boolean;
    value: string;
    fontSize: Theme.FontSizes;
    foregroundColor?: Theme.Color;
    backgroundColor?: Theme.Color;
}

export const Input = styled(Box)<InputProps>`
    color: ${(props) => ThemeUtils.colorSelector(props.theme, props.foregroundColor ?? { color: "white" })};
    text-align: center;
    font-size: ${(props) => (typeof props.fontSize === "object" ? props.fontSize.custom : props.theme.font.sizes[props.fontSize])};
    background-color: ${(props) => ThemeUtils.colorSelector(props.theme, props.backgroundColor ?? { color: "transparent" })};

    &:disabled {
        color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "gray", level: "500" })};
        cursor: not-allowed;
    }
`;
