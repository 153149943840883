export interface AcceptAction {
    actionId:      string | null;
    eventSequence: number;
    /**
     * The message type
     */
    msgType: 'AcceptAction';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
    transactTimeNs:        number;
}

export interface AutoStreamRequestAction {
    /**
     * The message type
     */
    msgType: 'AutoStreamRequestAction';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
}

export interface EnvironmentConfig {
    featureFlags:               FeatureFlags;
    hdsGatewayUrl:              string | null;
    identityProviderGatewayUrl: string | null;
}

export interface FeatureFlags {
    autoStreaming:         boolean | null;
    creditDock:            boolean | null;
    hdsBlotter:            boolean | null;
    showCustomerAnalytics: boolean | null;
}

export interface InquirySnapshotComplete {
    /**
     * The message type
     */
    msgType: 'InquirySnapshotComplete';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
}

export interface Inquiry {
    actionFailures:               ActionFailure[];
    aggregateId:                  string;
    allowedActions:               ActionType[];
    assetClass:                   AssetClass | null;
    autoSpotFailed:               boolean | null;
    autoXMode:                    AutoXMode | null;
    autoXModeChangeDescription:   string | null;
    autoXModeChangeReason:        AutoXModeChangeReason | null;
    benchmarkHedge:               BenchmarkHedge | null;
    counterpartyFirm:             string;
    counterpartyRank:             number | null;
    counterpartyTrader:           string;
    counterpartyType:             CounterpartyType | null;
    customerOppositePackagePrice: string | null;
    customerPackagePrice:         string | null;
    eventSequence:                number;
    /**
     * Populated for compressions. Indicates whether floating leg indices are different or have
     * different tenors.
     */
    hasMixedIndices:                      boolean | null;
    hedgingType:                          HedgingType | null;
    inquiryCreationTimestampNanos:        string;
    isAllToAll:                           boolean | null;
    isCounterpartyAnonymous:              boolean | null;
    isMac:                                boolean | null;
    isPackageMisweighted:                 boolean | null;
    isPtmmRequired:                       boolean | null;
    isRfm:                                boolean;
    lastAckedActionTraderName:            string | null;
    lastInquiryStateUpdateTimestampNanos: string;
    latestQuotedMidPackagePrice:          string | null;
    latestQuotedOppositePackagePrice:     string | null;
    latestQuotedPackagePrice:             string | null;
    legs:                                 InquiryLeg[];
    listId:                               string | null;
    /**
     * The message type
     */
    msgType:                                 'Inquiry';
    numberOfDealers:                         number | null;
    numberOfListItems:                       number | null;
    packageCoverPrice:                       string | null;
    packageDelta:                            string | null;
    packageDirection:                        PackageDirection | null;
    packageMidCompositePrice:                string | null;
    packageQuoteCompetitiveStatus:           QuoteCompetitiveStatus | null;
    packageTradedAway:                       TradedAway | null;
    packageType:                             PackageType | null;
    priceBasisEventSequence:                 number | null;
    priceBasisMarketPackage:                 PackagePriceBasisInquiry | null;
    priceBasisOppositePackage:               PackagePriceBasisInquiry | null;
    priceBasisPreTradeMidMarketPackagePrice: string | null;
    priceGenerationTimestampNanos:           string | null;
    pricerCustomComponents:                  InquiryPricerCustomComponents | null;
    pricerCustomInfo:                        string | null;
    pricerError:                             string | null;
    pricerOppositePackagePrice:              string | null;
    pricerPackageMidPrice:                   string | null;
    pricerPackagePrice:                      string | null;
    pricerState:                             PricerState;
    priceTier:                               number | null;
    priceValidForNanos:                      string | null;
    quoteIsAlwaysFirm:                       boolean | null;
    salesPersonId:                           string | null;
    salesPersonName:                         string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos:             string;
    spotNegotiationModel:              SpotNegotiationModel | null;
    spotRequestTimeNanos:              string | null;
    state:                             InquiryState;
    ticketId:                          number;
    timerEndTimestampMillis:           string | null;
    timerStartTimestampMillis:         string | null;
    tradedPackagePrice:                string | null;
    triggerEvent:                      TriggerEvent;
    updateType:                        UpdateType;
    venueName:                         string;
    venueSpecifiedOnTheWireTimeMillis: number | null;
    /**
     * Regulatory qualifier of the trading venue
     */
    venueType: VenueType | null;
}

export interface ActionFailure {
    actionId:   string;
    actionType: ActionType;
    reason:     string;
    source:     ActionFailureSource;
    userId:     number;
}

export enum ActionType {
    DealerAccept = "DEALER_ACCEPT",
    DealerReject = "DEALER_REJECT",
    Quote = "QUOTE",
}

export enum ActionFailureSource {
    Transficc = "TRANSFICC",
    Venue = "VENUE",
}

export enum AssetClass {
    Credit = "CREDIT",
    Irs = "IRS",
}

export enum AutoXMode {
    AutoExecute = "AUTO_EXECUTE",
    AutoReject = "AUTO_REJECT",
    AutoStream = "AUTO_STREAM",
    Manual = "MANUAL",
}

export enum AutoXModeChangeReason {
    AutoxDisabled = "AUTOX_DISABLED",
    CircuitBreaker = "CIRCUIT_BREAKER",
    SolicitedAPIRequest = "SOLICITED_API_REQUEST",
    Stopped = "STOPPED",
}

export enum BenchmarkHedge {
    Auto = "AUTO",
    Manual = "MANUAL",
}

export enum CounterpartyType {
    Customer = "CUSTOMER",
    Dealer = "DEALER",
}

export enum HedgingType {
    Cross = "CROSS",
    Spot = "SPOT",
}

export interface InquiryLeg {
    askCompositePrice:                string | null;
    benchmarkName:                    string | null;
    bidCompositePrice:                string | null;
    clearingFirm:                     string | null;
    coverPrice:                       string | null;
    currency:                         string;
    customerAskPrice:                 string | null;
    customerBidPrice:                 string | null;
    effectiveDate:                    number | null;
    fixedRate:                        string | null;
    floatingRateSpread:               string | null;
    instrumentName:                   string;
    isInstrumentCustom:               boolean | null;
    latestQuotedAskPrice:             string | null;
    latestQuotedBidPrice:             string | null;
    latestQuotedMidPrice:             string | null;
    leg1DayCountConvention:           string | null;
    leg1PaymentFrequency:             string | null;
    leg1RateType:                     RateType | null;
    leg2DayCountConvention:           string | null;
    leg2PaymentFrequency:             string | null;
    leg2RateType:                     RateType | null;
    midCompositePrice:                string | null;
    minPriceIncrement:                string;
    negotiatedPrice:                  string | null;
    position:                         number;
    priceBasisAskPriceSource:         PriceSource | null;
    priceBasisAskPriceSpread:         string | null;
    priceBasisBidPriceSource:         PriceSource | null;
    priceBasisBidPriceSpread:         string | null;
    priceBasisManualAskRate:          string | null;
    priceBasisManualBidRate:          string | null;
    priceBasisPreTradeMidMarketPrice: string | null;
    pricerAsk:                        string | null;
    pricerBid:                        string | null;
    pricerMid:                        string | null;
    /**
     * Price type of the leg
     */
    priceType:              PriceType;
    quantity:               string;
    quoteCompetitiveStatus: QuoteCompetitiveStatus | null;
    referenceId:            string;
    relativeSettlementDate: string | null;
    settlementDate:         number | null;
    side:                   Side;
    terminationDate:        number | null;
    tieBreakPrice:          string | null;
    tradedAway:             TradedAway | null;
}

export enum RateType {
    Fixed = "FIXED",
    Floating = "FLOATING",
}

export enum PriceSource {
    AutoquoteAsk = "AUTOQUOTE_ASK",
    AutoquoteBid = "AUTOQUOTE_BID",
    AutoquoteMid = "AUTOQUOTE_MID",
    QuotedAsk = "QUOTED_ASK",
    QuotedBid = "QUOTED_BID",
    QuotedMid = "QUOTED_MID",
    VenueAsk = "VENUE_ASK",
    VenueBid = "VENUE_BID",
    VenueMid = "VENUE_MID",
}

/**
 * Price type of the leg
 */
export enum PriceType {
    InterestRate = "INTEREST_RATE",
    NetPresentValue = "NET_PRESENT_VALUE",
    PercentOfPar = "PERCENT_OF_PAR",
    Spread = "SPREAD",
    Yield = "YIELD",
}

export enum QuoteCompetitiveStatus {
    Best = "BEST",
    Cover = "COVER",
    CoverTied = "COVER_TIED",
    NotSet = "NOT_SET",
    Tied = "TIED",
}

export enum Side {
    Buy = "BUY",
    Sell = "SELL",
    Undisclosed = "UNDISCLOSED",
}

export enum TradedAway {
    No = "NO",
    NotSet = "NOT_SET",
    Yes = "YES",
}

export enum PackageDirection {
    Market = "MARKET",
    Opposite = "OPPOSITE",
    Undisclosed = "UNDISCLOSED",
}

export enum PackageType {
    Compression = "COMPRESSION",
    Curve = "CURVE",
    Outright = "OUTRIGHT",
}

export interface PackagePriceBasisInquiry {
    priceBasisManualPackagePrice: string | null;
    priceBasisPackagePriceSource: PriceSource | null;
    priceBasisPackagePriceSpread: string | null;
}

/**
 * Optional configurable components for augmenting the Trader Desktop UI with on demand
 * pricer provided data
 */
export interface InquiryPricerCustomComponents {
    /**
     * Button for copying a ticket handle value to the clipboard
     */
    copyHandleButton: PricerCustomComponentsCopyHandleButton | null;
    /**
     * URL pointing to a risk web-view associated with this inquiry
     */
    riskViewUrl: string | null;
}

/**
 * Button for copying a ticket handle value to the clipboard
 */
export interface PricerCustomComponentsCopyHandleButton {
    handle: string | null;
}

export enum PricerState {
    Error = "ERROR",
    Invalid = "INVALID",
    Pending = "PENDING",
    Priced = "PRICED",
    Rejected = "REJECTED",
    Terminated = "TERMINATED",
}

export enum SpotNegotiationModel {
    Venuepriced = "VENUEPRICED",
    VenuepricedDelayed = "VENUEPRICED_DELAYED",
}

export enum InquiryState {
    CurtainPeriod = "CURTAIN_PERIOD",
    CurtainQuoteStreaming = "CURTAIN_QUOTE_STREAMING",
    CustomerAccepted = "CUSTOMER_ACCEPTED",
    DealerAccepted = "DEALER_ACCEPTED",
    Done = "DONE",
    InquiryError = "INQUIRY_ERROR",
    InquiryPickedUpOnVenueUI = "INQUIRY_PICKED_UP_ON_VENUE_UI",
    LastLook = "LAST_LOOK",
    NewRequest = "NEW_REQUEST",
    NotDoneCustomerReject = "NOT_DONE_CUSTOMER_REJECT",
    NotDoneCustomerTimeout = "NOT_DONE_CUSTOMER_TIMEOUT",
    NotDoneDealerReject = "NOT_DONE_DEALER_REJECT",
    NotDoneDealerTimeout = "NOT_DONE_DEALER_TIMEOUT",
    PendingPriceConfirmation = "PENDING_PRICE_CONFIRMATION",
    PendingSpot = "PENDING_SPOT",
    QuoteFirm = "QUOTE_FIRM",
    QuoteOtw = "QUOTE_OTW",
    QuoteRefreshRequested = "QUOTE_REFRESH_REQUESTED",
    QuoteSubject = "QUOTE_SUBJECT",
    Streaming = "STREAMING",
    TradedPendingFeeAllocation = "TRADED_PENDING_FEE_ALLOCATION",
}

export enum TriggerEvent {
    Counter = "COUNTER",
    CurtainPeriodExpired = "CURTAIN_PERIOD_EXPIRED",
    CustomerAccept = "CUSTOMER_ACCEPT",
    CustomerCancel = "CUSTOMER_CANCEL",
    CustomerReject = "CUSTOMER_REJECT",
    CustomerTimeout = "CUSTOMER_TIMEOUT",
    DealerAccept = "DEALER_ACCEPT",
    DealerReject = "DEALER_REJECT",
    DealerTimeout = "DEALER_TIMEOUT",
    HitLift = "HIT_LIFT",
    InquiryError = "INQUIRY_ERROR",
    InquiryPickedUpOnVenue = "INQUIRY_PICKED_UP_ON_VENUE",
    LastLookExpired = "LAST_LOOK_EXPIRED",
    NegotiationReport = "NEGOTIATION_REPORT",
    OnTheWireTimeExpired = "ON_THE_WIRE_TIME_EXPIRED",
    PriceConfirmationAgreed = "PRICE_CONFIRMATION_AGREED",
    QuoteRefreshRequest = "QUOTE_REFRESH_REQUEST",
    QuoteRequest = "QUOTE_REQUEST",
    QuoteResponse = "QUOTE_RESPONSE",
    ReplaceSpot = "REPLACE_SPOT",
    SpotAgreed = "SPOT_AGREED",
    TieBreak = "TIE_BREAK",
    TradedNegotiationReport = "TRADED_NEGOTIATION_REPORT",
}

export enum UpdateType {
    Snapshot = "SNAPSHOT",
    Update = "UPDATE",
}

/**
 * Regulatory qualifier of the trading venue
 */
export enum VenueType {
    Mtf = "MTF",
    OffVenue = "OFF_VENUE",
    Sef = "SEF",
}

export interface ManualRequestAction {
    /**
     * The message type
     */
    msgType: 'ManualRequestAction';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
}

export interface OnDemandPrices {
    legs: OnDemandPricesLeg[];
    /**
     * The message type
     */
    msgType:                       'OnDemandPrices';
    oppositePackagePrice:          string | null;
    packageMidPrice:               string | null;
    packagePrice:                  string | null;
    priceGenerationTimestampNanos: string | null;
    pricerCustomComponents:        OnDemandPricesPricerCustomComponents | null;
    pricerCustomInfo:              string | null;
    priceValidForNanos:            string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
}

export interface OnDemandPricesLeg {
    ask:         string | null;
    bid:         string | null;
    mid:         string | null;
    referenceId: string;
}

/**
 * Optional configurable components for augmenting the Trader Desktop UI with on demand
 * pricer provided data
 */
export interface OnDemandPricesPricerCustomComponents {
    /**
     * Button for copying a ticket handle value to the clipboard
     */
    copyHandleButton: PricerCustomComponentsCopyHandleButton | null;
    /**
     * URL pointing to a risk web-view associated with this inquiry
     */
    riskViewUrl: string | null;
}

export interface PriceBasisUpdateAction {
    legs:          PriceBasisUpdateActionLeg[] | null;
    marketPackage: PriceBasisUpdateActionMarketPackage | null;
    /**
     * The message type
     */
    msgType:                       'PriceBasisUpdateAction';
    oppositePackage:               PriceBasisUpdateActionOppositePackage | null;
    preTradeMidMarketPackagePrice: string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
}

export interface PriceBasisUpdateActionLeg {
    askPriceSource:         PriceSource | null;
    askPriceSpread:         string | null;
    bidPriceSource:         PriceSource | null;
    bidPriceSpread:         string | null;
    manualAskPrice:         string | null;
    manualBidPrice:         string | null;
    preTradeMidMarketPrice: string | null;
    referenceId:            string;
}

export interface PriceBasisUpdateActionMarketPackage {
    manualPackagePrice: string | null;
    packagePriceSource: PriceSource | null;
    packagePriceSpread: string | null;
}

export interface PriceBasisUpdateActionOppositePackage {
    manualPackagePrice: string | null;
    packagePriceSource: PriceSource | null;
    packagePriceSpread: string | null;
}

export interface PriceBasis {
    eventSequence: number | null;
    legs:          PriceBasisLeg[] | null;
    marketPackage: PriceBasisMarketPackage | null;
    /**
     * The message type
     */
    msgType:                       'PriceBasis';
    oppositePackage:               PriceBasisOppositePackage | null;
    preTradeMidMarketPackagePrice: string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
}

export interface PriceBasisLeg {
    askPriceSource:         PriceSource | null;
    askPriceSpread:         string | null;
    bidPriceSource:         PriceSource | null;
    bidPriceSpread:         string | null;
    manualAskPrice:         string | null;
    manualBidPrice:         string | null;
    preTradeMidMarketPrice: string | null;
    referenceId:            string;
}

export interface PriceBasisMarketPackage {
    manualPackagePrice: string | null;
    packagePriceSource: PriceSource | null;
    packagePriceSpread: string | null;
}

export interface PriceBasisOppositePackage {
    manualPackagePrice: string | null;
    packagePriceSource: PriceSource | null;
    packagePriceSpread: string | null;
}

/**
 * Optional configurable components for augmenting the Trader Desktop UI with on demand
 * pricer provided data
 */
export interface PricerCustomComponents {
    /**
     * Button for copying a ticket handle value to the clipboard
     */
    copyHandleButton: PricerCustomComponentsCopyHandleButtonObject | null;
    /**
     * URL pointing to a risk web-view associated with this inquiry
     */
    riskViewUrl: string | null;
}

/**
 * Button for copying a ticket handle value to the clipboard
 */
export interface PricerCustomComponentsCopyHandleButtonObject {
    handle: string | null;
}

export interface QuoteAction {
    actionId:      string | null;
    eventSequence: number;
    legs:          QuoteLeg[] | null;
    /**
     * The message type
     */
    msgType:                       'QuoteAction';
    onTheWireTimeMs:               number | null;
    oppositePackagePrice:          string | null;
    packagePrice:                  string | null;
    preTradeMidMarketPackagePrice: string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
    transactTimeNs:        number;
}

export interface QuoteLeg {
    askPrice:               string;
    bidPrice:               string;
    preTradeMidMarketPrice: string | null;
    referenceId:            string;
}

export interface RejectAction {
    actionId:      string | null;
    eventSequence: number;
    /**
     * The message type
     */
    msgType: 'RejectAction';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number;
    transactTimeNs:        number;
}

export interface SubscriptionRequest {
    /**
     * The message type
     */
    msgType: 'SubscriptionRequest';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    subscriptionTopic:     string | null;
}

export interface SubscriptionResponse {
    error:        boolean | null;
    errorMessage: string | null;
    /**
     * The message type
     */
    msgType: 'SubscriptionResponse';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    subscriptionTopic:     string | null;
}

export interface TraderDesktopSettings {
    /**
     * The message type
     */
    msgType: 'TraderDesktopSettings';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    tradingStatus:         TradingStatus | null;
    userGroups:            UserGroup[] | null;
}

export enum TradingStatus {
    Off = "OFF",
    On = "ON",
}

export interface UserGroup {
    /**
     * the user group id
     */
    id: number;
    /**
     * the user group name
     */
    name: string;
}

export interface UpdateTraderDesktopSettings {
    /**
     * The message type
     */
    msgType: 'UpdateTraderDesktopSettings';
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    tradingStatus:         TradingStatus | null;
}

export interface ValidationFailure {
    actionId: string | null;
    /**
     * The message type
     */
    msgType: 'ValidationFailure';
    reason:  string | null;
    /**
     * Unix Epoch timestamp in nanoseconds
     */
    sendingTimestampNanos: string;
    ticketId:              number | null;
}
