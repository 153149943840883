import React from "react";
import * as IRSDomain from "../irs-domain";
import { BidMidAsk, InquirySide, PricerState, PriceValidity, SelectedDriver } from "../irs-domain";
import { colorByState, colorForSide } from "../irs-domain/colors";
import { IRSPriceAndSpread } from "../irs-price-and-spread/irs-price-and-spread";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { IRSIncrementButtons } from "../irs-increment-buttons/irs-increment-buttons";
import { IRSPtmm } from "../irs-ptmm/irs-ptmm";
import { IrsRfmPriceDriver } from "../irs-price-driver/irs-rfm-price-driver";
import { DateAndSize, LegContainer, LegInformationHeader, usePriceAndSpreadIncrements } from "./irs-leg-control-components";
import { Box } from "@transficc/components";
import { toolTipForLeg } from "../irs-domain/tool-tip";
import { useMinPriceIncrementAllLegsSelector, useMinPriceIncrementLegSelector } from "../irs-slice/irs-selectors";

interface IRSLegControlSideProps {
    customerRateValue: DecimalNumber | null;
    isCustomerRateInputDisabled: boolean;
    customerRateDisplayState: IRSDomain.DisplayState;
    isCustomerRateInvalid: boolean;

    spreadValue: DecimalNumber | null;

    isSpreadInputDisabled: boolean;
    selectedDriver: SelectedDriver | null;

    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

interface IRSSideCallbacks {
    updateCustomerRate: (customerRate: DecimalNumber) => void;
    onCustomerRateDirtyChange: (isDirty: boolean) => void;

    updateSpreadValue: (spreadValue: DecimalNumber) => void;
    onSpreadDirtyChange: (isDirty: boolean) => void;

    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
}

export interface IRSLegControlProps {
    model: {
        pay: IRSLegControlSideProps;
        rcv: IRSLegControlSideProps;

        autoQuoteDisplayState: IRSDomain.DisplayState;
        autoQuotePriceError: string | null;
        autoQuotePricerState: PricerState | null;
        autoQuotePriceValidity: PriceValidity | null;

        inquiryState: IRSDomain.InquiryState;
        isInquiryFinished: boolean;

        leg: IRSDomain.IRSLeg<"outright" | "curve">;
        isMac: boolean;
    };
    callbacks: {
        pay: IRSSideCallbacks;
        rcv: IRSSideCallbacks;

        updatePtmmValue: (ptmmValue: DecimalNumber) => void;
    };
    legPosition: number;
}

const LegBody: React.FC<IRSLegControlProps> = ({ legPosition, model, callbacks }) => {
    const { inquiryLegBodyBackgroundColor } = colorByState(model.inquiryState);
    const tickSize = useMinPriceIncrementAllLegsSelector();
    const minPriceIncrement = useMinPriceIncrementLegSelector(legPosition);
    const { rateTickSize, spreadTickSize, onNewTickSizeSelected, spreadMinPriceIncrement, tickSizeLabels } = usePriceAndSpreadIncrements(
        model.leg.priceType,
        minPriceIncrement,
        tickSize,
    );

    const priceTitle = model.isMac ? `Fixed @ ${model.leg.fixedRate?.trim()}%` : "";
    const priceTitleSelector = model.isMac ? "Fee" : "Rate";
    const priceToolTip = toolTipForLeg(model.isMac, model.leg.priceType);

    return (
        <Box width={"full"} padding={"2"} backgroundColor={inquiryLegBodyBackgroundColor} contentAlignment={"center"}>
            <DateAndSize legPosition={legPosition} />
            <Box margin={"1"} />
            <IRSIncrementButtons labels={tickSizeLabels} onSelectedIndexChange={onNewTickSizeSelected} />
            <Box contentDirection="row" contentAlignment="center" contentJustification="center">
                <IRSPriceAndSpread
                    testId="irs-price-rate-pay"
                    priceProps={{
                        config: {
                            minPriceIncrement,
                            tickButtonSize: rateTickSize,
                            title: priceTitle,
                            titleSelector: priceTitleSelector,
                            size: "medium",
                            showTitleText: true,
                            titleLabel: {
                                text: "PAY",
                                backgroundColor: colorForSide(InquirySide.Buy),
                                foregroundColor: { color: "black" },
                            },
                            tooltip: priceToolTip,
                        },
                        model: {
                            value:
                                model.isInquiryFinished && model.leg.negotiatedPrice
                                    ? new ImmutableDecimalNumber(model.leg.negotiatedPrice)
                                    : model.pay.customerRateValue,
                            disabled: model.pay.isCustomerRateInputDisabled,
                            selected: model.pay.selectedDriver === null,
                            invalid: model.pay.isCustomerRateInvalid,
                            displayState: model.pay.customerRateDisplayState,
                            inputForegroundColor: { color: "black" },
                            inputBackgroundColor: colorForSide(InquirySide.Buy),
                        },
                        callbacks: {
                            onValueChange: callbacks.pay.updateCustomerRate,
                            onDirtyChange: callbacks.pay.onCustomerRateDirtyChange,
                        },
                    }}
                    spreadProps={{
                        config: {
                            minPriceIncrement: spreadMinPriceIncrement,
                            tickButtonSize: spreadTickSize,
                            size: "small",
                        },
                        model: {
                            value: model.pay.spreadValue,
                            disabled: model.pay.isSpreadInputDisabled,
                        },
                        callbacks: {
                            onValueChange: callbacks.pay.updateSpreadValue,
                            onDirtyChange: callbacks.pay.onSpreadDirtyChange,
                        },
                    }}
                />
                <Box margin="4" />
                <IRSPriceAndSpread
                    testId="irs-price-rate-rcv"
                    priceProps={{
                        config: {
                            minPriceIncrement,
                            tickButtonSize: rateTickSize,
                            title: priceTitle,
                            titleSelector: priceTitleSelector,
                            showTitleText: true,
                            titleLabel: {
                                text: "RCV",
                                backgroundColor: colorForSide(InquirySide.Sell),
                                foregroundColor: { color: "black" },
                            },
                            size: "medium",
                            tooltip: priceToolTip,
                        },
                        model: {
                            value:
                                model.isInquiryFinished && model.leg.negotiatedPrice
                                    ? new ImmutableDecimalNumber(model.leg.negotiatedPrice)
                                    : model.rcv.customerRateValue,
                            disabled: model.rcv.isCustomerRateInputDisabled,
                            selected: model.rcv.selectedDriver === null,
                            invalid: model.rcv.isCustomerRateInvalid,
                            displayState: model.rcv.customerRateDisplayState,
                            inputForegroundColor: { color: "black" },
                            inputBackgroundColor: colorForSide(InquirySide.Sell),
                        },
                        callbacks: {
                            onValueChange: callbacks.rcv.updateCustomerRate,
                            onDirtyChange: callbacks.rcv.onCustomerRateDirtyChange,
                        },
                    }}
                    spreadProps={{
                        config: {
                            minPriceIncrement: spreadMinPriceIncrement,
                            tickButtonSize: spreadTickSize,
                            size: "small",
                        },
                        model: {
                            value: model.rcv.spreadValue,
                            disabled: model.rcv.isSpreadInputDisabled,
                        },
                        callbacks: {
                            onValueChange: callbacks.rcv.updateSpreadValue,
                            onDirtyChange: callbacks.rcv.onSpreadDirtyChange,
                        },
                    }}
                />
            </Box>
            <IRSPtmm
                tickButtonSize={rateTickSize}
                minPriceIncrement={minPriceIncrement}
                size={"small"}
                onValueChange={callbacks.updatePtmmValue}
                legPosition={legPosition}
            />
        </Box>
    );
};

export const IrsDoubleSidedLegControl: React.FC<IRSLegControlProps> = ({ model, callbacks, legPosition }) => {
    return (
        <Box contentAlignment="center" data-testid={`irs-leg-${legPosition}`}>
            <LegContainer>
                <LegInformationHeader legPosition={legPosition} />
                <LegBody model={model} callbacks={callbacks} legPosition={legPosition} />
            </LegContainer>
            <Box margin="2" />
            <IrsRfmPriceDriver
                config={{
                    leftSideConfig: {
                        cellSelectionColor: { color: "rose", level: "300" },
                        cellSelectedBackgroundColor: colorForSide(InquirySide.Buy),
                        cellSelectionLabel: "Pay",
                        selectedForegroundColor: { color: "black" },
                    },
                    rightSideConfig: {
                        cellSelectionColor: { color: "cyan", level: "300" },
                        cellSelectedBackgroundColor: colorForSide(InquirySide.Sell),
                        cellSelectionLabel: "Rcv",
                        selectedForegroundColor: { color: "black" },
                    },
                }}
                model={{
                    leftSide: {
                        selectedDriver: model.pay.selectedDriver,
                        prices: {
                            autoQuotePrices: model.pay.autoQuotePrices,
                            venuePrices: model.pay.venuePrices,
                            latestQuotedPrices: model.pay.latestQuotedPrices,
                        },
                    },
                    rightSide: {
                        selectedDriver: model.rcv.selectedDriver,
                        prices: {
                            autoQuotePrices: model.rcv.autoQuotePrices,
                            venuePrices: model.rcv.venuePrices,
                            latestQuotedPrices: model.rcv.latestQuotedPrices,
                        },
                    },

                    autoQuoteDisplayState: model.autoQuoteDisplayState,
                    autoQuoteErrorMessage: model.autoQuotePriceError,
                    autoQuotePricerState: model.autoQuotePricerState,
                    autoQuotePriceValidity: model.autoQuotePriceValidity,

                    disabled: model.isInquiryFinished,
                }}
                callbacks={{
                    leftSide: {
                        selectDriver: callbacks.pay.selectDriver,
                        clearDriver: callbacks.pay.clearDriverValue,
                    },
                    rightSide: {
                        selectDriver: callbacks.rcv.selectDriver,
                        clearDriver: callbacks.rcv.clearDriverValue,
                    },
                }}
            />
        </Box>
    );
};
