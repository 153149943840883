import * as React from "react";
import { useEffect } from "react";
import { Box } from "@transficc/components";
import { useElectronApi, useEnvironmentConfig, useTimezoneProvider } from "@transficc/trader-desktop-application-context";
import { useDefinitelyAccessToken, useUserGroups } from "./redux/slices/session/useSessionSliceSelectors";
import { Navigate } from "react-router-dom";
import { HistoricalBlotter as Blotter } from "@transficc/trader-desktop-historical-blotter";

const HistoricalBlotter: React.FC = () => {
    const environmentConfig = useEnvironmentConfig();
    const timezoneProvider = useTimezoneProvider();
    const electronApi = useElectronApi();
    const accessToken = useDefinitelyAccessToken();
    const userGroups = useUserGroups();
    const hdsGatewayUrl = environmentConfig.hdsGatewayUrl;

    useEffect(() => {
        const title = "Historical Blotter";
        document.title = title;
        electronApi.setWindowTitle(title);
    }, [electronApi]);

    if (!hdsGatewayUrl) {
        return <Navigate to="/" />;
    }

    return (
        <Box data-testid="historical-blotter" height={"full"}>
            <Blotter hdsGatewayUrl={hdsGatewayUrl} accessToken={accessToken} timezoneProvider={timezoneProvider} userGroups={userGroups} />
        </Box>
    );
};

export default HistoricalBlotter;
