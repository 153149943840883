export enum InquirySide {
    Buy = "buy",
    Sell = "sell",
}

export enum PriceType {
    PercentOfPar = "price",
    Spread = "spread",
    Yield = "yield",
}

export enum InquiryState {
    InquiryError = "inquiry_error",
    InquiryPickedUpOnVenueUI = "inquiry_picked_up_on_venue_ui",

    DealerAccepted = "dealer_accepted",
    CustomerAccepted = "customer_accepted",

    CurtainPeriod = "curtain_period",
    NewRequest = "new_request",
    CurtainQuoteStreaming = "curtain_quote_streaming",
    QuoteOTW = "quote_otw",
    QuoteSubject = "quote_subject",
    QuoteFirm = "quote_firm",
    QuoteRefreshRequested = "quote_refresh_requested",
    LastLook = "last_look",
    PendingSpot = "pending_spot",
    PendingPriceConfirmation = "pending_price_confirmation",

    Done = "done",

    CustomerReject = "not_done_customer_reject",
    CustomerTimeout = "not_done_customer_timeout",
    DealerReject = "not_done_dealer_reject",
    DealerTimeout = "not_done_dealer_timeout",
    AutoSpotFailed = "auto_spot_failed",
}

export enum PricerState {
    Error = "error",
    Invalid = "invalid",
    Pending = "pending",
    Priced = "priced",
    Rejected = "rejected",
    Terminated = "terminated",
}

export function displayTextFor(priceState: PricerState | null): string | undefined {
    switch (priceState) {
        case null:
        case undefined:
        case PricerState.Priced:
        case PricerState.Terminated:
            return undefined;
        case PricerState.Invalid:
        case PricerState.Error:
            return "Error";
        case PricerState.Pending:
            return "Pending";
        case PricerState.Rejected:
            return "N/A";
    }
}
export function backgroundColorFor(priceState: PricerState | null): Theme.Color | undefined {
    switch (priceState) {
        case PricerState.Invalid:
        case PricerState.Error:
            return { color: "danger", level: "500" };
        case PricerState.Rejected:
            return { color: "warning", level: "500" };
        default:
            return undefined;
    }
}
