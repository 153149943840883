import React from "react";
import { Box, Text, ThemeUtils } from "@transficc/components";
import styled from "styled-components";

interface DataItem {
    term: string;
    value: string;
}

interface DataGroup {
    header: string;
    items: DataItem[];
}

const data: DataGroup[] = [
    {
        header: "MM",
        items: [
            { term: "1D", value: "0" },
            { term: "FUT (1M)", value: "0" },
        ],
    },
    {
        header: "STIR",
        items: [
            { term: "FUT (3M) Mar24", value: "0" },
            { term: "FUT (3M) Jun24", value: "0" },
            { term: "FUT (3M) Sep24", value: "0" },
            { term: "FUT (3M) Dec24", value: "300" },
            { term: "FUT (3M) Mar25", value: "560" },
            { term: "18M", value: "1,100" },
        ],
    },
    {
        header: "IRS",
        items: [
            { term: "2Y", value: "6,100" },
            { term: "4Y", value: "10,458" },
            { term: "5Y", value: "-40,458" },
            { term: "6Y", value: "-60,000" },
            { term: "7Y", value: "650" },
            { term: "8Y", value: "0" },
            { term: "9Y", value: "0" },
            { term: "10Y", value: "6,100" },
            { term: "12Y", value: "80" },
            { term: "15Y", value: "-15,458" },
            { term: "20Y", value: "0" },
            { term: "25Y", value: "0" },
            { term: "30Y", value: "0" },
        ],
    },
];

const Table = styled.table`
    text-align: center;
    border: none;
    border-collapse: collapse;
`;

const TH = styled.th`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => ThemeUtils.colorSelector(theme, { color: "gray", level: "400" })};
    border-collapse: collapse;
    color: white;
`;
const TD = styled.td`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => ThemeUtils.colorSelector(theme, { color: "gray", level: "400" })};
    border-collapse: collapse;
    background: white;
`;

const TDSPACE = styled.td`
    background: transparent;
    border: none;
`;

const TDRED = styled(TD)`
    color: ${({ theme }) => ThemeUtils.colorSelector(theme, { color: "danger", level: "500" })};
`;

const MainTable: React.FC = () => {
    return (
        <Box>
            <Table width={"100%"} className="table table-condensed table-hover details-table">
                <tbody>
                    <tr>
                        <TDSPACE></TDSPACE>
                        <TH colSpan={3}>USD SOFR</TH>
                    </tr>
                    <tr>
                        <TDSPACE width={"20%"}></TDSPACE>
                        <TD width={"40%"}>Flat</TD>
                        <TDRED width={"40%"}>-50k</TDRED>
                    </tr>
                    {data.map((group, groupIdx) =>
                        group.items.map((item, itemIdx) => (
                            <tr key={groupIdx + "-" + itemIdx}>
                                {itemIdx === 0 ? <TH rowSpan={group.items.length}>{group.header}</TH> : null}
                                <TD>{item.term}</TD>
                                {item.value.startsWith("-") ? <TDRED>{item.value}</TDRED> : <TD>{item.value}</TD>}
                            </tr>
                        )),
                    )}
                </tbody>
            </Table>
        </Box>
    );
};

const LCHvsCCPTable: React.FC = () => {
    return (
        <Box>
            <Table width={"100%"} className="table table-condensed table-hover details-table">
                <tbody>
                    <tr>
                        <TH>LCH vs CME</TH>
                    </tr>
                    <tr>
                        <TDRED>-75,456</TDRED>
                    </tr>
                </tbody>
            </Table>
        </Box>
    );
};

export interface RiskViewStaticExampleProps {
    numberOfItems: string | null;
}

export const RiskViewStaticExample: React.FC<RiskViewStaticExampleProps> = ({ numberOfItems }) => {
    return (
        <Box>
            <Box
                contentDirection={"row"}
                height={"12"}
                backgroundColor={{ color: "cyan", level: "700" }}
                contentAlignment={"center"}
                padding={"2"}
                marginB={"2"}
            >
                <Box>
                    <Text size={"xl"}>Risk View</Text>
                </Box>
                <Box marginL={"auto"}>
                    <Text textColor={{ color: "gray", level: "400" }}>{new Date().toUTCString()}</Text>
                </Box>
            </Box>
            <Box
                contentDirection={"row"}
                height={"12"}
                backgroundColor={{ color: "gray", level: "300" }}
                contentAlignment={"center"}
                padding={"2"}
                marginB={"8"}
            >
                <Box>
                    <Text textColor={{ color: "black" }} size={"xl"}>
                        USD IRS List ({numberOfItems ?? "10"} Items)
                    </Text>
                </Box>
                <Box
                    rounded
                    marginL={"12"}
                    backgroundColor={{ color: "warning", level: "400" }}
                    contentJustification="space-around"
                    padding={"1"}
                >
                    <Text textColor={{ color: "black" }} size={"lg"}>
                        Mixed CCPs
                    </Text>
                </Box>
            </Box>

            <Box contentDirection={"row"} width={"full"}>
                <Box width={"96"}>
                    <MainTable />
                </Box>
                <Box width={"52"} paddingL={"4"} paddingR={"4"}>
                    <LCHvsCCPTable />
                </Box>
            </Box>
        </Box>
    );
};
