import React from "react";
import { Box, Icon, IconNames, Text } from "../../atoms";
import { DecimalNumber } from "@transficc/infrastructure";
import { BorderType } from "../../theme/theme-utils";
import { NumberInputReducerView, useNumberInputReducer } from "./number-input-reducer";
import { AnnotatedComponent } from "../annotated-component/annotated-component";
import { Input } from "../input/input";

export type NumberInputSize = "small" | "medium" | "large";
export type NumberInputDisplayType = "input" | "box";

export interface NumberInputTitleLabelConfig {
    backgroundColor: Theme.Color;
    foregroundColor: Theme.Color;
    text: string;
}

export interface NumberInputConfig {
    snapToMultiplesOf: DecimalNumber;
    size: NumberInputSize;
    titleText: string;
    showTitleText: boolean;
    // note that the reducer relies on the assumption that this is an empty string or a dash
    initialDisplayText: "" | "-";
    titleTextSelector: string;
    formatWithCommas?: boolean;
    inputFontSize?: Theme.FontSizes;
    titleLabel?: NumberInputTitleLabelConfig;
    invalidText?: string;
    toolTip?: string;
}

export interface NumberInputModel {
    value: DecimalNumber | null;
    invalid: boolean;
    disabled: boolean;
    selected: boolean;
    displayType: NumberInputDisplayType;
    boxDisplayText?: string;
    boxForegroundColor?: Theme.Color;
    boxBackgroundColor?: Theme.Color;
    inputForegroundColor?: Theme.Color;
    inputBackgroundColor?: Theme.Color;
    increment: DecimalNumber;
}

export interface NumberInputCallbacks {
    onValueChange: (newValue: DecimalNumber) => void;
    onDirtyChange: (isDirty: boolean) => void;
}

export interface NumberInputProps {
    config: NumberInputConfig;
    model: NumberInputModel;
    callbacks: NumberInputCallbacks;
}

interface NumberInputSizeConfig {
    spacingLevel: Theme.SpacingLevels;
    inputBorderWidth: Theme.SpacingLevels;
    width: Theme.SpacingLevels;
    inputFontSize: Theme.FontSizes;
    smallestInputFontSize: Theme.FontSizes;
    titleFontSize: Theme.FontSizes;
}

const sizeValues: Record<NumberInputSize, NumberInputSizeConfig> = {
    small: {
        spacingLevel: "6",
        inputBorderWidth: "px",
        width: "44",
        inputFontSize: "sm",
        smallestInputFontSize: "xs",
        titleFontSize: "xs",
    },
    medium: {
        spacingLevel: "8",
        inputBorderWidth: "px",
        width: "52",
        inputFontSize: "lg",
        smallestInputFontSize: "xs",
        titleFontSize: "sm",
    },
    large: {
        spacingLevel: "10",
        inputBorderWidth: "0.5",
        width: "64",
        inputFontSize: "2xl",
        smallestInputFontSize: "base",
        titleFontSize: "base",
    },
};

function getMainColor(model: NumberInputModel): Theme.Color {
    return model.disabled ? { color: "gray", level: "400" } : { color: "white" };
}

function getBorder(config: NumberInputConfig, model: NumberInputModel, focused: boolean): BorderType {
    const width = model.invalid || model.selected ? "1" : sizeValues[config.size].inputBorderWidth;
    const color: Theme.Color = model.invalid
        ? { color: "warning", level: "500" }
        : model.disabled
        ? { color: "gray", level: "400" }
        : focused
        ? { color: "warning", level: "400" }
        : model.selected
        ? { color: "success", level: "300" }
        : { color: "white" };
    return {
        color,
        width,
        style: "solid",
    };
}

function getTitle(config: NumberInputConfig, model: NumberInputModel): JSX.Element | null {
    if (config.showTitleText || config.titleLabel) {
        const mainColor = getMainColor(model);
        const numberInputSizeConfig = sizeValues[config.size];
        return (
            <Box width="full" contentDirection={"row"} contentAlignment={"center"} contentJustification={"center"} marginB="0.5">
                {config.titleLabel && (
                    <Box
                        contentDirection={"row"}
                        contentAlignment={"center"}
                        padding={"0"}
                        paddingL={"2"}
                        paddingR={"2"}
                        marginR={"2"}
                        rounded
                        backgroundColor={config.titleLabel.backgroundColor}
                    >
                        <Text size={"xs"} textColor={config.titleLabel.foregroundColor}>
                            {config.titleLabel.text}
                        </Text>
                    </Box>
                )}

                {config.showTitleText && (
                    <Text textColor={mainColor} size={numberInputSizeConfig.titleFontSize}>
                        {config.titleText}
                    </Text>
                )}
            </Box>
        );
    } else {
        return null;
    }
}

const InputBox: React.FC<{
    config: NumberInputConfig;
    model: NumberInputModel;
    inputRef: React.Ref<HTMLInputElement>;
    focused: boolean;
    inputDisplayText: string;
    onClickBox: (e: React.MouseEvent<HTMLElement>) => void;
    onChangeInput: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlurInput: () => void;
    onFocusInput: () => void;
    onKeyDownInput: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}> = ({
    config,
    model,
    inputRef,
    focused,
    inputDisplayText,
    onClickBox,
    onChangeInput,
    onBlurInput,
    onFocusInput,
    onKeyDownInput,
    ...props
}) => {
    const numberInputSizeConfig = sizeValues[config.size];
    const inputFontSize = inputDisplayText.length > 9 ? numberInputSizeConfig.smallestInputFontSize : numberInputSizeConfig.inputFontSize;
    const border = getBorder(config, model, focused);
    if (model.displayType === "box" && !focused) {
        const onClick = (e: React.MouseEvent<HTMLElement>): void => {
            if (!model.disabled) {
                onClickBox(e);
            }
        };
        return (
            <Box
                {...props}
                height={numberInputSizeConfig.spacingLevel}
                width={"full"}
                contentDirection={"column"}
                contentAlignment={"center"}
                contentJustification={"center"}
                border={border}
                marginL="1"
                marginR="1"
                backgroundColor={model.boxBackgroundColor}
                padding="2"
                onClick={onClick}
                data-is-selected={model.selected}
                disabled={model.disabled}
            >
                <Text
                    textColor={model.disabled ? { color: "gray", level: "500" } : model.boxForegroundColor}
                    size={numberInputSizeConfig.titleFontSize}
                >
                    {model.boxDisplayText}
                </Text>
            </Box>
        );
    } else {
        return (
            <Input
                {...props}
                as="input"
                ref={inputRef}
                disabled={model.disabled}
                height={numberInputSizeConfig.spacingLevel}
                width={"full"}
                fontSize={inputFontSize}
                border={border}
                marginL="1"
                marginR="1"
                backgroundColor={model.inputBackgroundColor}
                foregroundColor={model.inputForegroundColor}
                padding="2"
                type="text"
                value={inputDisplayText}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                onFocus={onFocusInput}
                onKeyDown={onKeyDownInput}
                data-is-selected={model.selected}
                title={config.toolTip ?? ""}
            />
        );
    }
};

function getTickBox(
    config: NumberInputConfig,
    model: NumberInputModel,
    iconName: IconNames.Increase | IconNames.Decrease,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
): JSX.Element {
    const selectorText = config.titleTextSelector;
    const titleText = `${selectorText} - ${iconName === IconNames.Decrease ? "Tick Down" : "Tick Up"}`;
    const numberInputSizeConfig = sizeValues[config.size];
    const tickButtonDisabled = model.disabled || model.displayType === "box";
    const mainColor = getMainColor(model);

    return (
        <Box marginT="0.5">
            <Text srOnly>{titleText}</Text>
            <Icon
                disabled={tickButtonDisabled}
                iconColor={mainColor}
                name={iconName}
                size={numberInputSizeConfig.spacingLevel}
                onClick={onClick}
            />
        </Box>
    );
}

export const NumberInput: React.FC<NumberInputProps> = ({ config, model, callbacks, ...props }) => {
    const inputRef: React.RefObject<HTMLInputElement> = React.createRef();
    const {
        focused,
        inputDisplayText,
        onClickBox,
        onChangeInput,
        onBlurInput,
        onFocusInput,
        onKeyDownInput,
        onClickTickDown,
        onClickTickUp,
    }: NumberInputReducerView = useNumberInputReducer(inputRef, config, model, callbacks);

    const numberInputSizeConfig = sizeValues[config.size];
    const title = getTitle(config, model);
    const tickDownBox = getTickBox(config, model, IconNames.Decrease, onClickTickDown);
    const tickUpBox = getTickBox(config, model, IconNames.Increase, onClickTickUp);

    return (
        <Box contentDirection={"column"} width={numberInputSizeConfig.width}>
            {title}
            <Box width="full" contentDirection={"row"} contentAlignment={"start"}>
                {tickDownBox}
                <AnnotatedComponent visible={model.invalid} label={config.titleTextSelector} message={config.invalidText ?? ""}>
                    <InputBox
                        {...props}
                        config={config}
                        model={model}
                        inputRef={inputRef}
                        focused={focused}
                        inputDisplayText={inputDisplayText}
                        onClickBox={onClickBox}
                        onChangeInput={onChangeInput}
                        onBlurInput={onBlurInput}
                        onFocusInput={onFocusInput}
                        onKeyDownInput={onKeyDownInput}
                    />
                </AnnotatedComponent>
                {tickUpBox}
            </Box>
        </Box>
    );
};
