import React from "react";
import { DecimalNumber } from "@transficc/infrastructure";
import { backgroundColorFor, DisplayState, displayTextFor, foregroundColorFor } from "../irs-domain";
import styled from "styled-components";
import { Box, BoxProps, NumberInput, NumberInputSize, NumberInputTitleLabelConfig } from "@transficc/components";

export interface IRSPriceAndSpreadProps {
    priceProps: PriceProps;
    spreadProps: SpreadProps;
    testId?: string;
}

export interface PriceProps {
    config: {
        tickButtonSize: DecimalNumber;
        minPriceIncrement: DecimalNumber;
        title: string;
        showTitleText: boolean;
        titleSelector: string;
        formatWithCommas?: boolean;
        titleLabel?: NumberInputTitleLabelConfig;
        size: NumberInputSize;
        tooltip?: string;
    };
    model: {
        value: DecimalNumber | null;
        disabled: boolean;
        selected: boolean;
        invalid: boolean;
        displayState: DisplayState;
        inputForegroundColor?: Theme.Color;
        inputBackgroundColor?: Theme.Color;
    };
    callbacks: {
        onValueChange: (value: DecimalNumber) => void;
        onDirtyChange: (isDirty: boolean) => void;
    };
}

export interface SpreadProps {
    config: {
        tickButtonSize: DecimalNumber;
        minPriceIncrement: DecimalNumber;
        formatWithCommas?: boolean;
        size: NumberInputSize;
    };
    model: {
        value: DecimalNumber | null;
        disabled: boolean;
    };
    callbacks: {
        onValueChange: (value: DecimalNumber) => void;
        onDirtyChange: (isDirty: boolean) => void;
    };
}

const Container = styled(Box).attrs<{ marginTop: Theme.SpacingLevels }>(
    ({ marginTop }): BoxProps => ({ width: "full", marginT: marginTop, contentAlignment: "center" }),
)<{ marginTop: Theme.SpacingLevels }>``;

export const IRSPriceAndSpread: React.FC<IRSPriceAndSpreadProps> = ({ priceProps, spreadProps, testId }) => {
    return (
        <Box data-testid={testId}>
            <Container marginTop="0">
                <NumberInput
                    config={{
                        titleText: priceProps.config.title,
                        showTitleText: priceProps.config.showTitleText,
                        titleTextSelector: priceProps.config.titleSelector,
                        size: priceProps.config.size,
                        initialDisplayText: "",
                        snapToMultiplesOf: priceProps.config.minPriceIncrement,
                        formatWithCommas: priceProps.config.formatWithCommas,
                        titleLabel: priceProps.config.titleLabel,
                        invalidText: "Stale Price",
                        toolTip: priceProps.config.tooltip,
                    }}
                    model={{
                        value: priceProps.model.value,
                        disabled: priceProps.model.disabled,
                        selected: priceProps.model.selected,
                        invalid: priceProps.model.invalid,
                        displayType: priceProps.model.displayState === DisplayState.Valid ? "input" : "box",
                        boxDisplayText: displayTextFor(priceProps.model.displayState),
                        boxForegroundColor: foregroundColorFor(priceProps.model.displayState),
                        boxBackgroundColor: backgroundColorFor(priceProps.model.displayState),
                        inputBackgroundColor: priceProps.model.inputBackgroundColor,
                        inputForegroundColor: priceProps.model.inputForegroundColor,
                        increment: priceProps.config.tickButtonSize,
                    }}
                    callbacks={{
                        onValueChange: priceProps.callbacks.onValueChange,
                        onDirtyChange: priceProps.callbacks.onDirtyChange,
                    }}
                />
            </Container>
            <Container marginTop="2">
                <NumberInput
                    config={{
                        titleText: "Spread",
                        titleTextSelector: "Spread",
                        showTitleText: true,
                        size: spreadProps.config.size,
                        initialDisplayText: "-",
                        snapToMultiplesOf: spreadProps.config.minPriceIncrement,
                        formatWithCommas: spreadProps.config.formatWithCommas,
                    }}
                    model={{
                        value: spreadProps.model.value,
                        disabled: spreadProps.model.disabled,
                        invalid: false,
                        selected: false,
                        displayType: "input",
                        increment: spreadProps.config.tickButtonSize,
                    }}
                    callbacks={{
                        onValueChange: spreadProps.callbacks.onValueChange,
                        onDirtyChange: spreadProps.callbacks.onDirtyChange,
                    }}
                />
            </Container>
        </Box>
    );
};
