import { DisplayState, InquiryState, IRSInquiry, mapPricerStateToDisplayState, SelectedDriver } from "../irs-domain";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { Draft, produce } from "immer";
import { computeManualPtmmValue, computePtmmValue } from "../irs-domain/ptmm";
import { getSelectedDriver } from "../irs-domain/price-source";
import {
    CloseActionFailureAction,
    IRSCompressionReducerAction,
    IRSCompressionReducerState,
    Side,
    SideReducerState,
} from "./irs-compression-reducer-types";

export const compressionReducerInitialState: IRSCompressionReducerState = {
    inquiry: null,
    autoXModeChangeReason: null,
    priceBasis: {
        sequenceNumber: 0,
    },
    market: {
        packageFeeValue: null,
        selectedDriver: null,
        spreadValue: null,
        manualPackageFeeValue: null,
        roundedVenueMidPackagePrice: null,
        roundedAutoQuotedPackagePrice: null,
        roundedAutoQuotedOppositePackagePrice: null,
        roundedAutoQuotedPackageMidPrice: null,
    },
    opposite: {
        packageFeeValue: null,
        selectedDriver: null,
        spreadValue: null,
        manualPackageFeeValue: null,
        roundedVenueMidPackagePrice: null,
        roundedAutoQuotedPackagePrice: null,
        roundedAutoQuotedOppositePackagePrice: null,
        roundedAutoQuotedPackageMidPrice: null,
    },
    isDirty: false,
    roundedLatestQuotedPackagePrice: null,
    roundedLatestQuotedMidPackagePrice: null,
    roundedLatestQuotedOppositePackagePrice: null,
    roundedPtmm: null,
    manualPtmm: null,
    shouldPublishPriceBasisUpdate: false,
    latestActionFailure: null,
    closedActionFailures: [],
};

const roundToInteger = (value: string | null): string | null => {
    if (value === null) {
        return null;
    }

    const valueAsFloat = parseFloat(value);
    if (Number.isFinite(valueAsFloat)) {
        return valueAsFloat.toFixed(0);
    }
    return null;
};

const negate = (value: string | null): string | null => {
    if (value === null) {
        return null;
    }

    const valueAsFloat = parseFloat(value);
    if (Number.isFinite(valueAsFloat)) {
        return (-valueAsFloat).toString();
    }
    return null;
};

function onCloseActionFailure(draft: Draft<IRSCompressionReducerState>, action: CloseActionFailureAction): void {
    if (draft.latestActionFailure) {
        if (draft.latestActionFailure.actionId === action.actionId) {
            draft.latestActionFailure.closed = true;
            draft.closedActionFailures.push(action.actionId);
        }
    }
}

export function irsCompressionReducer(state: IRSCompressionReducerState, action: IRSCompressionReducerAction): IRSCompressionReducerState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "UpdateInquiry": {
                onUpdateInquiry(draft, action);
                break;
            }
            case "UpdatePackageFeeValue": {
                onUpdatePackageFeeValue(draft, action.newValue, action.side);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "UpdateSpreadValue": {
                onUpdateSpreadValue(draft, action.args.spreadValue, action.args.increment, action.args.side);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "SelectDriver": {
                onSelectDriver(draft, action.driver, action.side);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "DeselectDriver": {
                onDeselectDriver(draft, action.side);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "SetIsDirty": {
                draft.isDirty = action.isDirty;
                break;
            }
            case "UpdatePtmmValue": {
                onUpdatePtmmValue(draft, action.newValue);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "PriceBasisUpdateSent": {
                draft.shouldPublishPriceBasisUpdate = false;
                break;
            }
            case "ClearCircuitBreaker":
                draft.autoXModeChangeReason = null;
                break;
            case "CloseActionFailure": {
                onCloseActionFailure(draft, action);
                break;
            }
        }
    });
}

function shouldSendPriceBasisMessage(draft: Draft<IRSCompressionReducerState>): void {
    draft.priceBasis.sequenceNumber++;
    draft.shouldPublishPriceBasisUpdate = true;
}

function isDrivenByLatestQuotedPrice(draft: Draft<IRSCompressionReducerState>, side: Side): boolean {
    switch (side) {
        case "Market":
            return draft.market.selectedDriver?.rowNo === 0 && draft.market.selectedDriver.columnNo === 0;
        case "Opposite":
            return draft.opposite.selectedDriver?.rowNo === 0 && draft.opposite.selectedDriver.columnNo === 2;
    }
}

const inQuoteRefreshRequested = (draft: Draft<IRSCompressionReducerState>): boolean => {
    return draft.inquiry?.state === InquiryState.QuoteRefreshRequested;
};

const updatePriceBasisSequenceNumber = (
    draft: Draft<IRSCompressionReducerState>,
    action: { type: "UpdateInquiry"; args: { inquiry: IRSInquiry<"compression">; increment: DecimalNumber } },
): void => {
    const actionPriceBasisEventSequence = action.args.inquiry.priceBasis.eventSequence;
    if (actionPriceBasisEventSequence && actionPriceBasisEventSequence > draft.priceBasis.sequenceNumber) {
        draft.priceBasis.sequenceNumber = actionPriceBasisEventSequence;
    }
};

const updateInquiryOnPriceBasis = (
    draft: Draft<IRSCompressionReducerState>,
    action: { type: "UpdateInquiry"; args: { inquiry: IRSInquiry<"compression">; increment: DecimalNumber } },
): void => {
    const serverSequenceNumber = action.args.inquiry.priceBasis.eventSequence ?? 0;
    const screenSequenceNumber = draft.priceBasis?.sequenceNumber;
    if (serverSequenceNumber <= screenSequenceNumber) {
        return;
    }

    const priceBasisInfo = action.args.inquiry.priceBasis;

    const marketSelectedDriver = getSelectedDriver(priceBasisInfo.marketPackage.packagePriceSource);
    if (marketSelectedDriver) {
        onSelectDriver(draft, marketSelectedDriver, "Market");
    } else {
        onDeselectDriver(draft, "Market");
    }

    const oppositeSelectedDriver = getSelectedDriver(priceBasisInfo.oppositePackage.packagePriceSource);
    if (oppositeSelectedDriver) {
        onSelectDriver(draft, oppositeSelectedDriver, "Opposite");
    } else {
        onDeselectDriver(draft, "Opposite");
    }

    onUpdateSpreadValue(
        draft,
        priceBasisInfo.marketPackage.packagePriceSpread
            ? new ImmutableDecimalNumber(priceBasisInfo.marketPackage.packagePriceSpread)
            : null,
        action.args.increment,
        "Market",
    );

    onUpdateSpreadValue(
        draft,
        priceBasisInfo.oppositePackage.packagePriceSpread
            ? new ImmutableDecimalNumber(priceBasisInfo.oppositePackage.packagePriceSpread)
            : null,
        action.args.increment,
        "Opposite",
    );

    if (priceBasisInfo.marketPackage.manualPackagePrice) {
        onUpdatePackageFeeValue(draft, new ImmutableDecimalNumber(priceBasisInfo.marketPackage.manualPackagePrice), "Market");
    }

    if (priceBasisInfo.oppositePackage.manualPackagePrice) {
        onUpdatePackageFeeValue(draft, new ImmutableDecimalNumber(priceBasisInfo.oppositePackage.manualPackagePrice), "Opposite");
    }

    if (priceBasisInfo.preTradeMidMarketPackagePrice) {
        onUpdatePtmmValue(draft, new ImmutableDecimalNumber(priceBasisInfo.preTradeMidMarketPackagePrice));
    }

    updatePriceBasisSequenceNumber(draft, action);
};

function onUpdateInquiry(
    draft: Draft<IRSCompressionReducerState>,
    action: { type: "UpdateInquiry"; args: { inquiry: IRSInquiry<"compression">; increment: DecimalNumber; userId: number } },
): void {
    if (draft.inquiry === null) {
        selectInitialDriver(draft);
    }

    const previousLatestQuotedPackageFee = draft.inquiry?.latestQuotedPackageFee;
    const previousLatestQuotedOppositePackageFee = draft.inquiry?.latestQuotedOppositePackageFee;

    draft.inquiry = action.args.inquiry;
    draft.autoXModeChangeReason = action.args.inquiry.autoXModeChangeReason;

    const venueMidPackagePrice = draft.inquiry.packageMidCompositePrice
        ? new ImmutableDecimalNumber(draft.inquiry.packageMidCompositePrice)
        : null;

    draft.market.roundedVenueMidPackagePrice = venueMidPackagePrice?.format(0) ?? null;
    draft.opposite.roundedVenueMidPackagePrice = venueMidPackagePrice?.negated()?.format(0) ?? null;

    draft.roundedLatestQuotedPackagePrice = roundToInteger(draft.inquiry.latestQuotedPackageFee);
    draft.roundedLatestQuotedMidPackagePrice = roundToInteger(draft.inquiry.latestQuotedMidPackageFee);
    draft.roundedLatestQuotedOppositePackagePrice = roundToInteger(draft.inquiry.latestQuotedOppositePackageFee);

    draft.market.roundedAutoQuotedPackagePrice = roundToInteger(draft.inquiry.autoQuotePackagePrice);
    draft.market.roundedAutoQuotedPackageMidPrice = roundToInteger(draft.inquiry.autoQuotePackageMidPrice);
    draft.market.roundedAutoQuotedOppositePackagePrice = roundToInteger(draft.inquiry.autoQuoteOppositePackagePrice);

    draft.opposite.roundedAutoQuotedPackagePrice = roundToInteger(draft.inquiry.autoQuotePackagePrice);
    draft.opposite.roundedAutoQuotedPackageMidPrice = roundToInteger(negate(draft.inquiry.autoQuotePackageMidPrice));
    draft.opposite.roundedAutoQuotedOppositePackagePrice = roundToInteger(draft.inquiry.autoQuoteOppositePackagePrice);

    draft.roundedPtmm = computePtmmValue(
        draft.roundedPtmm,
        draft.inquiry.autoQuotePriceState,
        draft.market.roundedAutoQuotedPackageMidPrice,
    );
    draft.manualPtmm = computeManualPtmmValue(
        draft.roundedPtmm,
        draft.inquiry.autoQuotePriceState,
        draft.market.roundedAutoQuotedPackageMidPrice,
    );

    const lastFailure = draft.inquiry.actionFailures[draft.inquiry.actionFailures.length - 1];
    if (lastFailure && lastFailure.userId === action.args.userId) {
        draft.latestActionFailure = {
            actionId: lastFailure.actionId,
            actionType: lastFailure.actionType,
            reason: lastFailure.reason,
            source: lastFailure.source,
            userId: lastFailure.userId,
            closed: draft.closedActionFailures.includes(lastFailure.actionId),
        };
    }

    if (inQuoteRefreshRequested(draft)) {
        if (isDrivenByLatestQuotedPrice(draft, "Market")) {
            draft.market.selectedDriver = null;
        }
        if (isDrivenByLatestQuotedPrice(draft, "Opposite")) {
            draft.opposite.selectedDriver = null;
        }
    }

    const marketDriverValue = getDriverValue(draft, draft.market.selectedDriver, draft.market);
    if (draft.market.selectedDriver && !marketDriverValue) {
        draft.market.packageFeeValue = null;
        draft.market.manualPackageFeeValue = null;
        draft.market.spreadValue = null;
    }

    const oppositeDriverValue = getDriverValue(draft, draft.opposite.selectedDriver, draft.opposite);
    if (draft.opposite.selectedDriver && !oppositeDriverValue) {
        draft.opposite.packageFeeValue = null;
        draft.opposite.manualPackageFeeValue = null;
        draft.opposite.spreadValue = null;
    }

    const tradedPrice = draft.inquiry.tradedPackageFee;
    const isInquiryDone = draft.inquiry.state === InquiryState.Done && tradedPrice !== null;

    if (isInquiryDone) {
        draft.market.packageFeeValue = new ImmutableDecimalNumber(tradedPrice);
        draft.opposite.packageFeeValue = new ImmutableDecimalNumber(tradedPrice);
        if (marketDriverValue !== null) {
            draft.market.spreadValue = draft.market.packageFeeValue?.subtract(marketDriverValue) ?? null;
        } else {
            draft.market.spreadValue = null;
        }
        if (oppositeDriverValue !== null) {
            draft.opposite.spreadValue = draft.opposite.packageFeeValue?.subtract(oppositeDriverValue) ?? null;
        } else {
            draft.opposite.spreadValue = null;
        }
        updateInquiryOnPriceBasis(draft, action);
        return;
    }

    if (isDrivenByLatestQuotedPrice(draft, "Market")) {
        const newLatestQuotedPackageFee = action.args.inquiry.latestQuotedPackageFee;
        const newLatestQuotedOppositePackageFee = action.args.inquiry.latestQuotedOppositePackageFee;
        if (previousLatestQuotedPackageFee !== newLatestQuotedPackageFee) {
            draft.market.spreadValue = new ImmutableDecimalNumber(0);
        }
        if (previousLatestQuotedOppositePackageFee !== newLatestQuotedOppositePackageFee) {
            draft.opposite.spreadValue = new ImmutableDecimalNumber(0);
        }
    } else {
        if (marketDriverValue !== null) {
            if (!draft.market.spreadValue && draft.market.selectedDriver) {
                draft.market.spreadValue = new ImmutableDecimalNumber(0);
            }
            setPackageFeeFromDriverAndSpread(draft.market.spreadValue, marketDriverValue, action.args.increment, draft.market);
        }
        if (oppositeDriverValue !== null) {
            if (!draft.opposite.spreadValue && draft.opposite.selectedDriver) {
                draft.opposite.spreadValue = new ImmutableDecimalNumber(0);
            }
            setPackageFeeFromDriverAndSpread(draft.opposite.spreadValue, oppositeDriverValue, action.args.increment, draft.opposite);
        }
    }

    updateInquiryOnPriceBasis(draft, action);
}

function onUpdatePackageFeeValue(draft: Draft<IRSCompressionReducerState>, newValue: DecimalNumber, side: Side): void {
    if (side === "Market") {
        draft.market.packageFeeValue = newValue;
        draft.market.selectedDriver = null;
        draft.market.spreadValue = null;
        draft.market.manualPackageFeeValue = newValue;
    } else {
        draft.opposite.packageFeeValue = newValue;
        draft.opposite.selectedDriver = null;
        draft.opposite.spreadValue = null;
        draft.opposite.manualPackageFeeValue = newValue;
    }
}

function onUpdateSpreadValue(
    draft: Draft<IRSCompressionReducerState>,
    spreadValue: DecimalNumber | null,
    increment: DecimalNumber,
    side: Side,
): void {
    if (side === "Market") {
        draft.market.spreadValue = spreadValue;
        const marketDriverValue = getDriverValue(draft, draft.market.selectedDriver, draft.market);
        if (!marketDriverValue) {
            draft.market.packageFeeValue = null;
            draft.market.manualPackageFeeValue = null;
            draft.market.spreadValue = null;
        } else {
            setPackageFeeFromDriverAndSpread(spreadValue, marketDriverValue, increment, draft.market);
        }
    } else {
        draft.opposite.spreadValue = spreadValue;
        const oppositeDriverValue = getDriverValue(draft, draft.opposite.selectedDriver, draft.opposite);
        if (!oppositeDriverValue) {
            draft.opposite.packageFeeValue = null;
            draft.opposite.manualPackageFeeValue = null;
            draft.opposite.spreadValue = null;
        } else {
            setPackageFeeFromDriverAndSpread(spreadValue, oppositeDriverValue, increment, draft.opposite);
        }
    }
}

function selectInitialDriver(draft: Draft<IRSCompressionReducerState>): void {
    draft.market.selectedDriver = { rowNo: 1, columnNo: 0 };
    draft.opposite.selectedDriver = { rowNo: 1, columnNo: 2 };
}

function getDriverValue(
    draft: Draft<IRSCompressionReducerState> | IRSCompressionReducerState,
    selectedDriver: SelectedDriver | null,
    side: SideReducerState,
): string | null {
    if (draft.inquiry && selectedDriver) {
        if (selectedDriver.rowNo === 0) {
            switch (selectedDriver.columnNo) {
                case 0:
                    return draft.roundedLatestQuotedPackagePrice;
                case 1:
                    return draft.roundedLatestQuotedMidPackagePrice;
                case 2:
                    return draft.roundedLatestQuotedOppositePackagePrice;
                default:
                    return null;
            }
        } else if (selectedDriver.rowNo === 1) {
            switch (selectedDriver.columnNo) {
                case 0:
                    return side.roundedAutoQuotedPackagePrice;
                case 1:
                    return side.roundedAutoQuotedPackageMidPrice;
                case 2:
                    return side.roundedAutoQuotedOppositePackagePrice;
                default:
                    return null;
            }
        } else if (selectedDriver.rowNo === 2) {
            return side.roundedVenueMidPackagePrice;
        }
    }
    return null;
}

function onSelectDriver(draft: Draft<IRSCompressionReducerState>, driver: SelectedDriver, side: Side): void {
    if (draft.inquiry) {
        const driverValue = getDriverValue(draft, driver, side === "Market" ? draft.market : draft.opposite);

        const displayState = driver.rowNo !== 1 ? DisplayState.Valid : mapPricerStateToDisplayState(draft.inquiry.autoQuotePriceState);

        if (displayState !== DisplayState.Valid || driverValue) {
            switch (side) {
                case "Market":
                    draft.market.packageFeeValue = driverValue ? new ImmutableDecimalNumber(driverValue) : null;
                    draft.market.selectedDriver = driver;
                    draft.market.spreadValue = new ImmutableDecimalNumber(0);
                    draft.market.manualPackageFeeValue = null;
                    break;
                case "Opposite":
                    draft.opposite.packageFeeValue = driverValue ? new ImmutableDecimalNumber(driverValue) : null;
                    draft.opposite.selectedDriver = driver;
                    draft.opposite.spreadValue = new ImmutableDecimalNumber(0);
                    draft.opposite.manualPackageFeeValue = null;
                    break;
            }
        }
    }
}

const onDeselectDriver = (draft: Draft<IRSCompressionReducerState>, side: Side): void => {
    switch (side) {
        case "Market":
            draft.market.selectedDriver = null;
            draft.market.spreadValue = null;
            draft.market.manualPackageFeeValue = draft.market.packageFeeValue;
            break;
        case "Opposite":
            draft.opposite.selectedDriver = null;
            draft.opposite.spreadValue = null;
            draft.opposite.manualPackageFeeValue = draft.opposite.packageFeeValue;
            break;
    }
};

function onUpdatePtmmValue(draft: Draft<IRSCompressionReducerState>, newValue: DecimalNumber): void {
    draft.roundedPtmm = newValue;
    draft.manualPtmm = newValue;
}

function setPackageFeeFromDriverAndSpread(
    spreadValue: Draft<DecimalNumber> | null,
    driverValue: string,
    increment: DecimalNumber,
    sideToApply: Draft<SideReducerState>,
): void {
    const number = spreadValue ? new ImmutableDecimalNumber(driverValue).add(spreadValue) : new ImmutableDecimalNumber(driverValue);
    sideToApply.packageFeeValue = roundToIncrement(number, increment);
}

function roundToIncrement(number: DecimalNumber, increment: DecimalNumber): DecimalNumber {
    return number.subtract(number.modulo(increment));
}
