import { useCallback, useMemo } from "react";
import * as IRSDomain from "../irs-domain";
import {
    calculatePackageLevelValueByInquiryType,
    calculatePackageSpreadByPriceType,
    DisplayState,
    IRSInquiry,
    IRSLeg,
    mapPricerStateToDisplayState,
    PricerState,
    PriceValidity,
    SelectedDriver,
} from "../irs-domain";
import { DecimalNumber, EpochClock, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { decimalToBasisPoints, nullableRoundToIncrement } from "../irs-domain/scaling";
import { priceIsStale } from "../irs-domain/stale-prices";
import { getSelectedDriverValueForLeg } from "../irs-domain/price-source";
import { useRerenderOnInterval } from "@transficc/components";
import { useIRSReduxState } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";
import { computeManualPtmmValue, computePtmmValue } from "../irs-domain/ptmm";

const LEFT_LEG_POSITION = 0;
const RIGHT_LEG_POSITION = 1;

export interface IRSSideView {
    spreadValue: DecimalNumber | null;
    updateSpreadValue: (customerRate: DecimalNumber) => void;
    isSpreadInputDisabled: boolean;

    customerRateValue: DecimalNumber | null;
    updateCustomerRate: (customerRate: DecimalNumber) => void;

    shouldShowCustomerRateInputAsStale: boolean;

    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
    selectedDriver: SelectedDriver | null;
    selectedDriverDisplayState: DisplayState;

    isCustomerRateInputDisabled: boolean;
    hasDriverValue: boolean;
}

export interface IRSLegView {
    updatePtmmValue: (ptmmValue: DecimalNumber) => void;

    pay: IRSSideView;
    rcv: IRSSideView;
}

interface PackageSideView {
    packageLevelValue: DecimalNumber | null;
    updatePackageLevel: (packageLevel: DecimalNumber) => void;
    packageSpreadValue: DecimalNumber | null;
    isPackageSpreadInputDisabled: boolean;
    isPackageLevelInputDisabled: boolean;
    shouldShowPackageLevelInputAsStale: boolean;
    updatePackageSpread: (packageSpread: DecimalNumber) => void;
    packageAQLevel: DecimalNumber | null;
    packageLastQuoted: DecimalNumber | null;
    packageLevelDisplayState: DisplayState;
    isPackageLevelSelected: boolean;
}

interface PackageView {
    packageQuotedMid: DecimalNumber | null;
    packageVenueMid: DecimalNumber | null;
    packageAQMid: DecimalNumber | null;
    pay: PackageSideView;
    rcv: PackageSideView;
}

interface IRSCurveView {
    leftLeg: IRSLegView;
    rightLeg: IRSLegView;
    packageView: PackageView;
    autoQuoteDisplayState: DisplayState;
    autoQuotePriceValidity: PriceValidity | null;
}

export interface IRSQuotingState {
    spreadValue: DecimalNumber | null;
    updateSpreadValue: (customerRate: DecimalNumber) => void;

    customerRateValue: DecimalNumber | null;
    manualRateValue: DecimalNumber | null;
    updateCustomerRate: (customerRate: DecimalNumber) => void;

    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
    selectedDriver: SelectedDriver | null;
}

export interface IRSLegState {
    pay: IRSQuotingState;
    rcv: IRSQuotingState;
    ptmmValue: DecimalNumber | null;
    manualPtmmValue: DecimalNumber | null;
    updatePtmmValue: (ptmm: DecimalNumber) => void;
}

const useLegView = (
    legState: IRSLegState,
    inquiryLeg: IRSLeg<"curve">,
    isInquiryFinished: boolean,
    autoQuoteDisplayState: DisplayState,
    isOnDemandPricerPriceStale: boolean,
    isOnDemandPricerActive: boolean,
): IRSLegView => {
    return useMemo(() => {
        function createIRSSideView(quotingState: IRSQuotingState): IRSSideView {
            const isAutoQuoteDriverSelected = quotingState.selectedDriver?.rowNo === 1;
            const selectedDriverDisplayState = isAutoQuoteDriverSelected ? autoQuoteDisplayState : DisplayState.Valid;

            const hasDriverValue = getSelectedDriverValueForLeg(quotingState.selectedDriver, inquiryLeg) !== null;
            const isSpreadInputDisabled = quotingState.selectedDriver === null || !hasDriverValue || isInquiryFinished;

            return {
                selectedDriverDisplayState,
                spreadValue: quotingState.spreadValue,
                isCustomerRateInputDisabled: isInquiryFinished,
                isSpreadInputDisabled,
                updateSpreadValue: quotingState.updateSpreadValue,
                customerRateValue: quotingState.customerRateValue,
                updateCustomerRate: quotingState.updateCustomerRate,
                selectDriver: quotingState.selectDriver,
                clearDriverValue: quotingState.clearDriverValue,
                selectedDriver: quotingState.selectedDriver,
                shouldShowCustomerRateInputAsStale: isAutoQuoteDriverSelected && isOnDemandPricerPriceStale && isOnDemandPricerActive,
                hasDriverValue,
            };
        }

        const paySideView = createIRSSideView(legState.pay);
        const rcvSideView = createIRSSideView(legState.rcv);

        return {
            ptmmValue: legState.ptmmValue,
            autoQuoteDisplayState,
            updatePtmmValue: legState.updatePtmmValue,
            pay: paySideView,
            rcv: rcvSideView,
        };
    }, [
        autoQuoteDisplayState,
        inquiryLeg,
        isInquiryFinished,
        legState.pay,
        legState.ptmmValue,
        legState.rcv,
        legState.updatePtmmValue,
        isOnDemandPricerPriceStale,
        isOnDemandPricerActive,
    ]);
};

function useLegState(inquiry: IRSDomain.IRSInquiry<"curve">, legPosition: 0 | 1): IRSLegState {
    const state = useIRSReduxState();

    if (state.type !== "leg-based") {
        throw new Error("Tried to use non-leg-based state in leg-based inquiry");
    }

    const { updateLegCustomerRate, updateLegSpreadValue, updatePtmmValue, selectDriver, deselectDriver } = useIRSStateDispatch();

    const stateLeg = state.legs[legPosition];

    if (!stateLeg) {
        throw new Error("Should never happen");
    }

    const aqMidPrice = inquiry.legs[legPosition].autoQuotePrices[1];
    const ptmmValue = nullableRoundToIncrement(
        computePtmmValue(stateLeg.ptmmValue, inquiry.autoQuotePriceState, aqMidPrice),
        new ImmutableDecimalNumber(inquiry.legs[legPosition].minPriceIncrement),
    );
    const manualPtmmValue = nullableRoundToIncrement(
        computeManualPtmmValue(stateLeg.ptmmValue, inquiry.autoQuotePriceState, aqMidPrice),
        new ImmutableDecimalNumber(inquiry.legs[legPosition].minPriceIncrement),
    );

    return {
        pay: {
            spreadValue: stateLeg.pay.spreadValue,
            updateSpreadValue: (updatedSpreadValue: DecimalNumber) => {
                updateLegSpreadValue(legPosition, "PAY", updatedSpreadValue);
            },
            customerRateValue: stateLeg.pay.customerRateValue,
            manualRateValue: stateLeg.pay.manualCustomerRateValue,
            updateCustomerRate: (updatedCustomerRate: DecimalNumber) => {
                updateLegCustomerRate(legPosition, "PAY", updatedCustomerRate);
            },

            selectDriver: (driver: SelectedDriver) => {
                selectDriver("PAY", driver, legPosition);
            },
            clearDriverValue: () => {
                deselectDriver("PAY", legPosition);
            },
            selectedDriver: stateLeg.pay.selectedDriver,
        },
        rcv: {
            spreadValue: stateLeg.rcv.spreadValue,
            updateSpreadValue: (updatedSpreadValue: DecimalNumber) => {
                updateLegSpreadValue(legPosition, "RCV", updatedSpreadValue);
            },
            customerRateValue: stateLeg.rcv.customerRateValue,
            manualRateValue: stateLeg.rcv.manualCustomerRateValue,
            updateCustomerRate: (updatedCustomerRate: DecimalNumber) => {
                updateLegCustomerRate(legPosition, "RCV", updatedCustomerRate);
            },

            selectDriver: (driver: SelectedDriver) => {
                selectDriver("RCV", driver, legPosition);
            },
            clearDriverValue: () => {
                deselectDriver("RCV", legPosition);
            },
            selectedDriver: stateLeg.rcv.selectedDriver,
        },
        ptmmValue,
        manualPtmmValue,
        updatePtmmValue: (updatedPtmmValue: DecimalNumber) => {
            updatePtmmValue(updatedPtmmValue, legPosition);
        },
    };
}

export function useIRSCurve(inquiry: IRSInquiry<"curve">, clock: EpochClock, isInquiryFinished: boolean): IRSCurveView {
    const state = useIRSReduxState();

    if (state.type !== "leg-based") {
        throw new Error("Tried to use non-leg-based state in leg-based inquiry");
    }
    const { updatePackageValue, updatePackageSpreadValue } = useIRSStateDispatch();

    const leftLegState = useLegState(inquiry, 0);
    const rightLegState = useLegState(inquiry, 1);

    const payCallbacks = {
        updatePackageLevel: useCallback(
            (packageLevel: DecimalNumber) => {
                updatePackageValue(packageLevel, "PAY");
            },
            [updatePackageValue],
        ),
        updatePackageSpread: useCallback(
            (spreadValue: DecimalNumber) => {
                updatePackageSpreadValue(spreadValue, "PAY");
            },
            [updatePackageSpreadValue],
        ),
    };

    const receiveCallbacks = {
        updatePackageLevel: useCallback(
            (packageLevel: DecimalNumber) => {
                updatePackageValue(packageLevel, "RCV");
            },
            [updatePackageValue],
        ),
        updatePackageSpread: useCallback(
            (spreadValue: DecimalNumber) => {
                updatePackageSpreadValue(spreadValue, "RCV");
            },
            [updatePackageSpreadValue],
        ),
    };

    useRerenderOnInterval(500); // for timer-based rendering like stale prices

    const packageLevelValuePay = useMemo(() => {
        const rightLegPayValue = rightLegState.pay.customerRateValue;
        const leftLegRcvValue = leftLegState.rcv.customerRateValue;
        if (rightLegPayValue && leftLegRcvValue) {
            if (inquiry.isMac) {
                return rightLegPayValue.add(leftLegRcvValue);
            } else {
                return decimalToBasisPoints(rightLegPayValue.subtract(leftLegRcvValue));
            }
        } else {
            return null;
        }
    }, [inquiry.isMac, leftLegState.rcv.customerRateValue, rightLegState.pay.customerRateValue]);
    const packageLevelValueRcv = useMemo(() => {
        const rightLegRcvValue = rightLegState.rcv.customerRateValue;
        const leftLegPayValue = leftLegState.pay.customerRateValue;
        if (rightLegRcvValue && leftLegPayValue) {
            if (inquiry.isMac) {
                return rightLegRcvValue.add(leftLegPayValue);
            } else {
                return decimalToBasisPoints(rightLegRcvValue.subtract(leftLegPayValue));
            }
        } else {
            return null;
        }
    }, [inquiry.isMac, leftLegState.pay.customerRateValue, rightLegState.rcv.customerRateValue]);

    const autoQuoteDisplayState: DisplayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);
    const isOnDemandPricerPriceStale: boolean = priceIsStale(inquiry.autoQuotePriceValidity, clock);
    const isOnDemandPricerActive: boolean = inquiry.autoQuotePriceState === PricerState.Priced;

    const leftLegView = useLegView(
        leftLegState,
        inquiry.legs[0],
        isInquiryFinished,
        autoQuoteDisplayState,
        isOnDemandPricerPriceStale,
        isOnDemandPricerActive,
    );
    const rightLegView = useLegView(
        rightLegState,
        inquiry.legs[1],
        isInquiryFinished,
        autoQuoteDisplayState,
        isOnDemandPricerPriceStale,
        isOnDemandPricerActive,
    );
    const rightLeg = inquiry.legs[RIGHT_LEG_POSITION];
    const leftLeg = inquiry.legs[LEFT_LEG_POSITION];

    const packageSpreadValuePay = useMemo(() => {
        if (
            leftLegState.rcv.selectedDriver === null ||
            getSelectedDriverValueForLeg(leftLegState.rcv.selectedDriver, leftLeg) === null ||
            rightLegState.pay.selectedDriver === null ||
            getSelectedDriverValueForLeg(rightLegState.pay.selectedDriver, rightLeg) === null
        ) {
            return null;
        }

        return leftLegState.rcv.spreadValue && rightLegState.pay.spreadValue
            ? calculatePackageSpreadByPriceType(inquiry.isMac, leftLegState.rcv.spreadValue, rightLegState.pay.spreadValue)
            : null;
    }, [inquiry.isMac, leftLeg, rightLeg, leftLegState.rcv, rightLegState.pay]);

    const packageSpreadValueRcv = useMemo(() => {
        if (
            leftLegState.pay.selectedDriver === null ||
            getSelectedDriverValueForLeg(leftLegState.pay.selectedDriver, leftLeg) === null ||
            rightLegState.rcv.selectedDriver === null ||
            getSelectedDriverValueForLeg(rightLegState.rcv.selectedDriver, rightLeg) === null
        ) {
            return null;
        }

        return leftLegState.pay.spreadValue && rightLegState.rcv.spreadValue
            ? calculatePackageSpreadByPriceType(inquiry.isMac, leftLegState.pay.spreadValue, rightLegState.rcv.spreadValue)
            : null;
    }, [inquiry.isMac, leftLeg, rightLeg, leftLegState.pay, rightLegState.rcv]);

    const packageLevelDisplayStatePay = useMemo(() => {
        return leftLegView.rcv.selectedDriverDisplayState !== DisplayState.Valid
            ? leftLegView.rcv.selectedDriverDisplayState
            : rightLegView.pay.selectedDriverDisplayState;
    }, [leftLegView.rcv, rightLegView.pay]);

    const packageLevelPayStale = useMemo(() => {
        return leftLegView.rcv.shouldShowCustomerRateInputAsStale || rightLegView.pay.shouldShowCustomerRateInputAsStale;
    }, [leftLegView.rcv, rightLegView.pay]);

    const packageLevelDisplayStateRcv = useMemo(() => {
        return leftLegView.pay.selectedDriverDisplayState !== DisplayState.Valid
            ? leftLegView.pay.selectedDriverDisplayState
            : rightLegView.rcv.selectedDriverDisplayState;
    }, [leftLegView.pay, rightLegView.rcv]);

    const packageLevelRcvStale = useMemo(() => {
        return leftLegView.pay.shouldShowCustomerRateInputAsStale || rightLegView.rcv.shouldShowCustomerRateInputAsStale;
    }, [leftLegView.pay, rightLegView.rcv]);

    const isPackageLevelSelectedPay = useMemo(
        () => leftLegState.rcv.selectedDriver === null || rightLegState.pay.selectedDriver === null,
        [leftLegState.rcv.selectedDriver, rightLegState.pay.selectedDriver],
    );

    const isPackageLevelSelectedRcv = useMemo(
        () => leftLegState.pay.selectedDriver === null || rightLegState.rcv.selectedDriver === null,
        [leftLegState.pay.selectedDriver, rightLegState.rcv.selectedDriver],
    );

    const isPackageLevelInputDisabledPay = useMemo(
        () => isInquiryFinished || leftLegState.rcv.customerRateValue === null || rightLegState.pay.customerRateValue === null,
        [isInquiryFinished, leftLegState.rcv.customerRateValue, rightLegState.pay.customerRateValue],
    );
    const isPackageLevelInputDisabledRcv = useMemo(
        () => isInquiryFinished || leftLegState.pay.customerRateValue === null || rightLegState.rcv.customerRateValue === null,
        [isInquiryFinished, leftLegState.pay.customerRateValue, rightLegState.rcv.customerRateValue],
    );

    const isPackageSpreadInputDisabledPay = useMemo(
        () => isPackageLevelInputDisabledPay || leftLegState.rcv.selectedDriver === null || rightLegState.pay.selectedDriver === null,
        [isPackageLevelInputDisabledPay, leftLegState.rcv.selectedDriver, rightLegState.pay.selectedDriver],
    );
    const isPackageSpreadInputDisabledRcv = useMemo(
        () => isPackageLevelInputDisabledRcv || leftLegState.pay.selectedDriver === null || rightLegState.rcv.selectedDriver === null,
        [isPackageLevelInputDisabledRcv, leftLegState.pay.selectedDriver, rightLegState.rcv.selectedDriver],
    );

    const packageLastQuotedPay = useMemo(
        () => (inquiry.latestQuotedPackageFeePay ? new ImmutableDecimalNumber(inquiry.latestQuotedPackageFeePay) : null),
        [inquiry.latestQuotedPackageFeePay],
    );
    const packageLastQuotedRcv = useMemo(
        () => (inquiry.latestQuotedPackageFeeRcv ? new ImmutableDecimalNumber(inquiry.latestQuotedPackageFeeRcv) : null),
        [inquiry.latestQuotedPackageFeeRcv],
    );
    const packageLastQuotedMid = useMemo(
        () => (inquiry.latestQuotedMidPackageFee ? new ImmutableDecimalNumber(inquiry.latestQuotedMidPackageFee) : null),
        [inquiry.latestQuotedMidPackageFee],
    );

    const packageVenueMid = useMemo(() => {
        const packageMidCompositePrice = inquiry.packageMidCompositePrice;
        if (packageMidCompositePrice !== null) {
            return new ImmutableDecimalNumber(packageMidCompositePrice);
        }
        return null;
    }, [inquiry.packageMidCompositePrice]);

    const packageAQMid = useMemo(() => {
        const rightPrice = rightLeg.autoQuotePrices[1];
        const leftPrice = leftLeg.autoQuotePrices[1];
        return calculatePackageLevelValueByInquiryType(inquiry.isMac, rightPrice, leftPrice);
    }, [inquiry.isMac, leftLeg.autoQuotePrices, rightLeg.autoQuotePrices]);

    const packageAQLevelPay = useMemo(() => {
        const rightPrice = rightLeg.autoQuotePrices[0];
        const leftPrice = leftLeg.autoQuotePrices[2];
        return calculatePackageLevelValueByInquiryType(inquiry.isMac, rightPrice, leftPrice);
    }, [inquiry.isMac, leftLeg.autoQuotePrices, rightLeg.autoQuotePrices]);

    const packageAQLevelRcv = useMemo(() => {
        const rightPrice = rightLeg.autoQuotePrices[2];
        const leftPrice = leftLeg.autoQuotePrices[0];
        return calculatePackageLevelValueByInquiryType(inquiry.isMac, rightPrice, leftPrice);
    }, [inquiry.isMac, leftLeg.autoQuotePrices, rightLeg.autoQuotePrices]);

    return {
        leftLeg: leftLegView,
        rightLeg: rightLegView,
        packageView: {
            pay: {
                packageLevelValue: packageLevelValuePay,
                updatePackageLevel: payCallbacks.updatePackageLevel,
                packageSpreadValue: packageSpreadValuePay,
                isPackageLevelInputDisabled: isPackageLevelInputDisabledPay,
                isPackageSpreadInputDisabled: isPackageSpreadInputDisabledPay,
                updatePackageSpread: payCallbacks.updatePackageSpread,
                packageAQLevel: packageAQLevelPay,
                packageLastQuoted: packageLastQuotedPay,
                packageLevelDisplayState: packageLevelDisplayStatePay,
                isPackageLevelSelected: isPackageLevelSelectedPay,
                shouldShowPackageLevelInputAsStale: packageLevelPayStale,
            },
            rcv: {
                packageLevelValue: packageLevelValueRcv,
                updatePackageLevel: receiveCallbacks.updatePackageLevel,
                packageSpreadValue: packageSpreadValueRcv,
                isPackageLevelInputDisabled: isPackageLevelInputDisabledRcv,
                isPackageSpreadInputDisabled: isPackageSpreadInputDisabledRcv,
                updatePackageSpread: receiveCallbacks.updatePackageSpread,
                packageAQLevel: packageAQLevelRcv,
                packageLastQuoted: packageLastQuotedRcv,
                packageLevelDisplayState: packageLevelDisplayStateRcv,
                isPackageLevelSelected: isPackageLevelSelectedRcv,
                shouldShowPackageLevelInputAsStale: packageLevelRcvStale,
            },
            packageQuotedMid: packageLastQuotedMid,
            packageVenueMid,
            packageAQMid,
        },
        autoQuoteDisplayState,
        autoQuotePriceValidity: inquiry.autoQuotePriceValidity,
    };
}
