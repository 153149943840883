import { ReactElement, useCallback, useMemo, useState } from "react";
import { Box } from "../box";

import Select, { MultiValue } from "react-select";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";

export interface MultiselectOption<T> {
    value: T;
    label: string;
}

export interface MultiselectProps<T> {
    options: MultiselectOption<T>[];
    id: string;
    placeholder: string;
    selectedValues: T[];
    onChange?: (values: T[]) => void;
}

const StyledBox = styled(Box)`
    & .basic-multi-select {
        width: 100%;
        align-content: center;
        background-color: transparent;
        color: white;
        min-height: 32px;
        height: auto;
        text-indent: 8px;
        font-size: 0.875rem;

        & .select__indicators {
            height: 32px;
        }

        & .select__control {
            background: transparent;
            border: none;
            //border-color: white;
            height: auto;
            min-height: 32px;
            box-shadow: none;

            &:hover {
                border: none;
            }

            & .select__value-container {
                overflow: clip;
                height: 89%;
            }
        }

        & .select__option {
            color: black;
        }

        & .select__multi-value__remove {
            color: black;
        }

        & .select__placeholder {
            color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "gray", level: "400" })};
        }
    }
`;

export const Multiselect = <T,>({ options, id, selectedValues, placeholder, onChange }: MultiselectProps<T>): ReactElement => {
    const value = useMemo(() => {
        const result: MultiselectOption<T>[] = [];
        for (const selectedValue of selectedValues) {
            const matches = options.filter((option) => option.value === selectedValue);
            if (matches[0]) {
                result.push(matches[0]);
            } else {
                throw new Error("Unexpected option found");
            }
        }
        return result;
    }, [options, selectedValues]);

    const onChangeCallback = useCallback(
        (newValue: MultiValue<MultiselectOption<T>>) => {
            if (onChange !== undefined) {
                onChange(
                    newValue.map((option: MultiselectOption<T>) => {
                        return option.value;
                    }),
                );
            }
        },
        [onChange],
    );

    const [isFocused, setIsFocused] = useState(false);

    return (
        <StyledBox
            data-testid={id}
            width={"106"}
            contentDirection={"row"}
            contentAlignment={"center"}
            border={
                isFocused
                    ? { width: "px", color: { color: "warning" }, style: "solid" }
                    : { width: "px", color: { color: "white" }, style: "solid" }
            }
        >
            <Select
                value={value}
                isMulti
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={placeholder}
                onChange={onChangeCallback}
            />
        </StyledBox>
    );
};
