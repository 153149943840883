import * as React from "react";
import { useCallback, useEffect } from "react";
import { Box } from "@transficc/components";
import styled from "styled-components";
import { useAppVersion, useClock, useElectronApi, useEnvironmentConfig } from "@transficc/trader-desktop-application-context";
import {
    useConnectionStatus,
    useDefinitelyAccessToken,
    useServerTradingStatus,
    useTradingStatus,
    useUser,
} from "./redux/slices/session/useSessionSliceSelectors";
import { useSessionStateDispatch } from "./redux/slices/session/useSessionStateDispatch";
import { useTradingStatusToggleDispatch } from "./redux/slices/session/useTradingStatusToggleDispatch";
import { jwtDecode } from "jwt-decode";
import { AccessToken } from "./ApplicationRouter";
import ActiveBlotter from "./ActiveBlotter";
import { openWindow } from "./utils/openWindow";
import { TraderDesktopNavigationBar, TraderDesktopNavigationBarLinkProps } from "./navigation-bar/navigation-bar";
import { FrameName } from "@transficc/trader-desktop-electron-api";

const HomeContainer = styled(Box)`
    height: 100vh;
`;

// Do not increase the dock size, we have no more space!
const IRS_DOCK_WIDTH = 1800;
const IRS_DOCK_HEIGHT = 1000;
const CREDIT_DOCK_WIDTH = 1920;
const CREDIT_DOCK_HEIGHT = 900;

const HomePanel: React.FC = () => {
    const electronApi = useElectronApi();
    const appVersion = useAppVersion();
    const accessToken = useDefinitelyAccessToken();
    const user = useUser();
    const connectionStatus = useConnectionStatus();
    const sessionStateDispatch = useSessionStateDispatch();
    const tradingStatus = useTradingStatus();
    const serverTradingStatus = useServerTradingStatus();
    const tradingStatusToggleDispatch = useTradingStatusToggleDispatch();
    const clock = useClock();
    const decodedAccessToken = jwtDecode<AccessToken>(accessToken);
    const accessTokenRoles = [...new Set<string>(decodedAccessToken.roles)].join("\n");

    const onLogout = useCallback(() => {
        sessionStateDispatch.logoff();
    }, [sessionStateDispatch]);

    const { creditDock } = useEnvironmentConfig().featureFlags;

    const links: TraderDesktopNavigationBarLinkProps[] = [
        {
            title: "Active Inquiries (IRS)",
            onClick: () => {
                openWindow({
                    electronApi,
                    url: "/popup/dock/irs-ticket",
                    target: FrameName.IRS_DOCK,
                    electronOpenHidden: false,
                    popup: true,
                    width: IRS_DOCK_WIDTH,
                    height: IRS_DOCK_HEIGHT,
                });
            },
        },
    ];

    if (creditDock) {
        links.push({
            title: "Active Inquiries (Bonds)",
            onClick: () => {
                openWindow({
                    electronApi,
                    url: "/popup/dock/credit-ticket",
                    target: FrameName.CREDIT_DOCK,
                    electronOpenHidden: false,
                    popup: true,
                    width: CREDIT_DOCK_WIDTH,
                    height: CREDIT_DOCK_HEIGHT,
                });
            },
        });
    }

    useEffect(() => {
        const tokenExpireTimeEpochMs = decodedAccessToken.exp * 1000;
        const nowMs = clock.getCurrentTimeMs();
        const delayMs = tokenExpireTimeEpochMs - nowMs;
        if (delayMs < 0) {
            sessionStateDispatch.logoff();
            return;
        }
        if (delayMs > 2147483647) {
            sessionStateDispatch.logoff();
            throw Error("Timeout cannot be longer than 32bit integer, delay is " + delayMs + " logging out immediately");
        }
        const timeout = setTimeout(() => {
            sessionStateDispatch.logoff();
        }, delayMs);

        return () => clearTimeout(timeout);
    }, [decodedAccessToken, sessionStateDispatch, clock]);

    useEffect(() => {
        setTimeout(() => {
            openWindow({
                electronApi,
                url: "/popup/dock/irs-ticket",
                target: FrameName.IRS_DOCK,
                electronOpenHidden: true,
                popup: true,
                width: IRS_DOCK_WIDTH,
                height: IRS_DOCK_HEIGHT,
            });
        }, 0);
    }, [electronApi]);

    useEffect(() => {
        const title = "Trader Desktop";
        document.title = title;
        electronApi.setWindowTitle(title);
    }, [electronApi]);

    return (
        <HomeContainer data-testid="home-container">
            <Box margin={"3"}>
                <TraderDesktopNavigationBar
                    version={appVersion}
                    connectionStatus={connectionStatus}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                    onLogout={onLogout}
                    userName={user.username}
                    links={links}
                    onTradingStatusChanged={tradingStatusToggleDispatch}
                    userHoverText={accessTokenRoles}
                />
            </Box>
            <ActiveBlotter />
        </HomeContainer>
    );
};

export default HomePanel;
