import { ConnectionStatus } from "./ConnectionStatus";
import { TradingStatus } from "./TradingStatus";
import { useStateDispatch } from "../../hooks/useStateDispatch";
import { useMemo } from "react";
import {
    logoff,
    setAccessToken,
    setConnectionStatus,
    setIdToken,
    setServerTradingStatus,
    setTradingStatus,
    setUserGroups,
    toggleSoundEnabled,
} from "./SessionSlice";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";

export interface SessionStateDispatch {
    setAccessToken: (accessToken: string) => void;
    setIdToken: (idToken: string) => void;
    logoff: () => void;
    toggleSoundEnabled: () => void;
    setConnectionStatus: (connectionStatus: ConnectionStatus) => void;
    setTradingStatus: (tradingStatus: TradingStatus) => void;
    setServerTradingStatus: (tradingStatus: TradingStatus) => void;
    setUserGroups: (userGroups: TraderDesktopProtocol.UserGroup[] | null) => void;
}

export const useSessionStateDispatch = (): SessionStateDispatch => {
    const dispatch = useStateDispatch();
    return useMemo(() => {
        return {
            setAccessToken: (accessToken: string) => dispatch(setAccessToken(accessToken)),
            setIdToken: (idToken: string) => dispatch(setIdToken(idToken)),
            logoff: () => dispatch(logoff()),
            toggleSoundEnabled: () => dispatch(toggleSoundEnabled()),
            setConnectionStatus: (connectionStatus: ConnectionStatus) => dispatch(setConnectionStatus(connectionStatus)),
            setTradingStatus: (tradingStatus: TradingStatus) => dispatch(setTradingStatus(tradingStatus)),
            setServerTradingStatus: (tradingStatus: TradingStatus) => dispatch(setServerTradingStatus(tradingStatus)),
            setUserGroups: (userGroups: TraderDesktopProtocol.UserGroup[] | null) => dispatch(setUserGroups(userGroups)),
        };
    }, [dispatch]);
};
