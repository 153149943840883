import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { mapState, protocolToCreditMapper } from "./domain-mapper";
import { Inquiry, InquiryState, PriceType } from "../credit-domain";
import { DataMatrixCellPosition } from "@transficc/components"; // https://github.com/microsoft/TypeScript/issues/42873
import "reselect";
import "redux";

export interface PriceValueState {
    value: string | null;
    isDirty: boolean;
}

type PriceState = Record<PriceType, PriceValueState>;

export interface TicketState {
    priceState: PriceState;
    selectedDriverCellPosition: DataMatrixCellPosition | null;
}

export interface DismissedState {
    pendingDismissed: boolean;
}

export interface InquiryData {
    inquiry: Inquiry;
    ticketState: TicketState;
    dismissedState: DismissedState;
}

export interface CreditState {
    selectedPrimaryInquiryTicketId: number | null;
    inquiries: EntityState<InquiryData, number>;
}

export interface SetSelectedPrimaryInquiryAction {
    selectedPrimaryInquiryTicketId: number | null;
}

export interface InquiryAction {
    inquiry: TraderDesktopProtocol.Inquiry;
    userId: number;
}

export interface TicketAction {
    ticketId: number;
    actionId: string;
}

export interface SetDriverAction {
    ticketId: number;
    selectedDriverCellPosition: DataMatrixCellPosition | null;
}

export interface SetIsDirtyAction {
    ticketId: number;
    priceType: PriceType;
    isDirty: boolean;
}

export interface SetPriceValueAction {
    ticketId: number;
    priceType: PriceType;
    priceValue: string | null;
}

export interface PricesAction {
    prices: TraderDesktopProtocol.OnDemandPrices;
}

const inquiriesEntityAdapter = createEntityAdapter<InquiryData, number>({
    selectId: (inquiryData) => inquiryData.inquiry.ticketId,
    sortComparer: (e1, e2) => e2.inquiry.ticketId - e1.inquiry.ticketId,
});

function getDefaultSelectedDriverCell(inquiry: TraderDesktopProtocol.Inquiry): DataMatrixCellPosition | null {
    const inquiryLeg = inquiry.legs[0];

    if (!inquiryLeg) {
        throw new Error("At least one leg required!");
    }

    if (inquiryLeg.side === TraderDesktopProtocol.Side.Buy) {
        if (inquiry.pricerState === TraderDesktopProtocol.PricerState.Priced && inquiryLeg.pricerBid === null) {
            return null;
        }
        return { rowNo: 1, colNo: 0 };
    } else if (inquiryLeg.side === TraderDesktopProtocol.Side.Sell) {
        if (inquiry.pricerState === TraderDesktopProtocol.PricerState.Priced && inquiryLeg.pricerAsk === null) {
            return null;
        }
        return { rowNo: 1, colNo: 2 };
    } else {
        throw new Error("Side not supported for credit: " + inquiryLeg.side);
    }
}

const getInitialTicketState = (inquiry: TraderDesktopProtocol.Inquiry): TicketState => ({
    priceState: {
        [PriceType.Spread]: {
            isDirty: false,
            value: null,
        },
        [PriceType.PercentOfPar]: {
            isDirty: false,
            value: null,
        },
        [PriceType.Yield]: {
            isDirty: false,
            value: null,
        },
    },
    selectedDriverCellPosition: getDefaultSelectedDriverCell(inquiry),
});

export function isDismissed(inquiryState: InquiryState): boolean {
    return (
        inquiryState === InquiryState.Done ||
        inquiryState === InquiryState.InquiryError ||
        inquiryState === InquiryState.InquiryPickedUpOnVenueUI ||
        inquiryState === InquiryState.CustomerReject ||
        inquiryState === InquiryState.CustomerTimeout ||
        inquiryState === InquiryState.DealerReject ||
        inquiryState === InquiryState.DealerTimeout
    );
}

const upsertInquiry = (payload: InquiryAction, state: CreditState): void => {
    const payloadInquiryData = payload.inquiry;
    const ticketId = payloadInquiryData.ticketId;
    const userId = payload.userId;

    const currentInquiryData = state.inquiries.entities[ticketId];

    // Updating
    if (currentInquiryData) {
        const currentInquiryState = currentInquiryData.inquiry.state;
        currentInquiryData.inquiry = protocolToCreditMapper(
            payloadInquiryData,
            currentInquiryData.inquiry.userId,
            currentInquiryData.inquiry,
        );

        if (!currentInquiryData.dismissedState.pendingDismissed) {
            currentInquiryData.dismissedState.pendingDismissed =
                !isDismissed(currentInquiryState) && isDismissed(currentInquiryData.inquiry.state);
        }

        setUpdatedDriverValue(state, ticketId);

        return;
    }

    // Creating anew

    const inquiryState = mapState(payloadInquiryData.state, payloadInquiryData.autoSpotFailed);

    if (!isDismissed(inquiryState)) {
        const inquiry = protocolToCreditMapper(payloadInquiryData, userId, null);
        inquiriesEntityAdapter.addOne(state.inquiries, {
            inquiry: inquiry,
            ticketState: getInitialTicketState(payloadInquiryData),
            dismissedState: { pendingDismissed: false },
        });
        setUpdatedDriverValue(state, ticketId);
    }
};

const safeGetInquiryData = (state: CreditState, ticketId: number): InquiryData => {
    const inquiryData = state.inquiries.entities[ticketId];
    if (!inquiryData) {
        throw new Error(`Expected inquiry for ticket ID ${ticketId}`);
    }
    return inquiryData;
};

const safeGetTicketState = (state: CreditState, ticketId: number): TicketState => {
    const inquiryData = safeGetInquiryData(state, ticketId);
    return inquiryData?.ticketState;
};

const inquiryAdapterSelectors = inquiriesEntityAdapter.getSelectors<CreditState>((state) => state.inquiries);

const recalculateSelectedTicket = (state: CreditState): void => {
    if (state.selectedPrimaryInquiryTicketId === null && state.inquiries.ids.length > 0) {
        state.selectedPrimaryInquiryTicketId = state.inquiries.ids[0] ?? null;
    }
};

const setUpdatedDriverValue = (state: CreditState, ticketId: number): void => {
    const inquiry = state.inquiries.entities[ticketId]?.inquiry;
    const ticketState = safeGetTicketState(state, ticketId);

    const selectedDriverPos = ticketState.selectedDriverCellPosition;

    if (selectedDriverPos !== null && inquiry) {
        const isLatestQuoted = selectedDriverPos.rowNo === 0;
        const isAQ = selectedDriverPos.rowNo === 1;
        const isBid = selectedDriverPos.colNo === 0;
        const isMid = selectedDriverPos.colNo === 1;
        const isAsk = selectedDriverPos.colNo === 2;

        const priceState = ticketState.priceState[inquiry.leg.priceType];

        if (isAQ && isBid) {
            priceState.value = inquiry.leg.autoQuotedPrices.bid;
        } else if (isAQ && isMid) {
            priceState.value = inquiry.leg.autoQuotedPrices.mid;
        } else if (isAQ && isAsk) {
            priceState.value = inquiry.leg.autoQuotedPrices.ask;
        } else if (isLatestQuoted && isBid) {
            priceState.value = inquiry.leg.latestQuotedPrices.bid;
        } else if (isLatestQuoted && isMid) {
            priceState.value = inquiry.leg.latestQuotedPrices.mid;
        } else if (isLatestQuoted && isAsk) {
            priceState.value = inquiry.leg.latestQuotedPrices.ask;
        }
    }
};

export const credit = createSlice({
    name: "credit",
    initialState: (): CreditState => ({
        selectedPrimaryInquiryTicketId: null,
        inquiries: inquiriesEntityAdapter.getInitialState(),
    }),
    reducers: {
        upsertAll: (state, action: PayloadAction<InquiryAction[]>) => {
            for (const payload of action.payload) {
                upsertInquiry(payload, state);
            }

            recalculateSelectedTicket(state);
        },
        upsert: (state, action: PayloadAction<InquiryAction>) => {
            upsertInquiry(action.payload, state);
            recalculateSelectedTicket(state);
        },
        updateOnDemandPrices: (state, action: PayloadAction<PricesAction>) => {
            const prices = action.payload.prices;
            const inquiry = state.inquiries.entities[prices.ticketId]?.inquiry;
            const leg = prices.legs[0];
            if (inquiry && leg) {
                inquiry.leg.autoQuotedPrices = {
                    bid: leg.bid,
                    mid: leg.mid,
                    ask: leg.ask,
                };
                inquiry.autoQuotePricesGenerationTimestampNanos = prices.priceGenerationTimestampNanos;
                inquiry.autoQuotePricesValidForNanos = prices.priceValidForNanos;
                setUpdatedDriverValue(state, inquiry.ticketId);
            }
        },
        setSelectedPrimaryInquiryTicketId: (state, action: PayloadAction<SetSelectedPrimaryInquiryAction>) => {
            if (state.selectedPrimaryInquiryTicketId === action.payload.selectedPrimaryInquiryTicketId) {
                return;
            }

            state.selectedPrimaryInquiryTicketId = action.payload.selectedPrimaryInquiryTicketId;

            recalculateSelectedTicket(state);
        },
        closeLatestActionFailure: (state, action: PayloadAction<TicketAction>) => {
            const inquiry = state.inquiries.entities[action.payload.ticketId]?.inquiry;
            if (inquiry && inquiry.latestActionFailure && inquiry.latestActionFailure.actionId === action.payload.actionId) {
                inquiry.latestActionFailure.closed = true;
            }
        },
        setDriver: (state, action: PayloadAction<SetDriverAction>) => {
            safeGetTicketState(state, action.payload.ticketId).selectedDriverCellPosition = action.payload.selectedDriverCellPosition;

            setUpdatedDriverValue(state, action.payload.ticketId);
        },
        setIsDirty: (state, action: PayloadAction<SetIsDirtyAction>) => {
            safeGetTicketState(state, action.payload.ticketId).priceState[action.payload.priceType].isDirty = action.payload.isDirty;
        },
        setPriceValue: (state, action: PayloadAction<SetPriceValueAction>) => {
            safeGetTicketState(state, action.payload.ticketId).priceState[action.payload.priceType].value = action.payload.priceValue; // update the price value
            safeGetTicketState(state, action.payload.ticketId).selectedDriverCellPosition = null; // clear the driver
        },
        dismiss: (state, action: PayloadAction<{ ticketId: number }>) => {
            const ticketId = action.payload.ticketId;
            inquiriesEntityAdapter.removeOne(state.inquiries, ticketId);
            if (state.selectedPrimaryInquiryTicketId === ticketId) {
                state.selectedPrimaryInquiryTicketId = null;
                recalculateSelectedTicket(state);
            }
        },
    },
    selectors: {
        selectedPrimaryInquiryTicketId: (state) => state.selectedPrimaryInquiryTicketId,
        allInquiries: (state) => inquiryAdapterSelectors.selectAll(state),
        definitelyInquiryByTicketId: (state, ticketId: number) => {
            const inquiry = inquiryAdapterSelectors.selectById(state, ticketId)?.inquiry;
            if (!inquiry) {
                throw new Error(`Expected inquiry for ticket ID ${ticketId}`);
            }
            return inquiry;
        },
        ticketState: (state, ticketId: number): TicketState => safeGetTicketState(state, ticketId),
        ticketPriceStateForPriceType: (state, ticketId: number, priceType: PriceType): PriceValueState =>
            safeGetTicketState(state, ticketId).priceState[priceType],
        ticketPriceState: (state, ticketId: number): PriceValueState =>
            safeGetTicketState(state, ticketId).priceState[safeGetInquiryData(state, ticketId).inquiry.leg.priceType],
    },
});

export const creditSelectors = credit.getSelectors<{ credit: CreditState }>((state) => state.credit);
export const creditReducer = credit.reducer;

export const maybeScheduleForDismissal = createAsyncThunk<void, { ticketId: number }, { state: { credit: CreditState } }>(
    "credit/maybeScheduleForDismissal",
    async (action, { getState, dispatch }) => {
        const ticketId = action.ticketId;
        const inquiry = getState().credit.inquiries.entities[ticketId];

        if (!inquiry) {
            return;
        }

        if (inquiry.dismissedState.pendingDismissed) {
            await wait(30 * 1000);
            dispatch(credit.actions.dismiss({ ticketId }));
        }
    },
);

const wait = (waitMs: number): Promise<void> =>
    new Promise<void>((resolve) => {
        setTimeout(() => resolve(), waitMs);
    });
