import { formatWithCommas } from "@transficc/components";
import { ImmutableDecimalNumber } from "@transficc/infrastructure";

export function formatQuantity(unformattedQuantity: string): string {
    const quantity = new ImmutableDecimalNumber(unformattedQuantity);

    if (quantity.isNaN()) {
        return "-";
    }

    if (quantity.numberOfDecimalPlaces() === 0 && quantity.modulo(1000).equals(0)) {
        return formatWithCommas(quantity.divideBy(1000).format(0)) + "k";
    }

    return formatWithCommas(quantity.toTruncateDecimalPlaces(3).toString());
}
