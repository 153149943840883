import { PriceValidity } from "./models";
import { EpochClock } from "@transficc/infrastructure";
import { havePricesExpired } from "./stale-prices";

export enum PricerState {
    Error = "error",
    Invalid = "invalid",
    Pending = "pending",
    Priced = "priced",
    Rejected = "rejected",
    Terminated = "terminated",
}

export const invalidPricerStates = [PricerState.Rejected, PricerState.Error, PricerState.Invalid] as const;

export enum DisplayState {
    Valid = "valid",
    Error = "error",
    Pending = "pending",
    Unavailable = "unavailable",
}

export enum InquiryAction {
    DealerAccept = "dealer_accept",
    DealerReject = "dealer_reject",
    Quote = "quote",
}

export enum InquirySide {
    Buy = "buy",
    Sell = "sell",
    Undisclosed = "undisclosed",
}

export enum TradedAway {
    NotSet = "not_set",
    No = "no",
    Yes = "yes",
}

export enum FailureSource {
    Transficc = "Transficc",
    Venue = "Venue",
}

export enum QuoteCompetitiveStatus {
    NotSet = "NotSet",
    Cover = "Cover",
    CoverTied = "CoverTied",
    Tied = "Tied",
    Best = "Best",
}

export enum PackageDirection {
    Market = "Market",
    Opposite = "Opposite",
    Undisclosed = "Undisclosed",
}

export enum InquiryState {
    Done = "done",
    InquiryError = "inquiry_error",
    LastLook = "last_look",
    NewRequest = "new_request",
    CustomerReject = "not_done_customer_reject",
    CustomerTimeout = "not_done_customer_timeout",
    DealerReject = "not_done_dealer_reject",
    DealerTimeout = "not_done_dealer_timeout",
    Streaming = "streaming",
    DealerAccepted = "dealer_accepted",
    QuoteRefreshRequested = "quote_refresh_requested",
    TradedPendingFeeAllocation = "traded_pending_fee_allocation",
    CustomerAccepted = "customer_accepted",
}

export enum InquiryStatus {
    Active = "active",
    Done = "done",
    Error = "error",
    NotDone = "not_done",
}

export enum PriceType {
    InterestRate = "InterestRate",
    NetPresentValue = "NetPresentValue",
}

export function mapInquiryStateToDisplayName(state: InquiryState): string {
    switch (state) {
        case InquiryState.NewRequest:
            return "New Request";
        case InquiryState.Streaming:
            return "Quoted";
        case InquiryState.QuoteRefreshRequested:
            return "Quote Refresh Requested";
        case InquiryState.LastLook:
            return "Last Look";
        case InquiryState.TradedPendingFeeAllocation:
            return "Done - Pending Fee Allocation";
        case InquiryState.DealerAccepted:
            return "Done";
        case InquiryState.CustomerAccepted:
            return "Done";
        case InquiryState.Done:
            return "Done";
        case InquiryState.CustomerReject:
            return "Not Done - Customer Reject";
        case InquiryState.CustomerTimeout:
            return "Not Done - Customer Timeout";
        case InquiryState.DealerReject:
            return "Not Done - Dealer Reject";
        case InquiryState.DealerTimeout:
            return "Not Done - Dealer Timeout";
        case InquiryState.InquiryError:
            return "Inquiry Error";
        default:
            state satisfies never;
            throw new Error();
    }
}

export function mapInquirySideToShortDisplayName(side: InquirySide): string {
    switch (side) {
        case InquirySide.Buy:
            return "Pay";
        case InquirySide.Sell:
            return "Rcv";
        case InquirySide.Undisclosed:
            return "";
    }
}

export function foregroundColorFor(ignored?: DisplayState): Theme.Color {
    return { color: "white" };
}

export function backgroundColorFor(displayState?: DisplayState): Theme.Color | undefined {
    switch (displayState) {
        case DisplayState.Error:
            return { color: "danger", level: "500" };
        case DisplayState.Unavailable:
            return { color: "warning", level: "500" };
        default:
            return undefined;
    }
}

export function backgroundColorForStalePrices(
    price: string | null,
    validity: PriceValidity | null,
    isInquiryFinished: boolean,
    clock: EpochClock,
): Theme.Color | undefined {
    return hasPriceExpired(price, validity, isInquiryFinished, clock) ? { color: "warning", level: "500" } : undefined;
}

export function foregroundColorForStalePrices(
    price: string | null,
    validity: PriceValidity | null,
    isInquiryFinished: boolean,
    clock: EpochClock,
): Theme.Color | undefined {
    return hasPriceExpired(price, validity, isInquiryFinished, clock) ? { color: "black" } : undefined;
}

export function errorTextForStalePrices(
    price: string | null,
    validity: PriceValidity | null,
    pricerState: PricerState | null,
    isInquiryFinished: boolean,
    clock: EpochClock,
    errorMessage?: string,
): string | undefined {
    if (hasPriceExpired(price, validity, isInquiryFinished, clock)) {
        if (pricerState === PricerState.Priced) {
            return "Stale Price";
        } else {
            return "";
        }
    } else {
        return errorMessage;
    }
}

function hasPriceExpired(price: string | null, validity: PriceValidity | null, isInquiryFinished: boolean, clock: EpochClock): boolean {
    if (price === null) {
        return false;
    }

    return havePricesExpired(validity, isInquiryFinished, clock);
}

export function displayTextFor(displayState?: DisplayState): string | undefined {
    switch (displayState) {
        case DisplayState.Pending:
            return "Pending";
        case DisplayState.Error:
            return "Error";
        case DisplayState.Unavailable:
            return "N/A";
        default:
            return undefined;
    }
}

export function mapPricerStateToDisplayState(pricerState: PricerState | null): DisplayState {
    switch (pricerState) {
        case PricerState.Priced:
            return DisplayState.Valid;
        case PricerState.Terminated:
            return DisplayState.Valid;
        case PricerState.Invalid:
            return DisplayState.Error;
        case PricerState.Pending:
            return DisplayState.Pending;
        case PricerState.Rejected:
            return DisplayState.Unavailable;
        case PricerState.Error:
            return DisplayState.Error;
        case null:
            return DisplayState.Valid;
    }
}
