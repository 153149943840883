import { IRSInquiry } from "./models";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";

export const calculateNotional = (inquiry: IRSInquiry<"compression">): string => {
    let notional = 0;
    for (const leg of inquiry.legs) {
        notional += parseInt(leg.quantity);
    }
    return notional.toFixed(0);
};

export interface Increments {
    incrementLabels: [string, string, string];
    incrementValues: [DecimalNumber, DecimalNumber, DecimalNumber];
}

export const getIncrements = (minPriceIncrement: DecimalNumber | null): Increments => {
    const minimumIncrementThreshold = new ImmutableDecimalNumber("1000");
    const defaultLabels: [string, string, string] = ["1k", "10k", "100k"];
    const defaultValues: [DecimalNumber, DecimalNumber, DecimalNumber] = [
        new ImmutableDecimalNumber("1000"),
        new ImmutableDecimalNumber("10000"),
        new ImmutableDecimalNumber("100000"),
    ];

    if (minPriceIncrement === null || minPriceIncrement.equals(0)) {
        return { incrementLabels: defaultLabels, incrementValues: defaultValues };
    }

    if (minPriceIncrement.isLessThan(minimumIncrementThreshold)) {
        if (minimumIncrementThreshold.modulo(minPriceIncrement).equals(0)) {
            // if the minPriceIncrement is smaller and a factor of the threshold, use the default 1k 10k 100k buttons
            return { incrementLabels: defaultLabels, incrementValues: defaultValues };
        } else {
            const incrementValueButton1 = minimumIncrementThreshold.divideBy(minPriceIncrement).ceil().multiplyBy(minPriceIncrement);
            return getIncrementsForButton1Value(incrementValueButton1);
        }
    }

    return getIncrementsForButton1Value(minPriceIncrement);
};

const getIncrementsForButton1Value = (incrementValueButton1: DecimalNumber): Increments => {
    const incrementValueButton2 = incrementValueButton1.multiplyBy(10);
    const incrementValueButton3 = incrementValueButton1.multiplyBy(100);

    const incrementLabelButton1 = incrementValueButton1.divideBy(1000).toDecimalPlaces(3).toString() + "k";
    const incrementLabelButton2 = incrementValueButton2.divideBy(1000).toDecimalPlaces(2).toString() + "k";
    const incrementLabelButton3 = incrementValueButton3.divideBy(1000).toDecimalPlaces(1).toString() + "k";
    return {
        incrementLabels: [incrementLabelButton1, incrementLabelButton2, incrementLabelButton3],
        incrementValues: [incrementValueButton1, incrementValueButton2, incrementValueButton3],
    };
};
