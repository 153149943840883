import styled from "styled-components";
import { ThemeUtils } from "../../theme";

export interface TextProps {
    size?: Theme.FontSizes;
    weight?: Theme.FontWeights;
    textColor?: Theme.Color;
    textAlignment?: "left" | "center" | "right";
    srOnly?: boolean;
    truncateWithEllipsis?: boolean;
}

type TextPropsWithAs = TextProps & { as?: string };

export const Text = styled.p
    .withConfig<TextPropsWithAs>({
        shouldForwardProp: (prop, defaultValidatorFn) => ["sr-only"].includes(String(prop)) || defaultValidatorFn(prop),
    })
    .attrs<TextPropsWithAs>((props) => ({
        ...(props.srOnly ? { "sr-only": "true" } : {}),
    }))`
    color: ${({ textColor, theme }) => ThemeUtils.colorSelector(theme, textColor ?? { color: "white" })};
    font-size: ${({ size = "base", theme }) => (typeof size === "object" ? size.custom : theme.font.sizes[size])};
    font-weight: ${({ weight = "normal", theme }) => theme.font.weights[weight]};
    text-align: ${(props) => props.textAlignment};
    text-decoration: none;
    margin: 0;

    ${(props) =>
        props.srOnly
            ? `
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    `
            : ""}

    ${(props) =>
        props.as === "a"
            ? `
        &:hover {
            text-decoration: underline;
        }

    `
            : ""}

    ${(props) =>
        props.truncateWithEllipsis
            ? `
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
`
            : ""}
`;
