import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    acceptAction,
    autoStreamRequestAction,
    manualRequestAction,
    priceBasisUpdateAction,
    quoteAction,
    rejectAction,
} from "../../messaging/ApplicationProtocolMessages";
import { ThunkConfig } from "./ThunkConfig";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";

export interface DispatchableAction {
    transactTimeNs: number;
    actionId: string;
}

export const quote = createAsyncThunk<void, ActionPublisher.QuoteAction & DispatchableAction, ThunkConfig>(
    "inquiryActions/quote",
    (arg, { extra }) => {
        const message = quoteAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);

export const reject = createAsyncThunk<void, ActionPublisher.RejectAction & DispatchableAction, ThunkConfig>(
    "inquiryActions/reject",
    (arg, { extra }) => {
        const message = rejectAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);

export const accept = createAsyncThunk<void, ActionPublisher.AcceptAction & DispatchableAction, ThunkConfig>(
    "inquiryActions/accept",
    (arg, { extra }) => {
        const message = acceptAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);

export const priceBasisUpdate = createAsyncThunk<void, ActionPublisher.PriceBasisUpdateAction, ThunkConfig>(
    "inquiryActions/priceBasisUpdate",
    (arg, { extra }) => {
        const message = priceBasisUpdateAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);

export const autoStreamRequest = createAsyncThunk<void, ActionPublisher.AutoStreamRequestAction, ThunkConfig>(
    "inquiryActions/AutoStreamRequest",
    (arg, { extra }) => {
        const message = autoStreamRequestAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);

export const manualRequest = createAsyncThunk<void, ActionPublisher.ManualRequestAction, ThunkConfig>(
    "inquiryActions/ManualRequest",
    (arg, { extra }) => {
        const message = manualRequestAction(extra.clock, arg);
        extra.sessionTransport.send(JSON.stringify(message));
    },
);
