// eslint-disable-next-line @nx/enforce-module-boundaries
import "../../../libs/components/src/lib/theme/theme-fonts";
import * as React from "react";
import { ReactElement } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Application, log, loggerConfiguration, WebSocketDirectSessionTransport } from "@transficc/trader-desktop-core";
import { ActionIdGenerator, ApplicationContextProvider, SessionTransport } from "@transficc/trader-desktop-application-context";
import { v4 as uuidv4 } from "uuid";
import { SharedWorkerSessionTransport } from "./sharedworker/SharedWorkerSessionTransport";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { environment } from "./environments/environment";
import SharedWorkerFactoryImpl from "./sharedworker/SharedWorkerFactory";
import { disableSharedWorker } from "./FeatureFlags";
import { EpochClock, SystemEpochClock, SystemTimezoneProvider, TimezoneProvider } from "@transficc/infrastructure";
import { ErrorBoundary } from "@transficc/components";
import { HttpMetricConfiguration, HttpMetricPublisher } from "./metrics/HttpMetricPublisher";
import { createHeartbeatMetricFactory, createPlatformMetrics } from "./metrics/sensors";
import type { TraderDesktopElectronApi } from "@transficc/trader-desktop-electron-api";
import {
    getItemFromLocalStorage,
    getItemFromSessionStorage,
    LocalStorageKeys,
    SessionStorageKeys,
    setItemInLocalStorage,
    setItemInSessionStorage,
} from "@transficc/trader-desktop-local-storage";

function appVersionResolver(): string {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment
    const { version: appVersion } = require("../../../../build/transficcVersion.json");
    return appVersion as string;
}

window.addEventListener("error", function (e: ErrorEvent) {
    const msg = "Unhandled error occurred: ";

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (e.error && e.error.stack) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        log.error(msg, String(e.error), String(e.error.stack));
    } else {
        log.error(msg, String(e.error));
    }
    return false;
});

window.addEventListener("unhandledrejection", function (e: PromiseRejectionEvent) {
    const msg = "Unhandledrejection occurred: ";

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (e.reason && e.reason.stack) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        log.error(msg, String(e.reason), String(e.reason.stack));
    } else {
        log.error(msg, String(e.reason));
    }
});

const appVersion = appVersionResolver();

const oneHourInMillis = 60 * 60_000;
const httpMetricConfiguration = new HttpMetricConfiguration();
const httpMetricPublisher = new HttpMetricPublisher(httpMetricConfiguration);

const createSessionTransport = (disableSharedWorkerOverride: boolean): SessionTransport => {
    let sessionTransport: SessionTransport;

    if (typeof SharedWorker === "undefined" || disableSharedWorkerOverride) {
        log.info("No Shared worker available using WebSocketDirectSessionTransport");
        sessionTransport = new WebSocketDirectSessionTransport();
    } else {
        sessionTransport = new SharedWorkerSessionTransport(new SharedWorkerFactoryImpl(appVersion), httpMetricPublisher);
    }
    return sessionTransport;
};

const sessionTransport: SessionTransport = createSessionTransport(disableSharedWorker);

const clock: EpochClock = new SystemEpochClock();
const timezoneProvider: TimezoneProvider = new SystemTimezoneProvider();

function createWithBrowserRouter(routes: React.ReactElement): ReactElement {
    return <BrowserRouter>{routes}</BrowserRouter>;
}

const uuidActionIdGenerator: ActionIdGenerator = new (class implements ActionIdGenerator {
    next(): string {
        return uuidv4();
    }
})();

const resolveEnvironmentConfig = (): TraderDesktopProtocol.EnvironmentConfig => {
    if (environment.webpackDevServer) {
        return {
            identityProviderGatewayUrl: `http://${window.location.hostname}:40028`,
            hdsGatewayUrl: `http://${window.location.hostname}:30032`,
            featureFlags: window.__TF_ENVIRONMENT__?.featureFlags,
        };
    } else {
        return {
            identityProviderGatewayUrl: window.__TF_ENVIRONMENT__?.identityProviderGatewayUrl,
            hdsGatewayUrl: window.__TF_ENVIRONMENT__?.hdsGatewayUrl,
            featureFlags: window.__TF_ENVIRONMENT__?.featureFlags,
        };
    }
};

const environmentConfig: TraderDesktopProtocol.EnvironmentConfig = resolveEnvironmentConfig();

const resolveBrowserInstanceId = (): string => {
    const browserInstanceId = getItemFromLocalStorage(LocalStorageKeys.BROWSER_INSTANCE_ID);
    if (browserInstanceId == null) {
        const browserPrefix = "#";
        const generatedId = browserPrefix + Math.random().toString(36).substring(3, 12);
        setItemInLocalStorage(LocalStorageKeys.BROWSER_INSTANCE_ID, generatedId);
        return generatedId;
    }
    return browserInstanceId;
};

const resolveWindowInstanceId = (): string => {
    const windowId: string | null = getItemFromSessionStorage(SessionStorageKeys.WINDOW_ID);
    if (windowId == null) {
        const generatedId = Math.random().toString(36).substring(2, 12);
        setItemInSessionStorage(SessionStorageKeys.WINDOW_ID, generatedId);
        return generatedId;
    } else {
        return windowId;
    }
};

httpMetricConfiguration.setWindowId = resolveWindowInstanceId();
const browser = resolveBrowserInstanceId();

const electronApi: TraderDesktopElectronApi = {
    setWindowTitle: window.traderDesktopElectronApi?.setWindowTitle ?? ((ignored: string) => {}),
    moveTop: window.traderDesktopElectronApi?.moveTop ?? (() => {}),
    appInstanceId:
        window.traderDesktopElectronApi && window.traderDesktopElectronApi.appInstanceId != null
            ? window.traderDesktopElectronApi.appInstanceId
            : (callback: (appInstanceId: string) => void) => callback(browser),
    staticSysInfo:
        window.traderDesktopElectronApi && window.traderDesktopElectronApi.staticSysInfo != null
            ? window.traderDesktopElectronApi.staticSysInfo
            : (callback) => callback(null),
};

const heartbeatMetricFactory = createHeartbeatMetricFactory();
const inMainWindow = (): boolean => window.location.pathname === "/";

window.setInterval(() => {
    httpMetricPublisher.publish(heartbeatMetricFactory());
}, 5000);

electronApi.appInstanceId((appInstanceId) => {
    loggerConfiguration.setAppInstanceId = appInstanceId;
    httpMetricConfiguration.setAppInstanceId = appInstanceId;
});

if (inMainWindow()) {
    electronApi.staticSysInfo((staticSysInfo) => {
        let electronAppVersion: string;
        if (staticSysInfo != null) {
            electronAppVersion = staticSysInfo.appVersion;
        } else {
            electronAppVersion = "";
        }
        window.setTimeout(() => {
            httpMetricPublisher.publish(createPlatformMetrics(appVersion, electronAppVersion));
            window.setInterval(() => {
                httpMetricPublisher.publish(createPlatformMetrics(appVersion, electronAppVersion));
            }, oneHourInMillis);
        }, 10_000);
    });
}

window.addEventListener("storage", (event: StorageEvent) => {
    if (event.key === LocalStorageKeys.ACCESS_TOKEN && event.newValue === null) {
        window.close();
        window.location.reload(); //close can be ignored if the window is not opened by us i.e. a user typing in the url - this takes one back to logon screen
    }
});

const container = document.getElementById("root");
if (!container) {
    throw new Error("Failed to render page.");
}
const root = ReactDOMClient.createRoot(container);

root.render(
    <React.StrictMode>
        <ErrorBoundary
            reportError={({ name, message, stack }, errorInfo) => log.error(name, message, stack, errorInfo.componentStack)}
            renderError={() => (
                <h1>
                    An error occurred. This has been automatically reported to Transficc, please contact support for further information.
                </h1>
            )}
        >
            <ApplicationContextProvider
                clock={clock}
                timezoneProvider={timezoneProvider}
                actionIdGenerator={uuidActionIdGenerator}
                appVersion={appVersion}
                electronApi={electronApi}
                logger={log}
                sessionTransport={sessionTransport}
                environmentConfig={environmentConfig}
                metricPublisher={httpMetricPublisher}
            >
                <Application
                    routerCreator={createWithBrowserRouter}
                    messageBatchingMaxBatchSize={100}
                    messageBatchingMaxBatchIntervalMs={100}
                />
            </ApplicationContextProvider>
        </ErrorBoundary>
    </React.StrictMode>,
);
