import { useMemo } from "react";
import { AllSides, InquiryAction, irs, IrsState, Side } from "./irs-slice";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { useDispatch } from "react-redux";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { DecimalNumber } from "@transficc/infrastructure";
import { SelectedDriver } from "../irs-domain";

export interface IRSStateDispatch {
    upsert: (inquiry: InquiryAction) => void;
    updateOnDemandPrices: (prices: TraderDesktopProtocol.OnDemandPrices) => void;
    updatePriceBasis: (prices: TraderDesktopProtocol.PriceBasis) => void;
    dismiss: (ticketId: number) => void;

    updateSelectedInquiry: (ticketId: number) => void;
    updateLegCustomerRate: (legPosition: number, quotingSide: Side, newValue: DecimalNumber) => void;
    updateLegSpreadValue: (legPosition: number, quotingSide: Side, newValue: DecimalNumber) => void;
    setDirty: (isDirty: boolean) => void;
    updatePtmmValue: (ptmmValue: DecimalNumber, legPosition?: number) => void;
    priceBasisUpdateSent: (ticketId: number) => void;
    selectDriver: (side: AllSides, driver: SelectedDriver, legPosition?: number) => void;
    deselectDriver: (side: AllSides, legPosition?: number) => void;
    clearCircuitBreaker: () => void;
    closeActionFailure: (actionId: string) => void;
    updatePackageValue: (packageValue: DecimalNumber, quotingSide: AllSides) => void;
    updatePackageSpreadValue: (spreadValue: DecimalNumber, quotingSide: AllSides) => void;
}

export const useIRSStateDispatch = (): IRSStateDispatch => {
    const dispatch = useDispatch<ThunkDispatch<{ irs: IrsState }, unknown, AnyAction>>();
    return useMemo((): IRSStateDispatch => {
        return {
            upsert: (inquiry: InquiryAction) => dispatch(irs.actions.upsert(inquiry)),
            updateOnDemandPrices: (prices: TraderDesktopProtocol.OnDemandPrices) => dispatch(irs.actions.updateOnDemandPrices(prices)),
            updatePriceBasis: (prices: TraderDesktopProtocol.PriceBasis) => dispatch(irs.actions.updatePriceBasis(prices)),
            dismiss: (ticketId: number) => dispatch(irs.actions.dismiss(ticketId)),

            updateSelectedInquiry: (ticketId: number) => dispatch(irs.actions.updateSelectedInquiry({ ticketId })),
            updateLegCustomerRate: (legPosition, quotingSide, newValue) =>
                dispatch(irs.actions.updateLegCustomerRate({ legPosition, quotingSide, newValue: newValue })),
            updateLegSpreadValue: (legPosition, quotingSide, spreadValue) =>
                dispatch(irs.actions.updateSpreadValue({ legPosition, quotingSide, spreadValue })),
            selectDriver: (side, driver, legPosition) => dispatch(irs.actions.selectDriver({ side, driver, legPosition })),
            deselectDriver: (side, legPosition) => dispatch(irs.actions.deselectDriver({ side, legPosition })),
            setDirty: (isDirty) => dispatch(irs.actions.setDirty({ isDirty })),
            updatePtmmValue: (ptmmValue, legPosition) => dispatch(irs.actions.updatePtmmValue({ ptmmValue, legPosition })),
            priceBasisUpdateSent: (ticketId) => dispatch(irs.actions.priceBasisUpdateSent({ ticketId })),
            clearCircuitBreaker: () => dispatch(irs.actions.clearCircuitBreaker()),
            closeActionFailure: (actionId) => dispatch(irs.actions.closedActionFailure({ actionId })),
            updatePackageValue: (packageValue, quotingSide) => dispatch(irs.actions.updatePackageValue({ packageValue, quotingSide })),
            updatePackageSpreadValue: (spreadValue, quotingSide) =>
                dispatch(irs.actions.updatePackageSpreadValue({ spreadValue, quotingSide })),
        };
    }, [dispatch]);
};
