import { useCallback } from "react";
import * as IRSDomain from "../irs-domain";
import { BidMidAsk, DisplayState, IRSInquiry, mapPricerStateToDisplayState, PricerState, SelectedDriver } from "../irs-domain";
import { DecimalNumber } from "@transficc/infrastructure";
import { priceIsStale } from "../irs-domain/stale-prices";
import { LegSideState, useDefinitelySelectedInquiry, useIRSReduxState } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";
import { useClock } from "@transficc/trader-desktop-application-context";

export interface IRSSideView {
    spreadValue: DecimalNumber | null;
    updateSpreadValue: (customerRate: DecimalNumber) => void;
    spreadInputDisabled: boolean;

    customerRateValue: DecimalNumber | null;
    updateCustomerRate: (customerRate: DecimalNumber) => void;
    shouldShowCustomerRateInputAsStale: boolean;

    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
    selectedDriver: SelectedDriver | null;
    selectedDriverDisplayState: DisplayState;

    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

export interface IRSOutrightView {
    autoQuoteDisplayState: DisplayState;

    leg: IRSDomain.IRSLeg<"outright">;

    pay: IRSSideView;
    rcv: IRSSideView;
}

export interface IRSQuotingState {
    spreadValue: DecimalNumber | null;
    updateSpreadValue: (customerRate: DecimalNumber) => void;

    customerRateValue: DecimalNumber | null;
    manualRateValue: DecimalNumber | null;
    updateCustomerRate: (customerRate: DecimalNumber) => void;

    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
    selectedDriver: SelectedDriver | null;
    hasDriverValue: boolean;

    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

export function useQuotingCallbacks(state: LegSideState, quotingSide: "PAY" | "RCV"): IRSQuotingState {
    const { updateLegCustomerRate, updateLegSpreadValue, selectDriver, deselectDriver } = useIRSStateDispatch();
    return {
        spreadValue: state.spreadValue,
        updateSpreadValue: useCallback(
            (updatedSpreadValue: DecimalNumber) => {
                updateLegSpreadValue(0, quotingSide, updatedSpreadValue);
            },
            [quotingSide, updateLegSpreadValue],
        ),

        customerRateValue: state.customerRateValue,
        updateCustomerRate: useCallback(
            (updatedCustomerRate: DecimalNumber) => {
                updateLegCustomerRate(0, quotingSide, updatedCustomerRate);
            },
            [updateLegCustomerRate, quotingSide],
        ),
        manualRateValue: state.manualCustomerRateValue,

        selectDriver: useCallback(
            (driver: SelectedDriver) => {
                selectDriver(quotingSide, driver);
            },
            [quotingSide, selectDriver],
        ),
        clearDriverValue: useCallback(() => {
            deselectDriver(quotingSide);
        }, [deselectDriver, quotingSide]),
        selectedDriver: state.selectedDriver,
        hasDriverValue: state.hasDriverValue,

        autoQuotePrices: state.autoQuotePrices,
        venuePrices: state.venuePrices,
        latestQuotedPrices: state.latestQuotedPrices,
    };
}

export const useIRSOutrightView = (isInquiryFinished: boolean): IRSOutrightView => {
    const clock = useClock();

    const inquiry = useDefinitelySelectedInquiry() as IRSInquiry<"outright">;
    const state = useIRSReduxState();

    if (state.type !== "leg-based") {
        throw new Error("Tried to use non-leg-based state in leg-based inquiry");
    }

    const leg = inquiry.legs[0];
    const stateLeg = state.legs[0];

    if (!stateLeg) {
        throw new Error("Outright without any legs is not allowed");
    }

    const payCallbacks = useQuotingCallbacks(stateLeg.pay, "PAY");
    const receiveCallbacks = useQuotingCallbacks(stateLeg.rcv, "RCV");

    const autoQuoteDisplayState: DisplayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);

    const isOnDemandPricerPriceStale: boolean = priceIsStale(inquiry.autoQuotePriceValidity, clock);
    const isOnDemandPricerActive: boolean = inquiry.autoQuotePriceState === PricerState.Priced;

    const paySelectedDriver = stateLeg.pay.selectedDriver;
    const payIsAutoQuoteDriverSelected = paySelectedDriver?.rowNo === 1;
    const paySelectedDriverDisplayState = payIsAutoQuoteDriverSelected ? autoQuoteDisplayState : DisplayState.Valid;

    const rcvSelectedDriver = stateLeg.rcv.selectedDriver;
    const rcvIsAutoQuoteDriverSelected = rcvSelectedDriver?.rowNo === 1;
    const rcvSelectedDriverDisplayState = rcvIsAutoQuoteDriverSelected ? autoQuoteDisplayState : DisplayState.Valid;

    const paySpreadInputDisabled = paySelectedDriver === null || !stateLeg.pay.hasDriverValue || isInquiryFinished;
    const rcvSpreadInputDisabled = rcvSelectedDriver === null || !stateLeg.rcv.hasDriverValue || isInquiryFinished;

    return {
        pay: {
            spreadValue: stateLeg.pay.spreadValue,
            updateSpreadValue: payCallbacks.updateSpreadValue,
            customerRateValue: payCallbacks.customerRateValue,
            updateCustomerRate: payCallbacks.updateCustomerRate,
            selectDriver: payCallbacks.selectDriver,
            clearDriverValue: payCallbacks.clearDriverValue,
            selectedDriver: payCallbacks.selectedDriver,
            selectedDriverDisplayState: paySelectedDriverDisplayState,
            spreadInputDisabled: paySpreadInputDisabled,
            autoQuotePrices: payCallbacks.autoQuotePrices,
            venuePrices: payCallbacks.venuePrices,
            latestQuotedPrices: stateLeg.pay.latestQuotedPrices,
            shouldShowCustomerRateInputAsStale: payIsAutoQuoteDriverSelected && isOnDemandPricerPriceStale && isOnDemandPricerActive,
        },
        rcv: {
            spreadValue: stateLeg.rcv.spreadValue,
            updateSpreadValue: receiveCallbacks.updateSpreadValue,
            customerRateValue: receiveCallbacks.customerRateValue,
            updateCustomerRate: receiveCallbacks.updateCustomerRate,
            selectDriver: receiveCallbacks.selectDriver,
            clearDriverValue: receiveCallbacks.clearDriverValue,
            selectedDriver: stateLeg.rcv.selectedDriver,
            selectedDriverDisplayState: rcvSelectedDriverDisplayState,
            spreadInputDisabled: rcvSpreadInputDisabled,
            autoQuotePrices: stateLeg.rcv.autoQuotePrices,
            venuePrices: stateLeg.rcv.venuePrices,
            latestQuotedPrices: stateLeg.rcv.latestQuotedPrices,
            shouldShowCustomerRateInputAsStale: rcvIsAutoQuoteDriverSelected && isOnDemandPricerPriceStale && isOnDemandPricerActive,
        },
        leg,
        autoQuoteDisplayState,
    };
};
