import { InquirySide, InquiryState } from "./enums";

export const SellBackgroundColor: Theme.Color = { color: "rose", level: "200" };
export const BuyBackgroundColor: Theme.Color = { color: "cyan", level: "200" };

export const SellForegroundColor: Theme.Color = { color: "black" };
export const BuyForegroundColor: Theme.Color = { color: "black" };

export const getStateBarBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.NewRequest:
        case InquiryState.CurtainPeriod:
            return { color: "warning", level: "400" };
        case InquiryState.CurtainQuoteStreaming:
        case InquiryState.QuoteOTW:
        case InquiryState.QuoteFirm:
        case InquiryState.QuoteRefreshRequested:
            return { color: "info", level: "500" };
        case InquiryState.QuoteSubject:
            return { color: "warning", level: "800" };
        case InquiryState.LastLook:
            return { color: "rose", level: "600" };
        case InquiryState.PendingSpot:
        case InquiryState.PendingPriceConfirmation:
            return { color: "gray", level: "200" };
        case InquiryState.Done:
        case InquiryState.DealerAccepted:
        case InquiryState.CustomerAccepted:
            return { color: "success", level: "500" };
        case InquiryState.AutoSpotFailed:
            return { color: "danger", level: "600" };
        case InquiryState.InquiryError:
        case InquiryState.InquiryPickedUpOnVenueUI:
        case InquiryState.CustomerReject:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerReject:
        case InquiryState.DealerTimeout:
            return { color: "gray", level: "500" };
        default:
            state satisfies never;
            return { color: "gray", level: "500" };
    }
};

export const getStateBarForegroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.NewRequest:
        case InquiryState.CurtainPeriod:
        case InquiryState.PendingSpot:
        case InquiryState.PendingPriceConfirmation:
            return { color: "black" };
        default:
            return { color: "white" };
    }
};

export const getStateDisplayText = (state: InquiryState): string => {
    switch (state) {
        case InquiryState.CurtainPeriod:
            return "New Request - Curtain";
        case InquiryState.NewRequest:
            return "New Request - ASAP";
        case InquiryState.CurtainQuoteStreaming:
            return "Quoted - Curtain Period";
        case InquiryState.QuoteFirm:
        case InquiryState.QuoteOTW:
            return "Quote Live";
        case InquiryState.QuoteRefreshRequested:
            return "Quote Refresh Requested";
        case InquiryState.QuoteSubject:
            return "Quote Subject";
        case InquiryState.LastLook:
            return "Last Look";
        case InquiryState.DealerAccepted:
            return "Done";
        case InquiryState.CustomerAccepted:
            return "Done";
        case InquiryState.PendingSpot:
            return "Done - Pending Spot";
        case InquiryState.PendingPriceConfirmation:
            return "Done - Pending Price Confirmation";
        case InquiryState.Done:
            return "Done";
        case InquiryState.CustomerReject:
            return "Not Done - Customer Reject";
        case InquiryState.CustomerTimeout:
            return "Not Done - Customer Timeout";
        case InquiryState.DealerReject:
            return "Not Done - Dealer Reject";
        case InquiryState.DealerTimeout:
            return "Not Done - Dealer Timeout";
        case InquiryState.InquiryPickedUpOnVenueUI:
            return "Inquiry Picked Up On Venue UI";
        case InquiryState.InquiryError:
            return "Inquiry Error";
        case InquiryState.AutoSpotFailed:
            return "Auto-Spot Failed";
        default:
            state satisfies never;
            throw new Error();
    }
};

export const getSideBackgroundColor = (side: InquirySide): Theme.Color => {
    switch (side) {
        case InquirySide.Sell:
            return SellBackgroundColor;
        case InquirySide.Buy:
            return BuyBackgroundColor;
        default:
            side satisfies never;
            throw new Error();
    }
};

export const getSideForegroundColor = (side: InquirySide): Theme.Color => {
    switch (side) {
        case InquirySide.Sell:
            return SellForegroundColor;
        case InquirySide.Buy:
            return BuyForegroundColor;
        default:
            side satisfies never;
            throw new Error();
    }
};

export const getWholeInquiryBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.LastLook:
            return { color: "rose", level: "900" };
        case InquiryState.Done:
        case InquiryState.InquiryError:
        case InquiryState.CustomerReject:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerReject:
        case InquiryState.DealerTimeout:
            return { color: "gray", level: "900" };
        default:
            return { color: "transparent" };
    }
};

export const getWholeInquiryBackgroundShouldFlash = (state: InquiryState): boolean => {
    switch (state) {
        case InquiryState.LastLook:
            return true;
        default:
            return false;
    }
};

export const DEFAULT_ON_THE_WIRE_TIME_MILLIS = 60_000;
