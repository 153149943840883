// eslint-disable-next-line @nx/enforce-module-boundaries
import BigNumber from "bignumber.js";

type Value = DecimalNumber | string | number;

export interface DecimalNumber {
    add: (n: Value) => DecimalNumber;

    subtract: (n: Value) => DecimalNumber;

    equals: (n: Value) => boolean;

    multiplyBy: (n: Value) => DecimalNumber;

    divideBy: (n: Value) => DecimalNumber;

    modulo: (n: Value) => DecimalNumber;

    negated: () => DecimalNumber;

    isFinite: () => boolean;

    format: (decimalPlaces: number | DecimalNumber) => string;

    numberOfDecimalPlaces: () => number | null;

    toDecimalPlaces: (decimalPlaces: number) => DecimalNumber;

    toTruncateDecimalPlaces: (decimalPlaces: number) => DecimalNumber;

    toNumber: () => number;

    isLessThan: (other: Value) => boolean;

    isGreaterThan: (other: Value) => boolean;

    ceil: () => DecimalNumber;

    isNaN: () => boolean;
}

export class ImmutableDecimalNumber implements DecimalNumber {
    /** Used internally to identify a BigNumber instance. */
    private readonly _isDecimalNumber = true;

    private readonly number: BigNumber;

    constructor(number: string | number | DecimalNumber | BigNumber) {
        if (typeof number === "string" || typeof number === "number") {
            this.number = BigNumber(number);
        } else if ((number as ImmutableDecimalNumber)._isDecimalNumber) {
            this.number = BigNumber((number as ImmutableDecimalNumber).number);
        } else {
            this.number = number as BigNumber;
        }
    }

    subtract(n: Value): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.minus(this.getNumber(n)));
    }

    equals(n: Value): boolean {
        return this.number.eq(this.getNumber(n));
    }

    add(n: Value): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.plus(this.getNumber(n)));
    }

    multiplyBy(n: Value): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.times(this.getNumber(n)));
    }

    divideBy(n: Value): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.div(this.getNumber(n)));
    }

    modulo(n: Value): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.modulo(this.getNumber(n)));
    }

    negated(): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.negated());
    }

    isFinite(): boolean {
        return this.number.isFinite();
    }

    format(decimalPlaces: number | DecimalNumber): string {
        if (typeof decimalPlaces === "number") {
            return this.number.toFixed(decimalPlaces, BigNumber.ROUND_HALF_EVEN);
        }
        return this.number.toFixed((decimalPlaces as ImmutableDecimalNumber).number.toNumber(), BigNumber.ROUND_HALF_EVEN);
    }

    numberOfDecimalPlaces(): number | null {
        return this.number.decimalPlaces();
    }

    toDecimalPlaces(decimalPlaces: number): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_EVEN));
    }

    toTruncateDecimalPlaces(decimalPlaces: number): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.toFixed(decimalPlaces, BigNumber.ROUND_FLOOR));
    }

    toNumber(): number {
        return this.number.toNumber();
    }

    isLessThan(other: Value): boolean {
        return this.number.isLessThan(this.getNumber(other));
    }

    isGreaterThan(other: Value): boolean {
        return this.number.isGreaterThan(this.getNumber(other));
    }

    ceil(): DecimalNumber {
        return new ImmutableDecimalNumber(this.number.decimalPlaces(0, BigNumber.ROUND_CEIL));
    }

    isNaN(): boolean {
        return this.number.isNaN();
    }

    toString(): string {
        return this.number.toString();
    }

    private getNumber(number: Value): BigNumber.Value {
        if (typeof number === "string" || typeof number === "number") {
            return number;
        }
        return (number as ImmutableDecimalNumber).number;
    }
}

export const max = (...elements: DecimalNumber[]): DecimalNumber => {
    return elements.reduce((previousValue, currentValue) => (previousValue.isGreaterThan(currentValue) ? previousValue : currentValue));
};
