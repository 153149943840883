import React, { useMemo } from "react";
import * as IRSDomain from "../irs-domain";
import { formatQuantityToThreeDp, formatToThousandsWithK, PackageType } from "../irs-domain";
import styled from "styled-components";
import { calculateNotional } from "../irs-domain/compression-derivations";
import { colorForSide } from "../irs-domain/colors";
import { Box, formatWithCommas, Text } from "@transficc/components";

const IRSActiveInquirySummaryLegContainer = styled(Box)`
    & > ${Text} {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:not(:last-child) {
        border-bottom: 1px solid black;
    }
`;

export interface IRSActiveInquiryBodyProps {
    inquiry: IRSDomain.IRSInquiry<PackageType>;
}

function renderLeg(leg: IRSDomain.IRSLeg<IRSDomain.PackageType>): React.ReactNode {
    const sideColor: Theme.Color = colorForSide(leg.side);
    const productIdWithSidePrefix = `${IRSDomain.mapInquirySideToShortDisplayName(leg.side)} ${leg.instrumentName}`.trim();

    return (
        <IRSActiveInquirySummaryLegContainer key={leg.position} width={"full"} backgroundColor={sideColor} padding={"1.5"}>
            <Text textColor={{ color: "black" }} size={"sm"}>
                {productIdWithSidePrefix}
            </Text>
            <Text textColor={{ color: "black" }} size={"sm"}>
                {formatQuantityToThreeDp(leg.quantity)}
            </Text>
        </IRSActiveInquirySummaryLegContainer>
    );
}

const IRSActiveInquiryListSummaryRow: React.FC<{ title: string; value: string; valueColor?: Theme.Color }> = ({
    title,
    value,
    valueColor = { color: "black" },
}) => {
    return (
        <Box width="full" contentDirection={"row"} contentAlignment={"center"}>
            <Text textColor={{ color: "black" }} size={"sm"}>
                {title}&nbsp;
            </Text>
            <Text srOnly>{title}</Text>
            <Text textColor={valueColor} size={"sm"} weight={"bold"}>
                {value}
            </Text>
        </Box>
    );
};

const IRSActiveInquiryCompressionSummary: React.FC<{ inquiry: IRSDomain.IRSInquiry<"compression"> }> = ({ inquiry }) => {
    const legCountText = useMemo(
        () => inquiry.legs.length.toString() + " " + (inquiry.legs.length === 1 ? "Item" : "Items"),
        [inquiry.legs.length],
    );

    const notionalText = useMemo(() => {
        const notional = calculateNotional(inquiry);
        return formatWithCommas(formatQuantityToThreeDp(notional));
    }, [inquiry]);

    const delta = useMemo(() => inquiry.packageDelta, [inquiry]);

    const deltaText = useMemo(() => {
        if (delta != null) {
            return formatToThousandsWithK(delta);
        } else {
            return "-";
        }
    }, [delta]);

    const deltaColor: Theme.Color = useMemo(() => {
        if (delta !== null && Number.parseInt(delta) > 0) {
            return { color: "success", level: "600" };
        } else {
            return { color: "danger", level: "600" };
        }
    }, [delta]);

    return (
        <IRSActiveInquirySummaryLegContainer backgroundColor={{ color: "gray", level: "300" }} width={"full"} padding={"1.5"}>
            <IRSActiveInquiryListSummaryRow title="LIST" value={legCountText} />
            <IRSActiveInquiryListSummaryRow title="Notional" value={notionalText ?? ""} />
            <IRSActiveInquiryListSummaryRow title="Delta" value={deltaText} valueColor={deltaColor} />
        </IRSActiveInquirySummaryLegContainer>
    );
};

export const IRSActiveInquiryBody: React.FC<IRSActiveInquiryBodyProps> = ({ inquiry }) => {
    switch (inquiry.packageType) {
        case "outright":
            return <>{inquiry.legs.map((leg) => renderLeg(leg))}</>;
        case "curve":
            return <>{inquiry.legs.map((leg) => renderLeg(leg))}</>;
        case "compression":
            return <IRSActiveInquiryCompressionSummary inquiry={inquiry as IRSDomain.IRSInquiry<"compression">} />;
    }
};
