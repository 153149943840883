/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { credit, creditSelectors, CreditState, InquiryData, isDismissed } from "./CreditSlice";
import { AllowedAction, Inquiry, InquiryState, PricerState, PriceType } from "../credit-domain";
import { AnyAction, createSelector, Dispatch } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { displayTextFor } from "../credit-domain/enums";

interface PartialRootState {
    credit: CreditState;
}

const createCreditSelector = createSelector.withTypes<PartialRootState>();
const useCreditStateSelector: TypedUseSelectorHook<PartialRootState> = useSelector;

const selectAllActiveInquiries = createCreditSelector(creditSelectors.allInquiries, (inquiries) => inquiries.map((i) => i.inquiry));

export const useActiveCreditInquiries = (): Inquiry[] => useCreditStateSelector(selectAllActiveInquiries);

function mapInquiryRowData(inquiryState: InquiryData): InquiryRowData {
    const inquiry = inquiryState.inquiry;

    const autoQuoteCellIsSelected = inquiryState.ticketState.selectedDriverCellPosition?.rowNo === 1;
    const pricerNotInPricedState = inquiryState.inquiry.autoQuotePriceState !== PricerState.Priced;
    const shouldNotDisplayLevel = pricerNotInPricedState && autoQuoteCellIsSelected;
    const levelValueToDisplay = inquiryState.ticketState.priceState[inquiry.leg.priceType].value;
    const level = inquiry.leg.negotiatedPrice ?? (shouldNotDisplayLevel ? null : levelValueToDisplay);

    return { ...inquiry, level };
}

export type InquiryRowData = Inquiry & {
    level: string | null;
};

export const useActiveCreditInquiriesForRowData = (): InquiryRowData[] =>
    useCreditStateSelector(
        createCreditSelector([creditSelectors.allInquiries, (state) => state.credit.inquiries], (inquiries) => {
            return inquiries.map((inquiryState) => {
                return {
                    ...mapInquiryRowData(inquiryState),
                };
            });
        }),
    );

export const useSelectedPrimaryInquiryTicketId = () => useCreditStateSelector(creditSelectors.selectedPrimaryInquiryTicketId);

const getSelectedInquiry = (state: { credit: CreditState }): Inquiry | null => {
    const selectedPrimaryInquiryTicketId = creditSelectors.selectedPrimaryInquiryTicketId(state);

    if (selectedPrimaryInquiryTicketId === null) {
        return null;
    }

    return creditSelectors.definitelyInquiryByTicketId(state, selectedPrimaryInquiryTicketId);
};

export const useDisplayedInquiryNullable = () => useCreditStateSelector(getSelectedInquiry);

export const useDisplayedInquiry = () =>
    useCreditStateSelector((state) => {
        const inquiry = getSelectedInquiry(state);

        if (inquiry == null) {
            throw new Error("Required an inquiry to be selected");
        }

        return inquiry;
    });

const isNegotiationFinished = (inquiryState: InquiryState): boolean => {
    switch (inquiryState) {
        case InquiryState.NewRequest:
        case InquiryState.CurtainPeriod:
        case InquiryState.CurtainQuoteStreaming:
        case InquiryState.QuoteOTW:
        case InquiryState.QuoteSubject:
        case InquiryState.QuoteFirm:
        case InquiryState.LastLook:
        case InquiryState.QuoteRefreshRequested:
            return false;
        case InquiryState.PendingSpot:
        case InquiryState.PendingPriceConfirmation:
        case InquiryState.CustomerReject:
        case InquiryState.DealerReject:
        case InquiryState.DealerAccepted:
        case InquiryState.CustomerAccepted:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerTimeout:
        case InquiryState.InquiryError:
        case InquiryState.InquiryPickedUpOnVenueUI:
        case InquiryState.AutoSpotFailed:
        case InquiryState.Done:
            return true;
        default:
            inquiryState satisfies never;
            throw new Error();
    }
};

const createSelectNegotiationIsFinished = (ticketId: number) =>
    createCreditSelector([(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).state], isNegotiationFinished);

const createSelectSelectedDriver = (ticketId: number) =>
    createCreditSelector([(state) => creditSelectors.ticketState(state, ticketId)], (state) => state.selectedDriverCellPosition);

const createSelectIsPrimaryPriceType = (ticketId: number, priceType: PriceType) =>
    createCreditSelector(
        [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg],
        (leg) => leg.priceType === priceType,
    );

const driverStateSelector = (ticketId: number) =>
    createCreditSelector([createSelectSelectedDriver(ticketId)], (selectedDriverCellPosition) => {
        const isDrivingManually = selectedDriverCellPosition === null;
        const isDrivingFromAutoQuoter = selectedDriverCellPosition?.rowNo === 1;
        const driverState: "manual" | "fromAQ" | "fromLatestQuote" = isDrivingManually
            ? "manual"
            : isDrivingFromAutoQuoter
            ? "fromAQ"
            : "fromLatestQuote";
        return driverState;
    });

const selectNegotiationPriceIfNegotiationFinished = (ticketId: number) =>
    createCreditSelector(
        [
            (state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg.negotiatedPrice,
            createSelectNegotiationIsFinished(ticketId),
        ],
        (negotiatedPrice, negotiationIsFinished) =>
            negotiationIsFinished && negotiatedPrice ? new ImmutableDecimalNumber(negotiatedPrice) : null,
    );

const selectDriverDisplayText = (ticketId: number) =>
    createCreditSelector(
        [
            driverStateSelector(ticketId),
            (state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).autoQuotePriceState,
            selectNegotiationPriceIfNegotiationFinished(ticketId),
        ],
        (driverState, autoQuotePriceState, negotiatedPrice) => {
            if (negotiatedPrice !== null) {
                return undefined;
            }
            return driverState === "fromAQ" ? displayTextFor(autoQuotePriceState) : undefined;
        },
    );

export const CreditTicket = {
    useSelectedDriver: (ticketId: number) => {
        const selectSelectedDriver = useMemo(() => createSelectSelectedDriver(ticketId), [ticketId]);

        return useCreditStateSelector(selectSelectedDriver);
    },
    useDriverState: (ticketId: number) => {
        const selectDriverState = useMemo(() => driverStateSelector(ticketId), [ticketId]);

        return useCreditStateSelector(selectDriverState);
    },
    useAutoQuotePriceState: (ticketId: number) => {
        const selectAutoQuotePriceState = useMemo(
            () =>
                createCreditSelector([(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId)], (state) => {
                    return {
                        autoQuotePriceState: state.autoQuotePriceState,
                        autoQuotePricesGenerationTimestampNanos: state.autoQuotePricesGenerationTimestampNanos,
                        autoQuotePricesValidForNanos: state.autoQuotePricesValidForNanos,
                        isInquiryDismissed: isDismissed(state.state),
                    };
                }),
            [ticketId],
        );

        return useCreditStateSelector(selectAutoQuotePriceState);
    },
    usePriceValueForPriceType: (ticketId: number, priceType: PriceType) => {
        const dispatch = useDispatch<Dispatch<AnyAction>>();

        const selectPriceValue = useMemo(
            () =>
                createCreditSelector(
                    [
                        createCreditSelector(
                            [(state) => creditSelectors.ticketPriceStateForPriceType(state, ticketId, priceType).value],
                            (value) => (value ? new ImmutableDecimalNumber(value) : null),
                        ),
                        selectNegotiationPriceIfNegotiationFinished(ticketId),
                    ],
                    (valueForPriceType, negotiatedPrice) => negotiatedPrice ?? valueForPriceType,
                ),
            [ticketId, priceType],
        );

        const selectMinPriceIncrement = useMemo(
            () =>
                createCreditSelector(
                    [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg.minPriceIncrement],
                    (minPriceIncrement) => new ImmutableDecimalNumber(minPriceIncrement),
                ),
            [ticketId],
        );

        const selectIsPrimaryPriceType = useMemo(() => createSelectIsPrimaryPriceType(ticketId, priceType), [priceType, ticketId]);

        const shouldBeHighlighted = useMemo(
            () =>
                createCreditSelector(
                    [createSelectIsPrimaryPriceType(ticketId, priceType), driverStateSelector(ticketId)],
                    (isPrimaryPriceType, driverState) => {
                        return isPrimaryPriceType && driverState === "manual";
                    },
                ),
            [priceType, ticketId],
        );

        const driverStateDisplayText = useMemo(() => selectDriverDisplayText(ticketId), [ticketId]);

        const isDisplayingDriverState = useMemo(
            () =>
                createCreditSelector(
                    [createSelectIsPrimaryPriceType(ticketId, priceType), driverStateSelector(ticketId), selectDriverDisplayText(ticketId)],
                    (isPrimaryPriceType, driverState, driverDisplayText) => {
                        return isPrimaryPriceType && driverState === "fromAQ" && driverDisplayText !== undefined;
                    },
                ),
            [priceType, ticketId],
        );

        const selectIsDisabled = useMemo(
            () =>
                createCreditSelector(
                    [createSelectNegotiationIsFinished(ticketId), createSelectIsPrimaryPriceType(ticketId, priceType)],
                    (negotiationIsFinished, isPrimaryPriceType) => !isPrimaryPriceType || negotiationIsFinished,
                ),
            [priceType, ticketId],
        );

        return {
            value: useCreditStateSelector(selectPriceValue),
            onValueChange: useCallback(
                (priceValue: DecimalNumber | null) =>
                    dispatch(credit.actions.setPriceValue({ ticketId, priceType, priceValue: priceValue ? priceValue.toString() : null })),
                [dispatch, priceType, ticketId],
            ),
            onDirtyChange: useCallback(
                (isDirty: boolean) => dispatch(credit.actions.setIsDirty({ ticketId, priceType, isDirty })),
                [dispatch, priceType, ticketId],
            ),
            minPriceIncrement: useCreditStateSelector(selectMinPriceIncrement),
            isPrimaryPriceType: useCreditStateSelector(selectIsPrimaryPriceType),
            isDisabled: useCreditStateSelector(selectIsDisabled),
            shouldBeHighlighted: useCreditStateSelector(shouldBeHighlighted),
            driverStateDisplayText: useCreditStateSelector(driverStateDisplayText),
            isDisplayingDriverState: useCreditStateSelector(isDisplayingDriverState),
        };
    },
    usePriceValueForInquiry: (ticketId: number) => {
        return CreditTicket.usePriceValueForPriceType(
            ticketId,
            useCreditStateSelector((state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg.priceType),
        );
    },
    useNegotiationIsFinished: (ticketId: number) => {
        const selectNegotiationIsFinished = useMemo(() => createSelectNegotiationIsFinished(ticketId), [ticketId]);
        return useCreditStateSelector(selectNegotiationIsFinished);
    },
    usePriceMatrixState: (ticketId: number) => {
        const selectPriceMatrixState = createCreditSelector(
            [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId)],
            (inquiry) => ({
                latestQuotedPrices: inquiry.leg.latestQuotedPrices,
                autoQuotedPrices: inquiry.leg.autoQuotedPrices,
                autoQuotePriceState: inquiry.autoQuotePriceState,
                autoQuotePriceError: inquiry.autoQuotePriceError,
                autoQuotePricesGenerationTimestampNanos: inquiry.autoQuotePricesGenerationTimestampNanos,
                autoQuotePricesValidForNanos: inquiry.autoQuotePricesValidForNanos,
                isInquiryDismissed: isDismissed(inquiry.state),
            }),
        );

        return useCreditStateSelector(selectPriceMatrixState);
    },
    useQuoteButtonState: (ticketId: number) => {
        const dispatch = useDispatch<Dispatch<AnyAction>>();

        const quoteOtwIsConfigurable = useMemo(
            () =>
                createCreditSelector(
                    [
                        (state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).venueSpecifiedOnTheWireTimeMillis,
                        (state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).quoteIsAlwaysFirm,
                    ],
                    (quoteIsAlwaysFirm, venueSpecifiedOnTheWireTimeMillis) => {
                        if (venueSpecifiedOnTheWireTimeMillis) {
                            return false;
                        }
                        return !quoteIsAlwaysFirm;
                    },
                ),
            [ticketId],
        );
        const venueSpecifiedOnTheWireTimeMs = useMemo(
            () =>
                createCreditSelector(
                    [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).venueSpecifiedOnTheWireTimeMillis],
                    (venueSpecifiedOnTheWireTimeMillis) => {
                        return venueSpecifiedOnTheWireTimeMillis ? Number(venueSpecifiedOnTheWireTimeMillis) : null;
                    },
                ),
            [ticketId],
        );

        // TODO: do we want to do this with nested selectors, or just do it as one big selector?
        const selectQuoteButtonIsDisabled = useMemo(
            () =>
                createCreditSelector(
                    [
                        // price for price type is not dirty
                        createCreditSelector(
                            [(state) => creditSelectors.ticketPriceState(state, ticketId).isDirty],
                            (priceIsDirty) => !priceIsDirty,
                        ),
                        // has a price value for price type
                        createCreditSelector(
                            [(state) => creditSelectors.ticketPriceState(state, ticketId).value],
                            (priceValue) => priceValue !== null,
                        ),
                        // is AQ pricer state valid, if applicable
                        createCreditSelector(
                            [
                                (state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).autoQuotePriceState,
                                (state) => creditSelectors.ticketState(state, ticketId).selectedDriverCellPosition,
                            ],
                            (autoQuotePriceState, selectedDriverCellPosition) => {
                                return selectedDriverCellPosition?.rowNo === 1 ? autoQuotePriceState === PricerState.Priced : true;
                            },
                        ),
                        // is allowed to quote
                        createCreditSelector(
                            [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).allowedActions],
                            (allowedActions) => allowedActions.includes(AllowedAction.Quote),
                        ),
                    ],
                    // TODO: should we check if negotiation is finished? There are no tests for that
                    (priceIsNotDirty, hasAPriceValue, isAqPricerStateValidIfApplicable, isAllowedToQuote) =>
                        !(priceIsNotDirty && hasAPriceValue && isAqPricerStateValidIfApplicable && isAllowedToQuote),
                ),
            [ticketId],
        );

        const priceType = useCreditStateSelector((state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg.priceType);

        return {
            isDisabled: useCreditStateSelector(selectQuoteButtonIsDisabled),
            quoteOtwIsConfigurable: useCreditStateSelector(quoteOtwIsConfigurable),
            venueSpecifiedOnTheWireTimeMs: useCreditStateSelector(venueSpecifiedOnTheWireTimeMs),
            onDirtyChange: useCallback(
                (isDirty: boolean) => dispatch(credit.actions.setIsDirty({ ticketId, priceType, isDirty })),
                [dispatch, priceType, ticketId],
            ),
        };
    },
    useAcceptButtonState: (ticketId: number) => {
        // TODO: do we want to do this with nested selectors, or just do it as one big selector?
        const selectAcceptButtonIsDisabled = useMemo(
            () =>
                createCreditSelector(
                    [
                        // is allowed to accept
                        createCreditSelector(
                            [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).allowedActions],
                            (allowedActions) => allowedActions.includes(AllowedAction.DealerAccept),
                        ),
                    ],
                    // TODO: should we check if negotiation is finished? There are no tests for that
                    (isAllowedToAccept) => !isAllowedToAccept,
                ),
            [ticketId],
        );

        const selectValueToAccept = useMemo(
            () =>
                createCreditSelector([(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg], (leg) => {
                    if (leg.customerBidPrice) {
                        return leg.customerBidPrice;
                    } else if (leg.customerAskPrice) {
                        return leg.customerAskPrice;
                    } else {
                        return "-";
                    }
                }),
            [ticketId],
        );

        const minPriceIncrement = useMemo(
            () =>
                createCreditSelector([(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).leg], (leg) => {
                    return leg.minPriceIncrement;
                }),
            [ticketId],
        );

        return {
            isDisabled: useCreditStateSelector(selectAcceptButtonIsDisabled),
            valueDealerIsAccepting: useCreditStateSelector(selectValueToAccept),
            minPriceIncrement: useCreditStateSelector(minPriceIncrement),
        };
    },
    useRejectButtonState: (ticketId: number) => {
        // TODO: do we want to do this with nested selectors, or just do it as one big selector?
        const selectRejectButtonIsDisabled = useMemo(
            () =>
                createCreditSelector(
                    [
                        // is allowed to accept
                        createCreditSelector(
                            [(state) => creditSelectors.definitelyInquiryByTicketId(state, ticketId).allowedActions],
                            (allowedActions) => allowedActions.includes(AllowedAction.DealerReject),
                        ),
                    ],
                    // TODO: should we check if negotiation is finished? There are no tests for that
                    (isAllowedToReject) => !isAllowedToReject,
                ),
            [ticketId],
        );

        return {
            isDisabled: useCreditStateSelector(selectRejectButtonIsDisabled),
        };
    },
};
