import React from "react";
import { Box, Icon, IconNames, Text } from "@transficc/components";
import { useDisplayedInquiry } from "../credit-slice/useCreditSliceSelectors";

const UndisclosedDealersColor: Theme.Color = { color: "gray", level: "400" };
const SingleDealerColor: Theme.Color = { color: "success", level: "300" };
const MultipleDealersColor: Theme.Color = { color: "gold", level: "300" };
const A2AColor: Theme.Color = { color: "warning", level: "300" };

export const NumberOfDealers: React.FC = () => {
    const { numberOfDealers, isAllToAll } = useDisplayedInquiry();

    if (isAllToAll) {
        return (
            <Box
                contentDirection="row"
                contentAlignment="center"
                height={"12"}
                data-testid="inquiry-number-of-dealers-container"
                title={"All-to-all"}
            >
                <Icon iconColor={A2AColor} name={IconNames.MultipleUsers} size={"10"} />
                <Box marginL={"3"} />
                <Text textColor={A2AColor} size={"3xl"} weight={"medium"}>
                    A2A
                </Text>
            </Box>
        );
    }

    const color = numberOfDealers === 1 ? SingleDealerColor : MultipleDealersColor;
    const dealerLabel = numberOfDealers === 1 ? "Dealer" : "Dealers";

    const label =
        numberOfDealers && numberOfDealers > 5 ? (
            <Text textColor={color} size={"3xl"} weight={"semibold"} data-testid="inquiry-number-of-dealers">
                {numberOfDealers}
            </Text>
        ) : null;

    const icons =
        numberOfDealers === null ? (
            <Icon iconColor={UndisclosedDealersColor} name={IconNames.UserQuestionMark} size={"8"} />
        ) : (
            Array(Math.min(5, Math.max(1, numberOfDealers)))
                .fill(null)
                .map((ignored, index) => {
                    return <Icon key={index} iconColor={color} name={IconNames.User} size={"8"} />;
                })
        );

    const title = numberOfDealers === null ? "The number of dealers was not disclosed" : `${String(numberOfDealers)} ${dealerLabel}`;

    return (
        <Box
            contentDirection="row"
            contentAlignment="center"
            height={"12"}
            title={title}
            data-testid="inquiry-number-of-dealers-container"
            data-number-of-dealers={numberOfDealers ?? "undisclosed"}
        >
            {icons}
            <Box marginL={"3"} />
            {label}
        </Box>
    );
};
