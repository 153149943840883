import React, { useEffect } from "react";
import { ConnectionStatus } from "../redux/slices/session/ConnectionStatus";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { IncomingMessage } from "./ApplicationProtocolMessages";
import { DisconnectionReason } from "@transficc/websocket";
import { useSessionStateDispatch } from "../redux/slices/session/useSessionStateDispatch";
import { useAccessToken, useIdToken, useUser } from "../redux/slices/session/useSessionSliceSelectors";
import { useIRSStateDispatch } from "@transficc/trader-desktop-irs";
import log from "../logger/globalLogger";
import { useThunkDispatch } from "../redux/hooks/useThunkDispatch";
import { TradingStatus } from "../redux/slices/session/TradingStatus";
import { useCreditStateDispatch } from "@transficc/trader-desktop-credit";
import { useElectronApi, useSessionTransport } from "@transficc/trader-desktop-application-context";
import { loadingComplete } from "../redux/slices/LoadingStatusSlice";
import { MessageBatcher } from "./MessageBatcher";
import { useSessionTransportDispatcherMetrics } from "./useSessionTransportDispatcherMetrics";

interface SessionTransportDispatcherProps {
    messageBatchingMaxBatchSize: number;
    messageBatchingMaxBatchIntervalMs: number;
}

export const SessionTransportDispatcher: React.FC<SessionTransportDispatcherProps> = ({
    messageBatchingMaxBatchSize,
    messageBatchingMaxBatchIntervalMs,
}) => {
    const sessionTransport = useSessionTransport();
    const electronApi = useElectronApi();
    const irsStateDispatch = useIRSStateDispatch();
    const creditStateDispatch = useCreditStateDispatch();
    const sessionStateDispatch = useSessionStateDispatch();
    const accessToken = useAccessToken();
    const idToken = useIdToken();
    const user = useUser();
    const dispatch = useThunkDispatch();
    const { trackMessageForMetric } = useSessionTransportDispatcherMetrics();

    useEffect(() => {
        return sessionTransport.onConnected(() => {
            sessionStateDispatch.setConnectionStatus(ConnectionStatus.CONNECTED);
        });
    }, [dispatch, sessionStateDispatch, sessionTransport]);

    useEffect(() => {
        return sessionTransport.onDisconnected((reason, description) => {
            switch (reason) {
                case DisconnectionReason.NO_REASON:
                    sessionStateDispatch.setConnectionStatus(ConnectionStatus.DISCONNECTED);
                    break;
                case DisconnectionReason.NETWORK_FAILURE:
                    sessionStateDispatch.setConnectionStatus(ConnectionStatus.DISCONNECTED);
                    break;
                case DisconnectionReason.ERROR:
                    log.error("Error received from backend over websocket ", String(description));
                    break;
                case DisconnectionReason.AUTHENTICATION_FAILURE:
                    sessionStateDispatch.logoff();
                    break;
            }
        });
    }, [sessionStateDispatch, sessionTransport]);

    useEffect(() => {
        const processMessage = (message: string): void => {
            const jsonMessage = JSON.parse(message) as IncomingMessage;

            const msgType = jsonMessage.msgType;

            trackMessageForMetric(jsonMessage);

            switch (msgType) {
                case "InquirySnapshotComplete":
                    dispatch(loadingComplete());
                    break;
                case "SubscriptionResponse":
                    break; // no-op
                case "ValidationFailure":
                    throw new Error(
                        `The backend has notified us that we sent a message it cannot handle as we received a msg of ${
                            jsonMessage.msgType
                        } content: ${JSON.stringify(jsonMessage)}`,
                    );
                case "Inquiry": {
                    const assetClass = jsonMessage.assetClass;
                    if (!assetClass) {
                        throw new Error("AssetClass is undefined");
                    }
                    switch (assetClass) {
                        case TraderDesktopProtocol.AssetClass.Irs:
                            irsStateDispatch.upsert({ inquiry: jsonMessage, userId: user?.userId ?? Number.MIN_VALUE });
                            break;
                        case TraderDesktopProtocol.AssetClass.Credit:
                            creditStateDispatch.upsert({ inquiry: jsonMessage, userId: user?.userId ?? Number.MIN_VALUE });
                            break;
                        default:
                            assetClass satisfies never;
                            throw new Error(`Unknown inquiry category ${String(assetClass)}`);
                    }
                    break;
                }
                case "OnDemandPrices":
                    irsStateDispatch.updateOnDemandPrices(jsonMessage);
                    creditStateDispatch.updateOnDemandPrices({ prices: jsonMessage });
                    break;
                case "PriceBasis":
                    irsStateDispatch.updatePriceBasis(jsonMessage);
                    break;
                case "TraderDesktopSettings":
                    sessionStateDispatch.setUserGroups(jsonMessage.userGroups);
                    switch (jsonMessage.tradingStatus) {
                        case TraderDesktopProtocol.TradingStatus.Off:
                            sessionStateDispatch.setTradingStatus(TradingStatus.OFF);
                            sessionStateDispatch.setServerTradingStatus(TradingStatus.OFF);
                            break;
                        case TraderDesktopProtocol.TradingStatus.On:
                            sessionStateDispatch.setTradingStatus(TradingStatus.ON);
                            sessionStateDispatch.setServerTradingStatus(TradingStatus.ON);
                            break;
                        default:
                            throw new Error(`Unknown trading status ${String(jsonMessage.tradingStatus)}`);
                    }
                    break;
                default:
                    throw new Error(`Unhandled msgType ${String(msgType)}`);
            }
        };

        const messageBatcher = new MessageBatcher(messageBatchingMaxBatchSize, messageBatchingMaxBatchIntervalMs, processMessage);
        const clearOnMessageHandler = sessionTransport.onMessage((msg) => messageBatcher.onMessage(msg));

        return () => {
            clearOnMessageHandler();
            messageBatcher.cleanup();
        };
    }, [
        irsStateDispatch,
        sessionStateDispatch,
        sessionTransport,
        user?.userId,
        creditStateDispatch,
        messageBatchingMaxBatchSize,
        messageBatchingMaxBatchIntervalMs,
        dispatch,
        trackMessageForMetric,
    ]);

    useEffect(() => {
        if (!accessToken) {
            return;
        }

        if (!idToken) {
            return;
        }

        // We ALWAYS provide a value for electronApi
        // This used to have an if statement
        // but it doesn't really make sense anymore
        // "Electron API" is probably a bad name now
        electronApi.appInstanceId((appInstanceId) => sessionTransport.open(accessToken, idToken, appInstanceId));

        return () => sessionTransport.close();
    }, [sessionTransport, accessToken, idToken, electronApi]);

    return null;
};
