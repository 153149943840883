export const gray: Theme.ColorShades = {
    "100": "#f3f4f6",
    "200": "#e5e7eb",
    "300": "#d1d5db",
    "400": "#9ca3af",
    "500": "#6b7280",
    "600": "#4b5563",
    "700": "#374151",
    "800": "#1f2937",
    "900": "#111827",
};

export const cyan: Theme.ColorShades = {
    "100": "#D3F9EF",
    "200": "#AAF3E5",
    "300": "#78DBD1",
    "400": "#50B7B6",
    "500": "#228188",
    "600": "#186774",
    "700": "#114E61",
    "800": "#0A394E",
    "900": "#062941",
};

export const lime: Theme.ColorShades = {
    "100": "#F4FCE4",
    "200": "#E7F9CA",
    "300": "#D1EEAA",
    "400": "#B8DE8E",
    "500": "#96C869",
    "600": "#74AC4C",
    "700": "#569034",
    "800": "#3B7421",
    "900": "#286014",
};

export const rose: Theme.ColorShades = {
    "100": "#ffe4e6",
    "200": "#fecdd3",
    "300": "#fda4af",
    "400": "#fb7185",
    "500": "#f43f5e",
    "600": "#e11d48",
    "700": "#be123c",
    "800": "#9f1239",
    "900": "#881337",
};

export const gold: Theme.ColorShades = {
    "100": "#fef5db",
    "200": "#fde9b9",
    "300": "#fad995",
    "400": "#f6c779",
    "500": "#f0ad4e",
    "600": "#ce8a39",
    "700": "#ac6a27",
    "800": "#8b4d18",
    "900": "#73380e",
};

export const success: Theme.ColorShades = {
    "100": "#D6F8CE",
    "200": "#A8F29F",
    "300": "#6BD96A",
    "400": "#40B44A",
    "500": "#138227",
    "600": "#0D6F28",
    "700": "#095D28",
    "800": "#064B26",
    "900": "#033E24",
};

export const info: Theme.ColorShades = {
    "100": "#C7F9F6",
    "200": "#92F2F4",
    "300": "#59D2DE",
    "400": "#2FA7BE",
    "500": "#007193",
    "600": "#00587E",
    "700": "#004269",
    "800": "#002F55",
    "900": "#002146",
};

export const warning: Theme.ColorShades = {
    "100": "#FBF8CB",
    "200": "#F7F199",
    "300": "#E7DF64",
    "400": "#D0C53C",
    "500": "#B2A40A",
    "600": "#998B07",
    "700": "#807305",
    "800": "#675C03",
    "900": "#554B01",
};

export const danger: Theme.ColorShades = {
    "100": "#F9DACD",
    "200": "#F4AF9E",
    "300": "#E07769",
    "400": "#C14441",
    "500": "#99121B",
    "600": "#830D1F",
    "700": "#6E0921",
    "800": "#580521",
    "900": "#490321",
};
