import { useCallback, useMemo } from "react";
import * as IRSDomain from "../irs-domain";
import { DisplayState, IRSInquiry, mapPricerStateToDisplayState, PricerState, SelectedDriver } from "../irs-domain";
import { DecimalNumber, EpochClock } from "@transficc/infrastructure";
import { calculateNotional } from "../irs-domain/compression-derivations";
import { selectedDriverHasValueForPackage } from "../irs-domain/price-source";
import { priceIsStale } from "../irs-domain/stale-prices";
import { useIRSReduxState } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";

interface IRSCompressionSideView {
    packageFeeValue: DecimalNumber | null;
    updatePackageFee: (packageFee: DecimalNumber) => void;
    selectDriver: (selectedDriver: SelectedDriver) => void;
    clearDriverValue: () => void;
    selectedDriver: SelectedDriver | null;
    spreadValue: DecimalNumber | null;
    updateSpreadValue: (spreadValue: DecimalNumber) => void;
    spreadInputDisabled: boolean;
    autoQuotedPricesRow: CompressionPriceMatrixRow;
    venuePricesRow: CompressionPriceMatrixRow;
}

interface IRSCompressionView {
    market: IRSCompressionSideView;
    opposite: IRSCompressionSideView;
    shouldShowPackageFeeAsStale: boolean;

    orderedLegs: IRSDomain.IRSLeg<"compression">[];
    notional: string;
    delta: string | null;
    latestQuotedPricesRow: CompressionPriceMatrixRow;

    isMixedCcp: boolean;
    isMixedIndices: boolean;

    packageFeeDisplayState: DisplayState;
    autoQuoteDisplayState: DisplayState;
}

type CompressionPriceMatrixRow = [string | null, string | null, string | null];
const hasDifferentValues: (valuesArray: (string | null)[]) => boolean = (valuesArray) => {
    const valuesSet = new Set<string | null>(valuesArray);
    return valuesSet.size > 1;
};

export function useIRSCompression(inquiry: IRSInquiry<"compression">, clock: EpochClock, isInquiryFinished: boolean): IRSCompressionView {
    const state = useIRSReduxState();
    if (state.type !== "package-based") {
        throw new Error("Tried to use non-package-based state in package-based inquiry");
    }

    const { selectDriver, deselectDriver, updatePackageValue, updatePackageSpreadValue } = useIRSStateDispatch();

    const orderedLegs = useMemo(
        () =>
            [...inquiry.legs].sort((a, b) => {
                if (a.terminationDate === b.terminationDate) {
                    return a.position - b.position;
                } else {
                    return (a.terminationDate ?? 0) - (b.terminationDate ?? 0);
                }
            }),
        [inquiry.legs],
    );

    const latestQuotedPricesRow: CompressionPriceMatrixRow = state.market.latestQuotedPrices; // TODO:

    const selectedDriver = state.market.selectedDriver;

    const hasMarketDriverValue = useMemo(() => {
        return selectedDriverHasValueForPackage(inquiry, state.market.selectedDriver);
    }, [inquiry, state.market.selectedDriver]);
    const hasOppositeDriverValue = useMemo(() => {
        return selectedDriverHasValueForPackage(inquiry, state.opposite.selectedDriver);
    }, [inquiry, state.opposite.selectedDriver]);

    const notional = useMemo(() => calculateNotional(inquiry), [inquiry]);
    const delta = useMemo(() => inquiry.packageDelta, [inquiry]);

    const marketSpreadInputDisabled = state.market.selectedDriver === null || !hasMarketDriverValue || isInquiryFinished;
    const oppositeSpreadInputDisabled = state.opposite.selectedDriver === null || !hasOppositeDriverValue || isInquiryFinished;
    const isAutoQuoteDriverSelected = selectedDriver?.rowNo === 1;
    const autoQuoteDisplayState: DisplayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);
    const packageFeeDisplayState: DisplayState = isAutoQuoteDriverSelected ? autoQuoteDisplayState : DisplayState.Valid;
    const isMixedCcp = useMemo(() => {
        return hasDifferentValues(inquiry.legs.map((leg) => leg.clearingHouse));
    }, [inquiry.legs]);

    const isMixedIndices = useMemo(() => inquiry.hasMixedIndices ?? true, [inquiry]);

    const isOnDemandPricerPriceStale: boolean = priceIsStale(inquiry.autoQuotePriceValidity, clock);
    const isOnDemandPricerActive: boolean = inquiry.autoQuotePriceState === PricerState.Priced;

    return {
        market: {
            packageFeeValue: state.market.customerRateValue,
            updatePackageFee: useCallback(
                (updatedPackageFeeValue: DecimalNumber) => {
                    updatePackageValue(updatedPackageFeeValue, "Market");
                },
                [updatePackageValue],
            ),
            selectDriver: useCallback(
                (driver: SelectedDriver) => {
                    selectDriver("Market", driver);
                },
                [selectDriver],
            ),
            clearDriverValue: useCallback(() => {
                deselectDriver("Market");
            }, [deselectDriver]),
            selectedDriver: state.market.selectedDriver,

            spreadValue: state.market.spreadValue,
            updateSpreadValue: useCallback(
                (updatedSpreadValue: DecimalNumber) => {
                    updatePackageSpreadValue(updatedSpreadValue, "Market");
                },
                [updatePackageSpreadValue],
            ),
            spreadInputDisabled: marketSpreadInputDisabled,
            venuePricesRow: state.market.venuePrices,
            autoQuotedPricesRow: state.market.autoQuotePrices,
        },
        opposite: {
            packageFeeValue: state.opposite.customerRateValue,
            updatePackageFee: useCallback(
                (updatedPackageFeeValue: DecimalNumber) => {
                    updatePackageValue(updatedPackageFeeValue, "Opposite");
                },
                [updatePackageValue],
            ),
            selectDriver: useCallback(
                (driver: SelectedDriver) => {
                    selectDriver("Opposite", driver);
                },
                [selectDriver],
            ),
            clearDriverValue: useCallback(() => {
                deselectDriver("Opposite");
            }, [deselectDriver]),
            selectedDriver: state.opposite.selectedDriver,
            spreadValue: state.opposite.spreadValue,
            updateSpreadValue: useCallback(
                (updatedSpreadValue: DecimalNumber) => {
                    updatePackageSpreadValue(updatedSpreadValue, "Opposite");
                },
                [updatePackageSpreadValue],
            ),
            spreadInputDisabled: oppositeSpreadInputDisabled,
            venuePricesRow: state.opposite.venuePrices,
            autoQuotedPricesRow: state.opposite.autoQuotePrices,
        },
        shouldShowPackageFeeAsStale: isAutoQuoteDriverSelected && isOnDemandPricerPriceStale && isOnDemandPricerActive,
        packageFeeDisplayState,
        autoQuoteDisplayState,
        orderedLegs,

        notional,
        delta,
        latestQuotedPricesRow,

        isMixedCcp,
        isMixedIndices,
    };
}
