import React, { useMemo } from "react";
import { formatQuantityToThreeDp, formatRate, IRSLeg } from "../irs-domain";
import styled from "styled-components";
import { colorForSide } from "../irs-domain/colors";
import { Box, formatIntDateAsString, formatWithCommas, Text, ThemeUtils } from "@transficc/components";

const Table = styled.table`
    color: white;
    border-collapse: collapse;
    border-left: 1px solid white;

    & thead {
        position: sticky;
        top: 0;
        box-shadow: inset 0 1px 0 white, inset 0 -1px 0 white;
        background-color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "cyan", level: "900" })};
    }

    & th {
        color: white;
        border-left: 1px solid white;
        padding: ${(props) => props.theme.spacing["2"]};
    }

    & td {
        color: white;
        border: 1px solid white;
        padding: ${(props) => props.theme.spacing["1"]};
        padding-left: ${(props) => props.theme.spacing["2"]};
        padding-right: ${(props) => props.theme.spacing["2"]};
        font-size: ${(props) => props.theme.font.sizes["sm"]};
    }
`;

const VariableWidthTableCell = styled.td`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const TableContainer = styled(Box)`
    overflow-y: scroll; /* make the table scrollable if height is more than 200 px  */
    max-height: 36rem;
    border: 1px solid white;
    width: 58rem;
`;

const makeLegRow: React.FC<{ leg: IRSLeg<"compression">; index: number }> = ({ leg, index }) => {
    const sideText = leg.side === "sell" ? "RCV" : "PAY";
    const quantity = formatQuantityToThreeDp(leg.quantity);
    const rate = formatRate(leg.fixedRate, leg.floatingRateSpread);
    return (
        <tr key={index} row-id={`row-${index}`}>
            <Box as={"td"} backgroundColor={colorForSide(leg.side)}>
                <Text textColor={{ color: "black" }} data-testid={`row:${index}-side`} size={"sm"}>
                    {sideText}
                </Text>
            </Box>
            <VariableWidthTableCell title={leg.instrumentName} data-testid={`row:${index}-description`}>
                {leg.instrumentName}
            </VariableWidthTableCell>
            <td data-testid={`row:${index}-qty`}>{formatWithCommas(quantity)}</td>
            <td data-testid={`row:${index}-effectiveDate`}>{formatIntDateAsString(leg.effectiveDate)}</td>
            <td data-testid={`row:${index}-endDate`}>{formatIntDateAsString(leg.terminationDate)}</td>
            <td data-testid={`row:${index}-rate`}>{rate}</td>
            <td data-testid={`row:${index}-ccp`}>{leg.clearingHouse}</td>
        </tr>
    );
};

export interface IRSCompressionTableProps {
    model: {
        legs: IRSLeg<"compression">[];
    };
}

export const IrsCompressionTable: React.FC<IRSCompressionTableProps> = ({ model: { legs } }) => {
    const tableBodyComponents = useMemo(() => legs.map((leg, index) => makeLegRow({ leg, index })), [legs]);
    return (
        <TableContainer width="full">
            <Table>
                <thead>
                    <tr>
                        <th>Side</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Rate</th>
                        <th>CCP</th>
                    </tr>
                </thead>
                <tbody>{tableBodyComponents}</tbody>
            </Table>
        </TableContainer>
    );
};
