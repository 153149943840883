import React, { ErrorInfo } from "react";
import { IRSActiveInquiries, IRSActiveInquiriesProps } from "../irs-active-inquiries/irs-active-inquiries";
import { CouldNotRenderInquiry, NoInquiriesAvailable, NoInquirySelected } from "../irs-inquiry/irs-inquiry";
import styled from "styled-components";
import { IrsCurve } from "../irs-curve/irs-curve";
import { IrsCompression } from "../irs-compression/irs-compression";
import { IrsOutright } from "../irs-outright/irs-outright";
import { OpenCustomerAnalytics } from "../irs-inquiry-header/irs-inquiry-header";
import { Box, ErrorBoundary, Text } from "@transficc/components";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { useActiveIRSInquiryTicketIDs, useMaybeSelectedInquiry, useMaybeSelectedInquiryTicketId } from "../irs-slice/irs-selectors";

const DockContainer = styled(Box)`
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;

const SelectedIrsInquiryContainer = styled(Box)`
    width: 100%;
    overflow: auto;
`;

export interface IRSDockProps {
    publishAction: ActionPublisher.ActionPublisher;
    volumeIsOn: IRSActiveInquiriesProps["volumeIsOn"];
    onVolumeToggle: IRSActiveInquiriesProps["onVolumeToggle"];
    connectionStatus: IRSActiveInquiriesProps["connectionStatus"];
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
    unexpectedErrorHandler: (error: Error, errorInfo: ErrorInfo) => unknown;
    openRiskViewWindow: (url: string) => void;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

const SelectedInquiry: React.FC<{
    publishAction: ActionPublisher.ActionPublisher;
    openRiskViewWindow: (url: string) => void;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}> = ({ publishAction, openRiskViewWindow, openCustomerAnalyticsWindow }) => {
    const inquiry = useMaybeSelectedInquiry();

    if (!inquiry) {
        return <NoInquirySelected />;
    }

    // We set the key on any inquiry so that it always unmounts and remounts when switching between active inquiries
    if (inquiry.packageType === "outright") {
        return (
            <IrsOutright
                key={inquiry.ticketId}
                publishAction={publishAction}
                openRiskViewWindow={openRiskViewWindow}
                openCustomerAnalyticsWindow={openCustomerAnalyticsWindow}
            />
        );
    } else if (inquiry.packageType === "curve") {
        return (
            <IrsCurve
                key={inquiry.ticketId}
                publishAction={publishAction}
                openRiskViewWindow={openRiskViewWindow}
                openCustomerAnalyticsWindow={openCustomerAnalyticsWindow}
            />
        );
    } else if (inquiry.packageType === "compression") {
        return (
            <IrsCompression
                key={inquiry.ticketId}
                publishAction={publishAction}
                openRiskViewWindow={openRiskViewWindow}
                openCustomerAnalyticsWindow={openCustomerAnalyticsWindow}
            />
        );
    } else {
        return <CouldNotRenderInquiry reason={"not knowing the inquiry type"} />;
    }
};

export const IRSDock = React.forwardRef<HTMLDivElement, IRSDockProps>(
    (
        {
            publishAction,
            connectionStatus,
            volumeIsOn,
            onVolumeToggle,
            onTradingStatusChanged,
            tradingStatus,
            serverTradingStatus,
            unexpectedErrorHandler,
            openRiskViewWindow,
            openCustomerAnalyticsWindow,
        },
        containerRef,
    ) => {
        const activeInquiryTicketIDs = useActiveIRSInquiryTicketIDs();
        const selectedInquiryTicketId = useMaybeSelectedInquiryTicketId();

        return (
            <DockContainer ref={containerRef} data-testid="dock-container">
                <SelectedIrsInquiryContainer data-testid="dock-inquiry-panel-container">
                    <ErrorBoundary
                        key={selectedInquiryTicketId}
                        renderError={() => (
                            <Box>
                                <CouldNotRenderInquiry reason="unexpected error" />
                            </Box>
                        )}
                        reportError={unexpectedErrorHandler}
                    >
                        {activeInquiryTicketIDs.length > 0 ? (
                            <SelectedInquiry
                                publishAction={publishAction}
                                openRiskViewWindow={openRiskViewWindow}
                                openCustomerAnalyticsWindow={openCustomerAnalyticsWindow}
                            />
                        ) : (
                            <NoInquiriesAvailable />
                        )}
                    </ErrorBoundary>
                </SelectedIrsInquiryContainer>
                <ErrorBoundary
                    renderError={() => (
                        <Box>
                            <Box rounded backgroundColor={{ color: "danger", level: "600" }} padding={"4"}>
                                <Text textColor={{ color: "white" }}>Error: Could not render active inquiries due to unexpected error</Text>
                            </Box>
                        </Box>
                    )}
                    reportError={unexpectedErrorHandler}
                >
                    <IRSActiveInquiries
                        volumeIsOn={volumeIsOn}
                        onVolumeToggle={onVolumeToggle}
                        connectionStatus={connectionStatus}
                        onTradingStatusChanged={onTradingStatusChanged}
                        tradingStatus={tradingStatus}
                        serverTradingStatus={serverTradingStatus}
                    />
                </ErrorBoundary>
            </DockContainer>
        );
    },
);
