import { EpochClock } from "@transficc/infrastructure";
import { useCountdown } from "../../hooks";

const HIGHLIGHT_TIMER_ALMOST_EXPIRED_TIME_MS = 11000;

export function useTimerProgress({
    startTimeEpochMs,
    endTimeEpochMs,
    updateIntervalMs,
    clock,
}: {
    startTimeEpochMs: number;
    endTimeEpochMs: number;
    updateIntervalMs: number;
    clock: EpochClock;
}): { showWarningColour: boolean; progress: number; timerTextDisplayValue: string } {
    const { hours, minutes, seconds } = useCountdown({
        targetDateEpochMs: endTimeEpochMs,
        updateIntervalMs,
        clock,
    });

    const totalDurationOfCountdownMs = endTimeEpochMs - startTimeEpochMs;
    const elapsedTimeMs = clock.getCurrentTimeMs() - startTimeEpochMs;
    const remainingTimeMs = totalDurationOfCountdownMs - elapsedTimeMs;
    const showWarningColour = remainingTimeMs < HIGHLIGHT_TIMER_ALMOST_EXPIRED_TIME_MS;
    const progress = Math.max(0, Math.min(1, elapsedTimeMs / totalDurationOfCountdownMs));
    const timerTextDisplayHours = hours !== 0 ? `${hours}:` : "";
    const timerTextDisplayMinutes = hours !== 0 ? `${minutes.toFixed(0).padStart(2, "0")}:` : `${minutes}:`;
    const timerTextDisplayValue = `${timerTextDisplayHours}${timerTextDisplayMinutes}${seconds.toFixed(0).padStart(2, "0")}`;

    return { showWarningColour, progress, timerTextDisplayValue };
}
