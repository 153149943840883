import { Box, Text } from "@transficc/components";
import React from "react";

export interface NumberOfDealersProps {
    numberOfDealers: number | null;
}

const AvailableDealersColor: Theme.Color = { color: "warning", level: "200" };
const NoDealersColor: Theme.Color = { color: "gray", level: "400" };

export const NumberOfDealers: React.FC<NumberOfDealersProps> = ({ numberOfDealers }) => {
    if (numberOfDealers === null) {
        return null;
    }

    const dealerLabel = numberOfDealers === 1 ? "Dealer" : "Dealers";
    return (
        <Box rounded contentDirection="row" contentAlignment="center">
            <Box marginL="2" />
            <Text
                textColor={numberOfDealers >= 1 ? AvailableDealersColor : NoDealersColor}
                size="xl"
                data-testid="inquiry-number-of-dealers"
            >
                {numberOfDealers} {dealerLabel}
            </Text>
        </Box>
    );
};
