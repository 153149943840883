import React from "react";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";
import { Box } from "../box";

export interface ToggleProps {
    width: Theme.SpacingLevels;
    height: Theme.SpacingLevels;
    toggledOn: boolean;
    serverToggledOn: boolean;
    onToggle: (isToggledOn: boolean) => void;
    toggleBackgroundColor?: string;
    toggleForegroundColor?: string;
    rounded?: boolean;
    "data-testid"?: string;
}

const ToggleBackground = styled(Box)<{
    width: Theme.SpacingLevels;
    height: Theme.SpacingLevels;
    toggledOn: boolean;
    toggleBackgroundColor?: string;
}>`
    cursor: pointer;
    width: ${(props) => props.theme.spacing[props.width]};
    height: ${(props) => props.theme.spacing[props.height]};
    background-color: ${(props) =>
        props.toggleBackgroundColor ||
        ThemeUtils.colorSelector(props.theme, { color: props.toggledOn ? "success" : "danger", level: "700" })};
    transition: background-color 0.2s linear;
`;

const ToggleForeground = styled(Box)<{ toggledOn: boolean; toggleForegroundColor?: string }>`
    background-color: ${(props) =>
        props.toggleForegroundColor ||
        ThemeUtils.colorSelector(props.theme, { color: props.toggledOn ? "success" : "danger", level: "200" })};
    height: 100%;
    width: 50%;
    transition: all 0.2s linear;
    margin-left: ${(props) => (props.toggledOn ? "50%" : "0")};
`;

export const Toggle: React.FC<ToggleProps> = ({
    width,
    height,
    toggledOn,
    serverToggledOn,
    rounded = true,
    onToggle,
    toggleBackgroundColor,
    toggleForegroundColor,
    ...props
}) => {
    return (
        <Box contentDirection="row" contentAlignment="center">
            <ToggleBackground
                data-testid={props["data-testid"] ?? "toggle-button"}
                data-status-visible={toggledOn ? "on" : "off"}
                data-status-server={serverToggledOn ? "on" : "off"}
                width={width}
                height={height}
                toggledOn={toggledOn}
                rounded={rounded}
                onClick={() => {
                    onToggle(toggledOn);
                }}
                toggleBackgroundColor={toggleBackgroundColor}
            >
                <ToggleForeground rounded={rounded} toggledOn={toggledOn} toggleForegroundColor={toggleForegroundColor} />
            </ToggleBackground>
        </Box>
    );
};
