import React, { useCallback, useMemo } from "react";
import {
    clickWasOnSelectedDriver,
    dataMatrixConfig,
    DataMatrixContainer,
    getDataMatrixContent,
    PriceDriverCallbacks,
    PriceDriverPrices,
} from "./irs-price-driver";
import { DisplayState, PricerState, PriceValidity, SelectedDriver } from "../irs-domain";
import { DataMatrix, DataMatrixCellPosition, DataMatrixSelectedCell, Text } from "@transficc/components";
import { useClock } from "@transficc/trader-desktop-application-context";

interface IrsRfqPriceDriverModel {
    autoQuotePricerState: PricerState | null;
    autoQuoteDisplayState: DisplayState;
    autoQuoteErrorMessage: string | null;
    disabled: boolean;
    prices: PriceDriverPrices;
    selectedDriver: SelectedDriver | null;
    autoQuotePriceValidity: PriceValidity | null;
    cellSelectionColor?: Theme.Color;
}

interface IrsRfqPriceDriverProps {
    model: IrsRfqPriceDriverModel;
    callbacks: PriceDriverCallbacks;
}

export const IrsRfqPriceDriver: React.FC<IrsRfqPriceDriverProps> = ({ model, callbacks }) => {
    const dataMatrixSelectedCell: DataMatrixCellPosition | null = useMemo(
        () => (model.selectedDriver ? { rowNo: model.selectedDriver.rowNo, colNo: model.selectedDriver.columnNo } : null),
        [model.selectedDriver],
    );
    const clock = useClock();

    const dataMatrixSelectedCells: DataMatrixSelectedCell[] = dataMatrixSelectedCell
        ? [
              {
                  pos: dataMatrixSelectedCell,
                  config: {
                      cellSelectionBorderWidth: "1",
                      cellSelectionColor: model.cellSelectionColor ?? { color: "success", level: "300" },
                      cellDisabledSelectionColor: { color: "gray", level: "400" },
                  },
              },
          ]
        : [];

    const dataMatrixContent = getDataMatrixContent(model, clock);

    const onClickDataMatrixCell = useCallback(
        (clickedCell: DataMatrixCellPosition) => {
            if (clickWasOnSelectedDriver(clickedCell, dataMatrixSelectedCell)) {
                callbacks.clearDriver();
            } else {
                callbacks.selectDriver({ rowNo: clickedCell.rowNo, columnNo: clickedCell.colNo });
            }
        },
        [callbacks, dataMatrixSelectedCell],
    );

    return (
        <DataMatrixContainer>
            <Text srOnly>Price Matrix</Text>
            <DataMatrix
                config={dataMatrixConfig}
                data={dataMatrixContent}
                selectedCells={dataMatrixSelectedCells}
                onClickCell={onClickDataMatrixCell}
                disabledSelection={model.disabled}
            />
        </DataMatrixContainer>
    );
};
