import { Navigate, NavigateFunction, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { Location } from "history";
import OAuthErrorPanel from "./OAuthErrorPanel";
import { SessionStateDispatch, useSessionStateDispatch } from "./redux/slices/session/useSessionStateDispatch";
import { useAccessToken, useIsLoggedIn } from "./redux/slices/session/useSessionSliceSelectors";
import { useEnvironmentConfig, useLogger } from "@transficc/trader-desktop-application-context";

import HomePanel from "./HomePanel";
import IRSTicketDock from "./IrsTicketDock";
import HistoricalBlotter from "./HistoricalBlotter";
import CreditTicketDock from "./CreditTicketDock";
import { RiskViewStaticExample } from "./risk-view-static-example/risk-view-static-example";
import { CustomerAnalyticsStaticExample } from "./customer-analytics-static-example/customer-analytics-static-example";
import { SessionStorageKeys } from "packages/trader-desktop/local-storage/src/SessionStorageKeys";
import { removeItemFromSessionStorage, setItemInSessionStorage } from "@transficc/trader-desktop-local-storage";
import { getItemFromSessionStorage } from "packages/trader-desktop/local-storage/src/session-storage";

export const RECOMMENDED_CODE_VERIFIER_LENGTH = 96;
const PKCE_CHARSET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";

interface TokenResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    id_token: string;
}

// Not for any security purposes, only for profile picture
export interface IdToken {
    aud: string;
    exp: number;
    iat: number;
    iss: string;
    sub: string | undefined;
    user_id: number | undefined;
}

export interface AccessToken {
    tenantId: number;
    userId: number;
    iat: number;
    exp: number;
    roles: string[];
    userGroups: number[];
}

interface TokenErrorResponse {
    error: string;
    error_description: string;
}

const redirectToIdentityGateway = (
    identityProviderGatewayUrl: string,
    encodedCodeChallenge: string,
    state: string,
    redirectUri: string,
    customerId: string | (string | null)[] | null,
): void => {
    window.location.href = queryString.stringifyUrl(
        {
            url: identityProviderGatewayUrl + "/api/oidc/authorize",
            query: {
                code_challenge: encodedCodeChallenge,
                code_challenge_method: "S256",
                state: state,
                client_id: "transficc",
                redirect_uri: redirectUri,
                customer_id: customerId,
                response_type: "code",
                response_mode: "query",
            },
        },
        { encode: true },
    );
};

const initiateOAuthFlow = async (identityProviderGatewayUrl: string, customerId: string | null | (string | null)[]): Promise<void> => {
    const output = new Uint32Array(RECOMMENDED_CODE_VERIFIER_LENGTH);
    crypto.getRandomValues(output);
    const codeVerifier = Array.from(output)
        .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
        .join("");
    const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    setItemInSessionStorage(SessionStorageKeys.CODE_VERIFIER, codeVerifier);
    setItemInSessionStorage(SessionStorageKeys.REDIRECT_URI, redirectUri);

    const hasher = new Sha256();
    hasher.update(codeVerifier);
    const hash = await hasher.digest();

    let codeChallenge = "";
    const hashLength = hash.byteLength;

    const byteToNumber = (i: number): number => {
        const hashChar: number | undefined = hash[i];
        if (hashChar === undefined) {
            throw new Error("Index out of bounds");
        }
        return hashChar;
    };

    for (let i = 0; i < hashLength; i++) {
        const hashChar = byteToNumber(i);
        codeChallenge += String.fromCharCode(hashChar);
    }
    crypto.getRandomValues(output);
    const state = Array.from(output)
        .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
        .join("");

    const encodeToBase64UrlEncoding = (): string => {
        let encodedCodeChallenge = btoa(codeChallenge);
        // https://base64url.com
        encodedCodeChallenge = encodedCodeChallenge.split("=")[0] ?? ""; // Remove padding '='s
        encodedCodeChallenge = encodedCodeChallenge.replace(/\+/g, "-"); // 62nd char of encoding
        return encodedCodeChallenge.replace(/\//g, "_"); // 63rd char of encoding
    };

    const encodedCodeChallenge = encodeToBase64UrlEncoding();

    redirectToIdentityGateway(identityProviderGatewayUrl, encodedCodeChallenge, state, redirectUri, customerId);
};

const exchangeAuthCodeForToken = async (
    code: string,
    identityProviderGatewayUrl: string,
    navigate: NavigateFunction,
    sessionStateDispatch: SessionStateDispatch,
    location: Location,
    setOAuthErrorMessage: (value: ((prevState: string) => string) | string) => void,
): Promise<void> => {
    const formData = new URLSearchParams();
    formData.append("grant_type", "authorization_code");
    formData.append("client_id", "transficc");
    formData.append("code", code);
    const codeVerifier = getItemFromSessionStorage(SessionStorageKeys.CODE_VERIFIER);
    removeItemFromSessionStorage(SessionStorageKeys.CODE_VERIFIER);
    if (codeVerifier) {
        formData.append(SessionStorageKeys.CODE_VERIFIER, codeVerifier);
    }
    const redirectUri = getItemFromSessionStorage(SessionStorageKeys.REDIRECT_URI);
    removeItemFromSessionStorage(SessionStorageKeys.REDIRECT_URI);
    if (redirectUri) {
        formData.append(SessionStorageKeys.REDIRECT_URI, redirectUri);
    }
    const response = await fetch(identityProviderGatewayUrl + "/api/oidc/token", {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    if (response.status === 200) {
        const data = (await response.json()) as TokenResponse;

        const accessToken = data.access_token;
        const idToken = data.id_token;
        sessionStateDispatch.setAccessToken(accessToken);
        sessionStateDispatch.setIdToken(idToken);
        navigate(location.pathname, { replace: true });
    } else {
        const data = (await response.json()) as TokenErrorResponse;
        let error = data.error;
        if (data.error_description) {
            error = data.error_description;
        }
        setOAuthErrorMessage(error);
    }
};

const ApplicationRouter: React.FC = () => {
    const environmentConfig = useEnvironmentConfig();
    const logger = useLogger();
    const sessionStateDispatch = useSessionStateDispatch();
    const hasAccessToken = useIsLoggedIn();
    const location = useLocation();
    const navigate = useNavigate();
    const [oAuthErrorMessage, setOAuthErrorMessage] = useState("");
    const identityProviderGatewayUrl = environmentConfig.identityProviderGatewayUrl;
    const hasAccessTokenRequestInFlight = useRef(false);

    useEffect(() => {
        if (hasAccessToken || hasAccessTokenRequestInFlight.current) {
            return;
        }
        if (identityProviderGatewayUrl == null) {
            throw new Error("Failed to redirect you to the login service.");
        }
        const query = queryString.parse(location.search);

        const oauthError = query["error"];
        const oauthErrorDescription = query["error_description"];
        const authorizationCode = query["code"];
        const customerId = query["customer_id"] ?? null;

        if (oauthError && typeof oauthError === "string") {
            let error = oauthError;
            if (oauthErrorDescription && typeof oauthErrorDescription === "string") {
                error = oauthErrorDescription;
            }
            setOAuthErrorMessage(error);
        } else if (authorizationCode && !Array.isArray(authorizationCode)) {
            hasAccessTokenRequestInFlight.current = true;
            navigate(location.pathname, { replace: true });
            exchangeAuthCodeForToken(
                authorizationCode,
                identityProviderGatewayUrl,
                navigate,
                sessionStateDispatch,
                location,
                setOAuthErrorMessage,
            )
                .finally(() => (hasAccessTokenRequestInFlight.current = false))
                .catch((ignored: Error) => {
                    // throw new Error('Failed to request access token.');
                    throw ignored;
                });
        } else {
            initiateOAuthFlow(identityProviderGatewayUrl, customerId).catch((error) => {
                logger.error("Failed to redirect you to the login service.", String(error));
                throw new Error("Failed to redirect you to the login service.");
            });
        }
    }, [sessionStateDispatch, hasAccessToken, identityProviderGatewayUrl, location, navigate, logger]);

    const [searchParams] = useSearchParams();

    const accessToken = useAccessToken();

    return (
        <Routes>
            {accessToken != null ? (
                <>
                    <Route path="/" element={<HomePanel />} />
                    <Route path="/popup/dock/irs-ticket" element={<IRSTicketDock />} />
                    <Route path="/popup/dock/credit-ticket" element={<CreditTicketDock />} />
                    <Route path="/popup/historical-blotter" element={<HistoricalBlotter />} />

                    <Route
                        path="/popup/risk-view-static-example"
                        element={<RiskViewStaticExample numberOfItems={searchParams.get("numberOfItems")} />}
                    />
                    <Route
                        path="/popup/customer-analytics"
                        element={
                            <CustomerAnalyticsStaticExample
                                isCredit={searchParams.get("isCredit") === "true"}
                                customerFirm={searchParams.get("customerFirm") ?? "TransFICC Investment Ltd"}
                                customerTrader={searchParams.get("customerTrader") ?? "Judd Gaddie"}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </>
            ) : oAuthErrorMessage ? (
                <Route
                    path="*"
                    element={
                        <OAuthErrorPanel
                            errorMessage={oAuthErrorMessage}
                            initiateOAuthFlow={initiateOAuthFlow}
                            identityProviderGatewayUrl={identityProviderGatewayUrl}
                        />
                    }
                />
            ) : (
                <Route path="*" element={null} />
            )}
        </Routes>
    );
};

export default ApplicationRouter;
