import { Box, RadioBar } from "@transficc/components";
import React, { useCallback, useState } from "react";
import { useSelectedInquiryIsFinished } from "../irs-slice/irs-selectors";

export interface IncrementButtonProps {
    labels: string[];
    onSelectedIndexChange: (index: number) => void;
}

export const IRSIncrementButtons: React.FC<IncrementButtonProps> = ({ labels, onSelectedIndexChange }) => {
    const [activeIncrementText, setActiveIncrementText] = useState(labels[0] ?? "");
    const isFinished = useSelectedInquiryIsFinished();

    const onClick = useCallback(
        (value: string, index: number) => {
            onSelectedIndexChange(index);
            setActiveIncrementText(value);
        },
        [onSelectedIndexChange],
    );

    return (
        <Box data-testid={"irs-increment-buttons"} marginT={"2"} height={"7"}>
            <RadioBar
                values={labels}
                selectedValue={activeIncrementText}
                onClick={onClick}
                buttonWidth="16"
                isDisabled={isFinished}
                buttonSize={"xs"}
                joined
            />
        </Box>
    );
};
