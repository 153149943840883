import styled from "styled-components";
import React, { useEffect } from "react";
import * as IRSDomain from "../irs-domain";
import { colorByState } from "../irs-domain/colors";
import { Box, BoxProps, Icon, IconNames, Text } from "@transficc/components";
import { ActionPublisher, AutoXMode } from "@transficc/trader-desktop-shared-domain";
import { ErrorMessageBox } from "@transficc/trader-desktop-shared-components";
import { useInquiryFooter } from "../irs-slice/irs-selectors";

export interface IRSInquiryFooterProps {
    clearCircuitBreaker: () => void;
    closeActionFailure: (actionId: string) => void;
}

const StateBarBox = styled(Box).attrs(
    (): BoxProps => ({
        contentDirection: "row",
        contentAlignment: "center",
        contentJustification: "center",
        width: "full",
        height: "12",
    }),
)``;

const GrowingBox = styled(Box)`
    flex-grow: 1;
`;

interface ActionFailuresProp {
    ticketId: number;
    latestActionFailure: IRSDomain.LatestActionFailure | null;
    closeActionFailure: (actionId: string) => void;
}

const ActionFailures: React.FC<ActionFailuresProp> = ({ latestActionFailure, closeActionFailure }) => {
    return (
        <>
            <Text srOnly>Action Failures</Text>
            {latestActionFailure && !latestActionFailure?.closed && latestActionFailure?.reason ? (
                <ErrorMessageBox
                    title="Action Failure"
                    text={latestActionFailure.reason}
                    severity="danger"
                    dismissable={true}
                    onClose={() => {
                        closeActionFailure(latestActionFailure.actionId);
                    }}
                />
            ) : null}
        </>
    );
};

const StateBar: React.FC<{ state: IRSDomain.InquiryState }> = ({ state }) => {
    const { stateBarBackgroundColor, stateBarForegroundColor } = colorByState(state);

    return (
        <StateBarBox backgroundColor={stateBarBackgroundColor}>
            <Text srOnly>Inquiry State</Text>
            <Text size={"xl"} textColor={stateBarForegroundColor}>
                {IRSDomain.mapInquiryStateToDisplayName(state)}
            </Text>
        </StateBarBox>
    );
};

const AutoStreamingBar: React.FC = () => {
    return (
        <StateBarBox backgroundColor={{ color: "info", level: "600" }}>
            <Icon size={"6"} iconColor={{ color: "success", level: "300" }} name={IconNames.PlayCircle} />
            <Box marginL={"2.5"}>
                <Text srOnly>Inquiry State</Text>
                <Text size={"xl"} textColor={{ color: "white" }} data-testid={"streaming-state"}>
                    Streaming
                </Text>
            </Box>
        </StateBarBox>
    );
};

export const IRSInquiryFooter: React.FC<IRSInquiryFooterProps> = ({ clearCircuitBreaker, closeActionFailure }) => {
    const { state, isInquiryFinished, autoXMode, latestActionFailure, ticketId, autoXModeChangeReason, autoXModeChangeDescription } =
        useInquiryFooter();

    useEffect(() => {
        if (latestActionFailure && !latestActionFailure.closed) {
            setTimeout(() => {
                closeActionFailure(latestActionFailure.actionId);
            }, 30_000);
        }
    }, [closeActionFailure, latestActionFailure, ticketId]);

    return (
        <>
            <GrowingBox />
            <ActionFailures ticketId={ticketId} latestActionFailure={latestActionFailure} closeActionFailure={closeActionFailure} />
            {autoXModeChangeReason === ActionPublisher.AutoXModeChangeReason.CircuitBreaker && (
                <ErrorMessageBox
                    title="Warning - Streaming OFF - Circuit Breaker"
                    text={autoXModeChangeDescription ?? ""}
                    severity="warning"
                    dismissable={false}
                    onClose={clearCircuitBreaker}
                />
            )}
            {!isInquiryFinished && autoXMode === AutoXMode.AutoStream ? <AutoStreamingBar /> : <StateBar state={state} />}
        </>
    );
};
