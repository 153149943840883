import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import * as IRSDomain from "../irs-domain";
import {
    BidMidAsk,
    DisplayState,
    InquirySide,
    InquiryState,
    invalidPricerStates,
    IRSLeg,
    mapPricerStateToDisplayState,
    PriceType,
    SelectedDriver,
} from "../irs-domain";
import { Draft, produce } from "immer";
import { basisPointsToDecimal, decimalToBasisPoints, nullableRoundToIncrement, roundToIncrement } from "../irs-domain/scaling";
import { computeManualPtmmValue, computePtmmValue } from "../irs-domain/ptmm";
import { getSelectedDriver } from "../irs-domain/price-source";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import {
    CloseActionFailureAction,
    IRSOutrightReducerAction,
    IRSOutrightReducerState,
    IRSQuotingReducerState,
    QuotingSide,
    SelectDriverAction,
    UpdateCustomerRateValueAction,
    UpdateInquiryAction,
    UpdatePtmmValueAction,
    UpdateSpreadValueAction,
} from "./irs-outright-reducer-types";

function shouldSendPriceBasisMessage(draft: Draft<IRSOutrightReducerState>): void {
    draft.priceBasisSequenceNumber++;
    draft.shouldPublishPriceBasisUpdate = true;
}

function onUpdatePtmmValue(draft: Draft<IRSOutrightReducerState>, action: UpdatePtmmValueAction): void {
    draft.ptmmValue = action.newValue;
    draft.manualPtmmValue = action.newValue;
}

function onCloseActionFailure(draft: Draft<IRSOutrightReducerState>, action: CloseActionFailureAction): void {
    if (draft.latestActionFailure) {
        if (draft.latestActionFailure.actionId === action.actionId) {
            draft.latestActionFailure.closed = true;
            draft.closedActionFailures.push(action.actionId);
        }
    }
}

function defaultSelectedCell(side: InquirySide): { rowNo: number; columnNo: number } {
    return { rowNo: 1, columnNo: side === InquirySide.Buy ? 0 : 2 };
}

function onUpdateCustomerRateValue(
    draft: Draft<IRSQuotingReducerState>,
    action: UpdateCustomerRateValueAction,
    initialSpreadValue: DecimalNumber | null,
): void {
    updateCustomerRateValue(draft, action.newValue, initialSpreadValue);
}

function updateCustomerRateValue(
    draft: Draft<IRSQuotingReducerState>,
    newValue: DecimalNumber | null,
    initialSpreadValue: DecimalNumber | null,
): void {
    draft.customerRateValue = newValue;
    draft.manualRateValue = newValue;
    draft.selectedDriver = null;
    draft.hasDriverValue = false;
    draft.spreadValue = initialSpreadValue;
}

function getDriverValue(selectedDriver: SelectedDriver, quotingState: Readonly<IRSQuotingReducerState>): string | null {
    if (selectedDriver.rowNo === 0) {
        return quotingState.latestQuotedPrices[selectedDriver.columnNo] ?? null;
    } else if (selectedDriver.rowNo === 1) {
        return quotingState.autoQuotePrices[selectedDriver.columnNo] ?? null;
    } else if (selectedDriver.rowNo === 2) {
        return quotingState.venuePrices[selectedDriver.columnNo] ?? null;
    }
    return null;
}

function getCurrentSelectedDriverValue(quotingState: Readonly<IRSQuotingReducerState>): string | null {
    if (quotingState.selectedDriver) {
        return getDriverValue(quotingState.selectedDriver, quotingState);
    }
    return null;
}

function onUpdateSpreadValue(
    draft: Draft<IRSOutrightReducerState>,
    quotingValues: Draft<IRSQuotingReducerState>,
    action: UpdateSpreadValueAction,
    initialQuotingValues: IRSQuotingReducerState,
): void {
    updateSpreadValue(quotingValues, initialQuotingValues, action.spreadValue, action.increment, draft.inquiry?.legs[0].priceType);
}

function updateSpreadValue(
    quotingValues: Draft<IRSQuotingReducerState>,
    initialQuotingValues: IRSQuotingReducerState,
    spreadValue: DecimalNumber | null,
    increment: DecimalNumber,
    priceType?: PriceType,
): void {
    quotingValues.spreadValue = spreadValue;
    const driverValue = getCurrentSelectedDriverValue(quotingValues);

    if (!driverValue) {
        quotingValues.customerRateValue = initialQuotingValues.customerRateValue;
        quotingValues.manualRateValue = initialQuotingValues.customerRateValue;
        quotingValues.spreadValue = initialQuotingValues.spreadValue;
    } else {
        if (spreadValue === null) {
            quotingValues.customerRateValue = new ImmutableDecimalNumber(driverValue);
        } else {
            const spreadValueInDecimal = priceType === PriceType.NetPresentValue ? spreadValue : basisPointsToDecimal(spreadValue);
            quotingValues.customerRateValue = roundToIncrement(
                new ImmutableDecimalNumber(driverValue).add(spreadValueInDecimal),
                increment,
            );
            quotingValues.manualRateValue = null;
        }
    }
}

function onSelectDriver(
    draft: Draft<IRSOutrightReducerState>,
    quotingState: Draft<IRSQuotingReducerState>,
    action: SelectDriverAction,
    initialState: IRSQuotingReducerState,
): void {
    selectDriver(draft, quotingState, action.driver, initialState);
}

function selectDriver(
    draft: Draft<IRSOutrightReducerState>,
    quotingState: Draft<IRSQuotingReducerState>,
    driver: SelectedDriver,
    initialState: IRSQuotingReducerState,
): void {
    if (draft.inquiry) {
        const driverValue = getDriverValue(driver, quotingState);

        const displayState = driver.rowNo !== 1 ? DisplayState.Valid : mapPricerStateToDisplayState(draft.inquiry.autoQuotePriceState);

        if (displayState !== DisplayState.Valid || driverValue) {
            quotingState.selectedDriver = driver;
            quotingState.hasDriverValue = driverValue !== null;
            quotingState.spreadValue = new ImmutableDecimalNumber(0);
            quotingState.customerRateValue = driverValue ? new ImmutableDecimalNumber(driverValue) : initialState.customerRateValue;
            quotingState.manualRateValue = driverValue ? null : initialState.customerRateValue;
        }
    }
}

function onUpdateInquiryQuotingState(
    draft: Draft<IRSOutrightReducerState>,
    quotingState: Draft<IRSQuotingReducerState>,
    initialState: IRSQuotingReducerState,
    action: UpdateInquiryAction,
): void {
    if (draft.inquiry !== null) {
        const isALastQuoteDriverSelected = quotingState.selectedDriver?.rowNo === 0;
        const lastQuoteDriverSelectedColumn = quotingState.selectedDriver?.columnNo ?? -1;
        if (isALastQuoteDriverSelected && lastQuoteDriverSelectedColumn >= 0) {
            const previousLastSelectedQuotePrice = draft.inquiry.legs[0].lastQuotedPrices[lastQuoteDriverSelectedColumn];
            const newLastSelectedQuotePrice = action.inquiry.legs[0].lastQuotedPrices[lastQuoteDriverSelectedColumn];
            if (previousLastSelectedQuotePrice !== newLastSelectedQuotePrice) {
                quotingState.spreadValue = initialState.spreadValue;
            }
        }
    }
}

function updateDriverOnInquiryUpdate(
    inquiryState: Draft<IRSOutrightReducerState>,
    quotingState: Draft<IRSQuotingReducerState>,
    defaultQuotingState: IRSQuotingReducerState,
    action: UpdateInquiryAction,
): void {
    if (inquiryState.inquiry === null) {
        return;
    }

    const driverValue = getCurrentSelectedDriverValue(quotingState);
    if (quotingState.selectedDriver && !driverValue) {
        quotingState.customerRateValue = defaultQuotingState.customerRateValue;
        quotingState.manualRateValue = defaultQuotingState.customerRateValue;
        quotingState.spreadValue = defaultQuotingState.spreadValue;
        return;
    }

    const tradedPrice = inquiryState.inquiry.legs[0].negotiatedPrice || null;
    const isInquiryDone = inquiryState.inquiry.state === InquiryState.Done && tradedPrice !== null;

    if (driverValue && !isInquiryDone) {
        if (!quotingState.spreadValue) {
            quotingState.spreadValue = new ImmutableDecimalNumber(0);
        }
        const spreadValueInDecimal =
            inquiryState.inquiry.legs[0].priceType === PriceType.NetPresentValue
                ? quotingState.spreadValue
                : basisPointsToDecimal(quotingState.spreadValue);
        quotingState.customerRateValue = roundToIncrement(
            new ImmutableDecimalNumber(driverValue).add(spreadValueInDecimal),
            action.increment,
        );
        quotingState.manualRateValue = null;
    } else if (isInquiryDone) {
        quotingState.customerRateValue = new ImmutableDecimalNumber(tradedPrice);
        if (driverValue !== null) {
            if (inquiryState.inquiry.legs[0].priceType === PriceType.NetPresentValue) {
                quotingState.spreadValue = quotingState.customerRateValue.subtract(driverValue);
            } else {
                quotingState.spreadValue = decimalToBasisPoints(quotingState.customerRateValue.subtract(driverValue));
            }
        } else {
            quotingState.spreadValue = null;
        }
    }
}

function selectInitialDriver(inquiry: IRSDomain.IRSInquiry<"outright">, selectedDriver: SelectedDriver | null): SelectedDriver | null {
    if (selectedDriver == null) {
        return null;
    }
    const leg = inquiry.legs[0];
    const displayState = mapPricerStateToDisplayState(inquiry.autoQuotePriceState);

    const shouldSelectInitialDriver = displayState !== DisplayState.Valid || leg.autoQuotePrices[selectedDriver.columnNo] !== null;
    return shouldSelectInitialDriver ? selectedDriver : null;
}

const onDeselectDriver = (quotingState: Draft<IRSQuotingReducerState>, initialValue: DecimalNumber | null): void => {
    deselectDriver(quotingState, initialValue);
};

const deselectDriver = (quotingState: Draft<IRSQuotingReducerState>, initialSpreadValue: DecimalNumber | null): void => {
    quotingState.selectedDriver = null;
    quotingState.hasDriverValue = false;
    quotingState.spreadValue = initialSpreadValue;
    quotingState.manualRateValue = quotingState.customerRateValue;
};

export const outrightReducerInitialState: IRSOutrightReducerState = {
    inquiry: null,
    shouldPublishPriceBasisUpdate: false,
    autoXModeChangeReason: null,
    isDirty: false,
    pay: {
        spreadValue: null,
        customerRateValue: null,
        manualRateValue: null,
        selectedDriver: null,
        hasDriverValue: false,
        autoQuotePrices: [null, null, null],
        venuePrices: [null, null, null],
        latestQuotedPrices: [null, null, null],
    },
    rcv: {
        spreadValue: null,
        customerRateValue: null,
        manualRateValue: null,
        selectedDriver: null,
        hasDriverValue: false,
        autoQuotePrices: [null, null, null],
        venuePrices: [null, null, null],
        latestQuotedPrices: [null, null, null],
    },
    ptmmValue: null,
    manualPtmmValue: null,
    priceBasisSequenceNumber: 0,
    latestActionFailure: null,
    closedActionFailures: [],
};

export function irsOutrightReducer(state: IRSOutrightReducerState, action: IRSOutrightReducerAction): IRSOutrightReducerState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "UpdateInquiry": {
                onUpdateInquiry(draft, action);
                break;
            }
            case "UpdateCustomerRateValue": {
                const quotingReducerState = action.quotingSide === "PAY" ? draft.pay : draft.rcv;
                const initialSpreadValue =
                    action.quotingSide === "PAY"
                        ? outrightReducerInitialState.pay.spreadValue
                        : outrightReducerInitialState.rcv.spreadValue;
                onUpdateCustomerRateValue(quotingReducerState, action, initialSpreadValue);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "UpdateSpreadValue": {
                const quotingReducerState = action.quotingSide === "PAY" ? draft.pay : draft.rcv;
                const initialState = action.quotingSide === "PAY" ? outrightReducerInitialState.pay : outrightReducerInitialState.rcv;
                onUpdateSpreadValue(draft, quotingReducerState, action, initialState);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "SelectDriver": {
                const initialState = action.quotingSide === "PAY" ? outrightReducerInitialState.pay : outrightReducerInitialState.rcv;
                const quotingState = action.quotingSide === "PAY" ? draft.pay : draft.rcv;
                onSelectDriver(draft, quotingState, action, initialState);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "DeselectDriver": {
                if (action.quotingSide === "PAY") {
                    onDeselectDriver(draft.pay, outrightReducerInitialState.pay.spreadValue);
                } else {
                    onDeselectDriver(draft.rcv, outrightReducerInitialState.rcv.spreadValue);
                }
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "SetIsDirty": {
                draft.isDirty = action.isDirty;
                break;
            }
            case "PriceBasisUpdateSent": {
                draft.shouldPublishPriceBasisUpdate = false;
                break;
            }
            case "UpdatePtmmValue": {
                onUpdatePtmmValue(draft, action);
                shouldSendPriceBasisMessage(draft);
                break;
            }
            case "ClearCircuitBreaker": {
                draft.autoXModeChangeReason = null;
                break;
            }
            case "CloseActionFailure": {
                onCloseActionFailure(draft, action);
                break;
            }
        }
    });
}

const updatePriceBasisSequenceNumber = (draft: Draft<IRSOutrightReducerState>, action: UpdateInquiryAction): void => {
    const actionPriceBasisEventSequence = action.inquiry.priceBasis.eventSequence;
    if (actionPriceBasisEventSequence && actionPriceBasisEventSequence > draft.priceBasisSequenceNumber) {
        draft.priceBasisSequenceNumber = actionPriceBasisEventSequence;
    }
};

const applyPriceBasisUpdates = (draft: Draft<IRSOutrightReducerState>, action: UpdateInquiryAction): void => {
    const updatePriceBasisSide = (
        initialQuotingValues: IRSQuotingReducerState,
        side: IRSQuotingReducerState,
        priceSource: ActionPublisher.PriceSource | null,
        priceSpread: string | null,
        manualPrice: string | null,
    ): void => {
        if (!draft.inquiry) {
            return;
        }

        const selectedDriver = getSelectedDriver(priceSource);
        const priceType = draft.inquiry.legs[0].priceType;
        if (selectedDriver) {
            selectDriver(draft, side, selectedDriver, initialQuotingValues);
        } else {
            deselectDriver(side, initialQuotingValues.spreadValue);
        }
        updateSpreadValue(
            side,
            initialQuotingValues,
            priceSpread ? new ImmutableDecimalNumber(priceSpread) : null,
            action.increment,
            priceType,
        );
        if (manualPrice) {
            updateCustomerRateValue(side, new ImmutableDecimalNumber(manualPrice), initialQuotingValues.spreadValue);
        }
    };

    const serverSequenceNumber = action.inquiry.priceBasis.eventSequence ?? 0;
    const screenSequenceNumber = draft.priceBasisSequenceNumber;
    if (serverSequenceNumber <= screenSequenceNumber) {
        return;
    }

    const priceBasisLeg = action.inquiry.legs[0].priceBasisLeg;
    if (priceBasisLeg.ptmm !== null) {
        draft.ptmmValue = new ImmutableDecimalNumber(priceBasisLeg.ptmm);
        draft.manualPtmmValue = new ImmutableDecimalNumber(priceBasisLeg.ptmm);
    }

    updatePriceBasisSide(
        outrightReducerInitialState.pay,
        draft.pay,
        priceBasisLeg.bidPriceSource,
        priceBasisLeg.bidPriceSpread,
        priceBasisLeg.manualBidPrice,
    );
    updatePriceBasisSide(
        outrightReducerInitialState.rcv,
        draft.rcv,
        priceBasisLeg.askPriceSource,
        priceBasisLeg.askPriceSpread,
        priceBasisLeg.manualAskPrice,
    );

    updatePriceBasisSequenceNumber(draft, action);
};

export function onUpdateInquiry(draft: Draft<IRSOutrightReducerState>, action: UpdateInquiryAction): void {
    if (draft.inquiry === null) {
        draft.pay.selectedDriver = selectInitialDriver(action.inquiry, defaultSelectedCell(InquirySide.Buy));
        draft.rcv.selectedDriver = selectInitialDriver(action.inquiry, defaultSelectedCell(InquirySide.Sell));
    } else {
        onUpdateInquiryQuotingState(draft, draft.pay, outrightReducerInitialState.pay, action);
        onUpdateInquiryQuotingState(draft, draft.rcv, outrightReducerInitialState.rcv, action);
    }

    draft.inquiry = action.inquiry;
    draft.autoXModeChangeReason = action.inquiry.autoXModeChangeReason;

    const aqMidPrice = draft.inquiry.legs[0].autoQuotePrices[1];
    const aqPriceState = draft.inquiry.autoQuotePriceState;

    draft.ptmmValue = nullableRoundToIncrement(computePtmmValue(draft.ptmmValue, aqPriceState, aqMidPrice), action.increment);
    draft.manualPtmmValue = nullableRoundToIncrement(
        computeManualPtmmValue(draft.manualPtmmValue, aqPriceState, aqMidPrice),
        action.increment,
    );

    const leg = action.inquiry.legs[0];
    const isMac = draft.inquiry.isMac;
    const isRfm = draft.inquiry.isRfm;
    updateAutoQuotePrices(draft.pay, leg, "PAY", isMac, isRfm);
    updateAutoQuotePrices(draft.rcv, leg, "RCV", isMac, isRfm);
    updateVenuePrices(draft.pay, leg, "PAY", isMac, isRfm);
    updateVenuePrices(draft.rcv, leg, "RCV", isMac, isRfm);
    updateLatestQuotedPrices(draft.pay, leg);
    updateLatestQuotedPrices(draft.rcv, leg);

    draft.pay.hasDriverValue = draft.pay.selectedDriver !== null ? getCurrentSelectedDriverValue(draft.pay) !== null : false;
    draft.rcv.hasDriverValue = draft.rcv.selectedDriver !== null ? getCurrentSelectedDriverValue(draft.rcv) !== null : false;

    updateDriverOnInquiryUpdate(draft, draft.pay, outrightReducerInitialState.pay, action);
    updateDriverOnInquiryUpdate(draft, draft.rcv, outrightReducerInitialState.rcv, action);

    const autoQuotePriceState = action.inquiry.autoQuotePriceState;
    if (invalidPricerStates.some((state) => state === autoQuotePriceState)) {
        updateRateValueIfDrivenByAutoQuoter(draft.pay);
        updateRateValueIfDrivenByAutoQuoter(draft.rcv);
    }

    const lastFailure = draft.inquiry.actionFailures[draft.inquiry.actionFailures.length - 1];
    if (action.userId !== Number.MIN_SAFE_INTEGER && lastFailure && lastFailure.userId === action.userId) {
        draft.latestActionFailure = {
            actionId: lastFailure.actionId,
            actionType: lastFailure.actionType,
            reason: lastFailure.reason,
            source: lastFailure.source,
            userId: lastFailure.userId,
            closed: draft.closedActionFailures.includes(lastFailure.actionId),
        };
    }

    // this definitely needs to be last!
    applyPriceBasisUpdates(draft, action);
}

function updateRateValueIfDrivenByAutoQuoter(quotingState: IRSQuotingReducerState): void {
    if (quotingState.selectedDriver?.rowNo === 1) {
        quotingState.customerRateValue = null;
        quotingState.manualRateValue = null;
    }
}

const updateAutoQuotePrices = (
    quotingState: Draft<IRSQuotingReducerState>,
    leg: IRSLeg<"outright">,
    quotingSide: QuotingSide,
    isMac: boolean,
    isRfm: boolean,
): void => {
    quotingState.autoQuotePrices = negateMidPriceIfNecessary(leg.autoQuotePrices, quotingSide, isMac, isRfm);
};

const updateVenuePrices = (
    quotingState: Draft<IRSQuotingReducerState>,
    leg: IRSLeg<"outright">,
    quotingSide: QuotingSide,
    isMac: boolean,
    isRfm: boolean,
): void => {
    quotingState.venuePrices = negateMidPriceIfNecessary(leg.venuePrices, quotingSide, isMac, isRfm);
};

const updateLatestQuotedPrices = (quotingState: Draft<IRSQuotingReducerState>, leg: IRSLeg<"outright">): void => {
    quotingState.latestQuotedPrices = leg.lastQuotedPrices;
};

const negateMidPriceIfNecessary = (prices: BidMidAsk, quotingSide: QuotingSide, isMac: boolean, isRfm: boolean): BidMidAsk => {
    if (isMac && isRfm && quotingSide === "RCV") {
        let midPrice = prices[1];
        if (midPrice) {
            midPrice = (-Number(midPrice)).toString();
        }
        return [prices[0], midPrice, prices[2]];
    } else {
        return prices;
    }
};
