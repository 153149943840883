import { createGlobalStyle } from "styled-components";

// Lots of inspiration from https://github.com/tailwindlabs/tailwindcss/blob/master/src/css/preflight.css
// Eventually we should just copy it ALL in
export const GlobalStyles = createGlobalStyle`
    /*
        1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
        2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
    */
    *,
    ::before,
    ::after {
        box-sizing: border-box; /* 1 */
        border-width: 0; /* 2 */
        border-style: solid; /* 2 */
    }

    /*
        1. Use a consistent sensible line-height in all browsers.
        2. Prevent adjustments of font size after orientation changes in iOS.
        3. Use a more readable tab size.
        4. Use the \`sans\` font-family by default.
    */
    html {
        line-height: ${(props) => props.theme.font.defaultLineHeight}; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
        -moz-tab-size: 4; /* 3 */
        tab-size: 4; /* 3 */
        font-family: ${(props) => props.theme.font.family[props.theme.font.defaultFontFamily]}; /* 4 */
        font-feature-settings: "calt" 1;
        font-stretch: 75%;
    }

    /*
        1. Set global background color from theme
        2. Remove margin from body!
    */
    body {
        background-color: ${(props) => props.theme.colors.globalBackgroundColor}; /* 1 */
        margin: 0; /* 2 */
    }


    /*
        1. Change the font styles in all browsers.
        2. Remove the margin in Firefox and Safari.
        3. Remove default padding in all browsers.
    */
    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit; /* 1 */
        font-size: 100%; /* 1 */
        font-weight: inherit; /* 1 */
        line-height: inherit; /* 1 */
        color: inherit; /* 1 */
        margin: 0; /* 2 */
        padding: 0; /* 3 */
    }

    /*
        1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
        2. Set the default placeholder color to the user's configured gray 400 color.
    */
    input::placeholder,
    textarea::placeholder {
        opacity: 1; /* 1 */
        color: ${(props) => props.theme.colors.gray["400"]}; /* 2 */
    }

    /*
        Remove the inheritance of text transform in Edge and Firefox.
    */

    button,
    select {
        text-transform: none;
    }

    /*
        1. Correct the inability to style clickable types in iOS and Safari.
        2. Remove default button styles.
    */

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button; /* 1 */
        background-image: none; /* 2 */
    }

    /*
        Use the modern Firefox focus style for all focusable elements.
    */

    :-moz-focusring {
        outline: auto;
    }

    /*
        Remove the additional \`:invalid\` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
    */

    :-moz-ui-invalid {
        box-shadow: none;
    }

    /*
        1. Removes the outline when focusing on inputs
     */
    input:focus,
    textarea:focus {
        outline: none; /* 1 */
    }

    /*
        1. Removes number input ticker on Chrome, Safari, Edge, Opera
    */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none; /* 1 */
        margin: 0; /* 1 */
    }

    /*
        1. Removes number input ticker on Firefox
    */
    input[type=number] {
        -moz-appearance: textfield;  /* 1 */
    }
`;
