import { EpochClock } from "@transficc/infrastructure";
import { PriceValidity, SelectedDriver } from "./models";
import { getPriceSource } from "./price-source";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";

export function isSelectedPriceInvalidForActiveInquiry(
    isPricerStreaming: boolean,
    validity: PriceValidity | null,
    selectedDriver: SelectedDriver | null,
    isInquiryFinished: boolean,
    clock: EpochClock,
): boolean {
    if (!isPricerStreaming) {
        return false;
    }
    if (havePricesExpired(validity, isInquiryFinished, clock)) {
        const driver = getPriceSource(selectedDriver);
        switch (driver) {
            case ActionPublisher.PriceSource.AutoquoteAsk:
            case ActionPublisher.PriceSource.AutoquoteBid:
            case ActionPublisher.PriceSource.AutoquoteMid:
                return true;
            default:
                return false;
        }
    }
    return false;
}

export function havePricesExpired(validity: PriceValidity | null, isInquiryFinished: boolean, clock: EpochClock): boolean {
    if (isInquiryFinished || validity === null) {
        return false;
    }
    const utcMillis: number = toMillis(validity.priceGenerationTimestampNanos);
    const nowMs = clock.getCurrentTimeMs();
    return nowMs - utcMillis >= toMillis(validity.priceValidForNanos);
}

export function priceIsStale(validity: PriceValidity | null, clock: EpochClock): boolean {
    if (validity === null) {
        return false;
    }
    const utcMillis: number = toMillis(validity.priceGenerationTimestampNanos);
    const nowMs = clock.getCurrentTimeMs();
    return nowMs - utcMillis >= toMillis(validity.priceValidForNanos);
}

function toMillis(timestamp: string): number {
    return parseInt(timestamp) / 1_000_000;
}
