import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";

export const calculatePackageLevelValueByInquiryType = (
    isMac: boolean,
    rightPriceString: string | null,
    leftPriceString: string | null,
): DecimalNumber | null => {
    if (rightPriceString === null || leftPriceString === null) {
        return null;
    } else {
        const leftPrice = new ImmutableDecimalNumber(leftPriceString);
        const rightPrice = new ImmutableDecimalNumber(rightPriceString);
        if (leftPrice.isNaN() || rightPrice.isNaN()) {
            return null;
        }
        if (isMac) {
            return rightPrice.add(leftPrice);
        } else {
            return rightPrice.subtract(leftPrice).multiplyBy(100);
        }
    }
};
export const calculatePackageSpreadByPriceType = (
    isMac: boolean,
    leftLegSpread: DecimalNumber,
    rightLegSpread: DecimalNumber,
): DecimalNumber => {
    if (isMac) {
        return rightLegSpread.add(leftLegSpread);
    } else {
        return rightLegSpread.subtract(leftLegSpread);
    }
};
