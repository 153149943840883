import React, { useMemo } from "react";
import { formatQuantityToThreeDp, formatToThousandsWithK } from "../irs-domain";
import { Box, formatWithCommas, Text } from "@transficc/components";
import { InquiryButtonRow } from "../inquiry-button-row/inquiry-button-row";

export interface IrsCompressionHeaderProps {
    model: {
        legCount: number;
        notional: string;
        delta: string | null;
        isMixedCcp: boolean;
        isMixedIndices: boolean;
    };
    callbacks: {
        openRiskViewWindow: (url: string) => void;
    };
}

const IRSCompressionHeaderValue: React.FC<{
    title: string;
    value: string | null;
    valueColor?: Theme.Color;
}> = ({ title, value, valueColor = { color: "black" } }) => {
    return (
        <Box contentDirection={"row"} contentAlignment={"center"}>
            <Text textColor={{ color: "black" }} size={"lg"}>
                {title}&nbsp;
            </Text>
            <Text srOnly>{title}</Text>
            <Text textColor={valueColor} size={"lg"} weight={"bold"}>
                {value}
            </Text>
        </Box>
    );
};

const IRSCompressionHeaderWarningBox: React.FC<{
    isMixedCcp: boolean;
    isMixedIndices: boolean;
}> = ({ isMixedCcp, isMixedIndices }) => {
    const warningText = useMemo(() => {
        if (isMixedCcp && isMixedIndices) {
            return "Mixed CCPs & Mixed Indices";
        } else if (isMixedCcp) {
            return "Mixed CCPs";
        } else if (isMixedIndices) {
            return "Mixed Indices";
        } else {
            return undefined;
        }
    }, [isMixedCcp, isMixedIndices]);

    if (warningText) {
        return (
            <Box
                contentDirection="row"
                contentJustification="space-around"
                contentAlignment={"center"}
                padding={"1"}
                backgroundColor={{ color: "gold", level: "500" }}
                width={"full"}
            >
                <Text srOnly>{"Compression Warnings"}</Text>
                <Text size={"lg"} textColor={{ color: "black" }}>
                    {warningText}
                </Text>
            </Box>
        );
    } else {
        return <Text srOnly>{"No Compression Warnings"}</Text>;
    }
};

export const IrsCompressionHeader: React.FC<IrsCompressionHeaderProps> = ({
    model: { legCount, notional, delta, isMixedCcp, isMixedIndices },
    callbacks,
}) => {
    const legCountText = useMemo(() => legCount.toString() + " " + (legCount === 1 ? "Item" : "Items"), [legCount]);

    const notionalText = useMemo(() => formatWithCommas(formatQuantityToThreeDp(notional)), [notional]);
    const deltaText = useMemo(() => {
        if (delta != null) {
            return formatToThousandsWithK(delta);
        } else {
            return "-";
        }
    }, [delta]);

    const deltaColor: Theme.Color = useMemo(() => {
        if (delta !== null && Number.parseInt(delta) > 0) {
            return { color: "success", level: "600" };
        } else {
            return { color: "danger", level: "600" };
        }
    }, [delta]);

    return (
        <Box data-testid={"compression-header"}>
            <InquiryButtonRow openRiskViewWindow={callbacks.openRiskViewWindow} />
            <IRSCompressionHeaderWarningBox isMixedCcp={isMixedCcp} isMixedIndices={isMixedIndices} />
            <Box
                contentDirection="row"
                contentJustification="space-around"
                backgroundColor={{ color: "gray", level: "300" }}
                width={"full"}
                padding={"1.5"}
            >
                <IRSCompressionHeaderValue title="IRS List" value={legCountText} />
                <IRSCompressionHeaderValue title="Notional" value={notionalText} />
                <IRSCompressionHeaderValue title="Delta" value={deltaText} valueColor={deltaColor} />
            </Box>
        </Box>
    );
};
