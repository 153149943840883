import { useCreditStateDispatch } from "./useCreditStateDispatch";
import { CreditStateCallbacks } from "../credit-domain";

export const useCreditStateCallbacks = (): CreditStateCallbacks => {
    const creditStateDispatch = useCreditStateDispatch();
    return {
        closeActionFailure: (ticketId: number, actionId: string) => {
            creditStateDispatch.closeLatestActionFailure({ ticketId, actionId });
        },
    };
};
