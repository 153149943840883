import React from "react";
import { Box, Text } from "../../atoms";

export interface TierDisplayProps {
    tier?: number | null;
}

export const TierDisplay: React.FC<TierDisplayProps> = ({ tier }) => {
    return (
        <Box
            backgroundColor={{ color: "cyan", level: "100" }}
            width="24"
            height="8"
            rounded
            contentAlignment="center"
            contentJustification="center"
        >
            <Text
                textColor={{
                    color: "cyan",
                    level: "900",
                }}
                data-testid="inquiry-tier-display"
            >
                Tier {tier === null || tier === undefined ? "-" : tier.toFixed(0)}
            </Text>
        </Box>
    );
};
