import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { Inquiry, PriceType } from "../credit-domain";
import React from "react";
import styled from "styled-components";
import { CreditInquiryHeader } from "./credit-inquiry-header";
import { CreditInquiryFooter } from "./credit-inquiry-footer";
import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { CreditInquiryBody } from "./credit-inquiry-body";
import { NoSelectedInquiry } from "./no-selected-inquiry";
import {
    DEFAULT_ON_THE_WIRE_TIME_MILLIS,
    getWholeInquiryBackgroundColor,
    getWholeInquiryBackgroundShouldFlash,
} from "../credit-domain/constants";
import { useDisplayedInquiryNullable } from "../credit-slice/useCreditSliceSelectors";
import { Box, Text } from "@transficc/components";
import { getItemFromLocalStorage, LocalStorageKeys } from "@transficc/trader-desktop-local-storage";

export interface CreditInquiryProps {
    config: {
        clock: EpochClock;
        showCustomerAnalytics: boolean;
        timezoneProvider: TimezoneProvider;
        actionPublisher: ActionPublisher.ActionPublisher;
    };
    callbacks: {
        openCustomerAnalyticsWindow: OpenCustomerAnalytics;
    };
}

const SelectedCreditInquiryContainer = styled(Box)`
    width: 100%;
    overflow: auto;
    max-width: 46rem;
`;

const InquiryContainer = styled(Box)<{ wholeInquiryBackgroundShouldFlash: boolean }>`
    height: 100%;
    min-width: 46rem;
    min-height: 51rem;
    animation: ${({ wholeInquiryBackgroundShouldFlash }) =>
        wholeInquiryBackgroundShouldFlash ? "blinkingBackground 1s infinite" : "none"};

    @keyframes blinkingBackground {
        50% {
            background-color: transparent;
        }
    }
`;

export const CouldNotRenderInquiry: React.FC<{ reason: string }> = ({ reason }) => (
    <Box rounded backgroundColor={{ color: "danger", level: "600" }} padding={"4"}>
        <Text textColor={{ color: "white" }}>Error: Could not render inquiry due to {reason}</Text>
    </Box>
);

const supportedInquiry = (inquiry: Inquiry): boolean => {
    return inquiry.leg.priceType === PriceType.PercentOfPar || inquiry.leg.priceType === PriceType.Spread;
};

export type OpenCustomerAnalytics = (customerFirm: string, customerTrader: string) => void;

export const SelectedCreditInquiry: React.FC<CreditInquiryProps> = ({ config, callbacks }) => {
    const inquiry = useDisplayedInquiryNullable();
    const initialUserDefinedOnTheWireTimeMs = Number(
        getItemFromLocalStorage(LocalStorageKeys.USER_DEFINED_QUOTE_OTW_TIME_MILLIS) ?? DEFAULT_ON_THE_WIRE_TIME_MILLIS,
    );

    let content: React.ReactNode;
    if (!inquiry) {
        content = <NoSelectedInquiry />;
    } else if (supportedInquiry(inquiry)) {
        content = (
            <InquiryContainer
                backgroundColor={getWholeInquiryBackgroundColor(inquiry.state)}
                wholeInquiryBackgroundShouldFlash={getWholeInquiryBackgroundShouldFlash(inquiry.state)}
            >
                <CreditInquiryHeader
                    config={{ clock: config.clock, showCustomerAnalytics: config.showCustomerAnalytics }}
                    callbacks={callbacks}
                />
                <CreditInquiryBody
                    timezoneProvider={config.timezoneProvider}
                    actionPublisher={config.actionPublisher}
                    clock={config.clock}
                    initialUserDefinedOnTheWireTimeMs={initialUserDefinedOnTheWireTimeMs}
                />
                <CreditInquiryFooter timezoneProvider={config.timezoneProvider} />
            </InquiryContainer>
        );
    } else {
        content = <CouldNotRenderInquiry reason={"not knowing the inquiry type"} />;
    }

    return <SelectedCreditInquiryContainer data-testid={"selected-inquiry-container"}>{content}</SelectedCreditInquiryContainer>;
};
