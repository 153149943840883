export interface InquiriesResponse {
    inquiries: Inquiry[];
    /**
     * Configured maximum number of inquiries to be returned
     */
    inquiriesLimit: number;
    /**
     * Total number of inquiries
     */
    totalCount: number;
}

export interface Inquiry {
    /**
     * Venue id for this inquiry
     */
    aggregateId: string;
    /**
     * The AutoX mode for this inquiry
     */
    autoMode: AutoXMode | null;
    /**
     * Benchmark Hedge
     */
    benchmarkHedge: BenchmarkHedge | null;
    /**
     * Rank of an anonymous counterparty
     */
    counterpartyRank: number | null;
    /**
     * Type of an anonymous counterparty, dealer or customer
     */
    counterpartyType:        CounterpartyType | null;
    curtainTimePeriodMillis: string | null;
    /**
     * Customer provided identifier for this inquiry
     */
    customTradeHandle: string | null;
    /**
     * Trader's full name
     */
    dealerFullName: string | null;
    /**
     * Proprietary/Venue id of counterparty firm
     */
    firmId: string | null;
    /**
     * Hedging type
     */
    hedgingType: HedgingType | null;
    /**
     * time that the inquiry was taken
     */
    inquiryCreationTimestampNanos: string | null;
    /**
     * Indicates if inquiry is all to all
     */
    isAllToAll: boolean | null;
    /**
     * Indicates if counterparty is anonymous
     */
    isCounterpartyAnonymous: boolean | null;
    /**
     * Indicates one way (false) or two way (true) pricing
     */
    isRFM:                       boolean | null;
    latestQuotedMidPackagePrice: string | null;
    /**
     * Price package was traded at
     */
    latestQuotedOppositePackagePrice: string | null;
    /**
     * Price package was traded at
     */
    latestQuotedPackagePrice: string | null;
    legs:                     Leg[];
    listId:                   string | null;
    /**
     * The number of dealers including you
     */
    numberOfDealers: number | null;
    /**
     * Number of items in a list which this inquiry is part of. Set for inquiries that are part
     * of list, e.g. compression or non-contingent list
     */
    numberOfListItems: number | null;
    /**
     * Second best price in multi-dealer negotiation scenario
     */
    packageCoverPrice: string | null;
    /**
     * Ranking of dealer's last level against competition
     */
    packageQuoteCompetitiveStatus: QuoteCompetitiveStatus | null;
    /**
     * Did counterparty trade with another dealer in a competitive inquiry
     */
    packageTradedAway: boolean | null;
    /**
     * Price package was traded at
     */
    packageTradedPrice: string | null;
    /**
     * Package type
     */
    packageType: PackageType;
    /**
     * Counterparty tier assigned on the venue
     */
    priceTier: number | null;
    /**
     * Id of the sales person at the dealer
     */
    salesPersonId: string | null;
    /**
     * Name of the sales person at the dealer
     */
    salesPersonName: string | null;
    /**
     * time that the snapshot was taken
     */
    snapshotCreationTimestampNanos: string;
    /**
     * Spot negotiation type
     */
    spotNegotiationModel: SpotNegotiationModel | null;
    /**
     * Time the benchmark spot negotiation will be initiated
     */
    spotRequestTimeNanos: string | null;
    /**
     * Current inquiry state, after the trigger event has been processed
     */
    state: State;
    /**
     * TransFICC id for this inquiry
     */
    ticketId: number;
    /**
     * Name of the counter party firm
     */
    traderName: string | null;
    /**
     * Name of the venue for this inquiry
     */
    venueName: string;
    /**
     * Regulatory qualifier of the trading venue
     */
    venueType: VenueType | null;
}

/**
 * The AutoX mode for this inquiry
 */
export enum AutoXMode {
    AutoExecute = "AUTO_EXECUTE",
    AutoReject = "AUTO_REJECT",
    AutoStream = "AUTO_STREAM",
    Manual = "MANUAL",
}

/**
 * Benchmark Hedge
 */
export enum BenchmarkHedge {
    Auto = "AUTO",
    Manual = "MANUAL",
}

/**
 * Type of an anonymous counterparty, dealer or customer
 */
export enum CounterpartyType {
    Customer = "CUSTOMER",
    Dealer = "DEALER",
}

/**
 * Hedging type
 */
export enum HedgingType {
    Cross = "CROSS",
    Spot = "SPOT",
}

export interface Leg {
    /**
     * Instrument identifier as provided by the venue for the benchmark
     */
    benchmarkInstrumentId: string | null;
    /**
     * Instrument identifier type as provided by the venue
     */
    benchmarkInstrumentIdType: InstrumentIDType | null;
    /**
     * Human-readable benchmark name
     */
    benchmarkName: string | null;
    /**
     * Benchmark price that has been spotted as part of a multi-step spread based negotiation
     */
    benchmarkPrice: string | null;
    /**
     * Quantity of benchmark instrument which is traded as part of a cross
     */
    benchmarkQuantity: string | null;
    /**
     * Par price of multi-step spread based negotiation once benchmark had been spotted
     */
    cashPrice: string | null;
    /**
     * Second best price in multi-dealer negotiation scenario
     */
    coverPrice: string | null;
    /**
     * Currency the instrument is negotiated in
     */
    currency: string | null;
    /**
     * Rate of the fixed leg of an IRS instrument requested by counterparty (IRS
     * compressions/lists)
     */
    fixedRate: string | null;
    /**
     * Instrument identifier as provided by the venue
     */
    instrumentId: string | null;
    /**
     * Instrument identifier type as provided by the venue for the benchmark
     */
    instrumentIdType: InstrumentIDType | null;
    instrumentName:   string | null;
    /**
     * The latest quoted ask price
     */
    latestQuotedAskPrice: string | null;
    /**
     * The latest quoted bid price
     */
    latestQuotedBidPrice: string | null;
    /**
     * The latest quoted mid price
     */
    latestQuotedMidPrice: string | null;
    /**
     * Minimum price tick allowed by the venue for specific instrument/inquiry, when specified
     */
    minPriceIncrement: string | null;
    /**
     * Unique identifier for the leg within the context of a given aggregateId
     */
    position: number;
    /**
     * Price type
     */
    priceType: PriceType | null;
    quantity:  string | null;
    /**
     * Ranking of dealer's last level against competition
     */
    quoteCompetitiveStatus: QuoteCompetitiveStatus | null;
    /**
     * T+ syntax, used to represent the settlement date of inquiry relative to today/trade date
     * (T). e.g. T+5 means 5 business days from today/trade date
     */
    relativeSettlementDate: string | null;
    /**
     * Settlement date of leg encoded as a number in YYYYMMDD format
     */
    settlementDate: number | null;
    /**
     * From dealer's perspective
     */
    side: Side;
    /**
     * Effective (start) date for a swap encoded as a number in YYYYMMDD format
     */
    swapEffectiveDate: number | null;
    /**
     * End date for a swap encoded as a number in YYYYMMDD format
     */
    swapEndDate: number | null;
    /**
     * Did counterparty trade with another dealer in a competitive inquiry
     */
    tradedAway: boolean | null;
    /**
     * (MarketAxess US only) Provided only to covering dealer on trace disseminated and MSRB
     * reported bonds excluding EUR, EM, and Preferred Stock. Only sent during TRACE reporting
     * hours (8am-6:30PM EST).
     */
    tradedLevel: string | null;
    /**
     * (MarketAxess US only) The price type of traded level ('Percentage', 'Spread', or 'Yield').
     */
    tradedLevelType: string | null;
    /**
     * The final price of the trade including fees
     */
    tradedPrice: string | null;
}

/**
 * Instrument identifier type as provided by the venue
 *
 * Instrument identifier type as provided by the venue for the benchmark
 */
export enum InstrumentIDType {
    Cusip = "CUSIP",
    Isin = "ISIN",
    NotSet = "NOT_SET",
    VenueSymbol = "VENUE_SYMBOL",
}

/**
 * Price type
 */
export enum PriceType {
    InterestRate = "INTEREST_RATE",
    NetPresentValue = "NET_PRESENT_VALUE",
    PercentOfPar = "PERCENT_OF_PAR",
    Spread = "SPREAD",
}

/**
 * Ranking of dealer's last level against competition
 */
export enum QuoteCompetitiveStatus {
    Best = "BEST",
    Cover = "COVER",
    CoverTied = "COVER_TIED",
    Tied = "TIED",
}

/**
 * From dealer's perspective
 */
export enum Side {
    Buy = "BUY",
    Sell = "SELL",
    Undisclosed = "UNDISCLOSED",
}

/**
 * Package type
 */
export enum PackageType {
    Compression = "COMPRESSION",
    Curve = "CURVE",
    Outright = "OUTRIGHT",
}

/**
 * Spot negotiation type
 */
export enum SpotNegotiationModel {
    Negotiated = "NEGOTIATED",
    NegotiatedDelayed = "NEGOTIATED_DELAYED",
    NegotiatedDelayedCustomerinitiated = "NEGOTIATED_DELAYED_CUSTOMERINITIATED",
    NegotiatedPriceconfirmation = "NEGOTIATED_PRICECONFIRMATION",
    Phonepriced = "PHONEPRICED",
    PhonepricedDelayed = "PHONEPRICED_DELAYED",
    Venuepriced = "VENUEPRICED",
    VenuepricedDelayed = "VENUEPRICED_DELAYED",
    VenuepricedPriceconfirmation = "VENUEPRICED_PRICECONFIRMATION",
}

export enum State {
    CurtainPeriod = "CURTAIN_PERIOD",
    CurtainQuoteStreaming = "CURTAIN_QUOTE_STREAMING",
    CustomerAccepted = "CUSTOMER_ACCEPTED",
    CustomerRejected = "CUSTOMER_REJECTED",
    CustomerTimedOut = "CUSTOMER_TIMED_OUT",
    DealerAccepted = "DEALER_ACCEPTED",
    DealerRejected = "DEALER_REJECTED",
    DealerTimedOut = "DEALER_TIMED_OUT",
    Done = "DONE",
    InquiryError = "INQUIRY_ERROR",
    LastLook = "LAST_LOOK",
    NewRequest = "NEW_REQUEST",
    NotDoneCustomerReject = "NOT_DONE_CUSTOMER_REJECT",
    NotDoneCustomerTimeout = "NOT_DONE_CUSTOMER_TIMEOUT",
    NotDoneDealerReject = "NOT_DONE_DEALER_REJECT",
    NotDoneDealerTimeout = "NOT_DONE_DEALER_TIMEOUT",
    PendingPriceConfirmation = "PENDING_PRICE_CONFIRMATION",
    PendingSpot = "PENDING_SPOT",
    PickedUpOnVenueUI = "PICKED_UP_ON_VENUE_UI",
    QuoteFirm = "QUOTE_FIRM",
    QuoteOtw = "QUOTE_OTW",
    QuoteRefreshRequested = "QUOTE_REFRESH_REQUESTED",
    QuoteSubject = "QUOTE_SUBJECT",
    Streaming = "STREAMING",
    TradedPendingFeeAllocation = "TRADED_PENDING_FEE_ALLOCATION",
}

/**
 * Regulatory qualifier of the trading venue
 */
export enum VenueType {
    Mtf = "MTF",
    OffVenue = "OFF_VENUE",
    Sef = "SEF",
}
