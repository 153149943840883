import { PricerState } from "./enums";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";

export function computePtmmValue(
    ptmmValue: DecimalNumber | null,
    aqPriceState: PricerState | null,
    aqMidPrice: string | null,
): DecimalNumber | null {
    if (aqPriceState === PricerState.Priced && aqMidPrice !== null) {
        return new ImmutableDecimalNumber(aqMidPrice);
    }

    if (ptmmValue !== null) {
        return new ImmutableDecimalNumber(ptmmValue);
    }

    return null;
}

export function computeManualPtmmValue(
    ptmmValue: DecimalNumber | null,
    aqPriceState: PricerState | null,
    aqMidPrice: string | null,
): DecimalNumber | null {
    if (aqPriceState === PricerState.Priced && aqMidPrice !== null) {
        return null;
    }

    if (ptmmValue !== null) {
        return new ImmutableDecimalNumber(ptmmValue);
    }

    return null;
}
