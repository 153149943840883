import { useMemo } from "react";
import { credit, CreditState, InquiryAction, maybeScheduleForDismissal, PricesAction, TicketAction } from "./CreditSlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

export interface CreditStateDispatch {
    upsert: (inquiry: InquiryAction) => void;
    upsertAll: (inquiries: InquiryAction[]) => void;
    closeLatestActionFailure: (ticketAction: TicketAction) => void;
    updateOnDemandPrices: (pricesAction: PricesAction) => void;
    setSelectedPrimaryInquiryTicketId: (selectedPrimaryInquiryTicketId: number | null) => void;
}

export const useCreditStateDispatch = (): CreditStateDispatch => {
    const dispatch = useDispatch<ThunkDispatch<{ credit: CreditState }, unknown, AnyAction>>();
    return useMemo(
        () => ({
            upsert: (inquiry) => {
                dispatch(credit.actions.upsert(inquiry));
                void dispatch(maybeScheduleForDismissal(inquiry.inquiry));
            },
            // TODO: do we double dispatch here as well?
            upsertAll: (inquiries) => dispatch(credit.actions.upsertAll(inquiries)),
            closeLatestActionFailure: (ticketAction) => dispatch(credit.actions.closeLatestActionFailure(ticketAction)),
            updateOnDemandPrices: (priceAction) => dispatch(credit.actions.updateOnDemandPrices(priceAction)),
            setSelectedPrimaryInquiryTicketId: (selectedPrimaryInquiryTicketId) =>
                dispatch(credit.actions.setSelectedPrimaryInquiryTicketId({ selectedPrimaryInquiryTicketId })),
        }),
        [dispatch],
    );
};
