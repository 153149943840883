import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DisplayState, PricerState, PriceValidity, SelectedDriver } from "../irs-domain";
import {
    clickWasOnSelectedDriver,
    DataMatrixButtonsContainer,
    dataMatrixConfig,
    DataMatrixContainer,
    getDataMatrixContent,
    PriceDriverCallbacks,
    PriceDriverConfigRfm,
    PriceDriverPrices,
} from "./irs-price-driver";
import {
    DataMatrix,
    DataMatrixCellData,
    DataMatrixCellPosition,
    DataMatrixModifiers,
    DataMatrixSelectedCell,
    RadioBar,
    Text,
} from "@transficc/components";
import { useClock } from "@transficc/trader-desktop-application-context";

interface IRSPriceDriverPrices {
    selectedDriver: SelectedDriver | null;
    prices: PriceDriverPrices;
}

export interface IRSPriceDriverProps {
    config: PriceDriverConfigRfm;
    model: {
        autoQuotePriceValidity: PriceValidity | null;
        autoQuoteDisplayState: DisplayState;
        autoQuoteErrorMessage: string | null;
        autoQuotePricerState: PricerState | null;
        disabled: boolean;
        leftSide: IRSPriceDriverPrices;
        rightSide: IRSPriceDriverPrices;
    };
    callbacks: {
        leftSide: PriceDriverCallbacks;
        rightSide: PriceDriverCallbacks;
    };
}

export const IrsRfmPriceDriver: React.FC<IRSPriceDriverProps> = ({ config, model, callbacks }) => {
    const leftSide = config.leftSideConfig.cellSelectionLabel;
    const rightSide = config.rightSideConfig.cellSelectionLabel;

    const [selectedSide, setSelectedSide] = useState<string>(leftSide);
    const [holdingCtrl, setHoldingCtrl] = useState(false);
    const inverseSelectedSide = selectedSide === leftSide ? rightSide : leftSide;

    const dataMatrixPaySelectedCell: DataMatrixCellPosition | null = useMemo(
        () =>
            model.leftSide.selectedDriver
                ? { rowNo: model.leftSide.selectedDriver.rowNo, colNo: model.leftSide.selectedDriver.columnNo }
                : null,
        [model.leftSide.selectedDriver],
    );
    const dataMatrixRcvSelectedCell: DataMatrixCellPosition | null = useMemo(
        () =>
            model.rightSide.selectedDriver
                ? { rowNo: model.rightSide.selectedDriver.rowNo, colNo: model.rightSide.selectedDriver.columnNo }
                : null,
        [model.rightSide.selectedDriver],
    );

    const priceDriverPrices = useMemo(() => {
        if (selectedSide === leftSide) {
            return model.leftSide.prices;
        } else {
            return model.rightSide.prices;
        }
    }, [leftSide, model.leftSide.prices, model.rightSide.prices, selectedSide]);

    const clock = useClock();

    const dataMatrixContent = getDataMatrixContent(
        {
            ...model,
            prices: priceDriverPrices,
        },
        clock,
    );

    useEffect(() => {
        const keydown = (event: KeyboardEvent): void => {
            if (event.key !== "Control") {
                return;
            }

            setHoldingCtrl(true);
        };
        const keyup = (event: KeyboardEvent): void => {
            if (event.key !== "Control") {
                return;
            }
            setHoldingCtrl(false);
        };

        document.addEventListener("keydown", keydown);
        document.addEventListener("keyup", keyup);

        return () => {
            document.removeEventListener("keydown", keydown);
            document.removeEventListener("keyup", keyup);
        };
    }, []);

    const onClickDataMatrixCell = useCallback(
        (cellPosition: DataMatrixCellPosition, ignoredCellData: DataMatrixCellData, modifiers: DataMatrixModifiers[]) => {
            let isClickingForPay = selectedSide === leftSide;
            if (modifiers.includes("CTRL")) {
                isClickingForPay = !isClickingForPay;
            }

            if (isClickingForPay) {
                if (clickWasOnSelectedDriver(cellPosition, dataMatrixPaySelectedCell)) {
                    callbacks.leftSide.clearDriver();
                } else {
                    callbacks.leftSide.selectDriver({ rowNo: cellPosition.rowNo, columnNo: cellPosition.colNo });
                }
            } else {
                if (clickWasOnSelectedDriver(cellPosition, dataMatrixRcvSelectedCell)) {
                    callbacks.rightSide.clearDriver();
                } else {
                    callbacks.rightSide.selectDriver({ rowNo: cellPosition.rowNo, columnNo: cellPosition.colNo });
                }
            }
        },
        [selectedSide, leftSide, dataMatrixPaySelectedCell, callbacks.leftSide, callbacks.rightSide, dataMatrixRcvSelectedCell],
    );

    const dataMatrixSelectedCells: DataMatrixSelectedCell[] = [];

    if (dataMatrixPaySelectedCell) {
        dataMatrixSelectedCells.push({
            pos: dataMatrixPaySelectedCell,
            config: {
                cellSelectionBorderWidth: "1",
                cellSelectionColor: config.leftSideConfig.cellSelectionColor,
                cellDisabledSelectionColor: { color: "gray", level: "400" },
                cellSelectionLabel: leftSide,
            },
        });
    }
    if (dataMatrixRcvSelectedCell) {
        dataMatrixSelectedCells.push({
            pos: dataMatrixRcvSelectedCell,
            config: {
                cellSelectionBorderWidth: "1",
                cellSelectionColor: config.rightSideConfig.cellSelectionColor,
                cellDisabledSelectionColor: { color: "gray", level: "400" },
                cellSelectionLabel: rightSide,
            },
        });
    }

    return (
        <DataMatrixContainer contentDirection="column">
            <Text srOnly>Price Matrix</Text>
            <DataMatrix
                config={dataMatrixConfig}
                data={dataMatrixContent}
                selectedCells={dataMatrixSelectedCells}
                onClickCell={onClickDataMatrixCell}
                disabledSelection={model.disabled}
            />
            <DataMatrixButtonsContainer>
                <Text srOnly>Price Matrix Side</Text>
                <RadioBar
                    data-testid="price-matrix-side"
                    values={[leftSide, rightSide]}
                    joined
                    styling={[
                        {
                            selectedBackgroundColor: config.leftSideConfig.cellSelectedBackgroundColor,
                            unselectedBackgroundColor: {
                                color: "transparent",
                            },
                            selectedForegroundColor: config.leftSideConfig.selectedForegroundColor,
                            unselectedForegroundColor: {
                                color: "white",
                            },
                        },
                        {
                            selectedBackgroundColor: config.rightSideConfig.cellSelectedBackgroundColor,
                            unselectedBackgroundColor: {
                                color: "transparent",
                            },
                            selectedForegroundColor: config.rightSideConfig.selectedForegroundColor,
                            // color: "white", // Make this a prop, curve it's black, compression it's white
                            unselectedForegroundColor: {
                                color: "white",
                            },
                        },
                    ]}
                    selectedValue={holdingCtrl ? inverseSelectedSide : selectedSide}
                    onClick={(value) => {
                        setSelectedSide(value);
                    }}
                    isDisabled={model.disabled}
                    buttonWidth={"24"}
                    buttonHeight={"6"}
                />
            </DataMatrixButtonsContainer>
        </DataMatrixContainer>
    );
};
