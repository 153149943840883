import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import * as IRSDomain from "../irs-domain";
import { formatQuantityToThreeDp, InquirySide, PriceType } from "../irs-domain";
import { colorByState, colorForSide } from "../irs-domain/colors";
import { getIncrements } from "../irs-domain/compression-derivations";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { Box, BoxProps, formatIntDateAsString, Text } from "@transficc/components";
import { useInquiryLegHeaderSelector, useSelectedLegDateAndSize } from "../irs-slice/irs-selectors";

export const LegContainer = styled(Box)`
    width: 30rem;
`;

export const DateAndSizeContainer = styled(Box).attrs<{ highlighted: Theme.Color | false }>(
    (props): BoxProps => ({
        width: "80",
        padding: "0.5",
        contentAlignment: "center",
        backgroundColor: props.highlighted || undefined,
        border: { width: "0.5", color: props.highlighted ? { color: "transparent" } : { color: "white" } },
    }),
)<{ highlighted: Theme.Color | false }>`
    &:not(:first-child) {
        margin-top: ${(props) => props.theme.spacing["2"]};
    }

    & > ${Text} {
        color: ${(props) => (props.highlighted ? props.theme.colors["black"] : props.theme.colors["white"])};
    }
`;

export const PaymentFrequenciesContainer = styled(Box).attrs(
    (): BoxProps => ({
        width: "full",
        contentDirection: "row",
        borderB: { width: "1", color: { color: "transparent" } },
    }),
)``;

export const PaymentFrequenciesSeparator = styled(Box).attrs(
    (): BoxProps => ({
        width: "1",
        height: "full",
        backgroundColor: { color: "transparent" },
    }),
)``;

export const PaymentFrequencyContainer = styled(Box).attrs(
    (): BoxProps => ({
        contentDirection: "column",
        contentAlignment: "center",
        contentJustification: "center",
        width: "full",
        padding: "0.5",
    }),
)``;

export const CustomInstrumentIndicator: React.FC<{ isCustom: boolean }> = ({ isCustom }) => {
    return (
        <Box
            contentDirection={"row"}
            contentAlignment={"center"}
            contentJustification={"center"}
            width={"full"}
            padding={"0.5"}
            backgroundColor={isCustom ? { color: "warning", level: "300" } : { color: "cyan", level: "700" }}
        >
            <Text size={"xl"} textAlignment={"center"} textColor={isCustom ? { color: "black" } : { color: "white" }}>
                {isCustom ? "Custom" : "Standard"}
            </Text>
        </Box>
    );
};

const getFontSizeFromStringLength = (length: number): Theme.FontSizes => {
    switch (true) {
        case length > 41:
            return "base";
        case length > 37:
            return "lg";
        case length > 31:
            return "xl";
        default:
            return "2xl";
    }
};

export const InstrumentProduct: React.FC<{ side: InquirySide; instrumentName: string }> = ({ side, instrumentName }) => {
    const productId = `${instrumentName}`.trim();
    const textSize = getFontSizeFromStringLength(productId.length);
    const sideColor: Theme.Color = colorForSide(side);

    return (
        <Box
            contentDirection={"row"}
            contentAlignment={"center"}
            contentJustification={"center"}
            width={"full"}
            height={"12"}
            padding={"2"}
            backgroundColor={sideColor}
        >
            <Text
                data-testid="product-id"
                size={textSize}
                textAlignment={"center"}
                textColor={{ color: "black" }}
                title={productId}
                truncateWithEllipsis
            >
                {productId}
            </Text>
        </Box>
    );
};

interface PaymentFrequenciesProps {
    inquiryState: IRSDomain.InquiryState;
    fixedPeriod: string;
    floatPeriod: string;
    fixedRate: string | null;
}

export const PaymentFrequencies: React.FC<PaymentFrequenciesProps> = ({ inquiryState, fixedPeriod, floatPeriod, fixedRate }) => {
    const { inquiryLegPaymentInformationBackgroundColor } = colorByState(inquiryState);
    const fixedRateStr = useMemo(() => (fixedRate ? `Fixed @ ${fixedRate}%` : "Fixed"), [fixedRate]);
    return (
        <PaymentFrequenciesContainer>
            <PaymentFrequencyContainer backgroundColor={inquiryLegPaymentInformationBackgroundColor}>
                <Text srOnly>Fixed Rate</Text>
                <Text>{fixedRateStr}</Text>
                <Text data-testid={"fixed-period"} weight={"black"}>
                    {fixedPeriod}
                </Text>
            </PaymentFrequencyContainer>
            <PaymentFrequenciesSeparator>&nbsp;</PaymentFrequenciesSeparator>
            <PaymentFrequencyContainer backgroundColor={inquiryLegPaymentInformationBackgroundColor}>
                <Text>Float</Text>
                <Text data-testid={"float-period"} weight={"black"}>
                    {floatPeriod}
                </Text>
            </PaymentFrequencyContainer>
        </PaymentFrequenciesContainer>
    );
};

export const DateAndSize: React.FC<{ legPosition: number }> = ({ legPosition }) => {
    const { quantity, side, effectiveDate, terminationDate } = useSelectedLegDateAndSize(legPosition);

    const dateHighlightColor: Theme.Color = { color: "warning", level: "400" };
    const sideColor: Theme.Color = colorForSide(side);
    const effectiveDateFormatted = useMemo(() => {
        return formatIntDateAsString(effectiveDate);
    }, [effectiveDate]);
    const terminationDateFormatted = useMemo(() => {
        return formatIntDateAsString(terminationDate);
    }, [terminationDate]);
    return (
        <Box width={"full"} contentDirection={"column"} contentAlignment={"center"}>
            <DateAndSizeContainer highlighted={dateHighlightColor}>
                <Text data-testid={"effective-date"} size={"2xl"}>
                    {effectiveDateFormatted}
                </Text>
            </DateAndSizeContainer>
            <DateAndSizeContainer highlighted={false}>
                <Text data-testid={"termination-date"} size={"2xl"}>
                    {terminationDateFormatted}
                </Text>
            </DateAndSizeContainer>
            <DateAndSizeContainer highlighted={sideColor}>
                <Text size={"2xl"}>{formatQuantityToThreeDp(quantity)}</Text>
            </DateAndSizeContainer>
        </Box>
    );
};

export interface UsePriceIncrementButtonsView {
    spreadMinPriceIncrement: DecimalNumber;
    rateTickSize: DecimalNumber;
    spreadTickSize: DecimalNumber;
    onNewTickSizeSelected: (newIndex: number) => void;
    tickSizeLabels: [string, string, string];
}

export interface TickSizesAndPriceIncrements {
    spreadMinPriceIncrement: DecimalNumber;
    tickSizeLabels: [string, string, string];
    tickSizeValues: [DecimalNumber, DecimalNumber, DecimalNumber];
}

export const calculateNPVTickSizesAndPriceIncrements = (
    minPriceIncrementForLeg: DecimalNumber,
    minPriceIncrementForIncrementButtons: DecimalNumber,
): TickSizesAndPriceIncrements => {
    const { incrementLabels, incrementValues } = getIncrements(minPriceIncrementForIncrementButtons);
    return {
        spreadMinPriceIncrement: minPriceIncrementForLeg,
        tickSizeLabels: incrementLabels,
        tickSizeValues: incrementValues,
    };
};

export const calculateInterestRateTickSizesAndPriceIncrements = (
    minPriceIncrementForLeg: DecimalNumber,
    minPriceIncrementForIncrementButtons: DecimalNumber,
): TickSizesAndPriceIncrements => {
    const spreadMinPriceIncrement = minPriceIncrementForLeg.multiplyBy(100);
    const tickSizeLabels: [string, string, string] = [
        new ImmutableDecimalNumber(minPriceIncrementForIncrementButtons).multiplyBy(100).toString(),
        "0.5",
        "1.0",
    ];
    const tickSizeValues: [DecimalNumber, DecimalNumber, DecimalNumber] = [
        new ImmutableDecimalNumber(minPriceIncrementForIncrementButtons).multiplyBy(100),
        new ImmutableDecimalNumber("0.5"),
        new ImmutableDecimalNumber("1.0"),
    ];
    return { spreadMinPriceIncrement, tickSizeLabels, tickSizeValues };
};

export const usePriceAndSpreadIncrements = (
    priceType: IRSDomain.PriceType,
    minPriceIncrementForLeg: DecimalNumber,
    minPriceIncrementForIncrementButtons: DecimalNumber,
): UsePriceIncrementButtonsView => {
    const { spreadMinPriceIncrement, tickSizeLabels, tickSizeValues } = useMemo(() => {
        if (priceType === PriceType.NetPresentValue) {
            return calculateNPVTickSizesAndPriceIncrements(minPriceIncrementForLeg, minPriceIncrementForIncrementButtons);
        } else {
            return calculateInterestRateTickSizesAndPriceIncrements(minPriceIncrementForLeg, minPriceIncrementForIncrementButtons);
        }
    }, [priceType, minPriceIncrementForLeg, minPriceIncrementForIncrementButtons]);

    const [rateTickSizeByPriceIncrementButton, setRateTickSizeByPriceIncrementButton] = useState(
        priceType === PriceType.NetPresentValue ? tickSizeValues[0] : tickSizeValues[0].divideBy(100),
    );
    const [spreadTickSizeByPriceIncrementButton, setSpreadTickSizeByPriceIncrementButton] = useState(tickSizeValues[0]);

    const onNewTickSizeSelected = useCallback(
        (newPriceIncrementIndex: number) => {
            if (priceType === PriceType.NetPresentValue) {
                const newIncrementValue = tickSizeValues[newPriceIncrementIndex];
                if (newIncrementValue !== undefined) {
                    setRateTickSizeByPriceIncrementButton(newIncrementValue);
                    setSpreadTickSizeByPriceIncrementButton(newIncrementValue);
                }
            } else {
                const newIncrementInBasisPoints = tickSizeValues[newPriceIncrementIndex];
                if (newIncrementInBasisPoints !== undefined) {
                    setRateTickSizeByPriceIncrementButton(newIncrementInBasisPoints.divideBy(100));
                    setSpreadTickSizeByPriceIncrementButton(newIncrementInBasisPoints);
                }
            }
        },
        [priceType, tickSizeValues],
    );

    return {
        rateTickSize: rateTickSizeByPriceIncrementButton,
        spreadTickSize: spreadTickSizeByPriceIncrementButton,
        onNewTickSizeSelected,
        spreadMinPriceIncrement,
        tickSizeLabels,
    };
};

export const LegInformationHeader: React.FC<{ legPosition: number }> = ({ legPosition }) => {
    const headerState = useInquiryLegHeaderSelector(legPosition);
    return (
        <>
            <CustomInstrumentIndicator isCustom={headerState.isCustom} />
            <InstrumentProduct side={headerState.side} instrumentName={headerState.instrumentName} />
            <PaymentFrequencies
                inquiryState={headerState.inquiryState}
                fixedPeriod={headerState.fixedPeriod}
                floatPeriod={headerState.floatPeriod}
                fixedRate={headerState.fixedRate}
            />
        </>
    );
};
