import React from "react";
import { Box } from "../box";
import { Text } from "../text";
import styled from "styled-components";

import CAD from "./flags/CAD.svg";
import USD from "./flags/USD.svg";
import GBP from "./flags/GBP.svg";
import EUR from "./flags/EUR.svg";
import CHF from "./flags/CHF.svg";
import AUD from "./flags/AUD.svg";
import JPY from "./flags/JPY.svg";

const CurrencyDisplayContainer = styled.div`
    width: ${(props) => props.theme.spacing["20"]};
    height: ${(props) => props.theme.spacing["10"]};
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > * {
        width: 100%;
        height: 100%;
    }
`;

export interface CurrencyDisplayProps {
    isoCode: string;
}

export const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({ isoCode }) => {
    let innerContent: React.ReactNode;

    switch (isoCode.toUpperCase()) {
        case "CAD":
            innerContent = <img data-testid="inquiry-currency" src={CAD} alt="CAD" title="Canadian Dollar" />;
            break;
        case "USD":
            innerContent = <img data-testid="inquiry-currency" src={USD} alt="USD" title="United States Dollar" />;
            break;
        case "GBP":
            innerContent = <img data-testid="inquiry-currency" src={GBP} alt="GBP" title="British Pound Sterling" />;
            break;
        case "EUR":
            innerContent = <img data-testid="inquiry-currency" src={EUR} alt="EUR" title="Euro" />;
            break;
        case "CHF":
            innerContent = <img data-testid="inquiry-currency" src={CHF} alt="CHF" title="Swiss Franc" />;
            break;
        case "AUD":
            innerContent = <img data-testid="inquiry-currency" src={AUD} alt="AUD" title="Australian Dollar" />;
            break;
        case "JPY":
            innerContent = <img data-testid="inquiry-currency" src={JPY} alt="JPY" title="Japanese Yen" />;
            break;

        default:
            innerContent = (
                <Box
                    border={{ color: { color: "gray", level: "300" }, width: "px", style: "dashed" }}
                    contentAlignment="center"
                    contentJustification="center"
                    padding="2"
                >
                    <Text size="sm">{isoCode}</Text>
                </Box>
            );
            break;
    }

    return <CurrencyDisplayContainer>{innerContent}</CurrencyDisplayContainer>;
};
