import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { IRSInquiry, IRSLeg, SelectedDriver } from "./models";

export function getSelectedDriverValueForLeg(selectedDriver: SelectedDriver | null, leg: IRSLeg<"curve">): string | null {
    const driver = selectedDriver ? selectedDriver : undefined;
    return getDriverValueForLeg(leg, driver);
}

export function getDriverValueForLeg(leg: IRSLeg<"curve" | "outright">, driver?: SelectedDriver): string | null {
    if (driver) {
        const columnNo = driver.columnNo;
        const rowNo = driver.rowNo;

        if (rowNo === 0) {
            return leg.lastQuotedPrices[columnNo] ?? null;
        } else if (rowNo === 1) {
            return leg.autoQuotePrices[columnNo] ?? null;
        } else if (rowNo === 2) {
            return leg.venuePrices[columnNo] ?? null;
        }
    }
    return null;
}

export function selectedDriverHasValueForPackage(inquiry: IRSInquiry<"compression">, selectedDriver: SelectedDriver | null): boolean {
    if (selectedDriver?.rowNo === 0) {
        switch (selectedDriver.columnNo) {
            case 0:
                return inquiry.latestQuotedPackageFee !== null;
            case 1:
                return inquiry.latestQuotedMidPackageFee !== null;
            case 2:
                return inquiry.latestQuotedOppositePackageFee !== null;
            default:
                return false;
        }
    } else if (selectedDriver?.rowNo === 1) {
        switch (selectedDriver.columnNo) {
            case 0:
                return inquiry.autoQuotePackagePrice !== null;
            case 1:
                return inquiry.autoQuotePackageMidPrice !== null;
            case 2:
                return inquiry.autoQuoteOppositePackagePrice !== null;
            default:
                return false;
        }
    } else if (selectedDriver?.rowNo === 2 && selectedDriver.columnNo === 1) {
        return inquiry.packageMidCompositePrice != null;
    }
    return false;
}

export function isSelectedDriverAutoQuotePriceSource(selectedDriver: SelectedDriver | null): boolean {
    return selectedDriver?.rowNo === 1;
}

export function isAutoQuotePriceSource(priceSource: ActionPublisher.PriceSource | null): boolean {
    switch (priceSource) {
        case ActionPublisher.PriceSource.AutoquoteBid:
        case ActionPublisher.PriceSource.AutoquoteMid:
        case ActionPublisher.PriceSource.AutoquoteAsk:
            return true;
        default:
            return false;
    }
}

export function getPriceSource(selectedDriver: SelectedDriver | null): ActionPublisher.PriceSource | null {
    switch (selectedDriver?.rowNo) {
        case 0: {
            switch (selectedDriver?.columnNo) {
                case 0:
                    return ActionPublisher.PriceSource.QuotedBid;
                case 1:
                    return ActionPublisher.PriceSource.QuotedMid;
                case 2:
                    return ActionPublisher.PriceSource.QuotedAsk;
                default:
                    return null;
            }
        }
        case 1: {
            switch (selectedDriver?.columnNo) {
                case 0:
                    return ActionPublisher.PriceSource.AutoquoteBid;
                case 1:
                    return ActionPublisher.PriceSource.AutoquoteMid;
                case 2:
                    return ActionPublisher.PriceSource.AutoquoteAsk;
                default:
                    return null;
            }
        }
        case 2: {
            switch (selectedDriver?.columnNo) {
                case 0:
                    return ActionPublisher.PriceSource.VenueBid;
                case 1:
                    return ActionPublisher.PriceSource.VenueMid;
                case 2:
                    return ActionPublisher.PriceSource.VenueAsk;
                default:
                    return null;
            }
        }

        default:
            return null;
    }
}

export function getSelectedDriver(priceSource: ActionPublisher.PriceSource | null): SelectedDriver | null {
    switch (priceSource) {
        case null:
            return null;
        case ActionPublisher.PriceSource.QuotedBid:
            return { rowNo: 0, columnNo: 0 };
        case ActionPublisher.PriceSource.QuotedMid:
            return { rowNo: 0, columnNo: 1 };
        case ActionPublisher.PriceSource.QuotedAsk:
            return { rowNo: 0, columnNo: 2 };
        case ActionPublisher.PriceSource.AutoquoteBid:
            return { rowNo: 1, columnNo: 0 };
        case ActionPublisher.PriceSource.AutoquoteMid:
            return { rowNo: 1, columnNo: 1 };
        case ActionPublisher.PriceSource.AutoquoteAsk:
            return { rowNo: 1, columnNo: 2 };
        case ActionPublisher.PriceSource.VenueBid:
            return { rowNo: 2, columnNo: 0 };
        case ActionPublisher.PriceSource.VenueMid:
            return { rowNo: 2, columnNo: 1 };
        case ActionPublisher.PriceSource.VenueAsk:
            return { rowNo: 2, columnNo: 2 };
    }
}
