import { useDisplayedInquiry } from "../credit-slice/useCreditSliceSelectors";
import { BenchmarkHedge, HedgingType, PriceType, SpotNegotiationModel } from "../credit-domain";
import { Box, BoxProps, Text, TextProps } from "@transficc/components";
import styled from "styled-components";
import { TimezoneProvider } from "@transficc/infrastructure";
import { DateTime } from "luxon";

const getHedgingTypeDisplayText = (hedgingType: HedgingType | null): string => {
    switch (hedgingType) {
        case HedgingType.Spot:
            return "SPOT";
        case HedgingType.Cross:
            return "CROSS";
        case null:
            return "Unknown";
        default:
            hedgingType satisfies never;
            return "Unknown";
    }
};

const getBenchmarkHedgeSection = (benchmarkHedge: BenchmarkHedge | null): JSX.Element | null => {
    return benchmarkHedge != null ? (
        <SpreadInfoSectionText
            textColor={benchmarkHedge === BenchmarkHedge.Manual ? { color: "gold", level: "500" } : undefined}
            data-testid={"benchmark-hedge"}
        >
            {benchmarkHedge}
        </SpreadInfoSectionText>
    ) : null;
};

const SpreadInfoContainer = styled(Box).attrs(
    (): BoxProps => ({
        height: "10",
        contentDirection: "row",
        width: "full",
    }),
)``;

const SpreadInfoSection = styled(Box).attrs(
    (): BoxProps => ({
        border: { width: "px", color: { color: "cyan", level: "400" } },
        contentAlignment: "center",
        contentJustification: "center",
        paddingL: "1",
        paddingR: "1",
        contentDirection: "row",
    }),
)<{ flex: number }>`
    flex: ${(props) => props.flex};
    max-width: 45%;
`;

const SpreadInfoSectionText = styled(Text).attrs(
    (): TextProps => ({
        truncateWithEllipsis: true,
        size: "sm",
        weight: "bold",
    }),
)``;

interface CreditInquirySpreadInfoProps {
    timezoneProvider: TimezoneProvider;
}

const getSpottingTimeDisplayText = (
    timezoneProvider: TimezoneProvider,
    spotNegotiationModel: SpotNegotiationModel | null,
    spotRequestTimeNanos: string | null,
): string => {
    if (!spotNegotiationModel) {
        return "Unknown";
    }
    switch (spotNegotiationModel) {
        case SpotNegotiationModel.VenuePriced:
            return "Immediate";
        case SpotNegotiationModel.VenuePricedDelayed: {
            if (!spotRequestTimeNanos) {
                return "Later";
            }
            const spotRequestTimeSeconds = spotRequestTimeNanos.slice(0, spotRequestTimeNanos.length - 9);
            return DateTime.fromSeconds(parseInt(spotRequestTimeSeconds), { zone: "UTC" })
                .setZone(timezoneProvider.getTimezone())
                .toFormat("h:mma");
        }
        default:
            spotNegotiationModel satisfies never;
            throw new Error("Unsupported spot negotiation model");
    }
};

export const CreditInquirySpreadInfo: React.FC<CreditInquirySpreadInfoProps> = ({ timezoneProvider }) => {
    const inquiry = useDisplayedInquiry();

    if (inquiry.leg.priceType !== PriceType.Spread) {
        return <SpreadInfoContainer />;
    }

    return (
        <SpreadInfoContainer data-testid="spread-information">
            <SpreadInfoSection
                flex={2}
                title={"Hedging Type"}
                backgroundColor={inquiry.hedgingType === HedgingType.Cross ? { color: "white" } : { color: "cyan", level: "900" }}
            >
                <Box contentDirection={"column"} contentAlignment={"center"}>
                    <SpreadInfoSectionText
                        textColor={inquiry.hedgingType === HedgingType.Cross ? { color: "black" } : undefined}
                        data-testid={"hedging-type"}
                    >
                        {getHedgingTypeDisplayText(inquiry.hedgingType)}
                    </SpreadInfoSectionText>

                    {getBenchmarkHedgeSection(inquiry.benchmarkHedge)}
                </Box>
            </SpreadInfoSection>
            <SpreadInfoSection
                flex={4}
                borderL={"none"}
                title={`Benchmark Name: ${inquiry.leg.benchmarkName ?? "Unknown"}`}
                data-testid="benchmark-name"
                backgroundColor={{ color: "cyan", level: "900" }}
            >
                <SpreadInfoSectionText size={"xs"}>{inquiry.leg.benchmarkName ?? "Unknown"}</SpreadInfoSectionText>
            </SpreadInfoSection>
            <SpreadInfoSection flex={2} borderL={"none"} title={"Spotting Model"} backgroundColor={{ color: "cyan", level: "900" }}>
                <Box contentDirection={"column"} contentAlignment={"center"}>
                    <SpreadInfoSectionText size={"xs"} data-testid="spotting-model">
                        Auto-Spot
                    </SpreadInfoSectionText>
                    <SpreadInfoSectionText size={"xs"} data-testid="spotting-time">
                        {getSpottingTimeDisplayText(timezoneProvider, inquiry.spotNegotiationModel, inquiry.spotRequestTimeNanos)}
                    </SpreadInfoSectionText>
                </Box>
            </SpreadInfoSection>
        </SpreadInfoContainer>
    );
};
