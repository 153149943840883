import React, { useContext, useMemo } from "react";
import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { SessionTransport } from "./SessionTransport";
import { ActionIdGenerator } from "./ActionIdGenerator";
import { Logger } from "./Logger";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { TraderDesktopElectronApi } from "@transficc/trader-desktop-electron-api";
import { MetricPublisher } from "./MetricPublisher";

interface ApplicationContext {
    electronApi: TraderDesktopElectronApi | null;
    clock: EpochClock | null;
    timezoneProvider: TimezoneProvider | null;
    sessionTransport: SessionTransport | null;
    actionIdGenerator: ActionIdGenerator | null;
    appVersion: string | null;
    logger: Logger | null;
    environmentConfig: TraderDesktopProtocol.EnvironmentConfig | null;
    metricPublisher: MetricPublisher | null;
}

const Context = React.createContext<ApplicationContext>({
    electronApi: null,
    actionIdGenerator: null,
    appVersion: null,
    clock: null,
    logger: null,
    sessionTransport: null,
    timezoneProvider: null,
    environmentConfig: null,
    metricPublisher: null,
});

export const ApplicationContextProvider: React.FC<React.PropsWithChildren<ApplicationContext>> = ({
    children,
    electronApi,
    actionIdGenerator,
    appVersion,
    clock,
    logger,
    sessionTransport,
    timezoneProvider,
    environmentConfig,
    metricPublisher,
}) => {
    const value = useMemo(
        () => ({
            electronApi,
            actionIdGenerator,
            appVersion,
            clock,
            logger,
            sessionTransport,
            timezoneProvider,
            environmentConfig,
            metricPublisher,
        }),
        [actionIdGenerator, appVersion, clock, electronApi, environmentConfig, logger, metricPublisher, sessionTransport, timezoneProvider],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useElectronApi = (): TraderDesktopElectronApi => {
    const context = useContext(Context);

    if (!context.electronApi) {
        throw new Error();
    }

    return context.electronApi;
};

export const useClock = (): EpochClock => {
    const context = useContext(Context);

    if (!context.clock) {
        throw new Error();
    }

    return context.clock;
};

export const useTimezoneProvider = (): TimezoneProvider => {
    const context = useContext(Context);

    if (!context.timezoneProvider) {
        throw new Error();
    }

    return context.timezoneProvider;
};

export const useActionIdGenerator = (): ActionIdGenerator => {
    const context = useContext(Context);

    if (!context.actionIdGenerator) {
        throw new Error();
    }

    return context.actionIdGenerator;
};

export const useLogger = (): Logger => {
    const context = useContext(Context);

    if (!context.logger) {
        throw new Error();
    }

    return context.logger;
};

export const useAppVersion = (): string => {
    const context = useContext(Context);

    if (!context.appVersion) {
        throw new Error();
    }

    return context.appVersion;
};

export const useSessionTransport = (): SessionTransport => {
    const context = useContext(Context);

    if (!context.sessionTransport) {
        throw new Error();
    }

    return context.sessionTransport;
};

export const useEnvironmentConfig = (): TraderDesktopProtocol.EnvironmentConfig => {
    const context = useContext(Context);

    if (!context.environmentConfig) {
        throw new Error();
    }

    return context.environmentConfig;
};

export const useMetricPublisher = (): MetricPublisher => {
    const context = useContext(Context);

    if (!context.metricPublisher) {
        throw new Error();
    }

    return context.metricPublisher;
};
