import * as IRSDomain from "../irs-domain";
import {
    backgroundColorFor,
    backgroundColorForStalePrices,
    BidMidAsk,
    DisplayState,
    displayTextFor,
    errorTextForStalePrices,
    foregroundColorFor,
    foregroundColorForStalePrices,
    PricerState,
    PriceValidity,
    SelectedDriver,
} from "../irs-domain";
import styled from "styled-components";
import { EpochClock } from "@transficc/infrastructure";
import {
    Box,
    DataMatrixCellData,
    DataMatrixCellPosition,
    dataMatrixCellPositionEq,
    DataMatrixProps,
    formatWithCommas,
} from "@transficc/components";

const dataMatrixCellWidth: Theme.SpacingLevels = "24";

export const DataMatrixContainer = styled(Box)`
    margin-left: -${(props) => props.theme.spacing[dataMatrixCellWidth]};
`;

export const DataMatrixButtonsContainer = styled(Box)`
    padding-left: ${(props) => props.theme.spacing[dataMatrixCellWidth]};
    padding-top: ${(props) => props.theme.spacing["2"]};
    margin: auto;
`;

const DEFAULT_CELL_FONT_SIZE: Theme.FontSizes = "sm";

export const dataMatrixConfig: DataMatrixProps["config"] = {
    cellWidth: dataMatrixCellWidth,
    cellHeight: "8",
    cellFontSize: DEFAULT_CELL_FONT_SIZE,
    cellBorderWidth: "0.5",
    cellBackgroundColor: { color: "info", level: "900" },
    cellForegroundColor: { color: "white" },
    rowLabelPosition: "left",
    columnConfig: [{}, {}, {}],
    rowConfig: [{ label: "Q" }, { label: "AQ" }, { label: "V" }],
    allowClickingNullCells: false,
};

export const calculateFontSize = (value: string | null): Theme.FontSizes => {
    const mediumValueLengthThreshold = 9;
    const largeValueLengthThreshold = 11;

    let fontSize: Theme.FontSizes = DEFAULT_CELL_FONT_SIZE;
    if (value !== null && value.length > largeValueLengthThreshold) {
        fontSize = "2xs";
    } else if (value !== null && value.length > mediumValueLengthThreshold) {
        fontSize = "xs";
    }
    return fontSize;
};

const autoQuoteDataMatrixRow = (
    prices: IRSDomain.BidMidAsk,
    pricerState: PricerState | null,
    displayState: DisplayState,
    validity: PriceValidity | null,
    clock: EpochClock,
    isInquiryFinished: boolean,
    errorMessage?: string,
): DataMatrixCellData[] => {
    return prices.map((price) => {
        const displayText = displayTextFor(displayState);
        if (displayText) {
            return {
                value: displayText,
                secondaryText: errorMessage,
                cellForegroundColor: foregroundColorFor(displayState),
                cellBackgroundColor: backgroundColorFor(displayState),
            };
        }

        const text = formatWithCommas(price);
        const fontSize = calculateFontSize(text);
        return {
            value: text,
            secondaryText: errorTextForStalePrices(price, validity, pricerState, isInquiryFinished, clock, errorMessage),
            cellFontSize: fontSize,
            cellForegroundColor: foregroundColorForStalePrices(price, validity, isInquiryFinished, clock),
            cellBackgroundColor: backgroundColorForStalePrices(price, validity, isInquiryFinished, clock),
        };
    });
};

export const dataMatrixRow = (prices: BidMidAsk): DataMatrixCellData[] => {
    return prices.map((price) => {
        const value = formatWithCommas(price);
        const fontSize = calculateFontSize(value);

        return {
            value: value,
            cellForegroundColor: foregroundColorFor(),
            cellBackgroundColor: backgroundColorFor(undefined),
            cellFontSize: fontSize,
        };
    });
};

export const clickWasOnSelectedDriver = (
    clickedCell: DataMatrixCellPosition,
    dataMatrixSelectedCell: DataMatrixCellPosition | null,
): boolean => {
    return dataMatrixSelectedCell ? dataMatrixCellPositionEq(clickedCell, dataMatrixSelectedCell) : false;
};

export interface PriceDriverPrices {
    autoQuotePrices: BidMidAsk;
    venuePrices: BidMidAsk;
    latestQuotedPrices: BidMidAsk;
}

interface PriceDriverModel {
    autoQuotePricerState: PricerState | null;
    autoQuoteDisplayState: DisplayState;
    autoQuoteErrorMessage: string | null;
    disabled: boolean;
    prices: PriceDriverPrices;
    autoQuotePriceValidity: PriceValidity | null;
}

export interface PriceDriverConfigRfm {
    leftSideConfig: sideConfig;
    rightSideConfig: sideConfig;
}

interface sideConfig {
    cellSelectionColor: Theme.Color;
    cellSelectedBackgroundColor: Theme.Color;
    cellSelectionLabel: string;
    selectedForegroundColor: Theme.Color;
}

export interface PriceDriverCallbacks {
    selectDriver: (value: SelectedDriver) => void;
    clearDriver: () => void;
}

export const getDataMatrixContent = (
    model: PriceDriverModel,
    clock: EpochClock,
): [DataMatrixCellData[], DataMatrixCellData[], DataMatrixCellData[]] => {
    return [
        dataMatrixRow(model.prices.latestQuotedPrices),
        autoQuoteDataMatrixRow(
            model.prices.autoQuotePrices,
            model.autoQuotePricerState,
            model.autoQuoteDisplayState,
            model.autoQuotePriceValidity,
            clock,
            model.disabled,
            model.autoQuoteErrorMessage ?? undefined,
        ),
        dataMatrixRow(model.prices.venuePrices),
    ];
};
