import { createSlice } from "@reduxjs/toolkit";
import { RootState, useStateSelector } from "../hooks/useStateSelector";

const loadingStatusSlice = createSlice({
    name: "loadingStatus",
    initialState: {
        isLoading: true,
    },
    reducers: {
        loadingComplete(state) {
            state.isLoading = false;
        },
    },
});

export const loadingStatusReducer = loadingStatusSlice.reducer;
export const { loadingComplete } = loadingStatusSlice.actions;
export const useIsLoading = (): boolean => useStateSelector((state: RootState) => state.loadingStatus.isLoading);
