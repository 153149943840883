import React from "react";
import { NumberInput } from "@transficc/components";
import { PriceType } from "../credit-domain";
import { CreditTicket } from "../credit-slice/useCreditSliceSelectors";
import { EpochClock } from "@transficc/infrastructure";
import { isSelectedPriceStale } from "../credit-domain/stale-prices";
import { backgroundColorFor } from "../credit-domain/enums";

export interface CreditPriceInputProps {
    ticketId: number;
    forPriceType: PriceType;
    clock: EpochClock;
}

export const CreditPriceInput: React.FC<CreditPriceInputProps> = ({ forPriceType, ticketId, clock }) => {
    const driverState = CreditTicket.useDriverState(ticketId);
    const { autoQuotePriceState, autoQuotePricesGenerationTimestampNanos, autoQuotePricesValidForNanos } =
        CreditTicket.useAutoQuotePriceState(ticketId);

    const {
        value,
        onValueChange,
        onDirtyChange,
        minPriceIncrement,
        isPrimaryPriceType,
        isDisabled,
        shouldBeHighlighted,
        driverStateDisplayText,
        isDisplayingDriverState,
    } = CreditTicket.usePriceValueForPriceType(ticketId, forPriceType);

    const driverStateBackgroundColor = driverState === "fromAQ" ? backgroundColorFor(autoQuotePriceState) : undefined;

    return (
        <NumberInput
            model={{
                value: isPrimaryPriceType ? value : null,
                disabled: isDisabled,
                displayType: isDisplayingDriverState ? "box" : "input",
                boxDisplayText: driverStateDisplayText,
                boxBackgroundColor: driverStateBackgroundColor,
                selected: shouldBeHighlighted,
                increment: minPriceIncrement,
                invalid: isPrimaryPriceType
                    ? isSelectedPriceStale(
                          autoQuotePriceState,
                          autoQuotePricesGenerationTimestampNanos,
                          autoQuotePricesValidForNanos,
                          driverState,
                          clock,
                      )
                    : false,
            }}
            config={{
                snapToMultiplesOf: minPriceIncrement,
                size: "large",
                titleText: titleTextFor(forPriceType),
                titleTextSelector: titleTextFor(forPriceType),
                showTitleText: true,
                initialDisplayText: "-",
                invalidText: "Stale Price",
            }}
            callbacks={{
                onValueChange,
                onDirtyChange,
            }}
        />
    );
};

const titleTextFor = (priceType: PriceType): string => {
    switch (priceType) {
        case PriceType.PercentOfPar:
            return "Price";
        case PriceType.Spread:
            return "Spread";
        case PriceType.Yield:
            return "Yield";
        default:
            priceType satisfies never;
            throw new Error();
    }
};
