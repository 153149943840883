import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { Draft, produce } from "immer";
import { NumberInputCallbacks, NumberInputConfig, NumberInputModel } from "./number-input";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { formatPriceToDecimalNumber, formatPriceToString, formatWithCommas } from "../../formatters/formatters";

interface NumberInputReducerState {
    focused: boolean;
    inputDisplayText: string;
    inputDisplayTextWhenOriginallyFocused: string;
    modelValueWhenOriginallyFocused: DecimalNumber | null;
    needsCommit: boolean;
    valueToCommit: DecimalNumber | null;
    needsFocus: boolean;
}

type NumberInputReducerAction =
    | {
          type: "Increment";
          args: {
              currentValue: DecimalNumber | null;
              increment: DecimalNumber;
              snapToMultiplesOf: DecimalNumber;
          };
      }
    | {
          type: "Decrement";
          args: {
              currentValue: DecimalNumber | null;
              increment: DecimalNumber;
              snapToMultiplesOf: DecimalNumber;
          };
      }
    | { type: "UpdateDisplayText"; args: { newText: string } }
    | {
          type: "Commit";
          args: {
              currentValue: DecimalNumber | null;
              increment: DecimalNumber;
              initialDisplayText: string;
              snapToMultiplesOf: DecimalNumber;
          };
      }
    | { type: "ResetNeedsCommit" }
    | {
          type: "Rollback";
          args: {
              currentValue: DecimalNumber | null;
              initialDisplayText: string;
              snapToMultiplesOf: DecimalNumber;
          };
      }
    | { type: "Focus" }
    | { type: "ResetNeedsFocus" }
    | { type: "SetFocused"; focused: boolean; modelValueAtFocus: DecimalNumber | null };

export interface NumberInputReducerView {
    focused: boolean;
    invalid: boolean;
    inputDisplayText: string;
    onClickBox: (e: React.MouseEvent<HTMLElement>) => void;
    onChangeInput: (e: React.FormEvent<HTMLInputElement>) => void;
    onFocusInput: () => void;
    onBlurInput: () => void;
    onKeyDownInput: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClickTickDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onClickTickUp: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const numberInputInitialState = (args: { config: NumberInputConfig; model: NumberInputModel }): NumberInputReducerState => {
    return {
        focused: false,
        inputDisplayTextWhenOriginallyFocused: "",
        inputDisplayText: args.model.value ? args.model.value.toString() : args.config.initialDisplayText,
        modelValueWhenOriginallyFocused: null,
        needsCommit: false,
        valueToCommit: null,
        needsFocus: false,
    };
};

export function useNumberInputReducer(
    inputRef: React.RefObject<HTMLInputElement>,
    config: NumberInputConfig,
    model: NumberInputModel,
    callbacks: NumberInputCallbacks,
): NumberInputReducerView {
    const [state, dispatch] = useReducer(reducer, { config, model }, numberInputInitialState);

    const lastOnDirtyValue = useRef(false);

    const onClickTickDown = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            dispatch({
                type: "Decrement",
                args: {
                    currentValue: model.value,
                    increment: model.increment.negated(),
                    snapToMultiplesOf: config.snapToMultiplesOf.negated(),
                },
            });
        },
        [model.value, model.increment, config.snapToMultiplesOf],
    );

    const onClickTickUp = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            dispatch({
                type: "Increment",
                args: {
                    currentValue: model.value,
                    increment: model.increment,
                    snapToMultiplesOf: config.snapToMultiplesOf,
                },
            });
        },
        [model.value, model.increment, config.snapToMultiplesOf],
    );

    const onChangeInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (!/^-?[0-9.]*$/.test(e.currentTarget.value)) {
            return;
        }
        if (e.currentTarget.value.indexOf(".") !== e.currentTarget.value.lastIndexOf(".")) {
            return;
        }
        dispatch({
            type: "UpdateDisplayText",
            args: { newText: e.currentTarget.value },
        });
    }, []);

    const onKeyDownInput = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                dispatch({
                    type: "Commit",
                    args: {
                        currentValue: model.value,
                        increment: model.increment,
                        initialDisplayText: config.initialDisplayText,
                        snapToMultiplesOf: config.snapToMultiplesOf,
                    },
                });
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            } else if (e.key === "Escape") {
                dispatch({
                    type: "Rollback",
                    args: {
                        currentValue: state.modelValueWhenOriginallyFocused,
                        initialDisplayText: config.initialDisplayText,
                        snapToMultiplesOf: config.snapToMultiplesOf,
                    },
                });
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }
        },
        [
            config.initialDisplayText,
            config.snapToMultiplesOf,
            inputRef,
            model.increment,
            model.value,
            state.modelValueWhenOriginallyFocused,
        ],
    );

    const onBlurInput = useCallback(() => {
        // always dispatch commit - if we got here via escape or enter, we already committed or rolled back so this will be a noop
        dispatch({
            type: "Commit",
            args: {
                currentValue: model.value,
                increment: model.increment,
                initialDisplayText: config.initialDisplayText,
                snapToMultiplesOf: config.snapToMultiplesOf,
            },
        });
        dispatch({ type: "SetFocused", focused: false, modelValueAtFocus: null });
    }, [model.value, model.increment, config.initialDisplayText, config.snapToMultiplesOf]);

    const onClickBox = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            dispatch({ type: "Focus" });
            dispatch({ type: "SetFocused", focused: true, modelValueAtFocus: model.value });
        },
        [model.value],
    );

    const onFocusInput = useCallback(() => {
        dispatch({ type: "SetFocused", focused: true, modelValueAtFocus: model.value });
    }, [model.value]);

    // NOTE: we use this trick here due to the difference in dispatch priority of Redux vs useReducer
    // This dispatch to useReducer gets queued, and then when the onValueChange callback is called with an implementation
    // that dispatches to Redux, a re-render happens synchronously which causes this hook to be re-ran before the ResetNeedsCommit is processed,
    // causing an infinite render.
    // TODO: we really need to just re-write the way number-input works to remove this complexity
    const pendingCommit = useRef(false);

    useEffect(() => {
        if (state.needsCommit && state.valueToCommit) {
            if (pendingCommit.current) {
                return;
            }
            pendingCommit.current = true;
            dispatch({ type: "ResetNeedsCommit" });
            const onValueChange = callbacks.onValueChange;
            onValueChange(state.valueToCommit);
        } else {
            pendingCommit.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.needsCommit, state.valueToCommit]);

    useEffect(() => {
        if (inputRef.current && state.needsFocus) {
            dispatch({ type: "ResetNeedsFocus" });
            inputRef.current.focus();
        }
    }, [inputRef, state.needsFocus]);

    useEffect(() => {
        if (!state.focused) {
            dispatch({
                type: "UpdateDisplayText",
                args: {
                    newText: valueToInputDisplayText(
                        model.value,
                        config.initialDisplayText,
                        config.formatWithCommas ?? true,
                        config.snapToMultiplesOf,
                    ),
                },
            });
        }
    }, [config.initialDisplayText, model.value, state.focused, config.formatWithCommas, config.snapToMultiplesOf]);

    useEffect(() => {
        const modelEnabled = !model.disabled;
        const displayDifferentToModel = !isDisplayTextEquivalentToModelValue(
            state.inputDisplayText,
            model.value,
            config.initialDisplayText,
            config.snapToMultiplesOf,
        );
        const isDirty = modelEnabled && displayDifferentToModel && state.focused;
        if (lastOnDirtyValue.current !== isDirty) {
            const onDirtyChange = callbacks.onDirtyChange;
            onDirtyChange(isDirty);
            lastOnDirtyValue.current = isDirty;
        }
    }, [
        callbacks.onDirtyChange,
        state.inputDisplayText,
        model.value,
        config.initialDisplayText,
        model.disabled,
        state.focused,
        config.snapToMultiplesOf,
    ]);

    return {
        focused: state.focused,
        inputDisplayText: state.inputDisplayText,
        invalid: model.invalid,
        onClickBox,
        onChangeInput,
        onFocusInput,
        onBlurInput,
        onKeyDownInput,
        onClickTickDown,
        onClickTickUp,
    };
}

function valueToInputDisplayText(
    value: DecimalNumber | null,
    initialDisplayText: string,
    withCommas: boolean,
    snapToMultiplesOf: DecimalNumber,
): string {
    if (value && value.isFinite()) {
        const roundedValue = formatPriceToString(value, snapToMultiplesOf);
        if (withCommas) {
            return formatWithCommas(roundedValue) ?? roundedValue;
        } else {
            return roundedValue;
        }
    } else {
        return initialDisplayText;
    }
}

function isDisplayTextEquivalentToModelValue(
    inputDisplayText: string,
    value: DecimalNumber | null,
    initialDisplayText: string,
    snapToMultiplesOf: DecimalNumber,
): boolean {
    const valueDisplayText = valueToInputDisplayText(value, initialDisplayText, false, snapToMultiplesOf);
    return inputDisplayText === valueDisplayText;
}

function commitValueFromDisplayText(
    draft: Draft<NumberInputReducerState>,
    currentValue: DecimalNumber | null,
    initialDisplayText: string,
    snapToMultiplesOf: DecimalNumber,
): void {
    const typedNewValue = new ImmutableDecimalNumber(draft.inputDisplayText);
    if (!typedNewValue.isFinite()) {
        draft.inputDisplayText = valueToInputDisplayText(currentValue, initialDisplayText, false, snapToMultiplesOf);
    } else {
        const roundedToDecimalPlacesNewValue = formatPriceToDecimalNumber(typedNewValue, snapToMultiplesOf);

        // no need rounding as it's always gonna have the right number of decimal places
        const originalFocusedValue = new ImmutableDecimalNumber(draft.inputDisplayTextWhenOriginallyFocused);

        if (!roundedToDecimalPlacesNewValue.equals(originalFocusedValue)) {
            draft.valueToCommit = roundedToDecimalPlacesNewValue;
            draft.needsCommit = true;
        }
    }
}

function commitValueFromIncrement(
    draft: Draft<NumberInputReducerState>,
    currentValue: DecimalNumber | null,
    increment: DecimalNumber,
    snapToMultiplesOf: DecimalNumber,
): void {
    const valueToCommitUnRounded = (currentValue ?? new ImmutableDecimalNumber(0)).add(increment);

    draft.valueToCommit = formatPriceToDecimalNumber(valueToCommitUnRounded, snapToMultiplesOf);
    draft.needsCommit = true;
}

function reducer(state: NumberInputReducerState, action: NumberInputReducerAction): NumberInputReducerState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "Increment":
                commitValueFromIncrement(draft, action.args.currentValue, action.args.increment, action.args.snapToMultiplesOf);
                break;
            case "Decrement":
                commitValueFromIncrement(draft, action.args.currentValue, action.args.increment, action.args.snapToMultiplesOf);
                break;
            case "UpdateDisplayText":
                draft.inputDisplayText = action.args.newText;
                break;

            case "Commit":
                commitValueFromDisplayText(draft, action.args.currentValue, action.args.initialDisplayText, action.args.snapToMultiplesOf);
                break;

            case "ResetNeedsCommit":
                draft.needsCommit = false;
                draft.valueToCommit = null;
                break;

            case "Rollback":
                draft.inputDisplayText = valueToInputDisplayText(
                    action.args.currentValue,
                    action.args.initialDisplayText,
                    false,
                    action.args.snapToMultiplesOf,
                );
                break;

            case "Focus":
                draft.needsFocus = true;
                break;

            case "ResetNeedsFocus":
                draft.needsFocus = false;
                break;

            case "SetFocused":
                if (action.focused) {
                    if (draft.inputDisplayText === "-") {
                        draft.inputDisplayText = "";
                    } else {
                        draft.inputDisplayText = draft.inputDisplayText.split(",").join("");
                    }
                    draft.inputDisplayTextWhenOriginallyFocused = draft.inputDisplayText;
                    draft.modelValueWhenOriginallyFocused = action.modelValueAtFocus;
                } else {
                    draft.inputDisplayTextWhenOriginallyFocused = "";
                    draft.modelValueWhenOriginallyFocused = null;
                }
                draft.focused = action.focused;
                break;
        }
    });
}
