export enum LocalStorageKeys {
    ACCESS_TOKEN = "access-token",
    USER_GROUPS = "user-groups",
    ID_TOKEN = "id-token",
    USER_SOUND_ENABLED = "user-sound-enabled",
    BROWSER_INSTANCE_ID = "browser-instance-id",
    HISTORICAL_BLOTTER_COLUMN_STATE = "historical-blotter-columns-state",
    ACTIVE_BLOTTER_COLUMN_STATE = "active-blotter-columns-state",
    CREDIT_DOCK_COLUMN_STATE = "credit-dock-columns-state",
    CREDIT_DOCK_FILTER_STATE = "active-blotter-filter-state",
    USER_DEFINED_QUOTE_OTW_TIME_MILLIS = "quote-on-the-wire-time-millis",
}
