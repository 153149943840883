import { useMemo, useRef } from "react";

export const useMemoMappedList = <T extends object, U>(list: T[], mapperFn: (t: T) => U): U[] => {
    const itemMapRef = useRef(new WeakMap<T, U>());
    return useMemo(() => {
        return list.map((item) => {
            if (itemMapRef.current.has(item)) {
                const cachedMappedValue = itemMapRef.current.get(item);
                if (cachedMappedValue !== undefined) {
                    return cachedMappedValue;
                }
            }
            const mappedValue = mapperFn(item);
            itemMapRef.current.set(item, mappedValue);
            return mappedValue;
        });
    }, [list, mapperFn]);
};

export const useMemoFlatmappedList = <T extends object, U>(list: T[], mapperFn: (t: T) => U[]): U[] => {
    const itemMapRef = useRef(new WeakMap<T, U[]>());
    return useMemo(() => {
        return list.flatMap((item) => {
            if (itemMapRef.current.has(item)) {
                const cachedMappedValue = itemMapRef.current.get(item);
                if (cachedMappedValue !== undefined) {
                    return cachedMappedValue;
                }
            }
            const mappedValue = mapperFn(item);
            itemMapRef.current.set(item, mappedValue);
            return mappedValue;
        });
    }, [list, mapperFn]);
};
