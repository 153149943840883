import React from "react";
import { Box, BoxProps, Icon, IconNames, ProgressBar, Text, TextProps } from "../../atoms";
import { EpochClock } from "@transficc/infrastructure";
import styled from "styled-components";
import { useTimerProgress } from "./use-timer-progress";

interface BaseTimerProgressProps {
    startTimeEpochMs: number;
    endTimeEpochMs: number;
    updateIntervalMs: number;
    clock: EpochClock;
}

export interface InlineTimerProgressProps extends BaseTimerProgressProps {
    countdownTextProps?: TextProps;
}

export interface StackedTimerProgressProps extends BaseTimerProgressProps {}

const progressBarForeground: Theme.Color = { color: "cyan", level: "200" };
const progressBarBackground: Theme.Color = { color: "cyan", level: "700" };
const timerColor: Theme.Color = { color: "white" };
const timerColorWarning: Theme.Color = { color: "danger", level: "400" };

const calculateProgressBarWidthForStacked = (endTimeEpochMs: number, currentTimeEpochMs: number): Theme.SpacingLevels => {
    const totalDurationSeconds = (endTimeEpochMs - currentTimeEpochMs) / 1000;
    if (totalDurationSeconds < 10 * 60) {
        // less than 10 minutes, includes negative: 9:99
        return "16";
    } else if (totalDurationSeconds < 60 * 60) {
        // less than one hour: 59:99
        return "20";
    } else if (totalDurationSeconds < 10 * 60 * 60) {
        // less than 10 hours: 9:59:99
        return "24";
    }
    // largest 23:59:99
    return "24"; // 28 is too large :(
};

const InlineDisplayContainer = styled(Box).attrs(
    (): BoxProps => ({
        width: "full",
        height: "full",
    }),
)`
    padding: 0;
    margin: 0;
    position: relative;

    & > ${Text} {
        position: absolute;
        top: 50%; /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */

        transform: translate(-50%, -50%); /* This is a shorthand of translateX(-50%) and translateY(-50%) */
    }
`;

export const InlineTimerProgress: React.FC<InlineTimerProgressProps> = ({
    startTimeEpochMs,
    endTimeEpochMs,
    updateIntervalMs,
    clock,
    countdownTextProps,
}) => {
    const { showWarningColour, progress, timerTextDisplayValue } = useTimerProgress({
        startTimeEpochMs,
        endTimeEpochMs,
        updateIntervalMs,
        clock,
    });

    return (
        <InlineDisplayContainer>
            <ProgressBar
                backgroundColor={{ color: "transparent" }}
                foregroundColor={showWarningColour ? timerColorWarning : { color: "cyan", level: "500" }}
                progress={1 - progress}
                width={"full"}
                height={"full"}
                data-testid="timer-progress-progress-bar"
            />
            <Text textColor={{ color: "white" }} size={"sm"} {...countdownTextProps} data-testid="timer-progress-text">
                {timerTextDisplayValue}
            </Text>
        </InlineDisplayContainer>
    );
};

export const StackedTimerProgress: React.FC<StackedTimerProgressProps> = ({
    startTimeEpochMs,
    endTimeEpochMs,
    updateIntervalMs,
    clock,
}) => {
    const { showWarningColour, progress, timerTextDisplayValue } = useTimerProgress({
        startTimeEpochMs,
        endTimeEpochMs,
        updateIntervalMs,
        clock,
    });

    const progressBarWidth = calculateProgressBarWidthForStacked(endTimeEpochMs, clock.getCurrentTimeMs());

    return (
        <Box contentDirection="column" contentAlignment="center" marginB="1">
            <Box contentDirection="row" contentAlignment="center" marginB="0.5">
                <Icon
                    name={IconNames.Timer}
                    iconColor={showWarningColour ? timerColorWarning : timerColor}
                    size="4"
                    data-testid="timer-icon"
                />
                <Box marginR="2" />
                <Text textColor={showWarningColour ? timerColorWarning : timerColor} data-testid="timer-progress-text">
                    {timerTextDisplayValue}
                </Text>
            </Box>
            <ProgressBar
                backgroundColor={progressBarBackground}
                foregroundColor={showWarningColour ? timerColorWarning : progressBarForeground}
                progress={1 - progress}
                width={progressBarWidth}
                height={"4"}
                data-testid="timer-progress-progress-bar"
            />
        </Box>
    );
};
