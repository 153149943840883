import { useCallback, useEffect, useMemo, useRef } from "react";
import { IncomingMessage } from "./ApplicationProtocolMessages";
import { useMetricPublisher } from "@transficc/trader-desktop-application-context";
import { ImmutableDecimalNumber } from "@transficc/infrastructure";
import log from "../logger/globalLogger";

export const useSessionTransportDispatcherMetrics = (): { trackMessageForMetric: (message: IncomingMessage) => void } => {
    const metricPublisher = useMetricPublisher();

    const messageMetricsState = useRef<{
        lastPublished: Date;

        messagesSinceLastPublished: number;
        messageLatencyMsSinceLastPublished: {
            min: number;
            max: number;
            mean: {
                sum: number;
                count: number;
            };
        } | null;
    }>({
        lastPublished: new Date(),
        messagesSinceLastPublished: 0,
        messageLatencyMsSinceLastPublished: null,
    });

    const maybePublish = useCallback(() => {
        const state = messageMetricsState.current;
        const publishDeadlineHasPassed = Date.now() - state.lastPublished.valueOf() > 5_000;

        if (!publishDeadlineHasPassed) {
            return;
        }

        state.lastPublished = new Date();

        if (!state.messageLatencyMsSinceLastPublished) {
            return;
        }

        metricPublisher.publish([
            {
                fields: {
                    messageCountInPeriod: state.messagesSinceLastPublished,
                    minLatencyMs: state.messageLatencyMsSinceLastPublished.min,
                    maxLatencyMs: state.messageLatencyMsSinceLastPublished.max,
                    meanLatencyMs: state.messageLatencyMsSinceLastPublished.mean.sum / state.messageLatencyMsSinceLastPublished.mean.count,
                },
                name: "browser.traderDesktopMessageLatency",
                tags: {},
                timestamp: state.lastPublished.valueOf(),
            },
        ]);

        state.messagesSinceLastPublished = 0;
        state.messageLatencyMsSinceLastPublished = null;
    }, [metricPublisher]);

    useEffect(() => {
        const timer = setInterval(() => maybePublish(), 1000);
        return () => clearInterval(timer);
    }, [maybePublish]);

    return useMemo(
        () => ({
            trackMessageForMetric: (message: IncomingMessage) => {
                const sendingTimestampNanos = message.sendingTimestampNanos;

                if (!sendingTimestampNanos) {
                    log.error("Received a message with falsy sendingTimestampNanos: ", JSON.stringify(message));
                    return;
                }

                const sendingTimestampMillis = new ImmutableDecimalNumber(sendingTimestampNanos).divideBy(1_000_000).toNumber();
                const currentTimeMillis = Date.now();

                const latencyMs = currentTimeMillis - sendingTimestampMillis;

                const state = messageMetricsState.current;
                if (!state.messageLatencyMsSinceLastPublished) {
                    state.messageLatencyMsSinceLastPublished = {
                        min: latencyMs,
                        max: latencyMs,
                        mean: {
                            sum: latencyMs,
                            count: 1,
                        },
                    };
                } else {
                    const latencyData = state.messageLatencyMsSinceLastPublished;

                    state.messagesSinceLastPublished++;

                    latencyData.mean.sum += latencyMs;
                    latencyData.mean.count++;

                    if (latencyMs > latencyData.max) {
                        latencyData.max = latencyMs;
                    }
                    if (latencyMs < latencyData.min) {
                        latencyData.min = latencyMs;
                    }
                }

                maybePublish();
            },
        }),
        [maybePublish],
    );
};
