import {
    Box,
    BoxProps,
    DataMatrix,
    DataMatrixCellData,
    dataMatrixCellPositionEq,
    DataMatrixProps,
    DataMatrixSelectedCell,
    formatWithCommas,
    Text,
} from "@transficc/components";
import { useMemo } from "react";
import styled from "styled-components";
import { PricerState, Prices } from "../credit-domain";
import { backgroundColorFor, displayTextFor } from "../credit-domain/enums";
import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { DateTime } from "luxon";
import { useTicketStateDispatch } from "../credit-slice/useTicketStateDispatch";
import { CreditTicket } from "../credit-slice/useCreditSliceSelectors";
import { isPriceStale } from "../credit-domain/stale-prices";

const CreditInquiryPriceMatrixContainer = styled(Box).attrs(
    (): BoxProps => ({
        marginT: "4",
        contentDirection: "row",
        contentAlignment: "end",
        contentJustification: "center",
    }),
)``;

interface CreditInquiryPriceMatrixProps {
    ticketId: number;
    timezoneProvider: TimezoneProvider;
    clock: EpochClock;
}

const DEFAULT_CELL_FONT_SIZE: Theme.FontSizes = "sm";

const dataMatrixConfig: DataMatrixProps["config"] = {
    cellWidth: "24",
    cellHeight: "8",
    cellFontSize: DEFAULT_CELL_FONT_SIZE,
    cellBorderWidth: "0.5",
    cellBackgroundColor: { color: "info", level: "900" },
    cellForegroundColor: { color: "white" },
    rowLabelPosition: "left",
    columnConfig: [{}, {}, {}],
    rowConfig: [{ label: "Q" }, { label: "AQ" }],
};

const calculateFontSize = (value: string | null): Theme.FontSizes => {
    const mediumValueLengthThreshold = 9;
    const largeValueLengthThreshold = 11;

    let fontSize: Theme.FontSizes = DEFAULT_CELL_FONT_SIZE;
    if (value !== null && value.length > largeValueLengthThreshold) {
        fontSize = "2xs";
    } else if (value !== null && value.length > mediumValueLengthThreshold) {
        fontSize = "xs";
    }
    return fontSize;
};

const calcDataRow = (value: string | null): DataMatrixCellData => {
    const formattedValue = formatWithCommas(value);
    return { value: formattedValue, cellFontSize: calculateFontSize(formattedValue) };
};

const useDataRow = (values: Prices): DataMatrixCellData[] => {
    return useMemo(() => [calcDataRow(values.bid), calcDataRow(values.mid), calcDataRow(values.ask)], [values.ask, values.bid, values.mid]);
};

const useAqRow = (
    autoQuotedPrices: Prices,
    autoQuotePriceState: PricerState | null,
    autoQuotePriceError: string | null,
    pricesHaveExpired: boolean,
): DataMatrixCellData[] => {
    return useMemo(() => {
        const displayText = displayTextFor(autoQuotePriceState);
        if (displayText) {
            const cellOverride = {
                value: displayText,
                secondaryText: autoQuotePriceError ?? undefined,
                cellForegroundColor: { color: "white" } as Theme.Color,
                cellBackgroundColor: backgroundColorFor(autoQuotePriceState),
            };
            return [cellOverride, cellOverride, cellOverride];
        }

        if (pricesHaveExpired) {
            const bidOverride = calcDataRow(autoQuotedPrices.bid);
            bidOverride.cellBackgroundColor = { color: "warning", level: "500" };
            bidOverride.secondaryText = "Stale Price";

            const midOverride = calcDataRow(autoQuotedPrices.mid);
            midOverride.cellBackgroundColor = { color: "warning", level: "500" };
            midOverride.secondaryText = "Stale Price";

            const askOverride = calcDataRow(autoQuotedPrices.ask);
            askOverride.cellBackgroundColor = { color: "warning", level: "500" };
            askOverride.secondaryText = "Stale Price";

            return [bidOverride, midOverride, askOverride];
        }

        return [calcDataRow(autoQuotedPrices.bid), calcDataRow(autoQuotedPrices.mid), calcDataRow(autoQuotedPrices.ask)];
    }, [autoQuotePriceError, autoQuotePriceState, autoQuotedPrices.ask, autoQuotedPrices.bid, autoQuotedPrices.mid, pricesHaveExpired]);
};

export const CreditInquiryPriceMatrix: React.FC<CreditInquiryPriceMatrixProps> = ({ ticketId, timezoneProvider, clock }) => {
    const ticketStateDispatch = useTicketStateDispatch(ticketId);
    const selectedCellPosition = CreditTicket.useSelectedDriver(ticketId);
    const negotiationIsFinished = CreditTicket.useNegotiationIsFinished(ticketId);
    const {
        autoQuotedPrices,
        autoQuotePriceState,
        autoQuotePriceError,
        latestQuotedPrices,
        autoQuotePricesGenerationTimestampNanos,
        autoQuotePricesValidForNanos,
    } = CreditTicket.usePriceMatrixState(ticketId);

    const selectedCells = useMemo<DataMatrixSelectedCell[] | undefined>(() => {
        if (selectedCellPosition === null) {
            return undefined;
        }

        return [
            {
                pos: selectedCellPosition,
                config: {
                    cellSelectionColor: { color: "success", level: "300" },
                    cellDisabledSelectionColor: { color: "gray", level: "400" },
                    cellSelectionBorderWidth: "1",
                },
            },
        ];
    }, [selectedCellPosition]);

    const aqRow = useAqRow(
        autoQuotedPrices,
        autoQuotePriceState,
        autoQuotePriceError,
        isPriceStale(autoQuotePriceState, autoQuotePricesGenerationTimestampNanos, autoQuotePricesValidForNanos, clock),
    );
    const data = [useDataRow(latestQuotedPrices), aqRow];

    const timezone = timezoneProvider.getTimezone();

    const time = useMemo(() => {
        if (!autoQuotePricesGenerationTimestampNanos) {
            return "";
        }
        const asSeconds = autoQuotePricesGenerationTimestampNanos.slice(0, autoQuotePricesGenerationTimestampNanos.length - 9);
        return DateTime.fromSeconds(parseInt(asSeconds), { zone: "UTC" }).setZone(timezone).toFormat("hh:mm:ss a");
    }, [autoQuotePricesGenerationTimestampNanos, timezone]);

    return (
        <CreditInquiryPriceMatrixContainer data-testid="credit-price-matrix-container">
            <Text srOnly>Price Matrix</Text>
            <DataMatrix
                disabledSelection={negotiationIsFinished}
                config={dataMatrixConfig}
                data={data}
                selectedCells={selectedCells}
                onClickCell={(cellPosition) => {
                    if (selectedCellPosition !== null && dataMatrixCellPositionEq(selectedCellPosition, cellPosition)) {
                        ticketStateDispatch.setDriver(null);
                    } else {
                        ticketStateDispatch.setDriver(cellPosition);
                    }
                }}
            />

            <Box marginL={"3"} marginB={"1.5"} width={"28"}>
                <Text srOnly>AutoQuoted Price Generation Timestamp</Text>
                <Text>{time}</Text>
            </Box>
        </CreditInquiryPriceMatrixContainer>
    );
};
