import { Box, Icon, IconNames, Text } from "@transficc/components";

export const Loading: React.FC = () => {
    return (
        <Box contentDirection={"row"} contentAlignment={"center"} padding={"3"}>
            <Icon name={IconNames.Timer} size={"6"} iconColor={{ color: "white" }} />
            <Box marginL={"2"}></Box>
            <Text textColor={{ color: "white" }}>Loading, please wait...</Text>
        </Box>
    );
};
