import { getItemFromLocalStorage, LocalStorageKeys, removeItemFromLocalStorage } from "@transficc/trader-desktop-local-storage";
import { jwtDecode } from "jwt-decode";
import { IdToken } from "../ApplicationRouter";
import type { Logger, optionalString } from "@transficc/trader-desktop-application-context";

enum Level {
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
}

const formatArrayToPrintNullsAndUndefinedElements = (msg: optionalString[]): string => {
    const strings: string[] = msg.map((optionalString: optionalString) => {
        return String(optionalString);
    });
    return strings.join(" ");
};

export class LoggerConfiguration {
    private _appInstanceId: string | null = null;
    private _lastAccessToken: string | null = null;
    private _username: string | null = null;
    private _userId: number | null = null;

    set setAppInstanceId(appInstanceId: string) {
        this._appInstanceId = appInstanceId;
    }

    set setAccessToken(accessToken: string) {
        this._lastAccessToken = accessToken;
    }

    updateIdToken(idToken: string | null): void {
        if (idToken == null) {
            return;
        } else {
            const jwtIdToken = jwtDecode<IdToken>(idToken);
            if (jwtIdToken.user_id !== undefined) {
                this._userId = jwtIdToken.user_id;
            }
            if (jwtIdToken.sub !== undefined) {
                this._username = jwtIdToken.sub;
            }
        }
    }

    getAppInstanceId(): string | null {
        return this._appInstanceId;
    }

    getLastAvailableAccessToken(): string | null {
        if (typeof localStorage !== "undefined") {
            return getItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
        } else {
            return this._lastAccessToken;
        }
    }

    getLastAvailableUsername(): string | null {
        this.updateFieldsFromLocalStorage();
        return this._username;
    }

    getLastAvailableUserId(): number | null {
        this.updateFieldsFromLocalStorage();
        return this._userId;
    }

    private updateFieldsFromLocalStorage(): void {
        if (typeof localStorage !== "undefined") {
            this.updateIdToken(getItemFromLocalStorage(LocalStorageKeys.ID_TOKEN));
        }
    }
}

export class HttpSimpleLogger implements Logger {
    private readonly _uri: string;
    private readonly _loggerConfiguration: LoggerConfiguration;
    private _suppressFailures = false;

    constructor(uri: string, loggerConfiguration: LoggerConfiguration) {
        this._uri = uri;
        this._loggerConfiguration = loggerConfiguration;
    }

    info(...msg: optionalString[]): void {
        this.log(Level.INFO, msg);
    }

    warn(...msg: optionalString[]): void {
        this.log(Level.WARN, msg);
    }

    error(...msg: optionalString[]): void {
        this.log(Level.ERROR, msg);
    }

    private log(level: Level, msg: optionalString[]): void {
        const formattedMsg = formatArrayToPrintNullsAndUndefinedElements(msg);
        this.send(level, formattedMsg);
    }

    private send(level: string, msg: string): void {
        const payload = {
            level: level,
            browserEpochMillis: Date.now(),
            appInstanceId: this._loggerConfiguration.getAppInstanceId(),
            userId: this._loggerConfiguration.getLastAvailableUserId(),
            subject: this._loggerConfiguration.getLastAvailableUsername(),
            message: msg,
        };

        const lastAvailableAccessToken = this._loggerConfiguration.getLastAvailableAccessToken();
        if (lastAvailableAccessToken == null) {
            // eslint-disable-next-line
            console.error("Unable post log msg (token is null) : " + msg);
            return; // cannot post without token
        }

        fetch(this._uri, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization: "Bearer " + lastAvailableAccessToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    removeItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
                    window.location.reload();
                    return;
                }
                const expectedStatusCode = 200;
                if (response.status === expectedStatusCode) {
                    this._suppressFailures = false;
                } else {
                    // eslint-disable-next-line no-console
                    console.log("Failed to post log message, expected " + String(expectedStatusCode) + " got " + String(response.status));
                    this._suppressFailures = true;
                }
            })
            .catch((error) => {
                if (this._suppressFailures) {
                    return; // do not relog a failure
                }

                this._suppressFailures = true; // so we only log once

                if (error instanceof TypeError) {
                    // eslint-disable-next-line no-console
                    console.error("Failed to post log network failure. (Unable to establish TCP/QUIC Connection)");
                } else {
                    // eslint-disable-next-line no-console
                    console.error("Unexpected error while trying to post log: ", error);
                }
            });
    }
}

export class LogBroadcaster implements Logger {
    private readonly _loggers: Logger[];
    private readonly _failOnSharedWorker;

    constructor(loggers: Logger[], failIfOnSharedWorker: boolean) {
        this._loggers = loggers;
        this._failOnSharedWorker = failIfOnSharedWorker;
    }

    info(...msg: optionalString[]): void {
        this.checkSharedWorker();
        for (const logger of this._loggers) {
            logger.info(...msg);
        }
    }

    warn(...msg: optionalString[]): void {
        this.checkSharedWorker();
        for (const logger of this._loggers) {
            logger.warn(...msg);
        }
    }

    error(...msg: optionalString[]): void {
        this.checkSharedWorker();
        for (const logger of this._loggers) {
            logger.error(...msg);
        }
    }

    private checkSharedWorker(): void {
        if (this._failOnSharedWorker && typeof window === "undefined") {
            throw new Error("Global logger called outside of main js thread. Instantiate and configure your own logger.");
        }
    }
}

export class ConsoleLogger implements Logger {
    private readonly _loggerConfiguration: LoggerConfiguration;

    constructor(loggerConfiguration: LoggerConfiguration) {
        this._loggerConfiguration = loggerConfiguration;
    }

    info(...msg: optionalString[]): void {
        this.writeToConsole(Level.INFO, msg);
    }

    warn(...msg: optionalString[]): void {
        this.writeToConsole(Level.WARN, msg);
    }

    error(...msg: optionalString[]): void {
        this.writeToConsole(Level.ERROR, msg);
    }

    private writeToConsole(level: Level, msg: optionalString[]): void {
        const logTimestamp = new Date().toISOString();
        const formattedMsg = formatArrayToPrintNullsAndUndefinedElements(msg);
        switch (level) {
            case Level.INFO:
                // eslint-disable-next-line no-console
                console.info(logTimestamp + " [" + this._loggerConfiguration.getAppInstanceId() + "] " + formattedMsg);
                break;
            case Level.WARN:
                // eslint-disable-next-line no-console
                console.warn(logTimestamp + " [" + this._loggerConfiguration.getAppInstanceId() + "] " + formattedMsg);
                break;
            case Level.ERROR:
                // eslint-disable-next-line no-console
                console.error(logTimestamp + " [" + this._loggerConfiguration.getAppInstanceId() + "] " + formattedMsg);
                break;
            default:
                // eslint-disable-next-line no-console
                console.error(logTimestamp + " [" + this._loggerConfiguration.getAppInstanceId() + "] " + formattedMsg);
                break;
        }
    }
}
