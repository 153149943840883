import React from "react";
import { colorByState } from "../irs-domain/colors";
import styled from "styled-components";
import { Box, Text } from "@transficc/components";
import { useSelectedInquiryState } from "../irs-slice/irs-selectors";

export interface IRSInquiryBodyProps {
    children: React.ReactNode;
}

export interface InquiryContainerProps {
    children: React.ReactNode;
}

const StyledInquiryContainerBox = styled(Box)<{
    wholeInquiryBackgroundShouldFlash: boolean;
}>`
    min-width: 90rem;
    min-height: 60rem;
    height: 100%;
    animation: ${({ wholeInquiryBackgroundShouldFlash }) =>
        wholeInquiryBackgroundShouldFlash ? "blinkingBackground 1s infinite" : "none"};

    @keyframes blinkingBackground {
        50% {
            background-color: transparent;
        }
    }
`;

export const NoInquirySelected: React.FC = () => (
    <Box width={"full"} height={"full"} contentDirection={"row"} contentAlignment={"center"} contentJustification={"center"}>
        <Text textColor={{ color: "white" }}>No Inquiry Selected</Text>
    </Box>
);

export const NoInquiriesAvailable: React.FC = () => (
    <Box width={"full"} height={"full"} contentDirection={"row"} contentAlignment={"center"} contentJustification={"center"}>
        <Text textColor={{ color: "white" }}>No Inquiries Received</Text>
    </Box>
);

export const CouldNotRenderInquiry: React.FC<{ reason: string }> = ({ reason }) => (
    <Box rounded backgroundColor={{ color: "danger", level: "600" }} padding={"4"}>
        <Text textColor={{ color: "white" }}>Error: Could not render inquiry due to {reason}</Text>
    </Box>
);

export const IRSInquiryBody: React.FC<IRSInquiryBodyProps> = ({ children }) => {
    return (
        <Box width={"full"} contentDirection={"row"} contentJustification={"center"}>
            <Box contentDirection="row">{children}</Box>
        </Box>
    );
};

export const SelectedInquiryContainer: React.FC<InquiryContainerProps> = ({ children }) => {
    const inquiryState = useSelectedInquiryState();
    const { wholeInquiryBackgroundColor, wholeInquiryBackgroundShouldFlash } = colorByState(inquiryState);

    return (
        <StyledInquiryContainerBox
            backgroundColor={wholeInquiryBackgroundColor}
            wholeInquiryBackgroundShouldFlash={wholeInquiryBackgroundShouldFlash}
            data-testid={"selected-inquiry-container"}
        >
            {children}
        </StyledInquiryContainerBox>
    );
};
