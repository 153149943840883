import { DefaultTheme } from "styled-components";
import { cyan, danger, gold, gray, info, lime, rose, success, warning } from "./theme-colors";

export const defaultTheme: DefaultTheme = {
    borderRadius: "0.5rem",
    colors: {
        transparent: "transparent",
        black: "#222831",
        white: "#ffffff",
        globalBackgroundColor: cyan["900"],
        successBackgroundColor: { color: "custom", custom: "#33cc33" },
        errorBackgroundColor: { color: "custom", custom: "#EE2222" },
        progressBackgroundColor: { color: "custom", custom: "#f0ad4e" },
        disabledBackgroundColor: { color: "gray", level: "400" },
        successTextColor: { color: "black" },
        errorTextColor: { color: "white" },
        gray,
        cyan,
        lime,
        rose,
        success,
        info,
        warning,
        danger,
        gold,
    },
    font: {
        defaultLineHeight: "1.2",
        defaultFontFamily: "monospace",
        family: {
            monospace: "Monaspace Neon",
            sansSerif: "Ubuntu",
        },
        sizes: {
            "2xs": ".65rem",
            xs: ".75rem",
            sm: ".875rem",
            base: "1rem",
            lg: "1.125rem",
            xl: "1.25rem",
            "2xl": "1.5rem",
            "3xl": "1.875rem",
            "4xl": "2.25rem",
            "5xl": "3rem",
            "6xl": "4rem",
        },
        weights: {
            thin: "100",
            extralight: "200",
            light: "300",
            normal: "400",
            medium: "500",
            semibold: "600",
            bold: "700",
            extrabold: "800",
            black: "900",
        },
    },
    spacing: {
        px: "1px",
        "0": "0px",
        "0.5": "0.125rem",
        "1": "0.25rem",
        "1.5": "0.375rem",
        "2": "0.5rem",
        "2.5": "0.625rem",
        "3": "0.75rem",
        "3.5": "0.875rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
        "11": "2.75rem",
        "12": "3rem",
        "14": "3.5rem",
        "16": "4rem",
        "20": "5rem",
        "24": "6rem",
        "28": "7rem",
        "32": "8rem",
        "36": "9rem",
        "40": "10rem",
        "44": "11rem",
        "48": "12rem",
        "52": "13rem",
        "56": "14rem",
        "60": "15rem",
        "64": "16rem",
        "72": "18rem",
        "80": "20rem",
        "96": "24rem",
        "106": "26.5rem",
        auto: "auto",
        full: "100%",
    },
};
