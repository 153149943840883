import { SessionStorageKeys } from "./SessionStorageKeys";

export function removeItemFromSessionStorage(key: SessionStorageKeys): void {
    window.sessionStorage.removeItem(key);
}

export function setItemInSessionStorage(key: SessionStorageKeys, value: string): void {
    window.sessionStorage.setItem(key, value);
}

export function getItemFromSessionStorage(key: SessionStorageKeys): string | null {
    return window.sessionStorage.getItem(key);
}
