import React, { useCallback, useMemo, useState } from "react";
import { DecimalNumber } from "@transficc/infrastructure";
import * as IRSDomain from "../irs-domain";
import {
    backgroundColorFor,
    BidMidAsk,
    DisplayState,
    foregroundColorFor,
    PackageDirection,
    PricerState,
    PriceValidity,
    SelectedDriver,
} from "../irs-domain";
import { IRSPriceAndSpread } from "../irs-price-and-spread/irs-price-and-spread";
import { colorForFee } from "../irs-domain/colors";
import { IRSIncrementButtons } from "../irs-increment-buttons/irs-increment-buttons";
import { getIncrements } from "../irs-domain/compression-derivations";
import { IRSPtmm } from "../irs-ptmm/irs-ptmm";
import { Box } from "@transficc/components";
import { IrsRfmPriceDriver } from "../irs-price-driver/irs-rfm-price-driver";
import { colourForDirection, mktLabelConfig, oppLabelConfig } from "./compression-common";
import { IrsRfqPriceDriver } from "../irs-price-driver/irs-rfq-price-driver";
import { FEE_TOOL_TIP_MESSAGE } from "../irs-domain/tool-tip";
import { useMinPriceIncrementAllLegsSelector } from "../irs-slice/irs-selectors";

export interface IRSCompressionControlsProps {
    model: {
        market: {
            packageFeeValue: DecimalNumber | null;
            selectedDriver: SelectedDriver | null;
            spreadValue: DecimalNumber | null;

            isSpreadInputDisabled: boolean;
            autoQuotePrices: BidMidAsk;
            venuePrices: BidMidAsk;
        };
        opposite: {
            packageFeeValue: DecimalNumber | null;
            selectedDriver: SelectedDriver | null;
            spreadValue: DecimalNumber | null;

            isSpreadInputDisabled: boolean;
            autoQuotePrices: BidMidAsk;
            venuePrices: BidMidAsk;
        };
        isPackageFeeInputDisabled: boolean;
        packageFeeDisplayState: IRSDomain.DisplayState;
        isPackageFeeInvalid: boolean;

        autoQuotePriceValidity: PriceValidity | null;
        autoQuotePricerState: PricerState | null;

        autoQuoteDisplayState: IRSDomain.DisplayState;

        isInquiryFinished: boolean;

        lastQuotedPrices: BidMidAsk;
        autoQuoteErrorMessage: string | null;

        tradedPackageFee: string | null;

        packageDirection: PackageDirection;
    };
    callbacks: {
        market: {
            updatePackageFee: (packageFee: DecimalNumber) => void;
            selectDriver: (selectedDriver: SelectedDriver) => void;
            clearDriverValue: () => void;
            updateSpreadValue: (spreadValue: DecimalNumber) => void;
        };
        opposite: {
            updatePackageFee: (packageFee: DecimalNumber) => void;
            selectDriver: (selectedDriver: SelectedDriver) => void;
            clearDriverValue: () => void;
            updateSpreadValue: (spreadValue: DecimalNumber) => void;
        };
        onPackageFeeDirtyChange: (isDirty: boolean) => void;

        onSpreadDirtyChange: (isDirty: boolean) => void;

        updatePtmmValue: (ptmmValue: DecimalNumber) => void;
    };
}

interface PackageFeeColors {
    foreground: Theme.Color | undefined;
    background: Theme.Color | undefined;
}

const packageFeeColor: (displayState: DisplayState, packageFeeValue: DecimalNumber | null) => PackageFeeColors = (
    displayState,
    packageFeeValue,
) => {
    const displayStateDrivenBackgroundColor = backgroundColorFor(displayState);
    if (displayStateDrivenBackgroundColor !== undefined) {
        return { foreground: foregroundColorFor(displayState), background: displayStateDrivenBackgroundColor };
    }

    if (packageFeeValue !== null) {
        const feeColor = colorForFee(packageFeeValue);
        if (feeColor !== null) {
            return feeColor;
        }
    }

    return { foreground: foregroundColorFor(displayState), background: displayStateDrivenBackgroundColor };
};

interface IRSCompressionControlsView {
    market: {
        packageFeeColors: PackageFeeColors;
    };
    opposite: {
        packageFeeColors: PackageFeeColors;
    };

    incrementLabels: [string, string, string];
    activeIncrementValue: DecimalNumber;

    onClick: (newIncrementIndex: number) => void;
}

const useIRSCompressionControls = ({ model }: IRSCompressionControlsProps): IRSCompressionControlsView => {
    const minPriceIncrement = useMinPriceIncrementAllLegsSelector();
    const packageFeeColorsMarket: PackageFeeColors = useMemo(() => {
        return packageFeeColor(model.packageFeeDisplayState, model.market.packageFeeValue);
    }, [model.packageFeeDisplayState, model.market.packageFeeValue]);

    const packageFeeColorsOpposite: PackageFeeColors = useMemo(() => {
        return packageFeeColor(model.packageFeeDisplayState, model.opposite.packageFeeValue);
    }, [model.packageFeeDisplayState, model.opposite.packageFeeValue]);

    const { incrementLabels, incrementValues } = useMemo(() => getIncrements(minPriceIncrement), [minPriceIncrement]);

    const [activeIncrementValue, setActiveIncrementValue] = useState(incrementValues[0]);

    const onClick = useCallback(
        (newIncrementIndex: number): void => {
            switch (newIncrementIndex) {
                case 0:
                case 1:
                case 2:
                    setActiveIncrementValue(incrementValues[newIncrementIndex]);
                    break;
                default:
                    throw new Error("Index out of bounds: " + newIncrementIndex.toString());
            }
        },
        [setActiveIncrementValue, incrementValues],
    );

    return {
        market: {
            packageFeeColors: packageFeeColorsMarket,
        },
        opposite: {
            packageFeeColors: packageFeeColorsOpposite,
        },

        incrementLabels,
        activeIncrementValue,

        onClick,
    };
};

export const IrsRfmCompressionControls: React.FC<IRSCompressionControlsProps> = ({ model, callbacks }) => {
    const minPriceIncrement = useMinPriceIncrementAllLegsSelector();
    const {
        market,
        opposite,

        incrementLabels,
        activeIncrementValue,

        onClick,
    } = useIRSCompressionControls({ model, callbacks });
    const marketLeg = (
        <IRSPriceAndSpread
            testId="irs-price-rate-market"
            priceProps={{
                config: {
                    tickButtonSize: activeIncrementValue,
                    minPriceIncrement: minPriceIncrement,
                    title: "",
                    titleSelector: "Package Fee",
                    showTitleText: false,
                    size: "medium",
                    titleLabel: mktLabelConfig,
                    tooltip: FEE_TOOL_TIP_MESSAGE,
                },
                model: {
                    value: model.market.packageFeeValue,
                    disabled: model.isPackageFeeInputDisabled,
                    selected: model.market.selectedDriver === null,
                    displayState: model.packageFeeDisplayState,
                    inputForegroundColor: market.packageFeeColors.foreground,
                    inputBackgroundColor: market.packageFeeColors.background,
                    invalid: model.isPackageFeeInvalid,
                },
                callbacks: {
                    onValueChange: callbacks.market.updatePackageFee,
                    onDirtyChange: callbacks.onPackageFeeDirtyChange,
                },
            }}
            spreadProps={{
                config: {
                    tickButtonSize: activeIncrementValue,
                    minPriceIncrement: minPriceIncrement,
                    size: "small",
                },
                model: {
                    value: model.market.spreadValue,
                    disabled: model.market.isSpreadInputDisabled,
                },
                callbacks: {
                    onValueChange: callbacks.market.updateSpreadValue,
                    onDirtyChange: callbacks.onSpreadDirtyChange,
                },
            }}
        />
    );
    const oppositeLeg = (
        <IRSPriceAndSpread
            testId="irs-price-rate-opposite"
            priceProps={{
                config: {
                    tickButtonSize: activeIncrementValue,
                    minPriceIncrement: minPriceIncrement,
                    title: "",
                    showTitleText: false,
                    titleSelector: "Package Fee",
                    size: "medium",
                    titleLabel: oppLabelConfig,
                    tooltip: FEE_TOOL_TIP_MESSAGE,
                },
                model: {
                    value: model.opposite.packageFeeValue,
                    disabled: model.isPackageFeeInputDisabled,
                    selected: model.opposite.selectedDriver === null,
                    displayState: model.packageFeeDisplayState,
                    inputForegroundColor: opposite.packageFeeColors.foreground,
                    inputBackgroundColor: opposite.packageFeeColors.background,
                    invalid: model.isPackageFeeInvalid,
                },
                callbacks: {
                    onValueChange: callbacks.opposite.updatePackageFee,
                    onDirtyChange: callbacks.onPackageFeeDirtyChange,
                },
            }}
            spreadProps={{
                config: {
                    tickButtonSize: activeIncrementValue,
                    minPriceIncrement: minPriceIncrement,
                    size: "small",
                },
                model: {
                    value: model.opposite.spreadValue,
                    disabled: model.opposite.isSpreadInputDisabled,
                },
                callbacks: {
                    onValueChange: callbacks.opposite.updateSpreadValue,
                    onDirtyChange: callbacks.onSpreadDirtyChange,
                },
            }}
        />
    );
    const packageDirection = model.packageDirection;
    const shouldDisplayMarket = packageDirection !== PackageDirection.Opposite;
    const shouldDisplayOpp = packageDirection !== PackageDirection.Market;
    const callbacksForRfqPriceDriver = shouldDisplayMarket ? callbacks.market : callbacks.opposite;
    const isUndisclosed = packageDirection === PackageDirection.Undisclosed;
    return (
        <Box contentAlignment={"center"} marginL="4" data-testid={"irs-package-controls"}>
            <IRSIncrementButtons labels={incrementLabels} onSelectedIndexChange={onClick} />
            <Box margin={"1"} />
            <Box contentDirection={"row"}>
                {shouldDisplayMarket ? marketLeg : null}
                {isUndisclosed ? <Box margin={"2"} /> : null}
                {shouldDisplayOpp ? oppositeLeg : null}
            </Box>
            <IRSPtmm
                tickButtonSize={activeIncrementValue}
                minPriceIncrement={minPriceIncrement}
                onValueChange={callbacks.updatePtmmValue}
                size={"small"}
            />
            <Box marginT="8">
                {isUndisclosed ? (
                    <IrsRfmPriceDriver
                        config={{
                            leftSideConfig: {
                                cellSelectionColor: { color: colourForDirection(PackageDirection.Market), level: "500" },
                                cellSelectedBackgroundColor: { color: colourForDirection(PackageDirection.Market), level: "400" },
                                cellSelectionLabel: "Mkt",
                                selectedForegroundColor: { color: "black" },
                            },
                            rightSideConfig: {
                                cellSelectionColor: { color: colourForDirection(PackageDirection.Opposite), level: "700" },
                                cellSelectedBackgroundColor: { color: colourForDirection(PackageDirection.Opposite), level: "800" },
                                cellSelectionLabel: "Opp",
                                selectedForegroundColor: { color: "white" },
                            },
                        }}
                        model={{
                            leftSide: {
                                selectedDriver: model.market.selectedDriver,
                                prices: {
                                    latestQuotedPrices: model.lastQuotedPrices,
                                    autoQuotePrices: model.market.autoQuotePrices,
                                    venuePrices: model.market.venuePrices,
                                },
                            },
                            rightSide: {
                                selectedDriver: model.opposite.selectedDriver,
                                prices: {
                                    latestQuotedPrices: model.lastQuotedPrices,
                                    autoQuotePrices: model.opposite.autoQuotePrices,
                                    venuePrices: model.opposite.venuePrices,
                                },
                            },
                            autoQuotePricerState: model.autoQuotePricerState,
                            autoQuoteErrorMessage: model.autoQuoteErrorMessage,
                            autoQuoteDisplayState: model.autoQuoteDisplayState,
                            disabled: model.isInquiryFinished,
                            autoQuotePriceValidity: model.autoQuotePriceValidity,
                        }}
                        callbacks={{
                            leftSide: {
                                selectDriver: callbacks.market.selectDriver,
                                clearDriver: callbacks.market.clearDriverValue,
                            },
                            rightSide: {
                                selectDriver: callbacks.opposite.selectDriver,
                                clearDriver: callbacks.opposite.clearDriverValue,
                            },
                        }}
                    />
                ) : (
                    <IrsRfqPriceDriver
                        model={{
                            prices: {
                                latestQuotedPrices: model.lastQuotedPrices,
                                autoQuotePrices:
                                    model.packageDirection === "Market" ? model.market.autoQuotePrices : model.opposite.autoQuotePrices,
                                venuePrices: model.packageDirection === "Market" ? model.market.venuePrices : model.opposite.venuePrices,
                            },
                            autoQuotePricerState: model.autoQuotePricerState,
                            autoQuoteErrorMessage: model.autoQuoteErrorMessage,
                            autoQuoteDisplayState: model.autoQuoteDisplayState,
                            selectedDriver: shouldDisplayMarket ? model.market.selectedDriver : model.opposite.selectedDriver,
                            disabled: model.isInquiryFinished,
                            autoQuotePriceValidity: model.autoQuotePriceValidity,
                            cellSelectionColor: { color: colourForDirection(packageDirection), level: "500" },
                        }}
                        callbacks={{
                            selectDriver: callbacksForRfqPriceDriver.selectDriver,
                            clearDriver: callbacksForRfqPriceDriver.clearDriverValue,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};
