import { AnyAction, createListenerMiddleware, isAnyOf, ThunkDispatch } from "@reduxjs/toolkit";
import { irs, IrsState } from "./irs-slice";
import { SessionTransport } from "@transficc/trader-desktop-application-context";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { InquirySide, IRSInquiry, PackageDirection } from "../irs-domain";
import { getPriceSource } from "../irs-domain/price-source";
import { EpochClock } from "@transficc/infrastructure";

interface PartialRootState {
    irs: IrsState;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeIrsListenerMiddleware = (sessionDispatch: SessionTransport, clock: EpochClock) => {
    const listener = createListenerMiddleware<
        PartialRootState,
        ThunkDispatch<{ irs: IrsState }, unknown, AnyAction>,
        { sessionTransport: SessionTransport; clock: EpochClock }
    >({
        extra: {
            sessionTransport: sessionDispatch,
            clock: clock,
        },
    });

    listener.startListening({
        matcher: isAnyOf(...Object.values(irs.actions)),
        effect: (_action, ctx) => {
            const state = ctx.getState();
            const sessionTransport = ctx.extra.sessionTransport;
            const selectedInquiryTicketId = state.irs.selectedInquiry;

            if (selectedInquiryTicketId == null) {
                return;
            }

            const selectedInquiry = state.irs.inquiries.entities[selectedInquiryTicketId];

            if (!selectedInquiry) {
                return;
            }

            const inquiryDomain = selectedInquiry.latestInquiry;
            const inquiryTicketState = selectedInquiry.ticketState;

            if (!inquiryTicketState.state.shouldPublishPriceBasisUpdate) {
                return;
            }

            if (inquiryDomain.packageType === "outright" && inquiryTicketState.type === "outright") {
                const leg = inquiryDomain.legs[0];
                const stateLeg = inquiryTicketState.state;

                if (!stateLeg) {
                    throw new Error();
                }

                const bidPriceSpread = leg.side === InquirySide.Sell ? "" : stateLeg.pay.spreadValue?.toString() ?? "";
                const askPriceSpread = leg.side === InquirySide.Buy ? "" : stateLeg.rcv.spreadValue?.toString() ?? "";
                const manualBidPrice = leg.side === InquirySide.Sell ? "" : stateLeg.pay.manualRateValue?.toString() ?? "";
                const manualAskPrice = leg.side === InquirySide.Buy ? "" : stateLeg.rcv.manualRateValue?.toString() ?? "";

                const bidPriceSource = leg.side === InquirySide.Sell ? null : getPriceSource(stateLeg.pay.selectedDriver);
                const askPriceSource = leg.side === InquirySide.Buy ? null : getPriceSource(stateLeg.rcv.selectedDriver);
                const ptmm = stateLeg.manualPtmmValue?.toString() ?? "";

                sessionTransport.send(
                    JSON.stringify({
                        sendingTimestampNanos: ctx.extra.clock.getCurrentTimeNsStr(),
                        msgType: ActionPublisher.InquiryAction.PriceBasisUpdate,
                        ticketId: selectedInquiryTicketId,
                        oppositePackage: {
                            packagePriceSpread: null,
                            packagePriceSource: null,
                            manualPackagePrice: null,
                        },
                        marketPackage: {
                            packagePriceSpread: null,
                            packagePriceSource: null,
                            manualPackagePrice: null,
                        },
                        preTradeMidMarketPackagePrice: null,
                        legs: [
                            {
                                referenceId: leg.referenceId,
                                manualBidPrice: manualBidPrice,
                                bidPriceSource: bidPriceSource,
                                bidPriceSpread: bidPriceSpread,
                                manualAskPrice: manualAskPrice,
                                askPriceSource: askPriceSource,
                                askPriceSpread: askPriceSpread,
                                preTradeMidMarketPrice: ptmm,
                            },
                        ],
                    }),
                );
            } else if (inquiryDomain.packageType === "curve" && inquiryTicketState.type === "curve" && inquiryDomain.legs[1]) {
                const priceBasisLegs: ActionPublisher.PriceBasisUpdateLeg[] = [];
                const [leftLegState, rightLegState] = inquiryTicketState.state.legs;
                priceBasisLegs.push({
                    referenceId: inquiryDomain.legs[0].referenceId,
                    manualBidPrice: leftLegState.pay.manualRateValue?.toString() ?? "",
                    manualAskPrice: leftLegState.rcv.manualRateValue?.toString() ?? "",
                    preTradeMidMarketPrice: leftLegState.manualPtmmValue?.toString() ?? null,
                    bidPriceSource: getPriceSource(leftLegState.pay.selectedDriver),
                    askPriceSource: getPriceSource(leftLegState.rcv.selectedDriver),
                    bidPriceSpread: leftLegState.pay.spreadValue?.toString() ?? "",
                    askPriceSpread: leftLegState.rcv.spreadValue?.toString() ?? "",
                });
                priceBasisLegs.push({
                    referenceId: inquiryDomain.legs[1].referenceId,
                    manualBidPrice: rightLegState.pay.manualRateValue?.toString() ?? "",
                    manualAskPrice: rightLegState.rcv.manualRateValue?.toString() ?? "",
                    preTradeMidMarketPrice: rightLegState.manualPtmmValue?.toString() ?? null,
                    bidPriceSource: getPriceSource(rightLegState.pay.selectedDriver),
                    askPriceSource: getPriceSource(rightLegState.rcv.selectedDriver),
                    bidPriceSpread: rightLegState.pay.spreadValue?.toString() ?? "",
                    askPriceSpread: rightLegState.rcv.spreadValue?.toString() ?? "",
                });

                sessionTransport.send(
                    JSON.stringify({
                        sendingTimestampNanos: ctx.extra.clock.getCurrentTimeNsStr(),
                        msgType: ActionPublisher.InquiryAction.PriceBasisUpdate,
                        ticketId: selectedInquiryTicketId,
                        marketPackage: {
                            packagePriceSpread: null,
                            packagePriceSource: null,
                            manualPackagePrice: null,
                        },
                        oppositePackage: {
                            packagePriceSpread: null,
                            packagePriceSource: null,
                            manualPackagePrice: null,
                        },
                        preTradeMidMarketPackagePrice: null,
                        legs: priceBasisLegs,
                    }),
                );
            } else if (inquiryDomain.packageType === "compression" && inquiryTicketState.type === "compression") {
                const compression = inquiryDomain as IRSInquiry<"compression">;
                const marketPackage =
                    compression.packageDirection !== PackageDirection.Opposite
                        ? {
                              packagePriceSpread: inquiryTicketState.state.market.spreadValue?.toString() ?? null,
                              packagePriceSource: getPriceSource(inquiryTicketState.state.market.selectedDriver),
                              manualPackagePrice: inquiryTicketState.state.market.manualPackageFeeValue?.toString() ?? null,
                          }
                        : { packagePriceSource: null, packagePriceSpread: null, manualPackagePrice: null };
                const oppositePackage =
                    compression.packageDirection !== PackageDirection.Market
                        ? {
                              packagePriceSpread: inquiryTicketState.state.opposite.spreadValue?.toString() ?? null,
                              packagePriceSource: getPriceSource(inquiryTicketState.state.opposite.selectedDriver),
                              manualPackagePrice: inquiryTicketState.state.opposite.manualPackageFeeValue?.toString() ?? null,
                          }
                        : { packagePriceSource: null, packagePriceSpread: null, manualPackagePrice: null };

                sessionTransport.send(
                    JSON.stringify({
                        sendingTimestampNanos: ctx.extra.clock.getCurrentTimeNsStr(),
                        msgType: ActionPublisher.InquiryAction.PriceBasisUpdate,
                        ticketId: selectedInquiryTicketId,
                        marketPackage: marketPackage,
                        oppositePackage: oppositePackage,
                        preTradeMidMarketPackagePrice: inquiryTicketState.state.manualPtmm?.toString() ?? null,
                        legs: [],
                    }),
                );
            }

            ctx.dispatch(irs.actions.priceBasisUpdateSent({ ticketId: selectedInquiryTicketId }));
        },
    });

    return { middleware: listener.middleware };
};
