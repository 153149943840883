export enum VenueType {
    Mtf = "MTF",
    Sef = "SEF",
    OffVenue = "OFF VENUE",
}

export enum AssetClass {
    Credit = "CREDIT",
    Irs = "IRS",
}

export enum AutoXMode {
    AutoExecute = "AUTO_EXECUTE",
    AutoReject = "AUTO_REJECT",
    AutoStream = "AUTO_STREAM",
    Manual = "MANUAL",
}
