import React from "react";
import TradeWeb from "./venue-logos/tradeweb.png";
import Bloomberg from "./venue-logos/bloomberg.png";
import Trumid from "./venue-logos/trumid.png";
import MarketAxess from "./venue-logos/marketaxess.png";
import styled from "styled-components";
import { Box } from "../box";
import { Text } from "../text";

const VenueDisplayContainer = styled.div`
    width: ${(props) => props.theme.spacing["28"]};
    height: ${(props) => props.theme.spacing["10"]};
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > * {
        width: 100%;
        height: 100%;
    }
`;

const VenueDisplayFallbackText = styled(Text).attrs({ size: "xs" })`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface VenueDisplayProps {
    venueName: string;
}

export function venueText(venueName: string): string {
    const venueNameUpper = venueName.toUpperCase();
    if (venueNameUpper.startsWith("TRADEWEB")) {
        return "Tradeweb";
    } else if (venueNameUpper.startsWith("BLOOMBERG")) {
        return "Bloomberg";
    } else if (venueNameUpper.startsWith("TRUMID")) {
        return "Trumid";
    } else if (venueNameUpper.startsWith("MARKETAXESS")) {
        return "MarketAxess";
    } else {
        return venueName;
    }
}

export const VenueDisplay: React.FC<VenueDisplayProps> = ({ venueName }) => {
    let innerContent: React.ReactNode;

    const venueNameUpper = venueName.toUpperCase();
    const text = venueText(venueName);
    if (venueNameUpper.startsWith("TRADEWEB")) {
        innerContent = <img data-testid="inquiry-venue-name" src={TradeWeb} alt={text} />;
    } else if (venueNameUpper.startsWith("BLOOMBERG")) {
        innerContent = <img data-testid="inquiry-venue-name" src={Bloomberg} alt={text} />;
    } else if (venueNameUpper.startsWith("TRUMID")) {
        innerContent = <img data-testid="inquiry-venue-name" src={Trumid} alt={text} />;
    } else if (venueNameUpper.startsWith("MARKETAXESS")) {
        innerContent = <img data-testid="inquiry-venue-name" src={MarketAxess} alt={text} />;
    } else {
        innerContent = (
            <Box
                border={{ color: { color: "gray", level: "300" }, width: "px", style: "dashed" }}
                contentAlignment="center"
                contentJustification="center"
                padding="2"
            >
                <VenueDisplayFallbackText>{venueName}</VenueDisplayFallbackText>
            </Box>
        );
    }

    return <VenueDisplayContainer>{innerContent}</VenueDisplayContainer>;
};
