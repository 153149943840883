import { ConsoleLogger, HttpSimpleLogger, LogBroadcaster, LoggerConfiguration } from "./logger";
import type { Logger } from "@transficc/trader-desktop-application-context";

const doCreateLogger = (loggerConfiguration: LoggerConfiguration, failIfOnSharedWorker: boolean): Logger => {
    const consoleLogger = new ConsoleLogger(loggerConfiguration);
    const httpSimpleLogger = new HttpSimpleLogger("/api/secure/weblog", loggerConfiguration);
    return new LogBroadcaster([consoleLogger, httpSimpleLogger], failIfOnSharedWorker);
};

export const createLogger = (loggerConfiguration: LoggerConfiguration): Logger => {
    return doCreateLogger(loggerConfiguration, false);
};

export const loggerConfiguration = new LoggerConfiguration();
const log: Logger = doCreateLogger(loggerConfiguration, true);
export default log;
