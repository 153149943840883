import { Icon, IconNames } from "../icon/icon";
import React from "react";

export interface ConnectionStatusProps {
    connectionStatus: "connected" | "disconnected";
    size: Theme.SpacingLevels;
}

export const ConnectionStatus: React.FC<ConnectionStatusProps> = ({ connectionStatus, size }) => {
    const connectionStatusColor: Theme.Color =
        connectionStatus === "connected" ? { color: "success", level: "400" } : { color: "danger", level: "400" };
    const connectionStatusIcon: IconNames = connectionStatus === "connected" ? IconNames.Heart : IconNames.HeartBroken;
    const hoverTitle = connectionStatus === "connected" ? "Connected" : "Disconnected";

    return (
        <Icon
            name={connectionStatusIcon}
            iconColor={connectionStatusColor}
            size={size}
            hoverTitle={hoverTitle}
            data-testid="connection-status"
        />
    );
};
