import { useEffect } from "react";
import { ConnectionStatus } from "../slices/session/ConnectionStatus";
import { subscribeInquiries } from "../thunks/Subscriptions";
import { useThunkDispatch } from "./useThunkDispatch";
import { useConnectionStatus } from "../slices/session/useSessionSliceSelectors";

export const useInquiriesSubscription = (): void => {
    const dispatch = useThunkDispatch();
    const connectionStatus = useConnectionStatus();

    useEffect(() => {
        if (connectionStatus === ConnectionStatus.CONNECTED) {
            void dispatch(subscribeInquiries());
        }
    }, [connectionStatus, dispatch]);
};
