import { log } from "@transficc/trader-desktop-core";
import { MetricPublisher, SessionTransport, webSocketProtocol } from "@transficc/trader-desktop-application-context";
import {
    closeSocketMessage,
    connectSocketMessage,
    outboundMessageEnvelope,
    WorkerMessage,
    WorkerMessageTypes,
} from "./WorkerMessageDefinitions";
import { DisconnectionReason } from "@transficc/websocket";
import { SharedWorkerFactory } from "./SharedWorkerFactory";
import { createNoSharedWorkerHeartbeatMetric } from "../metrics/sensors";

export const SHARED_WORKER_HEARTBEAT_TIMEOUT_MS = 5000;
export const PAGE_TO_SHARED_WORKER_HEARTBEAT_MS = 2000;

export class SharedWorkerSessionTransport implements SessionTransport {
    private readonly sharedWorkerFactory: SharedWorkerFactory;
    private readonly httpMetricPublisher: MetricPublisher;

    private worker: SharedWorker | null = null;
    private beforeUnloadListener: (() => void) | null = null;
    private onMessageHandler: ((message: string) => void) | null = null;
    private onConnectedHandler: (() => void) | null = null;
    private onDisconnectedHandler: ((reason: DisconnectionReason, description: string | null) => void) | null = null;
    private sharedWorkerAliveCheckTimerId: NodeJS.Timeout | undefined;
    private lastReceivedMessageTimeMillis = 0;

    constructor(sharedWorkerFactory: SharedWorkerFactory, httpMetricPublisher: MetricPublisher) {
        this.sharedWorkerFactory = sharedWorkerFactory;
        this.httpMetricPublisher = httpMetricPublisher;
    }

    open(accessToken: string, idToken: string | null, appInstanceId: string): void {
        const worker = this.sharedWorkerFactory.createSharedWorker();

        this.beforeUnloadListener = (): void => {
            if (worker.port !== null) {
                worker.port.postMessage(closeSocketMessage());
            }
        };
        window.addEventListener("beforeunload", this.beforeUnloadListener);
        this.worker = worker;

        this.startIsSharedWorkerAliveTimer();

        this.lastReceivedMessageTimeMillis = 0;
        this.worker.port.onmessage = (message: MessageEvent<WorkerMessage>): void => {
            this.lastReceivedMessageTimeMillis = Date.now();
            this.workerMessageDispatcher(message.data);
        };
        this.worker.port.start();

        this.postMessage(connectSocketMessage(webSocketProtocol(), accessToken, idToken, appInstanceId));
    }

    close(): void {
        if (this.beforeUnloadListener !== null) {
            window.removeEventListener("beforeunload", this.beforeUnloadListener);
            this.beforeUnloadListener = null;
        }
        this.stopSharedWorkerAliveCheckTimer();
        if (this.worker !== null) {
            this.postMessage(closeSocketMessage());
            this.worker.port.close();
            this.worker = null;
        }
    }

    send(payload: string): void {
        this.postMessage(outboundMessageEnvelope(payload));
    }

    onMessage(handler: (message: string) => void): () => void {
        this.onMessageHandler = handler;

        return () => {
            this.onMessageHandler = null;
        };
    }

    onConnected(handler: () => void): () => void {
        this.onConnectedHandler = handler;

        return () => {
            this.onConnectedHandler = null;
        };
    }

    onDisconnected(handler: (reason: DisconnectionReason, description: string | null) => void): () => void {
        this.onDisconnectedHandler = handler;

        return () => {
            this.onDisconnectedHandler = null;
        };
    }

    private workerMessageDispatcher(workerMessage: WorkerMessage): void {
        switch (workerMessage.msgType) {
            case WorkerMessageTypes.INBOUND_MESSAGE_ENVELOPE:
                this.onMessageHandler?.(workerMessage.payload);
                break;
            case WorkerMessageTypes.SESSION_OPENED_EVENT:
                this.onConnectedHandler?.();
                break;
            case WorkerMessageTypes.SESSION_CLOSED_EVENT:
                this.onDisconnectedHandler?.(workerMessage.reason, workerMessage.description);
                break;
            case WorkerMessageTypes.HEARTBEAT:
                break;
            case WorkerMessageTypes.CONNECT_SOCKET:
            case WorkerMessageTypes.CLOSE_SOCKET:
            case WorkerMessageTypes.OUTBOUND_MESSAGE_ENVELOPE:
                throw new Error(`Unknown worker message type: ${workerMessage.msgType}`);
        }
    }

    private startIsSharedWorkerAliveTimer(): void {
        if (this.sharedWorkerAliveCheckTimerId) {
            this.stopSharedWorkerAliveCheckTimer();
        }

        this.sharedWorkerAliveCheckTimerId = setInterval(() => {
            const durationSinceLastWorkerMessage: number = Date.now() - this.lastReceivedMessageTimeMillis;
            if (durationSinceLastWorkerMessage >= SHARED_WORKER_HEARTBEAT_TIMEOUT_MS) {
                log.warn(`No heartbeat received from sharedWorker for ${durationSinceLastWorkerMessage}ms.`);
                this.httpMetricPublisher.publish(createNoSharedWorkerHeartbeatMetric(durationSinceLastWorkerMessage));
            }
        }, 5000);
    }

    private stopSharedWorkerAliveCheckTimer(): void {
        if (this.sharedWorkerAliveCheckTimerId) {
            clearInterval(this.sharedWorkerAliveCheckTimerId);
        }
    }

    private postMessage(workerMessage: WorkerMessage): void {
        const worker = this.worker;
        if (worker !== null) {
            worker.port.postMessage(workerMessage);
        } else {
            throw new Error("This should never happen");
        }
    }
}
