import { WarningLabel } from "@transficc/components";
import React from "react";

export const IrsCurveMisweightedLabel: React.FC<{ isMisweighted: boolean }> = (props) => {
    return (
        <WarningLabel
            visible={props.isMisweighted}
            message="Misweighted"
            label="Misweighted Package"
            hiddenLabel="Package is not Misweighted"
        />
    );
};
