export enum InquiryAction {
    Quote = "QuoteAction",
    DealerAccept = "AcceptAction",
    DealerReject = "RejectAction",
    PriceBasisUpdate = "PriceBasisUpdateAction",
    AutoStreamRequest = "AutoStreamRequestAction",
    ManualRequest = "ManualRequestAction",
}

export interface QuoteAction {
    msgType: InquiryAction.Quote;
    ticketId: number;
    eventSequence: number;
    packagePrice: string | null;
    oppositePackagePrice: string | null;
    preTradeMidMarketPackagePrice: string | null;
    onTheWireTimeMs: number | null;
    legs: QuoteLeg[];
}

export interface AcceptAction {
    msgType: InquiryAction.DealerAccept;
    ticketId: number;
    eventSequence: number;
}

export interface RejectAction {
    msgType: InquiryAction.DealerReject;
    ticketId: number;
    eventSequence: number;
}

export interface PackagePriceBasis {
    packagePriceSource: PriceSource | null;
    packagePriceSpread: string | null;
    manualPackagePrice: string | null;
}

export interface PriceBasisUpdateAction {
    msgType: InquiryAction.PriceBasisUpdate;
    ticketId: number;
    preTradeMidMarketPackagePrice: string | null;
    marketPackage: PackagePriceBasis | null;
    oppositePackage: PackagePriceBasis | null;
    legs: PriceBasisUpdateLeg[];
}

export interface AutoStreamRequestAction {
    msgType: InquiryAction.AutoStreamRequest;
    ticketId: number;
}

export interface ManualRequestAction {
    msgType: InquiryAction.ManualRequest;
    ticketId: number;
}

export interface PriceBasisUpdateLeg {
    bidPriceSource: PriceSource | null;
    askPriceSource: PriceSource | null;
    bidPriceSpread: string | null;
    askPriceSpread: string | null;
    manualBidPrice: string | null;
    manualAskPrice: string | null;
    preTradeMidMarketPrice: string | null;
    referenceId: string;
}

export enum PriceSource {
    QuotedBid = "QUOTED_BID",
    QuotedMid = "QUOTED_MID",
    QuotedAsk = "QUOTED_ASK",
    AutoquoteBid = "AUTOQUOTE_BID",
    AutoquoteMid = "AUTOQUOTE_MID",
    AutoquoteAsk = "AUTOQUOTE_ASK",
    VenueBid = "VENUE_BID",
    VenueMid = "VENUE_MID",
    VenueAsk = "VENUE_ASK",
}

export enum AutoXModeChangeReason {
    AutoxDisabled = "AUTOX_DISABLED",
    CircuitBreaker = "CIRCUIT_BREAKER",
    SolicitedAPIRequest = "SOLICITED_API_REQUEST",
    Stopped = "STOPPED",
}

export interface QuoteLeg {
    referenceId: string;
    bidPrice: string;
    askPrice: string;
    preTradeMidMarketPrice: string | null;
}

export type Action = RejectAction | QuoteAction | AcceptAction | PriceBasisUpdateAction | AutoStreamRequestAction | ManualRequestAction;

export type ActionPublisher = (action: Action) => void;
