import React from "react";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";
import { Box } from "../box";

export interface ProgressBarProps {
    backgroundColor: Theme.Color;
    foregroundColor: Theme.Color;

    width: Theme.SpacingLevels;
    height: Theme.SpacingLevels;

    rounded?: boolean;

    // 0-1-n - will truncate to 1
    progress: number;

    "data-testid"?: string;
}

const ProgressBarBackground = styled(Box)<{
    width: Theme.SpacingLevels;
    height: Theme.SpacingLevels;
    backgroundColor: Theme.Color;
}>`
    width: ${(props) => props.theme.spacing[props.width]};
    height: ${(props) => props.theme.spacing[props.height]};
    background-color: ${(props) => ThemeUtils.colorSelector(props.theme, props.backgroundColor)};
`;

const ProgressBarForeground = styled(Box)<{ foregroundColor: Theme.Color; progress: number }>`
    background-color: ${(props) => ThemeUtils.colorSelector(props.theme, props.foregroundColor)};
    height: 100%;
    width: ${(props) => (Math.max(0, Math.min(1, props.progress)) * 100).toFixed(0)}%;
    transition: all 0.5s linear;
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({
    backgroundColor,
    foregroundColor,
    width,
    height,
    progress,
    rounded = false,
    ...props
}) => {
    return (
        <ProgressBarBackground
            data-testid={`${props["data-testid"] ?? "progress-bar"}-background`}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            rounded={rounded}
        >
            <ProgressBarForeground
                data-testid={`${props["data-testid"] ?? "progress-bar"}-foreground`}
                foregroundColor={foregroundColor}
                progress={progress}
                rounded={rounded}
                data-progress={progress}
            />
        </ProgressBarBackground>
    );
};
