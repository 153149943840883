import React from "react";
import { defaultTheme, ThemeUtils } from "../../theme";

export interface ProgressCircleProps {
    percentage: number;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({ percentage }) => {
    const stroke = 15; // percentage width
    const normalizedRadius = 30;
    const circumference = normalizedRadius * 2 * Math.PI;

    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    const animationName = `circleProgress-${percentage}`;
    const circleProgress = `
        @keyframes ${animationName} {
            0% { stroke-dashoffset: 377; }
            100% { stroke-dashoffset: ${strokeDashoffset}; }
        }
        `;

    const margin = 10;
    const viewPortSize = normalizedRadius * 2.5 + margin;
    const centerOffset = viewPortSize / 2;

    const circleStyle = {
        fill: "transparent",
        strokeDasharray: ` ${circumference} ${circumference}`,
        transformOrigin: `${centerOffset}px ${centerOffset}px`,
    };

    let color: Theme.SemanticColors;
    if (percentage > 70) {
        color = "success";
    } else if (percentage > 40) {
        color = "warning";
    } else {
        color = "danger";
    }
    return (
        <div style={{ display: "flex" }}>
            <svg height={viewPortSize} width={viewPortSize}>
                <circle
                    stroke={"#e6e6e6"}
                    transform={"rotate(-90)"}
                    style={{ ...circleStyle, strokeDashoffset: 0, strokeWidth: stroke + "%" }}
                    r={normalizedRadius}
                    cx={centerOffset}
                    cy={centerOffset}
                />
                <circle
                    stroke={ThemeUtils.colorSelector(defaultTheme, { color: color, level: "400" })}
                    transform={"rotate(-90)"}
                    style={{
                        ...circleStyle,
                        strokeDashoffset: strokeDashoffset,
                        strokeWidth: stroke * 0.95 + "%",
                        animation: `${animationName} 1.0s forwards`,
                    }}
                    r={normalizedRadius}
                    cx={centerOffset}
                    cy={centerOffset}
                />
                <text
                    x={"50%"}
                    y={"50%"}
                    textAnchor={"middle"}
                    dy={".3em"}
                    fontSize={"15"}
                    style={{
                        fill: "white",
                    }}
                >
                    {`${percentage}%`}
                </text>
            </svg>
            <style>
                {`
                    ${circleProgress}
                `}
            </style>
        </div>
    );
};

export default ProgressCircle;
