import React from "react";
import {
    Box,
    Button,
    formatIntDateAsString,
    formatPriceOrDefaultEmptyValue,
    formatPriceOrEmptyValue,
    formatWithCommas,
    semanticButtonStyling,
    Text,
    useRerenderOnInterval,
} from "@transficc/components";
import { Inquiry, InquirySide, PriceType, TriggerEvent } from "../credit-domain";
import { getSideBackgroundColor } from "../credit-domain/constants";
import styled from "styled-components";
import { CreditInquiryPriceMatrix } from "./credit-inquiry-price-matrix";
import { formatQuantity } from "../formatters/formatQuantity";
import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { CreditPriceInput } from "./credit-price-input";
import { CreditTicket, useDisplayedInquiry } from "../credit-slice/useCreditSliceSelectors";
import { CreditInquirySpreadInfo } from "./credit-inquiry-spread-info";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { OnTheWireButton } from "@transficc/trader-desktop-shared-components";
import { LocalStorageKeys, setItemInLocalStorage } from "@transficc/trader-desktop-local-storage";

export interface TicketBodyProps {
    timezoneProvider: TimezoneProvider;
    actionPublisher: ActionPublisher.ActionPublisher;
    clock: EpochClock;
    initialUserDefinedOnTheWireTimeMs: number;
}

function getSide(side: InquirySide): string {
    switch (side) {
        case InquirySide.Sell:
            return "SELL";
        case InquirySide.Buy:
            return "BUY";
        default:
            side satisfies never;
            throw new Error();
    }
}

const getPriceIndicator = (inquiry: Inquiry): JSX.Element => {
    const leg = inquiry.leg;
    if (leg.customerBidPrice && inquiry.triggerEvent === TriggerEvent.Counter) {
        return <PriceIndicator price={leg.customerBidPrice} text={"Customer Counter"} minPriceIncrement={leg.minPriceIncrement} />;
    } else if (leg.customerAskPrice && inquiry.triggerEvent === TriggerEvent.Counter) {
        return <PriceIndicator price={leg.customerAskPrice} text={"Customer Counter"} minPriceIncrement={leg.minPriceIncrement} />;
    } else if (leg.tieBreakPrice) {
        return <PriceIndicator price={leg.tieBreakPrice} text={"Customer Tie Break"} minPriceIncrement={leg.minPriceIncrement} />;
    } else {
        return <Box height={"16"} data-testid="no-price-indicator-displayed" />;
    }
};

const InquiryDetailsContainer = styled(Box)`
    width: 32rem;
`;

const getInstrumentDescriptionFontSize = (length: number): Theme.FontSizes => {
    switch (true) {
        case length > 44:
            return "base";
        case length > 40:
            return "lg";
        case length > 33:
            return "xl";
        default:
            return "2xl";
    }
};

const getSideFontSize = (length: number): Theme.FontSizes => {
    switch (true) {
        case length > 44:
            return "sm";
        case length > 40:
            return "base";
        case length > 33:
            return "lg";
        default:
            return "xl";
    }
};

const getButtonSecondaryTextFontSize = (length: number): Theme.FontSizes => {
    switch (true) {
        case length > 14:
            return "2xs";
        case length > 8:
            return "xs";
        default:
            return "sm";
    }
};

const ButtonSecondaryText: React.FC<{ children: string }> = ({ children }) => {
    return (
        <Text as="span" weight={"medium"} size={getButtonSecondaryTextFontSize(children.length)}>
            {children}
        </Text>
    );
};

const PriceIndicator: React.FC<{ price: string; text: string; minPriceIncrement: string }> = ({ price, text, minPriceIncrement }) => {
    return (
        <Box
            rounded
            backgroundColor={{ color: "warning", level: "300" }}
            contentAlignment={"center"}
            contentJustification={"center"}
            height={"16"}
            data-testid="price-indicator"
        >
            <Text textColor={{ color: "black" }} weight={"bold"} size={"sm"}>
                {text}
            </Text>
            <Text textAlignment={"center"} textColor={{ color: "black" }} size={"sm"}>
                @ {formatPriceOrDefaultEmptyValue(price, minPriceIncrement)}
            </Text>
        </Box>
    );
};

export const CreditInquiryBody: React.FC<TicketBodyProps> = ({
    timezoneProvider,
    actionPublisher,
    clock,
    initialUserDefinedOnTheWireTimeMs,
}) => {
    const inquiry = useDisplayedInquiry();
    const { value: priceValue } = CreditTicket.usePriceValueForInquiry(inquiry.ticketId);

    const [userDefinedOnTheWireTimeMs, setUserDefinedOnTheWireTimeMs] = React.useState(initialUserDefinedOnTheWireTimeMs);

    const onClickQuote = (): void => {
        function getOnTheWireTimeMs(): number | null {
            if (inquiry.quoteIsAlwaysFirm) {
                return null;
            }
            return venueSpecifiedOnTheWireTimeMs ? venueSpecifiedOnTheWireTimeMs : userDefinedOnTheWireTimeMs;
        }

        actionPublisher({
            msgType: ActionPublisher.InquiryAction.Quote,
            ticketId: inquiry.ticketId,
            eventSequence: inquiry.eventSequence,
            packagePrice: null,
            oppositePackagePrice: null,
            preTradeMidMarketPackagePrice: null,
            onTheWireTimeMs: getOnTheWireTimeMs(),
            legs: [
                {
                    referenceId: inquiry.leg.referenceId,
                    bidPrice: inquiry.leg.side === InquirySide.Buy ? priceValue?.toString() ?? "" : "",
                    askPrice: inquiry.leg.side === InquirySide.Sell ? priceValue?.toString() ?? "" : "",
                    preTradeMidMarketPrice: null,
                },
            ],
        });
    };

    const onClickAccept = (): void => {
        actionPublisher({
            msgType: ActionPublisher.InquiryAction.DealerAccept,
            ticketId: inquiry.ticketId,
            eventSequence: inquiry.eventSequence,
        });
    };

    const onClickReject = (): void => {
        actionPublisher({
            msgType: ActionPublisher.InquiryAction.DealerReject,
            ticketId: inquiry.ticketId,
            eventSequence: inquiry.eventSequence,
        });
    };

    const onTheWireTimeChangedCallback = (onTheWireTimeSeconds: number): void => {
        setUserDefinedOnTheWireTimeMs(onTheWireTimeSeconds);
        setItemInLocalStorage(LocalStorageKeys.USER_DEFINED_QUOTE_OTW_TIME_MILLIS, onTheWireTimeSeconds.toString());
    };

    const {
        isDisabled: quoteButtonDisabled,
        quoteOtwIsConfigurable,
        venueSpecifiedOnTheWireTimeMs,
    } = CreditTicket.useQuoteButtonState(inquiry.ticketId);
    const {
        isDisabled: acceptButtonDisabled,
        valueDealerIsAccepting,
        minPriceIncrement,
    } = CreditTicket.useAcceptButtonState(inquiry.ticketId);
    const { isDisabled: rejectButtonDisabled } = CreditTicket.useRejectButtonState(inquiry.ticketId);

    const formattedAcceptValue = formatWithCommas(formatPriceOrEmptyValue(valueDealerIsAccepting, minPriceIncrement));

    const sideColor = getSideBackgroundColor(inquiry.leg.side);
    const priceIndicator = getPriceIndicator(inquiry);

    const settlementDateText =
        formatIntDateAsString(inquiry.leg.settlementDate) +
        (inquiry.leg.relativeSettlementDate !== null ? ` (${inquiry.leg.relativeSettlementDate})` : "");

    useRerenderOnInterval(500);

    return (
        <Box contentDirection={"row"} padding={"4"} paddingT={"0"}>
            <Box contentDirection={"column"}>
                <Box height={"12"} contentDirection="row" contentAlignment={"center"} contentJustification={"start"}>
                    <Box backgroundColor={sideColor} marginR={"2"} paddingL={"2"} paddingR={"2"} width={"20"}>
                        <Text
                            data-testid="side"
                            size={getSideFontSize(inquiry.leg.instrumentName.length)}
                            textAlignment={"center"}
                            textColor={{ color: "black" }}
                            title={inquiry.leg.side}
                            weight={"bold"}
                            truncateWithEllipsis
                        >
                            {getSide(inquiry.leg.side)}
                        </Text>
                    </Box>
                    <Box contentAlignment={"center"} paddingT={"0.5"}>
                        <Text
                            data-testid="instrument-description"
                            textColor={{ color: "white" }}
                            size={getInstrumentDescriptionFontSize(inquiry.leg.instrumentName.length)}
                            textAlignment={"center"}
                            title={inquiry.leg.instrumentName}
                            weight={"bold"}
                            truncateWithEllipsis
                        >
                            {inquiry.leg.instrumentName}
                        </Text>
                    </Box>
                </Box>
                <InquiryDetailsContainer backgroundColor={{ color: "cyan", level: "800" }}>
                    <CreditInquirySpreadInfo timezoneProvider={timezoneProvider} />
                    <Box contentDirection={"row"} padding={"4"}>
                        <Box contentDirection={"column"} width="full" contentAlignment="center">
                            <Box height={"6"} />
                            <Box
                                border={{ color: { color: "white" }, width: "px" }}
                                width="full"
                                padding="2"
                                contentAlignment="center"
                                data-testid="settlement-date"
                            >
                                <Text size={settlementDateText.length >= 16 ? "lg" : "xl"}>{settlementDateText}</Text>
                            </Box>
                            <Box height={"4"} />
                            <Box
                                border={{ color: sideColor, width: "px" }}
                                backgroundColor={sideColor}
                                width="full"
                                padding="2"
                                contentAlignment="center"
                                data-testid="quantity"
                            >
                                <Text size={"xl"} textColor={{ color: "black" }}>
                                    {formatQuantity(inquiry.leg.quantity)}
                                </Text>
                            </Box>
                        </Box>

                        <Box contentDirection={"column"} width="full" contentAlignment="center" paddingL={"4"}>
                            <CreditPriceInput forPriceType={PriceType.Spread} ticketId={inquiry.ticketId} clock={clock} />
                            <Box height={"4"} />
                            <CreditPriceInput forPriceType={PriceType.PercentOfPar} ticketId={inquiry.ticketId} clock={clock} />
                            <Box height={"4"} />
                            <CreditPriceInput forPriceType={PriceType.Yield} ticketId={inquiry.ticketId} clock={clock} />
                        </Box>
                    </Box>
                </InquiryDetailsContainer>

                <CreditInquiryPriceMatrix ticketId={inquiry.ticketId} timezoneProvider={timezoneProvider} clock={clock} />
            </Box>
            <Box contentDirection={"column"} marginL={"4"} width={"full"}>
                {priceIndicator}
                <Box height={"6"} />
                <OnTheWireButton
                    userDefinedOtwTimeMs={initialUserDefinedOnTheWireTimeMs}
                    otwTimeConfigurable={quoteOtwIsConfigurable}
                    venueRequestedOtwTimeMs={venueSpecifiedOnTheWireTimeMs}
                    buttonTitle="Quote"
                    onButtonClick={onClickQuote}
                    buttonDisabled={quoteButtonDisabled}
                    onTheWireTimeChangedMsCallback={onTheWireTimeChangedCallback}
                />
                <Box height={"4"} />
                <Button
                    title={
                        <>
                            Accept
                            <br />
                            <ButtonSecondaryText>{formattedAcceptValue}</ButtonSecondaryText>
                        </>
                    }
                    onClick={onClickAccept}
                    size={"lg"}
                    height="20"
                    styling={semanticButtonStyling("success")}
                    disabled={acceptButtonDisabled}
                />
                <Box height={"4"} />
                <Button
                    title={"Reject"}
                    onClick={onClickReject}
                    size={"lg"}
                    styling={semanticButtonStyling("danger")}
                    disabled={rejectButtonDisabled}
                />
            </Box>
        </Box>
    );
};
