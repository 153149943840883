import React, { useCallback } from "react";
import styled from "styled-components";
import {
    Box,
    BoxProps,
    Button,
    formatPriceOrDefaultEmptyValue,
    formatWithCommas,
    IconNames,
    semanticButtonStyling,
} from "@transficc/components";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import {
    useActionButtonsModel,
    useDefinitelySelectedInquiryTicketId,
    useQuoteActionValues,
    useSelectedInquiryAcceptDisabled,
    useSelectedInquiryAutoStreamDisabled,
    useSelectedInquiryAutoStreamStopDisabled,
    useSelectedInquiryEventSequence,
    useSelectedInquiryQuoteDisabled,
    useSelectedInquiryRejectDisabled,
} from "../irs-slice/irs-selectors";
import { InquiryAction } from "packages/trader-desktop/shared-domain/src/action-publisher";
import { useEnvironmentConfig } from "@transficc/trader-desktop-application-context";

export interface IRSActionButtonsProps extends IRSStreamingButtonsProps {
    publishAction: ActionPublisher.ActionPublisher;
}

interface IRSStreamingButtonsProps {
    onAutoStreamStartClick?: () => void;
    onAutoStreamStopClick?: () => void;
}

const ButtonContainer = styled(Box).attrs({
    height: "full",
    width: "48",
    contentJustification: "start",
    contentAlignment: "center",
} as BoxProps)`
    & > ${Box} {
        width: 100%;
    }
`;

const StreamingButton: React.FC<IRSStreamingButtonsProps> = ({ onAutoStreamStopClick, onAutoStreamStartClick }) => {
    const autoStreamStopDisabled = useSelectedInquiryAutoStreamStopDisabled();
    const autoStreamDisabled = useSelectedInquiryAutoStreamDisabled();

    return autoStreamStopDisabled ? (
        <Button
            title="Stream"
            size="lg"
            styling={semanticButtonStyling("info")}
            leftIcon={{
                name: IconNames.PlayCircle,
                size: "5",
                iconColor: autoStreamDisabled ? { color: "gray", level: "600" } : { color: "success", level: "300" },
            }}
            disabled={autoStreamDisabled}
            onClick={onAutoStreamStartClick}
        />
    ) : (
        <Button
            title="Stop"
            size="lg"
            styling={semanticButtonStyling("info")}
            leftIcon={{
                name: IconNames.Stop,
                size: "5",
                iconColor: autoStreamStopDisabled ? { color: "gray", level: "600" } : { color: "danger", level: "400" },
            }}
            disabled={autoStreamStopDisabled}
            onClick={onAutoStreamStopClick}
        />
    );
};

const AcceptButtonSecondaryText = styled.span<{ size: Theme.FontSizes }>`
    font-size: ${(props) => (typeof props.size === "object" ? props.size.custom : props.theme.font.sizes[props.size])};
`;

export const IRSActionButtons: React.FC<IRSActionButtonsProps> = ({ publishAction }) => {
    const eventSequence = useSelectedInquiryEventSequence();
    const ticketId = useDefinitelySelectedInquiryTicketId();
    const { customerAskPrice, customerBidPrice, minPriceIncrement } = useActionButtonsModel();
    const { autoStreaming } = useEnvironmentConfig().featureFlags;

    function label(text: string): JSX.Element {
        return (
            <>
                <br />
                <AcceptButtonSecondaryText size={"sm"}>{text}</AcceptButtonSecondaryText>
            </>
        );
    }

    const undisclosed = customerAskPrice && customerBidPrice;
    const oneSided = customerAskPrice ? customerAskPrice : customerBidPrice;

    const formattedCustomerBidPrice = formatPriceOrDefaultEmptyValue(customerBidPrice, minPriceIncrement);
    const formattedCustomerAskPrice = formatPriceOrDefaultEmptyValue(customerAskPrice, minPriceIncrement);

    const acceptTitle = (
        <>
            Accept
            {undisclosed && label(`${formatWithCommas(formattedCustomerBidPrice)} / ${formatWithCommas(formattedCustomerAskPrice)}`)}
            {!undisclosed && oneSided && label(formatWithCommas(oneSided))}
        </>
    );

    const quoteActionValues = useQuoteActionValues();

    const quoteDisabled = useSelectedInquiryQuoteDisabled();
    const acceptDisabled = useSelectedInquiryAcceptDisabled();
    const rejectDisabled = useSelectedInquiryRejectDisabled();

    const onAcceptClick = useCallback(() => {
        publishAction({
            msgType: ActionPublisher.InquiryAction.DealerAccept,
            ticketId: ticketId,
            eventSequence: eventSequence,
        });
    }, [publishAction, ticketId, eventSequence]);
    const onRejectClick = useCallback(() => {
        publishAction({
            msgType: ActionPublisher.InquiryAction.DealerReject,
            ticketId: ticketId,
            eventSequence: eventSequence,
        });
    }, [publishAction, ticketId, eventSequence]);
    const onAutoStreamStartClick = useCallback(() => {
        publishAction({
            msgType: ActionPublisher.InquiryAction.AutoStreamRequest,
            ticketId: ticketId,
        });
    }, [publishAction, ticketId]);
    const onAutoStreamStopClick = useCallback(() => {
        publishAction({
            msgType: ActionPublisher.InquiryAction.ManualRequest,
            ticketId: ticketId,
        });
    }, [publishAction, ticketId]);
    const quoteClickHandler = useCallback(() => {
        if (quoteDisabled) {
            return;
        }
        publishAction({ msgType: InquiryAction.Quote, ...quoteActionValues });
    }, [publishAction, quoteActionValues, quoteDisabled]);

    return (
        <ButtonContainer>
            {autoStreaming === true && (
                <>
                    <StreamingButton onAutoStreamStartClick={onAutoStreamStartClick} onAutoStreamStopClick={onAutoStreamStopClick} />
                    <Box margin={"2"} />
                </>
            )}
            <Button title="Quote" size="lg" styling={semanticButtonStyling("info")} disabled={quoteDisabled} onClick={quoteClickHandler} />
            <Box margin={"2"} />
            <Button
                title={acceptTitle}
                size="lg"
                styling={semanticButtonStyling("success")}
                height="20"
                disabled={acceptDisabled}
                onClick={onAcceptClick}
            />
            <Box margin={"2"} />
            <Button title="Reject" size="lg" styling={semanticButtonStyling("danger")} disabled={rejectDisabled} onClick={onRejectClick} />
            <Box margin={"2"} />
        </ButtonContainer>
    );
};
