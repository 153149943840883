import { NumberInputTitleLabelConfig } from "@transficc/components";
import { PackageDirection } from "../irs-domain";

export const mktLabelConfig: NumberInputTitleLabelConfig = {
    text: "MKT",
    backgroundColor: { color: colourForDirection(PackageDirection.Market), level: "400" },
    foregroundColor: { color: "black" },
};
export const oppLabelConfig: NumberInputTitleLabelConfig = {
    text: "OPP",
    backgroundColor: { color: colourForDirection(PackageDirection.Opposite), level: "800" },
    foregroundColor: { color: "white" },
};

export function colourForDirection(packageDirection: PackageDirection): Theme.ShadedColors {
    switch (packageDirection) {
        case PackageDirection.Market:
            return "lime";
        case PackageDirection.Opposite:
            return "gold";
        default:
            throw new Error("Don't know direction: " + packageDirection);
    }
}
