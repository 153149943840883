import React from "react";
import {
    Box,
    Button,
    CurrencyDisplay,
    Icon,
    IconNames,
    semanticButtonStyling,
    StackedTimerProgress,
    Text,
    TierDisplay,
    VenueDisplay,
} from "@transficc/components";
import styled from "styled-components";
import { EpochClock } from "@transficc/infrastructure";
import { NumberOfDealers } from "./number-of-dealers";
import { useDisplayedInquiry } from "../credit-slice/useCreditSliceSelectors";
import { CounterpartyType } from "../credit-domain";
import { OpenCustomerAnalytics } from "./selected-credit-inquiry";
import { VenueTypeDisplay } from "@transficc/trader-desktop-shared-components";

const TicketIdLabel = styled(Text)`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface HeaderProps {
    config: {
        clock: EpochClock;
        showCustomerAnalytics: boolean;
    };
    callbacks: {
        openCustomerAnalyticsWindow: OpenCustomerAnalytics;
    };
}

const CounterpartyInformation: React.FC = () => {
    const { counterpartyFirm, isCounterpartyAnonymous, counterpartyType, counterpartyTrader, counterpartyRank } = useDisplayedInquiry();

    if (!isCounterpartyAnonymous) {
        return (
            <>
                <Text title="Customer Firm" data-testid="inquiry-counter-party-firm">
                    {counterpartyFirm}
                </Text>
                <Text title="Customer Trader" data-testid="inquiry-counter-party-trader">
                    {counterpartyTrader}
                </Text>
            </>
        );
    }

    const stars: React.ReactNode =
        counterpartyRank === null || counterpartyRank <= 0 ? null : (
            <Box
                contentDirection={"row"}
                contentAlignment={"center"}
                marginT={"1"}
                title={`Counterparty ranking: ${counterpartyRank}`}
                data-testid={"inquiry-counter-party-rank"}
                data-counter-party-rank={counterpartyRank}
            >
                {Array(5)
                    .fill(null)
                    .map((ignored, index) => {
                        const isFilled = index < counterpartyRank;
                        return (
                            <Icon
                                key={index}
                                iconColor={{ color: "warning", level: "300" }}
                                name={isFilled ? IconNames.Star : IconNames.StarOutline}
                                size={"6"}
                            />
                        );
                    })}
                {counterpartyRank > 5 ? (
                    <>
                        <Box marginL={"2"} />
                        <Text data-testid="inquiry-counter-party-rank-label" size="xl" textColor={{ color: "warning", level: "300" }}>
                            {counterpartyRank}
                        </Text>
                    </>
                ) : null}
            </Box>
        );

    if (counterpartyType === CounterpartyType.Dealer) {
        return (
            <>
                <Text data-testid="inquiry-counter-party-firm">
                    Anonymous{" "}
                    <Text as="span" weight={"bold"} textColor={{ color: "warning", level: "300" }}>
                        Dealer
                    </Text>
                </Text>
                {stars}
            </>
        );
    } else {
        return (
            <>
                <Text data-testid="inquiry-counter-party-firm">Anonymous Customer</Text>
                {stars}
            </>
        );
    }
};

export const CreditInquiryHeader: React.FC<HeaderProps> = ({ config, callbacks }) => {
    const inquiry = useDisplayedInquiry();

    const timerProgress =
        inquiry.timerStartTimestampMillis !== null && inquiry.timerEndTimestampMillis !== null ? (
            <StackedTimerProgress
                startTimeEpochMs={parseInt(inquiry.timerStartTimestampMillis)}
                endTimeEpochMs={parseInt(inquiry.timerEndTimestampMillis)}
                updateIntervalMs={200}
                clock={config.clock}
            />
        ) : (
            <Text srOnly>Timer is unavailable</Text>
        );

    return (
        <Box width={"full"} contentDirection={"row"} padding={"4"} paddingB={"0"} height={"48"}>
            <Box width={"full"}>
                <NumberOfDealers />
                <Box height={"3"} />
                <Box contentDirection={"row"}>
                    <TierDisplay tier={inquiry.tier} />
                    {config.showCustomerAnalytics ? (
                        <Box marginL={"1"}>
                            <Button
                                styling={{ ...semanticButtonStyling("info"), unselectedBackgroundColor: { color: "transparent" } }}
                                width={"8"}
                                height={"8"}
                                size={"sm"}
                                onClick={() => callbacks.openCustomerAnalyticsWindow(inquiry.counterpartyFirm, inquiry.counterpartyTrader)}
                                leftIcon={{ size: "6", iconColor: { color: "white" }, name: IconNames.TrendUp }}
                                hoverTitle={"Customer Analytics"}
                            />
                        </Box>
                    ) : null}
                </Box>
                <Box height={"2"} />
                <CounterpartyInformation />
                <Text data-testid="inquiry-sales-person">Salesperson: {inquiry.salesPersonName || inquiry.salesPersonId || "-"}</Text>
            </Box>
            <Box>
                <Box contentDirection={"row"}>
                    <VenueDisplay venueName={inquiry.venueName} />
                    <Box width={"2"} />
                    <CurrencyDisplay isoCode={inquiry.leg.currency} />
                </Box>
                <Box height={"4"} paddingT={"0.5"}>
                    <VenueTypeDisplay venueType={inquiry.venueType} />
                </Box>
                <Box contentDirection={"column"} contentAlignment={"end"}>
                    <Text srOnly>Timer</Text>
                    <Box height={"12"}>{timerProgress}</Box>
                </Box>
                <Box height={"2"} />
                <Box contentAlignment={"end"}>
                    <TicketIdLabel data-testid="inquiry-ticket-id">#{inquiry.ticketId}</TicketIdLabel>
                </Box>
            </Box>
        </Box>
    );
};
