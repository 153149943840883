import * as React from "react";
import { RefObject, useCallback, useEffect, useRef } from "react";

import { GridOptions, LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, ViewportChangedEvent } from "ag-grid-community";
import { BlotterRow } from "./historical-blotter";
import { historicalBlotterColumnDefinitions } from "./historical-blotter-column-definitions";
import { AG_GRID_CLASS_NAME, AG_GRID_LICENSE, AgGridTheme, saveAndRestoreColumnState } from "@transficc/trader-desktop-ag-grid-common";
import { Box } from "@transficc/components";
import { LocalStorageKeys } from "@transficc/trader-desktop-local-storage";

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

export interface HistoricalBlotterPanelProps {
    hdsGatewayUrl: string;
    accessToken: string;
    blotterRows: BlotterRow[];
    fetchData: () => void;
    shouldAutoRefresh?: boolean;
    gridRef: RefObject<AgGridReact<BlotterRow>>;
}

const getDataPath = (row: BlotterRow): string[] => row.path;
const getRowId = ({ data }: { data: BlotterRow }): string =>
    `ticketId:${data.ticketId}-legPosition:${data.legPosition}--venueId:${data.venueId}`;

const gridOptions: GridOptions = {
    columnDefs: historicalBlotterColumnDefinitions,
    defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
    },
    suppressColumnVirtualisation: true,
    treeData: true,
    animateRows: false,
    groupDefaultExpanded: 0,
    groupDisplayType: "custom",
    getDataPath: getDataPath,
    getRowId: getRowId,
};

export const HistoricalBlotterPanel: React.FunctionComponent<HistoricalBlotterPanelProps> = ({
    blotterRows,
    fetchData,
    shouldAutoRefresh,
    gridRef,
}) => {
    const onGridReady = useCallback(
        (gridReadyEvent: GridReadyEvent) => {
            fetchData();
            saveAndRestoreColumnState(gridReadyEvent.api, LocalStorageKeys.HISTORICAL_BLOTTER_COLUMN_STATE);
        },
        [fetchData],
    );

    const refreshTimer: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);

    const setTimer: () => void = useCallback(() => {
        if (refreshTimer.current === null) {
            refreshTimer.current = setInterval(() => {
                fetchData();
            }, 5000);
        }
    }, [fetchData]);

    const clearTimer: () => void = () => {
        if (refreshTimer.current !== null) {
            clearInterval(refreshTimer.current);
            refreshTimer.current = null;
        }
    };

    useEffect(() => {
        if (shouldAutoRefresh) {
            setTimer();
        } else {
            clearTimer();
        }
    }, [shouldAutoRefresh, setTimer]);

    const onViewportChanged = useCallback(
        (params: ViewportChangedEvent) => {
            const onFirstPage = params.firstRow === 0;
            if (onFirstPage) {
                setTimer();
            } else {
                clearTimer();
            }
        },
        [setTimer],
    );

    return (
        <Box height={"full"} data-testid={"historical-blotter-panel-container"}>
            <AgGridTheme />
            <AgGridReact
                className={AG_GRID_CLASS_NAME}
                rowData={blotterRows}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                ref={gridRef}
                onViewportChanged={onViewportChanged}
                statusBar={{
                    statusPanels: [{ statusPanel: "agTotalAndFilteredRowCountComponent" }],
                }}
            />
        </Box>
    );
};
