import { InquirySide, InquiryState, PricerState, PriceType } from "./enums";
import { AssetClass, VenueType } from "@transficc/trader-desktop-shared-domain";

export { PriceType, InquirySide, InquiryState, PricerState };
export { AssetClass, VenueType };

export interface Prices {
    bid: string | null;
    mid: string | null;
    ask: string | null;
}

export interface Leg {
    instrumentName: string;
    quantity: string;
    side: InquirySide;
    priceType: PriceType;
    currency: string;
    latestQuotedPrices: Prices;
    autoQuotedPrices: Prices;
    position: number;
    referenceId: string;
    settlementDate: number | null;
    relativeSettlementDate: string | null;
    minPriceIncrement: string;
    customerBidPrice: string | null;
    customerAskPrice: string | null;
    negotiatedPrice: string | null;
    benchmarkName: string | null;
    tieBreakPrice: string | null;
}

export enum SpotNegotiationModel {
    VenuePriced = "venuepriced",
    VenuePricedDelayed = "venuepriced_delayed",
}

export enum CounterpartyType {
    Customer = "customer",
    Dealer = "dealer",
}

export enum HedgingType {
    Cross = "cross",
    Spot = "spot",
}

export enum BenchmarkHedge {
    Auto = "Auto-Hedge",
    Manual = "Manual-Hedge",
}

export enum AllowedAction {
    DealerAccept = "dealer_accept",
    DealerReject = "dealer_reject",
    Quote = "quote",
}

export enum TriggerEvent {
    ActionFailure = "ACTION_FAILURE",
    Counter = "COUNTER",
    CurtainPeriodExpired = "CURTAIN_PERIOD_EXPIRED",
    CustomerAccept = "CUSTOMER_ACCEPT",
    CustomerCancel = "CUSTOMER_CANCEL",
    CustomerReject = "CUSTOMER_REJECT",
    CustomerTimeout = "CUSTOMER_TIMEOUT",
    DealerAccept = "DEALER_ACCEPT",
    DealerReject = "DEALER_REJECT",
    DealerTimeout = "DEALER_TIMEOUT",
    HitLift = "HIT_LIFT",
    InquiryError = "INQUIRY_ERROR",
    InquiryPickedUpOnVenue = "INQUIRY_PICKED_UP_ON_VENUE",
    LastLookExpired = "LAST_LOOK_EXPIRED",
    NegotiationReport = "NEGOTIATION_REPORT",
    OnTheWireTimeExpired = "ON_THE_WIRE_TIME_EXPIRED",
    PriceConfirmationAgreed = "PRICE_CONFIRMATION_AGREED",
    QuoteRefreshRequest = "QUOTE_REFRESH_REQUEST",
    QuoteRequest = "QUOTE_REQUEST",
    QuoteResponse = "QUOTE_RESPONSE",
    ReplaceSpot = "REPLACE_SPOT",
    SpotAgreed = "SPOT_AGREED",
    TieBreak = "TIE_BREAK",
    TradedNegotiationReport = "TRADED_NEGOTIATION_REPORT",
}

export interface CreditStateCallbacks {
    closeActionFailure: (ticketId: number, actionId: string) => void;
}

export interface Inquiry {
    eventSequence: number;
    ticketId: number;
    venueSpecifiedOnTheWireTimeMillis: number | null;
    aggregateId: string;
    allowedActions: AllowedAction[];
    triggerEvent: TriggerEvent;
    venueName: string;
    venueType: VenueType | null;
    assetClass: AssetClass | null;
    tier: number | null;
    numberOfDealers: number | null;
    counterpartyFirm: string;
    counterpartyTrader: string;
    salesPersonId: string | null;
    salesPersonName: string | null;
    isCounterpartyAnonymous: boolean | null;
    counterpartyType: CounterpartyType | null;
    counterpartyRank: number | null;
    timerStartTimestampMillis: string | null;
    timerEndTimestampMillis: string | null;
    state: InquiryState;
    leg: Leg;
    autoQuotePriceState: PricerState;
    autoQuotePriceError: string | null;
    autoQuotePricesGenerationTimestampNanos: string | null;
    autoQuotePricesValidForNanos: string | null;
    isFromSnapshot: boolean;
    latestActionFailure: ActionFailure | null;
    userId: number;
    hedgingType: HedgingType | null;
    spotNegotiationModel: SpotNegotiationModel | null;
    spotRequestTimeNanos: string | null;
    listId: string | null;
    numberOfTotalListItems: number | null;
    isAllToAll: boolean | null;
    owner: string | null;
    quoteIsAlwaysFirm: boolean | null;
    creationTime: number;
    benchmarkHedge: BenchmarkHedge | null;
}

export interface ActionFailure {
    actionId: string;
    reason: string;
    closed: boolean;
}
