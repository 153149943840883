import * as React from "react";
import { RefObject, useCallback, useState } from "react";
import styled from "styled-components";
import { BlotterRow } from "./historical-blotter";
import { AgGridReact } from "ag-grid-react";
import { TimezoneProvider, useMemoMappedList } from "@transficc/infrastructure";
import { HDSProtocol } from "@transficc/hds-public-protocol-types";
import {
    Box,
    Button,
    DatePicker,
    Multiselect,
    MultiselectOption,
    semanticButtonStyling,
    Text,
    TextInput,
    TransficcLogo,
} from "@transficc/components";

export interface UserGroup {
    id: number;
    name: string;
}

export interface HdsBlotterControllerProps {
    hdsGatewayUrl: string;
    timezoneProvider: TimezoneProvider;
    accessToken: string;
    submitCallback: (
        startTime: number | null,
        endTime: number | null,
        customerFirm: string | null,
        instrumentId: string | null,
        aggregateId: string | null,
        minSize: number | null,
        maxSize: number | null,
        states: string | null,
        userGroups: string | null,
    ) => void;
    removeFilterCallback: () => void;
    isLiveMode: boolean;
    gridRef: RefObject<AgGridReact<BlotterRow>>;
    message: string;
    queryInProgress: boolean;
    userGroups: UserGroup[];
}

const FlowContainer = styled(Box)`
    flex-wrap: wrap;
`;

const STATE_OPTIONS: MultiselectOption<HDSProtocol.State>[] = [
    { value: HDSProtocol.State.Done, label: "DONE" },
    { value: HDSProtocol.State.NotDoneDealerReject, label: "DEALER REJECT" },
    { value: HDSProtocol.State.NotDoneCustomerReject, label: "CUSTOMER REJECT" },
    { value: HDSProtocol.State.NotDoneDealerTimeout, label: "DEALER TIMEOUT" },
    { value: HDSProtocol.State.NotDoneCustomerTimeout, label: "CUSTOMER TIMEOUT" },
    { value: HDSProtocol.State.InquiryError, label: "ERROR" },
    { value: HDSProtocol.State.PickedUpOnVenueUI, label: "PICKED UP ON VENUE UI" },
];

const newEmptyQuery = (): BackendQuery => {
    return new BackendQuery("", "", "", [], "", "", null, null, []);
};

class BackendQuery {
    constructor(
        readonly customerFirm: string,
        readonly instrumentId: string,
        readonly aggregateId: string,
        readonly states: HDSProtocol.State[],
        readonly minSize: string,
        readonly maxSize: string,
        readonly startDate: number | null,
        readonly endDate: number | null,
        readonly selectedUserGroups: number[],
    ) {}

    hasQuery(): boolean {
        return (
            this.customerFirm !== "" ||
            this.instrumentId !== "" ||
            this.aggregateId !== "" ||
            this.states.length > 0 ||
            this.minSize !== "" ||
            this.maxSize !== "" ||
            this.startDate !== null ||
            this.endDate !== null ||
            this.selectedUserGroups.length > 0
        );
    }

    withCustomerFirm(newCustomerFirm: string): BackendQuery {
        return new BackendQuery(
            newCustomerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withInstrumentId(newInstrumentId: string): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            newInstrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withAggregateId(newAggregateId: string): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            newAggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withStates(newStates: HDSProtocol.State[]): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            newStates,
            this.minSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withMinSize(newMinSize: string): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            newMinSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withMaxSize(newMaxSize: string): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            newMaxSize,
            this.startDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withStartDate(newStartDate: number | null): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            newStartDate,
            this.endDate,
            this.selectedUserGroups,
        );
    }

    withEndDate(newEndDate: number | null): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            this.startDate,
            newEndDate,
            this.selectedUserGroups,
        );
    }

    withSelectedUserGroups(newSelectedUserGroups: number[]): BackendQuery {
        return new BackendQuery(
            this.customerFirm,
            this.instrumentId,
            this.aggregateId,
            this.states,
            this.minSize,
            this.maxSize,
            this.startDate,
            this.endDate,
            newSelectedUserGroups,
        );
    }
}

export const HistoricalBlotterController: React.FunctionComponent<HdsBlotterControllerProps> = ({
    submitCallback,
    removeFilterCallback,
    isLiveMode,
    gridRef,
    message,
    queryInProgress,
    timezoneProvider,
    userGroups,
}) => {
    const [quickFilter, setQuickFilter] = useState<string>("");
    const [backendQuery, setBackendQuery] = useState<BackendQuery>(newEmptyQuery());
    const [iconVisibleCustomerFirm, setIconVisibleCustomerFirm] = useState(false);
    const [iconVisibleInstrumentId, setIconVisibleInstrumentId] = useState(false);
    const [iconVisibleAggregateId, setIconVisibleAggregateId] = useState(false);
    const [iconVisibleSizeMin, setIconVisibleSizeMin] = useState(false);
    const [iconVisibleSizeMax, setIconVisibleSizeMax] = useState(false);
    const [iconVisibleStartDate, setIconVisibleStartDate] = useState(false);
    const [iconVisibleEndDate, setIconVisibleEndDate] = useState(false);

    const userGroupOptions = useMemoMappedList(userGroups, (userGroup) => ({ value: userGroup.id, label: userGroup.name }));
    const onlyAllowIntegerNumber = (setter: (a: string) => void, iconSetter: (a: boolean) => void) => (input: string) => {
        if (input.includes(".") || input.includes(",")) {
            iconSetter(false);
            return;
        }

        if (input === "") {
            iconSetter(false);
            setter(input);
            return;
        }

        const parsedNumber = Number(input);
        if (!Number.isNaN(parsedNumber) && Number.isInteger(parsedNumber)) {
            setter(input);
            iconSetter(true);
        }
    };

    const onDownloadCsv = useCallback(() => {
        gridRef.current?.api.exportDataAsCsv({ fileName: "IRS-historical-inquiries.csv" });
    }, [gridRef]);

    const submitQuery = useCallback(
        () =>
            submitCallback(
                backendQuery.startDate,
                backendQuery.endDate,
                backendQuery.customerFirm !== "" ? backendQuery.customerFirm : null,
                backendQuery.instrumentId !== "" ? backendQuery.instrumentId : null,
                backendQuery.aggregateId !== "" ? backendQuery.aggregateId : null,
                backendQuery.minSize !== "" ? Number(backendQuery.minSize) : null,
                backendQuery.maxSize !== "" ? Number(backendQuery.maxSize) : null,
                backendQuery.states.length > 0 ? backendQuery.states.join(",") : null,
                backendQuery.selectedUserGroups.length > 0 ? backendQuery.selectedUserGroups.join(",") : null,
            ),
        [submitCallback, backendQuery],
    );

    const clearSearchInputs = useCallback(() => {
        setBackendQuery(newEmptyQuery());
        removeFilterCallback();
        setIconVisibleCustomerFirm(false);
        setIconVisibleInstrumentId(false);
        setIconVisibleAggregateId(false);
        setIconVisibleSizeMin(false);
        setIconVisibleSizeMax(false);
        setIconVisibleStartDate(false);
        setIconVisibleEndDate(false);
    }, [removeFilterCallback]);

    return (
        <Box contentDirection={"row"} data-testid="historical-blotter-controller">
            <Box contentDirection={"row"} contentJustification="space-between" width={"full"}>
                <Box contentDirection={"column"} contentAlignment={"end"}>
                    <Box contentDirection={"row"}>
                        <Box selfAlignment={"center"} width={"64"} padding={"6"}>
                            <TransficcLogo />
                        </Box>
                        <Box paddingR={"10"} />
                        <Box selfAlignment={"center"} paddingT={"4"} paddingR={"10"}>
                            <Text size={"xl"}>Historical Blotter</Text>
                        </Box>
                    </Box>
                    <Box padding={"2"} selfAlignment={"start"} contentDirection={"row"}>
                        <Box />
                        <TextInput
                            id="quickfilter"
                            value={quickFilter}
                            title={""}
                            placeholder={"Filter any column..."}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onInputEntered={(input: string) => {
                                setQuickFilter(input);
                                // gridRef.current?.api.setQuickFilter(input);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <Box contentAlignment={"center"}>
                            <Button
                                styling={semanticButtonStyling("info")}
                                size="sm"
                                title="Download CSV"
                                onClick={onDownloadCsv}
                                width={"32"}
                                height={"8"}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box selfAlignment={"end"}>
                    <FlowContainer contentDirection={"row"}>
                        <DatePicker
                            id="start-time"
                            placeholderText="Start Date"
                            selectedEpochMillis={backendQuery.startDate}
                            onChangeCallback={(epochMillis: number | null) => {
                                setIconVisibleStartDate(epochMillis != null);
                                return setBackendQuery(backendQuery.withStartDate(epochMillis));
                            }}
                            onEnterKeyPress={submitQuery}
                            timezoneProvider={timezoneProvider}
                            iconVisible={iconVisibleStartDate}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withStartDate(null));
                                setIconVisibleStartDate(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <DatePicker
                            id="end-time"
                            placeholderText="End Date"
                            selectedEpochMillis={backendQuery.endDate}
                            onChangeCallback={(epochMillis: number | null) => {
                                setIconVisibleEndDate(epochMillis != null);
                                return setBackendQuery(backendQuery.withEndDate(epochMillis));
                            }}
                            onEnterKeyPress={submitQuery}
                            timezoneProvider={timezoneProvider}
                            iconVisible={iconVisibleEndDate}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withEndDate(null));
                                setIconVisibleEndDate(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <TextInput
                            id="customer-firm"
                            value={backendQuery.customerFirm}
                            title={""}
                            onInputEntered={(value) => {
                                setBackendQuery(backendQuery.withCustomerFirm(value));
                                setIconVisibleCustomerFirm(value !== "");
                            }}
                            placeholder={"Customer Firm"}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onEnterKeyPress={submitQuery}
                            hasIcon={true}
                            iconVisible={iconVisibleCustomerFirm}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withCustomerFirm(""));
                                setIconVisibleCustomerFirm(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <TextInput
                            id="instrument-id"
                            value={backendQuery.instrumentId}
                            title={""}
                            onInputEntered={(value) => {
                                setBackendQuery(backendQuery.withInstrumentId(value));
                                setIconVisibleInstrumentId(value !== "");
                            }}
                            placeholder={"Instrument Id"}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onEnterKeyPress={submitQuery}
                            hasIcon={true}
                            iconVisible={iconVisibleInstrumentId}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withInstrumentId(""));
                                setIconVisibleInstrumentId(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <TextInput
                            id="venue-id"
                            value={backendQuery.aggregateId}
                            title={""}
                            onInputEntered={(value) => {
                                setBackendQuery(backendQuery.withAggregateId(value));
                                setIconVisibleAggregateId(value !== "");
                            }}
                            placeholder={"Venue Aggregate Id"}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onEnterKeyPress={submitQuery}
                            hasIcon={true}
                            iconVisible={iconVisibleAggregateId}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withAggregateId(""));
                                setIconVisibleAggregateId(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <Multiselect
                            id="inquiry-states"
                            placeholder={"States"}
                            options={STATE_OPTIONS}
                            selectedValues={backendQuery.states}
                            onChange={(newStates) => setBackendQuery(backendQuery.withStates(newStates))}
                        />

                        <Box paddingR={"2"} />
                        <TextInput
                            id="size-min"
                            value={backendQuery.minSize}
                            title={""}
                            onInputEntered={(value) => {
                                onlyAllowIntegerNumber(
                                    (newMinSize) => setBackendQuery(backendQuery.withMinSize(newMinSize)),
                                    setIconVisibleSizeMin,
                                )(value);
                            }}
                            placeholder={"Min Size"}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onEnterKeyPress={submitQuery}
                            hasIcon={true}
                            iconVisible={iconVisibleSizeMin}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withMinSize(""));
                                setIconVisibleSizeMin(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <TextInput
                            id="size-max"
                            value={backendQuery.maxSize}
                            title={""}
                            onInputEntered={(value) => {
                                onlyAllowIntegerNumber(
                                    (newMinSize) => setBackendQuery(backendQuery.withMaxSize(newMinSize)),
                                    setIconVisibleSizeMax,
                                )(value);
                            }}
                            placeholder={"Max Size"}
                            showTitle={false}
                            size={"xs"}
                            height={"8"}
                            width={"52"}
                            multiLine={false}
                            resize={"none"}
                            disabled={false}
                            onEnterKeyPress={submitQuery}
                            hasIcon={true}
                            iconVisible={iconVisibleSizeMax}
                            onIconClick={() => {
                                setBackendQuery(backendQuery.withMaxSize(""));
                                setIconVisibleSizeMax(false);
                            }}
                        />
                        <Box paddingR={"2"} />
                        <Multiselect
                            id="user-groups"
                            placeholder={"Product group"}
                            options={userGroupOptions}
                            selectedValues={backendQuery.selectedUserGroups}
                            onChange={(newValues: number[]) => setBackendQuery(backendQuery.withSelectedUserGroups(newValues))}
                        />
                        <Box paddingR={"2"} />
                    </FlowContainer>
                    <FlowContainer contentDirection={"row"} padding={"2"} selfAlignment={"end"}>
                        <Box contentJustification="center">
                            <Text size={"base"} data-testid={"historical-blotter-controller-message"}>
                                {message}
                            </Text>
                        </Box>
                        <Box paddingR={"2"} />

                        <Button
                            title={"Clear"}
                            selected={false}
                            size={"sm"}
                            styling={semanticButtonStyling("info")}
                            onClick={clearSearchInputs}
                            disabled={isLiveMode || queryInProgress}
                            width={"20"}
                            height={"8"}
                        />
                        <Box paddingR={"2"} />
                        <Button
                            title={"Submit"}
                            selected={false}
                            size={"sm"}
                            styling={semanticButtonStyling("info")}
                            onClick={submitQuery}
                            disabled={queryInProgress || !backendQuery.hasQuery()}
                            width={"20"}
                            height={"8"}
                        />
                    </FlowContainer>
                </Box>
            </Box>
        </Box>
    );
};
