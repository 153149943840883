import React from "react";
import { DisplayState, InquirySide, InquiryState, IRSInquiry, IRSLeg, PricerState, PriceValidity } from "../irs-domain";
import { IRSActionButtons } from "../irs-action-buttons/irs-action-buttons";
import { EpochClock } from "@transficc/infrastructure";
import { PackageControl } from "./irs-curve-package-control";
import { IrsSingleSidedLegControl } from "../irs-leg-control/irs-single-sided-leg-control";
import { IRSInquiryBody, SelectedInquiryContainer } from "../irs-inquiry/irs-inquiry";
import { IRSInquiryHeader, OpenCustomerAnalytics } from "../irs-inquiry-header/irs-inquiry-header";
import { IRSInquiryFooter } from "../irs-inquiry-footer/irs-inquiry-footer";
import { IrsCurveMisweightedLabel } from "./irs-curve-misweighted-label";
import { IrsDoubleSidedLegControl } from "../irs-leg-control/irs-double-sided-leg-control";
import { Box } from "@transficc/components";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { InquiryButtonRow } from "../inquiry-button-row/inquiry-button-row";
import { IRSLegView, useIRSCurve } from "./irs-curve-view";
import { useDefinitelySelectedInquiry, useSelectedInquiryIsFinished } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";
import { useClock } from "@transficc/trader-desktop-application-context";

export interface IRSCurveProps {
    publishAction: ActionPublisher.ActionPublisher;
    openRiskViewWindow: (url: string) => void;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

const getIrsLegControl = (
    legView: IRSLegView,
    inquiryLeg: IRSLeg<"curve">,
    inquiryState: InquiryState,
    autoQuotePriceError: string | null,
    autoQuotePricerState: PricerState,
    autoQuoteDisplayState: DisplayState,
    autoQuotePriceValidity: PriceValidity | null,
    isMac: boolean,
    isInquiryFinished: boolean,
    onIsDirtyChange: (isDirty: boolean) => void,
    clock: EpochClock,
): JSX.Element => {
    if (inquiryLeg.side === InquirySide.Undisclosed) {
        const pay = legView.pay;
        const rcv = legView.rcv;
        return (
            <IrsDoubleSidedLegControl
                model={{
                    pay: {
                        customerRateValue: pay.customerRateValue,
                        isCustomerRateInputDisabled: isInquiryFinished,
                        spreadValue: pay.spreadValue,
                        isSpreadInputDisabled: pay.isSpreadInputDisabled,
                        selectedDriver: pay.selectedDriver,
                        customerRateDisplayState: pay.selectedDriverDisplayState,
                        autoQuotePrices: inquiryLeg.autoQuotePrices,
                        venuePrices: inquiryLeg.venuePrices,
                        latestQuotedPrices: inquiryLeg.lastQuotedPrices,
                        isCustomerRateInvalid: pay.shouldShowCustomerRateInputAsStale,
                    },

                    rcv: {
                        customerRateValue: rcv.customerRateValue,
                        isCustomerRateInputDisabled: isInquiryFinished,
                        spreadValue: rcv.spreadValue,
                        isSpreadInputDisabled: rcv.isSpreadInputDisabled,
                        selectedDriver: rcv.selectedDriver,
                        customerRateDisplayState: rcv.selectedDriverDisplayState,
                        autoQuotePrices: inquiryLeg.autoQuotePrices,
                        venuePrices: inquiryLeg.venuePrices,
                        latestQuotedPrices: inquiryLeg.lastQuotedPrices,
                        isCustomerRateInvalid: rcv.shouldShowCustomerRateInputAsStale,
                    },
                    autoQuoteDisplayState,
                    autoQuotePriceError,
                    autoQuotePricerState,
                    autoQuotePriceValidity,
                    isMac,

                    inquiryState: inquiryState,
                    isInquiryFinished,
                    leg: inquiryLeg,
                }}
                callbacks={{
                    pay: {
                        updateCustomerRate: pay.updateCustomerRate,
                        onCustomerRateDirtyChange: onIsDirtyChange,
                        updateSpreadValue: pay.updateSpreadValue,
                        onSpreadDirtyChange: onIsDirtyChange,
                        selectDriver: pay.selectDriver,
                        clearDriverValue: pay.clearDriverValue,
                    },

                    rcv: {
                        updateCustomerRate: rcv.updateCustomerRate,
                        onCustomerRateDirtyChange: onIsDirtyChange,
                        updateSpreadValue: rcv.updateSpreadValue,
                        onSpreadDirtyChange: onIsDirtyChange,
                        selectDriver: rcv.selectDriver,
                        clearDriverValue: rcv.clearDriverValue,
                    },

                    updatePtmmValue: legView.updatePtmmValue,
                }}
                legPosition={inquiryLeg.position}
            />
        );
    } else {
        const sideView = inquiryLeg.side === InquirySide.Buy ? legView.pay : legView.rcv;
        return (
            <IrsSingleSidedLegControl
                config={{
                    clock,
                }}
                model={{
                    customerRateValue: sideView.customerRateValue,
                    isCustomerRateInputDisabled: sideView.isCustomerRateInputDisabled,
                    isCustomerRateInvalid: sideView.shouldShowCustomerRateInputAsStale,

                    spreadValue: sideView.spreadValue,
                    isSpreadInputDisabled: sideView.isSpreadInputDisabled,

                    selectedDriver: sideView.selectedDriver,
                    customerRateDisplayState: sideView.selectedDriverDisplayState,

                    autoQuotePrices: inquiryLeg.autoQuotePrices,
                    venuePrices: inquiryLeg.venuePrices,

                    inquiryState,
                    autoQuoteDisplayState: autoQuoteDisplayState,
                    autoQuotePriceError,
                    autoQuotePricerState: autoQuotePricerState,
                    autoQuotePriceValidity,
                    isInquiryFinished,
                    leg: inquiryLeg,
                    isMac,
                }}
                callbacks={{
                    updateCustomerRate: sideView.updateCustomerRate,
                    onCustomerRateDirtyChange: onIsDirtyChange,

                    updateSpreadValue: sideView.updateSpreadValue,
                    onSpreadDirtyChange: onIsDirtyChange,

                    updatePtmmValue: legView.updatePtmmValue,

                    selectDriver: sideView.selectDriver,
                    clearDriverValue: sideView.clearDriverValue,
                }}
                legPosition={inquiryLeg.position}
            />
        );
    }
};
export const IrsCurve: React.FC<IRSCurveProps> = ({ publishAction, openRiskViewWindow, openCustomerAnalyticsWindow }) => {
    const isInquiryFinished = useSelectedInquiryIsFinished();
    const inquiry = useDefinitelySelectedInquiry() as IRSInquiry<"curve">;
    const clock = useClock();

    const { packageView, leftLeg, rightLeg, autoQuoteDisplayState, autoQuotePriceValidity } = useIRSCurve(
        inquiry,
        clock,
        isInquiryFinished,
    );

    const { setDirty, clearCircuitBreaker, closeActionFailure } = useIRSStateDispatch();

    return (
        <SelectedInquiryContainer>
            <IRSInquiryHeader openCustomerAnalyticsWindow={openCustomerAnalyticsWindow} />
            <IRSInquiryBody>
                <Box contentDirection={"column"}>
                    <InquiryButtonRow openRiskViewWindow={openRiskViewWindow} />
                    <Box contentDirection={"row"}>
                        {getIrsLegControl(
                            leftLeg,
                            inquiry.legs[0],
                            inquiry.state,
                            inquiry.autoQuotePriceError,
                            inquiry.autoQuotePriceState,
                            autoQuoteDisplayState,
                            autoQuotePriceValidity,
                            inquiry.isMac,
                            isInquiryFinished,
                            setDirty,
                            clock,
                        )}
                        <Box marginL={"1"} />
                        {getIrsLegControl(
                            rightLeg,
                            inquiry.legs[1],
                            inquiry.state,
                            inquiry.autoQuotePriceError,
                            inquiry.autoQuotePriceState,
                            autoQuoteDisplayState,
                            autoQuotePriceValidity,
                            inquiry.isMac,
                            isInquiryFinished,
                            setDirty,
                            clock,
                        )}
                    </Box>
                </Box>
                <Box contentDirection={"column"} contentJustification={"space-between"} contentAlignment={"center"}>
                    <Box contentDirection={"column"} marginL={"4"}>
                        <IrsCurveMisweightedLabel isMisweighted={inquiry.isPackageMisweighted} />
                        <IRSActionButtons publishAction={publishAction} />
                    </Box>
                    <PackageControl
                        model={{
                            pay: {
                                aqLevel: packageView.pay.packageAQLevel,
                                quoted: packageView.pay.packageLastQuoted,

                                levelValue: packageView.pay.packageLevelValue,
                                levelDisabled: packageView.pay.isPackageLevelInputDisabled,
                                levelSelected: packageView.pay.isPackageLevelSelected,
                                levelDisplayState: packageView.pay.packageLevelDisplayState,
                                levelInvalid: packageView.pay.shouldShowPackageLevelInputAsStale,

                                spreadValue: packageView.pay.packageSpreadValue,
                                spreadDisabled: packageView.pay.isPackageSpreadInputDisabled,
                            },
                            rcv: {
                                aqLevel: packageView.rcv.packageAQLevel,
                                quoted: packageView.rcv.packageLastQuoted,

                                levelValue: packageView.rcv.packageLevelValue,
                                levelDisabled: packageView.rcv.isPackageLevelInputDisabled,
                                levelSelected: packageView.rcv.isPackageLevelSelected,
                                levelDisplayState: packageView.rcv.packageLevelDisplayState,
                                levelInvalid: packageView.rcv.shouldShowPackageLevelInputAsStale,

                                spreadValue: packageView.rcv.packageSpreadValue,
                                spreadDisabled: packageView.rcv.isPackageSpreadInputDisabled,
                            },
                            quotedMid: packageView.packageQuotedMid,
                            venueMid: packageView.packageVenueMid,
                            aqMid: packageView.packageAQMid,
                            isMac: inquiry.isMac,
                            isInquiryFinished,
                            sideForPackage: inquiry.legs[1].side,
                        }}
                        callbacks={{
                            pay: {
                                onLevelValueChange: packageView.pay.updatePackageLevel,
                                onLevelDirtyChange: setDirty,
                                onSpreadValueChange: packageView.pay.updatePackageSpread,
                                onSpreadDirtyChange: setDirty,
                            },
                            rcv: {
                                onLevelValueChange: packageView.rcv.updatePackageLevel,
                                onLevelDirtyChange: setDirty,
                                onSpreadValueChange: packageView.rcv.updatePackageSpread,
                                onSpreadDirtyChange: setDirty,
                            },
                        }}
                    />
                </Box>
            </IRSInquiryBody>
            <IRSInquiryFooter clearCircuitBreaker={clearCircuitBreaker} closeActionFailure={closeActionFailure} />
        </SelectedInquiryContainer>
    );
};
