import { DisconnectionReason } from "@transficc/websocket";

export interface SessionTransport {
    open: (accessToken: string, idToken: string | null, appInstanceId: string) => void;
    close: () => void;

    send: (payload: string) => void;
    onMessage: (handler: (payload: string) => void) => () => void;

    onConnected: (handler: () => void) => () => void;
    onDisconnected: (handler: (reason: DisconnectionReason, description: string | null) => void) => () => void;
}

export const webSocketProtocol = (): string => {
    const loc = window.location;
    let protocol: string;

    if (loc.protocol === "https:") {
        protocol = "wss:";
    } else {
        protocol = "ws:";
    }
    return `${protocol}//${loc.host}/ws-api`;
};
