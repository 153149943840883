import { formatWithCommas } from "@transficc/components";

export const formatQuantityToThreeDp: (quantity: string) => string = (quantity) => (Number.parseInt(quantity) / 1000000).toFixed(3);

export const formatRate: (fixedRate: string | null, floatingRateSpread: string | null) => string = (fixedRate, floatingRateSpread) => {
    const fractionDigits = 6;
    if (fixedRate !== null && fixedRate.trim().length > 0) {
        const number = +fixedRate;
        if (Number.isFinite(number)) {
            return (+number.toFixed(fractionDigits)).toString() + "%";
        }
    } else if (floatingRateSpread !== null && floatingRateSpread.trim().length > 0) {
        const number = +floatingRateSpread;
        if (Number.isFinite(number)) {
            return (+(number * 100).toFixed(fractionDigits)).toString() + "bps";
        }
    }

    return "-";
};

export const formatToThousandsWithK = (value: string): string => {
    const valueInThousands = Math.round(Number.parseInt(value) / 1_000).toString();
    const valueWithCommas = formatWithCommas(valueInThousands);
    return valueWithCommas ? valueWithCommas + "k" : "-";
};

export const addSpacesToCamelCase = (camelCaseString: string): string => {
    if (camelCaseString.length < 1) {
        return camelCaseString;
    }

    const buffer: string[] = [];

    const firstChar = camelCaseString[0];
    if (firstChar === undefined) {
        return camelCaseString;
    }
    buffer.push(firstChar);

    for (let i = 1; i < camelCaseString.length; i++) {
        const char = camelCaseString[i];
        const previousChar = camelCaseString[i - 1];
        if (char !== undefined && previousChar !== undefined) {
            if (char >= "A" && char <= "Z" && previousChar.trim() !== "") {
                buffer.push(" ");
            }
        }
        buffer.push(char || "");
    }
    return buffer.join("");
};
