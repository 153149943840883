import React, { useCallback, useMemo, useState } from "react";
import { DecimalNumber } from "@transficc/infrastructure";
import * as IRSDomain from "../irs-domain";
import { backgroundColorFor, BidMidAsk, DisplayState, foregroundColorFor, PricerState, PriceValidity, SelectedDriver } from "../irs-domain";
import { IrsRfqPriceDriver } from "../irs-price-driver/irs-rfq-price-driver";
import { IRSPriceAndSpread } from "../irs-price-and-spread/irs-price-and-spread";
import { colorForFee } from "../irs-domain/colors";
import { IRSIncrementButtons } from "../irs-increment-buttons/irs-increment-buttons";
import { getIncrements } from "../irs-domain/compression-derivations";
import { IRSPtmm } from "../irs-ptmm/irs-ptmm";
import { Box } from "@transficc/components";
import { FEE_TOOL_TIP_MESSAGE } from "../irs-domain/tool-tip";
import { useMinPriceIncrementAllLegsSelector } from "../irs-slice/irs-selectors";

export interface IRSCompressionControlsProps {
    model: {
        packageFeeValue: DecimalNumber | null;
        isPackageFeeInputDisabled: boolean;
        packageFeeDisplayState: IRSDomain.DisplayState;
        isPackageFeeInvalid: boolean;
        spreadValue: DecimalNumber | null;

        isSpreadInputDisabled: boolean;
        autoQuotePriceValidity: PriceValidity | null;
        autoQuotePricerState: PricerState | null;

        autoQuoteDisplayState: IRSDomain.DisplayState;
        selectedDriver: SelectedDriver | null;

        isInquiryFinished: boolean;

        lastQuotedPrices: BidMidAsk;
        autoQuotePrices: BidMidAsk;
        autoQuoteErrorMessage: string | null;
        venuePrices: BidMidAsk;

        tradedPackageFee: string | null;
    };
    callbacks: {
        updatePackageFee: (packageFee: DecimalNumber) => void;
        onPackageFeeDirtyChange: (isDirty: boolean) => void;

        updateSpreadValue: (spreadValue: DecimalNumber) => void;
        onSpreadDirtyChange: (isDirty: boolean) => void;

        updatePtmmValue: (ptmmValue: DecimalNumber) => void;

        selectDriver: (selectedDriver: SelectedDriver) => void;
        clearDriverValue: () => void;
    };
}

interface PackageFeeColors {
    foreground: Theme.Color | undefined;
    background: Theme.Color | undefined;
}

const packageFeeColor: (displayState: DisplayState, packageFeeValue: DecimalNumber | null) => PackageFeeColors = (
    displayState,
    packageFeeValue,
) => {
    const displayStateDrivenBackgroundColor = backgroundColorFor(displayState);
    if (displayStateDrivenBackgroundColor !== undefined) {
        return { foreground: foregroundColorFor(displayState), background: displayStateDrivenBackgroundColor };
    }

    if (packageFeeValue !== null) {
        const feeColor = colorForFee(packageFeeValue);
        if (feeColor !== null) {
            return feeColor;
        }
    }

    return { foreground: foregroundColorFor(displayState), background: displayStateDrivenBackgroundColor };
};

interface IRSCompressionControlsView {
    packageFeeColors: PackageFeeColors;

    incrementLabels: [string, string, string];
    activeIncrementValue: DecimalNumber;

    onClick: (newIncrementIndex: number) => void;
}

const useIRSCompressionControls = ({ model }: IRSCompressionControlsProps): IRSCompressionControlsView => {
    const minPriceIncrement = useMinPriceIncrementAllLegsSelector();
    const packageFeeColors: PackageFeeColors = useMemo(() => {
        return packageFeeColor(model.packageFeeDisplayState, model.packageFeeValue);
    }, [model.packageFeeDisplayState, model.packageFeeValue]);

    const { incrementLabels, incrementValues } = useMemo(() => getIncrements(minPriceIncrement), [minPriceIncrement]);

    const [activeIncrementValue, setActiveIncrementValue] = useState(incrementValues[0]);

    const onClick = useCallback(
        (newIncrementIndex: number): void => {
            switch (newIncrementIndex) {
                case 0:
                case 1:
                case 2:
                    setActiveIncrementValue(incrementValues[newIncrementIndex]);
                    break;
                default:
                    throw new Error("Index out of bounds: " + newIncrementIndex.toString());
            }
        },
        [setActiveIncrementValue, incrementValues],
    );

    return {
        packageFeeColors,

        incrementLabels,
        activeIncrementValue,

        onClick,
    };
};

export const IrsRfqCompressionControls: React.FC<IRSCompressionControlsProps> = ({ model, callbacks }) => {
    const minPriceIncrement = useMinPriceIncrementAllLegsSelector();
    const {
        packageFeeColors,

        incrementLabels,
        activeIncrementValue,

        onClick,
    } = useIRSCompressionControls({ model, callbacks });

    return (
        <Box contentAlignment={"center"} marginL="4" data-testid={"irs-package-controls"}>
            <IRSIncrementButtons labels={incrementLabels} onSelectedIndexChange={onClick} />
            <Box margin={"1"} />
            <IRSPriceAndSpread
                priceProps={{
                    config: {
                        tickButtonSize: activeIncrementValue,
                        minPriceIncrement: minPriceIncrement,
                        title: "",
                        showTitleText: false,
                        titleSelector: "Package Fee",
                        size: "medium",
                        tooltip: FEE_TOOL_TIP_MESSAGE,
                    },
                    model: {
                        value: model.packageFeeValue,
                        disabled: model.isPackageFeeInputDisabled,
                        selected: model.selectedDriver === null,
                        displayState: model.packageFeeDisplayState,
                        inputForegroundColor: packageFeeColors.foreground,
                        inputBackgroundColor: packageFeeColors.background,
                        invalid: model.isPackageFeeInvalid,
                    },
                    callbacks: {
                        onValueChange: callbacks.updatePackageFee,
                        onDirtyChange: callbacks.onPackageFeeDirtyChange,
                    },
                }}
                spreadProps={{
                    config: {
                        tickButtonSize: activeIncrementValue,
                        minPriceIncrement: minPriceIncrement,
                        size: "small",
                    },
                    model: {
                        value: model.spreadValue,
                        disabled: model.isSpreadInputDisabled,
                    },
                    callbacks: {
                        onValueChange: callbacks.updateSpreadValue,
                        onDirtyChange: callbacks.onSpreadDirtyChange,
                    },
                }}
            />
            <IRSPtmm
                tickButtonSize={activeIncrementValue}
                minPriceIncrement={minPriceIncrement}
                size={"small"}
                onValueChange={callbacks.updatePtmmValue}
            />
            <Box marginT="8">
                <IrsRfqPriceDriver
                    model={{
                        prices: {
                            latestQuotedPrices: model.lastQuotedPrices,
                            autoQuotePrices: model.autoQuotePrices,
                            venuePrices: model.venuePrices,
                        },
                        autoQuotePricerState: model.autoQuotePricerState,
                        autoQuoteErrorMessage: model.autoQuoteErrorMessage,
                        autoQuoteDisplayState: model.autoQuoteDisplayState,
                        selectedDriver: model.selectedDriver,
                        disabled: model.isInquiryFinished,
                        autoQuotePriceValidity: model.autoQuotePriceValidity,
                    }}
                    callbacks={{
                        selectDriver: callbacks.selectDriver,
                        clearDriver: callbacks.clearDriverValue,
                    }}
                />
            </Box>
        </Box>
    );
};
