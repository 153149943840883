import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionStatus } from "./ConnectionStatus";
import { TradingStatus } from "./TradingStatus";
import {
    getItemFromLocalStorage,
    LocalStorageKeys,
    removeItemFromLocalStorage,
    setItemInLocalStorage,
} from "@transficc/trader-desktop-local-storage";
import { IdToken } from "../../../ApplicationRouter";
import { jwtDecode } from "jwt-decode";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";

// https://github.com/microsoft/TypeScript/issues/42873
import "reselect";
import "redux";

export interface SessionUser {
    userId: number;
    username: string;
}

export interface SessionState {
    accessToken: string | null;
    idToken: string | null;
    soundEnabled: boolean | null;
    connectionStatus: ConnectionStatus;
    tradingStatus: TradingStatus;
    serverTradingStatus: TradingStatus;
    user: SessionUser | null;
    userGroups: TraderDesktopProtocol.UserGroup[];
}

function isSoundEnabled(): boolean | null {
    const soundEnabledJson = getItemFromLocalStorage(LocalStorageKeys.USER_SOUND_ENABLED);
    if (soundEnabledJson != null) {
        return JSON.parse(soundEnabledJson) as boolean;
    }
    return null;
}

const decodeJwtToken = (token: string | null): IdToken | null => {
    try {
        if (token) {
            return jwtDecode<IdToken>(token);
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
};

const parseIdToken = (rawToken: string | null): SessionUser | null => {
    const parsedToken = decodeJwtToken(rawToken);
    if (parsedToken) {
        const userId = parsedToken.user_id ?? Number.MIN_VALUE;
        const username = parsedToken.sub ?? "";
        return {
            userId,
            username,
        };
    }
    return null;
};

const parseUserGroups = (userGroupsJson: string | null): TraderDesktopProtocol.UserGroup[] => {
    if (userGroupsJson) {
        return JSON.parse(userGroupsJson) as TraderDesktopProtocol.UserGroup[];
    } else {
        return [];
    }
};

const sessionSlice = createSlice({
    name: "session",
    initialState: (): SessionState => ({
        accessToken: getItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN),
        idToken: getItemFromLocalStorage(LocalStorageKeys.ID_TOKEN),
        soundEnabled: isSoundEnabled(),
        connectionStatus: ConnectionStatus.DISCONNECTED,
        tradingStatus: TradingStatus.OFF,
        serverTradingStatus: TradingStatus.OFF,
        user: parseIdToken(getItemFromLocalStorage(LocalStorageKeys.ID_TOKEN)),
        userGroups: parseUserGroups(getItemFromLocalStorage(LocalStorageKeys.USER_GROUPS)),
    }),
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            setItemInLocalStorage(LocalStorageKeys.ACCESS_TOKEN, state.accessToken);
        },
        setIdToken: (state, action: PayloadAction<string>) => {
            state.idToken = action.payload;
            state.user = parseIdToken(action.payload);
            setItemInLocalStorage(LocalStorageKeys.ID_TOKEN, state.idToken);
        },
        logoff: (state) => {
            state.accessToken = null;
            state.idToken = null;
            state.user = null;
            removeItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
            removeItemFromLocalStorage(LocalStorageKeys.USER_GROUPS);
            removeItemFromLocalStorage(LocalStorageKeys.ID_TOKEN);
        },
        toggleSoundEnabled: (state) => {
            state.soundEnabled = !state.soundEnabled;
            setItemInLocalStorage(LocalStorageKeys.USER_SOUND_ENABLED, JSON.stringify(state.soundEnabled));
        },
        setConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.connectionStatus = action.payload;
        },
        setTradingStatus: (state, action: PayloadAction<TradingStatus>) => {
            state.tradingStatus = action.payload;
        },
        setServerTradingStatus: (state, action: PayloadAction<TradingStatus>) => {
            state.serverTradingStatus = action.payload;
        },
        setUserGroups: (state, action: PayloadAction<TraderDesktopProtocol.UserGroup[] | null>) => {
            state.userGroups = action.payload ?? [];
            setItemInLocalStorage(LocalStorageKeys.USER_GROUPS, JSON.stringify(state.userGroups));
        },
    },
});

export const {
    setAccessToken,
    setIdToken,
    logoff,
    toggleSoundEnabled,
    setConnectionStatus,
    setTradingStatus,
    setServerTradingStatus,
    setUserGroups,
} = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
