import React, { useMemo, useState } from "react";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { DisplayState, InquirySide } from "../irs-domain";
import { IRSIncrementButtons } from "../irs-increment-buttons/irs-increment-buttons";
import { getIncrements, Increments } from "../irs-domain/compression-derivations";
import { PackageControlAndPrices } from "./irs-curve-package-control-and-prices";
import { Box } from "@transficc/components";
import { colorForSide } from "../irs-domain/colors";
import { PackagePriceMatrix } from "./package-price-matrix";
import { useMinPriceIncrementAllLegsSelector, useMinPriceIncrementPackageSelector } from "../irs-slice/irs-selectors";

interface SideModel {
    quoted: DecimalNumber | null;
    aqLevel: DecimalNumber | null;
    levelValue: DecimalNumber | null;
    levelDisabled: boolean;
    levelSelected: boolean;
    levelDisplayState: DisplayState;
    levelInvalid: boolean;
    spreadValue: DecimalNumber | null;
    spreadDisabled: boolean;
}

interface SideCallbacks {
    onLevelValueChange: (value: DecimalNumber) => void;
    onLevelDirtyChange: (isDirty: boolean) => void;
    onSpreadValueChange: (value: DecimalNumber) => void;
    onSpreadDirtyChange: (isDirty: boolean) => void;
}

export interface IRSCurvePackageControlProps {
    model: {
        isMac: boolean;
        isInquiryFinished: boolean;
        sideForPackage: InquirySide;
        pay: SideModel;
        rcv: SideModel;
        quotedMid: DecimalNumber | null;
        aqMid: DecimalNumber | null;
        venueMid: DecimalNumber | null;
    };
    callbacks: {
        pay: SideCallbacks;
        rcv: SideCallbacks;
    };
}

export const PackageControl: React.FC<IRSCurvePackageControlProps> = ({ model, callbacks }) => {
    const increment = useMinPriceIncrementPackageSelector();
    const tickSize = useMinPriceIncrementAllLegsSelector();

    const incrementButtons: Increments = useMemo(() => {
        if (model.isMac) {
            return getIncrements(new ImmutableDecimalNumber(tickSize));
        } else {
            const minPriceIncrementInBasisPoints = new ImmutableDecimalNumber(tickSize).multiplyBy(100);
            return {
                incrementLabels: [minPriceIncrementInBasisPoints.toString(), "0.5", "1.0"],
                incrementValues: [minPriceIncrementInBasisPoints, new ImmutableDecimalNumber("0.5"), new ImmutableDecimalNumber("1.0")],
            };
        }
    }, [model.isMac, tickSize]);

    const [selectedTickSize, setSelectedTickSize] = useState(incrementButtons.incrementValues[0]);

    const getSingleSidedPackageControl = (): React.ReactNode => {
        const sideModel = model.sideForPackage === InquirySide.Buy ? model.pay : model.rcv;
        const sideCallbacks = model.sideForPackage === InquirySide.Buy ? callbacks.pay : callbacks.rcv;
        return (
            <PackageControlAndPrices
                config={{}}
                model={{
                    increment,
                    levelValue: sideModel.levelValue,
                    levelDisabled: sideModel.levelDisabled,
                    levelSelected: sideModel.levelSelected,
                    levelDisplayState: sideModel.levelDisplayState,
                    levelInvalid: sideModel.levelInvalid,
                    spreadValue: sideModel.spreadValue,
                    spreadDisabled: sideModel.spreadDisabled,
                    selectedTickSize,
                    quoted: sideModel.quoted,
                    aqLevel: sideModel.aqLevel,
                    isMac: model.isMac,
                }}
                callbacks={{
                    onLevelValueChange: sideCallbacks.onLevelValueChange,
                    onLevelDirtyChange: sideCallbacks.onLevelDirtyChange,
                    onSpreadValueChange: sideCallbacks.onSpreadValueChange,
                    onSpreadDirtyChange: sideCallbacks.onSpreadDirtyChange,
                }}
            />
        );
    };

    const getDoubleSidedPackageControl = (): React.ReactNode => (
        <Box contentDirection={"row"}>
            <Box contentAlignment={"center"} data-testid="irs-package-control-pay">
                <PackageControlAndPrices
                    config={{
                        packageTitleLabel: {
                            text: "PAY",
                            backgroundColor: colorForSide(InquirySide.Buy),
                            foregroundColor: { color: "black" },
                        },
                    }}
                    model={{
                        increment,
                        levelValue: model.pay.levelValue,
                        levelDisabled: model.pay.levelDisabled,
                        levelSelected: model.pay.levelSelected,
                        levelDisplayState: model.pay.levelDisplayState,
                        levelInvalid: model.pay.levelInvalid,
                        spreadValue: model.pay.spreadValue,
                        spreadDisabled: model.pay.spreadDisabled,
                        selectedTickSize,
                        quoted: model.pay.quoted,
                        aqLevel: model.pay.aqLevel,
                        inputForegroundColor: { color: "black" },
                        inputBackgroundColor: colorForSide(InquirySide.Buy),
                        isMac: model.isMac,
                    }}
                    callbacks={{
                        onLevelValueChange: callbacks.pay.onLevelValueChange,
                        onLevelDirtyChange: callbacks.pay.onLevelDirtyChange,
                        onSpreadValueChange: callbacks.pay.onSpreadValueChange,
                        onSpreadDirtyChange: callbacks.pay.onSpreadDirtyChange,
                    }}
                />
            </Box>
            <Box contentAlignment={"center"} marginL={"4"} data-testid="irs-package-control-rcv">
                <PackageControlAndPrices
                    config={{
                        packageTitleLabel: {
                            text: "RCV",
                            backgroundColor: colorForSide(InquirySide.Sell),
                            foregroundColor: { color: "black" },
                        },
                    }}
                    model={{
                        increment,
                        levelValue: model.rcv.levelValue,
                        levelDisabled: model.rcv.levelDisabled,
                        levelSelected: model.rcv.levelSelected,
                        levelDisplayState: model.rcv.levelDisplayState,
                        levelInvalid: model.rcv.levelInvalid,
                        spreadValue: model.rcv.spreadValue,
                        spreadDisabled: model.rcv.spreadDisabled,
                        selectedTickSize,
                        quoted: model.rcv.quoted,
                        aqLevel: model.rcv.aqLevel,
                        inputForegroundColor: { color: "black" },
                        inputBackgroundColor: colorForSide(InquirySide.Sell),
                        isMac: model.isMac,
                    }}
                    callbacks={{
                        onLevelValueChange: callbacks.rcv.onLevelValueChange,
                        onLevelDirtyChange: callbacks.rcv.onLevelDirtyChange,
                        onSpreadValueChange: callbacks.rcv.onSpreadValueChange,
                        onSpreadDirtyChange: callbacks.rcv.onSpreadDirtyChange,
                    }}
                />
            </Box>
        </Box>
    );

    return (
        <Box contentAlignment={"center"} marginL="4" data-testid={"irs-package-controls"}>
            <IRSIncrementButtons
                labels={incrementButtons.incrementLabels}
                onSelectedIndexChange={(newSelectedIndex) => {
                    const selectedValue = incrementButtons.incrementValues[newSelectedIndex];
                    if (selectedValue) {
                        setSelectedTickSize(selectedValue);
                    }
                }}
            />
            {model.sideForPackage === InquirySide.Undisclosed ? getDoubleSidedPackageControl() : getSingleSidedPackageControl()}
            <PackagePriceMatrix model={model} callbacks={callbacks} />
        </Box>
    );
};
