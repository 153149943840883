import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { ImmutableDecimalNumber } from "@transficc/infrastructure";
import {
    ActionFailure,
    BidMidAsk,
    calculatePackageLevelValueByInquiryType,
    FailureSource,
    InquiryAction,
    InquirySide,
    InquiryState,
    InquiryStatus,
    IRSInquiry,
    IRSLeg,
    PackageDirection,
    PackageType,
    PricerState,
    PriceType,
    QuoteCompetitiveStatus,
    TradedAway,
} from "../irs-domain";
import { AssetClass, AutoXMode, VenueType } from "@transficc/trader-desktop-shared-domain";

export const mapProtocolToIRSInquiry = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
): IRSInquiry<PackageType> => {
    if (!inquiry.packageType) {
        throw new Error("Package type is undefined");
    }

    switch (inquiry.packageType) {
        case TraderDesktopProtocol.PackageType.Outright:
            return mapProtocolToIRSOutrightInquiry(inquiry, onDemandPrices, priceBasis);

        case TraderDesktopProtocol.PackageType.Curve:
            return mapProtocolToIRSCurveInquiry(inquiry, onDemandPrices, priceBasis);

        case TraderDesktopProtocol.PackageType.Compression:
            return mapProtocolToIRSCompressionInquiry(inquiry, onDemandPrices, priceBasis);

        default:
            inquiry.packageType satisfies never;
            throw new Error();
    }
};

const asPartialOfInquiryLeg = <PartialInquiryLeg extends Partial<IRSLeg<PackageType>>>(inquiryLeg: PartialInquiryLeg): PartialInquiryLeg =>
    inquiryLeg;

const asPartialOfInquiry = <PartialInquiry extends Partial<IRSInquiry<PackageType>>>(inquiry: PartialInquiry): PartialInquiry => inquiry;

const mapProtocolToIRSOutrightInquiry = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
): IRSInquiry<"outright"> => {
    const legs = inquiry.legs.map((leg): IRSLeg<"outright"> => {
        return { ...commonLegFields(leg), ...outrightLegFields(leg, onDemandPrices, priceBasis) };
    });
    const leg = legs[0];
    if (!leg) {
        throw new Error("Leg for outright inquiry was not mapped");
    }
    return {
        ...commonInquiryFields(inquiry, onDemandPrices, priceBasis),
        ...outrightInquiryFields(inquiry),
        legs: [leg],
    };
};

const mapProtocolToIRSCurveInquiry = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
): IRSInquiry<"curve"> => {
    const legs = inquiry.legs.map((leg): IRSLeg<"curve"> => {
        return { ...commonLegFields(leg), ...curveLegFields(leg, onDemandPrices, priceBasis) };
    });

    const firstLeg = legs[0];
    const secondLeg = legs[1];
    if (!firstLeg || !secondLeg) {
        throw new Error("Failed to map curve legs");
    }

    return {
        ...commonInquiryFields(inquiry, onDemandPrices, priceBasis),
        ...curveInquiryFields(inquiry),
        legs: [firstLeg, secondLeg],
    };
};

const mapProtocolToIRSCompressionInquiry = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
): IRSInquiry<"compression"> => {
    const legs = inquiry.legs.map((leg): IRSLeg<"compression"> => {
        return { ...commonLegFields(leg), ...compressionLegFields(leg) };
    });

    const firstLeg = legs[0];
    const remainingLegs = legs.splice(1);
    if (!firstLeg) {
        throw new Error("Failed to map compression legs");
    }

    return {
        ...commonInquiryFields(inquiry, onDemandPrices, priceBasis),
        ...compressionInquiryFields(inquiry, onDemandPrices, priceBasis),
        legs: [firstLeg, ...remainingLegs],
    };
};

const commonInquiryFields = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
    const priceValidForNanos = onDemandPrices ? onDemandPrices.priceValidForNanos : inquiry.priceValidForNanos;
    const priceGenerationTimestampNanos = onDemandPrices
        ? onDemandPrices.priceGenerationTimestampNanos
        : inquiry.priceGenerationTimestampNanos;
    return asPartialOfInquiry({
        ticketId: inquiry.ticketId,
        aggregateId: inquiry.aggregateId,
        actionFailures: inquiry.actionFailures.map(mapActionFailure),
        allowedActions: inquiry.allowedActions.map(mapAction),
        eventSequence: inquiry.eventSequence,
        venueType: mapVenueType(inquiry.venueType),
        assetClass: AssetClass.Irs,
        tier: inquiry.priceTier,
        counterpartyFirm: inquiry.counterpartyFirm,
        counterpartyTrader: inquiry.counterpartyTrader,
        salesPersonId: inquiry.salesPersonId,
        salesPersonName: inquiry.salesPersonName,
        timerStartTimestampMillis: inquiry.timerStartTimestampMillis,
        timerEndTimestampMillis: inquiry.timerEndTimestampMillis,
        state: mapState(inquiry.state),
        status: statusFromState(inquiry.state),
        lastInquiryStateUpdateTimestampNanos: inquiry.lastInquiryStateUpdateTimestampNanos,
        inquiryCreationTimestampNanos: inquiry.inquiryCreationTimestampNanos,
        lastAckedActionTraderName: inquiry.lastAckedActionTraderName,
        venueName: inquiry.venueName,
        numberOfDealers: inquiry.numberOfDealers,
        isFromSnapshot: inquiry.updateType === TraderDesktopProtocol.UpdateType.Snapshot,
        copyHandle: inquiry.pricerCustomComponents?.copyHandleButton?.handle ?? null,
        riskViewUrl: inquiry.pricerCustomComponents?.riskViewUrl ?? null,
        packageCoverPrice: inquiry.packageCoverPrice,
        packageTradedAway: mapTradedAway(inquiry.packageTradedAway),
        packageQuoteCompetitiveStatus: mapQuoteCompetitiveStatus(inquiry.packageQuoteCompetitiveStatus),
        isRfm: inquiry.isRfm,
        isPtmmRequired: inquiry.isPtmmRequired ?? false,
        autoQuotePriceState: mapPricerState(inquiry.pricerState),
        autoQuotePriceError: inquiry.pricerError?.length ? inquiry.pricerError : null,
        autoQuotePriceValidity:
            priceGenerationTimestampNanos && priceValidForNanos
                ? {
                      priceGenerationTimestampNanos: priceGenerationTimestampNanos,
                      priceValidForNanos: priceValidForNanos,
                  }
                : null,
        priceBasis: {
            eventSequence: priceBasis ? priceBasis.eventSequence : inquiry.priceBasisEventSequence,
        },
        autoXMode: mapAutoXMode(inquiry.autoXMode),
        autoXModeChangeReason: inquiry.autoXModeChangeReason,
        autoXModeChangeDescription: inquiry.autoXModeChangeDescription,
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const outrightInquiryFields = (inquiry: TraderDesktopProtocol.Inquiry) => {
    return asPartialOfInquiry({
        isMac: inquiry.isMac ?? false,
        packageType: "outright",
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const curveInquiryFields = (inquiry: TraderDesktopProtocol.Inquiry) => {
    const leftLeg = inquiry.legs[0];
    const rightLeg = inquiry.legs[1];
    const isMacInquiry = inquiry.isMac ?? false;
    return asPartialOfInquiry({
        isPackageMisweighted: inquiry.isPackageMisweighted ?? false,
        isMac: isMacInquiry,
        packageMidCompositePrice: inquiry?.packageMidCompositePrice,
        packageType: "curve",
        latestQuotedPackageFeePay:
            leftLeg && rightLeg
                ? calculatePackageLevelValueByInquiryType(
                      isMacInquiry,
                      rightLeg.latestQuotedBidPrice,
                      leftLeg.latestQuotedAskPrice,
                  )?.toString() ?? null
                : null,
        latestQuotedPackageFeeRcv:
            leftLeg && rightLeg
                ? calculatePackageLevelValueByInquiryType(
                      isMacInquiry,
                      rightLeg.latestQuotedAskPrice,
                      leftLeg.latestQuotedBidPrice,
                  )?.toString() ?? null
                : null,
        latestQuotedMidPackageFee:
            leftLeg && rightLeg
                ? calculatePackageLevelValueByInquiryType(
                      isMacInquiry,
                      rightLeg.latestQuotedMidPrice,
                      leftLeg.latestQuotedMidPrice,
                  )?.toString() ?? null
                : null,
        packageMinPriceIncrement: getPackageMinPriceIncrement(isMacInquiry, rightLeg),
    });
};

export function getPackageMinPriceIncrement(isMac: boolean, leg: TraderDesktopProtocol.InquiryLeg | undefined): string {
    if (isMac && leg) {
        return leg.minPriceIncrement;
    } else if (leg) {
        return new ImmutableDecimalNumber(leg.minPriceIncrement).multiplyBy(100).toString();
    } else {
        return "";
    }
}

const compressionInquiryFields = (
    inquiry: TraderDesktopProtocol.Inquiry,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
    return asPartialOfInquiry({
        isMac: false,
        packageType: "compression",
        packageDirection: mapPackageDirection(inquiry.packageDirection),
        customerPackageFee: inquiry.customerPackagePrice,
        customerOppositePackageFee: inquiry.customerOppositePackagePrice,
        tradedPackageFee: inquiry.tradedPackagePrice,
        latestQuotedPackageFee: inquiry.latestQuotedPackagePrice,
        latestQuotedMidPackageFee: inquiry.latestQuotedMidPackagePrice,
        latestQuotedOppositePackageFee: inquiry.latestQuotedOppositePackagePrice,
        packageMidCompositePrice: inquiry.packageMidCompositePrice,
        packageDelta: inquiry.packageDelta,
        hasMixedIndices: inquiry.hasMixedIndices,
        listId: inquiry.listId,

        autoQuotePackagePrice: onDemandPrices ? onDemandPrices.packagePrice : inquiry.pricerPackagePrice,
        autoQuotePackageMidPrice: onDemandPrices ? onDemandPrices.packageMidPrice : inquiry.pricerPackageMidPrice,
        autoQuoteOppositePackagePrice: onDemandPrices ? onDemandPrices.oppositePackagePrice : inquiry.pricerOppositePackagePrice,
        priceBasis: {
            eventSequence: priceBasis ? priceBasis.eventSequence : inquiry.priceBasisEventSequence,
            marketPackage: {
                manualPackagePrice:
                    priceBasis && priceBasis.marketPackage
                        ? priceBasis.marketPackage.manualPackagePrice
                        : inquiry.priceBasisMarketPackage?.priceBasisManualPackagePrice
                        ? inquiry.priceBasisMarketPackage.priceBasisManualPackagePrice
                        : null,
                packagePriceSpread:
                    priceBasis && priceBasis.marketPackage
                        ? priceBasis.marketPackage.packagePriceSpread
                        : inquiry.priceBasisMarketPackage?.priceBasisPackagePriceSpread
                        ? inquiry.priceBasisMarketPackage.priceBasisPackagePriceSpread
                        : null,
                packagePriceSource:
                    priceBasis && priceBasis.marketPackage
                        ? priceBasis.marketPackage.packagePriceSource
                        : inquiry.priceBasisMarketPackage?.priceBasisPackagePriceSource
                        ? inquiry.priceBasisMarketPackage.priceBasisPackagePriceSource
                        : null,
            },
            oppositePackage: {
                manualPackagePrice:
                    priceBasis && priceBasis.oppositePackage
                        ? priceBasis.oppositePackage.manualPackagePrice
                        : inquiry.priceBasisOppositePackage?.priceBasisManualPackagePrice
                        ? inquiry.priceBasisOppositePackage.priceBasisManualPackagePrice
                        : null,
                packagePriceSpread:
                    priceBasis && priceBasis.oppositePackage
                        ? priceBasis.oppositePackage.packagePriceSpread
                        : inquiry.priceBasisOppositePackage?.priceBasisPackagePriceSpread
                        ? inquiry.priceBasisOppositePackage.priceBasisPackagePriceSpread
                        : null,
                packagePriceSource:
                    priceBasis && priceBasis.oppositePackage
                        ? priceBasis.oppositePackage.packagePriceSource
                        : inquiry.priceBasisOppositePackage?.priceBasisPackagePriceSource
                        ? inquiry.priceBasisOppositePackage.priceBasisPackagePriceSource
                        : null,
            },
            preTradeMidMarketPackagePrice: priceBasis
                ? priceBasis.preTradeMidMarketPackagePrice
                : inquiry.priceBasisPreTradeMidMarketPackagePrice,
        },
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const commonLegFields = (leg: TraderDesktopProtocol.InquiryLeg) => {
    return asPartialOfInquiryLeg({
        side: mapSide(leg.side),
        quantity: leg.quantity,
        instrumentName: leg.instrumentName,
        effectiveDate: leg.effectiveDate,
        terminationDate: leg.terminationDate,
        clearingHouse: leg.clearingFirm,
        minPriceIncrement: leg.minPriceIncrement.toString(),
        currency: leg.currency,
        isCustom: leg.isInstrumentCustom ?? false,
        negotiatedPrice: leg.negotiatedPrice,
        position: leg.position,
        referenceId: leg.referenceId,
        coverPrice: leg.coverPrice,
        tradedAway: mapTradedAway(leg.tradedAway),
        quoteCompetitiveStatus: mapQuoteCompetitiveStatus(leg.quoteCompetitiveStatus),
        fixedRate: leg.fixedRate,
        floatingRateSpread: leg.floatingRateSpread,
        priceType: mapPriceType(leg.priceType),
    });
};

const outrightLegFields = (
    inquiryLeg: TraderDesktopProtocol.InquiryLeg,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
    const onDemandPricesLeg = onDemandPrices && onDemandPrices.legs.find((l) => l.referenceId === inquiryLeg.referenceId);
    const autoQuotePrices: BidMidAsk = onDemandPricesLeg
        ? [onDemandPricesLeg.bid, onDemandPricesLeg.mid, onDemandPricesLeg.ask]
        : [inquiryLeg.pricerBid, inquiryLeg.pricerMid, inquiryLeg.pricerAsk];

    const priceBasisLeg = priceBasis && priceBasis.legs && priceBasis.legs.find((pbLeg) => pbLeg.referenceId === inquiryLeg.referenceId);

    return asPartialOfInquiryLeg({
        lastQuotedPrices: [inquiryLeg.latestQuotedBidPrice, inquiryLeg.latestQuotedMidPrice, inquiryLeg.latestQuotedAskPrice],
        venuePrices: [inquiryLeg.bidCompositePrice, inquiryLeg.midCompositePrice, inquiryLeg.askCompositePrice],
        negotiatedPrice: inquiryLeg.negotiatedPrice,
        fixedPeriod: getPeriodOfType(inquiryLeg, TraderDesktopProtocol.RateType.Fixed),
        floatPeriod: getPeriodOfType(inquiryLeg, TraderDesktopProtocol.RateType.Floating),
        customerAskPrice: inquiryLeg.customerAskPrice,
        customerBidPrice: inquiryLeg.customerBidPrice,
        autoQuotePrices: autoQuotePrices,
        priceBasisLeg: {
            bidPriceSource: priceBasisLeg ? priceBasisLeg.bidPriceSource : inquiryLeg.priceBasisBidPriceSource,
            askPriceSource: priceBasisLeg ? priceBasisLeg.askPriceSource : inquiryLeg.priceBasisAskPriceSource,
            bidPriceSpread: priceBasisLeg ? priceBasisLeg.bidPriceSpread : inquiryLeg.priceBasisBidPriceSpread,
            askPriceSpread: priceBasisLeg ? priceBasisLeg.askPriceSpread : inquiryLeg.priceBasisAskPriceSpread,
            manualBidPrice: priceBasisLeg ? priceBasisLeg.manualBidPrice : inquiryLeg.priceBasisManualBidRate,
            manualAskPrice: priceBasisLeg ? priceBasisLeg.manualAskPrice : inquiryLeg.priceBasisManualAskRate,
            ptmm: priceBasisLeg ? priceBasisLeg.preTradeMidMarketPrice : inquiryLeg.priceBasisPreTradeMidMarketPrice,
        },
    });
};

function getPeriodOfType(leg: TraderDesktopProtocol.InquiryLeg, rateType: TraderDesktopProtocol.RateType): string {
    if (leg.leg1RateType === rateType) {
        return `${leg.leg1PaymentFrequency ?? ""} ${leg.leg1DayCountConvention ?? ""}`;
    } else if (leg.leg2RateType === rateType) {
        return `${leg.leg2PaymentFrequency ?? ""} ${leg.leg2DayCountConvention ?? ""}`;
    } else {
        return "";
    }
}

const curveLegFields = (
    leg: TraderDesktopProtocol.InquiryLeg,
    onDemandPrices: TraderDesktopProtocol.OnDemandPrices | null,
    priceBasis: TraderDesktopProtocol.PriceBasis | null,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
    const onDemandPricesLeg = onDemandPrices && onDemandPrices.legs.find((l) => l.referenceId === leg.referenceId);
    const autoQuotePrices: BidMidAsk = onDemandPricesLeg
        ? [onDemandPricesLeg.bid, onDemandPricesLeg.mid, onDemandPricesLeg.ask]
        : [leg.pricerBid, leg.pricerMid, leg.pricerAsk];
    const priceBasisLeg = priceBasis && priceBasis.legs && priceBasis.legs.find((pbLeg) => pbLeg.referenceId === leg.referenceId);

    return asPartialOfInquiryLeg({
        customerAskPrice: leg.customerAskPrice,
        customerBidPrice: leg.customerBidPrice,
        lastQuotedPrices: [leg.latestQuotedBidPrice, leg.latestQuotedMidPrice, leg.latestQuotedAskPrice],
        venuePrices: [leg.bidCompositePrice, leg.midCompositePrice, leg.askCompositePrice],
        negotiatedPrice: leg.negotiatedPrice,
        fixedPeriod: getPeriodOfType(leg, TraderDesktopProtocol.RateType.Fixed),
        floatPeriod: getPeriodOfType(leg, TraderDesktopProtocol.RateType.Floating),
        autoQuotePrices,
        priceBasisLeg: {
            bidPriceSource: priceBasisLeg ? priceBasisLeg.bidPriceSource : leg.priceBasisBidPriceSource,
            askPriceSource: priceBasisLeg ? priceBasisLeg.askPriceSource : leg.priceBasisAskPriceSource,
            bidPriceSpread: priceBasisLeg ? priceBasisLeg.bidPriceSpread : leg.priceBasisBidPriceSpread,
            askPriceSpread: priceBasisLeg ? priceBasisLeg.askPriceSpread : leg.priceBasisAskPriceSpread,
            manualBidPrice: priceBasisLeg ? priceBasisLeg.manualBidPrice : leg.priceBasisManualBidRate,
            manualAskPrice: priceBasisLeg ? priceBasisLeg.manualAskPrice : leg.priceBasisManualAskRate,
            ptmm: priceBasisLeg ? priceBasisLeg.preTradeMidMarketPrice : leg.priceBasisPreTradeMidMarketPrice,
        },
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const compressionLegFields = (leg: TraderDesktopProtocol.InquiryLeg) => {
    return asPartialOfInquiryLeg({
        fixedRate: leg.fixedRate,
    });
};

export const statusFromState = (state: TraderDesktopProtocol.InquiryState): InquiryStatus => {
    switch (state) {
        case TraderDesktopProtocol.InquiryState.NewRequest:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.LastLook:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerTimeout:
            return InquiryStatus.NotDone;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerReject:
            return InquiryStatus.NotDone;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerTimeout:
            return InquiryStatus.NotDone;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerReject:
            return InquiryStatus.NotDone;
        case TraderDesktopProtocol.InquiryState.Streaming:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.InquiryError:
            return InquiryStatus.Error;
        case TraderDesktopProtocol.InquiryState.Done:
            return InquiryStatus.Done;
        case TraderDesktopProtocol.InquiryState.DealerAccepted:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.QuoteRefreshRequested:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.TradedPendingFeeAllocation:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.CustomerAccepted:
            return InquiryStatus.Active;
        case TraderDesktopProtocol.InquiryState.CurtainPeriod:
        case TraderDesktopProtocol.InquiryState.CurtainQuoteStreaming:
        case TraderDesktopProtocol.InquiryState.QuoteOtw:
        case TraderDesktopProtocol.InquiryState.QuoteSubject:
        case TraderDesktopProtocol.InquiryState.QuoteFirm:
        case TraderDesktopProtocol.InquiryState.InquiryPickedUpOnVenueUI:
        case TraderDesktopProtocol.InquiryState.PendingSpot:
        case TraderDesktopProtocol.InquiryState.PendingPriceConfirmation:
            throw new Error(state + " not supported for IRS.");
        default:
            state satisfies never;
            throw new Error();
    }
};

const mapAction = (allowedAction: TraderDesktopProtocol.ActionType): InquiryAction => {
    switch (allowedAction) {
        case TraderDesktopProtocol.ActionType.DealerAccept:
            return InquiryAction.DealerAccept;
        case TraderDesktopProtocol.ActionType.DealerReject:
            return InquiryAction.DealerReject;
        case TraderDesktopProtocol.ActionType.Quote:
            return InquiryAction.Quote;
        default:
            allowedAction satisfies never;
            throw new Error();
    }
};

function mapVenueType(venueType: TraderDesktopProtocol.VenueType | null): VenueType | null {
    if (venueType === null || venueType === undefined) {
        return null;
    }
    switch (venueType) {
        case TraderDesktopProtocol.VenueType.Mtf:
            return VenueType.Mtf;
        case TraderDesktopProtocol.VenueType.Sef:
            return VenueType.Sef;
        case TraderDesktopProtocol.VenueType.OffVenue:
            return VenueType.OffVenue;
        default:
            venueType satisfies never;
            throw new Error("unsupported venueType: " + String(venueType));
    }
}

const mapFailureSource = (actionFailureSource: TraderDesktopProtocol.ActionFailureSource): FailureSource => {
    switch (actionFailureSource) {
        case TraderDesktopProtocol.ActionFailureSource.Transficc:
            return FailureSource.Transficc;
        case TraderDesktopProtocol.ActionFailureSource.Venue:
            return FailureSource.Venue;
        default:
            actionFailureSource satisfies never;
            throw new Error();
    }
};

const mapState = (state: TraderDesktopProtocol.InquiryState): InquiryState => {
    switch (state) {
        case TraderDesktopProtocol.InquiryState.DealerAccepted:
            return InquiryState.DealerAccepted;
        case TraderDesktopProtocol.InquiryState.Done:
            return InquiryState.Done;
        case TraderDesktopProtocol.InquiryState.InquiryError:
            return InquiryState.InquiryError;
        case TraderDesktopProtocol.InquiryState.LastLook:
            return InquiryState.LastLook;
        case TraderDesktopProtocol.InquiryState.NewRequest:
            return InquiryState.NewRequest;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerReject:
            return InquiryState.CustomerReject;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerTimeout:
            return InquiryState.CustomerTimeout;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerReject:
            return InquiryState.DealerReject;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerTimeout:
            return InquiryState.DealerTimeout;
        case TraderDesktopProtocol.InquiryState.Streaming:
            return InquiryState.Streaming;
        case TraderDesktopProtocol.InquiryState.QuoteRefreshRequested:
            return InquiryState.QuoteRefreshRequested;
        case TraderDesktopProtocol.InquiryState.TradedPendingFeeAllocation:
            return InquiryState.TradedPendingFeeAllocation;
        case TraderDesktopProtocol.InquiryState.CustomerAccepted:
            return InquiryState.CustomerAccepted;
        case TraderDesktopProtocol.InquiryState.CurtainPeriod:
        case TraderDesktopProtocol.InquiryState.CurtainQuoteStreaming:
        case TraderDesktopProtocol.InquiryState.QuoteFirm:
        case TraderDesktopProtocol.InquiryState.QuoteOtw:
        case TraderDesktopProtocol.InquiryState.QuoteSubject:
        case TraderDesktopProtocol.InquiryState.PendingSpot:
        case TraderDesktopProtocol.InquiryState.PendingPriceConfirmation:
        case TraderDesktopProtocol.InquiryState.InquiryPickedUpOnVenueUI:
            throw new Error(state + " not supported for IRS.");
        default:
            state satisfies never;
            throw new Error("Unsupported state for IRS: " + String(state));
    }
};

const mapSide = (side: TraderDesktopProtocol.Side): InquirySide => {
    switch (side) {
        case TraderDesktopProtocol.Side.Buy:
            return InquirySide.Buy;
        case TraderDesktopProtocol.Side.Sell:
            return InquirySide.Sell;
        case TraderDesktopProtocol.Side.Undisclosed:
            return InquirySide.Undisclosed;
        default:
            side satisfies never;
            throw new Error();
    }
};

const mapAutoXMode = (autoXMode: TraderDesktopProtocol.AutoXMode | null): AutoXMode | null => {
    switch (autoXMode) {
        case null:
            return null;
        case TraderDesktopProtocol.AutoXMode.AutoExecute:
            return AutoXMode.AutoExecute;
        case TraderDesktopProtocol.AutoXMode.AutoReject:
            return AutoXMode.AutoReject;
        case TraderDesktopProtocol.AutoXMode.AutoStream:
            return AutoXMode.AutoStream;
        case TraderDesktopProtocol.AutoXMode.Manual:
            return AutoXMode.Manual;
        default:
            autoXMode satisfies never;
            throw new Error();
    }
};

const mapPricerState = (pricerState: TraderDesktopProtocol.PricerState): PricerState => {
    switch (pricerState) {
        case TraderDesktopProtocol.PricerState.Error:
            return PricerState.Error;
        case TraderDesktopProtocol.PricerState.Pending:
            return PricerState.Pending;
        case TraderDesktopProtocol.PricerState.Priced:
            return PricerState.Priced;
        case TraderDesktopProtocol.PricerState.Terminated:
            return PricerState.Terminated;
        case TraderDesktopProtocol.PricerState.Invalid:
            return PricerState.Invalid;
        case TraderDesktopProtocol.PricerState.Rejected:
            return PricerState.Rejected;
        default:
            pricerState satisfies never;
            throw new Error();
    }
};

const mapActionFailure = (actionFailure: TraderDesktopProtocol.ActionFailure): ActionFailure => {
    return {
        actionId: actionFailure.actionId,
        actionType: mapAction(actionFailure.actionType),
        reason: actionFailure.reason,
        source: mapFailureSource(actionFailure.source),
        userId: actionFailure.userId,
    };
};

const mapTradedAway = (tradedAway: TraderDesktopProtocol.TradedAway | null): TradedAway | null => {
    switch (tradedAway) {
        case null:
            return null;
        case TraderDesktopProtocol.TradedAway.Yes:
            return TradedAway.Yes;
        case TraderDesktopProtocol.TradedAway.No:
            return TradedAway.No;
        case TraderDesktopProtocol.TradedAway.NotSet:
            return TradedAway.NotSet;
        default:
            tradedAway satisfies never;
            throw new Error();
    }
};

const mapQuoteCompetitiveStatus = (
    quoteCompetitiveStatus: TraderDesktopProtocol.QuoteCompetitiveStatus | null,
): QuoteCompetitiveStatus | null => {
    switch (quoteCompetitiveStatus) {
        case null:
            return null;
        case TraderDesktopProtocol.QuoteCompetitiveStatus.Tied:
            return QuoteCompetitiveStatus.Tied;
        case TraderDesktopProtocol.QuoteCompetitiveStatus.Cover:
            return QuoteCompetitiveStatus.Cover;
        case TraderDesktopProtocol.QuoteCompetitiveStatus.Best:
            return QuoteCompetitiveStatus.Best;
        case TraderDesktopProtocol.QuoteCompetitiveStatus.CoverTied:
            return QuoteCompetitiveStatus.CoverTied;
        case TraderDesktopProtocol.QuoteCompetitiveStatus.NotSet:
            return QuoteCompetitiveStatus.NotSet;
        default:
            quoteCompetitiveStatus satisfies never;
            throw new Error();
    }
};

const mapPackageDirection = (packageDirection: TraderDesktopProtocol.PackageDirection | null): PackageDirection => {
    switch (packageDirection) {
        case TraderDesktopProtocol.PackageDirection.Market:
            return PackageDirection.Market;
        case TraderDesktopProtocol.PackageDirection.Opposite:
            return PackageDirection.Opposite;
        case TraderDesktopProtocol.PackageDirection.Undisclosed:
            return PackageDirection.Undisclosed;
        default:
            throw new Error();
    }
};

const mapPriceType = (priceType: TraderDesktopProtocol.PriceType): PriceType => {
    switch (priceType) {
        case TraderDesktopProtocol.PriceType.InterestRate:
            return PriceType.InterestRate;
        case TraderDesktopProtocol.PriceType.NetPresentValue:
            return PriceType.NetPresentValue;
        case TraderDesktopProtocol.PriceType.PercentOfPar:
        case TraderDesktopProtocol.PriceType.Spread:
        case TraderDesktopProtocol.PriceType.Yield:
            throw new Error("We do not support PercentOfPar, Yield or Spread pricing for IRS");
        default:
            priceType satisfies never;
            throw new Error();
    }
};
