import { useCallback } from "react";
import { TradingStatus } from "./TradingStatus";
import { updateTraderDesktopSettings } from "../../thunks/TraderSettings";
import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { useThunkDispatch } from "../../hooks/useThunkDispatch";
import { useSessionStateDispatch } from "./useSessionStateDispatch";

export const useTradingStatusToggleDispatch = (): ((currentlyToggledOn: boolean) => void) => {
    const sessionStateDispatch = useSessionStateDispatch();
    const dispatch = useThunkDispatch();

    return useCallback(
        (currentlyToggledOn: boolean) => {
            const newToggledOn = !currentlyToggledOn;

            sessionStateDispatch.setTradingStatus(newToggledOn ? TradingStatus.ON : TradingStatus.OFF);
            dispatch(
                updateTraderDesktopSettings({
                    tradingStatus: newToggledOn ? TraderDesktopProtocol.TradingStatus.On : TraderDesktopProtocol.TradingStatus.Off,
                }),
            );
        },
        [sessionStateDispatch, dispatch],
    );
};
