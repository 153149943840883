import React from "react";
import { Box, Text } from "../../atoms";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";
import { BorderType } from "../../theme/theme-utils";

export interface DataMatrixCellPosition {
    rowNo: number;
    colNo: number;
}

export interface DataMatrixSelectedCell {
    pos: DataMatrixCellPosition;
    config?: {
        cellSelectionColor?: Theme.Color;
        cellDisabledSelectionColor?: Theme.Color;
        cellSelectionBorderWidth?: Theme.SpacingLevels;
        cellSelectionLabel?: string;
    };
}

export const dataMatrixCellPositionEq = (a: DataMatrixCellPosition, b: DataMatrixCellPosition): boolean =>
    a.rowNo === b.rowNo && a.colNo === b.colNo;

export interface DataMatrixCellData {
    value: string | null;
    secondaryText?: string;
    cellBackgroundColor?: Theme.Color;
    cellForegroundColor?: Theme.Color;
    cellFontSize?: Theme.FontSizes;
}

export type DataMatrixModifiers = "CTRL" | "SHIFT";

export interface DataMatrixProps {
    config: {
        rowConfig: DataMatrixRowConfig[];
        rowLabelPosition?: "left" | "right";
        columnConfig: DataMatrixColumnConfig[];
        columnLabelPosition?: "top" | "bottom";
        cellBackgroundColor: Theme.Color;
        cellForegroundColor: Theme.Color;
        cellWidth: Theme.SpacingLevels;
        cellHeight: Theme.SpacingLevels;
        cellFontSize: Theme.FontSizes;
        cellBorderWidth: Theme.SpacingLevels;
        allowClickingNullCells?: boolean;
    };
    data: DataMatrixCellData[][];
    selectedCells?: DataMatrixSelectedCell[];
    onClickCell?: (cellPosition: DataMatrixCellPosition, cellData: DataMatrixCellData, modifiers: DataMatrixModifiers[]) => void;
    disabledSelection?: boolean;
}

export interface LabelConfig {
    backgroundColor: Theme.Color;
    foregroundColor: Theme.Color;
    text: string;
}

export interface DataMatrixRowConfig {
    label?: string;
    labelColor?: Theme.Color;
    labelSize?: Theme.FontSizes;
    titleLabel?: LabelConfig;
}

export interface DataMatrixColumnConfig {
    label?: string;
    labelColor?: Theme.Color;
    labelSize?: Theme.FontSizes;
}

const InnerGrid = styled(Box)<{ columns: number; rows: number; cellWidth: Theme.SpacingLevels; cellHeight: Theme.SpacingLevels }>`
    display: grid;
    grid-template-rows: repeat(${(props) => props.rows}, ${(props) => props.theme.spacing[props.cellHeight]});
    grid-template-columns: repeat(${(props) => props.columns}, ${(props) => props.theme.spacing[props.cellWidth]});
`;

const SelectedCellBorder = styled(Box).attrs({ rounded: true })<{
    borderT: ThemeUtils.BorderType;
    borderL: ThemeUtils.BorderType;
    borderB: ThemeUtils.BorderType;
    borderR: ThemeUtils.BorderType;
}>`
    z-index: 10;
    position: absolute;
    top: -${(props) => (props.borderT === "none" ? "0" : props.theme.spacing[props.borderT.width])};
    bottom: -${(props) => (props.borderB === "none" ? "0" : props.theme.spacing[props.borderB.width])};
    left: -${(props) => (props.borderL === "none" ? "0" : props.theme.spacing[props.borderL.width])};
    right: -${(props) => (props.borderR === "none" ? "0" : props.theme.spacing[props.borderR.width])};
`;

const Cell = styled(Box).attrs({
    width: "full",
    height: "full",
    contentDirection: "row",
    contentAlignment: "center",
    contentJustification: "center",
})<{ isClickable: boolean; selected: boolean }>`
    position: relative;
    user-select: none;
    ${(props) => (props.isClickable ? "cursor: pointer;" : "")}
    ${(props) => (props.isClickable ? "" : "pointer-events: none;")}
`;

const CornerSpacer = styled(Box)``;

const RowLabelContainer = styled(Box).attrs<{ rowLabelPosition: "left" | "right" }>((props) => ({
    width: "full",
    height: "full",
    contentDirection: "row",
    contentAlignment: "center",
    contentJustification: props.rowLabelPosition === "left" ? "end" : "start",
    paddingR: props.rowLabelPosition === "left" ? "2" : "0",
    paddingL: props.rowLabelPosition === "right" ? "2" : "0",
}))<{ rowLabelPosition: "left" | "right" }>``;

const ColumnLabelContainer = styled(Box).attrs<{ columnLabelPosition: "top" | "bottom" }>((props) => ({
    width: "full",
    height: "full",
    contentDirection: "row",
    contentAlignment: props.columnLabelPosition === "top" ? "end" : "start",
    contentJustification: "center",
    paddingT: props.columnLabelPosition === "bottom" ? "1" : "0",
    paddingB: props.columnLabelPosition === "top" ? "1" : "0",
}))<{ columnLabelPosition: "top" | "bottom" }>``;

const OuterGrid = styled(Box)<{
    rows: number;
    columns: number;
    cellWidth: Theme.SpacingLevels;
    cellHeight: Theme.SpacingLevels;
    rowLabelPosition: "left" | "right" | null;
    columnLabelPosition: "top" | "bottom" | null;
}>`
    display: grid;
    grid-template-rows: repeat(
        ${(props) => props.rows + (props.columnLabelPosition !== null ? 1 : 0)},
        ${(props) => props.theme.spacing[props.cellHeight]}
    );
    grid-template-columns: repeat(
        ${(props) => props.columns + (props.rowLabelPosition !== null ? 1 : 0)},
        ${(props) => props.theme.spacing[props.cellWidth]}
    );

    & > ${InnerGrid} {
        grid-column: ${(props) => (props.rowLabelPosition === "left" ? "2" : "1")} / span ${(props) => props.columns};
        grid-row: ${(props) => (props.columnLabelPosition === "top" ? "2" : "1")} / span ${(props) => props.rows};
    }

    & > ${CornerSpacer} {
        grid-column: ${(props) => (props.rowLabelPosition === "left" ? "1" : props.columns + 1)};
        grid-row: ${(props) => (props.columnLabelPosition === "top" ? "1" : props.rows + 1)};
    }
`;

function selections(
    disabledSelection: boolean,
    selectionsOnThisCell: DataMatrixSelectedCell[],
): { selectedCellBorderTopLeft: BorderType; selectedCellBorderBottomRight: BorderType; hasAnySelectionsAtAll: boolean } {
    const hasAnySelectionsAtAll = selectionsOnThisCell.length > 0;

    function cellBorderWidth(selectedCell?: DataMatrixSelectedCell): Theme.SpacingLevels {
        return selectedCell?.config?.cellSelectionBorderWidth ?? "0.5";
    }

    function cellBorderColor(selectedCell?: DataMatrixSelectedCell): Theme.Color {
        return (
            (disabledSelection ? selectedCell?.config?.cellDisabledSelectionColor : selectedCell?.config?.cellSelectionColor) ?? {
                color: "white",
            }
        );
    }

    let topLeftSelectedCell: DataMatrixSelectedCell | undefined;
    let bottomRightSelectedCell: DataMatrixSelectedCell | undefined;

    if (selectionsOnThisCell.length <= 1) {
        topLeftSelectedCell = selectionsOnThisCell[0];
        bottomRightSelectedCell = selectionsOnThisCell[0];
    } else if (selectionsOnThisCell.length === 2) {
        topLeftSelectedCell = selectionsOnThisCell[0];
        bottomRightSelectedCell = selectionsOnThisCell[1];
    } else {
        throw new Error("Too many selected cells to handle");
    }

    const selectedCellBorderTopLeft: BorderType = {
        width: cellBorderWidth(topLeftSelectedCell),
        color: cellBorderColor(topLeftSelectedCell),
    };
    const selectedCellBorderBottomRight: BorderType = {
        width: cellBorderWidth(bottomRightSelectedCell),
        color: cellBorderColor(bottomRightSelectedCell),
    };

    return { hasAnySelectionsAtAll, selectedCellBorderTopLeft, selectedCellBorderBottomRight };
}

export const DataMatrix: React.FC<DataMatrixProps> = ({
    config: {
        columnConfig,
        rowConfig,
        cellBorderWidth,
        rowLabelPosition = "left",
        columnLabelPosition = "top",
        cellForegroundColor,
        cellBackgroundColor,
        cellHeight,
        cellWidth,
        cellFontSize,
        allowClickingNullCells = false,
    },
    data,
    onClickCell,
    selectedCells,
    disabledSelection,
}) => {
    const areCellsClickable = !!onClickCell && !disabledSelection;
    const doesHaveRowLabel = rowConfig.some((row) => !!row.label);
    const doesHaveColumnLabel = columnConfig.some((col) => !!col.label);
    const noCellBorder: ThemeUtils.BorderType = { width: cellBorderWidth, color: { color: "transparent" } };
    const cellBorder: ThemeUtils.BorderType = { width: cellBorderWidth, color: cellForegroundColor };

    const renderInnerGrid = (): React.ReactNode => (
        <InnerGrid rows={rowConfig.length} columns={columnConfig.length} cellWidth={cellWidth} cellHeight={cellHeight}>
            {data.map((row, rowIndex) =>
                row.map((cell, columnIndex) => {
                    const cellHasDefinedValue = cell.value !== null;
                    const isClickable = areCellsClickable && (allowClickingNullCells || cellHasDefinedValue);
                    const selectionsOnThisCell = (selectedCells ?? []).filter(
                        (selectedCell) => selectedCell.pos.rowNo === rowIndex && selectedCell.pos.colNo === columnIndex,
                    );
                    const { hasAnySelectionsAtAll, selectedCellBorderTopLeft, selectedCellBorderBottomRight } = selections(
                        disabledSelection ?? false,
                        selectionsOnThisCell,
                    );
                    const selectedSides = selectionsOnThisCell
                        .map((value) => value.config?.cellSelectionLabel)
                        .filter((label) => label !== undefined)
                        .join(",");

                    const foregroundColor = cell.cellForegroundColor ?? cellForegroundColor;
                    const backgroundColor = cell.cellBackgroundColor ?? cellBackgroundColor;
                    const fontSize = cell.cellFontSize ?? cellFontSize;
                    const onClick = isClickable
                        ? (event: React.MouseEvent<HTMLElement>) => {
                              const modifiers: DataMatrixModifiers[] = [];
                              if (event.ctrlKey) {
                                  modifiers.push("CTRL");
                              }
                              if (event.shiftKey) {
                                  modifiers.push("SHIFT");
                              }
                              onClickCell({ rowNo: rowIndex, colNo: columnIndex }, cell, modifiers);
                          }
                        : undefined;

                    return (
                        <Cell
                            data-is-selected={String(hasAnySelectionsAtAll)}
                            data-selected-side={selectedSides.length > 0 ? selectedSides : undefined}
                            data-is-clickable={String(isClickable)}
                            key={`cell-${rowIndex}-${columnIndex}`}
                            borderL={columnIndex === 0 ? cellBorder : noCellBorder}
                            borderT={rowIndex === 0 ? cellBorder : noCellBorder}
                            borderR={cellBorder}
                            borderB={cellBorder}
                            backgroundColor={backgroundColor}
                            isClickable={isClickable}
                            selected={hasAnySelectionsAtAll}
                            onClick={onClick}
                            title={cell.secondaryText ?? ""}
                        >
                            {hasAnySelectionsAtAll && (
                                <SelectedCellBorder
                                    borderT={selectedCellBorderTopLeft}
                                    borderL={selectedCellBorderTopLeft}
                                    borderB={selectedCellBorderBottomRight}
                                    borderR={selectedCellBorderBottomRight}
                                />
                            )}
                            <Text textColor={foregroundColor} size={fontSize} weight={hasAnySelectionsAtAll ? "black" : "normal"}>
                                {cell.value ?? "-"}
                            </Text>
                        </Cell>
                    );
                }),
            )}
        </InnerGrid>
    );

    const renderRowLabels = (): React.ReactNode => {
        if (!doesHaveRowLabel) {
            return null;
        }

        return rowConfig.map((row, idx) => (
            <RowLabelContainer key={idx} rowLabelPosition={rowLabelPosition}>
                {row.titleLabel && (
                    <Box
                        contentDirection={"row"}
                        contentAlignment={"center"}
                        padding={"0.5"}
                        paddingL={"1"}
                        paddingR={"1"}
                        marginR={"2"}
                        rounded
                        backgroundColor={row.titleLabel.backgroundColor}
                    >
                        <Text size={"sm"} textColor={row.titleLabel.foregroundColor}>
                            {row.titleLabel.text}
                        </Text>
                    </Box>
                )}
                <Text textColor={row.labelColor ?? cellForegroundColor} size={row.labelSize}>
                    {row.label ? row.label : ""}
                </Text>
            </RowLabelContainer>
        ));
    };

    const renderColumnLabels = (): React.ReactNode => {
        if (!doesHaveColumnLabel) {
            return null;
        }

        return columnConfig.map((col, idx) => (
            <ColumnLabelContainer key={idx} columnLabelPosition={columnLabelPosition}>
                <Text textColor={col.labelColor ?? cellForegroundColor} size={col.labelSize}>
                    {col.label ? col.label : ""}
                </Text>
            </ColumnLabelContainer>
        ));
    };

    const renderCornerSpacer = (): React.ReactNode => {
        return (doesHaveColumnLabel || doesHaveRowLabel) && <CornerSpacer />;
    };

    const renderLabels = (): React.ReactNode => {
        if (doesHaveColumnLabel && columnLabelPosition === "bottom") {
            return (
                <>
                    {renderRowLabels()}
                    {renderColumnLabels()}
                </>
            );
        }

        return (
            <>
                {renderColumnLabels()}
                {renderRowLabels()}
            </>
        );
    };

    return (
        <OuterGrid
            rows={rowConfig.length}
            columns={columnConfig.length}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            rowLabelPosition={doesHaveRowLabel ? rowLabelPosition : null}
            columnLabelPosition={doesHaveColumnLabel ? columnLabelPosition : null}
            data-test-is-disabled={disabledSelection}
        >
            {renderCornerSpacer()}
            {renderLabels()}
            {renderInnerGrid()}
        </OuterGrid>
    );
};
