import * as React from "react";
import { useEffect } from "react";
import { useInquiriesSubscription } from "./redux/hooks/useInquiriesSubscription";
import { IRSDock, IRSDomain, useActiveIRSInquiries } from "@transficc/trader-desktop-irs";
import { ConnectionStatus } from "./redux/slices/session/ConnectionStatus";
import { useNewRequestEffect } from "./utils/useNewRequestEffect";
import {
    useConnectionStatus,
    useServerTradingStatus,
    useSoundEnabled,
    useTradingStatus,
} from "./redux/slices/session/useSessionSliceSelectors";
import { useSessionStateDispatch } from "./redux/slices/session/useSessionStateDispatch";
import { useCanSoundBeEnabledByDefault } from "./utils/useCanSoundBeEnabledByDefault";
import { useTradingStatusToggleDispatch } from "./redux/slices/session/useTradingStatusToggleDispatch";
import log from "./logger/globalLogger";
import { useInquiryActionPublisher } from "./redux/hooks/useInquiryActionPublisher";
import { useActionIdGenerator, useClock, useElectronApi } from "@transficc/trader-desktop-application-context";
import { openWindow } from "./utils/openWindow";
import { useIsLoading } from "./redux/slices/LoadingStatusSlice";
import { Loading } from "./Loading";
import { DisableBodyScrolling } from "./DisableBodyScrolling";
import { FrameName } from "@transficc/trader-desktop-electron-api";

const IRSTicketDock: React.FC = () => {
    const electronApi = useElectronApi();
    const actionIdGenerator = useActionIdGenerator();
    const clock = useClock();
    const sessionStateDispatch = useSessionStateDispatch();
    const activeInquiries = useActiveIRSInquiries();
    const connectionStatus = useConnectionStatus();
    const connectionStatusString = connectionStatus === ConnectionStatus.CONNECTED ? "connected" : "disconnected";

    const tradingStatus = useTradingStatus();
    const serverTradingStatus = useServerTradingStatus();
    const tradingStatusToggleDispatch = useTradingStatusToggleDispatch();

    const canSoundBeEnabledByDefault = useCanSoundBeEnabledByDefault();
    const volumeIsOn = useSoundEnabled() ?? canSoundBeEnabledByDefault;
    useNewRequestEffect(activeInquiries, electronApi, clock, volumeIsOn, [IRSDomain.InquiryState.NewRequest], true);

    useInquiriesSubscription();

    useEffect(() => {
        const title = "IRS Dock";
        document.title = title;
        electronApi.setWindowTitle(title);
    }, [electronApi]);

    const publishAction = useInquiryActionPublisher(actionIdGenerator, clock);

    const loading = useIsLoading();

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <DisableBodyScrolling />
            <IRSDock
                publishAction={publishAction}
                connectionStatus={connectionStatusString}
                volumeIsOn={volumeIsOn}
                onVolumeToggle={sessionStateDispatch.toggleSoundEnabled}
                onTradingStatusChanged={tradingStatusToggleDispatch}
                tradingStatus={tradingStatus}
                serverTradingStatus={serverTradingStatus}
                unexpectedErrorHandler={({ name, message, stack }, errorInfo) => log.error(name, message, stack, errorInfo.componentStack)}
                openRiskViewWindow={(url) =>
                    openWindow({
                        electronApi,
                        url: url,
                        target: FrameName.RISK_VIEW,
                        electronOpenHidden: false,
                        popup: true,
                        saveWindowSize: true,
                    })
                }
                openCustomerAnalyticsWindow={(customerFirm, customerTrader) =>
                    openWindow({
                        electronApi,
                        url: `/popup/customer-analytics?isCredit=false&customerFirm=${customerFirm}&customerTrader=${customerTrader}`,
                        target: FrameName.CUSTOMER_ANALYTICS,
                        electronOpenHidden: false,
                        popup: true,
                        saveWindowSize: true,
                        width: 970,
                        height: 410,
                    })
                }
            />
        </>
    );
};

export default IRSTicketDock;
