import * as React from "react";
import { useCallback, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { ColDef, FilterChangedEvent, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import { DateTime } from "luxon";
import { EpochClock, useMemoFlatmappedList } from "@transficc/infrastructure";

import { LicenseManager } from "ag-grid-enterprise";
import { IRSInquiry, PackageType } from "../irs-domain";

import { BlotterRow, mapInquiryToBlotterRows } from "./blotter-rows";
import {
    Box,
    formatDateTimeAsString,
    formatIntDateAsString,
    formatPriceOrDefaultEmptyValue,
    formatWithCommas,
} from "@transficc/components";
import { AG_GRID_CLASS_NAME, AG_GRID_LICENSE, AgGridTheme, saveAndRestoreColumnState } from "@transficc/trader-desktop-ag-grid-common";
import { LocalStorageKeys } from "@transficc/trader-desktop-local-storage";

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

export interface ActiveBlotterPanelProps {
    clock: EpochClock;
    filteredInquiries: IRSInquiry<PackageType>[];
    onReady: (event: ActiveBlotterPanelApi) => void;
}

export interface ActiveBlotterPanelApi {
    downloadCsv: (filename: string) => void;
}

interface BlotterFilterModel {
    creationTime: {
        dateFrom: string;
        dateTo: string | null;
        filterType: string;
        type: string;
        forToday: boolean;
    };
}

const dateTimeStringForMidnightToday = (clock: EpochClock): string => {
    return DateTime.fromMillis(clock.getCurrentTimeNs() / 1_000_000).toISODate() + " 00:00:00";
};

const dateTimeFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: DateTime) => {
        const filterDateMidnight = DateTime.fromJSDate(filterLocalDateAtMidnight);
        const cellValueAtMidnight = DateTime.fromObject({
            year: cellValue.year,
            month: cellValue.month,
            day: cellValue.day,
        });
        return cellValueAtMidnight.diff(filterDateMidnight).milliseconds;
    },
    browserDatePicker: true,
    minValidYear: 2000,
    maxValidYear: 2080,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    cellRendererParams: {
        suppressCount: true,
    },
};

const cellRendererWithTitle: Partial<ColDef> = {
    cellRenderer: (params: ICellRendererParams<string, string>) => <span title={params.value ?? undefined}>{params.value}</span>,
};

const priceFormatter = (params: { data: BlotterRow; value: string | null }): string => {
    return formatPriceOrDefaultEmptyValue(params.value, params.data.legMinPriceIncrement);
};

const inquiryColumnDefs: ColDef[] = [
    {
        headerName: "Ticket Id",
        cellRenderer: "agGroupCellRenderer",
        colId: "ticketId",
        field: "ticketId",
        cellRendererParams: {
            suppressCount: true,
        },
        minWidth: 180,
        filter: false,
    },
    {
        headerName: "Creation Time",
        colId: "creationTime",
        filter: "agDateColumnFilter",
        sort: "desc",
        icons: {
            filter: " ",
        },
        filterParams: dateTimeFilterParams,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressFloatingFilterButton: true,
        valueGetter: (params: { data: BlotterRow }): DateTime => {
            return DateTime.fromMillis(params.data.inquiryCreationTimestampNanos / 1_000_000);
        },
        valueFormatter: (params: { value: DateTime }): string => {
            return formatDateTimeAsString(params.value);
        },
        minWidth: 220,
    },
    {
        headerName: "Updated Time",
        colId: "updatedTime",
        filter: "agDateColumnFilter",
        icons: {
            filter: " ",
        },
        filterParams: dateTimeFilterParams,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressFloatingFilterButton: true,
        valueGetter: (params: { data: BlotterRow }): DateTime => {
            return DateTime.fromMillis(params.data.lastInquiryStateUpdateTimestampNanos / 1_000_000);
        },
        valueFormatter: (params: { value: DateTime }): string => {
            return formatDateTimeAsString(params.value);
        },
        minWidth: 220,
    },
    {
        headerName: "State",
        colId: "state",
        field: "inquiryState",
        minWidth: 270,
    },
    {
        headerName: "Auto Mode",
        colId: "autoMode",
        field: "autoMode",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Customer Firm",
        colId: "customerFirm",
        field: "counterpartyFirm",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Customer Trader",
        colId: "customerTrader",
        field: "counterpartyTrader",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Trader Name",
        colId: "traderName",
        field: "lastAckedActionTraderName",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Salesperson Name",
        colId: "salespersonName",
        field: "salespersonName",
        minWidth: 200,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Salesperson Id",
        colId: "salespersonId",
        field: "salespersonId",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Venue",
        colId: "venueName",
        field: "venueName",
        minWidth: 180,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Venue Type",
        colId: "venueType",
        field: "venueType",
        minWidth: 135,
    },
    {
        headerName: "Venue Aggregate Id",
        colId: "venueId",
        field: "aggregateId",
        minWidth: 200,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Leg",
        colId: "legPosition",
        field: "legPosition",
        minWidth: 80,
        filter: false,
    },
    {
        headerName: "Side",
        colId: "side",
        field: "legSide",
        minWidth: 140,
    },
    {
        headerName: "Instrument",
        colId: "instrumentName",
        field: "legInstrumentName",
        minWidth: 200,
        ...cellRendererWithTitle,
    },
    {
        headerName: "Size",
        colId: "size",
        field: "legQuantity",
        valueGetter: (params: { data: BlotterRow }): number => {
            const quantity = params.data.legQuantity;
            return parseInt(quantity);
        },
        valueFormatter: (params: { value: number }): string => {
            return formatWithCommas(`${params.value}`) ?? "-";
        },
        minWidth: 180,
        filter: false,
    },
    {
        headerName: "Fixed Rate",
        colId: "fixedRate",
        field: "legFixedRate",
        minWidth: 140,
        sortable: false,
        filter: false,
    },
    {
        headerName: "Price Type",
        colId: "priceType",
        field: "legPriceType",
        minWidth: 190,
        filter: false,
    },
    {
        headerName: "Effective Date",
        colId: "effectiveDate",
        field: "legEffectiveDate",
        minWidth: 190,
        filter: false,
        valueFormatter: (params: { value: number | null }): string => {
            return formatIntDateAsString(params.value);
        },
    },
    {
        headerName: "End Date",
        colId: "endDate",
        field: "legTerminationDate",
        minWidth: 190,
        filter: false,
        valueFormatter: (params: { value: number | null }): string => {
            return formatIntDateAsString(params.value);
        },
    },
    {
        headerName: "Traded Price",
        colId: "tradedPrice",
        field: "legTradedPrice",
        minWidth: 180,
        sortable: false,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Latest Quoted Bid Price",
        colId: "lastQuotedBidPrice",
        field: "legLatestQuotedBidPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Latest Quoted Mid Price",
        colId: "lastQuotedMidPrice",
        field: "legLatestQuotedMidPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Latest Quoted Ask Price",
        colId: "lastQuotedAskPrice",
        field: "legLatestQuotedAskPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Cover Price",
        colId: "coverPrice",
        field: "legCoverPrice",
        minWidth: 180,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Traded Away",
        colId: "tradedAway",
        field: "legTradedAway",
        minWidth: 150,
    },
    {
        headerName: "Quote Competitiveness",
        field: "legQuoteCompetitiveness",
        minWidth: 235,
    },
    {
        headerName: "RFM",
        colId: "isRfm",
        field: "isRfm",
        minWidth: 110,
    },
    {
        headerName: "Trade Handle",
        colId: "tradeHandle",
        field: "tradeHandle",
        minWidth: 190,
    },
];

const onTodayFilterOptionSelected = (
    gridApi: GridApi,
    clock: EpochClock,
    todayTimeoutIdRef: React.MutableRefObject<NodeJS.Timeout | null>,
): void => {
    setDateFilterToToday(gridApi, clock);
    setTimerToRollDateFilterAtMidnight(gridApi, todayTimeoutIdRef, clock);
};

const setDateFilterToToday = (gridApi: GridApi, clock: EpochClock): void => {
    const dateFrom = dateTimeStringForMidnightToday(clock);
    const defaultFilter: BlotterFilterModel = {
        creationTime: {
            dateFrom,
            dateTo: null,
            filterType: "date",
            type: "equals",
            forToday: true,
        },
    };
    gridApi.setFilterModel(defaultFilter);
};

const setTimerToRollDateFilterAtMidnight = (
    gridApi: GridApi,
    todayTimeoutIdRef: React.MutableRefObject<NodeJS.Timeout | null>,
    clock: EpochClock,
): void => {
    clearResetTodayTimer(todayTimeoutIdRef);

    const now = DateTime.fromMillis(Number(clock.getCurrentTimeNs()) / 1_000_000);
    const tomorrowMidnight = DateTime.local(now.year, now.month, now.day).plus({ days: 1 });

    const timeTilTomorrowMs = tomorrowMidnight.toMillis() - now.toMillis();
    todayTimeoutIdRef.current = setTimeout(onTodayFilterOptionSelected, timeTilTomorrowMs, gridApi, clock, todayTimeoutIdRef);
};

const clearResetTodayTimer = (todayTimeoutIdRef: React.MutableRefObject<NodeJS.Timeout | null>): void => {
    if (todayTimeoutIdRef.current != null) {
        clearTimeout(todayTimeoutIdRef.current);
        todayTimeoutIdRef.current = null;
    }
};

const getDataPath = (row: BlotterRow): string[] => row.path;
const getRowId = ({ data }: { data: BlotterRow }): string =>
    `ticketId:${data.ticketId}-aggregateId:${data.aggregateId}-legId:${data.legPosition}`;

const gridOptions: GridOptions = {
    columnDefs: inquiryColumnDefs,
    defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        useValueFormatterForExport: false,
    },
    suppressColumnVirtualisation: true,
    treeData: true,
    animateRows: false,
    groupDefaultExpanded: 0,
    groupDisplayType: "custom",
    getDataPath: getDataPath,
    getRowId: getRowId,
};

export const ActiveBlotterPanel: React.FunctionComponent<ActiveBlotterPanelProps> = ({ clock, filteredInquiries, onReady }) => {
    const blotterRows = useMemoFlatmappedList(filteredInquiries, mapInquiryToBlotterRows);

    const [gridWrapperClassName, setGridWrapperClassName] = useState("not-ready");
    const todayTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const onGridReady = useCallback(
        (gridReadyEvent: GridReadyEvent): void => {
            onReady({
                downloadCsv: (filename) => gridReadyEvent.api?.exportDataAsCsv({ fileName: filename }),
            });

            onTodayFilterOptionSelected(gridReadyEvent.api, clock, todayTimeoutIdRef);
            setGridWrapperClassName("ready");
            saveAndRestoreColumnState(gridReadyEvent.api, LocalStorageKeys.ACTIVE_BLOTTER_COLUMN_STATE);
        },
        [clock, onReady],
    );

    const onFilterChanged = useCallback(
        (event: FilterChangedEvent): void => {
            const filterModel = event.api.getFilterModel() as BlotterFilterModel;
            const today = dateTimeStringForMidnightToday(clock);
            const filteredDate = filterModel.creationTime?.dateFrom ?? today;
            const isFilteredOnToday = filteredDate === today;
            if (event["afterFloatingFilter"] !== undefined) {
                if (isFilteredOnToday) {
                    setTimerToRollDateFilterAtMidnight(event.api, todayTimeoutIdRef, clock);
                } else {
                    clearResetTodayTimer(todayTimeoutIdRef);
                }
            }
        },
        [clock],
    );

    return (
        <Box height={"full"} data-testid={"blotter-panel-container"} className={gridWrapperClassName}>
            <AgGridTheme />
            <AgGridReact
                className={AG_GRID_CLASS_NAME}
                rowData={blotterRows}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                statusBar={{
                    statusPanels: [{ statusPanel: "agTotalAndFilteredRowCountComponent" }],
                }}
            />
        </Box>
    );
};
