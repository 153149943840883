export class SessionLiveness {
    private readonly timeSupplier: () => number;
    private readonly interval: number;
    private readonly timeoutAfterPingInterval: number;
    private intervalId: ReturnType<typeof setInterval> | undefined;
    private readonly sendPing: () => void;
    private lastMessageTimeStamp = 0;
    private readonly onTimeout: () => void;
    private livenessTimeoutTime = Number.MAX_SAFE_INTEGER;

    constructor(
        timeSupplier: () => number,
        interval: number,
        timeoutAfterPingInterval: number,
        sendPing: () => void,
        onTimeout: () => void,
    ) {
        this.timeSupplier = timeSupplier;
        this.interval = interval;
        this.sendPing = sendPing;
        this.timeoutAfterPingInterval = timeoutAfterPingInterval;
        this.onTimeout = onTimeout;
    }

    onMessage(): void {
        this.lastMessageTimeStamp = this.timeSupplier();
        this.livenessTimeoutTime = Number.MAX_SAFE_INTEGER;
    }

    startMonitoring(): void {
        this.intervalId = setInterval(() => this.checkAlive(), this.interval);
    }

    stopMonitoring(): void {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
        this.livenessTimeoutTime = Number.MAX_SAFE_INTEGER;
    }

    private checkAlive(): void {
        const now = this.timeSupplier();
        if (now > this.livenessTimeoutTime) {
            this.onTimeout();
            this.stopMonitoring();
        } else {
            if (now - this.lastMessageTimeStamp > this.interval) {
                this.sendPing();
                this.livenessTimeoutTime = Math.min(this.livenessTimeoutTime, now + this.timeoutAfterPingInterval);
            }
        }
    }
}
