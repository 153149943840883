import React, { ChangeEvent, useEffect } from "react";
import { Box, Button, IconProps, Input, RadioBar, semanticButtonStyling, Text, ThemeUtils } from "@transficc/components";

export interface OnTheWireButtonProps {
    buttonTitle: string;
    otwTimeConfigurable: boolean;
    buttonDisabled: boolean;
    userDefinedOtwTimeMs: number;
    venueRequestedOtwTimeMs: number | null;
    leftIcon?: IconProps;
    onButtonClick?: () => unknown;
    onTheWireTimeChangedMsCallback?: (otwTime: number) => void;
}

const getButtonSecondaryTextFontSize = (length: number): Theme.FontSizes => {
    switch (true) {
        case length > 18:
            return "2xs";
        case length > 8:
            return "xs";
        default:
            return "sm";
    }
};

function getBorder(disabled: boolean): ThemeUtils.BorderType {
    const color: Theme.Color = disabled ? { color: "gray", level: "600" } : { color: "white" };
    return {
        color,
        width: "0.5",
        style: "solid",
    };
}

type TimeUnit = "Min" | "Sec";
const secTimeUnit: TimeUnit = "Sec";
const minTimeUnit: TimeUnit = "Min";

function toSeconds(millis: number): number {
    return millis / 1000;
}

function toMinutes(seconds: number): number {
    return seconds / 60;
}

function calculateInitialTimeUnit(venueRequestedOtwTimeMs: number | null, userDefinedOtwTimeMs: number): TimeUnit {
    const timeInSeconds = venueRequestedOtwTimeMs ? toSeconds(venueRequestedOtwTimeMs) : toSeconds(userDefinedOtwTimeMs);

    if (timeInSeconds % 60 === 0) {
        return minTimeUnit;
    }

    return secTimeUnit;
}

export const OnTheWireButton: React.FC<OnTheWireButtonProps> = ({
    buttonTitle,
    userDefinedOtwTimeMs,
    buttonDisabled,
    otwTimeConfigurable,
    venueRequestedOtwTimeMs,
    leftIcon,
    onButtonClick,
    onTheWireTimeChangedMsCallback,
}) => {
    const timeUnits: TimeUnit[] = [secTimeUnit, minTimeUnit];
    const isComponentDisabled = buttonDisabled || !otwTimeConfigurable;
    const [buttonDisabledByTyping, setButtonDisabledByTyping] = React.useState<boolean>(false);
    const [selectedTimeValue, setSelectedTimeValue] = React.useState<TimeUnit>(
        calculateInitialTimeUnit(venueRequestedOtwTimeMs, userDefinedOtwTimeMs),
    );
    const [commitedOtwTimeMs, setCommitedOtwTimeMs] = React.useState<number>(
        venueRequestedOtwTimeMs ? venueRequestedOtwTimeMs : userDefinedOtwTimeMs,
    );
    const [dirtyOtwTimeMs, setDirtyOtwTimeMs] = React.useState<number>(commitedOtwTimeMs);

    const refOtwInput: React.RefObject<HTMLInputElement> = React.createRef();

    useEffect(() => {
        const calculated = calculateInitialTimeUnit(venueRequestedOtwTimeMs, userDefinedOtwTimeMs);
        setSelectedTimeValue(calculated);
        setDirtyOtwTimeMs(venueRequestedOtwTimeMs ? venueRequestedOtwTimeMs : userDefinedOtwTimeMs);
        setCommitedOtwTimeMs(venueRequestedOtwTimeMs ? venueRequestedOtwTimeMs : userDefinedOtwTimeMs);
    }, [userDefinedOtwTimeMs, venueRequestedOtwTimeMs]);

    function otwText(): string {
        if (buttonDisabled) {
            return "";
        }
        if (!otwTimeConfigurable && !venueRequestedOtwTimeMs) {
            return "Good for RFQ";
        }

        if (dirtyOtwTimeMs === 0) {
            return "";
        }
        return `Good for ${formatOtwTime()}`;
    }

    function roundToOneDecimalPlace(value: number): number {
        return Math.round(value * 10) / 10;
    }

    function formatOtwTime(): string {
        const timeInSecs = toSeconds(dirtyOtwTimeMs);
        if (timeInSecs < 60) {
            const secondsLabel = timeInSecs === 1 ? "sec" : "secs";
            return `${roundToOneDecimalPlace(timeInSecs).toString()} ${secondsLabel}`;
        }

        const minutes = Math.floor(timeInSecs / 60);
        const seconds = timeInSecs - minutes * 60;

        const minutesLabel = minutes === 1 && seconds === 0 ? "min" : "mins";
        return `${minutes}${formatSeconds(seconds)} ${minutesLabel}`;
    }

    function formatSeconds(seconds: number): string {
        if (seconds > 0) {
            if (seconds > 10) {
                return `:${roundToOneDecimalPlace(seconds)}`;
            }

            return `:0${roundToOneDecimalPlace(seconds)}`;
        }

        return "";
    }

    function otwDisplayForNumberInput(): string {
        if (buttonDisabled || !otwTimeConfigurable) {
            return "-";
        }
        const inSeconds = toSeconds(dirtyOtwTimeMs);
        if (selectedTimeValue === secTimeUnit) {
            return roundToOneDecimalPlace(inSeconds).toString();
        }
        return roundToOneDecimalPlace(toMinutes(inSeconds)).toString();
    }

    function onNumberInputChanged(e: ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        setButtonDisabledByTyping(true);
        if (!/^[0-9]*$/.test(e.currentTarget.value)) {
            return;
        }
        if (e.currentTarget.value.indexOf(".") !== e.currentTarget.value.lastIndexOf(".")) {
            return;
        }
        if (e.currentTarget.value.length > 4) {
            return;
        }
        setNewDirtyOtwTime(e.target.value);
    }

    function setNewDirtyOtwTime(newOtwTimeInTimeUnit: string): void {
        let newOtwTimeMs: number;
        if (selectedTimeValue === secTimeUnit) {
            newOtwTimeMs = Number(newOtwTimeInTimeUnit) * 1000;
        } else {
            newOtwTimeMs = Number(newOtwTimeInTimeUnit) * 60 * 1000;
        }
        setDirtyOtwTimeMs(newOtwTimeMs);
        updateCallback(newOtwTimeMs);
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === "Enter") {
            setButtonDisabledByTyping(false);
            if (refOtwInput.current) {
                refOtwInput.current.blur();
                setCommitedOtwTimeMs(dirtyOtwTimeMs);
            }
        }
        if (event.key === "Escape") {
            setButtonDisabledByTyping(false);
            if (refOtwInput.current) {
                refOtwInput.current.blur();
                setDirtyOtwTimeMs(commitedOtwTimeMs);
            }
        }
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>): void {
        if (!e.target.value || e.target.value === "0") {
            setNewDirtyOtwTime("1");
            setCommitedOtwTimeMs(dirtyOtwTimeMs);
        }
        setButtonDisabledByTyping(false);
    }

    function changeSelectedTimeValue(newTimeUnit: TimeUnit): void {
        function updateState(newValue: number): void {
            setDirtyOtwTimeMs(newValue);
            updateCallback(newValue);
            setSelectedTimeValue(newTimeUnit);
        }

        let newOnTheWireTime = dirtyOtwTimeMs;
        if (selectedTimeValue !== minTimeUnit && newTimeUnit === minTimeUnit) {
            newOnTheWireTime *= 60;
            updateState(newOnTheWireTime);
        } else if (selectedTimeValue !== secTimeUnit && newTimeUnit === secTimeUnit) {
            newOnTheWireTime /= 60;
            updateState(newOnTheWireTime);
        }
    }

    function updateCallback(newOnTheWireTimeMs: number): void {
        if (onTheWireTimeChangedMsCallback) {
            onTheWireTimeChangedMsCallback(newOnTheWireTimeMs);
        }
    }

    return (
        <Box contentDirection="column" data-testid={"otw-button-container"}>
            <Box contentDirection="row" margin={"1.5"} contentAlignment={"center"} contentJustification={"space-evenly"}>
                <Input
                    as="input"
                    disabled={isComponentDisabled}
                    height={"5"}
                    width={"12"}
                    fontSize={"sm"}
                    border={getBorder(isComponentDisabled)}
                    marginL="1"
                    marginR="1"
                    backgroundColor={isComponentDisabled ? { color: "gray", level: "900" } : { color: "white" }}
                    foregroundColor={{ color: "black" }}
                    padding="2"
                    type="text"
                    value={otwDisplayForNumberInput()}
                    onChange={onNumberInputChanged}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    title={"Quote Good-For Time\nSet in either Second(s) or Minutes(s)\nNo decimals, minimum value: 1"}
                    data-testid={"user-defined-otw-time"}
                    ref={refOtwInput}
                />
                <RadioBar
                    isDisabled={isComponentDisabled}
                    values={timeUnits}
                    selectedValue={selectedTimeValue}
                    onClick={(value) => {
                        changeSelectedTimeValue(value as TimeUnit);
                    }}
                    buttonHeight={"5"}
                    buttonWidth={"10"}
                    joined
                    squareButtons
                    data-testid={"otw-time-unit"}
                />
            </Box>
            <Button
                title={
                    <>
                        {buttonTitle}
                        <br />
                        <Text
                            as="span"
                            weight={"medium"}
                            size={getButtonSecondaryTextFontSize(otwText().length)}
                            data-testid={"otw-button-value-text"}
                            textColor={buttonDisabled || buttonDisabledByTyping ? { color: "gray", level: "500" } : { color: "white" }}
                        >
                            {otwText()}
                        </Text>
                    </>
                }
                leftIcon={leftIcon}
                styling={semanticButtonStyling("info")}
                size={"lg"}
                disabled={buttonDisabled || buttonDisabledByTyping}
                height="20"
                onClick={onButtonClick}
                data-testid="on-the-wire-button"
            />
        </Box>
    );
};
