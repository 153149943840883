import React from "react";
import styled from "styled-components";
import { IRSActiveInquirySummary } from "./active-inquiry-summary";
import { Box } from "@transficc/components";
import { ActiveInquiriesBottomBar, ActiveInquiriesBottomBarProps } from "@transficc/trader-desktop-shared-components";
import { useActiveIRSInquiryTicketIDs } from "../irs-slice/irs-selectors";

export interface IRSActiveInquiriesProps {
    onVolumeToggle: ActiveInquiriesBottomBarProps["onVolumeToggle"];
    volumeIsOn: ActiveInquiriesBottomBarProps["volumeIsOn"];
    connectionStatus: ActiveInquiriesBottomBarProps["connectionStatus"];
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
}

const ActiveInquiriesContainer = styled(Box)`
    width: 26rem;
    height: 100%;
`;

const ActiveInquiriesList = styled(Box)`
    padding: ${(props) => props.theme.spacing["2"]};
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ActiveInquiriesFooter = styled(Box)``;

export const IRSActiveInquiries: React.FC<IRSActiveInquiriesProps> = ({
    onVolumeToggle,
    volumeIsOn,
    connectionStatus,
    onTradingStatusChanged,
    tradingStatus,
    serverTradingStatus,
}) => {
    const activeInquiryTicketIDs = useActiveIRSInquiryTicketIDs();

    return (
        <ActiveInquiriesContainer data-testid={"dock-active-inquiries-container"}>
            <ActiveInquiriesList>
                <div>
                    {activeInquiryTicketIDs.map((ticketId) => (
                        <IRSActiveInquirySummary key={ticketId} ticketId={ticketId} />
                    ))}
                </div>
            </ActiveInquiriesList>
            <ActiveInquiriesFooter>
                <ActiveInquiriesBottomBar
                    volumeIsOn={volumeIsOn}
                    onVolumeToggle={onVolumeToggle}
                    connectionStatus={connectionStatus}
                    onTradingStatusChanged={onTradingStatusChanged}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                />
            </ActiveInquiriesFooter>
        </ActiveInquiriesContainer>
    );
};
