import { DisconnectionReason } from "@transficc/websocket";

export enum WorkerMessageTypes {
    HEARTBEAT = "Heartbeat",
    CONNECT_SOCKET = "ConnectSocket",
    CLOSE_SOCKET = "CloseSocket",
    SESSION_OPENED_EVENT = "SessionOpenedEvent",
    SESSION_CLOSED_EVENT = "SessionClosedEvent",
    OUTBOUND_MESSAGE_ENVELOPE = "OutboundMessageEnvelope",
    INBOUND_MESSAGE_ENVELOPE = "InboundMessageEnvelope",
}

export interface HeartbeatMessage {
    msgType: WorkerMessageTypes.HEARTBEAT;
}

export interface ConnectSocketMessage {
    msgType: WorkerMessageTypes.CONNECT_SOCKET;

    url: string;
    idToken: string | null;
    accessToken: string;
    appInstanceId: string;
}

export interface OutboundMessageEnvelope {
    msgType: WorkerMessageTypes.OUTBOUND_MESSAGE_ENVELOPE;

    payload: string;
}

export interface InboundMessageEnvelope {
    msgType: WorkerMessageTypes.INBOUND_MESSAGE_ENVELOPE;

    payload: string;
}

export interface SessionOpenedEvent {
    msgType: WorkerMessageTypes.SESSION_OPENED_EVENT;
}

export interface SessionClosedEvent {
    msgType: WorkerMessageTypes.SESSION_CLOSED_EVENT;

    reason: DisconnectionReason;
    description: string | null;
}

export interface CloseSocketMessage {
    msgType: WorkerMessageTypes.CLOSE_SOCKET;
}

export type WorkerMessage =
    | HeartbeatMessage
    | ConnectSocketMessage
    | OutboundMessageEnvelope
    | InboundMessageEnvelope
    | SessionOpenedEvent
    | SessionClosedEvent
    | CloseSocketMessage;

const constructWorkerMessage = <T>(params: T): T => ({ ...params });

export const heartbeatMessage = (): HeartbeatMessage => constructWorkerMessage({ msgType: WorkerMessageTypes.HEARTBEAT });

export const connectSocketMessage = (
    url: string,
    accessToken: string,
    idToken: string | null,
    appInstanceId: string,
): ConnectSocketMessage =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.CONNECT_SOCKET,
        url,
        accessToken,
        idToken,
        appInstanceId: appInstanceId,
    });

export const outboundMessageEnvelope = (payload: string): OutboundMessageEnvelope =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.OUTBOUND_MESSAGE_ENVELOPE,
        payload,
    });

export const inboundMessageEnvelope = (payload: string): InboundMessageEnvelope =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.INBOUND_MESSAGE_ENVELOPE,
        payload,
    });

export const sessionOpenedEvent = (): SessionOpenedEvent =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.SESSION_OPENED_EVENT,
    });

export const sessionClosedEvent = (reason: DisconnectionReason, description: string | null): SessionClosedEvent =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.SESSION_CLOSED_EVENT,
        reason,
        description,
    });

export const closeSocketMessage = (): CloseSocketMessage =>
    constructWorkerMessage({
        msgType: WorkerMessageTypes.CLOSE_SOCKET,
    });
