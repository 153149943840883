import { DisconnectionReason, SessionLiveness, WebSocketSessionCloseCodes } from "@transficc/websocket";
import { ServerMessage, sessionOpenRequest, sessionPingRequest } from "./SessionProtocolMessages";
import { Logger } from "@transficc/trader-desktop-application-context";

export class TraderDesktopWebSocketSession {
    private static readonly INTERVAL = 5000;
    private static readonly TIMEOUT_AFTER_PING_INTERVAL = 15000;

    private readonly sessionLiveness: SessionLiveness;
    private readonly log: Logger;
    private reconnectionHandler: SessionReconnectionHandler | null = null;

    private webSocket: WebSocket | null = null;

    private onMessageHandler: ((message: ServerMessage) => void) | null = null;
    private onOpenHandler: (() => void) | null = null;
    private onCloseHandler: ((reason: DisconnectionReason, description: string | null) => void) | null = null;

    private status: "disconnected" | "connected" = "disconnected";

    constructor(log: Logger) {
        this.log = log;
        this.sessionLiveness = new SessionLiveness(
            () => Date.now(),
            TraderDesktopWebSocketSession.INTERVAL,
            TraderDesktopWebSocketSession.TIMEOUT_AFTER_PING_INTERVAL,
            () => this.sendServerMessage(sessionPingRequest()),
            () => this.doOnTimeout(),
        );
    }

    public connectSession(url: string, accessToken: string): void {
        if (this.webSocket !== null && this.webSocket.readyState !== WebSocket.CLOSED) {
            this.log.info(`Already connected not doing nothing with the websocket ${url}`);
            return;
        }

        this.log.info(`Creating new socket ${url}`);

        this.createNewWebsocket(url, accessToken);

        this.reconnectionHandler = new SessionReconnectionHandler(this.log, () => {
            this.createNewWebsocket(url, accessToken);
        });
    }

    public disconnectSession(code: number, reason: string): void {
        this.reconnectionHandler?.stopAttemptingReconnection();
        this.reconnectionHandler = null;
        this.webSocket?.close(code, reason);
        this.cleanUpWebsocketAndStopMonitoring();
        this.status = "disconnected";
    }

    public send(message: string): boolean {
        if (this.webSocket !== null && this.webSocket.readyState === WebSocket.OPEN) {
            this.webSocket.send(message);
            return true;
        }

        return false;
    }

    public onMessage(handler: (message: ServerMessage) => void): void {
        this.onMessageHandler = handler;
    }

    public onOpen(handler: () => void): void {
        this.onOpenHandler = handler;
    }

    public onClose(handler: (reason: DisconnectionReason, description: string | null) => void): void {
        this.onCloseHandler = handler;
    }

    public isConnected(): boolean {
        return this.status === "connected";
    }

    private sendServerMessage(message: ServerMessage): boolean {
        return this.send(JSON.stringify(message));
    }

    private createNewWebsocket(url: string, token: string): void {
        this.webSocket = new WebSocket(url);
        this.webSocket.onopen = (): void => {
            this.status = "connected";
            this.reconnectionHandler?.stopAttemptingReconnection();
            this.sendServerMessage(sessionOpenRequest(token));
        };
        this.webSocket.onerror = (): void => {
            this.handleDeadWebsocket(WebSocketSessionCloseCodes.UNKNOWN_ERROR);
        };
        this.webSocket.onclose = ({ code }): void => {
            this.handleDeadWebsocket(code);
        };
        this.webSocket.onmessage = (event): void => {
            this.doOnMessage(event);
        };
    }

    private handleDeadWebsocket(code: number): void {
        if (this.status === "connected") {
            this.onCloseHandler?.(this.websocketCloseCodeToDisconnectionReason(code), null);
            this.reconnectionHandler?.startAttemptingReconnection();
        }

        this.cleanUpWebsocketAndStopMonitoring();
        this.status = "disconnected";
    }

    private doOnMessage(ev: MessageEvent<unknown>): void {
        this.sessionLiveness.onMessage();
        const stringMessage = ev.data as string;
        const jsonMessage = JSON.parse(stringMessage) as ServerMessage;
        switch (jsonMessage.msgType) {
            case "SessionOpenResponse": {
                this.sessionLiveness.startMonitoring();
                this.onOpenHandler?.();
                break;
            }
            case "SessionClose": {
                if (jsonMessage.reason === "AUTHENTICATION_FAILURE") {
                    this.onCloseHandler?.(DisconnectionReason.AUTHENTICATION_FAILURE, jsonMessage.reason);
                }
                if (jsonMessage.reason === "ERROR") {
                    this.onCloseHandler?.(DisconnectionReason.AUTHENTICATION_FAILURE, jsonMessage.reason);
                } else {
                    this.onCloseHandler?.(DisconnectionReason.NO_REASON, jsonMessage.reason);
                }
                break;
            }
            case "SessionPongResponse": {
                break;
            }
            default: {
                this.onMessageHandler?.(jsonMessage);
            }
        }
    }

    private doOnTimeout(): void {
        if (!this.webSocket) {
            return;
        }

        const code = WebSocketSessionCloseCodes.SESSION_PING_TIMEOUT;
        const reason = `Session Ping Timeout after ${TraderDesktopWebSocketSession.TIMEOUT_AFTER_PING_INTERVAL}`;

        /**
         * This will purposefully leak the current websocket object after clearing all event handlers.
         *
         * We do this because `websocket.close` waits for the internal message buffer to be flushed before sending
         * the start of the closing handshake (see RFC), only timing out after one full minute. This is too slow for us,
         * so we leak the object under the assumption it will close eventually, and we create a new object in its place.
         */

        this.webSocket.close(code, reason);
        this.handleDeadWebsocket(code);
    }

    private cleanUpWebsocketAndStopMonitoring(): void {
        this.sessionLiveness.stopMonitoring();
        if (this.webSocket !== null) {
            this.webSocket.onclose = null;
            this.webSocket.onopen = null;
            this.webSocket.onerror = null;
            this.webSocket.onmessage = null;
        }
        this.webSocket = null;
    }

    private websocketCloseCodeToDisconnectionReason(code?: number): DisconnectionReason {
        switch (code) {
            case WebSocketSessionCloseCodes.ABNORMAL_CLOSURE:
            case WebSocketSessionCloseCodes.TRY_AGAIN_LATER:
            case WebSocketSessionCloseCodes.SESSION_PING_TIMEOUT:
                return DisconnectionReason.NETWORK_FAILURE;
            default:
                return DisconnectionReason.NO_REASON;
        }
    }
}

class SessionReconnectionHandler {
    private readonly connect: () => void;
    private readonly log: Logger;
    private timer: ReturnType<typeof setInterval> | null = null;

    constructor(log: Logger, connect: () => void) {
        this.connect = connect;
        this.log = log;
    }

    public startAttemptingReconnection(): void {
        this.clearTimer();

        const timeoutMs = 5000;

        const callback = (): void => {
            this.log.warn("Attempting to re-connect");
            this.connect();

            this.timer = setTimeout(callback, timeoutMs);

            this.log.warn(`Next re-connection attempt will be ${timeoutMs}ms from now`);
        };

        this.timer = setTimeout(callback, timeoutMs);
    }

    public stopAttemptingReconnection(): void {
        this.clearTimer();
    }

    private clearTimer(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}
