import { InquirySide, InquiryState } from "./enums";
import { DecimalNumber } from "@transficc/infrastructure";

export interface ColorByState {
    stateBarBackgroundColor: Theme.Color;
    stateBarForegroundColor: Theme.Color;
    wholeInquiryBackgroundColor: Theme.Color;
    wholeInquiryBackgroundShouldFlash: boolean;
    inquiryLegPaymentInformationBackgroundColor: Theme.Color;
    inquiryLegBodyBackgroundColor: Theme.Color;
}

export const colorByState = (state: InquiryState): ColorByState => ({
    stateBarBackgroundColor: getStateBarBackgroundColor(state),
    stateBarForegroundColor: getStateBarForegroundColor(state),
    wholeInquiryBackgroundColor: getWholeInquiryBackgroundColor(state),
    wholeInquiryBackgroundShouldFlash: getWholeInquiryBackgroundShouldFlash(state),
    inquiryLegPaymentInformationBackgroundColor: getInquiryLegPaymentInformationBackgroundColor(state),
    inquiryLegBodyBackgroundColor: getInquiryLegBodyBackgroundColor(state),
});

export const payColor: Theme.Color = { color: "rose", level: "200" };
export const rcvColor: Theme.Color = { color: "cyan", level: "200" };
export const rfmColor: Theme.Color = { color: "custom", custom: "#f5d19b" };

export const colorForSide = (side: InquirySide): Theme.Color => {
    switch (side) {
        case InquirySide.Buy:
            return payColor;
        case InquirySide.Sell:
            return rcvColor;
        case InquirySide.Undisclosed:
            return rfmColor;
        default:
            side satisfies never;
            throw new Error();
    }
};

export const colorForFee = (fee: DecimalNumber): { foreground: Theme.Color; background: Theme.Color } | null => {
    if (fee.isLessThan(0)) {
        return { foreground: { color: "black" }, background: colorForSide(InquirySide.Buy) };
    } else if (fee.isGreaterThan(0)) {
        return { foreground: { color: "black" }, background: colorForSide(InquirySide.Sell) };
    }
    return null;
};

const getStateBarBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.NewRequest:
            return { color: "warning", level: "400" };
        case InquiryState.Streaming:
        case InquiryState.QuoteRefreshRequested:
            return { color: "info", level: "500" };
        case InquiryState.LastLook:
            return { color: "rose", level: "600" };
        case InquiryState.Done:
            return { color: "success", level: "500" };
        case InquiryState.TradedPendingFeeAllocation:
            return { color: "success", level: "200" };
        default:
            return { color: "gray", level: "500" };
    }
};

const getStateBarForegroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.NewRequest:
        case InquiryState.TradedPendingFeeAllocation:
            return { color: "black" };
        default:
            return { color: "white" };
    }
};

const getWholeInquiryBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.LastLook:
            return { color: "rose", level: "900" };
        case InquiryState.Done:
        case InquiryState.InquiryError:
        case InquiryState.CustomerReject:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerReject:
        case InquiryState.DealerTimeout:
            return { color: "gray", level: "900" };
        default:
            return { color: "transparent" };
    }
};

const getWholeInquiryBackgroundShouldFlash = (state: InquiryState): boolean => {
    switch (state) {
        case InquiryState.LastLook:
            return true;
        default:
            return false;
    }
};

const getInquiryLegPaymentInformationBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.Done:
        case InquiryState.InquiryError:
        case InquiryState.CustomerReject:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerReject:
        case InquiryState.DealerTimeout:
            return { color: "gray", level: "800" };
        default:
            return { color: "cyan", level: "800" };
    }
};

const getInquiryLegBodyBackgroundColor = (state: InquiryState): Theme.Color => {
    switch (state) {
        case InquiryState.Done:
        case InquiryState.InquiryError:
        case InquiryState.CustomerReject:
        case InquiryState.CustomerTimeout:
        case InquiryState.DealerReject:
        case InquiryState.DealerTimeout:
            return { color: "gray", level: "800" };
        default:
            return { color: "cyan", level: "800" };
    }
};
