import { createGlobalStyle } from "styled-components";
import { AgPublicEventType, ColumnState, GridApi } from "ag-grid-community";
import {
    getItemFromLocalStorage,
    LocalStorageKeys,
    removeItemFromLocalStorage,
    setItemInLocalStorage,
} from "@transficc/trader-desktop-local-storage";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

export const AG_GRID_LICENSE =
    "Using_this_{AG_Grid}_Enterprise_key_{AG-065163}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TransFICC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TransFICC}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TransFICC}_need_to_be_licensed___{TransFICC}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_May_2025}____[v3]_[01]_MTc0NjU3MjQwMDAwMA==c48a687ae553f9713e3f5bcdcf73dfa1";
export const AG_GRID_CLASS_NAME = "ag-theme-alpine-dark ag-theme-trader-desktop";
export const AgGridTheme = createGlobalStyle`
    .ag-theme-trader-desktop {
        --ag-background-color: ${(props) => props.theme.colors.cyan["900"]};
        --ag-header-background-color: ${(props) => props.theme.colors.gray["900"]};
        --ag-odd-row-background-color: ${(props) => props.theme.colors.info["800"]};
        --ag-row-hover-color: ${(props) => props.theme.colors.gray["800"]};
        --ag-font-family: ${(props) => props.theme.font.family[props.theme.font.defaultFontFamily]};
        font-weight: 400;
    }

    .ag-theme-trader-desktop .ag-filter-toolpanel-header,
    .ag-theme-trader-desktop .ag-filter-toolpanel-search,
    .ag-theme-trader-desktop .ag-status-bar,
    .ag-theme-trader-desktop .ag-header-row,
    .ag-theme-trader-desktop .ag-panel-title-bar-title,
    .ag-theme-trader-desktop .ag-multi-filter-group-title-bar, .ag-theme-alpine-dark .ag-filter-toolpanel-header {
        font-weight: 400;
    }

    .ag-theme-trader-desktop .ag-header-cell,
    .ag-theme-trader-desktop .ag-header-group-cell {
        padding-left: 12px;
        padding-right: 12px;
    }
`;

export const NO_VALUE = "-";
export const mapBoolean: (value: boolean | null) => string = (value) => {
    if (value != null) {
        return value ? "YES" : "NO";
    } else {
        return NO_VALUE;
    }
};

function clearLocalStorageAndResetColumnState(localStorageKey: LocalStorageKeys, api: GridApi): void {
    removeItemFromLocalStorage(localStorageKey);
    api.resetColumnState();
}

function saveColumnStateToLocalStorage(key: LocalStorageKeys, api: GridApi): void {
    setItemInLocalStorage(key, JSON.stringify(api.getColumnState()));
}

function applyFilterState(api: GridApi, filterStateKey: LocalStorageKeys): void {
    try {
        const maybeFilterState = getItemFromLocalStorage(filterStateKey);
        if (maybeFilterState !== null) {
            const filterState = JSON.parse(maybeFilterState) as [key: string];
            api.setFilterModel(filterState);
        }
    } catch (e) {
        removeItemFromLocalStorage(filterStateKey);
        api.setFilterModel(null);
    } finally {
        api.addEventListener("filterChanged", (event) => setItemInLocalStorage(filterStateKey, JSON.stringify(event.api.getFilterModel())));
    }
}

function applyColumnState(api: GridApi, columnStateKey: LocalStorageKeys): void {
    try {
        const mayBeColumnDefs = getItemFromLocalStorage(columnStateKey);
        if (mayBeColumnDefs !== null) {
            const columnState = JSON.parse(mayBeColumnDefs) as ColumnState[];
            const wasAbleToApplyColumnState = api.applyColumnState({ state: columnState, applyOrder: true });
            if (!wasAbleToApplyColumnState) {
                clearLocalStorageAndResetColumnState(columnStateKey, api);
            }
        }
    } catch (e) {
        clearLocalStorageAndResetColumnState(columnStateKey, api);
    } finally {
        ["columnMoved", "columnVisible", "sortChanged"].forEach((eventName) =>
            api.addEventListener(eventName as AgPublicEventType, (event) => saveColumnStateToLocalStorage(columnStateKey, event.api)),
        );
    }
}

export function saveAndRestoreColumnState(api: GridApi, columnStateKey: LocalStorageKeys, filterStateKey?: LocalStorageKeys): void {
    applyColumnState(api, columnStateKey);

    if (filterStateKey) {
        applyFilterState(api, filterStateKey);
    }
}
