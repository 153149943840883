import { Box, Button } from "../../atoms";
import * as React from "react";
import { ButtonSize, ButtonStyling, semanticButtonStyling } from "../../atoms/button/button";

export interface RadioBarProps {
    values: string[];
    styling?: ButtonStyling[];
    selectedValue: string | null;
    onClick: (value: string, index: number) => void;
    buttonWidth?: Theme.SpacingLevels;
    buttonHeight?: Theme.SpacingLevels;
    isDisabled?: boolean;
    buttonSize?: ButtonSize;
    joined?: boolean;
    squareButtons?: boolean;
}

export const RadioBar: React.FC<RadioBarProps> = ({
    values,
    styling,
    selectedValue,
    onClick,
    buttonWidth,
    buttonHeight,
    isDisabled,
    buttonSize,
    joined,
    squareButtons,
    ...props
}) => {
    return (
        <Box contentDirection="row" {...props}>
            {values.map((value, index) => {
                const isFirst = index === 0;
                const isLast = index === values.length - 1;

                const flatOn = joined ? (isFirst ? "right" : isLast ? "left" : "both") : undefined;

                const buttonStyle = styling?.[index] ?? semanticButtonStyling("info");
                const isSelected = value === selectedValue;

                return (
                    <Box key={value} margin={joined ? "0" : "0.5"}>
                        <Button
                            title={value}
                            selected={isSelected}
                            size={buttonSize ?? "sm"}
                            styling={buttonStyle}
                            onClick={() => onClick(value, index)}
                            width={buttonWidth}
                            height={buttonHeight}
                            disabled={isDisabled}
                            flatOn={squareButtons ? "both" : flatOn}
                            data-is-selected={isSelected}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};
