import { EpochClock } from "./EpochClock";

export class SystemEpochClock implements EpochClock {
    getCurrentTimeNs(): number {
        return this.getCurrentTimeMs() * 1_000_000;
    }

    getCurrentTimeMs(): number {
        return Date.now();
    }

    getCurrentTimeNsStr(): string {
        return String(this.getCurrentTimeMs()) + "000000";
    }
}
