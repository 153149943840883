import * as React from "react";
import { useCallback, useRef, useState } from "react";

import { HistoricalBlotterPanel } from "./historical-blotter-panel";
import { HistoricalBlotterController, UserGroup } from "./historical-blotter-controller";
import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { TimezoneProvider } from "@transficc/infrastructure";
import { DateTime } from "luxon";
import { HDSProtocol } from "@transficc/hds-public-protocol-types";
import { Box, formatIntDateAsString, venueText } from "@transficc/components";
import { mapBoolean, NO_VALUE } from "@transficc/trader-desktop-ag-grid-common";
import { LocalStorageKeys, removeItemFromLocalStorage } from "@transficc/trader-desktop-local-storage";

export interface HdsBlotterProps {
    hdsGatewayUrl: string;
    accessToken: string;
    timezoneProvider: TimezoneProvider;
    userGroups: UserGroup[];
}

export interface BlotterRow {
    path: string[];
    ticketId: number;
    snapshotCreationTimestampNanos: string;
    state: string;
    firmId: string;
    venueName: string;
    venueType: string | null;
    traderName: string;
    salespersonName: string;
    salespersonId: string;
    dealerFullName: string;
    venueId: string;
    autoMode: string;
    legPosition: string;
    legSide: string;
    legInstrumentName: string;
    legQuantity: string | null;
    legPriceType: string;
    legTradedAway: string;
    legQuoteCompetitiveness: string;
    legCoverPrice: string;
    legTradedPrice: string;
    legFixedRate: string;
    legIsRfm: string;
    legMinPriceIncrement: string;
    legLatestQuotedBidPrice: string;
    legLatestQuotedMidPrice: string;
    legLatestQuotedAskPrice: string;
    customTradeHandle: string;
    legSwapEffectiveDate: string;
    legSwapEndDate: string;
    spotNegotiationModel: string;
    spotTime: string;
    hedgingType: string;
    curtainTimeMillis: string | null;
    numberOfListItems: string;
    listId: string;
    legInstrumentId: string;
    legInstrumentIdType: string;
    legBenchmarkName: string;
    legBenchmarkId: string;
    legBenchmarkIdType: string;
    legBenchmarkQuantity: string | null;
    legSettlementDate: number | null;
    legRelativeSettlementDate: string | null;
    legCurrency: string;
    legBenchmarkPrice: string;
    legCashPrice: string;
    priceTier: string;
    numberOfDealers: number | null;
    isAllToAll: string | null;
    isCounterpartyAnonymous: string | null;
    counterpartyRank: string | null;
    counterpartyType: string | null;
    tradedLevel: string | null;
    tradedLevelType: string | null;
    inquiryCreationTime: string | null;
    benchmarkHedge: string | null;
}

const getCounterpartyRankDisplayText = (counterpartyRank: number | null): string => {
    if (counterpartyRank === null || counterpartyRank < 1) {
        return NO_VALUE;
    } else {
        return counterpartyRank.toString();
    }
};

const getSpottingTimeDisplayText = (
    timezoneProvider: TimezoneProvider,
    spotNegotiationModel: HDSProtocol.SpotNegotiationModel | null,
    spotRequestTimeNanos: string | null,
): string => {
    if (!spotNegotiationModel) {
        return "-";
    }
    switch (spotNegotiationModel) {
        case HDSProtocol.SpotNegotiationModel.Venuepriced:
            return "Immediate";
        case HDSProtocol.SpotNegotiationModel.VenuepricedDelayed: {
            if (!spotRequestTimeNanos) {
                return "Later";
            }
            const spotRequestTimeSeconds = spotRequestTimeNanos.slice(0, spotRequestTimeNanos.length - 9);
            const timestampSeconds = DateTime.fromSeconds(parseInt(spotRequestTimeSeconds), { zone: "UTC" })
                .setZone(timezoneProvider.getTimezone())
                .toFormat("h:mm a");
            return `${timestampSeconds}`;
        }
        default:
            throw new Error("Unsupported spot negotiation model");
    }
};

const mapSpotNegotiationModel = (spotNegotiationModel: HDSProtocol.SpotNegotiationModel | null): string => {
    switch (spotNegotiationModel) {
        case HDSProtocol.SpotNegotiationModel.Venuepriced:
        case HDSProtocol.SpotNegotiationModel.VenuepricedDelayed:
            return "Auto";
        default:
            return NO_VALUE;
    }
};

const mapInstrumentIdType = (instrumentIdType: HDSProtocol.InstrumentIDType | null): string => {
    switch (instrumentIdType) {
        case HDSProtocol.InstrumentIDType.Isin:
            return "ISIN";
        case HDSProtocol.InstrumentIDType.Cusip:
            return "CUSIP";
        case HDSProtocol.InstrumentIDType.VenueSymbol:
            return "Venue Symbol";
        default:
            return NO_VALUE;
    }
};

const mapPriceType = (priceType: HDSProtocol.PriceType | null): string => {
    switch (priceType) {
        case null:
            return NO_VALUE;
        case HDSProtocol.PriceType.PercentOfPar:
            return "Price";
        case HDSProtocol.PriceType.NetPresentValue:
            return "Net Present Value";
        case HDSProtocol.PriceType.InterestRate:
            return "Interest Rate";
        case HDSProtocol.PriceType.Spread:
            return "Spread";
    }
};

const mapQuoteCompetitiveness = (quoteCompetitiveness: HDSProtocol.QuoteCompetitiveStatus | null): string => {
    switch (quoteCompetitiveness) {
        case null:
            return NO_VALUE;
        case HDSProtocol.QuoteCompetitiveStatus.Best:
            return "Best";
        case HDSProtocol.QuoteCompetitiveStatus.Cover:
            return "Cover";
        case HDSProtocol.QuoteCompetitiveStatus.CoverTied:
            return "Cover Tied";
        case HDSProtocol.QuoteCompetitiveStatus.Tied:
            return "Tied";
    }
};

const getCounterpartyTypeDisplayText = (counterpartyType: HDSProtocol.CounterpartyType | null): string => {
    switch (counterpartyType) {
        case null:
            return NO_VALUE;
        case HDSProtocol.CounterpartyType.Customer:
            return "Customer";
        case HDSProtocol.CounterpartyType.Dealer:
            return "Dealer";
    }
};

const getHedgingTypeDisplayText = (hedgingType: HDSProtocol.HedgingType | null): string => {
    switch (hedgingType) {
        case null:
            return NO_VALUE;
        case HDSProtocol.HedgingType.Cross:
            return "Cross";
        case HDSProtocol.HedgingType.Spot:
            return "Spot";
    }
};

const mapOutrightInquiryResponseToInquiryRow = (inquiry: HDSProtocol.Inquiry, timezoneProvider: TimezoneProvider): BlotterRow => {
    if (inquiry.legs[0]) {
        return {
            path: [`${inquiry.ticketId}`],
            ticketId: inquiry.ticketId,
            snapshotCreationTimestampNanos: inquiry.snapshotCreationTimestampNanos,
            state: removeUnderScores(inquiry.state),
            firmId: inquiry.firmId ?? NO_VALUE,
            traderName: inquiry.traderName ?? NO_VALUE,
            salespersonName: inquiry.salesPersonName ?? NO_VALUE,
            salespersonId: inquiry.salesPersonId ?? NO_VALUE,
            dealerFullName: inquiry.dealerFullName ?? NO_VALUE,
            venueName: venueText(inquiry.venueName),
            venueType: formatVenueType(inquiry.venueType),
            venueId: inquiry.aggregateId,
            autoMode: formatAutoMode(inquiry.autoMode),
            legPosition: inquiry.legs[0].position.toString(),
            legSide: legSide(inquiry),
            legInstrumentName: instrumentName(inquiry),
            legQuantity: inquiry.legs[0].quantity,
            legPriceType: mapPriceType(inquiry.legs[0].priceType),
            legTradedAway: mapBoolean(inquiry.legs[0].tradedAway),
            legQuoteCompetitiveness: mapQuoteCompetitiveness(inquiry.legs[0].quoteCompetitiveStatus),
            legCoverPrice: inquiry.legs[0].coverPrice ?? NO_VALUE,
            legTradedPrice: inquiry.legs[0].tradedPrice ?? NO_VALUE,
            legFixedRate: inquiry.legs[0].fixedRate ?? NO_VALUE,
            legIsRfm: mapBoolean(inquiry.isRFM),
            legMinPriceIncrement: inquiry.legs[0].minPriceIncrement ?? "",
            legLatestQuotedBidPrice: inquiry.legs[0].latestQuotedBidPrice ?? NO_VALUE,
            legLatestQuotedMidPrice: inquiry.legs[0].latestQuotedMidPrice ?? NO_VALUE,
            legLatestQuotedAskPrice: inquiry.legs[0].latestQuotedAskPrice ?? NO_VALUE,
            customTradeHandle: inquiry.customTradeHandle ?? NO_VALUE,
            legSwapEffectiveDate: formatIntDateAsString(inquiry.legs[0].swapEffectiveDate),
            legSwapEndDate: formatIntDateAsString(inquiry.legs[0].swapEndDate),
            spotNegotiationModel: mapSpotNegotiationModel(inquiry.spotNegotiationModel),
            spotTime: getSpottingTimeDisplayText(timezoneProvider, inquiry.spotNegotiationModel, inquiry.spotRequestTimeNanos),
            hedgingType: getHedgingTypeDisplayText(inquiry.hedgingType),
            curtainTimeMillis: inquiry.curtainTimePeriodMillis,
            numberOfListItems: inquiry.numberOfListItems?.toString() ?? NO_VALUE,
            listId: inquiry.listId ?? NO_VALUE,
            legInstrumentId: inquiry.legs[0].instrumentId ?? NO_VALUE,
            legInstrumentIdType: mapInstrumentIdType(inquiry.legs[0].instrumentIdType),
            legBenchmarkName: inquiry.legs[0].benchmarkName ?? NO_VALUE,
            legBenchmarkId: inquiry.legs[0].benchmarkInstrumentId ?? NO_VALUE,
            legBenchmarkIdType: mapInstrumentIdType(inquiry.legs[0].benchmarkInstrumentIdType),
            legBenchmarkQuantity: inquiry.legs[0].benchmarkQuantity,
            legSettlementDate: inquiry.legs[0].settlementDate,
            legRelativeSettlementDate: inquiry.legs[0].relativeSettlementDate,
            legCurrency: inquiry.legs[0].currency ?? NO_VALUE,
            legBenchmarkPrice: inquiry.legs[0].benchmarkPrice ?? NO_VALUE,
            legCashPrice: inquiry.legs[0].cashPrice ?? NO_VALUE,
            priceTier: inquiry.priceTier?.toString() ?? NO_VALUE,
            numberOfDealers: inquiry.numberOfDealers,
            isAllToAll: mapBoolean(inquiry.isAllToAll),
            isCounterpartyAnonymous: mapBoolean(inquiry.isCounterpartyAnonymous),
            counterpartyRank: getCounterpartyRankDisplayText(inquiry.counterpartyRank),
            counterpartyType: getCounterpartyTypeDisplayText(inquiry.counterpartyType),
            tradedLevel: inquiry.legs[0].tradedLevel ?? NO_VALUE,
            tradedLevelType: inquiry.legs[0].tradedLevelType ?? NO_VALUE,
            inquiryCreationTime: inquiry.inquiryCreationTimestampNanos,
            benchmarkHedge: mapBenchmarkHedge(inquiry.benchmarkHedge),
        };
    } else {
        throw new Error("Got an inquiry with no legs");
    }
};

const mapBenchmarkHedge = (value: HDSProtocol.BenchmarkHedge | null): string => {
    switch (value) {
        case HDSProtocol.BenchmarkHedge.Auto:
            return "Auto Hedge";
        case HDSProtocol.BenchmarkHedge.Manual:
            return "Manual Hedge";
        default:
            return NO_VALUE;
    }
};

const formatAutoMode = (value: HDSProtocol.AutoXMode | null): string => {
    if (value === null) {
        return NO_VALUE;
    }
    return value.toString().toUpperCase().replace(/_/g, " ");
};

const removeUnderScores = (value: HDSProtocol.State): string => {
    return value.toString().toUpperCase().replace(/_/g, " ");
};

const formatVenueType = (value: HDSProtocol.VenueType | null): string => {
    if (value === null) {
        return "-";
    } else {
        return value.toString().toUpperCase().replace(/_/g, " ");
    }
};

const instrumentName: (inquiry: HDSProtocol.Inquiry) => string = (inquiry) => {
    if (inquiry.packageType === HDSProtocol.PackageType.Curve && inquiry.legs[0]?.instrumentName && inquiry.legs[1]?.instrumentName) {
        return `${inquiry.legs[0].instrumentName} / ${inquiry.legs[1].instrumentName}`;
    } else if (inquiry.packageType === HDSProtocol.PackageType.Compression) {
        return `List - ${inquiry.legs.length} Items`;
    }
    return inquiry.legs[0]?.instrumentName ?? NO_VALUE;
};

const multiLegSummaryQuantity: (inquiry: HDSProtocol.Inquiry) => string | null = (inquiry) => {
    // If any of the legs have bad data, prefer to not display the unknown value to not give the false impression that the quantity is known
    for (const item of inquiry.legs) {
        if (item?.quantity === null) {
            return null;
        }
    }
    if (inquiry.packageType === HDSProtocol.PackageType.Curve && inquiry.legs[1]?.quantity) {
        return inquiry.legs[1].quantity;
    } else if (inquiry.packageType === HDSProtocol.PackageType.Compression) {
        const totalQuantity = inquiry.legs
            .map((leg) => leg.quantity)
            .map((quantity) => (quantity ? Number(quantity) : 0))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        return `${totalQuantity}`;
    } else {
        throw new Error(`Unknown multileg package type: ${inquiry.packageType}`);
    }
};

const legSide: (inquiry: HDSProtocol.Inquiry) => string = (inquiry) => {
    if (inquiry.packageType === HDSProtocol.PackageType.Curve && inquiry.legs[1]) {
        return inquiry.legs[1].side?.toString().toUpperCase() ?? NO_VALUE;
    } else if (inquiry.packageType === HDSProtocol.PackageType.Compression) {
        return "As Defined";
    }
    return inquiry.legs[0]?.side ?? NO_VALUE;
};

const legSettlementDate: (inquiry: HDSProtocol.Inquiry) => number | null = (inquiry) => {
    if (inquiry.packageType === HDSProtocol.PackageType.Compression) {
        return inquiry.legs[0]?.settlementDate ?? null;
    }
    if (inquiry.packageType === HDSProtocol.PackageType.Curve) {
        return inquiry.legs.find((leg) => leg.position === 1)?.settlementDate ?? null;
    }
    return null;
};

const legRelativeSettlementDate: (inquiry: HDSProtocol.Inquiry) => string | null = (inquiry) => {
    if (inquiry.packageType === HDSProtocol.PackageType.Compression) {
        return inquiry.legs[0]?.relativeSettlementDate ?? null;
    }
    if (inquiry.packageType === HDSProtocol.PackageType.Curve) {
        return inquiry.legs.find((leg) => leg.position === 1)?.relativeSettlementDate ?? null;
    }
    return null;
};

const mapMultiLegToExpandableInquiryRow: (inquiry: HDSProtocol.Inquiry) => BlotterRow = (inquiry) => {
    return {
        path: [`${inquiry.ticketId}`],
        ticketId: inquiry.ticketId,
        snapshotCreationTimestampNanos: inquiry.snapshotCreationTimestampNanos,
        state: removeUnderScores(inquiry.state),
        firmId: inquiry.firmId ?? NO_VALUE,
        traderName: inquiry.traderName ?? NO_VALUE,
        salespersonName: inquiry.salesPersonName ?? NO_VALUE,
        salespersonId: inquiry.salesPersonId ?? NO_VALUE,
        dealerFullName: inquiry.dealerFullName ?? NO_VALUE,
        venueName: venueText(inquiry.venueName),
        venueType: formatVenueType(inquiry.venueType),
        venueId: inquiry.aggregateId,
        autoMode: formatAutoMode(inquiry.autoMode),
        legPosition: NO_VALUE,
        legSide: legSide(inquiry),
        legInstrumentName: instrumentName(inquiry),
        legQuantity: multiLegSummaryQuantity(inquiry),
        legPriceType: NO_VALUE,
        legTradedAway: mapBoolean(inquiry.packageTradedAway),
        legQuoteCompetitiveness: mapQuoteCompetitiveness(inquiry.packageQuoteCompetitiveStatus),
        legCoverPrice: inquiry.packageCoverPrice ?? NO_VALUE,
        legTradedPrice: inquiry.packageTradedPrice ?? NO_VALUE,
        legFixedRate: NO_VALUE,
        legMinPriceIncrement: inquiry.legs[0]?.minPriceIncrement ?? "",
        legIsRfm: mapBoolean(inquiry.isRFM),
        legLatestQuotedBidPrice: inquiry.latestQuotedPackagePrice ?? NO_VALUE,
        legLatestQuotedMidPrice: inquiry.latestQuotedMidPackagePrice ?? NO_VALUE,
        legLatestQuotedAskPrice: inquiry.latestQuotedOppositePackagePrice ?? NO_VALUE,
        customTradeHandle: inquiry.customTradeHandle ?? NO_VALUE,
        legSwapEffectiveDate: NO_VALUE,
        legSwapEndDate: NO_VALUE,
        spotNegotiationModel: NO_VALUE,
        spotTime: NO_VALUE,
        hedgingType: NO_VALUE,
        curtainTimeMillis: null,
        numberOfListItems: inquiry.numberOfListItems?.toString() ?? NO_VALUE,
        listId: inquiry.listId ?? NO_VALUE,
        legInstrumentId: NO_VALUE,
        legInstrumentIdType: NO_VALUE,
        legBenchmarkName: NO_VALUE,
        legBenchmarkId: NO_VALUE,
        legBenchmarkIdType: NO_VALUE,
        legBenchmarkQuantity: null,
        legSettlementDate: legSettlementDate(inquiry),
        legRelativeSettlementDate: legRelativeSettlementDate(inquiry),
        legCurrency: inquiry.legs[0]?.currency ?? NO_VALUE,
        legBenchmarkPrice: NO_VALUE,
        legCashPrice: NO_VALUE,
        priceTier: inquiry.priceTier?.toString() ?? NO_VALUE,
        numberOfDealers: inquiry.numberOfDealers,
        isAllToAll: mapBoolean(inquiry.isAllToAll),
        isCounterpartyAnonymous: mapBoolean(inquiry.isCounterpartyAnonymous),
        counterpartyRank: getCounterpartyRankDisplayText(inquiry.counterpartyRank),
        counterpartyType: getCounterpartyTypeDisplayText(inquiry.counterpartyType),
        tradedLevel: NO_VALUE,
        tradedLevelType: NO_VALUE,
        inquiryCreationTime: inquiry.inquiryCreationTimestampNanos,
        benchmarkHedge: inquiry.benchmarkHedge ?? NO_VALUE,
    };
};

const mapLegsToBlotterRow: (inquiry: HDSProtocol.Inquiry) => BlotterRow[] = (inquiry) => {
    return inquiry.legs.map((leg) => {
        return {
            path: [`${inquiry.ticketId}`, `${leg.position}`],
            ticketId: inquiry.ticketId,
            snapshotCreationTimestampNanos: inquiry.snapshotCreationTimestampNanos,
            state: removeUnderScores(inquiry.state),
            firmId: inquiry.firmId ?? NO_VALUE,
            traderName: inquiry.traderName ?? NO_VALUE,
            salespersonName: inquiry.salesPersonName ?? NO_VALUE,
            salespersonId: inquiry.salesPersonId ?? NO_VALUE,
            dealerFullName: inquiry.dealerFullName ?? NO_VALUE,
            venueName: venueText(inquiry.venueName),
            venueType: formatVenueType(inquiry.venueType),
            venueId: inquiry.aggregateId,
            autoMode: formatAutoMode(inquiry.autoMode),
            legPosition: leg.position.toString(),
            legSide: leg.side,
            legInstrumentName: leg.instrumentName ?? NO_VALUE,
            legQuantity: leg.quantity,
            legPriceType: mapPriceType(leg.priceType),
            legTradedAway: NO_VALUE,
            legQuoteCompetitiveness: NO_VALUE,
            legCoverPrice: leg.coverPrice ?? NO_VALUE,
            legTradedPrice: leg.tradedPrice ?? NO_VALUE,
            legFixedRate: leg.fixedRate ?? NO_VALUE,
            legMinPriceIncrement: leg.minPriceIncrement ?? "",
            legIsRfm: NO_VALUE,
            legLatestQuotedBidPrice: leg.latestQuotedBidPrice ?? NO_VALUE,
            legLatestQuotedMidPrice: leg.latestQuotedMidPrice ?? NO_VALUE,
            legLatestQuotedAskPrice: leg.latestQuotedAskPrice ?? NO_VALUE,
            customTradeHandle: inquiry.customTradeHandle ?? NO_VALUE,
            legSwapEffectiveDate: formatIntDateAsString(leg.swapEffectiveDate),
            legSwapEndDate: formatIntDateAsString(leg.swapEndDate),
            spotNegotiationModel: NO_VALUE,
            spotTime: NO_VALUE,
            hedgingType: NO_VALUE,
            curtainTimeMillis: null,
            numberOfListItems: inquiry.numberOfListItems?.toString() ?? NO_VALUE,
            listId: inquiry.listId ?? NO_VALUE,
            legInstrumentId: leg.instrumentId ?? NO_VALUE,
            legInstrumentIdType: mapInstrumentIdType(leg.instrumentIdType),
            legBenchmarkName: NO_VALUE,
            legBenchmarkId: NO_VALUE,
            legBenchmarkIdType: NO_VALUE,
            legBenchmarkQuantity: null,
            legSettlementDate: null,
            legRelativeSettlementDate: null,
            legCurrency: leg.currency ?? NO_VALUE,
            legBenchmarkPrice: NO_VALUE,
            legCashPrice: NO_VALUE,
            priceTier: inquiry.priceTier?.toString() ?? NO_VALUE,
            numberOfDealers: inquiry.numberOfDealers,
            isAllToAll: mapBoolean(inquiry.isAllToAll),
            isCounterpartyAnonymous: mapBoolean(inquiry.isCounterpartyAnonymous),
            counterpartyRank: getCounterpartyRankDisplayText(inquiry.counterpartyRank),
            counterpartyType: getCounterpartyTypeDisplayText(inquiry.counterpartyType),
            tradedLevel: leg.tradedLevel ?? NO_VALUE,
            tradedLevelType: leg.tradedLevelType ?? NO_VALUE,
            inquiryCreationTime: inquiry.inquiryCreationTimestampNanos,
            benchmarkHedge: inquiry.benchmarkHedge ?? NO_VALUE,
        };
    });
};

// function venueType(venueType: VenueType | null): string {
//     return venueType?.toString() : "";
// }

let fetchIndex = 0;
let latestIsLive = false;
const requestTooOld = "request too old";
const fetchData = (
    hdsGatewayUrl: string,
    accessToken: string,
    callback: (rows: BlotterRow[]) => void,
    setMessage: (message: string) => void,
    isLive: boolean,
    timezoneProvider: TimezoneProvider,
    requestIndex: number,
    queryParams?: QueryParams,
): void => {
    latestIsLive = isLive;
    const inLiveModeAndLiveResults = (): boolean => {
        return latestIsLive && !queryParams;
    };
    const url = new URL(`${hdsGatewayUrl}/api/secure/inquiries`);
    if (queryParams !== undefined) {
        if (queryParams.startTimestampNanos !== null) {
            url.searchParams.set("startTimestampNanos", queryParams.startTimestampNanos);
        }
        if (queryParams.endTimestampNanos !== null) {
            url.searchParams.set("endTimestampNanos", queryParams.endTimestampNanos);
        }
        if (queryParams.customerFirm !== null) {
            url.searchParams.set("customerFirm", queryParams.customerFirm);
        }
        if (queryParams.instrumentId !== null) {
            url.searchParams.set("instrumentId", queryParams.instrumentId);
        }
        if (queryParams.aggregateId !== null) {
            url.searchParams.set("aggregateId", queryParams.aggregateId);
        }
        if (queryParams.minSize !== null) {
            url.searchParams.set("minSize", queryParams.minSize.toString());
        }
        if (queryParams.maxSize !== null) {
            url.searchParams.set("maxSize", queryParams.maxSize.toString());
        }
        if (queryParams.states !== null) {
            url.searchParams.set("states", queryParams.states);
        }
        if (queryParams.userGroups !== null) {
            url.searchParams.set("userGroups", queryParams.userGroups);
        }
    }
    if (!isLive) {
        setMessage("Querying...");
    }
    fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then((httpResponse) => {
            if (!inLiveModeAndLiveResults() && fetchIndex !== requestIndex) {
                throw new Error(requestTooOld);
            } else if (httpResponse.status === 401) {
                removeItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
                window.location.reload();
                throw new Error("Received Unauthenticated response");
            } else if (httpResponse.status === 200) {
                return httpResponse.json();
            } else if (httpResponse.status === 444) {
                throw new Error("Query timed out, please refine your search criteria");
            } else {
                throw new Error("Unexpected error while querying historical data: " + httpResponse.status.toString());
            }
        })
        .then((response) => {
            const inquiriesResponse: HDSProtocol.InquiriesResponse = response as HDSProtocol.InquiriesResponse;

            callback(mapInquiryArrayToRowArray(inquiriesResponse.inquiries, timezoneProvider));

            if (isLive) {
                let message = "Following live stream of inquiries";
                if (inquiriesResponse.totalCount === inquiriesResponse.inquiriesLimit) {
                    message += `. Showing latest ${inquiriesResponse.inquiriesLimit}`;
                }
                setMessage(message);
            } else if (inquiriesResponse.totalCount === 0) {
                setMessage("No results match your search criteria");
            } else if (inquiriesResponse.totalCount === inquiriesResponse.inquiriesLimit) {
                setMessage(
                    "Query returned the maximum of " +
                        inquiriesResponse.inquiriesLimit.toString() +
                        " results, possibly more results exist, please refine your search",
                );
            } else if (inquiriesResponse.totalCount === 1) {
                setMessage("Query returned 1 result");
            } else {
                setMessage("Query returned " + inquiriesResponse.totalCount.toString() + " results");
            }
        })
        .catch((error: Error) => {
            if (error.message === requestTooOld) {
                callback([]);
            } else {
                setMessage(error.message);
                callback([]);
            }
        });
};

const mapInquiryArrayToRowArray = (inquiries: HDSProtocol.Inquiry[], timezoneProvider: TimezoneProvider): BlotterRow[] => {
    const rows: BlotterRow[] = [];
    for (const inquiry of inquiries) {
        if (inquiry.legs.length === 1) {
            rows.push(mapOutrightInquiryResponseToInquiryRow(inquiry, timezoneProvider));
        } else {
            rows.push(mapMultiLegToExpandableInquiryRow(inquiry));
            rows.push(...mapLegsToBlotterRow(inquiry));
        }
    }
    return rows;
};

interface QueryParams {
    startTimestampNanos: string | null;
    endTimestampNanos: string | null;
    customerFirm: string | null;
    instrumentId: string | null;
    aggregateId: string | null;
    minSize: number | null;
    maxSize: number | null;
    states: string | null;
    userGroups: string | null;
}

const HistoricalBlotterContainer = styled(Box)`
    height: 100vh;
`;

export const HistoricalBlotter: React.FunctionComponent<HdsBlotterProps> = ({
    hdsGatewayUrl,
    accessToken,
    timezoneProvider,
    userGroups,
}) => {
    const [blotterRows, setBlotterRows] = useState([] as BlotterRow[]);
    const [queryInProgress, setQueryInProgress] = useState(false);

    const updateDisplayValues = (rows: BlotterRow[]): void => {
        setBlotterRows(rows);
    };

    const updateDisplayValuesAfterQuery = (rows: BlotterRow[]): void => {
        setBlotterRows(rows);
        setQueryInProgress(false);
    };

    const isLiveMode = useRef(true);
    const gridRef = useRef<AgGridReact<BlotterRow>>(null);

    const [message, setMessage] = useState<string>("");

    const fetchLiveData: () => void = () => {
        if (isLiveMode.current) {
            fetchData(hdsGatewayUrl, accessToken, updateDisplayValues, setMessage, true, timezoneProvider, ++fetchIndex);
        }
    };
    const fetchSearchData: (
        startTimestamp: number | null,
        endTimestamp: number | null,
        customerFirm: string | null,
        instrumentId: string | null,
        aggregateId: string | null,
        minSize: number | null,
        maxSize: number | null,
        states: string | null,
        queryUserGroups: string | null,
    ) => void = (
        startTimestampMillis,
        endTimestampMillis,
        customerFirm,
        instrumentId,
        aggregateId,
        minSize,
        maxSize,
        states,
        queryUserGroups,
    ) => {
        function millisToNanosString(millis: number | null): string | null {
            return millis?.toString()?.concat("000000") ?? null;
        }

        const queryParams: QueryParams = {
            startTimestampNanos: millisToNanosString(startTimestampMillis),
            endTimestampNanos: millisToNanosString(endTimestampMillis),
            customerFirm,
            instrumentId,
            aggregateId,
            minSize,
            maxSize,
            states,
            userGroups: queryUserGroups,
        };
        isLiveMode.current = false;
        setQueryInProgress(true);
        fetchData(
            hdsGatewayUrl,
            accessToken,
            updateDisplayValuesAfterQuery,
            setMessage,
            false,
            timezoneProvider,
            ++fetchIndex,
            queryParams,
        );
    };

    const removeFilter: () => void = useCallback(() => {
        isLiveMode.current = true;
        fetchData(hdsGatewayUrl, accessToken, updateDisplayValues, setMessage, true, timezoneProvider, ++fetchIndex);
    }, [hdsGatewayUrl, accessToken, timezoneProvider]);

    return (
        <HistoricalBlotterContainer>
            <HistoricalBlotterController
                hdsGatewayUrl={hdsGatewayUrl}
                timezoneProvider={timezoneProvider}
                accessToken={accessToken}
                submitCallback={fetchSearchData}
                removeFilterCallback={removeFilter}
                isLiveMode={isLiveMode.current}
                gridRef={gridRef}
                message={message}
                queryInProgress={queryInProgress}
                userGroups={userGroups}
            />
            <HistoricalBlotterPanel
                hdsGatewayUrl={hdsGatewayUrl}
                accessToken={accessToken}
                blotterRows={blotterRows}
                fetchData={fetchLiveData}
                shouldAutoRefresh={isLiveMode.current}
                gridRef={gridRef}
            />
        </HistoricalBlotterContainer>
    );
};
