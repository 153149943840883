import { BlotterRow } from "./historical-blotter";
import { DecimalNumber, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { DateTime } from "luxon";
import { ColDef } from "ag-grid-enterprise";
import { formatDateTimeAsString, formatIntDateAsString, formatPriceOrDefaultEmptyValue } from "@transficc/components";
import { NO_VALUE } from "@transficc/trader-desktop-ag-grid-common";

const priceFormatter = (params: { data: BlotterRow; value: string | null }): string => {
    return formatPriceOrDefaultEmptyValue(params.value, params.data.legMinPriceIncrement);
};

const dateTimeFormatter = (params: { value: DateTime | null }): string => {
    return params.value ? formatDateTimeAsString(params.value) : NO_VALUE;
};

const dateTimeValueGetter = (timeNanos: string | null): DateTime | null => {
    if (timeNanos === null) {
        return null;
    }

    const parsedDateTime = DateTime.fromMillis(new ImmutableDecimalNumber(timeNanos).divideBy(1_000_000).toNumber());
    return parsedDateTime.isValid ? parsedDateTime : null;
};

export const historicalBlotterColumnDefinitions: ColDef[] = [
    {
        headerName: "Ticket Id",
        field: "ticketId",
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
            suppressCount: true,
        },
        minWidth: 180,
        filter: false,
    },
    {
        headerName: "Creation Time",
        field: "inquiryCreationTime",
        minWidth: 220,
        valueGetter: (params: { data: BlotterRow }): DateTime | null => {
            return dateTimeValueGetter(params.data.inquiryCreationTime);
        },
        valueFormatter: dateTimeFormatter,
        filter: false,
    },
    {
        headerName: "Updated Time",
        field: "updatedTime",
        valueGetter: (params: { data: BlotterRow }): DateTime | null => {
            return dateTimeValueGetter(params.data.snapshotCreationTimestampNanos);
        },
        valueFormatter: dateTimeFormatter,
        minWidth: 220,
        filter: false,
    },
    { headerName: "State", field: "state", minWidth: 270 },
    { headerName: "Auto Mode", field: "autoMode", minWidth: 180 },
    { headerName: "Customer Firm", field: "firmId", minWidth: 180 },
    { headerName: "Customer Trader", field: "traderName", minWidth: 180 },
    { headerName: "Trader Name", field: "dealerFullName", minWidth: 180 },
    { headerName: "Salesperson Name", field: "salespersonName", minWidth: 180 },
    { headerName: "Salesperson Id", field: "salespersonId", minWidth: 150 },
    { headerName: "Venue", field: "venueName", minWidth: 180 },
    { headerName: "Venue Type", field: "venueType", minWidth: 135 },
    { headerName: "Venue Aggregate Id", field: "venueId", minWidth: 200 },
    { headerName: "Leg", field: "legPosition", minWidth: 80, filter: false },
    { headerName: "Side", field: "legSide", minWidth: 140 },
    { headerName: "Instrument Description", field: "legInstrumentName", minWidth: 250 },
    {
        headerName: "Size",
        field: "legQuantity",
        minWidth: 140,
        filter: "agNumberColumnFilter",
        valueGetter: (params: { data: BlotterRow }): number | null => {
            const quantity = params.data.legQuantity;
            if (quantity === null) {
                return null;
            }
            return Number.parseInt(quantity);
        },
        valueFormatter: (params: { value: number | null }): string => {
            if (params.value === null) {
                return NO_VALUE;
            }
            return `${params.value}`;
        },
    },
    { headerName: "Fixed Rate", field: "legFixedRate", minWidth: 140, filter: false },
    { headerName: "Price Type", field: "legPriceType", minWidth: 190 },
    { headerName: "Effective Date", field: "legSwapEffectiveDate", minWidth: 190 },
    { headerName: "End Date", field: "legSwapEndDate", minWidth: 190 },
    { headerName: "Traded Price", field: "legTradedPrice", minWidth: 150, filter: false },
    {
        headerName: "Latest Quoted Bid Price",
        field: "legLatestQuotedBidPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Latest Quoted Mid Price",
        field: "legLatestQuotedMidPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    {
        headerName: "Latest Quoted Ask Price",
        field: "legLatestQuotedAskPrice",
        minWidth: 240,
        valueFormatter: priceFormatter,
        filter: false,
    },
    { headerName: "Cover Price", field: "legCoverPrice", minWidth: 150, filter: false },
    { headerName: "Traded Away", field: "legTradedAway", minWidth: 180 },
    { headerName: "Quote Competitiveness", field: "legQuoteCompetitiveness", minWidth: 235 },
    { headerName: "RFM", field: "legIsRfm", minWidth: 110 },
    { headerName: "Trade Handle", field: "customTradeHandle", minWidth: 190 },
    { headerName: "Spot Model", field: "spotNegotiationModel", minWidth: 200 },
    { headerName: "Spot Time", field: "spotTime", minWidth: 200 },
    { headerName: "Hedging Type", field: "hedgingType", minWidth: 200 },
    {
        headerName: "Curtain Time (s)",
        colId: "curtainTime",
        valueGetter: (params: { data: BlotterRow }): DecimalNumber | null => {
            const timeMillis = params.data.curtainTimeMillis;
            if (timeMillis === null) {
                return null;
            }
            return new ImmutableDecimalNumber(timeMillis).divideBy(1_000);
        },
        valueFormatter: (params: { value: DecimalNumber | null }): string => {
            if (params.value === null) {
                return NO_VALUE;
            }
            return params.value.format(0);
        },
        minWidth: 200,
    },
    { headerName: "# List Items", field: "numberOfListItems", minWidth: 200 },
    { headerName: "List Id", field: "listId", minWidth: 200 },
    { headerName: "Instrument Id", field: "legInstrumentId", minWidth: 200 },
    { headerName: "Instrument Id Type", field: "legInstrumentIdType", minWidth: 200 },
    { headerName: "Benchmark Description", field: "legBenchmarkName", minWidth: 200 },
    { headerName: "Benchmark Id", field: "legBenchmarkId", minWidth: 200 },
    { headerName: "Benchmark Id Type", field: "legBenchmarkIdType", minWidth: 200 },
    {
        headerName: "Settlement Date",
        colId: "legSettlementDate",
        valueGetter: (params: { data: BlotterRow }): number | null => {
            return params.data.legSettlementDate;
        },
        valueFormatter: (params: { value: number | null }): string => {
            if (params.value === null) {
                return NO_VALUE;
            }
            return formatIntDateAsString(params.value);
        },
        minWidth: 220,
        filter: false,
    },
    {
        headerName: "Relative Settlement Date",
        colId: "legRelativeSettlementDate",
        valueGetter: (params: { data: BlotterRow }): string | null => {
            return params.data.legRelativeSettlementDate;
        },
        valueFormatter: (params: { value: string | null }): string => {
            return params.value ?? NO_VALUE;
        },
        minWidth: 200,
    },
    { headerName: "Currency", field: "legCurrency", minWidth: 200 },
    { headerName: "Benchmark Price", field: "legBenchmarkPrice", minWidth: 200 },
    {
        headerName: "Benchmark Quantity",
        field: "legBenchmarkQuantity",
        minWidth: 200,
        filter: "agNumberColumnFilter",
        valueGetter: (params: { data: BlotterRow }): number | null => {
            const quantity = params.data.legBenchmarkQuantity;
            if (quantity === null) {
                return null;
            }
            return Number.parseInt(quantity);
        },
        valueFormatter: (params: { value: number | null }): string => {
            if (params.value === null) {
                return NO_VALUE;
            }
            return `${params.value}`;
        },
    },
    { headerName: "Final Cash Price", field: "legCashPrice", minWidth: 200 },
    { headerName: "Price Tier", field: "priceTier", minWidth: 150 },
    {
        headerName: "Number Of Dealers",
        colId: "numberOfDealers",
        valueGetter: (params: { data: BlotterRow }): number | null => {
            return params.data.numberOfDealers;
        },
        valueFormatter: (params: { value: number | null }): string => {
            if (params.value === null) {
                return "Undisclosed";
            }
            return params.value.toString();
        },
        minWidth: 200,
    },
    { headerName: "All To All", field: "isAllToAll", minWidth: 140 },
    { headerName: "Is Counterparty Anonymous", field: "isCounterpartyAnonymous", minWidth: 265 },
    { headerName: "Counterparty Rank", field: "counterpartyRank", minWidth: 200 },
    { headerName: "Counterparty Type", field: "counterpartyType", minWidth: 200 },
    { headerName: "Traded Level", field: "tradedLevel", minWidth: 200 },
    { headerName: "Traded Level Type", field: "tradedLevelType", minWidth: 200 },
    { headerName: "Benchmark Hedge", field: "benchmarkHedge", minWidth: 200 },
];
