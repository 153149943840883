import globalLogger from "../logger/globalLogger";

export class MessageBatcher {
    private readonly batch: string[] = [];
    private flushMessageAfterIntervalTimerId: ReturnType<typeof setInterval> | null = null;
    private cleanedUp = false;

    constructor(
        private readonly maxBatchSize: number,
        private readonly batchIntervalMs: number,
        private readonly messageProcessor: (message: string) => void,
    ) {
        if (this.batchIntervalMs > 0) {
            this.flushMessageAfterIntervalTimerId = setInterval(() => this.flush(), this.batchIntervalMs);
        }
    }

    public onMessage(message: string): void {
        if (this.cleanedUp) {
            throw new Error("Cannot use MessageBatcher after call to .cleanup()");
        }

        this.batch.push(message);

        if (this.batch.length >= this.maxBatchSize) {
            this.flush();
        }
    }

    public cleanup(): void {
        this.flush();

        if (this.flushMessageAfterIntervalTimerId !== null) {
            clearInterval(this.flushMessageAfterIntervalTimerId);
            this.flushMessageAfterIntervalTimerId = null;
        }

        this.cleanedUp = true;
    }

    private flush(): void {
        // processing all messages in one event loop tick causes React 18 to batch the renders
        // see https://react-redux.js.org/api/batch
        this.batch.forEach((val) => {
            try {
                this.messageProcessor(val);
            } catch (error: unknown) {
                // don't let a poisoned message block the batch processing from progressing
                globalLogger.error("Error while processing message in batch", String(error));
            }
        });
        this.batch.length = 0;
    }
}
