import { useEffect, useRef } from "react";
import { TextToggle } from "@transficc/components";

interface TradingStatusToggleProps {
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
    connectionStatus: "connected" | "disconnected";
}

export const TradingStatusToggle: React.FC<TradingStatusToggleProps> = ({
    onTradingStatusChanged,
    tradingStatus,
    serverTradingStatus,
    connectionStatus,
}) => {
    const hasTurnedOnTradingStatus = useRef(false);

    useEffect(
        () => {
            if (
                tradingStatus === "off" &&
                serverTradingStatus === "off" &&
                connectionStatus === "connected" &&
                !hasTurnedOnTradingStatus.current
            ) {
                // turns toggle on (false is the current status, so it toggles it)
                onTradingStatusChanged(false);
                hasTurnedOnTradingStatus.current = true;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onTradingStatusChanged, connectionStatus],
    );

    return (
        <TextToggle
            data-testid="trading-status-toggle"
            text="Trader"
            onToggle={onTradingStatusChanged}
            position={"left"}
            width={"11"}
            height={"4"}
            toggledOn={tradingStatus === "on"}
            serverToggledOn={serverTradingStatus === "on"}
        />
    );
};
