import { ReactElement, useCallback, useMemo, useState } from "react";
import { Box } from "../box";

import { default as ReactSelect, SingleValue } from "react-select";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";

export interface SelectOption<T> {
    value: T;
    label: string;
}

export interface SelectProps<T> {
    options: SelectOption<T>[];
    id: string;
    placeholder: string;
    selectedValue?: T | null;
    onChange?: (value: T | null) => void;
}

const StyledBox = styled(Box)`
    & .basic-multi-select {
        width: 100%;
        align-content: center;
        background-color: transparent;
        color: white;
        min-height: 32px;
        height: auto;
        text-indent: 8px;
        font-size: 0.875rem;

        & .select__indicators {
            height: 32px;
        }

        & .select__control {
            background: transparent;
            border: none;
            //border-color: white;
            height: auto;
            min-height: 32px;
            box-shadow: none;

            &:hover {
                border: none;
            }

            & .select__value-container {
                overflow: clip;
                height: 89%;
            }
        }

        & .select__option {
            color: black;
        }

        & .select__multi-value__remove {
            color: black;
        }

        & .select__placeholder {
            color: ${(props) => ThemeUtils.colorSelector(props.theme, { color: "gray", level: "400" })};
        }
    }
`;

export const Select = <T,>({ options, id, selectedValue, placeholder, onChange }: SelectProps<T>): ReactElement => {
    const value = useMemo(() => {
        if (!selectedValue) {
            return null;
        }
        const match = options.filter((option) => option.value === selectedValue)[0];
        if (match) {
            return match;
        } else {
            throw new Error("Unexpected option found");
        }
    }, [options, selectedValue]);

    const onChangeCallback = useCallback(
        (newValue: SingleValue<SelectOption<T>>) => {
            if (onChange !== undefined) {
                onChange(newValue ? newValue.value : null);
            }
        },
        [onChange],
    );

    const [isFocused, setIsFocused] = useState(false);

    return (
        <StyledBox
            data-testid={id}
            width={"106"}
            contentDirection={"row"}
            contentAlignment={"center"}
            border={
                isFocused
                    ? { width: "px", color: { color: "warning" }, style: "solid" }
                    : { width: "px", color: { color: "white" }, style: "solid" }
            }
        >
            <ReactSelect
                value={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={placeholder}
                onChange={onChangeCallback}
            />
        </StyledBox>
    );
};
