import { useEffect, useState } from "react";
import { EpochClock } from "@transficc/infrastructure";

export interface CountdownDuration {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const useCountdown = ({
    targetDateEpochMs,
    updateIntervalMs,
    clock,
}: {
    targetDateEpochMs: number;
    updateIntervalMs: number;
    clock: EpochClock;
}): CountdownDuration => {
    const [countDownTimeMs, setCountDownTimeMs] = useState(targetDateEpochMs - clock.getCurrentTimeMs());

    useEffect(() => {
        setCountDownTimeMs(targetDateEpochMs - clock.getCurrentTimeMs());

        const interval = setInterval(() => {
            const now = clock.getCurrentTimeMs();

            if (now >= targetDateEpochMs) {
                setCountDownTimeMs(0);
                clearInterval(interval);
                return;
            }

            setCountDownTimeMs(targetDateEpochMs - now);
        }, updateIntervalMs);

        return () => clearInterval(interval);
    }, [clock, targetDateEpochMs, updateIntervalMs]);

    return getReturnValues(countDownTimeMs);
};

const getReturnValues = (_countDownTimeMs: number): CountdownDuration => {
    const countDownTimeMs = Math.max(0, _countDownTimeMs);
    // calculate time left
    const days = Math.floor(countDownTimeMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDownTimeMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDownTimeMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDownTimeMs % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};
