import React, { useCallback } from "react";
import styled from "styled-components";
import { rfmColor } from "../irs-domain/colors";
import { NumberOfDealers } from "./number-of-dealers";
import {
    Box,
    Button,
    CurrencyDisplay,
    IconNames,
    semanticButtonStyling,
    StackedTimerProgress,
    Text,
    TierDisplay,
    VenueDisplay,
} from "@transficc/components";
import { VenueTypeDisplay } from "@transficc/trader-desktop-shared-components";
import { useSelectedInquiryHeader } from "../irs-slice/irs-selectors";
import { useClock, useEnvironmentConfig } from "@transficc/trader-desktop-application-context";

const TicketIdLabel = styled(Text)`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export type OpenCustomerAnalytics = (customerFirm: string, customerTrader: string) => void;

export interface IRSInquiryHeaderProps {
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

export const IRSInquiryHeader: React.FC<IRSInquiryHeaderProps> = ({ openCustomerAnalyticsWindow }) => {
    const model = useSelectedInquiryHeader();
    const clock = useClock();
    const { showCustomerAnalytics } = useEnvironmentConfig().featureFlags;

    const timerProgress =
        model.timerStartTimestampMillis !== null && model.timerEndTimestampMillis !== null ? (
            <StackedTimerProgress
                startTimeEpochMs={parseInt(model.timerStartTimestampMillis)}
                endTimeEpochMs={parseInt(model.timerEndTimestampMillis)}
                updateIntervalMs={200}
                clock={clock}
            />
        ) : (
            <Text srOnly>Timer is unavailable</Text>
        );

    const inquiryTypeLabel = model.isRfm ? (
        <Box
            data-testid="inquiry-type-label"
            padding="0.5"
            paddingL="3"
            paddingR="3"
            border={{ width: "1", color: { color: "black" } }}
            rounded
            backgroundColor={rfmColor}
        >
            <Text textColor={{ color: "black" }} size="xl" weight="black">
                RFM
            </Text>
        </Box>
    ) : (
        <Text data-testid="inquiry-type-label" srOnly>
            No inquiry type displayed
        </Text>
    );

    const onCustomerAnalytics = useCallback(() => {
        openCustomerAnalyticsWindow(model.counterpartyFirm, model.counterpartyTrader);
    }, [openCustomerAnalyticsWindow, model.counterpartyFirm, model.counterpartyTrader]);

    return (
        <Box width={"full"}>
            <Box contentDirection={"row"} contentAlignment={"center"} width={"full"} padding={"2"}>
                <TierDisplay tier={model.tier} />
                {showCustomerAnalytics === true ? (
                    <Box marginL={"1"}>
                        <Button
                            styling={{ ...semanticButtonStyling("info"), unselectedBackgroundColor: { color: "transparent" } }}
                            width={"8"}
                            height={"8"}
                            size={"sm"}
                            onClick={onCustomerAnalytics}
                            leftIcon={{ size: "6", iconColor: { color: "white" }, name: IconNames.TrendUp }}
                            hoverTitle={"Customer Analytics"}
                        />
                    </Box>
                ) : null}
                <Box width={"2"} />
                <NumberOfDealers numberOfDealers={model.numberOfDealers} />
                <Box width={"4"} />
                {inquiryTypeLabel}
                <Box contentDirection={"row"} marginL={"auto"}>
                    <Box contentDirection={"column"} contentAlignment={"end"}>
                        <VenueDisplay venueName={model.venueName} />
                        <Box height={"4"} paddingT={"1.5"} contentDirection={"column"} contentAlignment={"end"}>
                            <VenueTypeDisplay venueType={model.venueType} />
                        </Box>
                    </Box>
                    <Box width={"2"} />
                    <CurrencyDisplay isoCode={model.currency} />
                </Box>
            </Box>
            <Box contentDirection={"row"} width={"full"} padding={"2"}>
                <Box>
                    <Text title="Customer Firm" data-testid="inquiry-counter-party-firm">
                        {model.counterpartyFirm}
                    </Text>
                    <Text title="Customer Trader" data-testid="inquiry-counter-party-trader">
                        {model.counterpartyTrader}
                    </Text>
                    <Text data-testid="inquiry-sales-person">Salesperson: {model.salesPersonName || model.salesPersonId || "-"}</Text>
                    <Box marginT={"2"}>
                        <Text data-testid="inquiry-clearing-house" weight={"bold"}>
                            {model.clearingHouse ?? ""}
                        </Text>
                    </Box>
                </Box>

                <Box contentDirection={"row"} marginL={"auto"} contentJustification={"end"}>
                    <Box contentDirection={"column"} contentAlignment={"end"}>
                        <Text srOnly>Timer</Text>
                        <Box height={"12"}>{timerProgress}</Box>
                        <Box contentAlignment={"end"}>
                            <TicketIdLabel data-testid="inquiry-ticket-id">#{model.ticketId}</TicketIdLabel>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
