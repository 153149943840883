import { IdToken, log } from "@transficc/trader-desktop-core";
import { getItemFromLocalStorage, LocalStorageKeys, removeItemFromLocalStorage } from "@transficc/trader-desktop-local-storage";
import { jwtDecode } from "jwt-decode";
import { Metric, MetricPublisher } from "@transficc/trader-desktop-application-context";

export class HttpMetricConfiguration {
    private _appInstanceId: string | null = null;
    private _windowId: string | null = null;
    private _lastAccessToken: string | null = null;
    private _userId: number | null = null;
    private _username: string | null = null;

    set setAppInstanceId(appInstanceId: string) {
        this._appInstanceId = appInstanceId;
    }

    set setWindowId(windowId: string) {
        this._windowId = windowId;
    }

    set setAccessToken(accessToken: string) {
        this._lastAccessToken = accessToken;
    }

    updateIdToken(idToken: string | null): void {
        if (idToken == null) {
            return;
        } else {
            const jwtIdToken = jwtDecode<IdToken>(idToken);
            if (jwtIdToken.user_id !== undefined) {
                this._userId = jwtIdToken.user_id;
            }
            if (jwtIdToken.sub !== undefined) {
                this._username = jwtIdToken.sub;
            }
        }
    }

    getAppInstanceId(): string | null {
        return this._appInstanceId;
    }

    getWindowId(): string | null {
        return this._windowId;
    }

    getLastAvailableAccessToken(): string | null {
        if (typeof localStorage !== "undefined") {
            return getItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
        } else {
            return this._lastAccessToken;
        }
    }

    getLastAvailableUsername(): string | null {
        this.updateFieldsFromLocalStorage();
        return this._username;
    }

    getLastAvailableUserId(): number | null {
        this.updateFieldsFromLocalStorage();
        return this._userId;
    }

    private updateFieldsFromLocalStorage(): void {
        if (typeof localStorage !== "undefined") {
            this.updateIdToken(getItemFromLocalStorage(LocalStorageKeys.ID_TOKEN));
        }
    }
}

const resolvePathName = (): string => {
    if (typeof window !== "undefined") {
        return window.location.pathname;
        // @ts-expect-error no types for DedicatedWorkerGlobalScope this is just a feature check to see if the object exists
        // eslint-disable-next-line no-restricted-globals
    } else if (typeof DedicatedWorkerGlobalScope !== "undefined" && self instanceof DedicatedWorkerGlobalScope) {
        return "[DedicatedWorker]";
        // @ts-expect-error no types for DedicatedWorkerGlobalScope this is just a feature check to see if the object exists
        // eslint-disable-next-line no-restricted-globals
    } else if (typeof SharedWorkerGlobalScope !== "undefined" && self instanceof SharedWorkerGlobalScope) {
        return "[SharedWorker]";
    } else {
        throw Error("metric publisher is running in unsupported context/scope");
    }
};

export class HttpMetricPublisher implements MetricPublisher {
    private readonly _httpMetricConfiguration: HttpMetricConfiguration;
    private readonly _url: string;
    private _suppressFailures = false;

    constructor(httpMetricConfiguration: HttpMetricConfiguration) {
        this._httpMetricConfiguration = httpMetricConfiguration;
        this._url = "/api/secure/metric";
    }

    publish(metrics: Metric[]): void {
        const appInstanceId = this._httpMetricConfiguration.getAppInstanceId();
        if (appInstanceId === null) {
            throw new Error("appInstanceId is not initialized before publication");
        }
        const windowId = this._httpMetricConfiguration.getWindowId();
        if (windowId === null) {
            throw new Error("windowId is not initialized before publication");
        }
        this.sendWithAppendedMetadata(
            appInstanceId,
            windowId,
            this._httpMetricConfiguration.getLastAvailableUsername(),
            this._httpMetricConfiguration.getLastAvailableUserId(),
            metrics,
        );
    }

    private sendWithAppendedMetadata(
        appInstanceId: string,
        windowId: string,
        user: string | null,
        userId: number | null,
        metrics: Metric[],
    ): void {
        metrics.forEach((metric): void => {
            metric.tags["appInstanceId"] = appInstanceId;
            metric.tags["windowId"] = windowId;
            if (user != null) {
                metric.tags["user"] = user;
            }
            if (userId != null) {
                metric.tags["userId"] = String(userId);
            }
            metric.tags["pathname"] = resolvePathName();
            if (!metric.timestamp) {
                metric.timestamp = Date.now();
            }
        });

        this.send(metrics);
    }

    private send(metrics: Metric[]): void {
        if (this._url === undefined) {
            throw new Error("Failed to post metrics message as URL is undefined.");
        }

        const lastAvailableAccessToken = this._httpMetricConfiguration.getLastAvailableAccessToken();
        if (lastAvailableAccessToken == null) {
            log.error("Unable to send metric (token is null)");
            return;
        }

        fetch(this._url, {
            // Since node's implementation of fetch does not support preserving cookies, logs are not sent to the same backend for each user
            method: "POST",
            body: JSON.stringify({ metrics: metrics }),
            headers: {
                Authorization: "Bearer " + lastAvailableAccessToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    removeItemFromLocalStorage(LocalStorageKeys.ACCESS_TOKEN);
                    window.location.reload();
                    return;
                }
                if (this._suppressFailures) {
                    return; // do not relog a failure
                }
                const expectedStatusCode = 200;
                if (response.status === expectedStatusCode) {
                    this._suppressFailures = false;
                } else {
                    log.error(`Failed to post metrics message to [${this._url}], expected ${expectedStatusCode} got ${response.status}`);
                    this._suppressFailures = true;
                }
            })
            .catch((error) => {
                if (this._suppressFailures) {
                    return; // do not relog a failure
                }

                this._suppressFailures = true; // so we only log once

                if (error instanceof TypeError) {
                    log.error("Failed to post log network failure. (Unable to establish TCP/QUIC Connection)");
                } else {
                    log.error("Unexpected error while trying to post log: ", String(error));
                }
            });
    }
}
