import { useEffect, useState } from "react";

export function useRerenderOnInterval(intervalMs: number): void {
    const [, setCounter] = useState(0);

    useEffect(() => {
        const handle = setInterval(() => {
            setCounter((i) => (i === Number.MAX_VALUE ? Number.MIN_VALUE : i + 1));
        }, intervalMs);

        return () => {
            clearInterval(handle);
        };
    }, [intervalMs]);
}
