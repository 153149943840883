import { Box, Icon, IconNames, Text } from "../../atoms";
import React from "react";

export interface WarningLabelProps {
    visible: boolean;
    message: string;
    label: string;
    hiddenLabel?: string;
}

const VisibleWarning: React.FC<{ message: string }> = ({ message }) => (
    <Box
        width="full"
        height="14"
        contentDirection="row"
        contentAlignment="center"
        contentJustification="center"
        backgroundColor={{ color: "gold", level: "500" }}
        rounded
    >
        <Icon size={"4"} iconColor={{ color: "black" }} name={IconNames.Warning} />
        <Box marginR={"1"} />
        <Text size={"lg"} weight={"medium"} textAlignment={"center"} textColor={{ color: "black" }}>
            {message}
        </Text>
    </Box>
);

const HiddenWarning: React.FC<{ hiddenLabel?: string }> = ({ hiddenLabel }) => (
    <Box marginT={"14"}>{hiddenLabel ? <Text srOnly>{hiddenLabel}</Text> : null}</Box>
);

export const WarningLabel: React.FC<WarningLabelProps> = ({ visible, message, label, hiddenLabel }) => {
    return (
        <Box
            contentDirection={"row"}
            contentAlignment={"center"}
            contentJustification={"center"}
            width={"40"}
            marginT={"1.5"}
            marginL={"4"}
            marginB={"4"}
            padding={"0.5"}
        >
            <Text srOnly>{label}</Text>
            {visible ? <VisibleWarning message={message} /> : <HiddenWarning hiddenLabel={hiddenLabel} />}
        </Box>
    );
};
