import { EpochClock } from "@transficc/infrastructure";
import { PricerState } from "./enums";

export function isSelectedPriceStale(
    pricerState: PricerState,
    autoquotePricesGenerationTimestampNanos: string | null,
    autoquotePricesValidForNanos: string | null,
    selectedDriver: "manual" | "fromAQ" | "fromLatestQuote",
    clock: EpochClock,
): boolean {
    if (isPriceStale(pricerState, autoquotePricesGenerationTimestampNanos, autoquotePricesValidForNanos, clock)) {
        return selectedDriver === "fromAQ";
    }
    return false;
}

export function isPriceStale(
    pricerState: PricerState,
    generationTimestampNanos: string | null,
    validForNanos: string | null,
    clock: EpochClock,
): boolean {
    if (pricerState !== PricerState.Priced || generationTimestampNanos === null || validForNanos === null) {
        return false;
    }

    const utcMillis: number = toMillis(generationTimestampNanos);
    const nowMs = clock.getCurrentTimeMs();
    return nowMs - utcMillis >= toMillis(validForNanos);
}

function toMillis(timestramp: string): number {
    return parseInt(timestramp) / 1_000_000;
}
