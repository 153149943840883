import React from "react";
import * as IRSDomain from "../irs-domain";
import { BidMidAsk, PricerState, PriceValidity, SelectedDriver } from "../irs-domain";
import { IrsRfqPriceDriver } from "../irs-price-driver/irs-rfq-price-driver";
import { colorByState, colorForSide } from "../irs-domain/colors";
import { IRSPriceAndSpread } from "../irs-price-and-spread/irs-price-and-spread";
import { DecimalNumber, EpochClock, ImmutableDecimalNumber } from "@transficc/infrastructure";
import { IRSIncrementButtons } from "../irs-increment-buttons/irs-increment-buttons";
import { IRSPtmm } from "../irs-ptmm/irs-ptmm";
import { DateAndSize, LegContainer, LegInformationHeader, usePriceAndSpreadIncrements } from "./irs-leg-control-components";
import { Box, NumberInputTitleLabelConfig } from "@transficc/components";
import { toolTipForLeg } from "../irs-domain/tool-tip";
import { useMinPriceIncrementAllLegsSelector, useMinPriceIncrementLegSelector } from "../irs-slice/irs-selectors";

export interface IRSLegControlProps {
    config: {
        clock: EpochClock;
    };
    model: {
        customerRateValue: DecimalNumber | null;
        isCustomerRateInputDisabled: boolean;
        customerRateDisplayState: IRSDomain.DisplayState;
        isCustomerRateInvalid: boolean;

        spreadValue: DecimalNumber | null;
        isSpreadInputDisabled: boolean;

        autoQuoteDisplayState: IRSDomain.DisplayState;
        autoQuotePriceError: string | null;
        autoQuotePricerState: PricerState;
        autoQuotePriceValidity: PriceValidity | null;
        selectedDriver: SelectedDriver | null;

        autoQuotePrices: BidMidAsk;
        venuePrices: BidMidAsk;

        inquiryState: IRSDomain.InquiryState;
        isInquiryFinished: boolean;

        leg: IRSDomain.IRSLeg<"outright" | "curve">;
        isMac: boolean;

        titleLabel?: NumberInputTitleLabelConfig;
    };
    callbacks: {
        updateCustomerRate: (customerRate: DecimalNumber) => void;
        onCustomerRateDirtyChange: (isDirty: boolean) => void;

        updateSpreadValue: (spreadValue: DecimalNumber) => void;
        onSpreadDirtyChange: (isDirty: boolean) => void;

        updatePtmmValue: (ptmmValue: DecimalNumber) => void;

        selectDriver: (selectedDriver: SelectedDriver) => void;
        clearDriverValue: () => void;
    };
    legPosition: number;
}

const LegBody: React.FC<IRSLegControlProps> = ({ legPosition, model, callbacks }) => {
    const { inquiryLegBodyBackgroundColor } = colorByState(model.inquiryState);
    const customerRateDisplayValue =
        model.isInquiryFinished && model.leg.negotiatedPrice
            ? new ImmutableDecimalNumber(model.leg.negotiatedPrice)
            : model.customerRateValue;
    const isCustomerRateSelected = model.selectedDriver === null;
    const tickSize = useMinPriceIncrementAllLegsSelector();
    const minPriceIncrement = useMinPriceIncrementLegSelector(legPosition);

    const { rateTickSize, spreadTickSize, onNewTickSizeSelected, spreadMinPriceIncrement, tickSizeLabels } = usePriceAndSpreadIncrements(
        model.leg.priceType,
        minPriceIncrement,
        tickSize,
    );
    const priceTitle = model.isMac ? `Fixed @ ${model.leg.fixedRate?.trim()}%` : "";
    const priceTitleSelector = model.isMac ? "Fee" : "Rate";
    const priceToolTip = toolTipForLeg(model.isMac, model.leg.priceType);
    return (
        <Box width={"full"} padding={"2"} backgroundColor={inquiryLegBodyBackgroundColor} contentAlignment={"center"}>
            <DateAndSize legPosition={legPosition} />
            <Box margin={"1"} />
            <IRSIncrementButtons labels={tickSizeLabels} onSelectedIndexChange={onNewTickSizeSelected} />
            <IRSPriceAndSpread
                priceProps={{
                    config: {
                        minPriceIncrement,
                        tickButtonSize: rateTickSize,
                        title: priceTitle,
                        tooltip: priceToolTip,
                        showTitleText: true,
                        titleSelector: priceTitleSelector,
                        size: "medium",
                        titleLabel: {
                            text: IRSDomain.mapInquirySideToShortDisplayName(model.leg.side).toUpperCase(),
                            backgroundColor: colorForSide(model.leg.side),
                            foregroundColor: { color: "black" },
                        },
                    },
                    model: {
                        value: customerRateDisplayValue,
                        disabled: model.isCustomerRateInputDisabled,
                        selected: isCustomerRateSelected,
                        displayState: model.customerRateDisplayState,
                        invalid: model.isCustomerRateInvalid,
                        inputForegroundColor: { color: "black" },
                        inputBackgroundColor: colorForSide(model.leg.side),
                    },
                    callbacks: {
                        onValueChange: callbacks.updateCustomerRate,
                        onDirtyChange: callbacks.onCustomerRateDirtyChange,
                    },
                }}
                spreadProps={{
                    config: {
                        minPriceIncrement: spreadMinPriceIncrement,
                        tickButtonSize: spreadTickSize,
                        size: "small",
                    },
                    model: {
                        value: model.spreadValue,
                        disabled: model.isSpreadInputDisabled,
                    },
                    callbacks: {
                        onValueChange: callbacks.updateSpreadValue,
                        onDirtyChange: callbacks.onSpreadDirtyChange,
                    },
                }}
            />
            <IRSPtmm
                tickButtonSize={rateTickSize}
                minPriceIncrement={minPriceIncrement}
                size={"small"}
                onValueChange={callbacks.updatePtmmValue}
                legPosition={legPosition}
            />
        </Box>
    );
};

export const IrsSingleSidedLegControl: React.FC<IRSLegControlProps> = ({ config, model, callbacks, legPosition }) => {
    return (
        <Box contentAlignment="center" data-testid={`irs-leg-${model.leg.position}`}>
            <LegContainer>
                <LegInformationHeader legPosition={legPosition} />
                <LegBody config={config} model={model} callbacks={callbacks} legPosition={legPosition} />
            </LegContainer>
            <Box margin="2" />
            <IrsRfqPriceDriver
                model={{
                    prices: {
                        latestQuotedPrices: model.leg.lastQuotedPrices,
                        autoQuotePrices: model.autoQuotePrices,
                        venuePrices: model.venuePrices,
                    },
                    autoQuotePricerState: model.autoQuotePricerState,
                    autoQuoteErrorMessage: model.autoQuotePriceError,
                    autoQuoteDisplayState: model.autoQuoteDisplayState,
                    autoQuotePriceValidity: model.autoQuotePriceValidity,
                    selectedDriver: model.selectedDriver,
                    disabled: model.isInquiryFinished,
                }}
                callbacks={{
                    selectDriver: callbacks.selectDriver,
                    clearDriver: callbacks.clearDriverValue,
                }}
            />
        </Box>
    );
};
