export interface SharedWorkerFactory {
    createSharedWorker: () => SharedWorker;
}

export default class SharedWorkerFactoryImpl implements SharedWorkerFactory {
    private readonly appVersion: string;

    constructor(appVersion: string) {
        this.appVersion = appVersion;
    }

    createSharedWorker(): SharedWorker {
        //WARNING the construction of the SharedWorker needs to be on a single line - webpack requires this to load it correctly
        const sharedWorker = new SharedWorker(new URL("./MessagingSharedWorker.ts", import.meta.url), {
            name: "TraderDesktop-" + this.appVersion,
        });
        // Unfortunately This works in Firefox only - not in Chrome therefor we need to get the errors form self.onerror in the MessagingSharedWorker.ts
        // sharedWorker.onerror = function (event: ErrorEvent) {
        //     log.error('Uncaught error in SharedWorker ', errorToJson(event));
        // };

        return sharedWorker;
    }
}
