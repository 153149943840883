import React from "react";
import { InquirySide } from "../irs-domain";
import { IRSActionButtons } from "../irs-action-buttons/irs-action-buttons";
import { IRSInquiryBody, SelectedInquiryContainer } from "../irs-inquiry/irs-inquiry";
import { IRSInquiryHeader, OpenCustomerAnalytics } from "../irs-inquiry-header/irs-inquiry-header";
import { IRSInquiryFooter } from "../irs-inquiry-footer/irs-inquiry-footer";
import { IrsDoubleSidedLegControl } from "../irs-leg-control/irs-double-sided-leg-control";
import { IrsSingleSidedLegControl } from "../irs-leg-control/irs-single-sided-leg-control";
import { Box, useRerenderOnInterval } from "@transficc/components";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { IRSSideView, useIRSOutrightView } from "./irs-outright-view";
import { InquiryButtonRow } from "../inquiry-button-row/inquiry-button-row";
import { useDefinitelySelectedInquiry, useSelectedInquiryIsFinished } from "../irs-slice/irs-selectors";
import { useIRSStateDispatch } from "../irs-slice/irs-state-dispatch";
import { useClock } from "@transficc/trader-desktop-application-context";

export interface IRSOutrightProps {
    publishAction: ActionPublisher.ActionPublisher;
    openRiskViewWindow: (url: string) => void;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

const IRSLegControl: React.FC = () => {
    const clock = useClock();
    const isInquiryFinished = useSelectedInquiryIsFinished();
    const { setDirty, updatePtmmValue } = useIRSStateDispatch();
    const inquiry = useDefinitelySelectedInquiry();
    const { pay, rcv, leg, autoQuoteDisplayState } = useIRSOutrightView(isInquiryFinished);

    if (leg.side === InquirySide.Undisclosed) {
        return (
            <IrsDoubleSidedLegControl
                model={{
                    pay: {
                        customerRateValue: pay.customerRateValue,
                        isCustomerRateInputDisabled: isInquiryFinished,
                        isCustomerRateInvalid: pay.shouldShowCustomerRateInputAsStale,
                        spreadValue: pay.spreadValue,
                        isSpreadInputDisabled: pay.spreadInputDisabled,
                        selectedDriver: pay.selectedDriver,
                        customerRateDisplayState: pay.selectedDriverDisplayState,
                        autoQuotePrices: pay.autoQuotePrices,
                        venuePrices: pay.venuePrices,
                        latestQuotedPrices: pay.latestQuotedPrices,
                    },

                    rcv: {
                        customerRateValue: rcv.customerRateValue,
                        isCustomerRateInputDisabled: isInquiryFinished,
                        isCustomerRateInvalid: rcv.shouldShowCustomerRateInputAsStale,
                        spreadValue: rcv.spreadValue,
                        isSpreadInputDisabled: rcv.spreadInputDisabled,
                        selectedDriver: rcv.selectedDriver,
                        customerRateDisplayState: rcv.selectedDriverDisplayState,
                        autoQuotePrices: rcv.autoQuotePrices,
                        venuePrices: rcv.venuePrices,
                        latestQuotedPrices: rcv.latestQuotedPrices,
                    },

                    autoQuoteDisplayState,
                    autoQuotePricerState: inquiry.autoQuotePriceState,
                    autoQuotePriceError: inquiry.autoQuotePriceError,
                    autoQuotePriceValidity: inquiry.autoQuotePriceValidity,

                    inquiryState: inquiry.state,
                    isInquiryFinished,
                    leg: leg,
                    isMac: inquiry.isMac,
                }}
                callbacks={{
                    pay: {
                        updateCustomerRate: pay.updateCustomerRate,
                        onCustomerRateDirtyChange: setDirty,
                        updateSpreadValue: pay.updateSpreadValue,
                        onSpreadDirtyChange: setDirty,
                        selectDriver: pay.selectDriver,
                        clearDriverValue: pay.clearDriverValue,
                    },

                    rcv: {
                        updateCustomerRate: rcv.updateCustomerRate,
                        onCustomerRateDirtyChange: setDirty,
                        updateSpreadValue: rcv.updateSpreadValue,
                        onSpreadDirtyChange: setDirty,
                        selectDriver: rcv.selectDriver,
                        clearDriverValue: rcv.clearDriverValue,
                    },

                    updatePtmmValue,
                }}
                legPosition={leg.position}
            />
        );
    } else {
        const legSideView: IRSSideView = leg.side === InquirySide.Buy ? pay : rcv;

        return (
            <IrsSingleSidedLegControl
                config={{
                    clock,
                }}
                model={{
                    customerRateValue: legSideView.customerRateValue,
                    isCustomerRateInputDisabled: isInquiryFinished,
                    isCustomerRateInvalid: legSideView.shouldShowCustomerRateInputAsStale,

                    spreadValue: legSideView.spreadValue,
                    isSpreadInputDisabled: legSideView.spreadInputDisabled,

                    selectedDriver: legSideView.selectedDriver,
                    customerRateDisplayState: legSideView.selectedDriverDisplayState,

                    autoQuotePrices: legSideView.autoQuotePrices,
                    venuePrices: legSideView.venuePrices,

                    inquiryState: inquiry.state,
                    autoQuoteDisplayState,
                    autoQuotePriceError: inquiry.autoQuotePriceError,
                    autoQuotePricerState: inquiry.autoQuotePriceState,
                    autoQuotePriceValidity: inquiry.autoQuotePriceValidity,
                    isInquiryFinished,
                    leg: leg,
                    isMac: inquiry.isMac,
                }}
                callbacks={{
                    updateCustomerRate: legSideView.updateCustomerRate,
                    onCustomerRateDirtyChange: setDirty,

                    updateSpreadValue: legSideView.updateSpreadValue,
                    onSpreadDirtyChange: setDirty,

                    updatePtmmValue,

                    selectDriver: legSideView.selectDriver,
                    clearDriverValue: legSideView.clearDriverValue,
                }}
                legPosition={leg.position}
            />
        );
    }
};

export const IrsOutright: React.FC<IRSOutrightProps> = ({ publishAction, openRiskViewWindow, openCustomerAnalyticsWindow }) => {
    const { clearCircuitBreaker, closeActionFailure } = useIRSStateDispatch();

    useRerenderOnInterval(500); // for timer-based rendering like stale prices

    return (
        <SelectedInquiryContainer>
            <IRSInquiryHeader openCustomerAnalyticsWindow={openCustomerAnalyticsWindow} />
            <IRSInquiryBody>
                <Box marginR={"8"}>
                    <InquiryButtonRow openRiskViewWindow={openRiskViewWindow} />
                    <IRSLegControl />
                </Box>
                <Box marginT={"20"}>
                    <IRSActionButtons publishAction={publishAction} />
                </Box>
            </IRSInquiryBody>
            <IRSInquiryFooter clearCircuitBreaker={clearCircuitBreaker} closeActionFailure={closeActionFailure} />
        </SelectedInquiryContainer>
    );
};
