import { DecimalNumber } from "@transficc/infrastructure";
import { Draft } from "immer";

export function roundToIncrement(number: DecimalNumber, increment: DecimalNumber): DecimalNumber {
    return number.subtract(number.modulo(increment));
}

export function nullableRoundToIncrement(number: DecimalNumber | null, increment: DecimalNumber): DecimalNumber | null {
    if (number === null) {
        return null;
    }
    return roundToIncrement(number, increment);
}

export function decimalToBasisPoints(decimal: Draft<DecimalNumber>): DecimalNumber {
    return decimal.multiplyBy(100);
}

export function basisPointsToDecimal(basisPoints: Draft<DecimalNumber>): DecimalNumber {
    return basisPoints.divideBy(100);
}
