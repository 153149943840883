import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import {
    ActionFailure,
    AllowedAction,
    BenchmarkHedge,
    CounterpartyType,
    HedgingType,
    Inquiry,
    InquirySide,
    InquiryState,
    PricerState,
    PriceType,
    SpotNegotiationModel,
    TriggerEvent,
} from "../credit-domain";
import { AssetClass, VenueType } from "@transficc/trader-desktop-shared-domain";

function mapSide(side: TraderDesktopProtocol.Side): InquirySide {
    switch (side) {
        case TraderDesktopProtocol.Side.Buy:
            return InquirySide.Buy;
        case TraderDesktopProtocol.Side.Sell:
            return InquirySide.Sell;
        default:
            // We don't support Undisclosed for Credit
            // side satisfies never;
            throw new Error("unsupported side: " + side);
    }
}

function mapVenueType(venueType: TraderDesktopProtocol.VenueType | null): VenueType | null {
    if (venueType === null || venueType === undefined) {
        return null;
    }
    switch (venueType) {
        case TraderDesktopProtocol.VenueType.Mtf:
            return VenueType.Mtf;
        case TraderDesktopProtocol.VenueType.Sef:
            return VenueType.Sef;
        case TraderDesktopProtocol.VenueType.OffVenue:
            return VenueType.OffVenue;
        default:
            venueType satisfies never;
            throw new Error("unsupported venueType: " + String(venueType));
    }
}

function mapCounterpartyType(counterpartyType: TraderDesktopProtocol.CounterpartyType | null): CounterpartyType | null {
    if (counterpartyType === null) {
        return null;
    }

    switch (counterpartyType) {
        case TraderDesktopProtocol.CounterpartyType.Customer:
            return CounterpartyType.Customer;
        case TraderDesktopProtocol.CounterpartyType.Dealer:
            return CounterpartyType.Dealer;
        default:
            counterpartyType satisfies never;
            throw new Error("unsupported workflow type: " + String(counterpartyType));
    }
}

function mapOwner(autoXMode: TraderDesktopProtocol.AutoXMode | null): string | null {
    if (autoXMode === null) {
        return "-";
    }

    switch (autoXMode) {
        case TraderDesktopProtocol.AutoXMode.Manual:
            return "-";
        case TraderDesktopProtocol.AutoXMode.AutoExecute:
        case TraderDesktopProtocol.AutoXMode.AutoReject:
            return "Auto";
        case TraderDesktopProtocol.AutoXMode.AutoStream:
            throw new Error(autoXMode + " not supported for credit.");
        default:
            autoXMode satisfies never;
            throw new Error("unsupported state: " + String(autoXMode));
    }
}

export function mapState(state: TraderDesktopProtocol.InquiryState, autoSpotFailed: boolean | null): InquiryState {
    switch (state) {
        case TraderDesktopProtocol.InquiryState.CustomerAccepted:
            return InquiryState.CustomerAccepted;
        case TraderDesktopProtocol.InquiryState.DealerAccepted:
            return InquiryState.DealerAccepted;
        case TraderDesktopProtocol.InquiryState.Done:
            return InquiryState.Done;
        case TraderDesktopProtocol.InquiryState.InquiryError:
            return InquiryState.InquiryError;
        case TraderDesktopProtocol.InquiryState.InquiryPickedUpOnVenueUI:
            return InquiryState.InquiryPickedUpOnVenueUI;
        case TraderDesktopProtocol.InquiryState.LastLook:
            return InquiryState.LastLook;
        case TraderDesktopProtocol.InquiryState.NewRequest:
            return InquiryState.NewRequest;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerReject:
            return InquiryState.CustomerReject;
        case TraderDesktopProtocol.InquiryState.NotDoneCustomerTimeout:
            return InquiryState.CustomerTimeout;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerReject:
            return InquiryState.DealerReject;
        case TraderDesktopProtocol.InquiryState.NotDoneDealerTimeout:
            return InquiryState.DealerTimeout;
        case TraderDesktopProtocol.InquiryState.CurtainPeriod:
            return InquiryState.CurtainPeriod;
        case TraderDesktopProtocol.InquiryState.CurtainQuoteStreaming:
            return InquiryState.CurtainQuoteStreaming;
        case TraderDesktopProtocol.InquiryState.QuoteOtw:
            return InquiryState.QuoteOTW;
        case TraderDesktopProtocol.InquiryState.QuoteSubject:
            return InquiryState.QuoteSubject;
        case TraderDesktopProtocol.InquiryState.QuoteFirm:
            return InquiryState.QuoteFirm;
        case TraderDesktopProtocol.InquiryState.QuoteRefreshRequested:
            return InquiryState.QuoteRefreshRequested;
        case TraderDesktopProtocol.InquiryState.PendingSpot:
            if (autoSpotFailed) {
                return InquiryState.AutoSpotFailed;
            } else {
                return InquiryState.PendingSpot;
            }
        case TraderDesktopProtocol.InquiryState.PendingPriceConfirmation:
            if (autoSpotFailed) {
                return InquiryState.AutoSpotFailed;
            } else {
                return InquiryState.PendingPriceConfirmation;
            }
        case TraderDesktopProtocol.InquiryState.Streaming:
        case TraderDesktopProtocol.InquiryState.TradedPendingFeeAllocation:
            throw new Error(state + " not supported for Credit.");
        default:
            state satisfies never;
            throw new Error("Unsupported state for Credit: " + String(state));
    }
}

const mapPricerState = (pricerState: TraderDesktopProtocol.PricerState): PricerState => {
    switch (pricerState) {
        case TraderDesktopProtocol.PricerState.Error:
            return PricerState.Error;
        case TraderDesktopProtocol.PricerState.Pending:
            return PricerState.Pending;
        case TraderDesktopProtocol.PricerState.Priced:
            return PricerState.Priced;
        case TraderDesktopProtocol.PricerState.Terminated:
            return PricerState.Terminated;
        case TraderDesktopProtocol.PricerState.Invalid:
            return PricerState.Invalid;
        case TraderDesktopProtocol.PricerState.Rejected:
            return PricerState.Rejected;
        default:
            pricerState satisfies never;
            throw new Error();
    }
};

const mapAllowedActions = (allowedActions: TraderDesktopProtocol.ActionType[]): AllowedAction[] => {
    return allowedActions.map((value) => {
        switch (value) {
            case TraderDesktopProtocol.ActionType.DealerAccept:
                return AllowedAction.DealerAccept;
            case TraderDesktopProtocol.ActionType.DealerReject:
                return AllowedAction.DealerReject;
            case TraderDesktopProtocol.ActionType.Quote:
                return AllowedAction.Quote;
            default:
                value satisfies never;
                throw new Error();
        }
    });
};

const mapTriggerEvent = (triggerEvent: TraderDesktopProtocol.TriggerEvent): TriggerEvent => {
    switch (triggerEvent) {
        case TraderDesktopProtocol.TriggerEvent.Counter:
            return TriggerEvent.Counter;
        case TraderDesktopProtocol.TriggerEvent.CurtainPeriodExpired:
            return TriggerEvent.CurtainPeriodExpired;
        case TraderDesktopProtocol.TriggerEvent.CustomerAccept:
            return TriggerEvent.CustomerAccept;
        case TraderDesktopProtocol.TriggerEvent.CustomerCancel:
            return TriggerEvent.CustomerCancel;
        case TraderDesktopProtocol.TriggerEvent.CustomerReject:
            return TriggerEvent.CustomerReject;
        case TraderDesktopProtocol.TriggerEvent.CustomerTimeout:
            return TriggerEvent.CustomerTimeout;
        case TraderDesktopProtocol.TriggerEvent.DealerAccept:
            return TriggerEvent.DealerAccept;
        case TraderDesktopProtocol.TriggerEvent.DealerReject:
            return TriggerEvent.DealerReject;
        case TraderDesktopProtocol.TriggerEvent.DealerTimeout:
            return TriggerEvent.DealerTimeout;
        case TraderDesktopProtocol.TriggerEvent.HitLift:
            return TriggerEvent.HitLift;
        case TraderDesktopProtocol.TriggerEvent.InquiryError:
            return TriggerEvent.InquiryError;
        case TraderDesktopProtocol.TriggerEvent.InquiryPickedUpOnVenue:
            return TriggerEvent.InquiryPickedUpOnVenue;
        case TraderDesktopProtocol.TriggerEvent.LastLookExpired:
            return TriggerEvent.LastLookExpired;
        case TraderDesktopProtocol.TriggerEvent.NegotiationReport:
            return TriggerEvent.NegotiationReport;
        case TraderDesktopProtocol.TriggerEvent.OnTheWireTimeExpired:
            return TriggerEvent.OnTheWireTimeExpired;
        case TraderDesktopProtocol.TriggerEvent.PriceConfirmationAgreed:
            return TriggerEvent.PriceConfirmationAgreed;
        case TraderDesktopProtocol.TriggerEvent.QuoteRefreshRequest:
            return TriggerEvent.QuoteRefreshRequest;
        case TraderDesktopProtocol.TriggerEvent.QuoteRequest:
            return TriggerEvent.QuoteRequest;
        case TraderDesktopProtocol.TriggerEvent.QuoteResponse:
            return TriggerEvent.QuoteResponse;
        case TraderDesktopProtocol.TriggerEvent.ReplaceSpot:
            return TriggerEvent.ReplaceSpot;
        case TraderDesktopProtocol.TriggerEvent.SpotAgreed:
            return TriggerEvent.SpotAgreed;
        case TraderDesktopProtocol.TriggerEvent.TieBreak:
            return TriggerEvent.TieBreak;
        case TraderDesktopProtocol.TriggerEvent.TradedNegotiationReport:
            return TriggerEvent.TradedNegotiationReport;
    }
};

function mapPriceType(priceType: TraderDesktopProtocol.PriceType): PriceType {
    switch (priceType) {
        case TraderDesktopProtocol.PriceType.PercentOfPar:
            return PriceType.PercentOfPar;
        case TraderDesktopProtocol.PriceType.Spread:
            return PriceType.Spread;
        case TraderDesktopProtocol.PriceType.Yield:
            return PriceType.Yield;
        case TraderDesktopProtocol.PriceType.NetPresentValue:
        case TraderDesktopProtocol.PriceType.InterestRate:
            throw new Error("Unknown priceType " + priceType);
    }
}

function mapActionFailure(
    inquiry: TraderDesktopProtocol.Inquiry,
    useId: number,
    currentLatestActionFailure: ActionFailure | undefined | null,
): ActionFailure | null {
    const myActionFailures = inquiry.actionFailures.filter((row) => row.userId === useId);

    if (myActionFailures.length === 0) {
        return null;
    }
    const latestActionFailure = myActionFailures[myActionFailures.length - 1];
    if (latestActionFailure === undefined) {
        return null;
    }
    if (currentLatestActionFailure?.actionId === latestActionFailure.actionId) {
        return currentLatestActionFailure;
    }
    return {
        actionId: latestActionFailure.actionId,
        reason: latestActionFailure.reason,
        closed: false,
    };
}

const mapHedgingType = (hedgingType: TraderDesktopProtocol.HedgingType | null): HedgingType | null => {
    if (hedgingType === null) {
        return null;
    }

    switch (hedgingType) {
        case TraderDesktopProtocol.HedgingType.Spot:
            return HedgingType.Spot;
        case TraderDesktopProtocol.HedgingType.Cross:
            return HedgingType.Cross;
        default:
            hedgingType satisfies never;
            throw new Error();
    }
};

const mapBenchmarkHedge = (benchmarkHedge: TraderDesktopProtocol.BenchmarkHedge | null): BenchmarkHedge | null => {
    switch (benchmarkHedge) {
        case TraderDesktopProtocol.BenchmarkHedge.Auto:
            return BenchmarkHedge.Auto;
        case TraderDesktopProtocol.BenchmarkHedge.Manual:
            return BenchmarkHedge.Manual;
        default:
            return null;
    }
};

const mapSpotNegotiationModel = (spotNegotiationModel: TraderDesktopProtocol.SpotNegotiationModel | null): SpotNegotiationModel | null => {
    if (spotNegotiationModel === null) {
        return null;
    }

    switch (spotNegotiationModel) {
        case TraderDesktopProtocol.SpotNegotiationModel.Venuepriced:
            return SpotNegotiationModel.VenuePriced;
        case TraderDesktopProtocol.SpotNegotiationModel.VenuepricedDelayed:
            return SpotNegotiationModel.VenuePricedDelayed;
        default:
            spotNegotiationModel satisfies never;
            throw new Error();
    }
};

const mapListId = (inquiry: TraderDesktopProtocol.Inquiry): string | null => {
    if (inquiry.listId === null || inquiry.numberOfListItems === null || inquiry.numberOfListItems <= 1) {
        return null;
    }
    return inquiry.listId;
};

export const protocolToCreditMapper = (
    inquiry: TraderDesktopProtocol.Inquiry,
    userId: number,
    currentDomainInquiry: Inquiry | null,
): Inquiry => {
    const leg = inquiry.legs[0];
    if (!leg) {
        throw new Error("Expected one leg for credit");
    }

    const state = mapState(inquiry.state, inquiry.autoSpotFailed);
    return {
        userId,
        eventSequence: inquiry.eventSequence,
        ticketId: inquiry.ticketId,
        venueSpecifiedOnTheWireTimeMillis: inquiry.venueSpecifiedOnTheWireTimeMillis,
        aggregateId: inquiry.aggregateId,
        allowedActions: mapAllowedActions(inquiry.allowedActions),
        state: state,
        triggerEvent: mapTriggerEvent(inquiry.triggerEvent),
        tier: inquiry.priceTier,
        counterpartyFirm: inquiry.counterpartyFirm,
        counterpartyTrader: inquiry.counterpartyTrader,
        salesPersonId: inquiry.salesPersonId,
        salesPersonName: inquiry.salesPersonName,
        timerStartTimestampMillis: inquiry.timerStartTimestampMillis,
        timerEndTimestampMillis: inquiry.timerEndTimestampMillis,
        venueName: inquiry.venueName,
        venueType: mapVenueType(inquiry.venueType),
        assetClass: AssetClass.Credit,
        numberOfDealers: inquiry.numberOfDealers,
        isAllToAll: inquiry.isAllToAll,
        isCounterpartyAnonymous: inquiry.isCounterpartyAnonymous,
        counterpartyType: mapCounterpartyType(inquiry.counterpartyType),
        counterpartyRank: inquiry.counterpartyRank,
        latestActionFailure: mapActionFailure(inquiry, userId, currentDomainInquiry?.latestActionFailure),
        owner: mapOwner(inquiry.autoXMode),
        leg: {
            position: leg.position,
            referenceId: leg.referenceId,
            side: mapSide(leg.side),
            instrumentName: leg.instrumentName,
            quantity: leg.quantity,
            priceType: mapPriceType(leg.priceType),
            currency: leg.currency,
            settlementDate: leg.settlementDate,
            relativeSettlementDate: leg.relativeSettlementDate,
            minPriceIncrement: leg.minPriceIncrement,
            latestQuotedPrices: {
                bid: leg.latestQuotedBidPrice,
                mid: leg.latestQuotedMidPrice,
                ask: leg.latestQuotedAskPrice,
            },
            autoQuotedPrices: {
                bid: leg.pricerBid,
                mid: leg.pricerMid,
                ask: leg.pricerAsk,
            },
            customerBidPrice: leg.customerBidPrice,
            customerAskPrice: leg.customerAskPrice,
            negotiatedPrice: leg.negotiatedPrice,
            benchmarkName: leg.benchmarkName,
            tieBreakPrice: leg.tieBreakPrice,
        },
        autoQuotePriceState: mapPricerState(inquiry.pricerState),
        autoQuotePriceError: inquiry.pricerError?.length ? inquiry.pricerError : null,
        autoQuotePricesGenerationTimestampNanos: inquiry.priceGenerationTimestampNanos,
        autoQuotePricesValidForNanos: inquiry.priceValidForNanos,
        isFromSnapshot: inquiry.updateType === TraderDesktopProtocol.UpdateType.Snapshot,
        hedgingType: mapHedgingType(inquiry.hedgingType),
        spotNegotiationModel: mapSpotNegotiationModel(inquiry.spotNegotiationModel),
        spotRequestTimeNanos: inquiry.spotRequestTimeNanos,
        listId: mapListId(inquiry),
        numberOfTotalListItems: inquiry.numberOfListItems,
        quoteIsAlwaysFirm: inquiry.quoteIsAlwaysFirm,
        creationTime: Number(inquiry.inquiryCreationTimestampNanos),
        benchmarkHedge: mapBenchmarkHedge(inquiry.benchmarkHedge),
    };
};
