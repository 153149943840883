import { IRSCurvePackageControlProps } from "./irs-curve-package-control";
import { DecimalNumber } from "@transficc/infrastructure";
import { calculateFontSize } from "../irs-price-driver/irs-price-driver";
import { Box, DataMatrix, DataMatrixCellData, DataMatrixProps, formatWithCommas, Text } from "@transficc/components";

function display(price: DecimalNumber | null): string {
    return formatWithCommas(price?.toString() ?? "-");
}

const dataMatrixCellWidth: Theme.SpacingLevels = "24";

const DEFAULT_CELL_FONT_SIZE: Theme.FontSizes = "sm";

export const PackagePriceMatrix: React.FC<IRSCurvePackageControlProps> = ({ model }) => {
    const dataMatrixConfig: DataMatrixProps["config"] = {
        cellWidth: dataMatrixCellWidth,
        cellHeight: "6",
        cellFontSize: DEFAULT_CELL_FONT_SIZE,
        cellBorderWidth: "0.5",
        cellBackgroundColor: { color: "gray", level: "900" },
        cellForegroundColor: { color: "gray", level: "300" },
        rowLabelPosition: "left",
        rowConfig: [
            { label: "Q", labelSize: "sm" },
            { label: "AQ", labelSize: "sm" },
            { label: "V", labelSize: "sm" },
        ],
        columnConfig: [{}, {}, {}],
    };

    const data: DataMatrixCellData[][] = [
        [{ value: display(model.pay.quoted) }, { value: display(model.quotedMid) }, { value: display(model.rcv.quoted) }],
        [{ value: display(model.pay.aqLevel) }, { value: display(model.aqMid) }, { value: display(model.rcv.aqLevel) }],
        [{ value: null }, { value: display(model.venueMid) }, { value: null }],
    ];

    const formattedData: DataMatrixCellData[][] = data.map((row: DataMatrixCellData[]) => {
        return row.map((rowItem: DataMatrixCellData) => {
            return {
                ...rowItem,
                cellFontSize: calculateFontSize(rowItem.value),
            };
        });
    });

    return (
        <Box paddingR={"24"} paddingT={"2"} data-testid="irs-package-price-matrix">
            <Text srOnly>Price Matrix</Text>
            <DataMatrix disabledSelection={true} config={dataMatrixConfig} data={formattedData} onClickCell={() => {}} />
        </Box>
    );
};
