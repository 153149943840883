import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useInquiriesSubscription } from "./redux/hooks/useInquiriesSubscription";
import { Box } from "@transficc/components";
import { EpochClock } from "@transficc/infrastructure";
import {
    ActiveBlotterController,
    ActiveBlotterPanel,
    ActiveBlotterPanelApi,
    InquiryData,
    IRSDomain,
    useAllIRSInquiries,
} from "@transficc/trader-desktop-irs";
import { useClock, useElectronApi, useEnvironmentConfig } from "@transficc/trader-desktop-application-context";
import { openWindow } from "./utils/openWindow";
import { useIsLoading } from "./redux/slices/LoadingStatusSlice";
import { Loading } from "./Loading";
import { FrameName } from "@transficc/trader-desktop-electron-api";

const filters = [
    {
        name: "All",
        statuses: [
            IRSDomain.InquiryStatus.Done,
            IRSDomain.InquiryStatus.NotDone,
            IRSDomain.InquiryStatus.Active,
            IRSDomain.InquiryStatus.Error,
        ],
    },
    { name: "Active", statuses: [IRSDomain.InquiryStatus.Active] },
    {
        name: "Ended",
        statuses: [IRSDomain.InquiryStatus.Done, IRSDomain.InquiryStatus.NotDone, IRSDomain.InquiryStatus.Error],
    },
    { name: "Done", statuses: [IRSDomain.InquiryStatus.Done] },
    { name: "Not Done", statuses: [IRSDomain.InquiryStatus.NotDone] },
    { name: "Error", statuses: [IRSDomain.InquiryStatus.Error] },
];

const filterNames = filters.map((filter) => filter.name);

const hasValidStatus = (inquiryData: InquiryData, statuses: IRSDomain.InquiryStatus[]): boolean => {
    return statuses.includes(inquiryData.latestInquiry.status);
};

interface ActiveBlotterPanelWithInquiriesProps {
    clock: EpochClock;
    onReady: (api: ActiveBlotterPanelApi) => void;
    shownStatuses: IRSDomain.InquiryStatus[];
}

const ActiveBlotterPanelWithInquiries: React.FunctionComponent<ActiveBlotterPanelWithInquiriesProps> = ({
    clock,
    onReady,
    shownStatuses,
}) => {
    const allIRSInquiries = useAllIRSInquiries();

    const filteredIRSInquiries = useMemo(() => {
        return allIRSInquiries.filter((inquiryData) => hasValidStatus(inquiryData, shownStatuses)).map((x) => x.latestInquiry);
    }, [allIRSInquiries, shownStatuses]);

    return <ActiveBlotterPanel filteredInquiries={filteredIRSInquiries} clock={clock} onReady={onReady} />;
};

const ActiveBlotter: React.FC = () => {
    const environmentConfig = useEnvironmentConfig();
    const electronApi = useElectronApi();
    const clock = useClock();
    const hdsBlotterButtonEnabled = environmentConfig.featureFlags.hdsBlotter ?? false;
    const blotterPanelApi: React.MutableRefObject<ActiveBlotterPanelApi | null> = useRef<ActiveBlotterPanelApi | null>(null);

    const [statuses, setStatuses] = useState(filters[0]?.statuses ?? []);

    useInquiriesSubscription();

    const onChange = useCallback((name: string) => {
        filters.some((filter) => {
            if (filter.name === name) {
                setStatuses(filter.statuses);
                return true;
            }
            return false;
        });
    }, []);

    const onDownloadCsv = useCallback(() => {
        blotterPanelApi.current?.downloadCsv("IRS-inquiries.csv");
    }, [blotterPanelApi]);

    const onOpenHistoricalBlotter = useCallback(() => {
        openWindow({
            electronApi,
            url: "/popup/historical-blotter",
            target: FrameName.HISTORICAL_BLOTTER,
            electronOpenHidden: false,
            popup: false,
        });
    }, [electronApi]);

    const onReady = useCallback((api: ActiveBlotterPanelApi) => {
        blotterPanelApi.current = api;
    }, []);

    const loading = useIsLoading();

    if (loading) {
        return <Loading />;
    }

    return (
        <Box data-testid="blotter" height={"full"}>
            <ActiveBlotterController
                filters={filterNames}
                onChange={onChange}
                onDownloadCsv={onDownloadCsv}
                onOpenHistoricalBlotter={onOpenHistoricalBlotter}
                hdsBlotterButtonEnabled={hdsBlotterButtonEnabled}
            />
            <ActiveBlotterPanelWithInquiries clock={clock} onReady={onReady} shownStatuses={statuses} />
        </Box>
    );
};

export default ActiveBlotter;
