import { Box, Button, IconNames, RadioBar, semanticButtonStyling } from "@transficc/components";
import * as React from "react";
import { useCallback, useState } from "react";

interface ActiveBlotterControllerProps {
    filters: string[];
    onChange: (filter: string) => void;
    onDownloadCsv: () => void;
    onOpenHistoricalBlotter: () => void;
    hdsBlotterButtonEnabled: boolean;
}

export const ActiveBlotterController: React.FunctionComponent<ActiveBlotterControllerProps> = ({
    filters,
    onChange,
    onDownloadCsv,
    onOpenHistoricalBlotter,
    hdsBlotterButtonEnabled,
}) => {
    const [active, setActive] = useState(filters[0] ?? null);

    const onClick = useCallback(
        (filter: string): void => {
            setActive(filter);
            onChange(filter);
        },
        [onChange],
    );

    return (
        <Box margin={"2"} contentAlignment="center" contentDirection="row">
            <RadioBar values={filters} selectedValue={active} onClick={onClick} joined buttonWidth="32" />
            <Box contentDirection="row" marginL="auto">
                <Button styling={semanticButtonStyling("info")} size="sm" title="Download CSV" onClick={onDownloadCsv} />
                {hdsBlotterButtonEnabled && (
                    <Box marginL="1">
                        <Button
                            styling={semanticButtonStyling("info")}
                            size="sm"
                            title="Historical Blotter"
                            onClick={onOpenHistoricalBlotter}
                            rightIcon={{ name: IconNames.NewWindow, size: "4", iconColor: { color: "white" } }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
