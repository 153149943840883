import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";
import { ErrorInfo } from "react";
import styled from "styled-components";
import { ActiveInquiries, ActiveInquiriesProps } from "./active-inquiries";
import { CouldNotRenderInquiry, OpenCustomerAnalytics, SelectedCreditInquiry } from "../credit-inquiry/selected-credit-inquiry";
import { useSelectedPrimaryInquiryTicketId } from "../credit-slice/useCreditSliceSelectors";
import { Box, ErrorBoundary, Text } from "@transficc/components";

export interface CreditDockProps {
    clock: EpochClock;
    timezoneProvider: TimezoneProvider;
    actionPublisher: ActionPublisher.ActionPublisher;
    onVolumeToggle: ActiveInquiriesProps["onVolumeToggle"];
    volumeIsOn: ActiveInquiriesProps["volumeIsOn"];
    connectionStatus: ActiveInquiriesProps["connectionStatus"];
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
    unexpectedErrorHandler: (error: Error, errorInfo: ErrorInfo) => unknown;
    showCustomerAnalytics: boolean;
    openCustomerAnalyticsWindow: OpenCustomerAnalytics;
}

const DockContainer = styled(Box)`
    height: 100vh;
    display: flex;
    flex-direction: row;
`;

export const CreditDock: React.FC<CreditDockProps> = ({
    clock,
    timezoneProvider,
    actionPublisher,
    volumeIsOn,
    connectionStatus,
    onVolumeToggle,
    onTradingStatusChanged,
    tradingStatus,
    serverTradingStatus,
    unexpectedErrorHandler,
    showCustomerAnalytics,
    openCustomerAnalyticsWindow,
}) => {
    const selectedInquiryTicketId = useSelectedPrimaryInquiryTicketId();

    return (
        <DockContainer data-testid="dock-container">
            <ErrorBoundary
                key={selectedInquiryTicketId /* TODO: do we need to do this anymore?? */}
                renderError={() => (
                    <Box>
                        <CouldNotRenderInquiry reason="unexpected error" />
                    </Box>
                )}
                reportError={unexpectedErrorHandler}
            >
                <SelectedCreditInquiry
                    config={{
                        clock: clock,
                        timezoneProvider: timezoneProvider,
                        actionPublisher: actionPublisher,
                        showCustomerAnalytics: showCustomerAnalytics,
                    }}
                    callbacks={{ openCustomerAnalyticsWindow: openCustomerAnalyticsWindow }}
                />
            </ErrorBoundary>
            <ErrorBoundary
                renderError={() => (
                    <Box>
                        <Box rounded backgroundColor={{ color: "danger", level: "600" }} padding={"4"}>
                            <Text textColor={{ color: "white" }}>Error: Could not render active inquiries due to unexpected error</Text>
                        </Box>
                    </Box>
                )}
                reportError={unexpectedErrorHandler}
            >
                <ActiveInquiries
                    connectionStatus={connectionStatus}
                    volumeIsOn={volumeIsOn}
                    onVolumeToggle={onVolumeToggle}
                    onTradingStatusChanged={onTradingStatusChanged}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                    clock={clock}
                    timezoneProvider={timezoneProvider}
                />
            </ErrorBoundary>
        </DockContainer>
    );
};
