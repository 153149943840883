import styled from "styled-components";
import { Box, BoxProps, Button, ConnectionStatus, IconNames, semanticButtonStyling, Text, TransficcLogo } from "@transficc/components";
import { TradingStatusToggle } from "@transficc/trader-desktop-shared-components";

export interface TraderDesktopNavigationBarLinkProps {
    title: string;
    hoverText?: string;
    onClick: () => void;
}

export interface TraderDesktopNavigationBarProps {
    userName: string;
    version: string;
    connectionStatus: "connected" | "disconnected";
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
    links: TraderDesktopNavigationBarLinkProps[];
    onLogout: () => void;

    onTradingStatusChanged: (toggledOn: boolean) => void;
    userHoverText: string;
}

export const TraderDesktopNavigationBar: React.FC<TraderDesktopNavigationBarProps> = ({
    userName,
    version,
    links,
    connectionStatus,
    tradingStatus,
    serverTradingStatus,
    onLogout,
    onTradingStatusChanged,
    userHoverText,
}) => {
    return (
        <NavBarContainer>
            <Box minWidth={"48"} contentAlignment={"center"}>
                <TransficcLogo />
                <Box marginT={"1"}>
                    <Text size={"xs"} weight={"thin"} textColor={{ color: "gray", level: "500" }}>
                        Application version: {version.replace("-unstable.", "-")}
                    </Text>
                </Box>
            </Box>
            {links.map((link) => {
                return (
                    <Box marginL="4" key={link.title}>
                        <Button
                            title={link.title}
                            hoverTitle={link.hoverText}
                            onClick={link.onClick}
                            styling={semanticButtonStyling("info")}
                            size={"md"}
                            rightIcon={{ name: IconNames.NewWindow, size: "5", iconColor: { color: "white" } }}
                        />
                    </Box>
                );
            })}

            <Box marginL="auto">
                <TradingStatusToggle
                    onTradingStatusChanged={onTradingStatusChanged}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                    connectionStatus={connectionStatus}
                />
            </Box>

            <Box marginL="auto">
                <ConnectionStatus connectionStatus={connectionStatus} size={"8"} />
            </Box>
            <Box title={userHoverText} marginL="4">
                <Text>{userName}</Text>
            </Box>
            <Box marginL="4">
                <Button title={"Logout"} styling={semanticButtonStyling("warning")} size={"sm"} onClick={onLogout} />
            </Box>
        </NavBarContainer>
    );
};

const NavBarContainer = styled(Box).attrs(
    (): BoxProps => ({
        width: "full",
        margin: "auto",
        contentDirection: "row",
        contentAlignment: "center",
    }),
)`
    flex-wrap: wrap;
    max-width: 132rem;
`;
