import {
    Box,
    BoxProps,
    Button,
    formatDateTimeAsString,
    formatPrice,
    Icon,
    IconNames,
    InlineTimerProgress,
    RadioBar,
    semanticButtonStyling,
    Text,
    TextInput,
    ThemeUtils,
} from "@transficc/components";
import styled, { createGlobalStyle, DefaultTheme, useTheme } from "styled-components";
import { CounterpartyType, InquirySide, InquiryState, PriceType, SpotNegotiationModel } from "../credit-domain";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    getSideBackgroundColor,
    getSideForegroundColor,
    getStateBarBackgroundColor,
    getStateBarForegroundColor,
    getStateDisplayText,
} from "../credit-domain/constants";
import {
    InquiryRowData,
    useActiveCreditInquiriesForRowData,
    useSelectedPrimaryInquiryTicketId,
} from "../credit-slice/useCreditSliceSelectors";
import { useCreditStateDispatch } from "../credit-slice/useCreditStateDispatch";
import { EpochClock, TimezoneProvider } from "@transficc/infrastructure";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
    AdvancedFilterModel,
    CellClassParams,
    ColDef,
    FilterModel,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
    ITooltipParams,
    SelectionChangedEvent,
    ToolPanelDef,
    ValueFormatterParams,
    ValueGetterParams,
} from "ag-grid-community";
import { formatQuantity } from "../formatters/formatQuantity";
import { formatSpotRequestTime } from "../formatters/formatSpotRequestTime";
import { DateTime } from "luxon";
import { ActiveInquiriesBottomBar, ActiveInquiriesBottomBarProps } from "@transficc/trader-desktop-shared-components";
import { AG_GRID_CLASS_NAME, AG_GRID_LICENSE, AgGridTheme, saveAndRestoreColumnState } from "@transficc/trader-desktop-ag-grid-common";
import { LocalStorageKeys } from "@transficc/trader-desktop-local-storage";

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

const ActiveInquiriesContainer = styled(Box).attrs(
    (): BoxProps => ({
        borderL: { width: "px", color: { color: "gray", level: "500" } },
    }),
)`
    width: 100%;
    min-width: min(72rem, 100vw);
    height: calc(100%);
`;

const ActiveInquiriesHeader = styled(Box).attrs(
    (): BoxProps => ({
        padding: "2",
        backgroundColor: { color: "cyan", level: "700" },
    }),
)``;

const ActiveInquiriesList = styled(Box)`
    height: 100%;
    overflow-y: auto;
`;

const ActiveInquiriesListTheme = createGlobalStyle`
    .ag-root-wrapper {
        border: none;
    }

    .ag-header-cell {
        justify-content: center;
        text-align: center;
        padding: 0;
    }

    .ag-header-cell-label {
        justify-content: center;
        padding: 0;
    }

    .ag-cell-value {
        padding: 0;
        border: none;
    }

    .no-inquiries-main-table {
        display: none;
    }

    .ag-row-selected {
        font-weight: bold;
    }

    .ag-row:hover {
        cursor: pointer;
    }
`;

const ActiveInquiriesFooter = styled(Box)``;

const fontSize: Theme.FontSizes = "sm";

const CounterpartyRankContainer = styled(Box)`
    position: relative;

    width: 1.75rem;
    height: 1.75rem;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    ${Text} {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 50%; /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */

        transform: translate(-50%, -50%); /* This is a shorthand of translateX(-50%) and translateY(-50%) */
    }
`;

const CounterpartyRank: React.FC<{ rank: number | null }> = ({ rank }) => {
    if (rank === null || rank < 1) {
        return null;
    }

    return (
        <CounterpartyRankContainer data-testid={"counterparty-rank"}>
            <Icon name={IconNames.Star} iconColor={{ color: "warning", level: "300" }} size={"7"} />
            <Text textColor={{ color: "black" }} size={"sm"} weight={"semibold"}>
                {rank}
            </Text>
        </CounterpartyRankContainer>
    );
};

const AnonymousCounterpartyContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 13rem;
    margin: 0 auto;
`;

const AnonymousCounterpartyType = styled.div`
    flex: 4;
    text-align: left;
`;
const AnonymousCounterpartyRank = styled.div`
    flex: 1;
`;

function timestampNanosToDateTime(value: number): DateTime {
    return DateTime.fromMillis(value / 1_000_000);
}

function renderCounterparty(params: ICellRendererParams<TableRow, CounterpartyCell>): React.ReactNode | string {
    const counterpartyCell = params.value;
    if (!counterpartyCell) {
        return "";
    }
    if (counterpartyCell.isCounterpartyAnonymous) {
        if (counterpartyCell.counterpartyType === CounterpartyType.Dealer) {
            return (
                <AnonymousCounterpartyContainer>
                    <AnonymousCounterpartyType>
                        Anonymous{" "}
                        <Text as="span" weight={"semibold"} size={fontSize} textColor={{ color: "warning", level: "300" }}>
                            Dealer
                        </Text>
                    </AnonymousCounterpartyType>
                    <AnonymousCounterpartyRank>
                        <CounterpartyRank rank={counterpartyCell.counterpartyRank} />
                    </AnonymousCounterpartyRank>
                </AnonymousCounterpartyContainer>
            );
        } else {
            return (
                <AnonymousCounterpartyContainer>
                    <AnonymousCounterpartyType>Anonymous Customer</AnonymousCounterpartyType>
                    <AnonymousCounterpartyRank>
                        <CounterpartyRank rank={counterpartyCell.counterpartyRank} />
                    </AnonymousCounterpartyRank>
                </AnonymousCounterpartyContainer>
            );
        }
    } else {
        return counterpartyCell.counterpartyFirm;
    }
}

function getSideDisplayText(side: InquirySide | "-"): string {
    switch (side) {
        case "-":
            return "-";
        case InquirySide.Sell:
            return "SELL";
        case InquirySide.Buy:
            return "BUY";
        default:
            side satisfies never;
            throw new Error();
    }
}

function getPriceTypeDisplayText(priceType: PriceType): string {
    switch (priceType) {
        case PriceType.PercentOfPar:
            return "Price";
        case PriceType.Yield:
            return "Yield";
        case PriceType.Spread:
            return "Spread";
        default:
            priceType satisfies never;
            throw new Error();
    }
}

function renderSide(params: ICellRendererParams<TableRow, InquirySide | "-">): React.ReactNode | string {
    if (params.value) {
        const side = params.value;
        return getSideDisplayText(side);
    } else {
        return "";
    }
}

function renderState(cell: InquiryStateCell): string {
    if (cell && cell.inquiryState) {
        const state = cell.inquiryState;
        const stateDisplayText = getStateDisplayText(state);
        return getStateRowText(stateDisplayText, state, cell.spotRequestTimeNanos, cell.spotNegotiationModel, cell.timezoneProvider);
    }
    return "";
}

export function getStateRowText(
    stateDisplayText: string,
    state: InquiryState,
    spotRequestTimeNanos: string | null,
    spotNegotiationModel: SpotNegotiationModel | null,
    timezoneProvider: TimezoneProvider,
): string {
    if (
        state === InquiryState.PendingSpot &&
        spotRequestTimeNanos !== null &&
        spotNegotiationModel === SpotNegotiationModel.VenuePricedDelayed
    ) {
        const spotRequestTimeFormatted = formatSpotRequestTime(spotRequestTimeNanos, timezoneProvider);
        return `${stateDisplayText} (${spotRequestTimeFormatted})`;
    }

    return stateDisplayText;
}

function renderTimer(params: ICellRendererParams<TableRow, ResponseTimeCell>): React.ReactNode | string {
    if (params.value && params.value.start !== null && params.value.end !== null) {
        return (
            <InlineTimerProgress
                startTimeEpochMs={parseInt(params.value.start)}
                endTimeEpochMs={parseInt(params.value.end)}
                updateIntervalMs={200}
                countdownTextProps={{ weight: "normal" }}
                clock={params.value.clock}
            />
        );
    } else {
        return <Text srOnly>Timer is unavailable</Text>;
    }
}

const inquiryRow = (inquiry: InquiryRowData, clock: EpochClock, theme: DefaultTheme, timezoneProvider: TimezoneProvider): TableRow => {
    return {
        rowId: `row-${inquiry.aggregateId}`,
        ticketId: inquiry.ticketId,
        counterparty: {
            counterpartyFirm: inquiry.counterpartyFirm,
            counterpartyType: inquiry.counterpartyType,
            counterpartyRank: inquiry.counterpartyRank,
            isCounterpartyAnonymous: inquiry.isCounterpartyAnonymous,
        },
        instrument: inquiry.leg.instrumentName,
        side: inquiry.leg.side,
        size: inquiry.leg.quantity,
        state: {
            inquiryState: inquiry.state,
            spotRequestTimeNanos: inquiry.spotRequestTimeNanos,
            spotNegotiationModel: inquiry.spotNegotiationModel,
            timezoneProvider: timezoneProvider,
        },
        level: inquiry.level,
        minPriceIncrement: inquiry.leg.minPriceIncrement,
        priceType: getPriceTypeDisplayText(inquiry.leg.priceType),
        responseTime: { clock: clock, start: inquiry.timerStartTimestampMillis, end: inquiry.timerEndTimestampMillis },
        theme,
        owner: inquiry.owner,
        creationTime: inquiry.creationTime,
        listId: inquiry.listId ?? "-",
    };
};

export interface ActiveInquiriesProps {
    onVolumeToggle: ActiveInquiriesBottomBarProps["onVolumeToggle"];
    volumeIsOn: ActiveInquiriesBottomBarProps["volumeIsOn"];
    connectionStatus: ActiveInquiriesBottomBarProps["connectionStatus"];
    onTradingStatusChanged: (toggledOn: boolean) => void;
    tradingStatus: "on" | "off";
    serverTradingStatus: "on" | "off";
    clock: EpochClock;
    timezoneProvider: TimezoneProvider;
}

interface CounterpartyCell {
    counterpartyFirm: string;
    counterpartyRank: number | null;
    counterpartyType: CounterpartyType | null;
    isCounterpartyAnonymous: boolean | null;
}

interface InquiryStateCell {
    inquiryState: InquiryState;
    spotRequestTimeNanos: string | null;
    spotNegotiationModel: SpotNegotiationModel | null;
    timezoneProvider: TimezoneProvider;
}

interface ResponseTimeCell {
    start: string | null;
    end: string | null;
    clock: EpochClock;
}

interface TableRow {
    ticketId: number;
    rowId: string;
    counterparty: CounterpartyCell;
    instrument: string;
    side: InquirySide | "-";
    size: string;
    state: InquiryStateCell;
    level: string | null;
    minPriceIncrement: string;
    priceType: string;
    responseTime: ResponseTimeCell;
    theme: DefaultTheme;
    owner: string | null;
    creationTime: number;
    listId: string | null;
}

const counterPartyTypeOrder = [CounterpartyType.Customer, CounterpartyType.Dealer];
const inquiryStateOrder = [
    InquiryState.NewRequest,
    InquiryState.CurtainPeriod,
    InquiryState.CurtainQuoteStreaming,
    InquiryState.QuoteOTW,
    InquiryState.QuoteFirm,
    InquiryState.QuoteSubject,
    InquiryState.LastLook,
    InquiryState.QuoteRefreshRequested,
    InquiryState.CustomerAccepted,
    InquiryState.DealerAccepted,
    InquiryState.PendingSpot,
    InquiryState.AutoSpotFailed,
    InquiryState.PendingPriceConfirmation,
    InquiryState.Done,
    InquiryState.CustomerReject,
    InquiryState.CustomerTimeout,
    InquiryState.DealerReject,
    InquiryState.DealerTimeout,
    InquiryState.InquiryPickedUpOnVenueUI,
    InquiryState.InquiryError,
];

function compareByRank(a: number | null, b: number | null): number {
    return (b ?? 0) - (a ?? 0);
}

function compareByCounterPartyType(a: CounterpartyCell, b: CounterpartyCell): number {
    if (a.counterpartyType === b.counterpartyType) {
        return compareByRank(a.counterpartyRank, b.counterpartyRank);
    }
    if (a.counterpartyType && b.counterpartyType) {
        return counterPartyTypeOrder.indexOf(a.counterpartyType) - counterPartyTypeOrder.indexOf(b.counterpartyType);
    }
    return 0;
}

function counterPartyComparator(a: CounterpartyCell, b: CounterpartyCell): number {
    if (a.isCounterpartyAnonymous && b.isCounterpartyAnonymous) {
        return compareByCounterPartyType(a, b);
    } else if (!a.isCounterpartyAnonymous && b.isCounterpartyAnonymous) {
        return -1;
    } else if (a.isCounterpartyAnonymous && !b.isCounterpartyAnonymous) {
        return 1;
    }

    return a.counterpartyFirm.localeCompare(b.counterpartyFirm);
}

function stateComparator(a: InquiryStateCell, b: InquiryStateCell): number {
    return inquiryStateOrder.indexOf(a.inquiryState) - inquiryStateOrder.indexOf(b.inquiryState);
}

function formatCounterParty(counterParty: CounterpartyCell): string {
    if (counterParty.isCounterpartyAnonymous) {
        if (counterParty.counterpartyType === CounterpartyType.Dealer) {
            return `Anonymous Dealer (rank ${counterParty.counterpartyRank ?? "unknown"})`;
        } else {
            return `Anonymous Customer (rank ${counterParty.counterpartyRank ?? "unknown"})`;
        }
    } else {
        return counterParty.counterpartyFirm;
    }
}

const columnDefs: ColDef[] = [
    {
        headerName: "Customer Firm",
        field: "counterparty",
        tooltipValueGetter: (params: ITooltipParams<TableRow, CounterpartyCell>) => {
            if (!params.value) {
                return "";
            }

            if (params.value.isCounterpartyAnonymous) {
                if (params.value.counterpartyType === CounterpartyType.Dealer) {
                    return `Anonymous Dealer (rank ${params.value.counterpartyRank ?? "unknown"})`;
                } else {
                    return `Anonymous Customer (rank ${params.value.counterpartyRank ?? "unknown"})`;
                }
            } else {
                return params.value.counterpartyFirm;
            }
        },
        colId: "counterparty",
        cellRenderer: renderCounterparty,
        cellStyle: { textAlign: "center", justifyContent: "center", paddingLeft: "0.5rem" },
        flex: 6,
        minWidth: 240,
        comparator: counterPartyComparator,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        filterValueGetter: (value: ValueGetterParams<TableRow>) => {
            const counterParty = value.data?.counterparty;
            if (counterParty) {
                return formatCounterParty(counterParty);
            }
            return null;
        },
        valueFormatter: (value: ValueFormatterParams<TableRow, CounterpartyCell>) => {
            const counterParty = value.data?.counterparty;
            if (counterParty) {
                return formatCounterParty(counterParty);
            }
            return "";
        },
    },
    {
        headerName: "Instrument",
        field: "instrument",
        tooltipField: "instrument",
        colId: "instrument",
        cellStyle: { textAlign: "center" },
        flex: 8,
        minWidth: 240,
        filter: "agTextColumnFilter",
        floatingFilter: true,
    },
    {
        headerName: "Side",
        field: "side",
        colId: "side",
        cellRenderer: renderSide,
        cellStyle: (params: CellClassParams<TableRow, InquirySide | "-">) => {
            const value = params.value;
            const data = params.data;
            const style = { textAlign: "center" };
            if (value && value !== "-" && data) {
                const backgroundColor = ThemeUtils.colorSelector(data.theme, getSideBackgroundColor(value));
                const foregroundColor = ThemeUtils.colorSelector(data.theme, getSideForegroundColor(value));
                return { ...style, backgroundColor: backgroundColor, color: foregroundColor };
            }
            return style;
        },
        flex: 1,
        minWidth: 100,
        filter: true,
        floatingFilter: true,
    },
    {
        headerName: "Size",
        field: "size",
        colId: "size",
        cellRenderer: (params: CellClassParams<TableRow, string>) => (
            <div data-testid={"quantity"}>{params.value ? formatQuantity(params.value) : ""}</div>
        ),
        cellStyle: { textAlign: "center" },
        flex: 2,
        minWidth: 160,
        comparator: (a: string, b: string): number => {
            return Number(b) - Number(a);
        },
        filter: "agNumberColumnFilter",
        cellDataType: "number",
        floatingFilter: true,
        filterValueGetter: (value: ValueGetterParams<TableRow>) => {
            return value.data ? Number(value.data?.size) / 1000 : null;
        },
    },
    {
        headerName: "Level",
        field: "level",
        colId: "level",
        cellRenderer: (params: ICellRendererParams<TableRow, string | null>) => {
            if (params.data?.minPriceIncrement && params.value) {
                return formatPrice(params.value, params.data.minPriceIncrement);
            } else {
                return "-";
            }
        },
        cellStyle: { textAlign: "center " },
        flex: 2,
        minWidth: 112,
        sortable: false,
    },
    {
        headerName: "Price Type",
        field: "priceType",
        colId: "priceType",
        cellRenderer: (params: ICellRendererParams<TableRow, string>) => params.value,
        cellStyle: { textAlign: "center" },
        flex: 2,
        minWidth: 160,
        filter: true,
        floatingFilter: true,
    },
    {
        headerName: "State",
        field: "state",
        colId: "state",
        cellStyle: (params: CellClassParams<TableRow, InquiryStateCell>) => {
            if (params.value) {
                const value = params.value.inquiryState;
                const data = params.data;
                const style = {
                    textAlign: "center",
                    fontSize: "80%",
                };
                if (data) {
                    const backgroundColor = ThemeUtils.colorSelector(data.theme, getStateBarBackgroundColor(value));
                    const foregroundColor = ThemeUtils.colorSelector(data.theme, getStateBarForegroundColor(value));
                    return { ...style, backgroundColor: backgroundColor, color: foregroundColor };
                }
                return style;
            } else {
                return null;
            }
        },
        flex: 2,
        minWidth: 240,
        comparator: stateComparator,
        filter: true,
        floatingFilter: true,
        filterParams: {
            values: inquiryStateOrder,
            valueFormatter: (value: ValueFormatterParams<null, InquiryState>) => {
                if (value.value) {
                    return getStateDisplayText(value.value);
                }
                return "";
            },
        },
        valueFormatter: (value: ValueFormatterParams<TableRow, InquiryStateCell | InquiryState>) => {
            if (value.value) {
                if (typeof value.value === "string") {
                    return getStateDisplayText(value.value);
                }
                return renderState(value.value);
            }
            return "";
        },
    },
    {
        headerName: "Time",
        field: "responseTime",
        colId: "responseTime",
        cellRenderer: renderTimer,
        sort: "asc",
        comparator: (valueA: ResponseTimeCell, valueB: ResponseTimeCell) => {
            const end1 = valueA.end !== null ? parseInt(valueA.end) : Number.MAX_SAFE_INTEGER;
            const end2 = valueB.end !== null ? parseInt(valueB.end) : Number.MAX_SAFE_INTEGER;
            return end1 - end2;
        },
        cellStyle: { textAlign: "center", height: "100%" },
        flex: 2,
        minWidth: 100,
        valueFormatter: () => {
            //This exists only to appease ag-grid as it doesn't know how to handle objects but we don't need an actual value as the time gets rendered in the cellRenderer
            return "";
        },
    },
    {
        headerName: "Owner",
        field: "owner",
        colId: "owner",
        cellRenderer: (params: ICellRendererParams<TableRow, string>) => {
            if (params.value === null) {
                return "-";
            } else if (params.value === "Auto") {
                return (
                    <Box contentDirection={"row"} contentAlignment={"center"} contentJustification={"center"}>
                        <Icon
                            iconColor={{ color: "lime", level: "600" }}
                            size={"4"}
                            name={IconNames.Auto}
                            data-testid="inquiry-owner-auto-icon"
                        />
                        <Box paddingR={"2"} />
                        <Text>Auto</Text>
                    </Box>
                );
            } else {
                return params.value;
            }
        },
        cellStyle: {
            textAlign: "center",
        },
        flex: 2,
        minWidth: 90,
        filter: true,
        floatingFilter: true,
    },
    {
        headerName: "Creation Time",
        field: "creationTime",
        colId: "creationTime",
        cellRenderer: (params: ICellRendererParams<TableRow, number>) => {
            if (params.value) {
                return formatDateTimeAsString(timestampNanosToDateTime(params.value));
            } else {
                return null;
            }
        },
        cellStyle: {
            textAlign: "center",
        },
        getQuickFilterText: (params) => formatDateTimeAsString(DateTime.fromMillis(params.value / 1_000_000)),
        flex: 2,
        minWidth: 180,
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: (filterLocalDateAtMidnight: Date, cellValue: number) => {
                const cellDate = timestampNanosToDateTime(cellValue).startOf("day").toJSDate();

                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
                return 0;
            },
            browserDatePicker: true,
        },
        floatingFilter: true,
    },
    {
        headerName: "List Id",
        field: "listId",
        colId: "listId",
        cellStyle: {
            textAlign: "center",
        },
        floatingFilter: true,
        flex: 2,
        minWidth: 180,
        filter: "agTextColumnFilter",
    },
];

const getRowId = (params: GetRowIdParams<TableRow>): string => params.data.rowId;

const gridOptions: GridOptions = {
    rowHeight: 32,
    animateRows: false,
    suppressCellFocus: true,
    alwaysShowVerticalScroll: true,
    defaultColDef: {
        sortable: true,
        filter: false,
        resizable: true,
    },
    getRowId: getRowId,
    tooltipShowDelay: 0,
};

//We tried our best but couldn't figure out the difference it makes for ag grid when specifying sideBar: true and sideBar: { the desired configuration goes here}
//When using the former the side bar acts as expected and when using the latter the side bar doesn't keep state on data update
//That's why this function exists - we initialize the side bar so it acts as expected and then in runtime we change the configuration to the one we actually want
function configureSideBar(api: GridApi<TableRow>): void {
    const sideBarDef = api.getSideBar();
    if (sideBarDef && sideBarDef.toolPanels) {
        const columnsPanel = sideBarDef.toolPanels[0] as ToolPanelDef;
        columnsPanel.toolPanelParams = { suppressPivotMode: true };
        api.setGridOption("sideBar", sideBarDef);
    }
    api.closeToolPanel();
}

export const ActiveInquiries: React.FC<ActiveInquiriesProps> = ({
    volumeIsOn,
    connectionStatus,
    onVolumeToggle,
    onTradingStatusChanged,
    tradingStatus,
    serverTradingStatus,
    clock,
    timezoneProvider,
}) => {
    const theme = useTheme();
    const selectedPrimaryInquiryTicketId = useSelectedPrimaryInquiryTicketId();
    const { setSelectedPrimaryInquiryTicketId } = useCreditStateDispatch(); // const selectedPrimaryInquiryTicketId = useSelectedPrimaryInquiryTicketId();

    const inquiries = useActiveCreditInquiriesForRowData();

    const onPrimaryInquirySelectionChanged = useCallback(
        (event: SelectionChangedEvent) => {
            const selectedRows = event.api.getSelectedRows() as TableRow[];
            const currentRowTicketId = selectedRows[0]?.ticketId;
            if (currentRowTicketId !== undefined && currentRowTicketId !== selectedPrimaryInquiryTicketId) {
                setSelectedPrimaryInquiryTicketId(currentRowTicketId);
            }
        },
        [selectedPrimaryInquiryTicketId, setSelectedPrimaryInquiryTicketId],
    );

    const inquiryRows: TableRow[] = useMemo(() => {
        return inquiries.map((inquiry) => {
            return inquiryRow(inquiry, clock, theme, timezoneProvider);
        });
    }, [clock, inquiries, theme, timezoneProvider]);

    const [mainTableApi, setMainTableApi] = useState<GridApi<TableRow> | null>(null);

    const [advancedFilterActive, setAdvancedFilterActive] = useState<boolean>(false);
    const onMainGridReady = useCallback((gridReadyEvent: GridReadyEvent<TableRow>): void => {
        const api = gridReadyEvent.api;

        configureSideBar(api);
        api.setGridOption("advancedFilterParent", document.getElementById("advancedFilterParent"));
        setMainTableApi(api);
        saveAndRestoreColumnState(api, LocalStorageKeys.CREDIT_DOCK_COLUMN_STATE, LocalStorageKeys.CREDIT_DOCK_FILTER_STATE);
        api.addEventListener("filterChanged", (e) => setHasFilter(e.api.isAnyFilterPresent()));
    }, []);

    useEffect(() => {
        const api = mainTableApi;
        if (api) {
            if (selectedPrimaryInquiryTicketId !== null) {
                api.forEachNode((node: IRowNode<TableRow>) => {
                    if (node.data?.ticketId === selectedPrimaryInquiryTicketId && !node.isSelected()) {
                        node.setSelected(true);
                    }
                });
            }
        }
    }, [mainTableApi, selectedPrimaryInquiryTicketId]);

    const sideBarDef = true;

    const [quickFilter, setQuickFilter] = useState<string>("");
    const [hasFilter, setHasFilter] = useState<boolean>(false);

    return (
        <ActiveInquiriesContainer>
            <AgGridTheme />
            <ActiveInquiriesListTheme />
            <ActiveInquiriesHeader>
                <Text weight={"bold"}>Live Inquiries</Text>
            </ActiveInquiriesHeader>
            <ActiveInquiriesList data-testid="active-inquiries-table">
                <Box contentDirection={"row"} width={"full"} height={"9"}>
                    <TextInput
                        id="active-inquiries-quickfilter"
                        data-testid="quickfilter"
                        value={quickFilter}
                        title={""}
                        placeholder={"Filter any column..."}
                        showTitle={false}
                        size={"xs"}
                        height={"8"}
                        width={"52"}
                        multiLine={false}
                        resize={"none"}
                        disabled={false}
                        onInputEntered={(input: string) => {
                            setQuickFilter(input);
                        }}
                    />
                    <Box contentDirection={"row"} marginL={"4"} marginT={"1"} marginB={"1"}>
                        <Button
                            height={"7"}
                            width="12"
                            styling={semanticButtonStyling("info")}
                            title={""}
                            leftIcon={{
                                name: IconNames.FilterOff,
                                size: "5",
                                iconColor: { color: "white" },
                                disabled: !hasFilter,
                            }}
                            hoverTitle={"Clear all filters"}
                            onClick={() => {
                                mainTableApi?.setFilterModel(null);
                                mainTableApi?.setAdvancedFilterModel(null);
                                setQuickFilter("");
                            }}
                            disabled={!hasFilter}
                            data-testid="clear-filters"
                        />
                    </Box>
                    <Box contentDirection={"row"} width={"full"} marginL={"4"} marginT={"1"} marginB={"1"}>
                        <RadioBar
                            values={["Basic", "Advanced"]}
                            selectedValue={advancedFilterActive ? "Advanced" : "Basic"}
                            onClick={() => {
                                const filterModel = mainTableApi?.getFilterModel();
                                const advancedFilterModel = mainTableApi?.getAdvancedFilterModel();

                                function confirm(model: FilterModel | AdvancedFilterModel | undefined | null): boolean {
                                    function noExistingFilter(): boolean {
                                        return model === undefined || model === null || Object.keys(model).length === 0;
                                    }

                                    function userConfirmsExistingFilterToBeLost(): boolean {
                                        return window.confirm(
                                            "Changing filtering mode will erase all current filters. Are you sure you want to proceed?",
                                        );
                                    }

                                    return noExistingFilter() || userConfirmsExistingFilterToBeLost();
                                }

                                if (confirm(advancedFilterActive ? advancedFilterModel : filterModel)) {
                                    setAdvancedFilterActive(!advancedFilterActive);
                                }
                            }}
                            buttonWidth={"24"}
                            buttonHeight={"7"}
                            isDisabled={false}
                            joined={true}
                            data-testid="switch-filters-button"
                        />
                        <Box id="advancedFilterParent" contentDirection={"row"} width={"full"} marginL={"2"} />
                    </Box>
                </Box>
                <AgGridReact
                    className={AG_GRID_CLASS_NAME + (inquiries.length === 0 ? " no-inquiries-main-table" : "")}
                    rowData={inquiryRows}
                    gridOptions={{ ...gridOptions, columnDefs }}
                    onGridReady={onMainGridReady}
                    rowSelection={"single"}
                    onSelectionChanged={onPrimaryInquirySelectionChanged}
                    sideBar={sideBarDef}
                    quickFilterText={quickFilter}
                    enableAdvancedFilter={advancedFilterActive}
                    suppressContextMenu={true}
                    statusBar={{
                        statusPanels: [{ statusPanel: "agTotalAndFilteredRowCountComponent" }],
                    }}
                />
                {inquiries.length === 0 && (
                    <Box padding={"4"}>
                        <Text srOnly>No Active Inquiries Status</Text>
                        <Text textAlignment={"center"}>No live RFQ to show</Text>
                    </Box>
                )}
            </ActiveInquiriesList>
            <ActiveInquiriesFooter>
                <ActiveInquiriesBottomBar
                    volumeIsOn={volumeIsOn}
                    onVolumeToggle={onVolumeToggle}
                    connectionStatus={connectionStatus}
                    onTradingStatusChanged={onTradingStatusChanged}
                    tradingStatus={tradingStatus}
                    serverTradingStatus={serverTradingStatus}
                />
            </ActiveInquiriesFooter>
        </ActiveInquiriesContainer>
    );
};
