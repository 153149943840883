import { TraderDesktopProtocol } from "@transficc/trader-desktop-public-protocol-types";
import { SubscriptionTopics } from "./SubscriptionTopics";
import { EpochClock } from "@transficc/infrastructure";
import { DispatchableAction } from "../redux/thunks/InquiryActions";
import { ActionPublisher } from "@transficc/trader-desktop-shared-domain";

export interface SubscriptionRequest {
    subscriptionTopic: SubscriptionTopics;
}

export interface UpdateTraderDesktopSettings {
    tradingStatus: TraderDesktopProtocol.TradingStatus;
}

export type OutgoingMessage =
    | TraderDesktopProtocol.SubscriptionRequest
    | TraderDesktopProtocol.QuoteAction
    | TraderDesktopProtocol.RejectAction
    | TraderDesktopProtocol.AcceptAction
    | TraderDesktopProtocol.PriceBasisUpdateAction;

const constructOutgoingMessage = <T>(
    clock: EpochClock,
    params: T,
): T & {
    sendingTimestampNanos: string;
} => ({ ...params, sendingTimestampNanos: clock.getCurrentTimeNsStr() });

export const subscriptionRequest = (clock: EpochClock, subscriptionTopic: SubscriptionTopics): TraderDesktopProtocol.SubscriptionRequest =>
    constructOutgoingMessage(clock, {
        msgType: "SubscriptionRequest",
        subscriptionTopic,
    });

export const priceBasisUpdateAction = (
    clock: EpochClock,
    params: ActionPublisher.PriceBasisUpdateAction,
): TraderDesktopProtocol.PriceBasisUpdateAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const autoStreamRequestAction = (
    clock: EpochClock,
    params: ActionPublisher.AutoStreamRequestAction,
): TraderDesktopProtocol.AutoStreamRequestAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const manualRequestAction = (
    clock: EpochClock,
    params: ActionPublisher.ManualRequestAction,
): TraderDesktopProtocol.ManualRequestAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const quoteAction = (
    clock: EpochClock,
    params: ActionPublisher.QuoteAction & DispatchableAction,
): TraderDesktopProtocol.QuoteAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const rejectAction = (
    clock: EpochClock,
    params: ActionPublisher.RejectAction & DispatchableAction,
): TraderDesktopProtocol.RejectAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const acceptAction = (
    clock: EpochClock,
    params: ActionPublisher.AcceptAction & DispatchableAction,
): TraderDesktopProtocol.AcceptAction => {
    return constructOutgoingMessage(clock, {
        ...params,
    });
};

export const updateTraderSettings = (
    clock: EpochClock,
    params: UpdateTraderDesktopSettings,
): TraderDesktopProtocol.UpdateTraderDesktopSettings =>
    constructOutgoingMessage(clock, {
        msgType: "UpdateTraderDesktopSettings",
        ...params,
    });

export type IncomingMessage =
    | TraderDesktopProtocol.SubscriptionResponse
    | TraderDesktopProtocol.ValidationFailure
    | TraderDesktopProtocol.Inquiry
    | TraderDesktopProtocol.InquirySnapshotComplete
    | TraderDesktopProtocol.OnDemandPrices
    | TraderDesktopProtocol.TraderDesktopSettings
    | TraderDesktopProtocol.PriceBasis;
