import React from "react";
import { Box, BoxProps, Text } from "@transficc/components";
import styled from "styled-components";

const NoSelectedInquiryContainer = styled(Box).attrs(
    (): BoxProps => ({
        padding: "4",
        height: "full",
        contentDirection: "row",
        contentAlignment: "center",
        contentJustification: "center",
    }),
)``;

export const NoSelectedInquiry: React.FC = () => {
    return (
        <NoSelectedInquiryContainer>
            <Text weight={"bold"} size={"lg"}>
                No selected inquiry
            </Text>
        </NoSelectedInquiryContainer>
    );
};
